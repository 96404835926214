<template>
  <v-container v-if="!isMobile" @change="updateTable" class="my-3">
    <v-row class="mx-1">
      <v-col class="d-flex justify-start">
        <h1 class="d-flex justify-start">
          {{ $t('reports.programmed.header') }} {{ reportsTable ? reportsTable.length : '0' }}/{{ maxReportsNumber }}
        </h1>
      </v-col>
      <v-col>
        <v-btn :disabled="reportsLimitReached" @click="openForm" class="float-right" color="primary"
          ><v-icon class="mr-1">mdi-plus</v-icon>{{ $t('reports.programmed.button_text') }}</v-btn
        >
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-data-table
      :loading="isTableLoading"
      :headers="reportsTableHeader"
      :items="reportsTable"
      hide-default-footer
      :page.sync="page"
      @page-count="pageCount = $event"
      :items-per-page="itemsPerPage"
      class="elevation-1 ma-4"
      :search="search"
      v-bind:loading-text="$t('general.table.loading')">
      <template v-slot:top>
        <v-text-field v-model="search" :label="$t('reports.programmed.searchbar_text')" class="mx-4"></v-text-field>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon medium class="mr-1" color="error" @click.stop="deleteForm(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination :dark="theme === 'dark'" v-model="page" :length="pageCount"></v-pagination>
    </div>
    <Confirm ref="confirm"></Confirm>

    <ProgramReport :key="update" :isActive="reports" v-on:cancel-reports="closeForm"> </ProgramReport>
  </v-container>

  <!-- Mobile View -->

  <v-container v-else @change="updateTable" class="my-3">
    <v-row class="mx-1">
      <v-col class="d-flex justify-start">
        <h1 class="d-flex justify-start programmed-header-h1">
          {{ $t('reports.programmed.header') }}
        </h1>
      </v-col>
      <v-col class="programmed-report-btn">
        <v-btn @click="openForm" class="ma-3 float-right" color="primary"
          ><v-icon class="mr-3">mdi-plus</v-icon>{{ $t('reports.programmed.button_text') }}</v-btn
        >
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-data-table
      :loading="isTableLoading"
      :headers="reportsTableHeader"
      :items="reportsTable"
      hide-default-footer
      :page.sync="page"
      @page-count="pageCount = $event"
      :items-per-page="itemsPerPage"
      class="elevation-1 ma-4"
      :search="search"
      v-bind:loading-text="$t('general.table.loading')">
      <template v-slot:top>
        <v-text-field v-model="search" :label="$t('reports.programmed.searchbar_text')" class="mx-4"></v-text-field>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon medium class="mr-1" color="error" @click.stop="deleteForm(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination :dark="theme === 'dark'" v-model="page" :length="pageCount"></v-pagination>
    </div>
    <Confirm ref="confirm"></Confirm>

    <ProgramReport :key="update" :isActive="reports" v-on:cancel-reports="closeForm"> </ProgramReport>
  </v-container>
</template>

<script>
import Confirm from '../../components/confirm.vue';
import ProgramReport from '../../components/forms/reportForm/reportsForm.vue';
import { getProgrammedReports } from '../../viewModels/reportsViewModel';
import { deleteProgrammedReport } from '../../viewModels/reportsViewModel';
import { eventBus } from '../../main';
const ls = require('localstorage-slim');

export default {
  components: {
    ProgramReport,
    Confirm,
  },
  data: () => ({
    theme: ls.get('theme') ?? 'ligth',
    reports: false,
    update: 0,
    reportsTable: [],
    isTableLoading: false,
    search: '',
    maxReportsNumber: 20,
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
  }),

  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
    this.reportsTable = await getProgrammedReports();

    this.translate();
  },

  beforeUpdate: function () {
    this.updateTable();
    this.translate();
  },

  methods: {
    async updateTable() {
      // FRAN: comento esto por bucle infinito, quizás estaba por alguna razón
      //this.reportsTable = await getProgrammedReports();
    },
    openForm() {
      eventBus.$emit('programReport', this.reportsTable);
      this.reports = true;
    },
    closeForm: function () {
      this.reports = false;
      this.update++;
    },

    deleteInPage(item) {
      let indexToDelete = this.reportsTable.indexOf(item);
      this.reportsTable.splice(indexToDelete, 1);
    },

    async deleteForm(report) {
      if (
        await this.$refs.confirm.open(this.$t('reports.programmed.delete'), this.$t('reports.programmed.delete_info'), {
          color: 'red',
        })
      ) {
        const response = await deleteProgrammedReport(report.title);
        this.deleteInPage(report);
      }
    },

    translate() {
      const englishArray = [
        'km_count',
        'driving_hours',
        'map_route',
        'pi_sum',
        'engine_hours',
        'temp_graph',
        'journey_sum',
        'temp_ticket',
        'Daily',
        'Weekly',
        'Monthly',
      ];

      const spanishArray = [
        'km_count',
        'driving_hours',
        'map_route',
        'pi_sum',
        'engine_hours',
        'temp_graph',
        'journey_sum',
        'temp_ticket',
        'Diario',
        'Semanal',
        'Mensual',
      ];

      const deutschArray = [
        'km_count',
        'driving_hours',
        'map_route',
        'pi_sum',
        'engine_hours',
        'temp_graph',
        'journey_sum',
        'temp_ticket',
        'Täglich',
        'Wöchentlich',
        'Monatlich',
      ];

      const frenchArray = [
        'km_count',
        'driving_hours',
        'map_route',
        'pi_sum',
        'engine_hours',
        'temp_graph',
        'journey_sum',
        'temp_ticket',
        'Journal',
        'Hebdomadaire',
        'Mensuel',
      ];

      const portugueseArray = [
        'km_count',
        'driving_hours',
        'map_route',
        'pi_sum',
        'engine_hours',
        'temp_graph',
        'journey_sum',
        'temp_ticket',
        'Diário',
        'Semanalmente',
        'Mensual',
      ];

      const italienArray = [
        'km_count',
        'driving_hours',
        'map_route',
        'pi_sum',
        'engine_hours',
        'temp_graph',
        'journey_sum',
        'temp_ticket',
        'Quotidiano',
        'Settimanalmente',
        'Mensile',
      ];

      const generalTranslation = [
        this.$t('reports.types.mileage'),
        this.$t('reports.types.working_time'),
        this.$t('reports.types.map_route'),
        this.$t('reports.types.pois'),
        this.$t('reports.types.engine_hours'),
        this.$t('reports.types.temp_graph'),
        this.$t('reports.types.journey_summary'),
        this.$t('reports.types.temp_ticket'),
        this.$t('reports.programmed.daily'),
        this.$t('reports.programmed.weekly'),
        this.$t('reports.programmed.monthly'),
      ];

      if (this.reportsTable.length > 0) {
        for (let report of this.reportsTable) {
          let array = [];

          if (Array.isArray(report.reports_type_selected)) {
            report.reports_type_selected.forEach(function (element) {
              let index = englishArray.indexOf(element);
              array.push(generalTranslation[index]);
              report.type = array;
            });
          }

          if (englishArray.find((element) => element === report.periodicity)) {
            let index = englishArray.indexOf(report.periodicity);
            report.periodicity = generalTranslation[index];
          }
          if (spanishArray.find((element) => element === report.periodicity)) {
            let index = spanishArray.indexOf(report.periodicity);
            report.periodicity = generalTranslation[index];
          }
          if (deutschArray.find((element) => element === report.periodicity)) {
            let index = deutschArray.indexOf(report.periodicity);
            report.periodicity = generalTranslation[index];
          }
          if (frenchArray.find((element) => element === report.periodicity)) {
            let index = frenchArray.indexOf(report.periodicity);
            report.periodicity = generalTranslation[index];
          }
          if (italienArray.find((element) => element === report.periodicity)) {
            let index = italienArray.indexOf(report.periodicity);
            report.periodicity = generalTranslation[index];
          }
          if (portugueseArray.find((element) => element === report.periodicity)) {
            let index = portugueseArray.indexOf(report.periodicity);
            report.periodicity = generalTranslation[index];
          }
        }
      }
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    reportsLimitReached() {
      return this.reportsTable.length >= this.maxReportsNumber;
    },

    reportsTableHeader() {
      return [
        {
          text: this.$t('reports.programmed.name'),
          value: 'title',
          width: '5rem',
          divider: true,
        },
        {
          text: this.$t('reports.programmed.type'),
          value: 'type',
          width: '5rem',
          divider: true,
        },
        {
          text: this.$t('reports.programmed.periodicity'),
          value: 'periodicity',
          width: '5rem',
          divider: true,
        },

        {
          text: this.$t('alarms.delete'),
          value: 'actions',
          width: '3rem',
          divider: true,
          sortable: false,
        },
      ];
    },
  },
};
</script>
