const { formatDate } = require('../../utils/utils');
const VueI18n = require('../../plugins/lang/index');

const handleNotification = () => {
  const notificationController = (notification, deviceCollection, groupSn) => {
    // CAMBIAR CARPLATE POR ALIAS
    for (let x = 0; x < deviceCollection.length; x++) {
      if (deviceCollection[x].device_sn == notification.device_sn) {
        notification.carplate = deviceCollection[x].alias;
      }
    }

    // AGRUPAR POR SN
    if (!groupSn[notification.device_sn]) {
      groupSn[notification.device_sn] = [];
    }
    groupSn[notification.device_sn].push(notification);

    //DATE
    notification.date = formatDate(notification.date);

    //STATE
    if (notification.state !== 'pending' && notification.state !== 'deleted') notification.state = 'read';

    //SENSOR- probe
    let probeParam = notification.probe;
    if (notification.probe) {
      notification.probe = `${VueI18n.default.t(`device.last_frames.frame_frigo.${probeParam}`)} `;
    }

    //DESCRIPCIÓN- type
    if (notification.type) {
      if (notification.type == 'temp') {
        const patron = /^p[1-8]$/;

        if (patron.test(probeParam)) {
          notification.type = `${VueI18n.default.t('notifications.temperatureOutOfRange')} - ${VueI18n.default.t(
            `device.last_frames.frame_frigo.${probeParam}`,
          )} - ${notification.measure}ºC`;
        } else {
          notification.type = `${VueI18n.default.t('notifications.temperatureOutOfRange')} - ${probeParam} - ${
            notification.measure
          }ºC`;
        }
      } else if (notification.type == 'door') {
        notification.type = VueI18n.default.t('notifications.openDoor');
      } else if (notification.type == 'certs') {
        notification.type = VueI18n.default.t('notifications.certificates');
      }
    }
  };

  return { notificationController };
};

module.exports = handleNotification();
