const PetitionError = require('../../petitionError');
const { get } = require('../../httpRequester');

class GetAllDevicesException extends PetitionError {
  constructor(code) {
    super('Get All Devices', code);
  }
}

async function getAllDevices(token) {
  const response = await get('devices/get_all_devices', token);

  if (response.result !== GetAllDevicesException.success) {
    throw (new GetAllDevicesException(response.result));
  } else {
    return response.devices; // TO DO transform
  }
}

// class GetAllDevicesException extends PetitionError {
//   constructor(code) {
//     super('Get All Devices', code);
//   }
// }

module.exports = {
  getAllDevices,
  GetAllDevicesException,
};
