const PetitionError = require('../../petitionError');

const { postAnonymous } = require('../../httpRequester');

class ValidationEmailPetitionException extends PetitionError {
  constructor(code) {
    super('Validate email', code);
  }
}

async function validationEmail(token, email, randomCode) {
  const payload = { email, randomCode };
  const response = await postAnonymous(`users/validation?&token=${token}`, payload);

  if (response.result !== ValidationEmailPetitionException.success) {
    throw new ValidationEmailPetitionException(response.result);
  } else {
    return response;
  }
}

module.exports = {
  validationEmail,
  ValidationEmailPetitionException,
};
