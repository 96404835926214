const PetitionError = require("../../petitionError");
const { post } = require("../../httpRequester");

class ChangeUserPasswordByAdminException extends PetitionError {
    static errorSamePassword = "SAME_PASSWORD";

    constructor(code) {
        super("Change User Passord By Admin", code);
    }
}

async function changeUserPasswordByAdmin(user_id, new_password, token) {
    const payload = { user_id, new_password };

    const response = await post("users/change_password_by_admin", payload, token);

    if (response.result !== ChangeUserPasswordByAdminException.success) {
        throw new ChangeUserPasswordByAdminException(response.result);
    } else {
        return response.user; // TODO transform
    }
}

// class ChangeUserPasswordByAdminException extends PetitionError {
//   static errorSamePassword = 'SAME_PASSWORD';

//   constructor(code) {
//     super('Change User Passord By Admin', code);
//   }
// }

module.exports = {
    changeUserPasswordByAdmin,
    ChangeUserPasswordByAdminException,
};
