<template>
  <v-container class="pa-0 create-device-container">
    <v-row>
      <v-col class="create-device-col">
        <div class="breadcrumbs-container breadcrumbs-container-create-device">
          <v-row style="justify-content: center; text-align: center">
            <h1>{{ this.$t('general.form.deviceCertificates') + ' ' + deviceData.device_sn }}</h1>
          </v-row>
          <v-row class="breadcrumbs" style="justify-content: center">
            <v-breadcrumbs :items="items" class="pl-0">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                  {{ item.text.toUpperCase() }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <div class="container-create-device" style="max-width: 80%; margin-left: auto !important; margin-right: auto !important">
      <h2 class="d-flex justify-start my-2">
        {{ $t('user.form.details') }}
      </h2>

      <CertificateDataForm @removeCert="removeCert" :deviceData="deviceData" ref="devicesData"></CertificateDataForm>

      <v-row class="buttoms-edit-user-mobile d-flex justify-center mt-10">
        <v-btn color="primary" class="d-flex justify-space-between mx-15" @click="saveCertificate">
          <v-icon class="mr-1">mdi-content-save</v-icon>{{ $t('general.form.save') }}
        </v-btn>

        <v-btn color="red" class="d-flex justify-space-between mx-15" @click="goBack">
          <v-icon class="white--text mr-1">mdi-exit-to-app</v-icon>
          <span class="white--text">{{ $t('general.form.exit') }}</span>
        </v-btn>
      </v-row>

      <ErrorDialog
        :isActive="errorDialog"
        v-bind:tittleText="$t('general.dialog.error.title')"
        :messageText="errorText"
        v-bind:acceptButtonText="$t('general.dialog.error.accept_button')"
        v-on:accept-dialog="errorDialog = false">
      </ErrorDialog>
    </div>
  </v-container>
</template>

<script>
import router from '../../../../plugins/router/index';
import CertificateDataForm from '../../../../components/forms/deviceDataForm/certificateDataForm.vue';
import ErrorDialog from '../../../../components/errorDialog.vue';
import { removeCertificate, saveCertificate, getAdminCertificates } from '../../../../viewModels/devicesViewModel';
import { CreateCertificateException } from '../../../../server/petitions/devices/createCertificate';
import { getUser } from '../../../../viewModels/userViewModel';
import { getUserDevices } from '../../../../server/petitions/users/getUserDevices';

export default {
  components: {
    CertificateDataForm,
    ErrorDialog,
  },
  props: {
    deviceData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    errorDialog: false,
    errorText: '',
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '../../../dashboard/temperatures',
      },
      {
        text: 'Dispositivos',
        disabled: false,
        href: '/admin/devices',
      },
      {
        text: 'Certificados',
        disabled: true,
        href: '/admin/devices/edit/:device_sn/certificates',
      },
    ],
    user: null,
    docs: [],
    periodicity: 24,
  }),
  mounted: async function (e) {
    this.user = await getUser();

    let deviceDocuments = await getAdminCertificates(this.deviceData.device_sn);

    deviceDocuments
      .flatMap((i) => i.certificates || []) //desmonto la array anidada y filtro null/undefined
      .filter((c) => c.device_sn == this.deviceData.device_sn) // filtro falsy values (null, undefined, '', 0, false, NaN)
      .forEach((c) => this.docs.push(c));

    this.$refs.devicesData.certTableData = this.docs;
  },
  methods: {
    goBack: function () {
      router.push({ name: 'devices' });
    },

    formatDateCert(date) {
      let month = date.getMonth() + 1 + this.periodicity; // Sumamos la periodicidad al mes actual
      let year = date.getFullYear();

      // Ajustar meses y años si se pasa de 12
      if (month > 12) {
        let extraYears = Math.floor(month / 12); // Cuántos años extras tenemos
        month = month % 12; // El mes actual después de ajustar los años
        year += extraYears; // Ajustamos el año

        // ¿Y si la suma nos deja en un mes 0? Eso sería diciembre del año anterior
        if (month === 0) {
          month = 12; // Ajustamos a diciembre
          year -= 1; // Retrocedemos un año, porque técnicamente estamos en diciembre del año anterior
        }
      }

      // Formateo final
      const day = date.getDate().toString().padStart(2, '0');
      month = month.toString().padStart(2, '0'); // Asegúrate de que el mes tenga dos dígitos

      return `${year}/${month}/${day}`;
    },

    saveCertificate: async function () {
      try {
        var certificateDataForm = this.$refs.devicesData;
        if (certificateDataForm.validate()) {
          let sn = Number(certificateDataForm.serialNumber);
          let certName = certificateDataForm.name;
          let nextInspectionDate = this.formatDateCert(new Date(certificateDataForm.nextInspectionDate));
          let days = certificateDataForm.days;
          let base64 = certificateDataForm.base64;
          let id = this.deviceData._id;
          let alias;

          this.devices = await getUserDevices(this.user.token, this.user.userId);
          this.devices.map((i) => {
            if (i.device_sn == sn) alias = i.alias;
          });

          let result = await saveCertificate(base64, certName, sn, nextInspectionDate, days, id, alias);
          this.$refs.devicesData.certTableData.push(result.certificate.at(-1));

          // this.goBack();
        }
      } catch (e) {
        switch (e.code) {
          case CreateCertificateException.unknownError:
          case CreateCertificateException.incorrectParameters:
          case CreateCertificateException.invalidToken:
          case CreateCertificateException.expiredToken:
            console.error(e);
            this.errorText = this.$t('general.error.unexpected_error');
            this.errorDialog = true;
            break;
          default:
            console.error('CATCH ERROR:', e);
        }
      }
    },
    removeCert: async function (certificateId, deviceSn) {
      try {
        await removeCertificate(certificateId, deviceSn);
      } catch (error) {
        switch (e.code) {
          case CreateCertificateException.unknownError:
          case CreateCertificateException.incorrectParameters:
          case CreateCertificateException.invalidToken:
          case CreateCertificateException.expiredToken:
            console.error(e);
            this.errorText = this.$t('general.error.unexpected_error');
            this.errorDialog = true;
            break;
          default:
            console.error('CATCH ERROR:', e);
        }
      }
    },
  },
};
</script>
