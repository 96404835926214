<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card>
      <v-container>
        <v-form ref="eventForm" @submit.prevent="addEvent">
          <v-text-field autofocus v-model="event.name" type="text" :label="$t('general.dialog.eventDialog.name')"></v-text-field>
          <v-autocomplete
            :label="$t('general.dialog.eventDialog.device')"
            chips
            deletable-chips
            small-chips
            v-model="event.selectedDevice"
            :items="event.devices"></v-autocomplete>
          <v-textarea
            rows="1"
            v-model="event.description"
            type="text"
            :label="$t('general.dialog.eventDialog.description')"></v-textarea>
          <v-col sm="12" lg="12">
            <v-sheet>
              <v-row md="4">
                <v-text-field
                  class="mt-2"
                  v-model="event.start"
                  type="date"
                  :label="$t('general.dialog.eventDialog.start')"></v-text-field>
                <v-text-field
                  class="mt-2 ml-5"
                  v-model="event.end"
                  type="date"
                  :label="$t('general.dialog.eventDialog.end')"></v-text-field>
              </v-row>
            </v-sheet>
            <v-sheet>
              <v-row md="4">
                <v-subheader class="mt-1">{{ $t('general.dialog.eventDialog.color') }}</v-subheader>
                <v-menu
                  bottom
                  right
                  transition="scale-transition"
                  origin="top left"
                  :close-on-content-click="closeOnContentClick">
                  <template v-slot:activator="{ on, attrs }">
                    <i class="mt-2 ml-12">
                      <v-btn small :color="event.color" light v-bind="attrs" v-on="on"></v-btn>
                    </i>
                  </template>
                  <v-card width="300">
                    <v-list>
                      <v-list-item>
                        <v-color-picker
                          style="display: flex; align-items: center"
                          v-model="event.color"
                          dot-size="10"
                          hide-inputs
                          show-swatches
                          swatches-max-height="100"></v-color-picker>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </v-row>
            </v-sheet>
          </v-col>
        </v-form>
        <div class="mt-5" style="display: flex; justify-content: center">
          <v-btn type="submit" color="primay" class="mr-4" @click.stop="submit">{{
            $t('general.dialog.eventDialog.save')
          }}</v-btn>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  model: {
    prop: 'event',
  },
  props: {
    event: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    closeOnContentClick: false,
  }),
  methods: {
    submit() {
      this.dialog = false;
      this.$emit('eventDialog:submit');
    },
    setActiveModal: function (active) {
      this.dialog = active;
    },
  },

  computed: {},

  mounted: async function () {},
};
</script>
