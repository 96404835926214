<template>
  <div class="mt-5 mx-10">
    <v-row>
      <v-col class="d-flex justify-center">
        <h1>{{ $t('admin.menu.sim-management') }}</h1>
        <v-btn :disabled="isUpdatingData" color="primary" style="width: 3rem; min-width: 30px !important" @click="updateSims()"
          ><v-icon>mdi-reload</v-icon></v-btn
        >
      </v-col>
    </v-row>

    <template>
      <div v-if="allSims.length === 0">
        <v-autocomplete prepend-icon="mdi-loading mdi-spin" :label="$t('device.form.loading')"></v-autocomplete>
      </div>
      <v-data-table
        v-if="allSims.length > 0"
        :headers="simCardsHeader"
        data-cy="simcardsTable"
        :items="allSims"
        @click:row="selectSimCard"
        :search="search"
        item-key="iccid"
        :items-per-page="10"
        sort-by="iccid"
        class="sim-table table-cursor elevation-1"
        cursor="pointer"
        style="overflow: auto; overflow-x: hidden; min-height: 100px; max-height: 750px">
        <template v-slot:top>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('admin.menu.search-sim')"
            single-line
            hide-details
            prepend-inner-icon=" "></v-text-field>
        </template>
        <template v-slot:[`item.iccid`]="{ item }">
          <span v-if="item.status?.toLowerCase() === active" class="mdi mdi-circle icon-green" :title="$t('admin.menu.active')">
          </span>
          <span
            v-if="item.status?.toLowerCase() === terminated"
            class="mdi mdi-circle icon-red"
            :title="$t('admin.menu.terminated')">
          </span>
          <span
            v-if="item.status?.toLowerCase() === suspended"
            class="mdi mdi-circle icon-orange"
            :title="$t('admin.menu.suspended')">
          </span>
          <span
            v-if="item.status?.toLowerCase() === inventory"
            class="mdi mdi-circle icon-blue"
            :title="$t('admin.menu.inventory')">
          </span>
          <span v-if="!item.status" class="mdi mdi-circle icon-grey" :title="$t('admin.menu.unknown')"> </span>
          {{ item.iccid }}
        </template>

        <template v-slot:[`item.last_seen`]="{ item }">
          {{ formatDate(item.last_seen) }}
        </template>

        <template v-slot:[`item.device_sn`]="{ item }">
          <a href="#" @click="editDevice(item)">
            {{ item.device_sn }}
          </a>
        </template>
      </v-data-table>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" scrollable max-width="1400px">
          <v-card v-show="dialog" class="dialog-style">
            <v-card-title style="width: 100%" class="dialog">
              <v-row>
                <v-col class="d-flex justify-center" cols="11"> {{ $t('nav_bar.menu.info') }} </v-col>
                <v-col class="d-flex justify-end" cols="1">
                  <v-card-actions>
                    <v-btn style="margin-bottom: 1em" color="red darken-1" text @click="changeDialog()"
                      ><v-icon> mdi-window-close </v-icon></v-btn
                    >
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text v-if="sim.carrier !== 'Matooma'" class="card-style">
              <br />
              <span v-if="sim.iccid"> ICCID: {{ sim.iccid }} <br /> </span>
              <span v-if="sim.activation"> {{ $t('admin_menu.activation.date') }}: {{ formatDate(sim.activation) }} <br /> </span>
              <span v-if="sim.carrier"> Carrier: {{ sim.carrier }} <br /> </span>
              <span v-if="sim.deactivation">
                {{ $t('admin_menu.deactivation_date') }}: {{ formatDate(sim.deactivation) }} <br />
              </span>
              <span v-if="sim.device_sn"> SN: {{ sim.device_sn }} <br /> </span>
              <span v-if="sim.last_seen"> {{ $t('admin_menu.last_seen') }}: {{ formatDate(sim.last_seen) }} <br /> </span>
              <span v-if="sim.phone"> {{ $t('company.atribute.phone') }}: {{ sim.phone }} <br /> </span>
              <span v-if="sim.status"> {{ $t('dashboard.details.status') }}: {{ sim.status }} <br /> </span>
              <span v-if="sim.notes"> {{ $t('company.atribute.notes') }}: {{ sim.notes }} <br /> </span>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text v-if="dialogCompany" style="text-align: center; width: 100%">
              <v-progress-circular v-show="isLoading" color="primary" size="30" indeterminate></v-progress-circular>
              <div id="pdf"></div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
      <br />
    </template>

    <br />
    <v-snackbar v-model="isSnackbar" :timeout="timeout" color="success">
      Cargando datos... Espere hasta que se actualice
    </v-snackbar>
  </div>
</template>
<script>
import ConfirmationDialog from '../../../components/confirmationDialog';
import ErrorDialog from '../../../components/errorDialog';
import router from '../../../plugins/router/index';
import { getAllSims, getAllSimsCollection, getSimInfoForMatooma } from '../../../viewModels/adminViewModel.js';
import { getDevice } from '../../../viewModels/adminViewModel.js';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  components: {
    ConfirmationDialog,
    ErrorDialog,
  },

  data() {
    return {
      active: 'active',
      terminated: 'terminated',
      suspended: 'suspended',
      inventory: 'inventory',
      allSims: [],
      search: '',
      dialog: false,
      sim: '',
      dialogCompany: false,
      newPetitionMatooma: null,
      pdfViewer: null,
      isLoading: true,
      user: null,
      isUpdatingData: false,
      isSnackbar: false,
      timeout: 30000,
    };
  },

  mounted() {
    this.getAllSimCards();
  },

  methods: {
    async updateSims() {
      this.isUpdatingData = true;
      this.isSnackbar = true;

      await getAllSimsCollection().then(
        setTimeout(() => {
          window.location.reload();
        }, this.timeout),
      );
    },
    changeDialog() {
      this.dialog = false;
      this.dialogCompany = false;
    },
    async getPDF() {
      const noData = this.$t('admin_menu.no-data');
      const documentDefinition = {
        pageSize: { width: 900, height: 'auto' },

        content: [
          {
            text: `${this.$t('admin_menu.matooma_data')}`,
            alignment: 'center',
            fontSize: 14,
            bold: true,
            margin: [0, 20, 0, 8],
          },
          {
            style: 'tableExample',

            table: {
              headerRows: 1,
              widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
              body: [
                [
                  { text: 'ICCID', style: 'tableHeader', fontSize: 8, alignment: 'center' },
                  { text: `${this.$t('admin_menu.activation.date')}`, style: 'tableHeader', fontSize: 8, alignment: 'center' },
                  { text: `${this.$t('admin_menu.deactivation_date')}`, style: 'tableHeader', fontSize: 8, alignment: 'center' },
                  { text: `${this.$t('admin_menu.last_seen')}`, style: 'tableHeader', fontSize: 8, alignment: 'center' },
                  { text: `${this.$t('company.atribute.phone')}`, style: 'tableHeader', fontSize: 8, alignment: 'center' },
                  { text: `${this.$t('dashboard.details.status')}`, style: 'tableHeader', fontSize: 8, alignment: 'center' },
                  { text: `${this.$t('admin_menu.manufacturename')}`, style: 'tableHeader', fontSize: 8, alignment: 'center' },
                  { text: `${this.$t('admin_menu.modelname')}`, style: 'tableHeader', fontSize: 8, alignment: 'center' },
                  { text: `${this.$t('admin_menu.profilename')}`, style: 'tableHeader', fontSize: 8, alignment: 'center' },
                  { text: 'ID', style: 'tableHeader', fontSize: 8, alignment: 'center' },
                ],
                [
                  {
                    text: ` ${this.sim.iccid ? this.sim.iccid : noData}`,
                    fontSize: 10,
                    alignment: 'center',
                    margin: [0, 0, 2],
                  },
                  {
                    text: ` ${this.sim.activation ? this.formatDate(this.sim.activation) : noData}`,
                    fontSize: 10,
                    alignment: 'center',
                    margin: [0, 0, 2],
                  },
                  {
                    text: `${this.sim.deactivation ? this.formatDate(this.sim.deactivation) : noData}`,
                    fontSize: 10,
                    alignment: 'center',
                    margin: [0, 0, 2],
                  },
                  {
                    text: ` ${this.sim.last_seen ? this.formatDate(this.sim.last_seen) : noData}`,
                    fontSize: 10,
                    alignment: 'center',
                    margin: [0, 0, 2],
                  },
                  {
                    text: ` ${this.sim.phone ? this.sim.phone : noData}`,
                    fontSize: 10,
                    alignment: 'center',
                    margin: [0, 0, 2],
                  },
                  {
                    text: ` ${this.newPetitionMatooma.data.simcardState ?? noData}`,
                    fontSize: 10,
                    alignment: 'center',
                    margin: [0, 0, 2],
                  },
                  {
                    text: ` ${
                      this.newPetitionMatooma.data.manufacturerName ? this.newPetitionMatooma.data.manufacturerName : noData
                    }`,
                    fontSize: 10,
                    alignment: 'center',
                    margin: [0, 0, 2],
                  },
                  {
                    text: ` ${this.newPetitionMatooma.data.modelName ? this.newPetitionMatooma.data.modelName : noData}`,
                    fontSize: 10,
                    alignment: 'center',
                    margin: [0, 0, 2],
                  },
                  {
                    text: ` ${this.newPetitionMatooma.data.profileName ? this.newPetitionMatooma.data.profileName : noData}`,
                    fontSize: 10,
                    alignment: 'center',
                    margin: [0, 0, 2],
                  },
                  {
                    text: ` ${this.newPetitionMatooma.data.id ? this.newPetitionMatooma.data.id : noData}`,
                    fontSize: 10,
                    alignment: 'center',
                    margin: [0, 0, 2],
                  },
                ],
              ],
            },
            layout: 'lightHorizontalLines',
          },
        ],
      };

      this.isLoading = false;

      pdfMake.createPdf(documentDefinition).getDataUrl((dataUrl) => {
        this.pdfViewer = document.getElementById('pdf');
        const iframe = document.createElement('iframe');
        iframe.src = dataUrl;
        iframe.style.width = '100%';
        iframe.style.height = '550px';
        iframe.style.border = 'none';

        this.pdfViewer.appendChild(iframe);
      });
    },

    async getAllSimCards() {
      this.allSims = await getAllSims();
    },
    async getSimCardInfo(id) {
      this.newPetitionMatooma = await getSimInfoForMatooma(id);

      this.getPDF();
    },
    selectSimCard(sim) {
      if (sim.carrier === 'Matooma') {
        this.isLoading = true;
        this.dialog = false;
        this.dialogCompany = false;
        this.getSimCardInfo(sim.simcard_id);
        setTimeout(() => {
          this.dialogCompany = true;
          this.dialog = true;
          this.sim = sim;
        }, 200);
      } else
        setTimeout(() => {
          this.dialog = true;
          this.dialogCompany = false;
          this.sim = sim;
        }, 200);
    },
    editDevice: async function (e) {
      const device = await getDevice(e.device_sn);
      router.push({
        name: 'editDevice',
        params: { device_sn: e.device_sn, deviceData: device },
      });
    },
    formatDate(date) {
      if (!date) return;
      date = date.replace('.000Z', '');
      let minute = new Date(date).getMinutes();
      if (minute.toString().length < 2) minute = '0' + minute;
      let hour = new Date(date).getHours();
      if (hour.toString().length < 2) hour = '0' + hour;
      let day = new Date(date).getDate();
      if (day.toString().length < 2) day = '0' + day;
      let month = new Date(date).getMonth() + 1;
      if (month.toString().length < 2) month = '0' + month;
      let year = new Date(date).getFullYear();

      let formatedDate = `${day}/${month}/${year} - ${hour}:${minute}`;
      return formatedDate;
    },
  },

  computed: {
    simCardsHeader() {
      return [
        {
          text: 'ICCID',
          value: 'iccid',
          width: '8rem',
          divider: true,
        },

        {
          text: 'SN',
          value: 'device_sn',
          width: '6rem',
          divider: true,
        },
        {
          text: 'Carrier',
          value: 'carrier',
          width: '8rem',
          divider: true,
        },
        {
          text: `${this.$t('admin_menu.last_seen')}`,
          value: 'last_seen',
          width: '8rem',
          divider: true,
        },

        {
          text: `${this.$t('company.atribute.notes')}`,
          value: 'notes',
          width: '10rem',
          divider: true,
        },
      ];
    },
  },
};
</script>
