import Vue from 'vue';
import VueRouter from 'vue-router';
import userViewModel from '../../viewModels/userViewModel';
import adminViewModel from '../../viewModels/adminViewModel';

import EmailValidation from '../../views/emailValidation.vue';
import Platform from '../../platform.vue';
import Login from '../../views/login.vue';

import Dashboard from '../../views/dashboard/dashboard.vue';
import Map from '../../components/dashboard/map.vue';
import Temperatures from '../../views/dashboard/temperatures/temperatures.vue';
import Pois from '../../views/dashboard/pois/pois.vue';

import Admin from '../../views/admin/admin.vue';
import Devices from '../../views/admin/devices/devices.vue';
import Beacons from '../../views/admin/beacons/beacons.vue';
import CreateDevice from '../../views/admin/devices/createDevice/createDevice.vue';
import CreateBeacon from '../../views/admin/beacons/createBeacon/createBeacon.vue';
import CreateCertificate from '../../views/admin/devices/createDevice/certificate.vue';
import CreateDocument from '../../views/dashboard/documentation/documentation.vue';
import EditDevice from '../../views/admin/devices/editDevices/editDevice.vue';
import EditBeacon from '../../views/admin/beacons/editBeacons/editBeacon.vue';
import Users from '../../views/admin/users/users.vue';
import Companies from '../../views/admin/companies/companies.vue';
import NewCompany from '../../views/admin/companies/newCompany/newCompany.vue';
import CreateUser from '../../views/admin/users/createUser/createUser.vue';
import EditUser from '../../views/admin/users/editUser/editUser.vue';
import changeUserPassword from '../../views/admin/users/changeUserPassword/changeUserPassword.vue';
import EditDataUser from '../../views/admin/users/editDataUser/editDataUser.vue';
import EditCompany from '../../views/admin/companies/editCompany/editCompany.vue';
import Search from '../../views/admin/users/search.vue';

import Configuration from '../../views/userConfiguration/userConfiguration/userConfiguration.vue';
import MenuConfiguration from '../../views/userConfiguration/menuConfiguration.vue';
import ConfigAlarm from '../../views/userConfiguration/configAlarm/configAlarm.vue';
import Calendar from '../../views/userConfiguration/configAlarm/calendar.vue';
import Maintenance from '../../views/userConfiguration/configAlarm/maintenance.vue';
import Certificates from '../../views/userConfiguration/maintenances/certsHandler.vue';
import Itv from '../../views/userConfiguration/maintenances/itv.vue';
import FrigoMachine from '../../views/userConfiguration/maintenances/frigoMaintenance.vue';
import EditAlarm from '../../views/userConfiguration/editAlarm/editAlarm.vue';
import ChangePassword from '../../views/userConfiguration/changePassword/changePassword.vue';
// import UserBeacons from '../../views/userConfiguration/userBeacons/userBeacons.vue';
import Metrics from '../../views/userConfiguration/metrics/metrics.vue';
import Emails from '../../views/userConfiguration/emails/emails.vue';
import Names from '../../views/userConfiguration/probesNames/probesNames.vue';

import Notifications from '../../views/notifications/notifications.vue';

import Shared from '../../views/shared/shared.vue';
import ShareDevice from '../../views/shared/sharedDevices/sharedDevices.vue';
import ShareCustomer from '../../views/shared/sharedCustomer/sharedCustomer.vue';

import Reports from '../../views/reports/reports.vue';
import ReportNavbar from '../../views/reports/reportNavbar';
import ProgramReport from '../../views/reports/programReport';
import HistoricReports from '../../views/reports/historicReports';
import NewPlatformSample from '../../views/newPlatform/newPlatformSample';
import Simcards from '../../views/admin/matooma/simCards.vue';
import Loader from '../../components/loader.vue';
import Terms from '../../views/terms.vue';
import Status from '../../views/status.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/status',
    name: 'status',
    component: Status,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/new_platform/:id',
    name: 'newPlatform',
    component: NewPlatformSample,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/email_validation/:email',
    name: 'emailValidation',
    component: EmailValidation,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/',
    name: 'platform',
    component: Platform,
    redirect: 'dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        redirect: 'dashboard/temperatures',
        component: Dashboard,
        children: [
          {
            path: '/dashboard/temperatures',
            name: 'temperatures',
            props: true,
            component: Temperatures,
          },
          {
            path: '/dashboard/map',
            name: 'map',
            props: true,
            component: Map,
          },
          {
            path: '/dashboard/pois',
            name: 'pois',
            component: Pois,
          },
        ],
      },
      {
        path: '/configuration',
        name: 'configuration',
        component: MenuConfiguration,
        props: true,
        redirect: 'configuration/user',

        children: [
          {
            path: '/configuration/user',
            name: 'user',
            component: Configuration,
          },
          {
            path: '/configuration/password',
            name: 'password',
            component: ChangePassword,
          },
          // {
          //   path: '/configuration/beacons',
          //   name: 'userBeacons',
          //   component: UserBeacons,
          // },
          {
            path: '/configuration/metrics',
            name: 'metrics',
            component: Metrics,
          },
          {
            path: '/configuration/emails',
            name: 'emails',
            props: true,
            component: Emails,
          },
          {
            path: '/configuration/alarms',
            name: 'alarm',
            component: ConfigAlarm,
          },
          {
            path: '/configuration/calendar',
            name: 'calendar',
            component: Calendar,
          },
          {
            path: '/configuration/alarms/edit/:_id',
            name: 'editAlarm',
            props: true,
            component: EditAlarm,
          },
          {
            path: '/configuration/names',
            component: Names,
          },
          {
            path: '/configuration/docs',
            name: 'docs',
            props: true,
            component: CreateDocument,
          },
        ],
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: Notifications,
      },
      {
        path: '/shared',
        name: 'shared',
        redirect: 'shared/devices',
        component: Shared,
        meta: {
          requiresNotClient: true,
        },
        children: [
          {
            path: '/shared/devices',
            name: 'shareDevice',
            component: ShareDevice,
          },
          {
            path: '/shared/customer',
            name: 'shareCustomer',
            component: ShareCustomer,
          },
        ],
      },
      {
        path: '/maintenance',
        name: 'maintenance',
        redirect: 'maintenance/certs',
        component: Maintenance,
        meta: {
          requiresNotClient: true,
        },
        children: [
          {
            path: '/maintenance/certs',
            name: 'cert',
            component: Certificates,
          },
          {
            path: '/maintenance/itv',
            name: 'itv',
            component: Itv,
          },
          {
            path: '/maintenance/frigo_machine',
            name: 'frigo_machine',
            component: FrigoMachine,
          },
        ],
      },
      {
        path: '/reports',
        name: 'reports',
        redirect: 'reports/create',
        props: true,
        component: ReportNavbar,
        meta: {
          requiresNotClient: true,
        },
        children: [
          {
            path: '/reports/create',
            name: 'createReport',
            component: Reports,
            props: true,
          },
          {
            path: '/reports/program',
            name: 'programReport',
            component: ProgramReport,
          },
          {
            path: '/reports/historic',
            name: 'historicReport',
            component: HistoricReports,
          },
        ],
      },
      {
        path: '/admin',
        name: 'admin',
        redirect: 'admin/devices',
        component: Admin,
        meta: {
          requiresAdmin: true,
        },

        children: [
          {
            path: '/admin/beacons',
            name: 'beacons',
            component: Beacons,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: '/admin/beacons/create',
            name: 'createBeacon',
            component: CreateBeacon,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: '/admin/beacons/edit/:mac_address',
            name: 'editBeacon',
            props: true,
            component: EditBeacon,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: '/admin/devices',
            name: 'devices',
            component: Devices,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: '/admin/devices/create',
            name: 'createDevice',
            component: CreateDevice,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: '/admin/devices/edit/:device_sn/certificates',
            name: 'certificate',
            props: true,
            component: CreateCertificate,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: '/admin/devices/edit/:device_sn',
            name: 'editDevice',
            props: true,
            component: EditDevice,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: '/admin/users',
            name: 'users',
            component: Users,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: '/admin/users/create',
            name: 'createUser',
            component: CreateUser,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: '/admin/users/edit/:userId',
            name: 'editUser',
            props: true,
            component: EditUser,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: '/admin/search',
            name: 'search',
            props: true,
            component: Search,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: '/admin/users/change-password/:userId',
            name: 'changeUserPassword',
            props: true,
            component: changeUserPassword,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: '/admin/users/change-password/:userId',
            name: 'editDataUser',
            props: true,
            component: EditDataUser,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: '/admin/companies',
            name: 'companies',
            component: Companies,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: '/admin/companies/new-company',
            name: 'newCompany',
            component: NewCompany,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: '/admin/companies/edit/:companyId',
            name: 'editCompany',
            props: true,
            component: EditCompany,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: '/admin/simcards',
            name: 'simcards',
            component: Simcards,
            meta: {
              requiresAdmin: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/loader',
    name: 'loader',
    props: true,
    component: Loader,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const fetchUserDataIfNull = async (to, next, actionName, fetchUserFunc) => {
  if (to.params.userData == null) {
    const user = await fetchUserFunc(to.params.userId);
    next({
      name: actionName,
      params: { userId: to.params.userId, userData: user },
    });
    return true;
  }
  return false;
};

const fetchEntityDataIfNull = async (to, next, actionName, fetchEntityFunc, entityId) => {
  if (to.params[entityId] == null) {
    const entity = await fetchEntityFunc(to.params[entityId]);
    next({
      name: actionName,
      params: { [entityId]: to.params[entityId], [`${entityId}Data`]: entity },
    });
    return true;
  }
  return false;
};

const actionMap = {
  editUser: async (to, user, next) => fetchUserDataIfNull(to, next, 'editUser', adminViewModel.getUser),
  changeUserPassword: async (to, user, next) => fetchUserDataIfNull(to, next, 'changeUserPassword', adminViewModel.getUser),
  editDataUser: async (to, user, next) => fetchUserDataIfNull(to, next, 'editDataUser', adminViewModel.getUser),
  editCompany: async (to, user, next) => fetchEntityDataIfNull(to, next, 'editCompany', adminViewModel.getCompany, 'companyId'),
  editDevice: async (to, user, next) => {
    const device = await adminViewModel.getDevice(to.params.device_sn);
    next({
      name: 'editDevice',
      params: { device_sn: to.params.device_sn, deviceData: device },
      meta: { requiresAdmin: true },
    });
  },
  certificate: async (to, user, next) => {
    const device = await adminViewModel.getDevice(to.params.device_sn);
    next({ name: 'certificate', params: { device_sn: to.params.device_sn, deviceData: device } });
  },
};

let loopCounter = 0;
router.beforeEach(async (to, from, next) => {
  loopCounter++;

  if (loopCounter > 2) {
    loopCounter = 0;
    return next();
  }

  window.scrollTo(0, 0);

  const user = await userViewModel.getUser();

  const userType = user?.type;
  if (user) {
    const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);

    const requiresNotClient = to.matched.some((record) => record.meta.requiresNotClient);

    if ((requiresAdmin && userType != 'admin') || (requiresNotClient && userType == 'client')) {
      return next({ path: '/dashboard/map' });
    }
  }

  if (to.name === 'login' && (await userViewModel.getUser()) != null) {
    return next({ path: '/' });
  }

  if (!to.meta.allowAnonymous && !(await userViewModel.isUserLogged())) {
    return next({ path: '/login', query: { redirect: to.fullPath } });
  }

  if (actionMap[to.name]) {
    await actionMap[to.name](to, user, next);
  }

  next();
});

export default router;
