const PetitionError = require('../../petitionError');
const { postRaw } = require('../../httpRequester');

class NewPlatformException extends PetitionError {
  constructor(code) {
    super('Unable to send email', code);
  }
}

async function newPlatformPetition(data) {
  const response = await postRaw('users/new_platform', data);
  return response;
}

// class NewPlatformException extends PetitionError {
//   constructor(code) {
//     super('Unable to send email', code);
//   }
// }

module.exports = {
  newPlatformPetition,
  NewPlatformException,
};
