<template>
  <v-col>
    <v-card class="card-range-temperature">
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title> {{ this.$t('dashboard.limits.probe') + ' ' + num }}(Cº) </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-form ref="probe">
          <v-list-item>
            <v-text-field
              class="AAAA"
              v-model.number="setPoint"
              ref="setPoint"
              type="number"
              outlined
              :label="$t('dashboard.configuration.set_point')"
              :rules="tempRules">
            </v-text-field>
          </v-list-item>

          <v-list-item>
            <v-select
              class="AAAA"
              :items="errorRangeItems"
              ref="Error Range"
              :label="$t('dashboard.configuration.error_range')"
              v-model="errorRange"
              outlined></v-select>
          </v-list-item>
          <v-list-item>
            <v-text-field
              :disabled="setPoint == null"
              type=" number"
              class="AAAA"
              v-model="interval"
              :label="$t('dashboard.configuration.interval_in_min')"
              outlined></v-text-field>
          </v-list-item>
        </v-form>
      </v-list>
      <v-btn @click="reset" color="red" class="mx-5 mb-5">
        <span class="white--text">{{ $t('alarms.remove_alarm') }}</span>
      </v-btn>
    </v-card>

    <div v-if="(setPoint || setPoint === 0) && errorRange" class="show-range-temperatures">
      <v-card :style="{ 'background-color': theme === 'dark' ? '#14262b' : '' }" class="show-range-temperatures-vcard mx-5 mt-4">
        <h5>{{ this.$t('dashboard.configuration.set_point') }} : {{ setPoint }}°C</h5>
        <h5>{{ this.$t('dashboard.configuration.error_range') }}: {{ errorRange }}°C</h5>
      </v-card>
      <v-card
        :style="{ 'background-color': theme === 'dark' ? '#14262b' : '' }"
        class="show-range-temperatures-vcard mx-5 mt-4 mb-3">
        <h5 mr-3>
          {{ this.$t('dashboard.configuration.alarm_explanation_1') }}
          {{ maxRange }}
          {{ this.$t('dashboard.configuration.alarm_explanation_2') }}{{ minRange }}°C
        </h5>
      </v-card>
    </div>
  </v-col>
</template>

<script>
const ls = require('localstorage-slim');
import { eventBus } from '../../main';

export default {
  props: {
    num: {
      type: String,
    },
    probe: {
      type: String,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    theme: ls.get('theme') ?? 'ligth',
    interval: 10,
    intervalMax: 180,
    intervalMin: 5,
    menu: null,
    validForm: true,
    formActive: true,
    setPoint: null,
    errorRangeItems: [2, 3, 4, 5],
    errorRange: 2,
    max: 99,
    min: null,
    alarmInter: null,
  }),

  emits: {
    enableButton: null,
    disableButton: null,
  },

  methods: {
    reset() {
      this.$refs.setPoint.reset();
    },
    checkButtonStatus() {
      const isSetPointValid = Number(this.setPoint) <= 99 && Number(this.setPoint) >= -99;
      const isIntervalValid = this.interval <= 180 && this.interval >= 5;
      const isSetPointDefined = this.setPoint !== null && this.setPoint !== '';
      const isIntervalDefined = this.interval !== null && this.interval !== '';

      if (isSetPointValid && isIntervalValid) {
        this.$emit('enableButton');
      } else {
        this.$emit('disableButton');
      }

      if (!isSetPointDefined) this.$emit('emptyErrorRange');

      if (!isIntervalDefined) this.$emit('emptyErrorInterval');

      this.$emit('limits', this.max, this.min, this.num, this.setPoint, this.errorRange, Number(this.interval));
    },
  },

  computed: {
    isNumber() {
      return typeof this.setPoint;
    },

    tempRules() {
      if (Number(this.setPoint) > 99 || Number(this.setPoint) < -99) {
        this.$emit('disableButton');
      }
      return [
        (value) => Number(value) >= -99 || this.$t('dashboard.configuration.temp_min'),
        (value) => Number(value) <= 99 || this.$t('dashboard.configuration.temp_max'),
      ];
    },

    maxRange() {
      return this.setPoint + Number(this.errorRange);
    },

    minRange() {
      return this.setPoint - Number(this.errorRange);
    },
  },

  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
  },

  updated: function () {
    this.max = this.maxRange;
    this.min = this.minRange;
    this.checkButtonStatus();
  },
};
</script>

<style>
label:hover {
  cursor: pointer;
}
h5 {
  font-weight: normal;
}
</style>
