<template>
  <div class="ml-5">
    <div class="user-configuration-container">
      <v-form class="user-configuration-form" ref="userConfigForm" v-model="formValid" lazy-validation>
        <div class="user-configuration-form-title">
          <h1 class="my-5 text-center">
            {{ $t('user.password.title') }}
          </h1>

          <div class="user-configuration-password">
            <div class="user-right user-configuration-password-contain">
              <v-form ref="passwordForm" class="contain-user-edit-password" v-model="formValid" lazy-validation>
                <v-row justify="center">
                  <v-col :cols="isMobile ? 8 : 4">
                    <v-text-field
                      dense
                      outlined
                      class="rounded-lg"
                      type="password"
                      v-model="password"
                      v-bind:hint="$t('user.password.hint')"
                      :rules="passwordRules"
                      :label="$t('user.password.new_password')"
                      :error-messages="passwordIncorrectMessage"
                      required
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (showPassword = !showPassword)"
                      :type="showPassword ? 'password' : 'text'" />
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col :cols="isMobile ? 8 : 4">
                    <v-text-field
                      dense
                      outlined
                      class="rounded-lg"
                      type="password"
                      v-model="confirmPassword"
                      :label="$t('user.password.repeat_password')"
                      v-bind:rules="[password === confirmPassword || $t('user.password.error.passwords_not_match')]"
                      required
                      :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (showConfirmPassword = !showConfirmPassword)"
                      :type="showConfirmPassword ? 'password' : 'text'" />
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col :cols="isMobile ? 8 : 3">
                    <v-btn small color="primary" class="d-inline" @click="savePassword">
                      {{ $t('general.form.savePassword') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </div>
        </div>
        <v-alert v-if="validReport" border="top" color="green lighten-2" prominent type="success" dark @click="closeAlert">
          <!--v-click-outside="closeAlert"-->
          <v-col class="grow"> <v-icon>mdi-emoticon-happy</v-icon> {{ $t('general.form.saved') }} </v-col>
        </v-alert>
      </v-form>
      <!-- <ErrorDialog
        :isActive="errorDialog"
        v-bind:tittleText="$t('general.dialog.error.title')"
        :messageText="errorText"
        v-bind:acceptButtonText="$t('general.dialog.error.accept_button')"
        v-on:accept-dialog="errorDialog = false">
      </ErrorDialog> -->
    </div>
  </div>
</template>

<script>
import { changeUserPasswordByAdmin } from '../../../viewModels/adminViewModel';
import { ChangeUserPasswordByAdminException } from '../../../server/petitions/users/changeUserPasswordByAdmin';
import { getUser } from '../../../viewModels/userViewModel';

export default {
  data: () => ({
    password: '',
    confirmPassword: '',
    passwordIncorrectMessage: '',
    validReport: false,
    success: true,
    phone: '',
    selectedUnit: '',
    emailsInStandBy: [],
    errorDialog: false,
    errorText: '',
    showPassword: String,
    showConfirmPassword: String,
  }),

  beforeDestroy() {
    if (this.emailsInStandBy.length > 0) {
      for (let email of this.emailsInStandBy) {
        this.notificationsEmails.push(email);
      }
    }
  },

  methods: {
    savePassword: async function () {
      if (this.$refs.passwordForm.validate()) {
        try {
          await changeUserPasswordByAdmin(this.userId, this.password);
          this.activateAlert();
        } catch (e) {
          switch (e.code) {
            case ChangeUserPasswordByAdminException.errorSamePassword:
              this.passwordIncorrectMessage = this.$t('user.password.error.password_must_be_different');
              this.password = '';
              this.confirmPassword = '';
              break;
            case ChangeUserPasswordByAdminException.unknownError:
            case ChangeUserPasswordByAdminException.incorrectParameters:
            case ChangeUserPasswordByAdminException.invalidToken:
            case ChangeUserPasswordByAdminException.expiredToken:
              console.error(e);
              this.errorText = this.$t('general.error.unexpected_error');
              this.errorDialog = true;
              break;
            default:
              console.error('CATCH ERROR:', e);
          }
        }
        this.validReport = true;
        console.log(this.password, 'alvaro password enviado ');
        console.log(this.confirmPassword, 'alvaro confirm password enviado');
      }
    },
    activateAlert() {
      setTimeout(() => {
        this.validReport = true;
        this.closeAlert();
      }, 1000);
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    passwordRules() {
      return [
        (value) => !!value || this.$t('user.password.error.empty_password'),
        (value) => (value && value.length >= 6) || this.$t('user.password.error.invalid_password'),
      ];
    },
  },

  mounted: async function () {
    let user = await getUser();
    this.userId = user.userId;
    this.name = user.name;
    this.email = user.email;
    this.notificationsEmails = user.notification_emails || user.notificationsEmails || [];
    this.phone = user.phone;
    this.selectedUnit = user.unit;
  },
};
</script>
