<template>
  <div v-if="!isMobile">
    <v-container class="my-3">
      <v-row>
        <v-col>
          <h1 class="d-flex justify-start">{{ $t('shared.devices') }}</h1>
        </v-col>
        <v-col>
          <v-btn
            :style="{
              color: colorTheme,
              'background-color': backgroundcolorTheme,
            }"
            disabled
            v-if="blockButton"
            @click="openShare"
            class="float-right"
            color="primary"
            ><v-icon class="mr-3">mdi-share</v-icon> {{ $t('shared.max') }}</v-btn
          >

          <v-btn
            v-else
            :style="{
              color: colorTheme,
              'background-color': backgroundcolorTheme,
            }"
            data-cy="shareDevice"
            @click="openShare"
            class="float-right"
            color="primary"
            ><v-icon class="mr-3">mdi-share</v-icon>{{ $t('shared.share') }}</v-btn
          >
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <v-data-table
        :loading="isTableLoading"
        :headers="reportsTableHeader"
        :items="devicesTable"
        hide-default-footer
        :page.sync="page"
        @page-count="pageCount = $event"
        :items-per-page="itemsPerPage"
        class="shared-data-table elevation-1"
        :search="search"
        v-bind:loading-text="$t('general.table.loading')">
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-1" color="error" data-cy="delete" @click.stop="deleteRow(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:top>
          <v-text-field style="width: 100%" v-model="search" :label="$t('shared.search')" prepend-inner-icon=" "></v-text-field>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination :dark="theme === 'dark'" v-model="page" :length="pageCount"></v-pagination>
      </div>
    </v-container>
    <Confirm ref="confirm"></Confirm>
    <SharedDevice
      :devicesTable="devicesTable"
      :isActive="shared"
      v-on:cancel-share="closeShare"
      v-on:result-share="manageResultShare">
    </SharedDevice>

    <v-snackbar v-model="showSnackbar" :timeout="snackbarTimeout" :color="sharedResult === 'success' ? 'green' : 'red'">
      {{ sharedResult ? $t(`shared.share_${sharedResult}`) : '' }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="
            sharedSuccess = '';
            showSnackbar = false;
          ">
          {{ $t('shared.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>

  <!-- Mobile view -->

  <div v-else>
    <v-container class="my-3 shared-device-mobile">
      <v-row>
        <v-col>
          <h1 class="d-flex justify-start">{{ $t('shared.devices') }}</h1>
        </v-col>
        <v-col>
          <v-btn
            :style="{
              color: colorTheme,
              'background-color': backgroundcolorTheme,
            }"
            disabled
            v-if="blockButton"
            class="float-right"
            color="primary"
            ><v-icon :style="{ color: colorTheme }" class="mr-3">mdi-share</v-icon>{{ $t('shared.max') }}</v-btn
          >

          <v-btn
            v-else
            :style="{
              color: colorTheme,
              'background-color': backgroundcolorTheme,
            }"
            @click="openShare"
            class="float-right"
            color="primary"
            ><v-icon :style="{ color: colorTheme }" class="mr-3">mdi-share</v-icon>{{ $t('shared.share') }}</v-btn
          >
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <v-data-table
        :headers="reportsTableHeader"
        :items="devicesTable"
        hide-default-footer
        :page.sync="page"
        @page-count="pageCount = $event"
        :items-per-page="itemsPerPage"
        :search="search"
        v-bind:loading-text="$t('general.table.loading')">
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-1" color="error" @click.stop="deleteRow(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:top>
          <v-text-field style="width: 100%" v-model="search" :label="$t('shared.search')" prepend-inner-icon=" "></v-text-field>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination :dark="theme === 'dark'" v-model="page" :length="pageCount"></v-pagination>
      </div>
    </v-container>
    <Confirm ref="confirm"></Confirm>
    <SharedDevice :isActive="shared" v-on:cancel-share="closeShare" v-on:result-share="manageResultShare"> </SharedDevice>

    <v-snackbar v-model="showSnackbar" :timeout="snackbarTimeout" :color="sharedResult === 'success' ? 'green' : 'red'">
      {{ sharedResult ? $t(`shared.share_${sharedResult}`) : '' }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="
            sharedSuccess = '';
            showSnackbar = false;
          ">
          {{ $t('shared.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style></style>
<script>
import { getUser } from '../../../viewModels/userViewModel';
import { getCustomers } from '../../../viewModels/clientViewModel';
import SharedDevice from '../../../components/shared/shareDevice/sharedDevice.vue';
import { getShared, reset } from '../../../viewModels/devicesViewModel';
import { deleteShared } from '../../../viewModels/devicesViewModel';
import Confirm from '../../../components/confirm.vue';
import { formatDate } from '../../../utils/utils';
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';

export default {
  components: {
    SharedDevice,
    Confirm,
  },

  data: () => ({
    backgroundcolorTheme: ls.get('theme') === 'dark' ? '#ffffff1a !important' : '',
    colorTheme: ls.get('theme') === 'dark' ? 'white !important' : '',
    theme: ls.get('theme') ?? 'ligth',
    shared: false,
    devicesTable: [],
    search: '',
    isTableLoading: false,
    blockButton: false,
    user: null,
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    showSnackbar: false,
    sharedResult: '',
    snackbarTimeout: 4000,
  }),
  methods: {
    openShare() {
      this.shared = true;
    },

    closeShare: function () {
      this.shared = false;
    },

    manageResultShare: function (shareDeviceResult) {
      this.shared = false;
      this.showSnackbar = true;
      if (shareDeviceResult.result === 'SUCCESS' && shareDeviceResult.sharedDevices.length > 0) {
        this.sharedResult = 'success';
      } else {
        this.sharedResult = 'fail';
      }
    },

    deleteRow: async function (item) {
      if (
        await this.$refs.confirm.open(this.$t('shared.question1'), this.$t('shared.question2'), {
          color: 'red',
        })
      ) {
        let indexToDelete = this.devicesTable.indexOf(item);
        this.devicesTable.splice(indexToDelete, 1);
        let res = await deleteShared(item.email, item.device_sn);
        eventBus.$emit('sharedDeviceDeleted');
      }
      this.blockButton = false;
    },
  },
  updated() {
    if (this.user && this.devicesTable.length >= this.user.devices.length * this.getCustomer.length) {
      this.blockButton = true;
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    reportsTableHeader() {
      return [
        {
          text: this.$t('shared.carplate'),
          value: 'name' ? 'name' : 'carplate',
          width: '5rem',
          divider: true,
        },
        {
          text: this.$t('shared.sn'),
          value: 'device_sn',
          width: '5rem',
          divider: true,
        },
        {
          text: this.$t('shared.with'),
          value: 'email',
          width: '5rem',
          divider: true,
        },
        {
          text: this.$t('shared.since'),
          value: 'from',
          width: '5rem',
          divider: true,
        },
        {
          text: this.$t('shared.until'),
          value: 'to',
          width: '5rem',
          divider: true,
        },
        {
          text: '',
          value: 'actions',
          width: '3rem',
          divider: true,
          sortable: false,
        },
      ];
    },
  },

  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
      this.colorTheme = theme === 'dark' ? 'white !important' : '';
      this.backgroundcolorTheme = theme === 'dark' ? '#ffffff1a !important' : '';
    });
    this.isTableLoading = true;
    this.user = await getUser();
    this.getCustomer = await getCustomers();

    reset();
    let sharedDevices = await getShared(this.user.email);
    for (let [index, shared] of sharedDevices.entries()) {
      sharedDevices[index].from = formatDate(sharedDevices[index].from);
      sharedDevices[index].to = formatDate(sharedDevices[index].to);

      for (let device of this.user.devices) {
        if (shared.device_sn == device.device_sn) {
          sharedDevices[index].name = device.name;
        }
      }
    }
    this.devicesTable = sharedDevices;
    this.isTableLoading = false;
  },
};
</script>
