<template>
  <v-card
    :key="device.device_sn"
    class="mx-2 pa-1 no-data-card"
    @click="showInfo(device)"
    :ripple="{ class: `primary--text` }"
    outlined
    :style="isSelectedCard">
    <div class="point" :style="isSelected"></div>

    <v-row class="card-noData-title">
      <v-col class="pb-0">
        <div class="header-noData-device rounded elevation-2 white grey--text font-weight-bold mr-1">
          <div class="header-noData-device-contain d-flex justify-space-between ml-3">
            <span class="header-noData-device-title">{{ carplate }}</span>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="card-noData-label text-sm-caption">
      <v-col class="font-weight-medium pt-2"> </v-col>
    </v-row>

    <v-container class="card-noData-temperature ma-1">
      <span class="card-noData-temperature-text">{{ $t('general.error.no_data') }}</span>
    </v-container>
  </v-card>
</template>

<script>
import { getUser } from '../../../../viewModels/userViewModel';

export default {
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    user: null,
    carplate: '',

    locationClass: '',
    tagClass: 'grey',
  }),
  mounted: async function () {
    this.device.select = false;
    this.user = this.$store.state.currentUser;
    this.getCarPlate();
  },
  methods: {
    showInfo(device) {
      this.$emit('onShowInfo', device);
    },
    getCarPlate() {
      for (let userDevice of this.user.devices) {
        if (userDevice.device_id == this.device.device_id) {
          this.carplate = userDevice.name || this.device.device_data_from_frame.carplate || this.device.device_sn;
        }
      }
    },
  },
  computed: {
    isSelected() {
      if (this.device.select) {
        return {
          width: '5px',
          height: '48px',
          background: '#78909C',
          float: 'left',
          position: 'absolute',
          right: '-14px',
          top: '20px',
          visibility: 'visible',
          'border-radius': '40%',
        };
      }
      return 'width: 94%';
    },
    isSelectedCard() {
      if (this.device.select) {
        return {
          'box-shadow': 'rgb(38, 57, 77) 0px 20px 30px -10px',
        };
      }
    },
  },
};
</script>
