const PetitionError = require('../../petitionError');
const { get } = require('../../httpRequester');

class LoginByTokenException extends PetitionError {
  constructor(code) {
    super('Login By Token', code);
  }
}

async function loginByToken(token) {
  const response = await get('authentication/login_by_token', token);

  if (response.result !== LoginByTokenException.success) {
    localStorage.removeItem('authToken');
    throw new LoginByTokenException(response.result);
  } else {
    return response.user; // TODO transform
  }
}

module.exports = { loginByToken, LoginByTokenException };
