const PetitionError = require('../../petitionError');
const { delRaw } = require('../../httpRequester');

class deleteScheduledReportException extends PetitionError {
  constructor(code) {
    super('Schedule Report', code);
  }
}

async function deleteScheduledReport(report, token, email) {
  // const payload = { report, email };
  const data = await delRaw(`reports/programmedReport/${report}/${email}`, token);
  const response = await data.json();
  return response.response_result;
}

// class deleteScheduledReportException extends PetitionError {
//   constructor(code) {
//     super('Schedule Report', code);
//   }
// }
module.exports = {
  deleteScheduledReport,
  deleteScheduledReportException,
};
