const PetitionError = require('../../petitionError');
const { getAnonymous } = require('../../httpRequester');

class getAllCustomersException extends PetitionError {
  constructor(code) {
    super('Get Notification', code);
  }
}

async function getAllCustomers(user_email, token) {
  const response = await getAnonymous(`clients/get_clients?user_email=${user_email}&token=${token}`);
  if (response.result !== getAllCustomersException.success) {
    return (new getAllCustomersException(response.result));
  }
  return response.clients; // TODO transform
}

// class getAllCustomersException extends PetitionError {
//   constructor(code) {
//     super('Get Notification', code);
//   }
// }

module.exports = {
  getAllCustomers,
  getAllCustomersException,
};
