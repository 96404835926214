const PetitionError = require('../../petitionError');
const { getAnonymous } = require('../../httpRequester');

class GetStartOfTheDayMileageException extends PetitionError {
  constructor(code) {
    super('Get Start Of The Day Mileage', code);
  }
}

async function getStartOfTheDayMileage(device_sn, token) {
  const timezone = new Date().getTimezoneOffset();
  const response = await getAnonymous(
    `devices/get_start_of_the_day_mileage?device_sn=${device_sn}&timezone=${timezone}&token=${token}`,
  );
    // No es anónima, uso el token, pero así puedo mandarle por query el id también.
  if (response.result !== GetStartOfTheDayMileageException.success) {
    throw new GetStartOfTheDayMileageException(response.result);
  } else {
    return response.mileage; // TO DO transform
  }
}

// class GetStartOfTheDayMileageException extends PetitionError {
//   constructor(code) {
//     super('Get Start Of The Day Mileage', code);
//   }
// }

module.exports = {
  getStartOfTheDayMileage,
  GetStartOfTheDayMileageException,
};
