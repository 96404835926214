<template>
  <div>
    <apexcharts height="120" type="rangeBar" :options="chartOptions" :series="series" ref="apexcharts"></apexcharts>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { eventBus } from '../../main';
import { getToken } from '../../viewModels/userViewModel';
import { getAddress } from '../../server/petitions/devices/getGeocode';

const { formatDuration, batchPromises } = require('../../utils/utils');
const VueI18n = require('../../plugins/lang/index');

export default {
  name: 'Chart',
  components: {
    apexcharts: VueApexCharts,
  },
  data: function () {
    return {
      timeline: [],
      chartOptions: {
        noData: {
          text: VueI18n.default.t('admin_menu.no-data'),
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: 'orange',
            fontSize: '25px',
            fontFamily: 'Helvetica',
          },
        },
        chart: {
          type: 'rangeBar',
          animations: {
            speed: 200,
          },
          stackType: '20%',
          // toolbar: { show: false }, // muestra un menu de opciones: aumentar,disminuir, desplazo y menú de descargas
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '65%',
            rangeBarGroupRows: true,
            columnWidth: '70%',
            barHeight: '70%',
          },
        },
        fill: {
          type: 'solid',
          colors: [
            function ({ value, seriesIndex, w }) {
              let name = w.config.series[seriesIndex].name;
              if (name == 'movement') {
                return '#00cc00';
              } else if (name == 'stop') {
                return '#ff3300';
              } else if (name == 'pause') {
                return '#f9a825';
              } else {
                ('#808080');
              }
            },
          ],
        },
        xaxis: {
          forceNiceScale: false,
          categories: ['Status'],
          type: 'datetime',
          position: 'top',
          min: new Date().setHours(0, 0, 0, 0), //timezone
          max: new Date().setHours(23, 59, 0, 0), //timezone
          labels: {
            datetimeUTC: false,
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          onDatasetHover: {
            highlightDataSeries: false,
          },
          fixed: {
            enabled: false,
            position: 'topRight',
            //offsetX: 0,
            //offsetY: 0,
          },
          // enabled: true,
          // enabledOnSeries: undefined,
          // shared: true,
          followCursor: false,
          // intersect: false,
          inverseOrder: true,
          custom: function (opts) {
            //let dateTest = new Date().getTimezoneOffset() * 60 * 1000;
            const date1 = new Date(opts.y1).getTime();
            new Date().setMinutes();
            const date2 = new Date(opts.y2).getTime();
            let formatDate1 = new Date(date1);
            let formatDate2 = new Date(date2);
            let minute = formatDate1.getMinutes();

            if (minute.toString().length < 2) minute = '0' + minute;
            let hour = formatDate1.getHours();
            if (hour.toString().length < 2) hour = '0' + hour;
            let minute2 = formatDate2.getMinutes();
            if (minute2.toString().length < 2) minute2 = '0' + minute2;
            let hour2 = formatDate2.getHours();
            if (hour2.toString().length < 2) hour2 = '0' + hour2;
            if (opts.w.config.series[opts.seriesIndex].name) {
              const type = opts.w.config.series[opts.seriesIndex].name;
              const chart = opts.w.config.series[opts.seriesIndex].data[0].metadata.chartEl;

              if (chart) {
                const charDraw = new google.visualization.LineChart(chart);

                const chartData = opts.w.config.series[opts.seriesIndex].data[0].metadata.chartData;
                charDraw.draw(chartData, {
                  title: `${VueI18n.default.t('alarms.temperature')}`,
                  titleTextStyle: {
                    color: 'rgba(0, 0, 0, 0.87)',
                    fontSize: '14',
                    bold: false,
                  },
                  curveType: 'function',
                  legend: { position: 'right' },
                  //chartArea: { width: '100%', height: '80%' },
                  width: 450,
                  height: 250,
                  vAxis: {
                    viewWindowMode: 'explicit',
                    format: '#,##0.0 °C',
                  },
                  hAxis: {
                    //ticks: hTicks,
                    viewWindowMode: 'explicit',
                    //title: 'HH:mm',
                    format: 'HH:mm',
                  },
                });
              }

              const distance = opts.w.config.series[opts.seriesIndex].data[0].metadata.distance;
              const durationMin = opts.w.config.series[opts.seriesIndex].data[0].metadata.duration;
              const start = opts.w.config.series[opts.seriesIndex].data[0].start;
              const end = opts.w.config.series[opts.seriesIndex].data[0].end;
              const initBlock = `<span> ${VueI18n.default.t('dashboard.start')}: ${hour}:${minute}${
                start ? ' - ' + start : ''
              }</span>`;
              const endBlock = `<span>${VueI18n.default.t('dashboard.end')}: ${hour2}:${minute2}${end ? ' - ' + end : ''}</span>`;
              const stopBlock = `<span>${VueI18n.default.t('dashboard.stop_off')} ${hour}:${minute} a ${hour2}:${minute2}${
                start ? ' - ' + start : ''
              }</span>`;
              const durationBlock = `<span>${VueI18n.default.t('dashboard.duration')}: ${formatDuration(durationMin)}</span>`;

              let distanceBlock = null;

              let tooltip = '';
              if (type == 'movement') {
                // TODO: falta saber las unidades, si son mi o Kms, porngo kms por ahora.
                distanceBlock = `<span>${VueI18n.default.t('dashboard.distance')}: ${distance.toFixed()} kms</span>`;
                tooltip = `<div style="display: grid"> ${initBlock} ${endBlock} ${durationBlock} ${distanceBlock ?? ''} <div>`;
              } else {
                tooltip = `<div style="display: grid"> ${stopBlock} ${durationBlock} ${distanceBlock ?? ''} <div>`;
              }

              if (chart) {
                tooltip += chart.innerHTML;
              }
              return tooltip;
            } else {
              return '';
            }
          },
        },
      },
      series: [],
    };
  },
  methods: {
    focusOnDevice: async function (deviceFocused, dates) {
      this.series = [];
      eventBus.$on('movementLine', async (movements) => {
        this.series = [];
        await movementline(movements);
      });
      eventBus.$on('movementLineRoute', async (locations) => {
        this.series = [];
        await movementline(locations);
      });

      const movementline = async (timeline) => {
        if (timeline.length > 0) {
          let resolveGeocode = false;
          const revGeocodeDict = [];

          if (!resolveGeocode) {
            const revGeocodePromises = [];
            const existingPromises = [];
            timeline.forEach((movement) => {
              const keyPromiseInit = movement.positionInitLat.toString() + movement.positionInitLng.toString();

              if (!existingPromises.includes(keyPromiseInit)) {
                revGeocodePromises.push(getAddress(movement.positionInitLat, movement.positionInitLng, getToken()));
                existingPromises.push(keyPromiseInit);
              }

              const keyPromiseEnd = movement.positionEndLat.toString() + movement.positionEndLng.toString();
              if (!existingPromises.includes(keyPromiseEnd)) {
                revGeocodePromises.push(getAddress(movement.positionEndLat, movement.positionEndLng, getToken()));
                existingPromises.push(keyPromiseEnd);
              }
            });

            //const revGeocodePromisesResults = await Promise.all(revGeocodePromises);
            const revGeocodePromisesResults = await batchPromises(revGeocodePromises, 10, 200);

            revGeocodePromisesResults.forEach((revGeocodePromisesResult) => {
              if (revGeocodePromisesResult?.lat && revGeocodePromisesResult?.lng) {
                const keyRevGeocodeDict = revGeocodePromisesResult.lat.toString() + revGeocodePromisesResult.lng.toString();
                revGeocodeDict[keyRevGeocodeDict] = revGeocodePromisesResult.addressData;
              }
            });
          }
          let i = 0;
          let chartElements = [];
          for (let frame in timeline) {
            // if (timeline[frame].type == 'run') {
            let dateTest = new Date(timeline[frame].init).getTimezoneOffset() * 60 * 1000;
            let dateTest2 = new Date(timeline[frame].end).getTimezoneOffset() * 60 * 1000;
            let y = [new Date(timeline[frame].init).getTime() + dateTest, new Date(timeline[frame].end).getTime() + dateTest2];

            let locationInitGeocode;
            let locationEndGeocode;
            if (!resolveGeocode) {
              const keyRevGeocodeDictInit =
                timeline[frame].positionInitLat.toString() + timeline[frame].positionInitLng.toString();
              const keyRevGeocodeDictEnd = timeline[frame].positionEndLat.toString() + timeline[frame].positionEndLng.toString();

              if (revGeocodeDict[keyRevGeocodeDictInit] && revGeocodeDict[keyRevGeocodeDictEnd]) {
                locationInitGeocode = revGeocodeDict[keyRevGeocodeDictInit];
                locationEndGeocode = revGeocodeDict[keyRevGeocodeDictEnd];
              }
            } else {
              locationInitGeocode = timeline[frame].positionInit;
              locationEndGeocode = timeline[frame].positionEnd;
            }

            let temperatureFrames = timeline[frame].framesFrigo;
            let data;
            if (temperatureFrames.length > 0 && Object.keys(temperatureFrames?.at(0).probes_temperature).length > 0) {
              chartElements[i] = document.createElement('div');

              data = new google.visualization.DataTable();

              let columnName = VueI18n.default.t('reports.types.temp_ticket');
              data.addColumn({ type: 'timeofday', id: 'hours', label: columnName });

              Object.keys(temperatureFrames?.at(0).probes_temperature).forEach((probe) => {
                let translatedProbe = VueI18n.default.t(`device.last_frames.frame_frigo.${probe}`);
                data.addColumn({ type: 'number', id: probe, label: translatedProbe + '\n' });
              });

              let rows = [];
              temperatureFrames.forEach((frame) => {
                let date = new Date(frame.device_timestamp);
                let timeofday = [date.getHours(), date.getMinutes(), date.getSeconds()];
                if (
                  Object.values(frame.probes_temperature).length !=
                  Object.keys(temperatureFrames?.at(0).probes_temperature).length
                ) {
                  return;
                }
                rows.push([timeofday, ...Object.values(frame.probes_temperature)]);
              });
              data.addRows(rows);
            }

            let movement = {
              name: timeline[frame].type,
              data: [
                {
                  x: 'Status',
                  y: y,
                  start: locationInitGeocode?.displayFormatted ?? '',
                  end: locationEndGeocode?.displayFormatted ?? '',
                  metadata: {
                    duration: timeline[frame].duration,
                    distance: timeline[frame].distance,
                    chartEl: chartElements[i] ?? '',
                    chartData: data,
                  },
                },
              ],
            };
            this.series.push(movement);
            i++;
            // }
          }
        } else {
          this.timeline = timeline;
        }
        if (this.series.length > 0) {
          this.chartOptions = {
            ...this.chartOptions,
            ...{
              xaxis: {
                min: new Date(this.series.at(0).data[0].y[0]).setHours(0, 0, 0, 0), //timezone
                max: new Date(this.series.at(-1).data[0].y[1]).setHours(23, 59, 59, 0), //timezone
              },
            },
          };
        }
        this.$refs.apexcharts?.updateOptions(this.chartOptions, true);
      };
    },
  },

  mounted() {
    this.focusOnDevice();
  },
  destroyed() {
    eventBus.$off('movementLineRoute');
    eventBus.$off('movementLine');
  },
};
</script>
