<template>
  <v-container class="pa-0 container-new-company">
    <v-row class="breadcrumbs-row-new-company">
      <v-col>
        <div class="breadcrumbs-container breadcrumbs-container-company">
          <v-row>
            <h1>{{ $t('company.new') }}</h1>
          </v-row>
          <v-row class="breadcrumbs">
            <v-breadcrumbs :items="items" class="pl-0">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                  {{ item.text.toUpperCase() }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <div class="container-create-company">
      <div class="container-create-company-container">
        <CompanyDataForm ref="companyData" forCompany="0" :companyData="companyData" style="width: 70%; margin: auto" />
      </div>

      <!-- <v-divider class="my-10"></v-divider> -->
      <v-row class="d-flex justify-center mt-10 butons-new-company">
        <v-btn color="primary" class="d-flex justify-space-between mx-15" @click="createCompany">
          <v-icon>mdi-content-save</v-icon>{{ $t('general.form.save') }}
        </v-btn>

        <v-btn color="red" class="d-flex justify-space-between mx-15" @click="goBack">
          <v-icon class="white--text mr-1">mdi-exit-to-app</v-icon>
          <span class="white--text">{{ $t('general.form.exit') }}</span>
        </v-btn>
      </v-row>
      <ErrorDialog
        :isActive="errorDialog"
        v-bind:tittleText="$t('general.dialog.error.title')"
        :messageText="errorText"
        v-bind:acceptButtonText="$t('general.dialog.error.accept_button')"
        v-on:accept-dialog="errorDialog = false">
      </ErrorDialog>
    </div>
  </v-container>
</template>

<script>
import router from '../../../../plugins/router/index';
import UserDataForm from '../../../../components/forms/userDataForm/userDataForm.vue';
import CompanyDataForm from '../../../../components/forms/companyDataForm/companyDataForm.vue';
import { createNewCompany } from '../../../../viewModels/adminViewModel';
import { CreateCompanyException } from '../../../../server/petitions/companies/createCompany';
import ErrorDialog from '../../../../components/errorDialog.vue';

export default {
  components: {
    UserDataForm,
    CompanyDataForm,
    ErrorDialog,
  },
  props: {
    companyData: {
      type: Object,
    },
  },
  data: () => ({
    userData: UserDataForm.data,
    errorDialog: false,
    errorText: '',
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '../../../dashboard/temperatures',
      },
      {
        text: 'Empresas',
        disabled: false,
        href: '/admin/companies',
      },
      {
        text: 'Crear Empresa',
        disabled: true,
        href: 'admin/companies/new-company',
      },
    ],
  }),
  methods: {
    goBack: function () {
      router.push({ name: 'companies' });
    },
    goToCreateUsers: async () => {
      router.push({ name: 'createUser' });
    },
    createCompany: async function () {
      try {
        var companyFormData = this.$refs.companyData;

        if (companyFormData.validate()) {
          let company_name = companyFormData.companyName;
          let company_cif = companyFormData.companyCIF;
          let company_phone = companyFormData.companyPhone;
          let company_email = companyFormData.companyEmail;
          let company_notes = companyFormData.companyNotes;

          await createNewCompany(company_name, company_cif, company_phone, company_email, company_notes);

          this.goToCreateUsers();
        }
      } catch (e) {
        switch (e.code) {
          case CreateCompanyException.unknownError:
          case CreateCompanyException.incorrectParameters:
          case CreateCompanyException.invalidToken:
          case CreateCompanyException.expiredToken:
            console.error(e);
            this.errorText = this.$t('general.error.unexpected_error');
            this.errorDialog = true;
            break;
          case CreateCompanyException.errorUserEmailAlreadyInUse:
            this.errorText = this.$t('user.add_modify.error.email_already_in_use');
            this.errorDialog = true;
            break;
          case CreateCompanyException.cifAlreadyInUser:
            this.errorText = this.$t('user.add_modify.error.cif_already_in_use');
            this.errorDialog = true;
            break;
          case CreateCompanyException.companyAlreadyExists:
            this.errorText = this.$t('company.add_modify.cif_already_in_company');
            this.errorDialog = true;
            break;
          default:
            console.error('CATCH ERROR:', e);
        }
      }
    },
  },
};
</script>
