<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
      persistent
    >
    
      <v-card >
       <v-card-title   class="text-h5 amber darken-4">
         {{ $t("user.form.restricted_account") }}
        </v-card-title>

        <v-card-text>
           
            {{ $t("user.form.restricted_account_info") }}
        </v-card-text>


        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="logOut"
          >
             {{ $t("nav_bar.menu.logout") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import router from "../plugins/router/index"
import userViewModel from "../viewModels/userViewModel";
import devicesViewModel from "../viewModels/devicesViewModel";
import adminViewModel from "../viewModels/devicesViewModel";
import reportsViewModel from "../viewModels/reportsViewModel";
export default {
  data: () => ({
    dialog: true,
    psw: '',
    confirmPassword:'',
    validForm:false,
  }),

methods:{
   logOut: async function() {
        await userViewModel.logout();
        await devicesViewModel.reset();
        await adminViewModel.reset();
        await reportsViewModel.reset()
        router.push({ path: "login" });
      },

},
  

};
</script>