const PetitionError = require('../../petitionError');
const { getAnonymous } = require('../../httpRequester');

class GetAllBeaconsException extends PetitionError {
  constructor(code) {
    super('Get Beacon', code);
  }
}

async function getBeacon(token, mac_address) {
  try {
    const response = await getAnonymous(`beacons/${mac_address}?token=${token}`);

    if (response.result === GetAllBeaconsException.success) {
      return response.beacon;
    }
    throw new GetAllBeaconsException(response.result);
  } catch (error) {
    console.error(error);
  }
}

module.exports = { getBeacon, GetAllBeaconsException };
