const PetitionError = require('../../petitionError');
const { getAnonymous } = require('../../httpRequester');

class GetAllDevicesException extends PetitionError {
  constructor(code) {
    super('Get Device', code);
  }
}

async function getDevice(token, device_sn) {
  try {
    const response = await getAnonymous(`devices/device?token=${token}&device_sn=${device_sn}`);

    if (response.result === GetAllDevicesException.success) {
      return response.device;
    }
    throw (new GetAllDevicesException(response.result));
  } catch (error) {
    console.error(error);
  }
}

// class GetAllDevicesException extends PetitionError {
//   constructor(code) {
//     super('Get Device', code);
//   }
// }

module.exports = {
  getDevice,
  GetAllDevicesException,
};
