<template>
  <v-form
    class="poiDataFormSize"
    :style="`padding: 2em;  border-radius:10px;  background-color: ${theme === 'dark' ? ' grey' : 'white'};`"
    ref="poiDataFormOnMap"
    lazy-validation>
    <v-row>
      <v-col cols="12">
        <div>
          <v-text-field autofocus v-model="value.name" :label="$t('shared.name')" :rules="validations.nameRules"> </v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field v-model="value.address" :label="$t('shared.address')" :rules="validations.addressRules"> </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="value.latitude"
          :label="$t('device.last_frames.frame_gps.latitude')"
          :rules="validations.latitudeRules"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="value.longitude"
          :label="$t('device.last_frames.frame_gps.longitude')"
          :rules="validations.longitudeRules"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field v-model="value.radius" :label="$t('dashboard.poi_radius')" :rules="validations.radiusRules" type="number">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          v-model="value.poi_category"
          :disabled="isUpdating"
          :items="poiCategories"
          color="blue-grey lighten-2"
          :label="$t('dashboard.pois_categories.category')"
          item-text="name"
          item-value="_id">
          <template v-slot:selection="data">
            <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="clear()">
              <v-avatar left>
                <v-img :src="data.item.icon"></v-img>
              </v-avatar>
              {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template>
              <v-list-item-avatar>
                <img :src="data.item.icon" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-btn color="red darken-1" text @click="close"> {{ $t('general.dialog.confirmation.button.cancel') }} </v-btn>
    <v-btn color="green darken-1" text @click="save"> {{ $t('general.form.save') }} </v-btn>
  </v-form>
</template>
<script>
import { eventBus } from '../../../main';
import { getToken, getUser } from '../../../viewModels/userViewModel';
import { getAllPOICategories } from '../../../server/petitions/pois/getAllPOICategories';
import { omit } from '../../../utils/utils';
import { postPOI } from '../../../server/petitions/pois/postPOI';
import { getAllPOIS } from '../../../server/petitions/pois/getAllPOIS';

const ls = require('localstorage-slim');

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data: (instance) => ({
    theme: ls.get('theme') ?? 'light',
    allUserPois: null,
    currentUser: {},
    emptyCategory: {
      icon: '/assets/markers/pi.png',
    },
    poiCategories: [],
    isUpdating: false,
    defaultIcon: '/assets/markers/pi.png',
  }),
  methods: {
    async savePOI() {
      if (this.value.poi_category?._id) {
        this.value.categoryId = this.value.poi_category?._id;
      } else {
        this.value.categoryId = this.value.poi_category;
      }

      const poi = { ...omit(this.value, 'category'), userId: this.currentUser.userId };

      const resultCreatedPOI = await postPOI(this.token, poi);

      this.value = resultCreatedPOI.poi;

      if (this.value.isCreated) eventBus.$emit('savePoiDataFormOnMap');
    },
    save() {
      if (this.$refs.poiDataFormOnMap.validate()) {
        this.savePOI();

        this.close();
      }
    },
    close() {
      eventBus.$emit('closePoiDataFormOnMap');
    },
    reset() {
      this.$refs.poiForm?.resetValidation();
    },
    clear() {
      this.value.categoryId = '';
    },
    isFormValid() {
      return this.$refs.poiForm.validate();
    },
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
  },
  mounted: async function () {
    this.currentUser = this.$store.state.currentUser ?? (await getUser());
    this.token = getToken();

    this.allUserPois = await getAllPOIS(this.token, this.currentUser.userId);

    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
      this.close();
    });

    const poiCategoriesFromDb = await getAllPOICategories(this.token, this.currentUser.userId);

    this.poiCategories = poiCategoriesFromDb.map((poiCategoryFromDb) => {
      if (!poiCategoryFromDb.icon) poiCategoryFromDb.icon = this.defaultIcon;

      return poiCategoryFromDb;
    });
  },
  created: function () {
    eventBus.$on('reloadPois', async () => {
      const poiCategoriesFromDb = await getAllPOICategories(this.token, this.currentUser.userId);

      this.poiCategories = poiCategoriesFromDb.map((poiCategoryFromDb) => {
        if (!poiCategoryFromDb.icon) poiCategoryFromDb.icon = this.defaultIcon;
        return poiCategoryFromDb;
      });
    });
  },
  computed: {
    validations() {
      const addressRules = [];
      if ((this.value.longitude == '' && this.value.latitude == '') || (this.value.longitude == 0 && this.value.latitude == 0)) {
        addressRules.push((value) => !!value || this.$t('general.error.empty_value'));
      }

      return {
        nameRules: [
          (value) => !!value || this.$t('general.error.empty_value'),
          (value) => (value && value.length <= 18) || this.$t('dashboard.poi_name_length'),
        ],
        radiusRules: [
          (value) => (!isNaN(parseFloat(value)) && value >= 10 && value <= 999) || this.$t('general.error.must_be_number'),
        ],
        addressRules,
      };
    },
  },
};
</script>

<style>
/* SI QUITO LA CLASE POR COMPLETO AL v-form, se adapta al tamaño del form */
@media only screen and (min-width: 768px) {
  .poiDataFormSize {
    height: 40.5em;
  }
}

/* Pantallas grandes de escritorio */
@media only screen and (min-width: 1500px) {
  .poiDataFormSize {
    height: 51.8em;
  }
}
</style>
