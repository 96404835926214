const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class SaveConfigDeviceException extends PetitionError {
  static serialNumberAlreadyInDevice = 'SERIALNUMBER_ALREADY_IN_DEVICE';

  constructor(code) {
    super('Save Config', code);
  }
}

async function saveConfigDevice(token, device_sn, config, user_id) {
  const payload = {
    config,
    device_sn,
    user_id,
  };

  const response = await post('devices/save_config', payload, token);
  if (response.result !== SaveConfigDeviceException.success) {
    return (new SaveConfigDeviceException(response.result));
  }
  return response; // TODO transform
}

// class SaveConfigDeviceException extends PetitionError {
//   static serialNumberAlreadyInDevice = 'SERIALNUMBER_ALREADY_IN_DEVICE';

//   constructor(code) {
//     super('Save Config', code);
//   }
// }

module.exports = {
  saveConfigDevice,
  SaveConfigDeviceException,
};
