const PetitionError = require('../../petitionError');
const { getAnonymous } = require('../../httpRequester');

class getAllAlarmsException extends PetitionError {
  constructor(code) {
    super('Get Notification', code);
  }
}

async function getAllAlarms(email, token) {
  const response = await getAnonymous(`alarm?email=${email}&token=${token}`);
  if (response.result !== getAllAlarmsException.success) {
    return new getAllAlarmsException(response.result);
  }
  return response.alarms; // TODO transform
}

module.exports = {
  getAllAlarms,
  getAllAlarmsException,
};
