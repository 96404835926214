<template>
  <v-card class="elevation-2 ma-2">
    <v-card-text class="pt-0">
      <!-- <div class="text-overline font-weight-bold">{{ $t('dashboard.details.cold_machine') }} - {{ frigoMachineType }}</div> -->
      <div class="text-overline font-weight-bold">{{ $t('dashboard.details.cold_machine') }}</div>

      <v-divider class="py-1 mx-2" style="border-color: #607d8b"></v-divider>

      <div class="ml-1 mb-2 d-flex flex-column">
        <span class="mb-1">
          <v-icon :class="checkStatus" x-small> mdi-circle </v-icon>
          {{ coldMachineTimestamp }}
        </span>
      </div>

      <v-row v-if="getTemps" class="pt-5">
        <v-col>
          <v-row v-if="canChangeSetpoint" class="justify-space-around">
            <v-chip
              label
              small
              class="justify-center ma-1 white--text blue-grey lighten-1"
              :style="{
                backgroundColor: colorSetpoint,
                color: pretrip_received ? 'white !important' : '',
                width: '50%',
              }">
              <span> {{ $t('dashboard.configuration.modify_setpoint') }}{{ tempSetpoint }} </span>
              <span @click="openModalSetPoint('setpoint')">
                <div>
                  <v-icon
                    :style="{
                      color: theme === 'dark' ? 'white !important' : '',
                      margin: '0.2em',
                    }"
                    >mdi-pencil-outline</v-icon
                  >
                </div>
              </span>
            </v-chip>
            <v-chip
              label
              small
              class="justify-center ma-1 blue-grey lighten-4"
              :style="{
                backgroundColor: colorPretrip,
                color: pretrip_received && pretrip ? 'white !important' : '',
                margin: '0.2em',
                width: '35%',
              }">
              <span style="font-size: 11px" @click="openModalSetPoint('pretrip')">
                <div>
                  {{ $t('dashboard.configuration.pretrip') }} {{ tempP1 }}
                  <v-icon
                    :style="{
                      color: theme === 'dark' ? 'white !important' : '',
                      margin: '0.2em',
                    }"
                    >mdi-pencil-outline</v-icon
                  >
                </div>
              </span>
            </v-chip>

            <v-divider class="py-2 mx-6 mt-3" style="border-color: #607d8b"></v-divider>
          </v-row>

          <v-row class="justify-space-around" v-for="[tempKey, value] in getTemps" v-bind:key="tempKey">
            <v-chip label small class="justify-center ma-1 white--text blue-grey lighten-1" style="width: 50%">
              {{ $t(`dashboard.details.${tempKey}`) }}
            </v-chip>
            <v-chip label small class="justify-center ma-1 blue-grey lighten-4" style="width: 35%">
              <span v-if="tempKey == 'temp_setpoint'" @click="openModalSetPoint">
                <div>
                  <span>{{ value }}ºC</span>
                </div>
              </span>
              <span v-if="tempKey !== 'temp_setpoint'">{{ value }}ºC </span>
            </v-chip>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col style="text-align: center">
          <v-divider class="py-1 mx-2" style="border-color: #607d8b"></v-divider>
          <v-chip label small :color="getMachineStatus ? 'green' : 'grey'">
            <span style="color: white"> {{ getMachineStatus ? $t('dashboard.details.on') : $t('dashboard.details.off') }} </span>
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { getUser } from '../../../../viewModels/userViewModel';
import { eventBus } from '../../../../main';

const ls = require('localstorage-slim');

export default {
  components: {},
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
    status: {
      type: Object,
    },
    coldMachineTimestamp: {
      type: String,
    },
  },
  data: () => ({
    pretrip: false,
    pretrip_received: false,
    timeout: false,
    firstCANCol: [],
    secondCANCol: [],
    canChangeSetpoint: false,
    theme: ls.get('theme') ?? 'ligth',
    tempP1: '',
    colorPretrip: '',
    colorSetpoint: '',
    tempSetpoint: '',
    colorImportant: 'rgb(65, 172, 86) !important',
  }),
  computed: {
    frameFrigoMachine() {
      return this.device?.last_frames?.frame_frigo_machine;
    },
    frigoMachine() {
      return this.frameFrigoMachine?.frigo_machine;
    },
    frigoMachineType() {
      return this.frigoMachine?.machine_type;
    },
    checkStatus() {
      return this.status.coldMachine ? 'green--text' : 'gray--text';
    },
    getTemps() {
      let temps = Object.keys(this.frigoMachine).filter((key) => key.startsWith('temp_'));

      return Object.entries(this.frigoMachine).filter(([key, value]) => temps.includes(key));
    },
    getMachineStatus() {
      return this.frigoMachine?.machine_status?.machine_on ?? false;
    },
  },
  methods: {
    openModalSetPoint(options) {
      // TODO
      if (options === 'pretrip') {
        eventBus.$emit('isPretrip');
      }
      if (this.canChangeSetpoint) this.$emit('openModalSetPoint');
    },
  },
  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
    eventBus.$on('pretrip-received', (setpoint, isPretrip) => {
      this.pretrip_received = true;

      if (isPretrip) {
        this.colorPretrip = '';
        this.tempP1 = Number(setpoint);

        setTimeout(() => {
          if (isPretrip) this.tempP1 = Number(setpoint) + 2 + 'ºC';
          this.pretrip = isPretrip;
          this.colorPretrip = this.pretrip_received && this.pretrip ? this.colorImportant : '';
        }, 10000);
      }

      if (!isPretrip) {
        this.colorSetpoint = '';
        this.tempSetpoint = Number(setpoint);

        setTimeout(() => {
          if (!isPretrip) this.tempSetpoint = Number(setpoint) + 'ºC';
          this.colorSetpoint = this.pretrip_received ? this.colorImportant : '';
        }, 5000);
      }
    });

    const user = await getUser();
    this.canChangeSetpoint = user.restrictions.features.change_setpoint === false ? true : false;
    this.getTemps;
    if (this.device) {
      this.getNumberOfColums;
    }
  },
};
</script>
