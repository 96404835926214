const PetitionError = require("../../petitionError");
const { del } = require("../../httpRequester");

class DeleteUserException extends PetitionError {
    static userSharesDevices = "USER_SHARES_DEVICES";

    constructor(code) {
        super("Delete User", code);
    }
}

async function deleteUser(user_id, mode, token) {
    const params = {
        token,
        id: user_id,
        mode,
    };
    const response = await del("users/", params);

    if (response.result !== DeleteUserException.success) {
        throw new DeleteUserException(response.result);
    } else {
        return response.user; // TODO transform
    }
}

// class DeleteUserException extends PetitionError {
//   static userSharesDevices = 'USER_SHARES_DEVICES';

//   constructor(code) {
//     super('Delete User', code);
//   }
// }

module.exports = {
    deleteUser,
    DeleteUserException,
};
