const PetitionError = require('../../petitionError');
const { getAnonymous } = require('../../httpRequester');

class getAllBeaconsException extends PetitionError {
  constructor(code) {
    super(' Get All Beacons ', code);
  }
}

async function getAllBeacons(token) {
  const response = await getAnonymous(`beacons/?token=${token}`);

  if (response.result !== getAllBeaconsException.success) {
    throw new getAllBeaconsException(response.result);
  } else {
    return response.beacons;
  }
}

module.exports = { getAllBeaconsPetition: getAllBeacons, getAllBeaconsException };
