import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faMapLocationDot,
  faTemperatureDown,
  faClipboardCheck,
  faDroplet,
  faRoute,
  faMobileScreenButton,
  faTemperatureQuarter,
  faTemperatureThreeQuarters,
  faChartColumn,
  faReceipt,
  faTemperatureEmpty,
  faKey,
  faCarBattery,
  faDoorOpen,
  faStar,
  faWind,
  faScrewdriverWrench,
  faCalendarCheck,
  faExclamation,
  faFileContract,
  faCircleExclamation,
  faChevronDown,
  faChevronUp,
  faPowerOff,
  faCloudArrowDown,
  faTrash,
  faUpRightFromSquare,
  faPenToSquare,
  faUserPlus,
  faEnvelope,
  faFilePdf,
  faFileCsv,
  faXmarkCircle,
  faCircleInfo,
  faSquareShareNodes,
  faHouse,
  faUserShield,
  faShareNodes,
  faCircleArrowDown,
  faCircleQuestion,
  faDownload,
  faFileZipper,
  faBell,
  faUser,
  faCheck,
  faPhone,
  faPhoneVolume,
  faBook,
  faRoadLock,
  faFileExcel,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faTelegram } from '@fortawesome/free-brands-svg-icons';

library.add(
  faUser,
  faBell,
  faFileZipper,
  faDownload,
  faCircleArrowDown,
  faCircleQuestion,
  faShareNodes,
  faSquareShareNodes,
  faUserShield,
  faMapLocationDot,
  faTemperatureDown,
  faClipboardCheck,
  faDroplet,
  faRoute,
  faMobileScreenButton,
  faTemperatureQuarter,
  faTemperatureThreeQuarters,
  faChartColumn,
  faReceipt,
  faTemperatureEmpty,
  faKey,
  faCarBattery,
  faDoorOpen,
  faStar,
  faWind,
  faScrewdriverWrench,
  faCalendarCheck,
  faExclamation,
  faFileContract,
  faCircleExclamation,
  faChevronDown,
  faChevronUp,
  faPowerOff,
  faCloudArrowDown,
  faTrash,
  faUpRightFromSquare,
  faPenToSquare,
  faUserPlus,
  faEnvelope,
  faFilePdf,
  faFileCsv,
  faXmarkCircle,
  faCircleInfo,
  faHouse,
  faCheck,
  faPhone,
  faPhoneVolume,
  faBook,
  faRoadLock,
  faFileExcel,
  faTelegram,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
