<template>
  <div id="dashboard-temperatures-scroll-overflow">
    <div v-if="!selectedDevice" id="device-not-selected" class="ma-5 justify-center mx-auto temperatures-screen" max-width="400">
      <div id="title">
        <div id="text-title">
          <v-row class="justify-center">
            <p class="text-md-h4 font-weight-bold text-center">
              {{ $t('thermography.demo') }}
            </p>
          </v-row>
        </div>
      </div>

      <div id="cards-temperatures">
        <div class="cards-temperatures-subtitle">
          <v-row v-if="!isMobile" class="d-flex justify-center ma-2 text-caption">
            S/N: {{ this.selectedDevice ? this.selectedDevice.device_sn : '000000' }}
          </v-row>
          <v-row v-else class="d-flex justify-center ma-3 text-caption">
            S/N: {{ this.selectedDevice ? this.selectedDevice.device_sn : '000000' }}
          </v-row>
        </div>

        <Temperatures
          :p1="this.selectedDevice ? this.selectedDevice.last_frames.frame_frigo?.probes_temperature.p1 + 'ºC' : '-15.3ºC'"
          :p2="this.selectedDevice ? this.selectedDevice.last_frames.frame_frigo?.probes_temperature.p2 + 'ºC' : '-16.3ºC'"
          :p3="this.selectedDevice ? this.selectedDevice.last_frames.frame_frigo?.probes_temperature.p3 + '% RH' : '61% RH'"
          :p4="this.selectedDevice ? this.selectedDevice.last_frames.frame_frigo?.probes_temperature.p4 + '% RH' : '43% RH'" />
      </div>

      <v-row class="d-flex justify-center ma-2 text-center text-caption">
        {{ $t('thermography.explanation') }}
      </v-row>

      <v-row class="d-flex justify-center mt-2 mb-2" id="indicators">
        <v-tooltip bottom class="indicator-button-demo">
          <template v-slot:activator="{ on, attrs }">
            <font-awesome-icon icon="fa-solid fa-key" v-bind="attrs" v-on="on" class="indicator-icon-demo indicator-icon-key" />
          </template>
          <span> {{ $t('thermography.contact') }}</span>
        </v-tooltip>

        <v-tooltip bottom class="indicator-button-demo">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="indicator-icon-demo indicator-icon-batery"> mdi-engine </v-icon>
          </template>
          <span> {{ $t('thermography.fridge') }}</span>
        </v-tooltip>

        <v-tooltip bottom class="indicator-button-demo">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="indicator-icon-demo indicator-icon-batery"> mdi-link-variant </v-icon>
          </template>
          <span> {{ $t('thermography.beacon') }}</span>
        </v-tooltip>

        <v-tooltip bottom class="indicator-button-demo">
          <template v-slot:activator="{ on, attrs }">
            <font-awesome-icon
              icon="fa-solid fa-door-open"
              v-bind="attrs"
              v-on="on"
              class="indicator-icon-demo indicator-icon-door" />
          </template>
          <span> {{ $t('thermography.door') }}</span>
        </v-tooltip>

        <v-tooltip bottom class="indicator-button-demo">
          <template v-slot:activator="{ on, attrs }">
            <font-awesome-icon
              icon="fa-solid fa-star"
              v-bind="attrs"
              v-on="on"
              class="indicator-icon-demo indicator-icon-custom" />
          </template>
          <span>
            {{ $t('thermography.custom') }}
          </span>
        </v-tooltip>

        <v-tooltip bottom class="indicator-button-demo">
          <template v-slot:activator="{ on, attrs }">
            <font-awesome-icon
              icon="fa-solid fa-droplet"
              v-bind="attrs"
              v-on="on"
              class="indicator-icon-demo indicator-icon-humedity" />
          </template>
          <span>
            {{ $t('thermography.humidity') }}
          </span>
        </v-tooltip>

        <v-tooltip bottom class="indicator-button-demo">
          <template v-slot:activator="{ on, attrs }">
            <font-awesome-icon icon="fa-solid fa-wind" v-bind="attrs" v-on="on" class="indicator-icon-demo indicator-icon-air" />
          </template>
          <span> {{ $t('thermography.quality') }}</span>
        </v-tooltip>

        <v-tooltip bottom class="indicator-button-demo">
          <template v-slot:activator="{ on, attrs }">
            <font-awesome-icon
              icon="fa-solid fa-temperature-three-quarters"
              v-bind="attrs"
              v-on="on"
              class="indicator-icon-demo indicator-template-temperature" />
          </template>
          <span>
            {{ $t('thermography.probes') }}
          </span>
        </v-tooltip>

        <v-tooltip bottom class="indicator-button-demo">
          <template v-slot:activator="{ on, attrs }">
            <font-awesome-icon
              icon="fa-solid fa-screwdriver-wrench"
              v-bind="attrs"
              v-on="on"
              class="indicator-icon-demo indicator-icon-mantenance" />
          </template>
          <span>
            {{ $t('thermography.mantenance_frigo') }}
          </span>
        </v-tooltip>

        <v-tooltip bottom class="indicator-button-demo">
          <template v-slot:activator="{ on, attrs }">
            <font-awesome-icon
              icon="fa-solid fa-calendar-check"
              v-bind="attrs"
              v-on="on"
              class="indicator-icon-demo indicator-icon-calendar" />
          </template>
          <span>
            {{ $t('thermography.mantenance') }}
          </span>
        </v-tooltip>
      </v-row>

      <div id="img" class="mt-5 mb-5 d-flex justify-center">
        <div v-if="!isMobile">
          <v-img
            max-width="95%"
            class="img-demostration img-auto"
            :src="require('../../../../public/assets/fakeTemps.png')"></v-img>
        </div>
        <div v-else class="img-mobile">
          <v-img
            max-width="95%"
            class="img-demostration img-auto"
            :src="require('../../../../public/assets/fakeTemps.png')"></v-img>
        </div>
      </div>
    </div>

    <div
      v-else
      id="selected-device"
      @change="focusOnDevice(selectedDevice)"
      class="ma-5 justify-center mx-auto temperatures-screen select-device-mobile"
      max-width="400"
      data-cy="selected-device">
      <div v-if="!isMobile" id="selected-device-desktop">
        <div v-if="!isTraker" id="selected-device-desktop-isntracker">
          <div id="title">
            <div v-if="!isMobile" id="text-title">
              <v-row class="justify-center">
                <p class="text-md-h4 font-weight-bold text-center">
                  {{ getCarplate }}
                </p>
              </v-row>
            </div>
          </div>
          <Certs ref="certModal" v-on:cancel-dialog="closeModalCerts" :deviceData="selectedDevice ?? ''" />

          <div id="cards-temperatures">
            <div class="cards-temperatures-subtitle">
              <v-row v-if="!isMobile" class="d-flex justify-center ma-2 text-caption">
                S/N: {{ this.selectedDevice ? this.selectedDevice.device_sn : '000000' }}
              </v-row>
              <v-row v-else class="d-flex justify-center ma-3 text-caption">
                S/N: {{ this.selectedDevice ? this.selectedDevice.device_sn : '000000' }}
              </v-row>
              <div class="frigo-time-stamp d-flex">
                <v-icon small :class="isFrigoActive" class="icon-frigo-active"> mdi-snowflake </v-icon>
                <div>{{ getFrigoTimestampTooltip }}</div>
              </div>
            </div>

            <v-row class="sub-menu mb-2 group pa-2">
              <!-- Dejar comentado hasta arreglar el duplicado de dispositivos !important -->
              <!-- En modo móvil funciona  -->
              <!-- <v-btn class="btn-submenu mx-2 fab dark small color='white" @click="mapView">
                <font-awesome-icon class="btn-submenu-icon icon-btn-location" icon="fa-solid fa-map-location-dot" />
                <span class="text-nav-bar">{{ $t('nav_bar.map') }}</span>
              </v-btn> -->

              <v-btn
                class="btn-submenu mx-2 fab dark small color='white"
                v-if="selectedDevice.last_frames.frame_frigo"
                @click="openTicketDialog">
                <font-awesome-icon class="btn-submenu-icon icon-btn-receipt" icon="fa-solid fa-receipt" />
                <span class="text-nav-bar">{{ $t('dashboard.device_card.ticket') }}</span>
              </v-btn>
              <v-btn v-else disabled class="btn-submenu mx-2 fab dark small color='white">
                <font-awesome-icon class="btn-submenu-icon icon-btn-receipt" icon="fa-solid fa-receipt" />
                <span class="text-nav-bar">{{ $t('dashboard.device_card.ticket') }}</span>
              </v-btn>
              <v-btn
                class="btn-submenu mx-2 fab dark small color='white"
                v-show="this.selectedDevice?.certificate?.length > 0"
                @click="certificateView">
                <font-awesome-icon class="btn-submenu-icon icon-btn-location" icon="fa fa-book" />
                <span class="text-nav-bar">{{ $t('dashboard.device_card.certificates') }}</span>
              </v-btn>
            </v-row>

            <v-row id="content-temperatures">
              <div class="ma-2 d-flex justify-center contain-temp-card" style="display: flex !important; width: 100%">
                <div v-for="(row, index) in probes" v-bind:key="row.key">
                  <v-col class="tooltip text-center" v-if="result[row.probe]">
                    <v-sheet
                      class="temperature-card"
                      elevation="1"
                      rounded
                      :class="myclass"
                      :style="{
                        width: '5em',
                        height: '3rem',
                        fontSize: '16px',
                        backgroundColor: backgroundcolorTheme,
                      }">
                      <div id="temperature-card-content">
                        <span :style="{ color: colorTheme }" class="tooltiptext" data-cy="tooltiptext">{{ row.name }} </span>
                        <div :style="{ color: colorTheme }" id="temp-ll">
                          {{ result[row.probe] }}
                        </div>
                      </div>
                    </v-sheet>
                  </v-col>
                </div>
              </div>
            </v-row>
          </div>

          <v-row class="d-flex justify-center mt-2 mb-2" id="indicators">
            <v-tooltip bottom class="indicator-button">
              <template v-slot:activator="{ on, attrs }" class="indicator-template">
                <span class="indicator" :class="isContactOn">
                  <font-awesome-icon icon="fa-solid fa-key" v-bind="attrs" v-on="on" class="indicator-icon indicator-icon-key" />
                </span>
              </template>
              <span>{{ $t('dashboard.temp.alarms.ignition') }}</span>
            </v-tooltip>

            <v-tooltip bottom class="indicator-button">
              <template v-slot:activator="{ on, attrs }" class="indicator-template">
                <span class="indicator" :class="isEngineOn">
                  <v-icon v-bind="attrs" v-on="on" class="indicator-icon indicator-icon-batery"> mdi-engine </v-icon>
                </span>
              </template>
              <span>{{ $t('dashboard.temp.alarms.engine') }}</span>
            </v-tooltip>

            <v-tooltip bottom class="indicator-button">
              <template v-slot:activator="{ on, attrs }" class="indicator-template">
                <span class="indicator" :class="isBeaconNearby">
                  <v-icon v-bind="attrs" v-on="on" class="indicator-icon indicator-icon-batery"> mdi-link-variant </v-icon>
                </span>
              </template>
              <span>{{ $t('dashboard.temp.alarms.beacon_nearby') }}</span>
            </v-tooltip>

            <v-tooltip bottom class="indicator-button">
              <template v-slot:activator="{ on, attrs }" class="indicator-template">
                <span class="indicator" :class="isDoorOpen">
                  <font-awesome-icon
                    icon="fa-solid fa-door-open"
                    v-bind="attrs"
                    v-on="on"
                    class="indicator-icon indicator-icon-door" />
                  <font-awesome-icon icon="fa-solid fa-exclamation" class="indicador-exclamation" />
                </span>
              </template>
              <span>{{ $t('dashboard.temp.alarms.door') }}</span>
            </v-tooltip>

            <v-tooltip bottom class="indicator-button">
              <template v-slot:activator="{ on, attrs }" class="indicator-template">
                <span class="indicator" :class="isCustomOn">
                  <font-awesome-icon
                    icon="fa-solid fa-star"
                    v-bind="attrs"
                    v-on="on"
                    class="indicator-icon indicator-icon-custom" />
                </span>
              </template>
              <span>{{ $t('dashboard.temp.alarms.custom') }}</span>
            </v-tooltip>

            <v-tooltip bottom class="indicator-button">
              <template v-slot:activator="{ on, attrs }" class="indicator-template">
                <span class="indicator" :class="isAlarmOneOn">
                  <font-awesome-icon
                    icon="fa-solid fa-temperature-three-quarters"
                    v-bind="attrs"
                    v-on="on"
                    class="indicator-icon indicator-template-temperature" />
                  <font-awesome-icon icon="fa-solid fa-exclamation" class="indicador-exclamation" />
                </span>
              </template>
              <span> {{ $t('thermography.probe1') }}</span>
            </v-tooltip>

            <v-tooltip bottom class="indicator-button">
              <template v-slot:activator="{ on, attrs }" class="indicator-template">
                <span class="indicator" :class="isAlarmTwoOn">
                  <font-awesome-icon
                    icon="fa-solid fa-temperature-three-quarters"
                    v-bind="attrs"
                    v-on="on"
                    class="indicator-icon indicator-template-temperature" />
                  <font-awesome-icon icon="fa-solid fa-exclamation" class="indicador-exclamation" />
                </span>
              </template>
              <span>{{ $t('thermography.probe2') }}</span>
            </v-tooltip>

            <v-tooltip bottom class="indicator-button">
              <template v-slot:activator="{ on, attrs }" class="indicator-template">
                <span class="indicator" :class="inactiveClass">
                  <font-awesome-icon
                    icon="fa-solid fa-screwdriver-wrench"
                    v-bind="attrs"
                    v-on="on"
                    class="indicator-icon indicator-icon-mantenance" />
                  <font-awesome-icon icon="fa-solid fa-exclamation" class="indicador-exclamation" />
                </span>
              </template>
              <span>{{ $t('dashboard.temp.alarms.maintenance') }}</span>
            </v-tooltip>

            <v-tooltip bottom class="indicator-button">
              <template v-slot:activator="{ on, attrs }" class="indicator-template">
                <span class="indicator" :class="inactiveClass">
                  <font-awesome-icon
                    icon="fa-solid fa-calendar-check"
                    v-bind="attrs"
                    v-on="on"
                    class="indicator-icon indicator-icon-calendar" />
                  <font-awesome-icon icon="fa-solid fa-exclamation" class="indicador-exclamation" />
                </span>
              </template>
              <span>{{ $t('dashboard.temp.alarms.revision') }}</span>
            </v-tooltip>
          </v-row>

          <div v-if="!isTraker && hasFrame" id="img" class="mt-5 mb-5 d-flex justify-center">
            <v-sheet
              id="img-sheet"
              elevation="3"
              color="blue-grey lighten-5"
              class="d-flex justify-center align-center white--text font-weight-bold rounded-lg pa-4">
              <Line-chart ref="lineChart" :device="selectedDevice"></Line-chart>
            </v-sheet>
          </div>
          <div
            v-if="!isTraker && !hasFrame"
            id="selected-device-desktop-istracker"
            class="d-flex justify-space-between align-center ma-2 text-caption flex-column pb-5">
            <v-row id="card-not-information">
              <v-sheet
                style="width: 750px"
                id="noFrames"
                elevation="3"
                color="blue-grey lighten-5"
                class="black--text rounded-lg pa-4">
                <p class="text-h6">{{ $t('dashboard.temp.no_temp') }}</p>
              </v-sheet>
            </v-row>
          </div>
        </div>

        <div
          v-else
          id="selected-device-desktop-istracker"
          class="d-flex justify-space-between align-center ma-2 text-caption flex-column">
          <v-row id="info-not-information" style="margin-top: 130px"> S/N: {{ this.selectedDevice.device_sn }} </v-row>

          <v-row id="card-not-information">
            <v-sheet
              style="width: 900px; height: 400px"
              elevation="3"
              color="blue-grey lighten-5"
              class="d-flex justify-center align-center black--text font-weight-bold rounded-lg pa-4 mt-15 mr-15 pr-5">
              <p class="text-h6">{{ $t('dashboard.temp.no_temp') }}</p>
            </v-sheet>
          </v-row>
        </div>
      </div>
      <div v-else id="selected-device-mobile">
        <div v-if="!isTraker" id="selected-device-mobile-isntracker">
          <Certs ref="certModal" v-on:cancel-dialog="closeModalCerts" :deviceData="selectedDevice ?? ''" />

          <div id="cards-temperatures">
            <div class="cards-temperatures-subtitle">
              <v-row v-if="!isMobile" class="d-flex justify-center ma-2 text-caption">
                S/N: {{ this.selectedDevice ? this.selectedDevice.device_sn : '---' }}
              </v-row>
              <v-row v-else class="d-flex justify-center ma-3 text-caption">
                S/N: {{ this.selectedDevice ? this.selectedDevice.device_sn : '---' }}
              </v-row>
              <div class="frigo-time-stamp d-flex">
                <v-icon v-if="!this.selectedDevice.last_frames.frame_frigo?.probes_temperature.p3" small :class="isFrigoActive">
                  mdi-snowflake
                </v-icon>
                <div class="frigo-time-stamp-text">
                  {{ !this.selectedDevice.last_frames.frame_frigo?.probes_temperature.p3 ? getFrigoTimestampTooltip : '' }}
                </div>
              </div>
            </div>

            <v-row class="sub-menu mb-2 group pa-2">
              <v-btn class="btn-submenu mx-2 fab dark small color='white" @click="mapView">
                <font-awesome-icon class="btn-submenu-icon icon-btn-location" icon="fa-solid fa-map-location-dot" />
                <span class="text-nav-bar">{{ $t('nav_bar.map') }}</span>
              </v-btn>

              <v-btn
                class="btn-submenu mx-2 fab dark small color='white"
                v-if="selectedDevice.last_frames.frame_frigo"
                @click="openTicketDialog">
                <font-awesome-icon class="btn-submenu-icon icon-btn-receipt" icon="fa-solid fa-receipt" />
                {{ $t('dashboard.device_card.ticket') }}
              </v-btn>
              <v-btn v-else disabled class="btn-submenu mx-2 fab dark small color='white">
                <font-awesome-icon class="btn-submenu-icon icon-btn-receipt" icon="fa-solid fa-receipt" />
                {{ $t('dashboard.device_card.ticket') }}
              </v-btn>
            </v-row>
            <v-row id="content-temperatures">
              <template v-for="(row, index) in probes">
                <template v-if="index < 1">
                  <v-col class="tooltip text-center" v-if="result[row.probe]">
                    <v-sheet
                      class="temperature-card"
                      elevation="1"
                      rounded
                      :class="myclass"
                      :style="{
                        width: '8em',
                        height: '4rem',
                        fontSize: '16px',
                        backgroundColor: backgroundcolorTheme,
                      }">
                      <div id="temperature-card-content">
                        <span :style="{ color: colorTheme }" class="tooltiptext" data-cy="tooltiptext">{{ row.name }}</span>
                        <div :style="{ color: colorTheme }" id="temp-ll">
                          {{ result[row.probe] }}
                        </div>
                      </div>
                    </v-sheet>
                  </v-col>
                </template>
                <template v-else>
                  <v-col class="tooltip text-center" v-if="result[row.probe]">
                    <v-sheet
                      class="temperature-card"
                      elevation="1"
                      rounded
                      :class="myclass"
                      :style="{
                        width: '8em',
                        height: '4rem',
                        fontSize: '16px',
                        backgroundColor: backgroundcolorTheme,
                      }">
                      <div id="temperature-card-content">
                        <span :style="{ color: colorTheme }" class="tooltiptext" data-cy="tooltiptext">{{ row.name }}</span>
                        <div :style="{ color: colorTheme }" id="temp-ll">
                          {{ result[row.probe] }}
                        </div>
                      </div>
                    </v-sheet>
                  </v-col>
                </template>
              </template>
            </v-row>
          </div>

          <v-row class="d-flex justify-center mt-2 mb-2" id="indicators">
            <v-tooltip bottom class="indicator-button">
              <template v-slot:activator="{ on, attrs }" class="indicator-template">
                <span class="indicator" :class="isContactOn">
                  <font-awesome-icon icon="fa-solid fa-key" v-bind="attrs" v-on="on" class="indicator-icon indicator-icon-key" />
                </span>
              </template>
              <span>{{ $t('dashboard.temp.alarms.ignition') }}</span>
            </v-tooltip>

            <v-tooltip bottom class="indicator-button">
              <template v-slot:activator="{ on, attrs }" class="indicator-template">
                <span class="indicator" :class="isEngineOn">
                  <v-icon v-bind="attrs" v-on="on" class="indicator-icon indicator-icon-batery"> mdi-engine </v-icon>
                </span>
              </template>
              <span>{{ $t('dashboard.temp.alarms.engine') }}</span>
            </v-tooltip>

            <v-tooltip bottom class="indicator-button">
              <template v-slot:activator="{ on, attrs }" class="indicator-template">
                <span class="indicator" :class="isBeaconNearby">
                  <v-icon v-bind="attrs" v-on="on" class="indicator-icon indicator-icon-batery"> mdi-link-variant </v-icon>
                </span>
              </template>
              <span>{{ $t('dashboard.temp.alarms.beacon_nearby') }}</span>
            </v-tooltip>

            <v-tooltip bottom class="indicator-button">
              <template v-slot:activator="{ on, attrs }" class="indicator-template">
                <span class="indicator" :class="isDoorOpen">
                  <font-awesome-icon
                    icon="fa-solid fa-door-open"
                    v-bind="attrs"
                    v-on="on"
                    class="indicator-icon indicator-icon-door" />
                  <font-awesome-icon icon="fa-solid fa-exclamation" class="indicador-exclamation" />
                </span>
              </template>
              <span>{{ $t('dashboard.temp.alarms.door') }}</span>
            </v-tooltip>

            <v-tooltip bottom class="indicator-button">
              <template v-slot:activator="{ on, attrs }" class="indicator-template">
                <span class="indicator" :class="isCustomOn">
                  <font-awesome-icon
                    icon="fa-solid fa-star"
                    v-bind="attrs"
                    v-on="on"
                    class="indicator-icon indicator-icon-custom" />
                </span>
              </template>
              <span>{{ $t('dashboard.temp.alarms.custom') }}</span>
            </v-tooltip>

            <v-tooltip bottom class="indicator-button">
              <template v-slot:activator="{ on, attrs }" class="indicator-template">
                <span class="indicator" :class="isAlarmOneOn">
                  <font-awesome-icon
                    icon="fa-solid fa-temperature-three-quarters"
                    v-bind="attrs"
                    v-on="on"
                    class="indicator-icon indicator-template-temperature" />
                  <font-awesome-icon icon="fa-solid fa-exclamation" class="indicador-exclamation" />
                </span>
              </template>
              <span> {{ $t('thermography.probe1') }}</span>
            </v-tooltip>

            <v-tooltip bottom class="indicator-button">
              <template v-slot:activator="{ on, attrs }" class="indicator-template">
                <span class="indicator" :class="isAlarmTwoOn">
                  <font-awesome-icon
                    icon="fa-solid fa-temperature-three-quarters"
                    v-bind="attrs"
                    v-on="on"
                    class="indicator-icon indicator-template-temperature" />
                  <font-awesome-icon icon="fa-solid fa-exclamation" class="indicador-exclamation" />
                </span>
              </template>
              <span>{{ $t('thermography.probe2') }}</span>
            </v-tooltip>

            <v-tooltip bottom class="indicator-button">
              <template v-slot:activator="{ on, attrs }" class="indicator-template">
                <span class="indicator" :class="inactiveClass">
                  <font-awesome-icon
                    icon="fa-solid fa-screwdriver-wrench"
                    v-bind="attrs"
                    v-on="on"
                    class="indicator-icon indicator-icon-mantenance" />
                  <font-awesome-icon icon="fa-solid fa-exclamation" class="indicador-exclamation" />
                </span>
              </template>
              <span>{{ $t('dashboard.temp.alarms.maintenance') }}</span>
            </v-tooltip>

            <v-tooltip bottom class="indicator-button">
              <template v-slot:activator="{ on, attrs }" class="indicator-template">
                <span class="indicator" :class="inactiveClass">
                  <font-awesome-icon
                    icon="fa-solid fa-calendar-check"
                    v-bind="attrs"
                    v-on="on"
                    class="indicator-icon indicator-icon-calendar" />
                  <font-awesome-icon icon="fa-solid fa-exclamation" class="indicador-exclamation" />
                </span>
              </template>
              <span>{{ $t('dashboard.temp.alarms.revision') }}</span>
            </v-tooltip>
          </v-row>

          <div v-if="!isTraker && hasFrame" id="img" class="mt-5 mb-5 d-flex justify-center">
            <v-sheet
              id="img-sheet"
              elevation="3"
              color="blue-grey lighten-5"
              class="d-flex justify-center align-center white--text font-weight-bold rounded-lg pa-4">
              <Line-chart id="linechartId" ref="lineChart" :device="selectedDevice" />
            </v-sheet>
          </div>
          <div
            v-if="!isTraker && !hasFrame"
            id="selected-device-mobile-istracker-noframe"
            class="mt-5 mb-5 d-flex justify-center">
            <v-row id="card-not-information" class="pl-3">
              <v-sheet
                :style="{
                  backgroundColor: backgroundcolorTheme,
                }"
                id="noFrames"
                elevation="3"
                color="blue-grey lighten-5"
                class="d-flex justify-center align-center black--text font-weight-bold rounded-lg pa-4">
                <p class="text-h6">{{ $t('dashboard.temp.no_temp') }}</p>
              </v-sheet>
            </v-row>
          </div>
        </div>

        <div v-else id="selected-device-mobile-istracker">
          <div id="arent-plots">
            <div id="title">
              <div id="text-title">
                <v-row class="justify-center">
                  <p class="text-md-h4 font-weight-bold text-center">
                    {{ $t('thermography.demo') }}
                  </p>
                </v-row>
              </div>
            </div>
            <v-row id="info-not-information" class="d-flex justify-center ma-2 text-caption">
              S/N: {{ this.selectedDevice.device_sn }}
            </v-row>
            <v-row class="d-flex justify-center" id="card-not-information">
              <v-sheet
                elevation="3"
                color="blue-grey lighten-5"
                class="d-flex justify-center align-center black--text font-weight-bold rounded-lg pa-4 ma-8">
                <p class="text-h6">{{ $t('dashboard.temp.no_temp') }}</p>
              </v-sheet>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '../../../components/dashboard/lineChart.vue';
import { getAlarms } from '../../../viewModels/alarmsViewModel';

import router from '../../../plugins/router/index';
import { checkNotifications } from '../../../utils/device';
import Temperatures from '../../../components/dashboard/show-temperature/temperature.vue';
import { eventBus } from '../../../main';
import Certs from '../../../components/dashboard/certs/certs.vue';
import { getUserDevices } from '../../../server/petitions/users/getUserDevices';
import { getUser } from '../../../viewModels/userViewModel';
const ls = require('localstorage-slim');

export default {
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    LineChart,
    Temperatures,
    Certs,
  },
  data: () => ({
    colorTheme: ls.get('theme') === 'dark' ? 'white !important' : '',
    backgroundcolorTheme: ls.get('theme') === 'dark' ? '#f7f7f73d !important' : '',
    theme: ls.get('theme') ?? 'ligth',
    myclass: 'd-flex justify-center align-center white--text font-weight-bold text-h5',
    selectedDevice: null,
    inactiveClass: 'filter-bn',
    user: null,
    isAlarmOneOn: '',
    isAlarmTwoOn: '',
    alarms: [],
    temp: null,
    result: null,
    probes: [],
    userDevices: null,
    beacon: null,
    minutesDeducted: 3 * 60000,
    speed: 15,
  }),

  async beforeMount() {
    this.isTemperatureAlarmOn();
  },

  async beforeUpdate() {
    this.isTemperatureAlarmOn();
    this.probeCollection;
  },

  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
      this.colorTheme = theme === 'dark' ? 'white !important' : '';
      this.backgroundcolorTheme = theme === 'dark' ? '#f7f7f73d !important' : '';
    });
    eventBus.$on('logout', () => {
      this.$store.state.currentUser = null;
    });

    this.user = this.$store.state.currentUser == null ? await getUser() : this.$store.state.currentUser;

    this.userDevices = await getUserDevices(this.user.token, this.user.userId);

    if (this.device.device_sn) {
      this.selectedDevice = this.device;

      this.focusOnDevice(this.selectedDevice);
    }
    this.$emit('onInit');
  },

  updated() {
    if (this.selectedDevice) this.selectedDevice.select = true;
    this.user = this.$store.state.currentUser;
    this.focusOnDevice(this.selectedDevice);
  },

  methods: {
    async isTemperatureAlarmOn() {
      if (this.selectedDevice && this.selectedDevice.device_sn) {
        let alarmConfig;
        let alarms = await getAlarms();
        for (let alarm of alarms) {
          if (alarm.device_sn == this.selectedDevice.device_sn && alarm.type == 'temp') {
            alarmConfig = alarm.probes_config;
          }
        }
        let probes_temp = this.selectedDevice.last_frames.frame_frigo
          ? this.selectedDevice.last_frames.frame_frigo?.probes_temperature
          : null;

        let notifications = checkNotifications(alarmConfig, probes_temp, this.selectedDevice.last_frames.frame_status);
        if (notifications.p1Max || notifications.p1Min) {
          this.isAlarmOneOn = '';
        } else {
          this.isAlarmOneOn = 'filter-bn';
        }
        if (notifications.p2Max || notifications.p2Min) {
          this.isAlarmTwoOn = '';
        } else {
          this.isAlarmTwoOn = 'filter-bn';
        }
      }
    },
    focusOnDeviceFromNavbar() {
      this.selectedDevice = this.$store.state.selectedDevice;

      if (this.$refs.lineChart) {
        this.$refs.lineChart.getDeviceFrames();
      }
    },
    removeDeviceFromMap() {},
    focusOnDevice(device) {
      this.selectedDevice = device;
      if (this.$refs.lineChart) {
        this.$refs.lineChart.getDeviceFrames(device);
      }
    },
    openTicketDialog() {
      this.$emit('openTicketDialog');
    },
    mapView: function () {
      eventBus.$emit('cleanSearch');
      router.push({
        name: 'map',
        params: { device: this.device },
      });
      if (this.$store.state.selectedDevice) {
        eventBus.$emit('focusOnThisDevice', this.$store.state.selectedDevice);
      } else {
        eventBus.$emit('zoomMap');
      }
    },
    certificateView: function () {
      this.$refs.certModal.setActiveModal(true);
    },

    closeModalCerts: function () {
      this.$refs.certModal.setActiveModal(false);
    },

    followDevice() {},
    addDeviceToMap() {},
    addPoiToMap() {},
    exitRoutes() {},
    updateDeviceToMap() {},
    resetMap() {},
    formatDate: function (date) {
      let months = [
        this.$t('general.months.january'),
        this.$t('general.months.february'),
        this.$t('general.months.march'),
        this.$t('general.months.april'),
        this.$t('general.months.may'),
        this.$t('general.months.june'),
        this.$t('general.months.july'),
        this.$t('general.months.august'),
        this.$t('general.months.september'),
        this.$t('general.months.october'),
        this.$t('general.months.november'),
        this.$t('general.months.december'),
      ];
      let splitedDate = date.split('-');
      let formatedDate = `${new Date(date).getDate()} ${this.$t('general.form.of')} ${
        months[new Date(date).getMonth()]
      } ${this.$t('general.form.of')} ${splitedDate[0]}`;
      return formatedDate;
    },
  },
  computed: {
    getCarplate() {
      let alias;
      if (this.selectedDevice?.device_sn) {
        this.userDevices?.find((i) => {
          if (i.device_sn == this.selectedDevice.device_sn) alias = i.alias;
        });
      }
      return alias;
    },
    async probeCollection() {
      if (this.user) {
        await this.user.devices.map((d) => {
          if (d.device_sn == this.selectedDevice.device_sn) this.selectedDevice.probes_config = d.probes_config;
        });
        const replace = { temp: 'ºC', hum: '%RH', airq: 'AQ' };

        this.result = Object.keys(this.selectedDevice.device_data_from_frame.probes_type).reduce((result, key) => {
          //Testear que funciona correctamente después borrar.
          // if (
          //   this.selectedDevice.device_data_from_frame.probes_type[key] &&
          //   this.selectedDevice.last_frames.frame_frigo.probes_temperature[key]
          // ) {
          const value =
            replace[this.selectedDevice.device_data_from_frame.probes_type[key]] ||
            this.selectedDevice.device_data_from_frame.probes_type[key];
          result[key] = `${this.selectedDevice.last_frames.frame_frigo?.probes_temperature[key]}${value}`;
          // }
          return result;
        }, {});

        function flattenObject(obj, result = {}) {
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                flattenObject(obj[key], result);
              } else {
                result[key] = obj[key];
              }
            }
          }

          return result;
        }

        const unifiedProbesObject = flattenObject(this.selectedDevice.last_frames.frame_frigo?.probes);

        this.probes = [];

        for (const i in unifiedProbesObject) {
          const probeNumber = i.split('p')[1];
          const probeName = this.selectedDevice.probes_config?.[i]?.name
            ? this.selectedDevice.probes_config[i].name
            : `${this.$t('device.last_frames.frame_frigo.probe')} ${probeNumber}`;

          this.probes.push({
            probe: i,
            name: probeName,
            hasTemp: true,
            key: probeNumber,
          });
        }
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    hasFrame() {
      let currentDate = new Date();
      let currentDateISO = currentDate.toISOString();
      let twelveHoursAgo = new Date(currentDate);
      twelveHoursAgo.setHours(currentDate.getHours() - 12);
      let twelveHoursAgoISO = twelveHoursAgo.toISOString();

      let temperatureFrame =
        this.selectedDevice.last_frames.frame_frigo.device_timestamp >= twelveHoursAgoISO &&
        this.selectedDevice.last_frames.frame_frigo.device_timestamp <= currentDateISO;

      if (this.selectedDevice && !temperatureFrame) {
        return false;
      } else {
        return true;
      }
    },
    isTraker() {
      if (this.selectedDevice && !this.selectedDevice.last_frames.frame_frigo) {
        return true;
      } else {
        return false;
      }
    },

    isContactOn() {
      if (this.selectedDevice && this.selectedDevice.device_sn) {
        if (this.selectedDevice.last_frames.frame_status.contact) {
          return 'is-contact-on';
        }
      }
      return 'filter-bn';
    },
    isEngineOn() {
      if (this.selectedDevice && this.selectedDevice.device_sn) {
        if (this.selectedDevice.last_frames.frame_status.engine_on) {
          return '';
        }
      }
      return 'filter-bn';
    },
    isBeaconNearby() {
      if (this.selectedDevice && this.selectedDevice.device_sn) {
        this.beacon = this.selectedDevice.last_frames.frame_status?.beacons?.beacon1 ?? null;

        const lastFrameTimestamp = this.selectedDevice.last_frames.frame_status.device_timestamp;
        const beaconDeviceTimestamp = this.beacon?.device_timestamp;

        const timeCondition =
          new Date(beaconDeviceTimestamp).getTime() >= new Date(lastFrameTimestamp).getTime() - this.minutesDeducted;

        const speedCondition = this.selectedDevice.last_frames.frame_gps?.speed >= this.speed;

        if (this.user.beacons?.length > 0) {
          for (const beacon of this.user.beacons) {
            if (beacon.mac_address == this.beacon?.macAddress && timeCondition && speedCondition) {
              return '';
            }
          }
        }
      }
      return 'filter-bn';
    },
    isDoorOpen() {
      if (this.selectedDevice && this.selectedDevice.device_sn) {
        if (this.selectedDevice.last_frames.frame_status.door) {
          return '';
        }
      }
      return 'filter-bn';
    },
    isCustomOn() {
      if (this.selectedDevice && this.selectedDevice.device_sn) {
        if (this.selectedDevice.last_frames.frame_status.custom) {
          return '';
        }
      }
      return 'filter-bn';
    },
    getFrigoTimestampTooltip() {
      if (this.selectedDevice) {
        const actualDate = new Date();
        const oneDay = 24 * 60 * 60 * 1000;
        const date = new Date(this.selectedDevice.last_frames.frame_frigo.device_timestamp);
        const formatedDate = this.formatDate(this.selectedDevice.last_frames.frame_frigo.device_timestamp);
        const diffDays = Math.round(Math.abs((actualDate - date) / oneDay));

        if (diffDays < 1) {
          const diffHours = Math.round(Math.abs((actualDate - date) / 1000 / 3600));
          if (diffHours > 1) {
            return this.$t('dashboard.details.last_measure_hours', {
              hours: diffHours,
            });
          }
          const diffMinutes = Math.round(Math.abs((actualDate - date) / 1000 / 60));
          if (diffMinutes > 1) {
            return this.$t('dashboard.details.last_measure_minutes', {
              minutes: diffMinutes,
            });
          }
          return this.$t('dashboard.details.last_measure_recently');
        }

        if (diffDays < 7) {
          return this.$t('dashboard.details.last_measure_in_days', {
            days: diffDays,
          });
        }

        return this.$t('dashboard.details.last_measure_date', {
          date: formatedDate,
        });
      }
    },
    isFrigoActive() {
      if (this.selectedDevice) {
        const actualDate = new Date();
        const date = new Date(this.selectedDevice.last_frames.frame_frigo.device_timestamp);
        const diffMinutes = Math.round(Math.abs((actualDate - date) / 1000 / 60));

        if (diffMinutes < 5) {
          return 'frigoActive blue--text';
        }
        if (diffMinutes < 15) {
          return 'blue--text';
        }
        return 'grey--text';
      }
    },
  },
};
</script>
