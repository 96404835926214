const { getUser } = require('./userViewModel');
const { getToken } = require('./userViewModel');
const { getAllAlarms } = require('../server/petitions/alarms/getAllAlarms');
const { saveAlarmPetition } = require('../server/petitions/alarms/saveAlarmPetition');
const { deleteAlarmsPetition } = require('../server/petitions/alarms/deleteAlarms');
// Para poder usar las traducciones en js VueI18n.default.t(`alarms.${selectedDay}`)
const VueI18n = require('../plugins/lang/index');

const alarmsViewModel = function () {
  let alarms = [];

  async function getAlarms() {
    alarms = [];
    if (alarms.length === 0) {
      const user = await getUser();
      const user_email = user.email;
      const token = getToken();
      alarms = await getAllAlarms(user_email, token);
      alarms = alarms.map((alarm) => {
        alarm.start_day = alarm.startingTime + '/' + formatedDate(alarm.start_day);
        alarm.end_day = alarm.endingTime + '/' + formatedDate(alarm.end_day);
        alarm.selected_days = alarm.selected_days.map((selectedDay) => VueI18n.default.t(`alarms.${selectedDay}`));
        return alarm;
      });
      return alarms;
    }
    return alarms;
  }

  async function saveAlarms(alarm) {
    const token = getToken();
    let res = await saveAlarmPetition(alarm, token);
    if (res.result == 'SUCCESS') {
      alarm.start_day = alarm.startingTime + '/' + formatedDate(alarm.start_day);
      alarm.end_day = alarm.endingTime + '/' + formatedDate(alarm.end_day);
      alarm.selected_days = alarm.selected_days.map((selectedDay) => VueI18n.default.t(`alarms.${selectedDay}`));

      alarms.push(alarm);
    }
    return res;
  }

  async function deleteAlarm(id, email, device_sn) {
    const token = getToken();
    const res = await deleteAlarmsPetition(token, id, email, device_sn);
    //updateTable(res);
  }

  const formatedDate = (date) => {
    let day = new Date(date).getDate();
    if (day.toString().length < 2) day = '0' + day;
    let month = new Date(date).getMonth() + 1;
    if (month.toString().length < 2) month = '0' + month;
    let year = new Date(date).getFullYear();
    let formatedDateDMY = `${day}/${month}/${year}`;
    return formatedDateDMY;
  };

  return {
    getAlarms,
    saveAlarms,
    deleteAlarm,
  };
};

module.exports = alarmsViewModel();
