const PetitionError = require('../../petitionError');
const { get } = require('../../httpRequester');

class getAllPOICategoriesException extends PetitionError {
  constructor(code) {
    super('Get POI Categories', code);
  }
}

async function getAllPOICategories(token, userId) {
  const response = await get(`poiCategories/${userId}`, token);
  if (response.result !== getAllPOICategoriesException.success) {
    return new getAllPOICategoriesException(response.result);
  }
  return response.poiCategories;
}

module.exports = {
  getAllPOICategories,
  getAllPOICategoriesException,
};
