export const arrayWithColorsDark = [
  //AZULES NEON
  '#7051d6',
  '#687EFF',
  '#80B3FF',
  '#98E4FF',
  '#B6FFFA',
  '#6499E9',
  '#9EDDFF',
  '#A6F6FF',
  '#BEFFF7',
  '#E4F1FF',
];
export const arrayWithColorsLight = [
  // ROJOS-MORADOS
  '#641E16',
  '#B03A2E',
  '#E74C3C',
  '#F1948A',
  '#FADBD8',
  '#512E5F',
  '#76448A',
  '#9B59B6',
  '#C39BD3',
  '#E8DAEF',
];
