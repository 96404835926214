<template>
  <div class="ml-10">
    <div class="user-configuration-container">
      <div class="user-configuration-form-title">
        <h1 class="my-5"><v-icon @click="back" class="mr-1">mdi-chevron-left</v-icon>Información Alarma</h1>
      </div>
      <div id="container-edit-device">
        <v-row class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">Nombre de la alarma: </label>
          </v-col>
          <v-col class="edit-data-right">
            <!-- <v-text-field dense outlined class="rounded-lg" type="text" v-model="name"></v-text-field> -->
            {{ name }}
          </v-col>
        </v-row>
        <v-row class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">Tipo de alarma: </label>
          </v-col>
          <v-col class="edit-data-right">
            {{ alarm.type }}
          </v-col>
        </v-row>
        <v-row class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">Device SN: </label>
          </v-col>
          <v-col class="edit-data-right">
            {{ alarm.device_sn }}
          </v-col>
        </v-row>
        <v-row class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">Días seleccionados: </label>
          </v-col>
          <v-col class="edit-data-right">
            <p v-for="row in alarm.selected_days" v-bind:key="row.key">{{ row }}</p>
          </v-col>
        </v-row>
        <v-row class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">Fecha de inicio: </label>
          </v-col>
          <v-col class="edit-data-right">
            {{ alarm.start_day }}
          </v-col>
        </v-row>
        <v-row class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">Fecha finalización: </label>
          </v-col>
          <v-col class="edit-data-right">
            {{ alarm.end_day }}
          </v-col>
        </v-row>
        <v-row v-if="alarm.probes_config.p1" class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">Sonda 1: </label>
          </v-col>
          <v-col class="edit-data-right"> Max: {{ alarm.probes_config.p1.max }} Min: {{ alarm.probes_config.p1.min }} </v-col>
        </v-row>
        <v-row v-if="alarm.probes_config.p2" class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">Sonda 2: </label>
          </v-col>
          <v-col class="edit-data-right"> Max: {{ alarm.probes_config.p2.max }} Min: {{ alarm.probes_config.p2.min }} </v-col>
        </v-row>
        <v-row v-if="alarm.probes_config.p3" class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">Sonda 3: </label>
          </v-col>
          <v-col class="edit-data-right"> Max: {{ alarm.probes_config.p3.max }} Min: {{ alarm.probes_config.p3.min }} </v-col>
        </v-row>
        <v-row v-if="alarm.probes_config.p4" class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">Sonda 4: </label>
          </v-col>
          <v-col class="edit-data-right"> Max: {{ alarm.probes_config.p4.max }} Min: {{ alarm.probes_config.p4.min }} </v-col>
        </v-row>
        <v-row v-if="alarm.mileage" class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">Kilometraje: </label>
          </v-col>
          <v-col class="edit-data-right"> {{ alarm.mileage }} </v-col>
        </v-row>
        <v-row v-if="alarm.frigo" class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">Horas de motor frigo: </label>
          </v-col>
          <v-col class="edit-data-right"> {{ alarm.frigo }} </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import router from '../../../plugins/router/index';

export default {
  props: {
    alarm: {
      type: Object,
    },
  },
  data: () => ({
    name: '',
  }),
  methods: {
    back() {
      router.push({
        path: '/configuration/alarms',
      });
    },
  },
  computed: {},

  mounted: async function () {
    if (this.alarm.type == 'temp') {
      this.alarm.type = 'Temperatura';
    }
    this.name = this.alarm.name;
  },
};
</script>
