const { getUser } = require('./userViewModel');
const { getToken } = require('./userViewModel');

const { getAllReminder } = require('../server/petitions/reminder/getAllReminder');
const { saveReminderPetition } = require('../server/petitions/reminder/saveReminder');
const { deleteReminderPetition } = require('../server/petitions/reminder/deleteReminder');

const reminderViewModel = function () {
  let reminders = [];

  async function getReminders() {
    if (reminders.length === 0) {
      const user = await getUser();
      const token = getToken();

      reminders = await getAllReminder(user.userId, token);

      return reminders;
    }
    return reminders;
  }

  async function saveReminder(reminder) {
    const token = getToken();
    let res = await saveReminderPetition(reminder, token);

    if (res.result == 'SUCCESS') {
      reminders.push(res);
      reminder.id = await res.response_reminder._id;
    }
    return reminder;
  }

  async function deleteReminder(id) {
    const token = getToken();
    let res = await deleteReminderPetition(token, id);
    return res;
  }

  return {
    getReminders,
    saveReminder,
    deleteReminder,
  };
};

module.exports = reminderViewModel();
