const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class DeleteClientPetitionException extends PetitionError {
  static errorDeleteClient = 'ERROR';

  constructor(code) {
    super('There is an error', code);
  }
}

async function deleteClient(token, user, email) {
  const payload = {
    user,
    email,
  };

  const response = await post('clients/delete_client', payload, token);

  if (response.result !== DeleteClientPetitionException.success) {
    return (new DeleteClientPetitionException(response));
  }
  return response;
}

// class DeleteClientPetitionException extends PetitionError {
//   static errorDeleteClient = 'ERROR';

//   constructor(code) {
//     super('There is an error', code);
//   }
// }

module.exports = {
  deleteClient,
  DeleteClientPetitionException,
};
