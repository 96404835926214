const PetitionError = require('../../petitionError');
const { put } = require('../../httpRequester');

class ModifyBeaconByAdminException extends PetitionError {
  static macAddressAlreadyInBeacon = 'MAC_ADDRESS_ALREADY_IN_BEACON';

  constructor(code) {
    super('Modify beacon by admin', code);
  }
}

async function modifyBeaconByAdmin(token, payload) {
  const response = await put('beacons/', payload, token);

  if (response.result !== ModifyBeaconByAdminException.success) {
    throw new ModifyBeaconByAdminException(response.result);
  } else {
    return response.beacon;
  }
}

module.exports = { modifyBeaconByAdmin, ModifyBeaconByAdminException };
