import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);

// const vuexLocalStorage = new VuexPersist({
//   key: 'vuex', // The key to store the state on in the storage provider.
//   storage: window.sessionStorage, // or window.sessionStorage or localForage
//   // Function that passes the state and returns the state with only the objects you want to store.
//   // reducer: state => state,
//   // Function that passes a mutation and lets you decide if it should update the state in localStorage.
//   // filter: mutation => (true)
// });
export default new Vuex.Store({
  state: {
    reportDevice: null,
    mouseOverDevice: null,
    currentUser: null,
    notificationsOn: 0,
    notificationTable: {
      notis: [],
      firstTime: true,
    },
    cloneNotificationTable: {
      notis: [],
      firstTime: true,
    },
    showMouseOver: false,
    selectedDevice: null,
    updatedDevices: [],
    // locations: [],
  },

  mutations: {
    SET_SELECTED_DEVICE(state, device) {
      state.selectedDevice = device;
    },
    SET_REPORT_DEVICE(state, device) {
      state.reportDevice = device;
    },
    SET_MOUSEOVER_DEVICE(state, device) {
      state.mouseOverDevice = device;
    },

    SET_CURRENT_USER(state, user) {
      state.currentUser = user;
    },

    SET_NOTIFICATION_TABLE(state, list) {
      state.notificationTable.notis = list;
      // state.notificationTable['firstTime'] = false
      state.notificationTable.firstTime = false;
    },
    SET_CLONE_NOTIFICATION_TABLE(state, list) {
      state.cloneNotificationTable.notis = list;
      // state.notificationTable['firstTime'] = false
      state.cloneNotificationTable.firstTime = false;
    },

    SET_MOUSE_OVER(state, show) {
      state.showMouseOver = show;
    },
    SET_UPDATED_DEVICES(state, device) {
      state.updatedDevices = device;
    },
    // SET_LOCATIONS(state, locations) {
    //   console.log(locations, 'PEPE LOC');
    //   state.locations = locations;
    // },
  },

  actions: {
    setSelectedDevice({ commit }, device) {
      commit('SET_SELECTED_DEVICE', device);
    },

    setReportDevice({ commit }, device) {
      commit('SET_REPORT_DEVICE', device);
    },

    setMouseOverDevice({ commit }, device) {
      commit('SET_MOUSEOVER_DEVICE', device);
    },

    setCurrentUser({ commit }, user) {
      commit('SET_CURRENT_USER', user);
    },

    setNotificationTable({ commit }, list) {
      commit('SET_NOTIFICATION_TABLE', list);
    },

    setCloneNotificationTable({ commit }, list) {
      commit('SET_CLONE_NOTIFICATION_TABLE', list);
    },

    setMouseOver({ commit }, show) {
      commit('SET_MOUSE_OVER', show);
    },
    setUpdatedDevices({ commit }, device) {
      commit('SET_UPDATED_DEVICES', device);
    },
  },

  getters: {
    getMouseOverState() {
      return state.showMouseOver;
    },
    getTicketDevice() {
      return state.mouseOverDevice;
    },
  },
  // plugins: [vuexLocalStorage.plugin],

  modules: {},
});
