const PetitionError = require('../../petitionError');
const { getAnonymous } = require('../../httpRequester');

class CheckingUserCodeException extends PetitionError {
  constructor(code) {
    super('Checking code', code);
  }
}

async function checkingUserCode(code, email) {
  const response = await getAnonymous(`users/checking_validation?code=${code}&email=${email}`);

  if (response.result !== CheckingUserCodeException.success) {
    throw new CheckingUserCodeException(response.result);
  } else {
    return response.response_code;
  }
}

module.exports = {
  checkingUserCode,
  CheckingUserCodeException,
};
