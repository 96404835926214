const PetitionError = require('../../petitionError');
const { getRaw } = require('../../httpRequester');

class GetLinkedDeviceException extends PetitionError {
  constructor(code) {
    super(' Get Linked Device : ', code);
  }
}

async function getLinkedDevicePetition(device_sn, token) {
  const data = await getRaw(`devices/${device_sn}/get_linked_device`, token);
  const response = await data.json();
  return response.result; // TO DO transform
  // if(response){

  //     //     throw (new GetLinkedDeviceException(response.result))
  //     console.log(response, 'rispons en petishions')
  // }

  // // }
}

// class GetLinkedDeviceException extends PetitionError {
//   constructor(code) {
//     super(' Get Linked Device : ', code);
//   }
// }

module.exports = {
  getLinkedDevicePetition,
  GetLinkedDeviceException,
};
