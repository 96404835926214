const PetitionError = require("../../petitionError");
const { post } = require("../../httpRequester");

class saveUserPOIException extends PetitionError {
    constructor(code) {
        super("Save Config", code);
    }
}

async function saveUserPOI(user, token, poi) {
    const payload = { user, poi };

    const response = await post("users/poi/", payload, token);
    if (response.result !== saveUserPOIException.success) {
        return new saveUserPOIException(response.result);
    }
    return response; // TODO transform
}

// class saveUserPOIException extends PetitionError {
//   constructor(code) {
//     super('Save Config', code);
//   }
// }

module.exports = {
    saveUserPOI,
    saveUserPOIException,
};
