<template>
  <v-container v-if="!isMobile" class="shared-container-desktop pa-2">
    <v-row class="shared-content">
      <v-col cols="2" class="menu-shared">
        <v-navigation-drawer permanent class="panel-left-shared">
          <v-toolbar-title class="mb-5" style="margin-top: 3rem; margin-left: 0.5em"></v-toolbar-title>

          <v-divider></v-divider>

          <v-tabs vertical class="tab-vertical">
            <v-tab data-cy="sharedNav" to="/shared/devices" class="panel-left-btn d-flex justify-space-between">
              <v-icon>mdi-share</v-icon>
              <span>{{ $t('nav_bar.shared') }}</span>
              <v-icon>mdi-chevron-right</v-icon>
            </v-tab>
            <v-divider></v-divider>
            <v-tab data-cy="client" to="/shared/customer" class="panel-left-btn d-flex justify-space-between">
              <v-icon>mdi-account-multiple</v-icon>
              <span>{{ $t('nav_bar.customers') }}</span
              ><v-icon>mdi-chevron-right</v-icon>
            </v-tab>
            <v-divider></v-divider>
          </v-tabs>
        </v-navigation-drawer>
      </v-col>
      <v-col cols="10" class="shared-table">
        <router-view style="z-index: -1"></router-view>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else class="shared-container-mobile pa-2 ma-2" style="max-width: 2500px">
    <!-- <v-row style="width: 100%;">
      <v-col cols="2">
        <v-navigation-drawer permanent fixed style="z-index: 0;">
          <v-toolbar-title class="mb-5" style="margin-top: 8rem; margin-left: 0.5em;"
            ></v-toolbar-title
          >
          <v-divider></v-divider>
          <v-tabs vertical>
            <v-tab to="/shared/devices" class="d-flex justify-space-between">
              <v-icon>mdi-share</v-icon> Compartidos
              <v-icon>mdi-chevron-right</v-icon>
            </v-tab>
            <v-divider></v-divider>
            <v-tab to="/shared/customer" class="d-flex justify-space-between">
              <v-icon>mdi-account-multiple</v-icon> Clientes<v-icon>mdi-chevron-right</v-icon>
            </v-tab>
            <v-divider></v-divider>
          </v-tabs>
        </v-navigation-drawer>
      </v-col> -->
    <!-- <v-col cols="10"> -->
    <router-view style="z-index: -1" class="shared-content-mobile"></router-view>
    <!-- </v-col> -->
    <!-- </v-row> -->
  </v-container>
</template>

<script>
export default {
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({}),
  methods: {},
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>