const DeviceDetector = require('device-detector-js');

const VueI18n = require('../plugins/lang/index');

const Utils = () => {
  const getPCName = () => {
    const { userAgent } = navigator;
    const pcDetector = new DeviceDetector();
    const pc = pcDetector.parse(userAgent);
    const url = window.location.host;
    const hostSplit = url.split('.')[0];
    const browser = pc.client.name;

    let os = pc.os.name;

    if (os === 'GNU/Linux') {
      os = 'Linux';
    }

    const result = `${browser} (${os}) ${hostSplit}`;
    return result;
  };

  const downloadFile = async (response) => {
    const result = await response.blob();
    const link = document.createElement('a');

    link.style.display = 'none';
    link.href = URL.createObjectURL(result, '_self');
    link.download = 'APACHE.CFG';

    document.body.appendChild(link);

    link.click();

    window.URL.revokeObjectURL(link.href);

    /*
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = 'Certificates_report';
    link.download = fileName;
    link.click();
    */
  };

  const formatDate = (date) => {
    if (!date) return '';
    date = date.replace('.000Z', '');
    let minute = new Date(date).getMinutes();
    if (minute.toString().length < 2) minute = '0' + minute;
    let hour = new Date(date).getHours();
    if (hour.toString().length < 2) hour = '0' + hour;
    let day = new Date(date).getDate();
    if (day.toString().length < 2) day = '0' + day;
    let month = new Date(date).getMonth() + 1;
    if (month.toString().length < 2) month = '0' + month;
    let year = new Date(date).getFullYear();

    let formatedDate = `${hour}:${minute} - ${day}/${month}/${year}`;
    return formatedDate;
  };

  const formatDates = (date) => {
    let months = [
      VueI18n.default.t('general.months.january'),
      VueI18n.default.t('general.months.february'),
      VueI18n.default.t('general.months.march'),
      VueI18n.default.t('general.months.april'),
      VueI18n.default.t('general.months.may'),
      VueI18n.default.t('general.months.june'),
      VueI18n.default.t('general.months.july'),
      VueI18n.default.t('general.months.august'),
      VueI18n.default.t('general.months.september'),
      VueI18n.default.t('general.months.october'),
      VueI18n.default.t('general.months.november'),
      VueI18n.default.t('general.months.december'),
    ];
    let splitedDate = date.split('-');
    let formatedDate = `${new Date(date).getDate()} ${VueI18n.default.t('general.form.of')} ${
      months[new Date(date).getMonth()]
    } ${VueI18n.default.t('general.form.of')} ${splitedDate[0]}`;
    return formatedDate;
  };

  const formatLastInspectionDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // +1 porque getMonth() empieza en 0
    const year = date.getFullYear();
    return `${year}/${month}/${day}`;
  };

  const formatDateTable = (date) => {
    let dateTimeFormat = Intl.DateTimeFormat().resolvedOptions();
    return new Date(date).toLocaleDateString(dateTimeFormat.locale).replace(/\//g, '-');
  };

  const formatDuration = (durationMinutes) => {
    let formattedDuration = '';

    const hours = Math.trunc(durationMinutes / 60);
    if (hours > 0) formattedDuration += `${pluralize(hours, 'h')}`;
    const minutes = Math.trunc(durationMinutes % 60);
    if (minutes > 0) formattedDuration += `${pluralize(minutes, 'm')} `;
    minutesRemainder = (durationMinutes % 60) % minutes;
    const seconds = (minutesRemainder * 60).toFixed();
    if (seconds > 0) formattedDuration += `${pluralize(seconds, 's')}`;

    return formattedDuration;
  };

  const pluralize = (count, noun, suffix = ' ') => `${count} ${noun}${count !== 1 ? suffix : ''}`;

  const batchPromises = async (promises, limit, delayInMilliseconds = 0) => {
    const results = [];
    for (let i = 0; i < promises.length; i = i + limit) {
      if (i > 0 && delayInMilliseconds > 0) {
        await new Promise((r) => setTimeout(r, delayInMilliseconds));
      }
      const batch = promises.slice(i, i + limit);
      const result = await Promise.all(batch);
      results.push(...result);
    }
    return results;
  };

  const convertBase64 = async (file) => {
    const prom = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    return prom;
  };

  const pick = (obj, ...keys) => Object.fromEntries(keys.filter((key) => key in obj).map((key) => [key, obj[key]]));

  const inclusivePick = (obj, ...keys) => Object.fromEntries(keys.map((key) => [key, obj[key]]));

  const omit = (obj, ...keys) => Object.fromEntries(Object.entries(obj).filter(([key]) => !keys.includes(key)));

  const downloadReport = async (response, fileName) => {
    const blob = await response.blob();
    var link = document.createElement('a');
    var url = window.URL.createObjectURL(blob);
    link.href = url;

    // iOS
    if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
      link.click();
      setTimeout(() => {
        URL.revokeObjectURL(blob);
        window.close();
      }, 5000);
    } else {
      // Android
      link.download = fileName;
      // No estoy seguro del todo pero creo que esto es para Firefox
      document.body.appendChild(link);
      link.click();

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        window.close();
      }, 10000);
    }
  };

  const validateDateRange = (dates) => {
    if (dates.length !== 2) return;

    const diffInMs = Math.abs(new Date(dates[0]) - new Date(dates[1]));
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    if (diffInDays > 30) {
      const maxDate = new Date(dates[0]);
      maxDate.setDate(maxDate.getDate() + 30);
      return (dates = [dates[0], maxDate.toISOString().substring(0, 10)]);
    }
  };

  const maxDate = (dates) => {
    if (!dates.length) return new Date().toISOString().substring(0, 10);
    const maxDate = new Date(dates[0]);
    maxDate.setDate(maxDate.getDate() + 30);
    return maxDate > new Date() ? new Date().toISOString().substring(0, 10) : maxDate.toISOString().substring(0, 10);
  };

  const validEmail = (email) => {
    const regEx = /^[^._\-"'][a-za-z0-9_\-.]{0,28}[^._\-"']@[^._\-"'][a-za-z0-9_\-.]{0,28}[^._\-"']\.[a-za-z]{2,5}$/;
    return regEx.test(email);
  };

  const updateMaintenanceDates = (item) => {
    let daysToMilliseconds = item.days * 24 * 60 * 60 * 1000;
    let isNext = new Date(item.nextInspectionDate ?? item.next_inspection_date) - daysToMilliseconds <= new Date();
    let isExpired = new Date(item.nextInspectionDate ?? item.next_inspection_date) <= new Date();
    let remainingMilliseconds = new Date(item.nextInspectionDate ?? item.next_inspection_date) - new Date();
    let days = remainingMilliseconds / 1000 / 60 / 60 / 24;

    item.remainingDays = isExpired ? VueI18n.default.t('nav_bar.menu.expired').toLowerCase() : Math.round(days) + ' d';
    item.state = isExpired ? 'expired' : isNext ? 'next' : 'active';
    return item;
  };

  const updateMaintenanceHours = (item, isRenew) => {
    if (isRenew) {
      item.last_inspection_hours = Math.round(item.engine_time);
      item.next_inspection_hours = Math.round(item.engine_time) + item.maintenance_periodicity;
    }

    let isNext = item.next_inspection_hours - Math.round(item.engine_time) <= item.hours;
    let isExpired = item.next_inspection_hours <= Math.round(item.engine_time);
    let hours = item.next_inspection_hours - Math.round(item.engine_time);

    item.remainingHours = isExpired ? VueI18n.default.t('nav_bar.menu.expired').toLowerCase() : Math.round(hours);
    item.state = isExpired ? 'expired' : isNext ? 'next' : 'active';
    item.order = item.state == 'expired' ? 1 : item.state == 'next' ? 2 : 3;
    // return item;
  };

  return {
    getPCName,
    downloadFile,
    formatDate,
    formatDates,
    formatDateTable,
    formatLastInspectionDate,
    formatDuration,
    pluralize,
    batchPromises,
    pick,
    inclusivePick,
    omit,
    convertBase64,
    downloadReport,
    validateDateRange,
    maxDate,
    validEmail,
    updateMaintenanceDates,
    updateMaintenanceHours,
  };
};

module.exports = Utils();
