const PetitionError = require('../../petitionError');
const { getAnonymous } = require('../../httpRequester');

class UsersDevicesException extends PetitionError {
  constructor(code) {
    super('Get Device', code);
  }
}

async function usersDevices(token, _id) {
  try {
    const response = await getAnonymous(`users/devices?token=${token}`);

    if (response.result === UsersDevicesException.success) {
      return response.devices;
    }
    throw new UsersDevicesException(response.result);
  } catch (error) {
    console.error(error);
  }
}

module.exports = { usersDevices, UsersDevicesException };
