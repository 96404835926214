const PetitionError = require('../../petitionError');
const { post, get } = require('../../httpRequester');

class GetMyDevicesException extends PetitionError {
  constructor(code) {
    super('Get User Devices', code);
  }
}

async function getMyDevices(devices, token) {
  const payload = { devices };

  const response = await post('users/devices', payload, token);

  if (response.result !== GetMyDevicesException.success) {
    throw new GetMyDevicesException(response.result);
  } else {
    return response.devices; // TODO transform
  }
}

async function getUserDevices(token, userId) {
  const response = await get(`users/${userId}/devices`, token);

  if (response.result !== GetMyDevicesException.success) {
    throw new GetMyDevicesException(response.result);
  } else {
    return response.devices;
  }
}
async function getUserDevicesForAdmin(userId, token) {
  const response = await get(`users/${userId}/devices_admin`, token);

  if (response.result !== GetMyDevicesException.success) {
    throw new GetMyDevicesException(response.result);
  } else {
    return response.devices;
  }
}

async function getUserDevicesForSearch(token, userId) {
  const response = await get(`users/${userId}/search_devices`, token);

  if (response.result !== GetMyDevicesException.success) {
    throw new GetMyDevicesException(response.result);
  } else {
    return response.devices;
  }
}

module.exports = { getMyDevices, GetMyDevicesException, getUserDevices, getUserDevicesForAdmin, getUserDevicesForSearch };
