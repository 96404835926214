var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobile),expression:"!isMobile"}],staticStyle:{"width":"45rem","text-align":"center"}},[_c('v-carousel',{staticStyle:{"width":"100%","height":"27rem"},attrs:{"hide-delimiters":""}},_vm._l((_vm.chartDataFilter),function(item,i){return _c('v-carousel-item',{attrs:{"reverse-transition":"fade-transition","transition":"fade-transition"}},[_c('LineChartGenerator',{attrs:{"chart-data":item,"chart-options":i === 0
              ? _vm.chartOptionsAll
              : item.datasets[0]['yAxesID'] === 'temp'
              ? _vm.chartOptionsTemp
              : item.datasets[0]['yAxesID'] === 'hum'
              ? _vm.chartOptionsHum
              : _vm.chartOptionsAirq,"chart-id":item.datasets[0]['yAxesID'],"dataset-id-key":_vm.datasetIdKey,"plugins":_vm.plugins,"css-classes":_vm.cssClasses,"styles":_vm.styles,"width":_vm.width,"height":_vm.height}})],1)}),1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}],attrs:{"id":"container-carousel"}},[_c('v-carousel',{staticStyle:{"width":"400px"},attrs:{"id":"carouselId","show-arrows-on-hover":"","hide-delimiters":""}},_vm._l((_vm.chartDataFilter),function(item,i){return _c('v-carousel-item',{attrs:{"id":"carousel-itemId","reverse-transition":"fade-transition","transition":"fade-transition"}},[_c('LineChartGenerator',{staticStyle:{"height":"380px"},attrs:{"id":"linechart-mobile","chart-data":item,"chart-options":i === 0
              ? _vm.chartOptionsAll
              : item.datasets[0]['yAxesID'] === 'temp'
              ? _vm.chartOptionsTemp
              : item.datasets[0]['yAxesID'] === 'hum'
              ? _vm.chartOptionsHum
              : _vm.chartOptionsAirq,"chart-id":item.datasets[0]['yAxesID'],"dataset-id-key":_vm.datasetIdKey,"plugins":_vm.plugins,"css-classes":_vm.cssClasses,"styles":_vm.styles,"width":_vm.width,"height":_vm.height}})],1)}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }