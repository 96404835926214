<template>
  <div class="mt-5 mx-10">
    <v-row>
      <v-col class="d-flex justify-start pb-0">
        <div class="breadcrumbs-container ml-3">
          <v-row>
            <h1>{{ $t('user.form.users') }}</h1>
          </v-row>
          <v-row class="breadcrumbs">
            <v-breadcrumbs :items="items" class="pl-0">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                  {{ item.text.toUpperCase() }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-row>
        </div>
      </v-col>
      <v-col class="btn-new-user">
        <v-btn color="primary" class="float-right" to="users/create">
          <v-icon class="mr-1">mdi-plus</v-icon>
          {{ $t('user.form.new_user') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="row-user-table mt-0">
      <v-col class="col-user-table pt-0">
        <v-data-table
          data-cy="usersTable"
          :headers="usersTableHeader"
          :items="usersTableData"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          class="user-table elevation-1"
          :loading="isTableLoading"
          :search="search"
          v-bind:loading-text="$t('general.table.loading')"
          @click:row="editUser"
          @contextmenu:row="editUserCtrl">
          <template v-slot:top>
            <v-text-field
              style="width: 100%"
              autofocus
              v-model="search"
              v-bind:label="$t('user.form.search_users')"
              prepend-inner-icon=" "></v-text-field>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="icon-table-edit" color="primary">
              <v-icon medium color="error" class="delete-icon" @click.stop="openDialog(item)"> mdi-delete </v-icon>
            </div>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            :dark="theme === 'dark'"
            :style="{
              color: theme === 'dark' ? 'white !important' : '',
            }"
            v-model="page"
            :length="pageCount"></v-pagination>
        </div>
      </v-col>
    </v-row>

    <ConfirmationDialog
      :isActive="deleteDialog"
      v-on:cancel-dialog="closeDialog"
      v-on:accept-dialog="deleteUser(deleteMode)"
      v-bind:tittleText="$t('general.dialog.confirmation.title')"
      :messageText="dialogText"
      v-bind:cancelButtonText="$t('general.dialog.confirmation.button.cancel')"
      v-bind:acceptButtonText="$t('general.dialog.confirmation.button.accept_delete')">
    </ConfirmationDialog>

    <ErrorDialog
      :isActive="errorDialog"
      v-bind:tittleText="$t('general.dialog.error.title')"
      :messageText="errorText"
      v-bind:acceptButtonText="$t('general.dialog.error.accept_button')"
      v-on:accept-dialog="errorDialog = false">
    </ErrorDialog>
  </div>
</template>

<script>
import { getAllUsers, deleteUser, getCompany } from '../../../viewModels/adminViewModel';
import { DeleteUserException } from '../../../server/petitions/users/deleteUser';
import { GetUsersException } from '../../../server/petitions/users/getUsers';
import ConfirmationDialog from '../../../components/confirmationDialog';
import ErrorDialog from '../../../components/errorDialog';
import router from '../../../plugins/router/index';
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';

export default {
  components: {
    ConfirmationDialog,
    ErrorDialog,
  },
  data: () => ({
    theme: ls.get('theme') ?? 'ligth',
    isTableLoading: true,
    search: '',
    usersTableData: [],
    deleteDialog: false,
    dialogText: '',
    userToDelete: null,
    deleteMode: 'soft',
    page: 1,
    pageCount: 0,
    itemsPerPage: 8,
    errorDialog: false,
    errorText: '',
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '../dashboard/temperatures',
      },
      {
        text: 'Usuarios',
        disabled: true,
        href: 'admin/users',
      },
    ],
  }),
  methods: {
    editUser: function (e) {
      router.push({
        name: 'editUser',
        params: { userId: e._id, userData: e },
      });
    },
    editUserCtrl: async function (e, i) {
      e.preventDefault();
      let routeData = router.resolve({ name: 'editUser', params: { userId: i.item._id, userData: i.item } });
      window.open(routeData.href, '_blank');
    },
    deleteUser: async function (mode) {
      try {
        await deleteUser(this.userToDelete._id, mode);

        this.userToDelete = null;
        this.deleteDialog = false;
      } catch (e) {
        switch (e.code) {
          case DeleteUserException.unknownError:
          case DeleteUserException.incorrectParameters:
          case DeleteUserException.invalidToken:
          case DeleteUserException.expiredToken:
            console.error(e);
            this.errorText = this.$t('general.error.unexpected_error');
            this.errorDialog = true;
            break;
          case DeleteUserException.userSharesDevices:
            this.dialogText = this.$t('user.delete.error.user_shares_devices');
            this.deleteMode = 'hard';
            this.deleteDialog = true;
            break;
          default:
            console.error('CATCH ERROR:', e);
        }
      }
    },
    openDialog: function (e) {
      this.dialogText = this.$t('user.delete.warning.user_will_delete', {
        name: e.name,
        email: e.email,
      });
      this.userToDelete = e;
      this.deleteDialog = true;
    },
    closeDialog: function () {
      this.deleteDialog = false;
      this.userToDelete = null;
      this.deleteMode = 'soft';
    },
  },
  mounted: async function () {
    try {
      eventBus.$on('changeTheme', (theme) => {
        this.theme = theme;
      });
      this.usersTableData = await getAllUsers();
      this.isTableLoading = false;
      for (let i = 0; i < this.usersTableData.length; i++) {
        if (this.usersTableData[i].company_id) {
          let userCompany = await getCompany(this.usersTableData[i].company_id);
          this.usersTableData[i].company = userCompany.name;
        }
      }
    } catch (e) {
      switch (e.code) {
        case GetUsersException.unknownError:
        case GetUsersException.incorrectParameters:
        case GetUsersException.invalidToken:
        case GetUsersException.expiredToken:
          console.error(e);
          this.dialogText = this.$t('general.error.unexpected_error');
          this.errorDialog = true;
          break;
        default:
          console.error('CATCH ERROR:', e);
      }
    }
  },
  computed: {
    usersTableHeader() {
      return [
        {
          text: this.$t('user.atribute.name'),
          value: 'name',
          width: '12rem',
          divider: true,
        },
        {
          text: this.$t('user.atribute.company'),
          value: 'company',
          width: '12rem',
          divider: true,
        },
        {
          text: this.$t('user.atribute.email'),
          value: 'email',
          width: '12rem',
          divider: true,
        },
        {
          text: this.$t('user.atribute.phone'),
          value: 'phone',
          width: '8rem',
          divider: true,
        },
        {
          text: this.$t('user.atribute.devices'),
          value: 'devices.length',
          width: '4rem',
          divider: true,
        },
        {
          text: this.$t('user.atribute.notes'),
          value: 'notes',
          width: '11rem',
          divider: true,
        },
        {
          text: '',
          value: 'actions',
          width: '2rem',
          divider: true,
          sortable: false,
        },
      ];
    },
  },
};
</script>

<style>
.v-data-table__wrapper td {
  word-break: break-all;
}
.v-data-table__wrapper tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.v-data-table__wrapper tbody tr:hover {
  background-color: rgba(68, 138, 255, 0.5) !important;
}
</style>
