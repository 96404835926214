import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/es5/locale/es';
import en from 'vuetify/es5/locale/en';
import fr from 'vuetify/es5/locale/fr';
import de from 'vuetify/es5/locale/de';
import constants from './lang/constants';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: {
      es,
      en,
      fr,
      de,
    },
    current: constants.default_locale,
  },
});
