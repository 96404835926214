<template>
  <v-dialog :dark="theme === 'dark' ? true : false" v-model="isDialogActive" max-width="80%" persistent>
    <CSVImporter
      :title="$t('dashboard.poisImport')"
      @csv-importer:cancel="setDialogActivation(false)"
      @csv-importer:finish="onImported"
      :fields-to-map="fieldsToMap" />
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark class="mb-2 ml-2" v-bind="attrs" v-on="on">
        <v-icon class="mr-1">mdi-import</v-icon> {{ btnText }}
      </v-btn>
    </template>
  </v-dialog>
</template>

<script>
import CSVImporter from '../../importers/csvImporter.vue';
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';

export default {
  components: {
    CSVImporter,
  },
  props: {
    btnText: {
      type: String,
      required: true,
    },
  },
  data: (instance) => {
    return {
      theme: ls.get('theme') ?? 'ligth',
      isDialogActive: false,
      fieldsToMap: [
        { field: 'name', text: instance.$t('shared.name'), required: true },
        { field: 'address', text: instance.$t('shared.address'), default: '' },
        { field: 'latitude', text: instance.$t('device.last_frames.frame_gps.latitude'), default: '' },
        { field: 'longitude', text: instance.$t('device.last_frames.frame_gps.longitude'), default: '' },
        { field: 'radius', text: instance.$t('dashboard.poi_radius'), default: 50 },
        { field: 'category', text: instance.$t('dashboard.pois_categories.category'), default: '' },
      ],
    };
  },
  methods: {
    onImported(pois) {
      this.$emit('pois-imported', pois);
      this.setDialogActivation(false);
    },
    setDialogActivation(activation) {
      this.isDialogActive = activation;
    },
  },
  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
  },
};
</script>
