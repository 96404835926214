const { getAllPOIS } = require('../pois/getAllPOIS');

const PetitionError = require('../../petitionError');
const { getAnonymous } = require('../../httpRequester');

class GetUserPOISException extends PetitionError {
  constructor(code) {
    super('Get User POIS', code);
  }
}

async function getUserPOIS(user, token) {
  const response = await getAllPOIS(token, user.userId);

  if (response.result !== GetUserPOISException.success) {
    throw new GetUserPOISException(response.result);
  } else {
    return response; // TODO transform
  }
}

// class GetUserPOISException extends PetitionError {
//   constructor(code) {
//     super('Get User POIS', code);
//   }
// }

module.exports = {
  getUserPOIS,
  GetUserPOISException,
};
