<template>
  <div class="text-center" style="display: flex; flex-direction: column">
    <v-btn style="width: 1em; height: 2rem; background-color: rgba(255, 255, 255, 0.7)" id="zoomIn" @click="zommIn">
      <v-icon>mdi mdi-plus</v-icon>
    </v-btn>
    <v-btn style="width: 1em; height: 2rem; background-color: rgba(255, 255, 255, 0.7)" id="zoomOut" @click="zoomOut">
      <v-icon>mdi mdi-minus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { eventBus } from '../main';

export default {
  data: () => ({
    user: null,
    token: null,
  }),

  mounted: async function () {},
  methods: {
    zommIn() {
      eventBus.$emit('zoom-in');
    },
    zoomOut() {
      eventBus.$emit('zoom-out');
    },
  },
};
</script>
