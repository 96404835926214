const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class ModifyUserItselfException extends PetitionError {
  static emailAlreadyInUser = 'EMAIL_ALREADY_IN_USER';
  static errorSamePassword = 'SAME_PASSWORD';

  constructor(code) {
    super('Modify User By Itslef', code);
  }
}

async function modifyUserItselfPetition(
  user_name,
  user_phone,
  user_email,
  user_notification_emails,
  user_unit,
  token,
  base64,
) {
  const payload = {
    name: user_name,
    phone: user_phone,
    email: user_email,
    base64: base64,
    notification_emails: user_notification_emails,
    unit: user_unit,
  };

  const response = await post('users/update_itself', payload, token);

  if (response.result !== ModifyUserItselfException.success) {
    throw new ModifyUserItselfException(response.result);
  } else {
    return response.user; // TODO transform
  }
}

module.exports = {
  modifyUserItselfPetition,
  ModifyUserItselfException,
};
