<template>
  <div>
    <v-container class="my-3">
      <v-row>
        <v-col>
          <h1 class="d-flex justify-center">{{ $t('alarms.alarms') }}</h1>
        </v-col>
      </v-row>
      <v-row>
        <h5 v-if="this.disabledButton == true" class="text-red">
          {{ $t('alarms.alert-message') }}
          <router-link to="/configuration/emails"> {{ $t('alarms.here') }}</router-link>
        </h5>
        <v-col>
          <v-btn
            :style="{
              color: theme === 'dark' ? 'white !important' : '',
              'background-color': theme === 'dark' ? '#ffffff1a !important' : '',
            }"
            :disabled="disabledButton"
            @click="openCreate"
            class="float-right mb-5"
            color="primary"
            ><v-icon class="mr-3">mdi-alarm</v-icon>{{ $t('alarms.add_alarms') }}</v-btn
          >
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-data-table
        @click:row="editAlarm"
        :loading="isTableLoading"
        :headers="alarmsTableHeader"
        :items="alarmsTable"
        hide-default-footer
        :page.sync="page"
        @page-count="pageCount = $event"
        :items-per-page="itemsPerPage"
        class="shared-data-table elevation-1"
        :search="search"
        v-bind:loading-text="$t('general.table.loading')">
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon data-cy="delete" medium class="mr-1" color="error" @click.stop="deleteRow(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:top>
          <v-text-field
            style="width: 100%"
            v-model="search"
            :label="$t('alarms.search_alarm')"
            prepend-inner-icon=" "></v-text-field>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination :dark="theme === 'dark'" v-model="page" :length="pageCount"></v-pagination>
      </div>
    </v-container>
    <Confirm ref="confirm"></Confirm>
    <CreateAlarm @userEmail="turnButtonOff" :key="update + 'A'" :isActive="create" @cancel-create="closeCreate"></CreateAlarm>
  </div>
</template>
<style>
.text-red {
  color: red;
}
</style>
<script>
import Confirm from '../../../components/confirm.vue';
import CreateAlarm from '../../../components/alarms/createAlarm.vue';
import { getAlarms, deleteAlarm } from '../../../viewModels/alarmsViewModel';
import router from '../../../plugins/router/index';
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';

// Otra forma de importar  i18n en js
import i18n from '../../../plugins/lang/index';

export default {
  components: {
    CreateAlarm,
    Confirm,
  },
  data: () => ({
    theme: ls.get('theme') ?? 'ligth',
    alarmsTable: [],
    search: '',
    create: false,
    add: false,
    isTableLoading: true,
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    update: 0,
    disabledButton: false,
  }),
  methods: {
    editAlarm: async function (e) {
      router.push({
        name: 'editAlarm',
        params: { _id: e._id, alarm: e },
      });
    },

    turnButtonOff() {
      this.disabledButton = true;
    },

    openCreate() {
      this.create = true;
    },
    closeCreate: function () {
      this.create = false;
      this.update++;
    },
    openAdd() {
      this.add = true;
    },
    deleteRow: async function (item) {
      if (
        // Otra forma de usar traducciones de i18n en js , i18n.t('alarms.delete_message')
        await this.$refs.confirm.open(i18n.t('alarms.delete'), i18n.t('alarms.delete_report'), {
          color: 'red',
        })
      ) {
        let indexToDelete = this.alarmsTable.indexOf(item);
        this.alarmsTable.splice(indexToDelete, 1);
        let res = await deleteAlarm(item._id, item.email, item.device_sn);
      }
    },
    async getMyAlarms() {
      try {
        let res = await getAlarms();
        this.alarmsTable = res;
        this.isTableLoading = false;
      } catch (e) {
        console.log(e);
      }
    },

    formatedDate(date) {
      let day = new Date(date).getDate();
      if (day.toString().length < 2) day = '0' + day;
      let month = new Date(date).getMonth() + 1;
      if (month.toString().length < 2) month = '0' + month;
      let year = new Date(date).getFullYear();
      let formatedDateDMY = `${day}/${month}/${year}`;
      return formatedDateDMY;
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    // Desde y Hasta estan comentados por el momento
    alarmsTableHeader() {
      return [
        {
          text: this.$t('alarms.name'),
          value: 'name',
          width: '7rem',
          divider: true,
        },
        {
          text: this.$t('alarms.type'),
          value: 'type',
          width: '7rem',
          divider: true,
        },
        {
          text: this.$t('alarms.device'),
          value: 'device_sn',
          width: '5rem',
          divider: true,
        },
        {
          text: this.$t('alarms.days'),
          value: 'selected_days',
          width: '5rem',
          divider: true,
        },
        {
          text: this.$t('alarms.from'),
          value: 'start',
          width: '5rem',
          divider: true,
        },
        {
          text: this.$t('alarms.to'),
          value: 'end',
          width: '5rem',
          divider: true,
        },
        {
          text: '',
          value: 'actions',
          width: '3rem',
          divider: true,
          sortable: false,
        },
      ];
    },
  },

  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
    this.getMyAlarms();
  },
};
</script>
