<template>
  <v-card
    class="d-flex mb-4"
    outlined
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragover = true"
    @dragenter.prevent="dragover = true"
    @dragleave.prevent="dragover = false"
    :class="{ 'grey lighten-2': dragover }">
    <v-card-text>
      <v-row v-if="inputFile == null" class="d-flex flex-column" dense align="center" justify="center">
        <v-file-input
          v-model="inputFile"
          @change="onFileInputChange"
          hide-input
          :accept="supportedMimeTypes.join(',')"
          prepend-icon="mdi-cloud-upload"
          :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"></v-file-input>
        <p>{{ $t('dashboard.csv_drop') }}</p>
      </v-row>
      <v-row v-else>
        <v-list-item key="input-file-import">
          <v-list-item-content>
            <v-list-item-title>
              {{ inputFile.name }}
              <span class="ml-3 text--secondary"> {{ inputFile.size }} bytes</span>
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn @click.stop="removeInputFile" icon>
              <v-icon> mdi-close-circle </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-row>
      <v-row v-if="uploadErrors.length > 0">
        <v-list-item v-for="(error, index) in uploadErrors" :key="index">
          <v-list-item-content>
            <v-list-item-title>
              {{ error }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import mimeTypes from 'mime-types';

export default {
  name: 'UploadFiles',
  model: {
    prop: 'file',
  },
  props: {
    supportedMimeTypes: {
      type: Array,
      default: () => {
        return ['text/csv', 'text/x-csv', 'application/vnd.ms-excel', 'text/plain'];
      },
    },
    validation: {
      type: Boolean,
      default: true,
    },
    file: {
      type: Object,
      default: null,
    },
  },
  data: () => {
    return {
      dragover: false,
      isValidFileMimeType: false,
      uploadErrors: [],
      inputFile: null,
    };
  },
  methods: {
    removeInputFile() {
      this.uploadErrors = [];
      this.inputFile = null;
    },
    onFileInputChange(file) {
      this.removeInputFile();

      if (!this.validFileMimeType(file)) {
        const errorMsg = this.$t('dashboard.invalid_mime').replace('$1', file.name);
        this.uploadErrors.push(errorMsg);
        return;
      }

      this.inputFile = file;
    },
    onDrop(event) {
      this.dragover = false;

      if (event.dataTransfer.files.length == 0) {
        return;
      }

      if (event.dataTransfer.files.length > 1) {
        this.uploadErrors.push(this.$t('dashboard.max_files'));
        return;
      }

      this.removeInputFile();
      const file = event.dataTransfer.files[0];

      if (!this.validFileMimeType(file)) {
        const errorMsg = this.$t('dashboard.invalid_mime').replace('$1', file.name);
        this.uploadErrors.push(errorMsg);
        return;
      }

      this.inputFile = file;
    },
    validFileMimeType(file) {
      if (!this.validation) return true;

      const mimeType = file.type === '' ? mimeTypes.lookup(file.name) : file.type;

      return this.isSupportedMimeType(mimeType);
    },
    isSupportedMimeType(mimeType) {
      return this.supportedMimeTypes.indexOf(mimeType) > -1;
    },
  },
  watch: {
    inputFile() {
      this.$emit('fileUploadedChange', this.inputFile);
    },
  },
  computed: {
    showErrorMessage() {
      return this.uploadedFiles.length > 0 && !this.isValidFileMimeType;
    },
  },
};
</script>
