const PetitionError = require('../../petitionError');
const { postRaw } = require('../../httpRequester');
const { downloadReport } = require('../../../utils/utils');

class createDocsReportException extends PetitionError {
  static unknownDevice = 'UNKNOWN_DEVICE';

  static noDataInDate = 'NO_DATA_IN_DATE';

  constructor(code) {
    super('Create docs Reports', code);
  }
}

async function createDocsReport(token, docs) {
  let payload = {
    docs,
    timeZoneOffset: new Date().getTimezoneOffset() / 60,
  };
  const response = await postRaw('reports/docs', payload, token);
  if (response.status !== 200) {
    const result = await response.json();
    throw new createDocsReportException(result.result);
  } else {
    const fileName = 'Certificates_report';
    downloadReport(response, fileName);
  }
  return response;
}

module.exports = {
  createDocsReport,
  createDocsReportException,
};
