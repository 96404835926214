<template>
  <div class="mt-5 mx-10">
    <v-row>
      <v-col class="d-flex justify-start ml-3">
        <div class="breadcrumbs-container">
          <v-row>
            <h1>{{ $t('company.list') }}</h1>
          </v-row>
          <v-row class="breadcrumbs mb-0">
            <v-breadcrumbs :items="items" class="pl-0 pb-0">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                  {{ item.text.toUpperCase() }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-row>
        </div>
      </v-col>

      <v-col class="btn-new-company">
        <v-btn color="primary" to="companies/new-company" class="float-right">
          <v-icon class="mr-1">mdi-plus</v-icon>{{ $t('company.new') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="row-company-table mt-0">
      <v-col class="col-company-table pt-0">
        <v-data-table
          data-cy="companiesTable"
          :headers="companiesTableHeader"
          :items="companiesTableData"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          class="company-table elevation-1"
          :loading="isTableLoading"
          :search="search"
          v-bind:loading-text="$t('general.table.loading')"
          @click:row="editCompany">
          <template v-slot:top>
            <v-text-field
              style="width: 100%"
              autofocus
              v-model="search"
              v-bind:label="$t('company.search')"
              prepend-inner-icon=" "></v-text-field>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <!-- <span class="d-flex">
              <v-icon medium class="mr-1" color="primary" @click.stop="editCompany(item)"> mdi-pencil </v-icon>
            <v-icon medium color="error" class="ml-1" @click.stop="openDialog(item)"> mdi-delete </v-icon>
           </span> -->

            <div class="icon-table-edit">
              <v-icon medium color="error" @click.stop="openDialog(item)"> mdi-delete </v-icon>
            </div>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            :dark="theme === 'dark'"
            :style="{
              color: theme === 'dark' ? 'white !important' : '',
            }"
            v-model="page"
            :length="pageCount"></v-pagination>
        </div>
      </v-col>
    </v-row>
    <ConfirmationDialog
      :isActive="deleteDialog"
      v-on:cancel-dialog="closeDialog"
      v-on:accept-dialog="removeCompany(deleteMode)"
      v-bind:tittleText="$t('general.dialog.confirmation.title')"
      :messageText="dialogText"
      v-bind:cancelButtonText="$t('general.dialog.confirmation.button.cancel')"
      v-bind:acceptButtonText="$t('general.dialog.confirmation.button.accept_delete')">
    </ConfirmationDialog>
    <ErrorDialog
      :isActive="errorDialog"
      v-bind:tittleText="$t('general.dialog.error.title')"
      :messageText="errorText"
      v-bind:acceptButtonText="$t('general.dialog.error.accept_button')"
      v-on:accept-dialog="errorDialog = false">
    </ErrorDialog>
  </div>
</template>

<script>
import router from '../../../plugins/router/index';
import { getAllCompanies, deleteCompany } from '../../../viewModels/adminViewModel';
import { DeleteCompanyException } from '../../../server/petitions/companies/deleteCompany';
import { GetCompaniesException } from '../../../server/petitions/companies/getCompanies';
import ConfirmationDialog from '../../../components/confirmationDialog.vue';
import ErrorDialog from '../../../components/errorDialog.vue';
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';

export default {
  components: {
    ConfirmationDialog,
    ErrorDialog,
  },
  data: () => ({
    theme: ls.get('theme') ?? 'ligth',
    page: 1,
    pageCount: 0,
    itemsPerPage: 8,
    isTableLoading: true,
    search: '',
    companiesTableData: [],
    deleteDialog: false,
    dialogText: '',
    companyToDelete: null,
    deleteMode: 'soft',
    errorDialog: false,
    errorText: '',
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '../dashboard/temperatures',
      },
      {
        text: 'Empresas',
        disabled: true,
        href: 'admin/companies',
      },
    ],
  }),
  methods: {
    editCompany: function (e) {
      router.push({
        name: 'editCompany',
        params: { companyId: e._id, companyData: e },
      });
    },
    removeCompany: async function (mode) {
      try {
        await deleteCompany(this.companyToDelete._id, mode);

        this.companyToDelete = null;
        this.deleteDialog = false;
      } catch (e) {
        switch (e.code) {
          case DeleteCompanyException.unknownError:
          case DeleteCompanyException.incorrectParameters:
          case DeleteCompanyException.invalidToken:
          case DeleteCompanyException.expiredToken:
            console.error(e);
            this.errorText = this.$t('general.error.unexpected_error');
            this.errorDialog = true;
            break;
          case DeleteCompanyException.unknownCompany:
            this.errorText = this.$t('company.delete.error.unknown_company');
            this.errorDialog = true;
            break;
          case DeleteCompanyException.userSharesDevices:
            this.errorText = this.$t('company.delete.error.user_has_clients');
            this.errorDialog = true;
            break;
          default:
            console.error('CATCH ERROR:', e);
        }
      }
    },
    openDialog: function (e) {
      this.dialogText = this.$t('company.delete.warning.company_will_delete', { name: e.name });
      this.companyToDelete = e;
      this.deleteDialog = true;
    },
    closeDialog: function () {
      this.deleteDialog = false;
      this.companyToDelete = null;
    },
  },
  mounted: async function () {
    try {
      eventBus.$on('changeTheme', (theme) => {
        this.theme = theme;
      });
      this.companiesTableData = await getAllCompanies();
      this.isTableLoading = false;
    } catch (e) {
      switch (e.code) {
        case GetCompaniesException.unknownError:
        case GetCompaniesException.incorrectParameters:
        case GetCompaniesException.invalidToken:
        case GetCompaniesException.expiredToken:
          console.error(e);
          this.errorText = this.$t('general.error.unexpected_error');
          this.errorDialog = true;
          break;
        default:
          console.error('CATCH ERROR:', e);
      }
    }
  },
  computed: {
    companiesTableHeader() {
      return [
        {
          text: this.$t('company.atribute.name'),
          value: 'name',
          width: '12rem',
          divider: true,
        },
        {
          text: this.$t('company.atribute.email'),
          value: 'email',
          width: '15.5rem',
          divider: true,
        },
        {
          text: this.$t('company.atribute.phone'),
          value: 'phone',
          width: '10rem',
          divider: true,
        },
        {
          text: this.$t('company.atribute.cif'),
          value: 'cif',
          width: '8.6rem',
          divider: true,
        },
        {
          text: this.$t('company.atribute.notes'),
          value: 'notes',
          width: '14rem',
          divider: true,
        },
        {
          text: '',
          value: 'actions',
          width: '2rem',
          divider: true,
          sortable: false,
        },
      ];
    },
  },
};
</script>
