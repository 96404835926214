const PetitionError = require('../../petitionError');
const { put } = require('../../httpRequester');

class UpdateMaintenanceException extends PetitionError {
  constructor(code) {
    super(' ERROR : Error when updating the maintenance', code);
  }
}

async function updateMaintenancePetition(token, payload) {
  const response = await put('maintenances/', payload, token);

  if (response.result !== UpdateMaintenanceException.success) {
    throw new UpdateMaintenanceException(response.result);
  } else {
    return response;
  }
}

module.exports = { updateMaintenancePetition, UpdateMaintenanceException };
