<template>
  <v-card
    :key="device.device_sn"
    class="mx-2 pa-1 gps-card"
    @click="showInfo(device)"
    :ripple="{ class: `primary--text` }"
    outlined
    :style="isSelectedCard">
    <div class="point" :style="isSelected"></div>

    <v-row class="card-gps-title">
      <v-col class="pb-0">
        <div class="header-gps-device rounded elevation-2 white grey--text font-weight-bold mr-1">
          <div class="header-gps-device-contain d-flex justify-space-between ml-3 text-truncate">
            <span class="header-gps-device-title">{{ carplate }}</span>
            <div>
              <v-chip
                color="blue-grey lighten-5"
                style="max-width: 100%; margin-right: 5px"
                class="elevation-1 px-1"
                x-small
                label>
                <font-awesome-icon v-if="isInsideAPoi" icon="fa-solid fa-circle-exclamation" class="indicador-exclamation" />
                <v-tooltip bottom open-delay="500" color="rgba(0, 0, 0, 0.85)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @mouseover="getGpsTimestampTooltip" v-bind="attrs" v-on="on" small :class="isGPSActive">
                      mdi-wifi
                    </v-icon>
                  </template>
                  <span>{{ gpsTimestampTooltip }}</span>
                </v-tooltip>
              </v-chip>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="card-temperatures-title" style="display: flex; justify-content: flex-end">
      <v-chip
        v-if="tag"
        color="green"
        class="elevation-1 px-1"
        style="max-width: 100%; margin-right: 19px; font-weight: bold"
        x-small
        label>
        <v-tooltip bottom open-delay="500" color="rgba(0, 0, 0, 0.85)">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"> {{ tag }}</span>
          </template>
          <span> {{ $t('user.configuration.label') }}</span>
        </v-tooltip>
      </v-chip>
    </v-row>

    <v-row class="card-gps-label text-sm-caption">
      <v-col class="device-frame-gps font-weight-medium pt-2" :class="locationClass">
        <v-tooltip bottom open-delay="700" color="rgba(0, 0, 0, 0.85)">
          <template v-slot:activator="{ on, attrs }">
            <span
              @mouseover="getGpsTimestampTooltip"
              v-bind="attrs"
              v-on="on"
              class="d-flex align-center"
              :class="locationClass"
              style="max-width: 90%">
              <span v-if="!isMobile">{{ getGpsHourTimestamp }}</span>
              <v-icon small :class="locationClass"> mdi-map-marker </v-icon>
              <span class="d-inline-block text-truncate" style="max-width: 60%">
                {{ getGeocode }}
              </span>
            </span>
          </template>
          <span v-if="device.last_frames.frame_gps">{{ gpsTimestampTooltip }}</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-container class="card-gps-temperature ma-1">
      <v-row style="width: 100%">
        <v-col class="d-flex justify-center pa-1">
          <v-tooltip bottom open-delay="500" color="rgba(0, 0, 0, 0.85)">
            <template v-slot:activator="{ on, attrs }">
              <v-chip small v-bind="attrs" v-on="on">
                {{ getTodayMileage }}
              </v-chip>
            </template>
            <span>{{ this.$t('dashboard.device_card.mileage_today') }}</span>
          </v-tooltip>
        </v-col>

        <v-divider vertical></v-divider>

        <v-col class="d-flex justify-center pa-1">
          <v-tooltip bottom open-delay="500" color="rgba(0, 0, 0, 0.85)">
            <template v-slot:activator="{ on, attrs }">
              <v-chip small v-bind="attrs" v-on="on">
                {{ getOdometer }}
              </v-chip>
            </template>
            <span>{{ this.$t('dashboard.device_card.odometer') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { getUser, getToken } from '../../../../viewModels/userViewModel';
import { calculateTodayMileage, getFramesDevice } from '../../../../viewModels/devicesViewModel';
import { getReverseGeocode } from '../../../../viewModels/devicesViewModel';
import { checkNotifications, gpsHourTimestamp } from '../../../../utils/device';
import { getAllPOIS } from '../../../../server/petitions/pois/getAllPOIS';
const VueI18n = require('../../../../plugins/lang/index');

const MOVEMENT = 'movement';
export default {
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    user: null,
    token: null,
    carplate: '',
    units: 'km',
    geocode: VueI18n.default.t('general.table.loading'),
    gpsTimestampTooltip: VueI18n.default.t('general.table.loading'),
    km: '',
    locationClass: '',
    tagClass: 'teal lighten-1',
    myPOIS: null,
    movements: [],
    tag: '',
  }),
  mounted: async function () {
    this.device.select = false;
    this.user = this.$store.state.currentUser ?? (await getUser());
    this.token = getToken();
    this.myPOIS = await getAllPOIS(this.token, this.user.userId);
    this.getCarPlate();
    this.units = this.user.unit;

    for (let device of this.user.devices) {
      if (device.device_id == this.device.device_id) this.tag = device.tags[0];
    }

    if (this.device.last_frames.frame_gps) {
      this.movements = await getFramesDevice(this.device.device_sn);
      let actualTime = new Date().getTime();
      let frameTime = new Date(this.device.last_frames.frame_gps.gps_timestamp).getTime();

      if (actualTime - frameTime > 3600000) {
        this.locationClass = 'grey--text';
      } else if (actualTime - frameTime > 1200000) {
        this.locationClass = 'orange--text';
      } else {
        this.locationClass = 'green--text';
      }
    }

    if (this.device.last_frames.frame_gps) {
      if (this.device.last_frames.frame_gps.geocode) {
        this.geocode = this.device.last_frames.frame_gps.geocode;
      } else {
        this.geocode = await getReverseGeocode(
          this.device.last_frames.frame_gps.latitude,
          this.device.last_frames.frame_gps.longitude,
          this.device.device_sn,
        );
        this.device.last_frames.frame_gps.geocode = this.geocode;
      }
    }
  },
  methods: {
    distanceCoordinates(coords1, coords2) {
      var ML = (((coords1.latitude + coords2.latitude) / 2) * Math.PI) / 180;
      var KPDlat = 111.13209 - 0.56605 * Math.cos(2 * ML) + 0.0012 * Math.cos(4 * ML);
      var KPDlon = 111.41513 * Math.cos(ML) - 0.09455 * Math.cos(3 * ML) + 0.00012 * Math.cos(5 * ML);

      var NS = KPDlat * (coords1.latitude - coords2.latitude);
      var EW = KPDlon * (coords1.longitude - coords2.longitude);

      return 1000 * Math.sqrt(NS * NS + EW * EW);
    },
    capitalizeName(name) {
      return name[0].toUpperCase() + name.toLowerCase().slice(1);
    },

    showInfo(device) {
      this.device.select = true;
      this.$emit('onShowInfo', device);
    },
    getCarPlate() {
      for (let userDevice of this.user.devices) {
        if (userDevice.device_id == this.device.device_id) {
          this.carplate = userDevice.name || this.device.device_data_from_frame.carplate || this.device.device_sn;
        }
      }
    },
    formatDate(date) {
      let months = [
        this.$t('general.months.january'),
        this.$t('general.months.february'),
        this.$t('general.months.march'),
        this.$t('general.months.april'),
        this.$t('general.months.may'),
        this.$t('general.months.june'),
        this.$t('general.months.july'),
        this.$t('general.months.august'),
        this.$t('general.months.september'),
        this.$t('general.months.october'),
        this.$t('general.months.november'),
        this.$t('general.months.december'),
      ];
      let splitedDate = date.split('-');
      let formatedDate = `${new Date(date).getDate()} ${this.$t('general.form.of')} ${
        months[new Date(date).getMonth()]
      } ${this.$t('general.form.of')} ${splitedDate[0]}`;
      return formatedDate;
    },
    getGpsTimestampTooltip() {
      const oneDay = 24 * 60 * 60 * 1000;
      const date = new Date(this.device.last_frames.frame_gps.gps_timestamp);
      const actualDate = new Date();
      const formatedDate = this.formatDate(this.device.last_frames.frame_gps.gps_timestamp);
      const diffDays = Math.round(Math.abs((actualDate - date) / oneDay));

      if (diffDays < 1) {
        const diffHours = Math.round(Math.abs((actualDate - date) / 1000 / 3600));
        if (diffHours > 1) {
          this.gpsTimestampTooltip = this.$t('dashboard.details.last_measure_hours', {
            hours: diffHours,
          });
        }
        const diffMinutes = Math.round(Math.abs((actualDate - date) / 1000 / 60));
        if (diffMinutes > 1) {
          this.gpsTimestampTooltip = this.$t('dashboard.details.last_measure_minutes', {
            minutes: diffMinutes,
          });
        }
        this.gpsTimestampTooltip = this.$t('dashboard.details.last_measure_recently');
      }

      if (diffDays < 7) {
        this.gpsTimestampTooltip = this.$t('dashboard.details.last_measure_in_days', {
          days: diffDays,
        });
      }

      this.gpsTimestampTooltip = this.$t('dashboard.details.last_measure_date', {
        date: formatedDate,
      });
    },
    // hasNotifications() {
    //   if (this.device.last_frames.frame_status) {
    //     //let notifications = checkNotifications(false, false, false, this.isInsideAPoi());
    //     return this.isInsideAPoi();
    //   }
    // },
  },
  computed: {
    isInsideAPoi() {
      let insidePoi = false;
      if (this.myPOIS?.length > 0) {
        for (let poi of this.myPOIS) {
          const distance = this.distanceCoordinates(poi, this.device.last_frames.frame_gps);
          if (distance <= poi.radius) {
            insidePoi = true;
          }
        }
      }
      return insidePoi;
    },
    isGPSActive() {
      const actualDate = new Date();
      const date = new Date(this.device.last_frames.frame_gps.device_timestamp);
      const diffMinutes = Math.round(Math.abs((actualDate - date) / 1000 / 60));

      if (diffMinutes < 10) {
        return 'frigoActive blue--text';
      }
      if (diffMinutes < 45) {
        return 'blue--text';
      }
      return 'grey--text';
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    model() {
      return this.capitalizeName(this.device.model);
    },
    isSelected() {
      if (this.device.select) {
        return {
          width: '5px',
          height: '48px',
          background: '#00BFA5',
          float: 'left',
          position: 'absolute',
          right: '-14px',
          top: '28px',
          visibility: 'visible',
          'border-radius': '40%',
        };
      }
      return 'width: 94%';
    },
    isSelectedCard() {
      if (this.device.select) {
        return {
          'box-shadow': 'rgb(38, 57, 77) 0px 20px 30px -10px',
        };
      }
    },
    getGeocode() {
      return this.geocode;
    },
    getOdometer() {
      if (this.units == 'imperial') {
        const convertedOdometer = ((this.device.last_frames.frame_gps.odometer.offset_applied * 1000) / 1609.34).toFixed(2);
        return convertedOdometer + ' ml';
      }
      if (this.device.last_frames.frame_gps.odometer.offset_applied) {
        this.km = ' km';
      }
      return (this.device.last_frames.frame_gps.odometer.offset_applied.toFixed() || 'No data') + this.km;
    },

    getTodayMileage() {
      var mileageSinceMidnight = 0;
      this.movements?.forEach((e) => {
        if (e.type == MOVEMENT) mileageSinceMidnight += e.distance;
      });

      if (this.units == 'imperial') {
        return ((mileageSinceMidnight * 1000) / 1609.34).toFixed(0) + ' ml';
      }

      return Math.abs(mileageSinceMidnight.toFixed(0)) + ' km';
    },

    getGpsHourTimestamp() {
      if (!this.device.last_frames.frame_gps) {
        return 'No data';
      }
      return gpsHourTimestamp(this.device.last_frames.frame_gps.gps_timestamp);
    },
  },
};
</script>
