const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class ModifyCompanyException extends PetitionError {
  static nameAlreadyInCompany = 'NAME_ALREADY_IN_COMPANY';

  static cifAlreadyInCompany = 'CIF_ALREADY_IN_COMPANY';

  constructor(code) {
    super('Modify Company', code);
  }
}

async function modifyCompany(
  company_id,
  company_name,
  company_cif,
  company_phone,
  company_email,
  company_notes,
  token,
) { // TODO add security
  const payload = {
    company_id,
    company_name,
    company_cif,
    company_phone,
    company_email,
    company_notes,
  };

  const response = await post('companies/modify_company', payload, token);

  if (response.result !== ModifyCompanyException.success) {
    throw (new ModifyCompanyException(response.result));
  } else {
    return response.company; // TO DO transform
  }
}

// class ModifyCompanyException extends PetitionError {
//   static nameAlreadyInCompany = 'NAME_ALREADY_IN_COMPANY';

//   static cifAlreadyInCompany = 'CIF_ALREADY_IN_COMPANY';

//   constructor(code) {
//     super('Modify Company', code);
//   }
// }

module.exports = {
  modifyCompany,
  ModifyCompanyException,
};
