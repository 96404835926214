const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class AssignBeaconToUserException extends PetitionError {
  constructor(code) {
    super('Assign beacon to user', code);
  }
}

async function assignBeaconToUser(beacon_id, users_ids, mac_address, alias, notes, category, token) {
  const payload = { beacon_id, users_ids, mac_address, alias, notes, category };

  const response = await post('users/assign_beacon', payload, token);

  if (response.result !== AssignBeaconToUserException.success) {
    throw new AssignBeaconToUserException(response.result);
  } else {
    return response.user;
  }
}

module.exports = { assignBeaconToUser, AssignBeaconToUserException };
