<template>
  <div v-if="!isMobile" class="context-menu" v-show="display" :style="style" ref="context" tabindex="0">
    <slot></slot>
  </div>
  <div v-else class="context-menu" v-show="display" :style="style" ref="context" tabindex="0" @click="close">
    <slot></slot>
  </div>
</template>
<script>
import Vue from 'vue';

export default {
  name: 'ContextMenu',
  props: {
    display: Boolean, // prop detect if we should show context menu
  },
  data() {
    //SE ha removido el @blur:"close" de la línea 7, ahora va el mouseOverCard pero el create poi no.
    return {
      left: 0, // left position
      top: 0, // top position
      show: false, // affect display of context menu
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    // get position of context menu
    style() {
      return {
        top: this.top + 'px',
        left: this.left + 'px',
      };
    },
  },
  methods: {
    // closes context menu
    closeWeb() {
      this.show = false;
    },
    close() {
      this.show = false;
      // this.left = 0;
      // this.top = 0;
    },
    open(evt) {
      // updates position of context menu
      this.left = evt.pageX || evt.clientX;
      this.top = evt.pageY || evt.clientY;
      // make element focused
      // @ts-ignore
      Vue.nextTick(() => this.$el.focus());
      this.show = true;
    },
  },
};
</script>
<style>
.context-menu {
  position: fixed;

  z-index: 999;
  outline: none;

  cursor: pointer;
  border-radius: 6px;
  padding: 4px;
}
</style>
