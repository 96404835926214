const { getUserdevices } = require('../server/petitionsMqtt/devices/getUserDevices');
const { getMyDevices } = require('../server/petitions/users/getUserDevices');

const { getUser } = require('./userViewModel');

const { getToken } = require('./userViewModel');

const { getStartOfTheDayMileage } = require('../server/petitions/devices/getStartOfTheDayMileage');

const { getStartOfTheDayEngineHours } = require('../server/petitions/devices/getStartOfTheDayEngineHours');

const { getAddress } = require('../server/petitions/devices/getGeocode');

const { setGeocode } = require('../server/petitions/devices/setGeocode');

const { getLocationsTodayPetition } = require('../server/petitions/devices/getLocationsTodayPetition');
const { getLocationsByDayPetition } = require('../server/petitions/devices/getLocationsByDay');

const { getFramesTimeLine } = require('../server/petitions/devices/getFramesTimeLine');
const userViewModel = require('./userViewModel');

const { saveConfigDevice } = require('../server/petitions/devices/saveConfigDevice');
const { saveOdometerDevice } = require('../server/petitions/devices/saveOdometer');
const { turnOffDevice } = require('../server/petitions/devices/turnOff');

const { saveFrigoHoursDevice } = require('../server/petitions/devices/saveFrigoHours');
const { saveSetpointDevice } = require('../server/petitions/devices/saveSetpoint.js');

const { shareMyDevice } = require('../server/petitions/devices/shareDevice');

const { getSharedDevices } = require('../server/petitions/devices/getSharedDevices');
const { deleteSharedDevice } = require('../server/petitions/devices/deleteSharedDevice');
const { getLinkedDevicePetition } = require('../server/petitions/devices/getLinkedDevicePetition');

const store = require('../plugins/store/index');
const { createCertificate } = require('../server/petitions/devices/createCertificate');
const { getAllCertificate } = require('../server/petitions/devices/getCertificate');
const { getAdminCertificate } = require('../server/petitions/devices/getAdminCertificate');
const { deleteCertificate } = require('../server/petitions/devices/deleteCertificate');
const { updateCertificatePetition } = require('../server/petitions/devices/updateCertificate');

const { formatDate } = require('../utils/utils');

const UserDevicesViewModel = function () {
  let devices = [];

  let sharedDev = [];

  const saveCertificate = async (certificateFile, name, device_sn, nextInspectionDate, days, id, alias) => {
    try {
      const token = userViewModel.getToken();
      const certificate = { certificateFile, name, device_sn, nextInspectionDate, days, id, alias };

      return await createCertificate(token, certificate);
    } catch (error) {
      console.log(error);
    }
  };
  const getCertificates = async (userId) => {
    try {
      const token = userViewModel.getToken();

      return await getAllCertificate(token, userId);
    } catch (error) {
      console.log(error);
    }
  };
  const getAdminCertificates = async (deviceSn) => {
    try {
      const token = userViewModel.getToken();

      return await getAdminCertificate(token, deviceSn);
    } catch (error) {
      console.log(error);
    }
  };
  const removeCertificate = async (certificateId, deviceSn) => {
    try {
      const token = userViewModel.getToken();

      const removeFile = await deleteCertificate(token, certificateId, deviceSn);
    } catch (error) {
      console.log(error);
    }
  };

  const updateCertificate = async (payload) => {
    try {
      const token = userViewModel.getToken();

      return await updateCertificatePetition(token, payload);
    } catch (error) {
      console.log(error);
    }
  };

  function getUserDevices() {
    // Usamos el setTimeout para que rellene de forma correcta
    setTimeout(function () {
      store.default.dispatch('setUpdatedDevices', devices);
    }, 4000);

    return devices;
  }

  async function getMyFullDevices(devices) {
    try {
      const token = userViewModel.getToken();

      const myDevices = await getMyDevices(devices, token);
      return myDevices;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  function reset() {
    devices = [];
    sharedDev = [];
  }

  async function getFramesDevice(device_sn, dates = '', resolveGeocode = false) {
    try {
      if (!dates) {
        const today = new Date();
        const currentDay = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
        dates = [currentDay, currentDay];
      }

      const token = userViewModel.getToken();
      const locations = await getFramesTimeLine(device_sn, token, dates, resolveGeocode);
      return locations;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async function saveConfig(device_sn, config) {
    const isEqualSn = (device) => device.device_sn === device_sn;
    const index = devices.findIndex(isEqualSn);
    const token = userViewModel.getToken();
    // devices[index].name = config.name;
    // devices[index].probes_config = config.probes_config;
    const user = await userViewModel.getUser();
    const user_id = user.email;
    const response = await saveConfigDevice(token, device_sn, config, user_id);
    return response;
  }

  async function saveOdometer(device_sn, odometer, offset) {
    const token = userViewModel.getToken();
    const response = await saveOdometerDevice(token, device_sn, odometer, offset);
    return response;
  }

  async function saveFrigoHours(device_sn, frigoHours, offset) {
    const token = userViewModel.getToken();
    const response = await saveFrigoHoursDevice(token, device_sn, frigoHours, offset);
    return response;
  }
  async function saveSetpoint(payload) {
    const token = userViewModel.getToken();
    const response = await saveSetpointDevice(token, payload);
    return response;
  }

  async function getUserDevicesLittleByLittle(onGetDevice) {
    try {
      const token = userViewModel.getToken();
      let dispositivos = [];
      await getUserdevices(token, (device, isLast) => {
        if (!dispositivos.includes(device.device_sn)) {
          dispositivos.push(device.device_sn);
          devices.push(device);
          onGetDevice(device, isLast);
        }
      });
    } catch (e) {
      console.error('ERROR: ', e);

      return null;
    }
  }

  async function getLocationsToday(device_sn) {
    try {
      const token = userViewModel.getToken();
      const locations = await getLocationsTodayPetition(device_sn, token);
      return locations;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async function getLocationsByDay(device_sn, dates) {
    try {
      const token = userViewModel.getToken();
      const locations = await getLocationsByDayPetition(device_sn, dates, token);
      return locations;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async function calculateTodayMileage(device_sn) {
    try {
      const token = userViewModel.getToken();
      const startOfTheDayMileage = await getStartOfTheDayMileage(device_sn, token);
      if (startOfTheDayMileage) {
        return startOfTheDayMileage;
      }
      return 0;
    } catch (e) {
      console.error(e);

      return null;
    }
  }

  async function calculateTodaysEngineHours(actualEngineHours, device_sn) {
    try {
      const token = userViewModel.getToken();
      const startOfTheDayEngineHours = await getStartOfTheDayEngineHours(device_sn, token);
      if (startOfTheDayEngineHours) {
        const todaysEngineHours = (actualEngineHours - startOfTheDayEngineHours.raw).toFixed(0);
        return todaysEngineHours;
      }
      return 0;
    } catch (e) {
      console.error(e);

      return null;
    }
  }

  async function getReverseGeocode(lat, lng, serialNumber) {
    try {
      const token = userViewModel.getToken();
      const address = await getAddress(lat, lng, token);
      if (address?.addressData) {
        const reverseGeocode = address.addressData;
        const location = reverseGeocode?.displayFormatted;

        if (location) {
          await setGeocode(location, serialNumber, token);

          for (const device of devices) {
            if (device.device_sn == serialNumber) {
              device.last_frames.frame_gps.geocode = location;
            }
          }
        }
        return location;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function shareDevice(payload) {
    const token = userViewModel.getToken();
    const response = await shareMyDevice(payload, token);
    for (const device of response.sharedDevices) {
      device.from = formatDate(device.from);
      device.to = formatDate(device.to);
      sharedDev.push(device);
    }
    return response;
  }

  async function getShared(email) {
    if (sharedDev.length == 0) {
      const token = getToken();
      sharedDev = await getSharedDevices(email, token);
      return sharedDev;
    }
    return sharedDev;
  }

  async function deleteShared(email, sn) {
    const token = await getToken();
    user = await userViewModel.getUserByToken(token);
    const res = await deleteSharedDevice(token, user, email, sn);
  }

  function updateTable(res) {
    for (const shared of sharedDev) {
      if (shared.email === res.client) {
        const indexToDelete = sharedDev.indexOf(shared);
        sharedDev.splice(indexToDelete, 1);
      }
    }
  }

  async function getLinkedTracker(device_sn) {
    try {
      const token = userViewModel.getToken();
      const tracker = await getLinkedDevicePetition(device_sn, token);
      return tracker;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async function turnOff(device_sn, stopFlag) {
    try {
      const token = userViewModel.getToken();
      const tracker = await turnOffDevice(device_sn, stopFlag, token);
      return tracker;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  return {
    getMyFullDevices,
    turnOff,
    getUserDevices,
    getLinkedTracker,
    getUserDevicesLittleByLittle,
    calculateTodayMileage,
    calculateTodaysEngineHours,
    getReverseGeocode,
    reset,
    saveConfig,
    getLocationsToday,
    getFramesDevice,
    shareDevice,
    getShared,
    deleteShared,
    updateTable,
    saveOdometer,
    saveFrigoHours,
    saveSetpoint,
    getLocationsByDay,
    saveCertificate,
    getCertificates,
    getAdminCertificates,
    removeCertificate,
    updateCertificate,
  };
};

module.exports = UserDevicesViewModel();
