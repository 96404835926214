const PetitionError = require("../../petitionError");
const { getAnonymous } = require("../../httpRequester");

class getSharedDevicesException extends PetitionError {
    constructor(code) {
        super("Get Notification", code);
    }
}

async function getSharedDevices(user_email, token) {
    const response = await getAnonymous(`users/shared_devices?email=${user_email}&token=${token}`);
    if (response.result !== getSharedDevicesException.success) {
        return getSharedDevicesException.unknownError;
    }
    return response.devices;
}

// class getSharedDevicesException extends PetitionError {
//   constructor(code) {
//     super('Get Notification', code);
//   }
// }

module.exports = {
    getSharedDevices,
    getSharedDevicesException,
};
