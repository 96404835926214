var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isMobile)?_c('div',[_c('v-container',{staticClass:"my-3"},[_c('v-row',[_c('v-col',[_c('h1',{staticClass:"d-flex justify-start"},[_vm._v(_vm._s(_vm.$t('shared.devices')))])]),_c('v-col',[(_vm.blockButton)?_c('v-btn',{staticClass:"float-right",style:({
            color: _vm.colorTheme,
            'background-color': _vm.backgroundcolorTheme,
          }),attrs:{"disabled":"","color":"primary"},on:{"click":_vm.openShare}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-share")]),_vm._v(" "+_vm._s(_vm.$t('shared.max')))],1):_c('v-btn',{staticClass:"float-right",style:({
            color: _vm.colorTheme,
            'background-color': _vm.backgroundcolorTheme,
          }),attrs:{"data-cy":"shareDevice","color":"primary"},on:{"click":_vm.openShare}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-share")]),_vm._v(_vm._s(_vm.$t('shared.share')))],1)],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"shared-data-table elevation-1",attrs:{"loading":_vm.isTableLoading,"headers":_vm.reportsTableHeader,"items":_vm.devicesTable,"hide-default-footer":"","page":_vm.page,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"loading-text":_vm.$t('general.table.loading')},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-1",attrs:{"medium":"","color":"error","data-cy":"delete"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteRow(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"top",fn:function(){return [_c('v-text-field',{staticStyle:{"width":"100%"},attrs:{"label":_vm.$t('shared.search'),"prepend-inner-icon":" "},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"dark":_vm.theme === 'dark',"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1),_c('Confirm',{ref:"confirm"}),_c('SharedDevice',{attrs:{"devicesTable":_vm.devicesTable,"isActive":_vm.shared},on:{"cancel-share":_vm.closeShare,"result-share":_vm.manageResultShare}}),_c('v-snackbar',{attrs:{"timeout":_vm.snackbarTimeout,"color":_vm.sharedResult === 'success' ? 'green' : 'red'},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"black","text":""},on:{"click":function($event){_vm.sharedSuccess = '';
          _vm.showSnackbar = false;}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('shared.close'))+" ")])]}}],null,false,4286377200),model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.sharedResult ? _vm.$t(`shared.share_${_vm.sharedResult}`) : '')+" ")])],1):_c('div',[_c('v-container',{staticClass:"my-3 shared-device-mobile"},[_c('v-row',[_c('v-col',[_c('h1',{staticClass:"d-flex justify-start"},[_vm._v(_vm._s(_vm.$t('shared.devices')))])]),_c('v-col',[(_vm.blockButton)?_c('v-btn',{staticClass:"float-right",style:({
            color: _vm.colorTheme,
            'background-color': _vm.backgroundcolorTheme,
          }),attrs:{"disabled":"","color":"primary"}},[_c('v-icon',{staticClass:"mr-3",style:({ color: _vm.colorTheme })},[_vm._v("mdi-share")]),_vm._v(_vm._s(_vm.$t('shared.max')))],1):_c('v-btn',{staticClass:"float-right",style:({
            color: _vm.colorTheme,
            'background-color': _vm.backgroundcolorTheme,
          }),attrs:{"color":"primary"},on:{"click":_vm.openShare}},[_c('v-icon',{staticClass:"mr-3",style:({ color: _vm.colorTheme })},[_vm._v("mdi-share")]),_vm._v(_vm._s(_vm.$t('shared.share')))],1)],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.reportsTableHeader,"items":_vm.devicesTable,"hide-default-footer":"","page":_vm.page,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"loading-text":_vm.$t('general.table.loading')},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-1",attrs:{"medium":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteRow(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"top",fn:function(){return [_c('v-text-field',{staticStyle:{"width":"100%"},attrs:{"label":_vm.$t('shared.search'),"prepend-inner-icon":" "},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"dark":_vm.theme === 'dark',"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1),_c('Confirm',{ref:"confirm"}),_c('SharedDevice',{attrs:{"isActive":_vm.shared},on:{"cancel-share":_vm.closeShare,"result-share":_vm.manageResultShare}}),_c('v-snackbar',{attrs:{"timeout":_vm.snackbarTimeout,"color":_vm.sharedResult === 'success' ? 'green' : 'red'},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"black","text":""},on:{"click":function($event){_vm.sharedSuccess = '';
          _vm.showSnackbar = false;}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('shared.close'))+" ")])]}}]),model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.sharedResult ? _vm.$t(`shared.share_${_vm.sharedResult}`) : '')+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }