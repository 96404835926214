<template>
  <v-form :dark="theme === 'dark' ? true : false" ref="poiCategoryForm" lazy-validation>
    <v-data-table
      :loading="isTableLoading"
      :headers="headers"
      :items="poiCategories"
      :search="search"
      class="elevation-1"
      fixed-header
      height="350px">
      <v-divider inset></v-divider>

      <template v-slot:top>
        <v-toolbar :style="{ 'background-color': theme === 'dark' ? '#1E1E1E' : '' }" flat>
          <v-row :style="{ 'background-color': theme === 'dark' ? '#1E1E1E' : '' }" class="mt-2">
            <v-col cols="9">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('dashboard.pois_categories.search')"
                dense
                outlined></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="3">
              <v-btn color="primary" class="white--text" @click="addNew" :disabled="editedIndex != -1">
                <v-icon dark>mdi-plus</v-icon> {{ $t('dashboard.pois_categories.category') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>

      <template v-slot:item.name="{ item }">
        <v-text-field
          v-model="editedItem.name"
          dense
          single-line
          :autofocus="true"
          :rules="validations.nameRules"
          :label="$t('dashboard.pois_categories.name')"
          v-if="item.index === editedItem.index" />
        <span v-else>{{ item.name }}</span>
      </template>

      <template v-slot:item.icon="{ item }">
        <v-file-input
          v-model="editedItem.iconInput"
          dense
          single-line
          show-size
          :rules="validations.iconRules"
          accept="image/png, image/jpeg"
          prepend-icon="fa-solid fa-cloud-arrow-up"
          :label="$t('dashboard.pois_categories.icon')"
          @change="onFileChange"
          v-if="item.index === editedItem.index" />

        <v-img v-else-if="item.icon != ''" :src="item.icon" max-width="32"></v-img>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-row v-if="item.index === editedItem.index" style="justify-content: space-evenly">
          <v-icon color="red" @click="close"> mdi-window-close </v-icon>
          <v-icon color="primary" @click="save"> mdi-content-save </v-icon>
        </v-row>

        <v-row v-else style="justify-content: space-evenly">
          <v-icon color="primary" @click="editItem(item)"> mdi-pencil </v-icon>
          <v-icon color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
        </v-row>
      </template>

      <template v-slot:no-data>
        <v-label>{{ $t('general.error.no_data') }}</v-label>
      </template>
    </v-data-table>
    <Confirm ref="confirm"></Confirm>
  </v-form>
</template>

<script>
import Confirm from '../../../components/confirm.vue';
import { getAllPOICategories } from '../../../server/petitions/pois/getAllPOICategories';
import { postPOICategory } from '../../../server/petitions/pois/postPOICategory';
import { deletePOICategory } from '../../../server/petitions/pois/deletePOICategory';
import { getToken, getUser } from '../../../viewModels/userViewModel';
import { omit, convertBase64 } from '../../../utils/utils';
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';

export default {
  components: {
    Confirm,
  },
  data: () => ({
    theme: ls.get('theme') ?? 'ligth',
    isTableLoading: true,
    search: '',
    poiCategories: [],
    editedIndex: -1,
    editedItem: {
      index: 0,
      name: '',
      icon: '',
      iconInput: [],
    },
    defaultItem: {
      index: 0,
      name: 'Nueva categoría',
      icon: '',
      iconInput: [],
    },
    dialogPOICategoryDelete: false,
    token: '',
    currentUser: {},
    defaultIcon: '/assets/markers/pi.png',
  }),
  methods: {
    async editItem(item) {
      this.editedIndex = this.poiCategories.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },
    async getFileFromURL(url) {
      let response = await fetch(url, { mode: 'no-cors' });
      let data = await response.blob();
      let metadata = {
        type: 'image/png',
      };
      return new File([data], 'icon.png', metadata);
    },
    async deleteItem(item) {
      if (
        (await this.$refs.confirm.open(
          this.$t('dashboard.pois_categories.delete'),
          this.$t('dashboard.pois_categories.delete_confirm').replace('$1', item.name),
          {
            color: 'red',
          },
        )) &&
        !this.isTableLoading
      ) {
        this.isTableLoading = true;

        await deletePOICategory(this.token, item._id);

        const index = this.poiCategories.indexOf(item);
        this.poiCategories.splice(index, 1);
        this.isTableLoading = false;
      }
    },
    close() {
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    async addNew() {
      const addObj = Object.assign({}, this.defaultItem);
      addObj.index = this.poiCategories.length + 1;
      this.poiCategories.unshift(addObj);
      await this.editItem(addObj);
    },
    async save() {
      if (!this.isFormValid || this.isTableLoading) return;

      this.isTableLoading = true;

      let poiCategory = { ...omit(this.editedItem, 'index', 'iconInput'), userId: this.currentUser.userId };
      const resultCreatedPOICategory = await postPOICategory(this.token, poiCategory);

      this.editedItem = resultCreatedPOICategory.poiCategory;
      if (!this.editedItem.icon) this.editedItem.icon = this.defaultIcon;
      Object.assign(this.poiCategories[this.editedIndex], this.editedItem);

      this.close();
      this.isTableLoading = false;
    },
    async onFileChange(file) {
      this.editedItem.iconBase64 = await convertBase64(file);
    },
  },
  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
    this.currentUser = this.$store.state.currentUser ?? (await getUser());
    this.token = getToken();

    const poiCategoriesFromDb = await getAllPOICategories(this.token, this.currentUser.userId);

    poiCategoriesFromDb.map((poiCategoryFromDb, index) => {
      if (!poiCategoryFromDb.icon) poiCategoryFromDb.icon = this.defaultIcon;
      this.poiCategories.push({ index: index + 1, ...poiCategoryFromDb });
    });

    this.isTableLoading = false;
  },
  computed: {
    isFormValid() {
      return this.$refs.poiCategoryForm.validate();
    },
    headers() {
      return [
        {
          text: this.$t('dashboard.pois_categories.name'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('dashboard.pois_categories.icon'),
          value: 'icon',
          sortable: false,
        },
        { text: '', value: 'actions', sortable: false, width: '110px' },
      ];
    },
    validations() {
      return {
        nameRules: [
          (value) => !!value || this.$t('general.error.empty_value'),
          (value) => (value && value.length <= 40) || this.$t('dashboard.pois_categories.validation_rules.category_length'),
        ],
        iconRules: [
          (value) =>
            value.size == undefined || value.size < 100000 || this.$t('dashboard.pois_categories.validation_rules.icon_size'),
        ],
      };
    },
  },
};
</script>
