const PetitionError = require('../../petitionError');
const { postRaw } = require('../../httpRequester');
const { GetTemperatureTicketException } = require('./getConfig');
const { downloadReport } = require('../../../utils/utils');

class GetReportException extends PetitionError {
  static unknownDevice = 'UNKNOWN_DEVICE';

  static noDataInDate = 'NO_DATA_IN_DATE';

  constructor(code) {
    super('Get Report', code);
  }
}

async function getReport(token, payload) {
  const response = await postRaw('reports/get_report', payload, token);

  if (response.status !== 200) {
    const result = await response.json();
    throw new GetTemperatureTicketException(result.result);
  } else {
    const fileName = 'Report';
    downloadReport(response, fileName);
  }
}

module.exports = {
  getReport,
  GetReportException,
};
