<template>
  <div class="text-center">
    <v-bottom-navigation style="border-radius: 10px; background-color: rgba(255, 255, 255, 0.7)">
      <template>
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn style="min-width: 0px !important" @click="poisRedirection" v-on="{ ...tooltip }">
              <v-icon>mdi mdi-map-marker-radius</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('dashboard.general.go-pois') }}</span>
        </v-tooltip>
      </template>

      <v-menu offset-y left bottom :rounded="'lg'">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                :disabled="poiCategoriesFromDb?.length <= 0"
                style="min-width: 0px !important"
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }">
                <v-icon>mdi mdi-filter-menu</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('dashboard.general.select-category') }}</span>
          </v-tooltip>
        </template>

        <v-list style="max-height: 12rem; overflow: auto; background-color: rgba(255, 255, 255, 0.7) !important">
          <v-list-item v-for="(item, index) in poiCategoriesFromDb" :key="index">
            <v-list-item-title style="cursor: pointer" @click="emitToMap(item.name)">
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <template>
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn style="min-width: 0px !important" v-if="isVisible" @click="visibleChange()" v-on="{ ...tooltip }">
              <v-icon>mdi mdi-eye-outline</v-icon>
            </v-btn>
            <v-btn style="min-width: 0px !important" v-else @click="visibleChange()" v-on="{ ...tooltip }">
              <v-icon>mdi mdi-eye-off-outline</v-icon>
            </v-btn>
          </template>
          <span v-if="isVisible">{{ $t('dashboard.general.hide-pois') }}</span>
          <span v-else>{{ $t('dashboard.general.show-pois') }}</span>
        </v-tooltip>
      </template>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { getAllPOICategories } from '../server/petitions/pois/getAllPOICategories';
import { getToken, getUser } from '../viewModels/userViewModel';
import router from '../plugins/router/index';
import { eventBus } from '../main';

export default {
  data: () => ({
    isVisible: true,
    poiCategoriesFromDb: null,
    user: null,
    token: null,
  }),

  mounted: async function () {
    this.token = getToken();
    this.user = this.$store.state.currentUser ? this.$store.state.currentUser : await getUser();
    this.poiCategoriesFromDb = await getAllPOICategories(this.token, this.user.userId);
  },
  methods: {
    poisRedirection() {
      eventBus.$emit('cleanSearch');
      router.push({
        name: 'pois',
      });
    },
    emitToMap(category) {
      this.isVisible = true;
      eventBus.$emit('categories-map', category);
    },
    visibleChange() {
      this.isVisible = !this.isVisible;
      eventBus.$emit('visible-change', this.isVisible);
    },
  },
};
</script>
