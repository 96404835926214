<template>
  <v-card class="elevation-2 ma-2">
    <v-card-text class="pt-0">
      <div class="text-overline font-weight-bold">
        {{ $t('dashboard.details.status') }}
      </div>
      <v-divider class="py-1 mx-2" style="border-color: #607d8b"></v-divider>

      <div class="ml-1 d-flex flex-column">
        <span class="mb-1">
          <v-icon :class="checkStatus" x-small> mdi-circle </v-icon>
          {{ stateTimestamp }}
        </span>

        <span v-if="device.last_frames.frame_status.battery_voltage" class="d-flex flex-nowrap align-center mb-1">
          <v-chip label x-small class="px-1 py-3">
            <v-icon style="width: 1.5rem" small class="justify-center"> mdi-battery-charging </v-icon>
          </v-chip>
          {{ device ? voltage.toFixed(2) : '...' }} V/BAT
          <v-progress-linear
            :value="batteryLevel"
            height="15"
            rounded
            :color="batteryColor"
            class="mx-3"
            style="width: 50%"
            :style="colorStyleBattery">
            {{ batteryPercentage }} %
          </v-progress-linear>
        </span>

        <span class="mb-1" :class="isContactOn ? 'font-weight-bold' : ''">
          <v-chip label x-small class="px-1 py-3" :color="isContactOn ? 'rgb(33, 150, 243, 0.7)' : ''">
            <!-- <v-icon small> mdi-key </v-icon> -->
            <font-awesome-icon style="width: 1rem" icon="fa-solid fa-key" class="mx-1" />
          </v-chip>
          {{ $t('dashboard.temp.alarms.ignition') }}
        </span>

        <span v-if="device.model !== 'tracker'" class="mb-1" :class="frigoEngineStatus() ? 'font-weight-bold' : ''">
          <v-chip label x-small class="px-1 py-3" :color="frigoEngineStatus() ? 'rgb(33, 150, 243, 0.7)' : ''">
            <!-- <v-icon small> mdi-snowflake </v-icon> -->
            <v-icon small style="width: 1.5rem"> mdi-engine </v-icon>
          </v-chip>
          {{ $t('dashboard.temp.alarms.engine') }}
        </span>
        <div>
          <span v-if="device.model == 'tracker'" class="mb-1" :class="remoteControlStatus() ? 'font-weight-bold' : ''">
            <v-chip label x-small class="px-1 py-3" :color="remoteControlStatus() ? 'rgb(255, 142, 142)' : ''">
              <!-- <v-icon small> mdi-snowflake </v-icon> -->

              <v-icon v-if="remoteControlStatus()" small> mdi-lock </v-icon>
              <v-icon v-else small> mdi-lock-open-variant</v-icon>
            </v-chip>
            {{ $t('device.immobilise') }}
          </span>
        </div>
        <span v-if="checkIsBeaconNerby" class="mb-1" :class="beaconStatus() ? 'font-weight-bold' : ''">
          <v-chip label x-small class="px-1 py-3" color="rgb(33, 150, 243, 0.7)">
            <v-icon style="width: 1.5rem" small> mdi-link-variant </v-icon>
          </v-chip>
          {{ alias }}
        </span>

        <span v-if="device.model !== 'tracker'" class="mb-1" :class="isCustomOn ? 'font-weight-bold' : ''">
          <v-chip label x-small class="px-1 py-3" :color="isCustomOn ? 'rgb(33, 150, 243, 0.7)' : ''">
            <!-- <v-icon small> mdi-octagram </v-icon> -->
            <font-awesome-icon style="width: 1rem" icon="fa-solid fa-star" class="mx-1" />
          </v-chip>
          {{ $t('dashboard.temp.alarms.custom') }}
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { getBeacon } from '../../../../viewModels/adminViewModel';
import { getUser } from '../../../../viewModels/userViewModel';

export default {
  components: {},
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
    batteryPercentage: {
      type: Number,
    },
    batteryColor: {
      type: String,
    },
    batteryLevel: {
      type: Number,
    },
    isCustomOn: {
      type: Boolean,
    },
    isContactOn: {
      type: Boolean,
    },
    status: {
      type: Object,
    },
    stateTimestamp: {
      type: String,
    },
    voltage: {
      type: Number,
    },
  },
  data: () => ({
    colorStyleBattery: null,
    beacon: {},
    user: null,
    alias: '',
    isBeacon: false,
    speed: 15,
    beaconNearby: false,
    minutesDeducted: 3 * 60000,
    beaconWithAlias: null,
  }),
  computed: {
    checkStatus() {
      if (this.status.status) {
        return 'green--text';
      }
      return 'gray--text';
    },
    checkIsBeaconNerby() {
      return this.beaconNearby;
    },
  },
  methods: {
    frigoEngineStatus() {
      return this.device.last_frames.frame_status.engine_on;
    },
    beaconStatus() {
      return this.device.last_frames.frame_status?.beacons?.beacon1;
    },
    remoteControlStatus() {
      return this.device.last_frames.frame_status.remote_control;
    },

    async checkBeacon() {
      this.user = await getUser();
      let macAddressBeacon = null;
      let singleBeacon = null;

      //Esto debería ser siempre cuando se crea el dispositivo un array vacio.
      if (this.user.beacons?.length > 0) {
        this.user.beacons.map((i) => {
          if (i.mac_address == this.device.last_frames.frame_status?.beacons?.beacon1?.macAddress) {
            macAddressBeacon = this.device.last_frames.frame_status?.beacons?.beacon1?.macAddress;
            singleBeacon = this.device.last_frames.frame_status?.beacons?.beacon1;
          }
        });
      }

      this.beacon = singleBeacon ?? null;
      if (this.beacon?.macAddress) {
        this.beaconWithAlias = await getBeacon(macAddressBeacon);
      }

      const timeCondition = new Date(this.beacon?.device_timestamp).getTime() >= new Date().getTime() - this.minutesDeducted;

      const speedCondition = this.device.last_frames.frame_gps?.speed >= this.speed;

      if (this.beacon && timeCondition && speedCondition) {
        this.alias = this.beaconWithAlias?.alias;
        this.beaconNearby = true;
        return this.beacon;
      } else {
        this.beaconNearby = false;
      }
    },
  },
  mounted: async function () {
    this.colorStyleBattery = this.batteryColor;
    this.checkBeacon();
  },
  updated() {
    this.checkBeacon();
  },
};
</script>
