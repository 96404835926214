<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ title }}</span>
    </v-card-title>

    <v-card-text>
      <v-stepper v-model="importerStep" vertical @keydown.esc="onCancel">
        <v-stepper-step :complete="importerStep > 1" step="1">
          {{ $t('dashboard.select_file') }}
          <small>{{ $t('dashboard.only_csv') }}</small>
        </v-stepper-step>
        <v-stepper-content step="1">
          <UploadFiles ref="uploadFiles" @fileUploadedChange="onFileUploadedChange" />
          <v-btn color="primary" :disabled="uploadedFile == null" @click="load"> {{ $t('dashboard.next') }} </v-btn>
          <v-btn text @click="onCancel"> {{ $t('general.dialog.confirmation.button.cancel') }} </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="importerStep > 2" step="2"> {{ $t('dashboard.select_fields') }}</v-stepper-step>

        <v-stepper-content step="2">
          <v-card outlined class="mb-3">
            <v-card-title>
              <label>{{ $t('dashboard.preview_csv') }}</label>
            </v-card-title>
            <v-card-text>
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <tr v-for="row in importedDataPreview.data">
                      <td v-for="col in row">{{ col }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>

          <v-card outlined class="mb-3 mt-2" min-height="200px">
            <v-card-title>
              <label>
                {{ $t('dashboard.csv_mapping') }}
              </label>
            </v-card-title>
            <v-card-text>
              <v-form ref="mappingFieldsForm">
                <v-virtual-scroll :items="fieldsToMap" height="200" item-height="60">
                  <template v-slot:default="{ item }">
                    <v-list-item :key="item.field" class="d-flex flex-wrap">
                      <v-list-item-content>
                        <v-select
                          required
                          :label="item.text"
                          dense
                          rounded
                          outlined
                          :rules="item.required ? requiredFieldRule : []"
                          v-model="mapping[item.field]"
                          item-text="column"
                          item-value="index"
                          :items="uploadedFileFirstRow" />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
              </v-form>
            </v-card-text>
          </v-card>
          <v-btn color="primary" @click="onFinish"> {{ $t('dashboard.import') }} </v-btn>
          <v-btn text @click="importerStep = 1"> {{ $t('dashboard.back') }} </v-btn>
        </v-stepper-content>
      </v-stepper>
    </v-card-text>

    <v-row v-if="errors.length > 0">
      <v-list-item v-for="(error, key) in errors" :key="key">
        <v-list-item-content>
          <v-list-item-title>
            {{ error }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-row>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="onCancel"> {{ $t('shared.close') }} </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import UploadFiles from './uploadFiles.vue';
import Papa from 'papaparse';

export default {
  name: 'CSVImporter',
  components: {
    UploadFiles,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    fieldsToMap: {
      type: Array,
      required: true,
    },
  },
  data: () => {
    return {
      importerStep: 1,
      uploadedFile: null,
      errors: [],
      importedDataPreview: [],
      importedData: [],
      mapping: {},
    };
  },
  methods: {
    load() {
      this.readFile((output) => {
        this.importedDataPreview = Papa.parse(output, { preview: 2, skipEmptyLines: true });
        this.importedData = Papa.parse(output, { skipEmptyLines: true });
      });
      this.importerStep = 2;
    },
    readFile(callback) {
      let file = this.uploadedFile;
      if (file) {
        let reader = new FileReader();
        reader.readAsText(file, 'UTF-8');
        reader.onload = function (evt) {
          callback(evt.target.result);
        };
        reader.onerror = function () {};
      }
    },
    onCancel() {
      this.$refs.uploadFiles.removeInputFile();
      this.importerStep = 1;
      this.$emit('csv-importer:cancel');
    },
    onFinish() {
      if (this.errors.length == 0 && this.isMappingFieldsFormValid) {
        const [, ...data] = this.importedData.data;

        const result = data.map((row) => {
          return this.fieldsToMap.reduce((result, item) => {
            if (!row[this.mapping[item.field]]) return { ...result, [item.field]: item.default };

            return { ...result, [item.field]: row[this.mapping[item.field]] };
          }, {});
        });

        this.$refs.uploadFiles.removeInputFile();
        this.$refs.mappingFieldsForm.reset();
        this.importerStep = 1;
        this.mapping = {};
        this.importedData = [];
        this.importedDataPreview = [];
        this.$emit('csv-importer:finish', result);
      }
    },
    onFileUploadedChange(file) {
      this.uploadedFile = file;
    },
  },
  computed: {
    uploadedFileFirstRow() {
      const firstRow = this.importedDataPreview?.data?.at(0) ?? [];

      return firstRow.map((column, index) => {
        return { index, column };
      });
    },

    requiredFieldRule() {
      return [(v) => v != undefined || this.$t('dashboard.field_required')];
    },

    isMappingFieldsFormValid() {
      return this.$refs.mappingFieldsForm.validate();
    },
  },
};
</script>
