<template>
  <v-app :class="{ '--dark': isDarkMode }" id="app">
    <router-view></router-view>
    <notifications group="foo" position="bottom center" />
  </v-app>
</template>

<script>
import './App.scss';
import { eventBus } from './main';
const ls = require('localstorage-slim');

export default {
  name: 'App',
  components: {},

  data() {
    return {
      theme: ls.get('theme') ?? 'ligth',
    };
  },

  computed: {
    isDarkMode() {
      ls.set('theme', this.theme);
      this.theme = ls.get('theme');

      return this.theme === 'dark';
    },
  },

  methods: {},

  created() {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
  },
};
</script>

<style>
.--dark {
  filter: invert(1) hue-rotate(180deg);
}
</style>
