const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class PostPOICategoryException extends PetitionError {
  constructor(code) {
    super('Post POI Category', code);
  }
}

async function postPOICategory(token, poiCategory) {
  const payload = { ...poiCategory };
  const response = await post('poiCategories', payload, token);

  if (response.result !== PostPOICategoryException.success) {
    return response.result;
  }

  return response;
}

module.exports = {
  postPOICategory,
  PostPOICategoryException,
};
