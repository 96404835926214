<template>
  <v-form ref="deviceForm" v-model="formValid" lazy-validation>
    <div id="device-data-form-container">
      <div class="device-data-form-table">
        <v-data-table
          :headers="certsTableHeader"
          :items="documentsTable"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          :loading="isTableLoading"
          class="elevation-1 ma-4 mt-0"
          style="overflow: auto; min-height: 100px; max-height: 400px">
          <template v-slot:[`item.certificateUrl`]="{ item }">
            <a :href="item.certificateUrl" target="_blank">
              <v-icon color="primary">mdi-download</v-icon>
            </a>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="mr-1" color="error" @click.stop="deleteRow(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
        <div class="text-center pt-2" style="display: flex; justify-content: center">
          <v-btn color="primary" class="d-flex justify-space-between mx-15" @click="reportDocs">
            <v-icon class="mr-1">mdi-file-document</v-icon>
            {{ $t('nav_bar.menu.report') }}
          </v-btn>
          <v-pagination
            :dark="theme === 'dark'"
            :style="{
              color: theme === 'dark' ? 'white !important' : '',
            }"
            v-model="page"
            :length="pageCount"></v-pagination>
        </div>
      </div>
      <div class="expansion-panel-edit-data">
        <div id="container-edit-device" class="mt-6">
          <v-row>
            <v-col cols="12" sm="6" md="4" class="mt-3">
              <v-select
                :items="items"
                prepend-icon="mdi-text"
                v-model="form.name"
                :label="$t('dashboard.device_card.select_name')"
                dense></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                :label="$t('general.form.devices')"
                chips
                deletable-chips
                small-chips
                v-model="form.selectedDevice"
                :items="device.map((item) => item.id)"
                item-value="id"
                @input="onDeviceSelected">
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.nextInspectionDate"
                    :label="$t('general.form.next_inspection')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on">
                  </v-text-field>
                </template>
                <v-date-picker v-model="date" max="2100-01-01" min="1990-01-01"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="mt-2">
              <v-text-field
                dense
                outlined
                class="rounded-lg"
                :label="$t('general.form.days_in_advance')"
                type="number"
                v-model="form.days"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="8">
              <v-file-input
                :dark="theme === 'dark'"
                v-model="form.file"
                outlined
                show-size
                :rules="[customFileValidation]"
                accept="image/png, image/jpeg, application/pdf"
                prepend-icon="fa-solid fa-cloud-arrow-up"
                :label="$t('general.form.certificate')"
                @change="changeFile"></v-file-input>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <Confirm ref="confirm"></Confirm>
  </v-form>
</template>

<script>
import Confirm from '../../../components/confirm.vue';
import { getUser } from '../../../viewModels/userViewModel';
import { getUserDevices } from '../../../server/petitions/users/getUserDevices';
import { convertBase64 } from '../../../utils/utils';
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';
const VueI18n = require('../../../plugins/lang/index');

export default {
  components: { Confirm },
  data: (instance) => ({
    theme: ls.get('theme') ?? 'ligth',
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    items: [
      VueI18n.default.t('dashboard.device_card.termo'),
      VueI18n.default.t('device.last_frames.frame_frigo.p1'),
      VueI18n.default.t('device.last_frames.frame_frigo.p2'),
      VueI18n.default.t('device.last_frames.frame_frigo.p3'),
      VueI18n.default.t('device.last_frames.frame_frigo.p4'),
      'ITV',
    ],
    formValid: false,
    isTableLoading: true,
    certs: [],
    certToRemove: [],
    user: null,
    device: [],
    form: {
      name: '',
      selectedDevice: '',
      nextInspectionDate: instance.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      ),
      days: 30,
      file: null,
    },
    resetForm: {
      name: '',
      selectedDevice: '',
      nextInspectionDate: instance.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      ),
      days: 30,
      file: null,
    },
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    url: '',
    file: null,
    resFile: null,
    enabled: true,
    documentsTable: [],
    deviceID: '',
    isFileValid: false,
  }),
  methods: {
    reportDocs() {
      this.$emit('reportDocs');
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${year}/${month}/${day}`;
    },
    textRules(value) {
      const isValidText = value ?? false;

      if (!isValidText) {
        this.isFileValid = false;
        this.$emit('file-changed', this.isFileValid);
        return this.$t('general.error.empty_value');
      }
      this.isFileValid = true;
      this.$emit('file-changed', this.isFileValid);
      return true;
    },
    customFileValidation(value) {
      const acceptedTypes = ['image/png', 'image/jpeg', 'application/pdf'];
      const isValidType = value ? acceptedTypes.includes(value.type) : false;
      const isValidSize = value ? value.size < 5000000 : false;

      if (!isValidType) {
        this.isFileValid = false;
        this.$emit('file-changed', this.isFileValid);
        return this.$t('general.error.empty_value');
      }
      if (!isValidSize) {
        this.isFileValid = false;
        this.$emit('file-changed', this.isFileValid);
        return this.$t('general.form.maxSize');
      }

      this.isFileValid = true;
      this.$emit('file-changed', this.isFileValid);
      return true;
    },
    async changeFile() {
      this.isFileValid = false;
      this.$emit('file-changed', this.isFileValid);
      const file = this.form.file;
      this.base64 = await convertBase64(file);
      this.resFile = this.base64;
    },
    deleteRow: async function (item) {
      if (
        await this.$refs.confirm.open('Delete', this.$t('general.form.delete_certificate'), {
          color: 'red',
        })
      ) {
        const certificateId = item._id;
        const deviceSn = item.device_sn;

        let indexToDelete = this.documentsTable.indexOf(item);
        this.certToRemove.push(this.certs[indexToDelete]);
        this.certs.splice(indexToDelete, 1);
        this.documentsTable.splice(indexToDelete, 1);

        this.$emit('removeDoc', certificateId, deviceSn);
      }
    },
    validate: function () {
      return this.$refs.deviceForm.validate();
    },

    openWindow: async (e) => {
      window.open(e.certificateUrl);
    },
    onDeviceSelected() {
      const selectedDeviceId = this.form.selectedDevice;

      if (selectedDeviceId !== null) {
        const selectedDevice = this.device.find((item) => item.id === selectedDeviceId);
        return (this.deviceID = selectedDevice.deviceId);
      }
    },
    async deviceToSelect() {
      this.isFileValid = true;
      this.device = [];
      let deviceCollection = await getUserDevices(this.user.token, this.user.userId);
      deviceCollection.map((element) => {
        if (element.certificates <= 4) {
          const { deviceId, device_sn, alias } = element;

          return this.device.push({ id: `${device_sn}-${alias}`, name: alias, deviceId });
        }
      });
    },
  },

  computed: {
    editing() {
      return this.$route.name === 'editDevice';
    },
    certsTableHeader() {
      return [
        { text: this.$t('general.form.device_sn'), value: 'device_sn' },
        { text: this.$t('general.form.alias'), value: 'alias' },
        { text: this.$t('general.form.name_certificate'), value: 'name' },
        { text: this.$t('general.form.documentation'), value: 'certificateUrl' },
        { text: this.$t('general.form.next_inspection'), value: 'nextInspectionDate' },
        { text: '', value: 'actions' },
      ];
    },
  },
  watch: {
    date(val) {
      this.form.nextInspectionDate = this.formatDate(this.date);
    },
  },
  mounted: async function (e) {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
    this.user = await getUser();
    this.deviceToSelect();
    this.isTableLoading = false;
  },
};
</script>
