<template>
  <v-dialog v-model="isActive" max-width="32rem" @click:outside="cancelAdd">
    <v-card class="create-customer-modal pa-4 mx-lg-auto">
      <h1 class="mb-3">{{ $t('shared.add_customer') }}</h1>
      <h5 class="mb-3">{{ $t('shared.max_customers') }}</h5>
      <v-text-field v-model="name" class="mx-5" data-cy="clientName" :label="this.$t('shared.enter_name')" outlined>
      </v-text-field>
      <v-text-field
        v-model="email"
        class="mx-5"
        data-cy="clientEmail"
        :label="this.$t('shared.email')"
        placeholder="example@email.com"
        outlined>
      </v-text-field>
      <v-row class="d-flex" style="justify-content: space-around">
        <v-btn
          :loading="loadingButton"
          data-cy="addClientButton"
          :disabled="loadingButton"
          @click="addNewClient"
          color="primary"
          class="mr-3">
          <font-awesome-icon class="white--text mr-1" icon="fa-solid fa-user-plus" />
          {{ $t('shared.just_add') }}
        </v-btn>
        <v-btn color="red" data-cy="cancelAddClient" @click="cancelAdd" class="mb-5">
          <v-icon class="white--text mr-1">mdi-exit-to-app</v-icon>
          <span class="white--text">{{ $t('general.form.exit') }}</span>
        </v-btn>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="timeout" color="success">
      {{ $t('shared.added') }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="cancelAdd"> {{ $t('shared.close') }} </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="snackbar2" :timeout="timeout" color="danger">
      {{ $t('shared.fail_to_create') }}
      <template v-slot:action="{ attrs2 }">
        <v-btn color="red" text v-bind="attrs2" @click="snackbar2 = false"> {{ $t('shared.close') }} </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<style>
.title {
  font-size: large;
}
</style>

<script>
import { addClient } from '../../../viewModels/clientViewModel';

export default {
  components: {},
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    name: '',
    email: '',
    timeout: 2000,
    snackbar: false,
    snackbar2: false,
    loadingButton: false,
  }),
  computed: {},

  methods: {
    addNewClient: async function () {
      this.loadingButton = true;

      let res = await addClient(this.name, this.email);
      if (res.result !== 'SUCCESS') {
        this.snackbar2 = !this.snackbar2;
      } else {
        this.snackbar = !this.snackbar;
      }
      this.loadingButton = false;
      this.$emit('cancel-add');
    },
    cancelAdd: function () {
      this.$emit('cancel-add');
    },
  },
};
</script>
