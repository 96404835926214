const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class SetGeocodeException extends PetitionError {
  constructor(code) {
    super('Get All Devices', code);
  }
}

async function setGeocode(geocode, serialNumber, token) {
  const payload = {
    device_sn: serialNumber,
    geocode,
  };

  const response = await post('devices/set_geocode', payload, token);

  if (response.result !== SetGeocodeException.success) {
    throw (new SetGeocodeException(response.result));
  } else {
    return response.geocode; // TO DO transform
  }
}

// class SetGeocodeException extends PetitionError {
//   constructor(code) {
//     super('Get All Devices', code);
//   }
// }

module.exports = {
  setGeocode,
  SetGeocodeException,
};
