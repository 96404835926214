const PetitionError = require('../../petitionError');
const { getAnonymous } = require('../../httpRequester');

class getAllCarrierSimsException extends PetitionError {
  constructor(code) {
    super(' Get All Carrier Sims: ', code);
  }
}

async function getAllCarrierSims(token) {
  const response = await getAnonymous(`matooma/get_all_sims?token=${token}`);

  if (response.result !== getAllCarrierSimsException.success) {
    throw new getAllCarrierSimsException(response.result);
  } else {
    return response.data;
  }
}

module.exports = { getAllCarrierSims, getAllCarrierSimsException };
