<template>
  <v-dialog v-model="isActive" max-width="55rem" @click:outside="cancelFrigoHours" @keydown.esc="cancelFrigoHours">
    <v-card class="ticket-view-card">
      <v-row>
        <v-card-title color="primary" class="texth5 font-weight-black"
          >{{ this.$t('dashboard.device_card.modal_frigo') }} {{ device.device_sn }}</v-card-title
        >
      </v-row>
      <v-card-title color="primary" class="caption pt-0"></v-card-title>

      <v-divider class="py-1 mx-2" style="border-color: #607d8b"></v-divider>
      <v-card-title color="primary" class="caption pt-0">{{ this.$t('dashboard.device_card.label_modal_frigo') }}</v-card-title>
      <v-text-field
        v-model="frigoHours"
        :rules="mileageRules"
        :label="getEngineHours"
        solo
        class="mx-6 input_number"
        suffix="h"
        type="number"
        min="0"></v-text-field>
      <v-form>
        <v-container class="text-md-center">
          <v-btn class="mx-lg-6" color="primary" elevation="2" x-large @click="save"
            ><v-icon>mdi-content-save</v-icon>{{ this.$t('dashboard.configuration.accept') }}</v-btn
          >
          <v-btn class="mx-lg-6" color="error" elevation="2" x-large @click="cancelFrigoHours"
            ><v-icon>mdi-cancel</v-icon>{{ this.$t('dashboard.configuration.cancel') }}</v-btn
          >
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.title {
  font-size: large;
}
.input_number ::-webkit-outer-spin-button,
.input_number ::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input_number [type='number'] {
  -moz-appearance: textfield;
}
</style>

<script>
import { saveFrigoHours } from '../../viewModels/devicesViewModel';
import { eventBus } from '../../main';
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    device: {
      type: Object,
    },
    titleInput: {
      type: Boolean,
    },
  },
  data: () => ({
    frigoHours: null,
  }),
  computed: {
    mileageRules() {
      return [(value) => Number(value) <= 999999999 || this.$t('dashboard.configuration.max_mileage')];
    },
    getEngineHours() {
      if (this.device) {
        if (this.device.last_frames) {
          if (this.device.last_frames.frame_frigo) {
            return this.device.last_frames.frame_frigo.engine_time.offset_applied.toFixed(0);
          }
        }
      }
    },
  },

  methods: {
    cancelFrigoHours: function () {
      this.$emit('cancel-frigo');
    },
    save: async function () {
      let offset =
        this.frigoHours && this.device.last_frames.frame_frigo
          ? this.frigoHours - this.device.last_frames.frame_frigo.engine_time.raw
          : 0;

      if (this.frigoHours) {
        let res = await saveFrigoHours(this.device.device_sn, this.frigoHours, offset.toFixed(0));
      }
      eventBus.$emit('refresh-frigoHours', this.frigoHours);
      this.$emit('cancel-frigo');
    },
  },

  mounted: async function () {},
};
</script>
