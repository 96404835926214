const PetitionError = require('../../petitionError');
const { get } = require('../../httpRequester');

class getAllPOISException extends PetitionError {
  constructor(code) {
    super('Get POIS', code);
  }
}

async function getAllPOIS(token, userId) {
  const response = await get(`pois/${userId}`, token);
  if (response.result !== getAllPOISException.success) {
    return new getAllPOISException(response.result);
  }
  return response.pois;
}

module.exports = {
  getAllPOIS,
  getAllPOISException,
};
