const PetitionError = require('../../petitionError');
const { postRaw } = require('../../httpRequester');

class ScheduleReportException extends PetitionError {
  constructor(code) {
    super('Schedule Report', code);
  }
}

async function scheduleReport(report, token, email) {
  const dateTimeFormat = Intl.DateTimeFormat().resolvedOptions();

  const payload = { report, email, dateTimeFormat };
  const response = await postRaw('reports/scheduleReport', payload, token);
  return response;
}

// class ScheduleReportException extends PetitionError {
//   constructor(code) {
//     super('Schedule Report', code);
//   }
// }

module.exports = {
  scheduleReport,
  ScheduleReportException,
};
