const PetitionError = require('../../petitionError');
const { put } = require('../../httpRequester');

class UpdateCertificateException extends PetitionError {
  constructor(code) {
    super(' ERROR : Error when updating the certificate', code);
  }
}

async function updateCertificatePetition(token, payload) {
  const response = await put('devices/certificate', payload, token);

  if (response.result !== UpdateCertificateException.success) {
    throw new UpdateCertificateException(response.result);
  } else {
    return response; // TO DO transform
  }
}

module.exports = { updateCertificatePetition, UpdateCertificateException };
