<template>
  <v-dialog v-model="isActive" max-width="40rem" @click:outside="reportsForm">
    <div class="scrollable">
      <v-card v-if="!isMobile" class="report-form-modal pa-10 mx-lg-auto">
        <v-row>
          <h1 class="mr-4 justify-center">{{ $t('reports.programmed.program') }}</h1>
          <div style="width: 60%; margin: auto">
            <v-text-field v-model="title" :label="$t('reports.programmed.reports_name')" append-icon="mdi-pencil"> </v-text-field>
          </div>
        </v-row>
        <br />
        <h3>{{ $t('reports.programmed.frequency') }}</h3>
        <v-radio-group v-model="periodicity" row>
          <v-spacer></v-spacer>
          <v-radio :label="$t('reports.programmed.daily')" color="indigo darken-3" value="Daily"></v-radio>
          <v-spacer></v-spacer>
          <v-radio :label="$t('reports.programmed.weekly')" color="indigo darken-3" value="Weekly"></v-radio>
          <v-spacer></v-spacer>
          <v-radio :label="$t('reports.programmed.monthly')" color="indigo darken-3" value="Monthly"></v-radio>
          <v-spacer></v-spacer>
        </v-radio-group>

        <div class="info-times-report d-flex justify-center mb-6">
          <v-card v-if="periodicity == 'Daily'" style="width: 80%" class="info-times-report-vcard d-flex justify-center mb-6">
            <h4>{{ $t('reports.programmed.daily_info') }}</h4>
          </v-card>
        </div>

        <div class="info-times-report d-flex justify-center mb-6">
          <v-card v-if="periodicity == 'Weekly'" style="width: 80%" class="info-times-report-vcard d-flex justify-center mb-6">
            <h3>{{ $t('reports.programmed.weekly_info') }}</h3>
          </v-card>
        </div>

        <div class="info-times-report d-flex justify-center mb-6">
          <v-card v-if="periodicity == 'Monthly'" style="width: 80%" class="info-times-report-vcard d-flex justify-center mb-6">
            <h3>{{ $t('reports.programmed.monthly_info') }}</h3>
          </v-card>
        </div>

        <div>
          <v-row class="mt-6">
            <v-col>
              <h3 class="d-flex justify-start">
                {{ $t('reports.gps_reports') }}
              </h3>
              <v-checkbox
                @change="uncheckOthers('km_count')"
                v-model="reportsTypeSelected"
                hide-details
                value="km_count"
                :label="$t('reports.types.mileage')"></v-checkbox>
              <v-checkbox
                @change="uncheckOthers('driving_hours')"
                v-model="reportsTypeSelected"
                hide-details
                value="driving_hours"
                :label="$t('reports.types.working_time')"></v-checkbox>
              <v-checkbox
                @change="uncheckOthers('map_route')"
                v-model="reportsTypeSelected"
                hide-details
                value="map_route"
                :label="$t('reports.types.map_route')"></v-checkbox>
              <!-- <v-checkbox
                @change="uncheckOthers('pi_sum')"
                v-model="reportsTypeSelected"
                hide-details
                value="pi_sum"
                :label="$t('reports.types.pois')"></v-checkbox> -->
            </v-col>
            <v-col>
              <h3 class="d-flex justify-start">
                {{ $t('reports.frigo_reports') }}
              </h3>
              <v-checkbox
                @change="uncheckOthers('engine_hours')"
                v-model="reportsTypeSelected"
                hide-details
                value="engine_hours"
                :label="$t('reports.types.engine_hours')"></v-checkbox>
              <v-checkbox
                @change="uncheckOthers('temp_graph')"
                v-model="reportsTypeSelected"
                hide-details
                value="temp_graph"
                :label="$t('reports.types.temp_graph')"></v-checkbox>
              <v-checkbox
                @change="uncheckOthers('journey_sum')"
                v-model="reportsTypeSelected"
                hide-details
                value="journey_sum"
                :label="$t('reports.types.journey_summary')"></v-checkbox>
              <v-checkbox
                @change="uncheckOthers('temp_report')"
                v-model="reportsTypeSelected"
                hide-details
                value="temp_report"
                :label="$t('reports.types.temp_ticket')"></v-checkbox>
            </v-col>
            <v-col cols="6" style="margin: auto">
              <h3 class="d-flex">
                {{ $t('reports.programmed.select_devices') }}
              </h3>
              <v-autocomplete
                multiple
                v-model="selectedTrackerDevices"
                :items="trackerDevices"
                color="primary"
                item-text="alias"
                item-value="device_sn"
                :label="$t('device.form.search_device')"
                prepend-icon="mdi-magnify"></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="px-6" v-if="selectedDevicesProbes.length > 0">
            <v-label>{{ $t('reports.probes') }}</v-label>
            <v-checkbox
              v-for="probe in selectedDevicesProbes"
              :key="probe"
              :label="$t(`device.last_frames.frame_frigo.${probe}`)"
              :value="probe"
              v-model="selectedProbes"
              hide-details
              class="ma-2"></v-checkbox>
          </v-row>
        </div>
        <v-card>
          <v-spacer></v-spacer>
          <v-alert
            v-if="invalidReport"
            border="top"
            color="red lighten-2"
            prominent
            type="error"
            dark
            v-click-outside="removeAlert">
            {{ $t('reports.programmed.alert_common') }} {{ errorField }}
          </v-alert>

          <v-alert
            v-if="unavailableName"
            border="top"
            color="red lighten-2"
            prominent
            type="error"
            dark
            v-click-outside="removeAlert">
            {{ $t('reports.programmed.name_not_available') }} {{ title }}
          </v-alert>

          <v-alert
            v-if="validReport"
            border="top"
            color="green lighten-2"
            prominent
            type="success"
            dark
            v-click-outside="removeAlert">
            {{ $t('reports.programmed.success') }}
          </v-alert>
          <v-spacer></v-spacer>
        </v-card>
        <v-row class="butons-report-form d-flex mb-10">
          <v-btn class="mx-lg-6" color="primary" elevation="2" x-large @click="checkReportFields"
            ><v-icon class="mr-1">mdi-content-save</v-icon>{{ this.$t('dashboard.configuration.accept') }}</v-btn
          >
          <v-btn class="mx-lg-6" color="red" elevation="2" x-large @click="discard">
            <v-icon class="white--text mr-1">mdi-exit-to-app</v-icon>
            <span class="white--text">{{ $t('general.form.exit') }}</span>
          </v-btn>
        </v-row>
      </v-card>
      <v-card v-else class="report-form-modal pa-10 mx-lg-auto">
        <v-card>
          <v-spacer></v-spacer>
          <v-alert
            v-if="invalidReport"
            border="top"
            color="red lighten-2"
            prominent
            type="error"
            dark
            v-click-outside="removeAlert">
            {{ $t('reports.programmed.alert_common') }} {{ errorField }}
          </v-alert>

          <v-alert
            v-if="unavailableName"
            border="top"
            color="red lighten-2"
            prominent
            type="error"
            dark
            v-click-outside="removeAlert">
            {{ $t('reports.programmed.name_not_available') }} {{ title }}
          </v-alert>

          <v-alert
            v-if="validReport"
            border="top"
            color="green lighten-2"
            prominent
            type="success"
            dark
            v-click-outside="removeAlert">
            {{ $t('reports.programmed.success') }}
          </v-alert>
          <v-spacer></v-spacer>
        </v-card>
        <v-row class="report-modal-row">
          <h1 class="mr-4 justify-center report-modal-h1">{{ $t('reports.programmed.program') }}</h1>
          <div style="width: 50%" class="report-modal-search">
            <v-text-field v-model="title" :label="$t('reports.programmed.reports_name')" append-icon="mdi-pencil"> </v-text-field>
          </div>
        </v-row>
        <br />
        <h3>{{ $t('reports.programmed.frequency') }}</h3>
        <v-radio-group v-model="periodicity">
          <v-spacer></v-spacer>
          <v-radio :label="$t('reports.programmed.daily')" color="indigo darken-3" value="Daily" class="ma-2"></v-radio>
          <v-spacer></v-spacer>
          <v-radio :label="$t('reports.programmed.weekly')" color="indigo darken-3" value="Weekly" class="ma-2"></v-radio>
          <v-spacer></v-spacer>
          <v-radio :label="$t('reports.programmed.monthly')" color="indigo darken-3" value="Monthly" class="ma-2"></v-radio>
          <v-spacer></v-spacer>
        </v-radio-group>

        <div class="d-flex justify-center">
          <v-card v-if="periodicity == 'Daily'" style="width: 80%" class="d-flex justify-center mb-6">
            <h3>{{ $t('reports.programmed.daily_info') }}</h3>
          </v-card>
        </div>

        <div class="d-flex justify-center">
          <v-card v-if="periodicity == 'Weekly'" style="width: 80%" class="d-flex justify-center mb-6">
            <h3>{{ $t('reports.programmed.weekly_info') }}</h3>
          </v-card>
        </div>

        <div class="d-flex justify-center">
          <v-card v-if="periodicity == 'Monthly'" style="width: 80%" class="d-flex justify-center mb-6">
            <h3>{{ $t('reports.programmed.monthly_info') }}</h3>
          </v-card>
        </div>

        <div>
          <v-row class="mt-6">
            <v-col>
              <h3 class="d-flex justify-start">
                {{ $t('reports.gps_reports') }}
              </h3>
              <v-checkbox
                @change="uncheckOthers('km_count')"
                v-model="reportsTypeSelected"
                hide-details
                value="km_count"
                :label="$t('reports.types.mileage')"></v-checkbox>
              <v-checkbox
                @change="uncheckOthers('driving_hours')"
                v-model="reportsTypeSelected"
                hide-details
                value="driving_hours"
                :label="$t('reports.types.working_time')"></v-checkbox>
              <v-checkbox
                @change="uncheckOthers('map_route')"
                v-model="reportsTypeSelected"
                hide-details
                value="map_route"
                :label="$t('reports.types.map_route')"></v-checkbox>
              <!-- <v-checkbox
                @change="uncheckOthers('pi_sum')"
                v-model="reportsTypeSelected"
                hide-details
                value="pi_sum"
                :label="$t('reports.types.pois')"></v-checkbox> -->
            </v-col>
            <v-col>
              <h3 class="d-flex justify-start">
                {{ $t('reports.frigo_reports') }}
              </h3>
              <v-checkbox
                @change="uncheckOthers('engine_hours')"
                v-model="reportsTypeSelected"
                hide-details
                value="engine_hours"
                :label="$t('reports.types.engine_hours')"></v-checkbox>
              <v-checkbox
                @change="uncheckOthers('temp_graph')"
                v-model="reportsTypeSelected"
                hide-details
                value="temp_graph"
                :label="$t('reports.types.temp_graph')"></v-checkbox>
              <v-checkbox
                @change="uncheckOthers('journey_sum')"
                v-model="reportsTypeSelected"
                hide-details
                value="journey_sum"
                :label="$t('reports.types.journey_summary')"></v-checkbox>
              <v-checkbox
                @change="uncheckOthers('temp_report')"
                v-model="reportsTypeSelected"
                hide-details
                value="temp_report"
                :label="$t('reports.types.temp_ticket')"></v-checkbox>
            </v-col>
            <v-col cols="6" class="report-modal-select-devices">
              <h3 class="d-flex justify-start">
                {{ $t('reports.programmed.select_devices') }}
              </h3>
              <v-autocomplete
                multiple
                v-model="selectedTrackerDevices"
                :items="trackerDevices"
                color="primary"
                item-text="device_sn"
                item-value="device_sn"
                :label="$t('device.form.search_device')"
                prepend-icon="mdi-magnify"></v-autocomplete>
            </v-col>
          </v-row>
        </div>

        <v-row class="butons-report-form d-flex mb-10">
          <v-btn class="mx-lg-6" color="primary" elevation="2" x-large @click="checkReportFields"
            ><v-icon class="mr-1">mdi-content-save</v-icon>{{ this.$t('dashboard.configuration.accept') }}</v-btn
          >
          <v-btn class="mx-lg-6" color="red" elevation="2" x-large @click="discard">
            <v-icon class="white--text mr-1">mdi-exit-to-app</v-icon>
            <span class="white--text">{{ $t('general.form.exit') }}</span>
          </v-btn>
        </v-row>
      </v-card>

      <v-snackbar v-model="snackbar" color="success">
        {{ $t('general.form.report_success') }}

        <template v-slot:action="{ attrs }">
          <v-btn color="black" text v-bind="attrs" @click="discard"> Cerrar {{ $t('general.form.close') }} </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-dialog>
</template>

<style>
.title {
  font-size: large;
}
</style>

<script>
import { getAllUserDevices } from '../../../viewModels/adminViewModel';
import { getUser } from '../../../viewModels/userViewModel';
import { programReport } from '../../../viewModels/reportsViewModel';
import { eventBus } from '../../../main';

export default {
  components: {},
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    snackbar: false,
    update: 0,
    periodicity: '',
    userDevices: [],
    trackerDevices: [],
    reportsTypeSelected: [],
    title: '',
    selectedTrackerDevices: [],
    type: [],
    invalidReport: false,
    validReport: false,
    invalidNameReport: false,
    errorField: null,
    unavailableName: false,
    existingNames: [],
    carplates: [],
    fullInfoDevice: [],
    deviceDataToUpload: [],
    devicesNames: [],
    user: null,
    selectedProbes: [],
  }),

  mounted: async function () {
    this.user = await getUser();
    this.trackerDevices = await getAllUserDevices(this.user.devices);
    this.retrieveDeviceNames(this.user);
    for (let [index, device] of this.trackerDevices.entries()) {
      if (this.devicesNames[index]) {
        device.alias = this.devicesNames[index];
      } else {
        device.alias = this.trackerDevices[index].device_data_from_frame.carplate || this.trackerDevices[index].device_sn;
      }
    }

    eventBus.$on('programReport', (table) => {
      for (let element of table) {
        this.existingNames.push(element.title);
      }
    });
  },

  methods: {
    uncheckOthers(type) {
      this.reportsTypeSelected = [type];
    },
    retrieveDeviceNames(user) {
      for (let device of user.devices) {
        this.devicesNames.push(device.name);
      }
    },

    reportsForm() {
      this.$emit('cancel-reports');
    },

    convertDevices() {
      for (let [index, device] of this.trackerDevices.entries()) {
        if (this.devicesNames[index]) {
          const deviceDataFromName = {
            serialNumber: this.trackerDevices[index].device_sn,
            carplate: this.trackerDevices[index].device_data_from_frame.carplate,
            name: this.devicesNames[index],
          };
          this.fullInfoDevice.push(deviceDataFromName);
        } else {
          const deviceData = {
            serialNumber: device.device_sn,
            carplate: device.device_data_from_frame.carplate,
            name: null,
          };
          this.fullInfoDevice.push(deviceData);
        }
      }

      for (let number of this.fullInfoDevice) {
        if (
          this.selectedTrackerDevices.includes(number.carplate) ||
          this.selectedTrackerDevices.includes(number.name) ||
          this.selectedTrackerDevices.includes(number.serialNumber)
        ) {
          this.deviceDataToUpload.push(number);
        }
      }
    },

    validateReportName() {
      const foundName = this.existingNames.includes(this.title);
      if (foundName) {
        this.unavailableName = true;
      }
    },

    async save() {
      this.validateReportName();
      this.convertDevices();
      const report = {
        reports_type_selected: this.reportsTypeSelected,
        periodicity: this.periodicity,
        title: this.title,
        complete_data_devices: this.deviceDataToUpload,
        probes: this.selectedProbes,
      };

      if (!this.unavailableName) {
        this.validReport = true;
        const response = await programReport(report);
      }
      if (this.unavailableName) {
        this.validReport = false;
      }
    },

    checkReportFields() {
      if (!this.title) {
        this.errorField = this.$t('reports.programmed.name');
        this.invalidReport = true;
        return;
      }

      if (!this.periodicity) {
        this.errorField = this.$t('reports.programmed.periodicity');
        this.invalidReport = true;
        return;
      }

      if (this.selectedTrackerDevices.length === 0) {
        this.errorField = this.$t('reports.programmed.select_devices');
        this.invalidReport = true;
        return;
      }
      if (this.reportsTypeSelected.length === 0) {
        this.errorField = this.$t('reports.programmed.type');
        this.invalidReport = true;
        return;
      }
      this.snackbar = !this.snackbar;
      this.save();
      this.reportsForm();
    },

    removeAlert() {
      this.invalidReport = false;
      this.validReport = false;
    },

    discard() {
      this.reportsForm();
    },
  },

  watch: {
    selectedDevicesProbes: function (newVal, oldVal) {
      this.selectedProbes = newVal;
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    selectedDevices() {
      return this.selectedTrackerDevices.map((deviceSn) => this.trackerDevices.find((device) => device.device_sn == deviceSn));
    },

    selectedDevicesProbes() {
      let probes = [];
      this.selectedDevices.forEach((device) => {
        if (device) {
          Object.entries(device.device_data_from_frame.probes_type).forEach(([probeName, probeValue]) => {
            if (probeValue != null && probes.indexOf(probeName) < 0) {
              probes = [...probes, probeName];
            }
          });
        }
      });
      return probes;
    },
  },
};
</script>
