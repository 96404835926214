<template>
  <v-container v-if="loaded">
    <h1>Status</h1>
    <v-row>
      <v-col cols="12"> Backend: {{ backendStatus.ok == 1 }} </v-col>
      <v-col cols="12">
        MongoDB: <code>{{ backendStatus }}</code>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"> MQTT: {{ mqttStatus }} </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getAnonymous } from '../server/httpRequester';
import BrokerConnection from '../server/brokerConnection/brokerConnection';

export default {
  data: () => ({
    backendStatus: {},
    mqttStatus: false,
    loaded: false,
  }),

  methods: {},

  mounted: async function () {
    const response = await getAnonymous('status');

    this.backendStatus = {
      ok: response.data.mongo.ok,
      uptime: response.data.mongo.uptime,
      opLatencies: response.data.mongo.opLatencies,
    };

    await BrokerConnection.initializeBroker(() => {
      this.mqttStatus = true;
      this.loaded = true;
    });
  },
};
</script>
