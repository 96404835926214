const PetitionError = require("../../petitionError");
const { post } = require("../../httpRequester");

class AssignDeviceToUserException extends PetitionError {
    constructor(code) {
        super("Assign device to user", code);
    }
}

async function assignDeviceToUser(device_id, users_ids, device_sn, token) {
    const payload = { device_id, users_ids, device_sn };

    const response = await post("users/assign_device", payload, token);

    if (response.result !== AssignDeviceToUserException.success) {
        throw new AssignDeviceToUserException(response.result);
    } else {
        return response.user; // TODO transform
    }
}

// class AssignDeviceToUserException extends PetitionError {
//   constructor(code) {
//     super('Assign device to user', code);
//   }
// }

module.exports = {
    assignDeviceToUser,
    AssignDeviceToUserException,
};
