<template>
  <v-form ref="deviceForm" v-model="formValid" lazy-validation>
    <v-autocomplete
      :items="usersToChoose"
      @change="addUser"
      item-text="name"
      return-object
      outlined
      autofocus
      v-bind:label="$t('device.form.add_user')"
      class="device-search rounded-lg mt-4 mb-0">
    </v-autocomplete>

    <div id="device-data-form-container">
      <div class="device-data-form-table">
        <v-data-table
          :headers="usersTableHeader"
          :items="usersTableData"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          :loading="isTableLoading"
          class="elevation-1 ma-4 mt-0"
          @click:row="editUser"
          @contextmenu:row="editUserCtrl"
          style="overflow: auto; min-height: 100px; max-height: 400px">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="mr-1" color="error" @click.stop="deleteRow(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            :dark="theme === 'dark'"
            :style="{
              color: theme === 'dark' ? 'white !important' : '',
            }"
            v-model="page"
            :length="pageCount"></v-pagination>
        </div>
      </div>

      <div class="expansion-panel-edit-data">
        <v-expansion-panels class="card-data expansion-panels">
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <v-row no-gutters>
                  <v-col>
                    {{ $t('device.title') }}
                  </v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="expansion-panel-content">
              <div id="container-edit-device">
                <v-row class="edit-data-user edit-data-device-serie">
                  <v-col class="edit-data-left">
                    <label class="font-weight-black float-left">{{ $t('device.atribute.serial_number') }}* </label>
                  </v-col>
                  <v-col class="edit-data-right">
                    <v-text-field
                      v-if="!deviceData.device_sn"
                      dense
                      outlined
                      class="rounded-lg"
                      type="text"
                      v-model="serialNumber"
                      :rules="textRules"></v-text-field>
                    <div v-if="deviceData.device_sn" class="font-weight-bold">
                      {{ deviceData.device_sn }}
                    </div>
                  </v-col>
                </v-row>

                <v-row class="edit-data-user edit-data-device">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left">{{ $t('device.atribute.model') }}*</label>
                  </v-col>
                  <v-col class="edit-data-right">
                    <v-select
                      :items="getModels"
                      v-model="selectedModel"
                      item-text="name"
                      item-value="value"
                      outlined
                      dense
                      class="rounded-lg"
                      required></v-select>
                  </v-col>
                </v-row>
                <v-row class="edit-data-user edit-data-device">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left">{{ $t('device.atribute.vehicleType') }}*</label>
                  </v-col>
                  <v-col class="edit-data-right">
                    <v-select
                      :items="getVehicleTypes"
                      v-model="vehicleType"
                      item-text="name"
                      item-value="value"
                      outlined
                      dense
                      class="rounded-lg"
                      required></v-select>
                  </v-col>
                </v-row>

                <v-row class="edit-data-user edit-data-device">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left">{{ $t('device.atribute.submodel') }}</label>
                  </v-col>
                  <v-col class="edit-data-right">
                    <v-select
                      :items="getSubmodels"
                      item-text="name"
                      item-value="value"
                      outlined
                      dense
                      v-model="selectedSubmodel"
                      class="rounded-lg"></v-select>
                  </v-col>
                </v-row>

                <v-row class="edit-data-user edit-data-device">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left">{{ $t('device.atribute.distributor') }}</label>
                  </v-col>
                  <v-col class="edit-data-right">
                    <v-autocomplete
                      :items="companiesToChoose"
                      item-text="name"
                      item-value="_id"
                      outlined
                      dense
                      v-model="selectedDistributor"
                      class="rounded-lg"></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row class="edit-data-user edit-data-device">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left">{{ $t('device.atribute.workshop') }}</label>
                  </v-col>
                  <v-col class="edit-data-right">
                    <v-autocomplete
                      :items="companiesToChoose"
                      item-text="name"
                      item-value="_id"
                      outlined
                      dense
                      v-model="selectedWorkshop"
                      class="rounded-lg"></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row class="edit-data-user edit-data-device">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left company-client">
                      {{ $t('device.atribute.company') }}
                    </label>
                  </v-col>
                  <v-col class="edit-data-right">
                    <v-autocomplete
                      :items="companiesToChoose"
                      item-text="name"
                      item-value="_id"
                      outlined
                      dense
                      v-model="selectedCompany"
                      class="rounded-lg"></v-autocomplete>
                  </v-col>
                </v-row>
                <div v-if="selectedModel == 'tracker'">
                  <v-row class="edit-data-user edit-data-device">
                    <v-col class="edit-data-left">
                      <label class="mt-2 font-weight-black float-left"> Iccid </label>
                    </v-col>
                    <span class="edit-data-left edit-data-flex">
                      <v-col class="edit-data-flex-left">
                        <v-text-field dense outlined class="rounded-lg" type="text" v-model="iccid" :rules="textRules">
                        </v-text-field>
                      </v-col>
                    </span>
                  </v-row>
                </div>
                <v-row class="edit-data-user edit-data-device">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left"> {{ $t('device.atribute.secret') }}* </label>
                  </v-col>
                  <span class="edit-data-left edit-data-flex">
                    <v-col class="edit-data-flex-left">
                      <v-text-field dense outlined class="rounded-lg" type="text" v-model="password" :rules="textRules">
                      </v-text-field>
                    </v-col>

                    <v-col>
                      <v-btn color="primary" @click="randomHEX(serialNumber)"> HEX </v-btn>
                    </v-col>
                  </span>
                </v-row>

                <v-row class="edit-data-user edit-data-device">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left">
                      {{ $t('device.atribute.aes') }}
                    </label>
                  </v-col>

                  <span class="edit-data-right edit-data-flex">
                    <v-col>
                      <v-text-field dense outlined label="Iv*" class="rounded-lg" type="text" v-model="aesIv" :rules="textRules">
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        dense
                        outlined
                        label="Key*"
                        class="rounded-lg"
                        type="text"
                        v-model="aesKey"
                        :rules="textRules"></v-text-field>
                    </v-col>
                  </span>
                </v-row>

                <v-row class="edit-data-user edit-data-device">
                  <v-col class="edit-data-left"> </v-col>
                  <v-col>
                    <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="certificateDate"
                          label="Fecha del certificado"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="certificateDate" max="2100-01-01" min="1990-01-01"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row class="edit-data-user edit-data-device">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left">
                      {{ $t('device.atribute.third_party_services') }}
                    </label>
                  </v-col>
                  <v-col class="edit-data-right">
                    <v-expansion-panels>
                      <v-expansion-panel class="device-data-form-expansion-panel">
                        <v-expansion-panel-header> Webfleet </v-expansion-panel-header>
                        <v-expansion-panel-content class="device-data-form-expansion-panel-content">
                          <v-text-field :label="$t('device.atribute.account')" v-model="webfleetAccount"></v-text-field>
                          <v-text-field :label="$t('device.atribute.username')" v-model="webfleetUsername"> </v-text-field>
                          <v-text-field :label="$t('device.atribute.key')" v-model="webfleetPassword"></v-text-field>
                          <v-text-field :label="$t('device.atribute.object_number')" v-model="webfleetObjectNumber">
                          </v-text-field>
                          <v-text-field :label="$t('device.atribute.object_uid')" v-model="webfleetObjectUid"> </v-text-field>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>

                <v-row class="edit-data-user edit-data-device">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left">
                      {{ $t('device.atribute.notes') }}
                    </label>
                  </v-col>
                  <v-col class="edit-data-right">
                    <v-text-field dense outlined class="rounded-lg" type="text" v-model="notes"></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="edit-data-user edit-data-device">
                  <v-col class="edit-data-left"></v-col>
                  <v-col class="edit-data-right">
                    <v-checkbox
                      v-model="isGPSEnabled"
                      class="delete-bgc-option"
                      v-bind:label="$t('device.atribute.gps_enabled')"></v-checkbox>
                  </v-col>
                </v-row>

                <v-row class="edit-data-user edit-data-device">
                  <v-col class="edit-data-left"></v-col>
                  <v-col class="edit-data-right">
                    <v-checkbox v-model="enabled" class="delete-bgc-option" v-bind:label="$t('device.atribute.visible')">
                    </v-checkbox>
                  </v-col>
                </v-row>

                <div v-if="editing">
                  <v-row class="edit-data-user edit-data-device">
                    <v-col cols="6" class="edit-data-left">
                      <label class="mt-2 font-weight-black float-left">
                        {{ $t('device.atribute.odometer_adjustment') }} (km.)
                      </label>
                    </v-col>
                    <v-col cols="5" class="edit-data-right">
                      <v-text-field dense outlined class="rounded-lg" type="text" v-model="odometerAdjustment"> </v-text-field>
                    </v-col>
                    <v-col cols="1">
                      <v-icon medium @click="btnSaveOdometer"> mdi-content-save </v-icon>
                    </v-col>
                  </v-row>

                  <v-row class="edit-data-user edit-data-device">
                    <v-col cols="6" class="edit-data-left">
                      <label class="mt-2 font-weight-black float-left"> {{ $t('device.atribute.frigo_adjustment') }} (h.) </label>
                    </v-col>
                    <v-col cols="5" class="edit-data-right">
                      <v-text-field dense outlined class="rounded-lg" type="text" v-model="frigoHoursAdjustment"> </v-text-field>
                    </v-col>
                    <v-col cols="1">
                      <v-icon medium @click="btnSaveEngineTime"> mdi-content-save </v-icon>
                    </v-col>
                  </v-row>
                </div>

                <v-row v-if="showLinkTracker" class="edit-data-user edit-data-device">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left">{{ $t('device.atribute.linked_to') }} </label>
                  </v-col>
                  <v-col class="edit-data-right">
                    <v-autocomplete
                      :items="devicesList"
                      item-text="device_sn"
                      item-value="device_sn"
                      outlined
                      dense
                      v-model="linkedTo"
                      class="rounded-lg"></v-autocomplete>
                    <v-checkbox v-model="trackerDisabled" class="delete-bgc-option" label="Deshabilitar Tracker"> </v-checkbox>
                  </v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </v-form>
</template>

<script>
import router from '../../../plugins/router/index';
import { getAllUsers, getAllCompanies, getAllDevices, getDeviceUsers, getCompany } from '../../../viewModels/adminViewModel';
import { saveFrigoHours, saveOdometer } from '../../../viewModels/devicesViewModel';
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';

export default {
  props: {
    deviceData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: (instance) => ({
    userIds: [],
    theme: ls.get('theme') ?? 'ligth',
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    formValid: false,
    isLoadingUsers: true,
    isTableLoading: true,
    users: [],
    usersToRemoveDevice: [],
    serialNumber: instance.deviceData.device_sn,
    selectedModel: instance.deviceData.model,
    vehicleType: instance.deviceData.movement_settings ? 'delivery' : 'trailer',
    selectedSubmodel: instance.deviceData.submodel,
    selectedDistributor: '',
    selectedWorkshop: '',
    selectedCompany: '',
    trackerDisabled: instance.deviceData.stop_flag || false,
    usersToChoose: [],
    companiesToChoose: [],
    trackerDevicesOptions: [],
    frigoDevicesOptions: [],
    iccid: instance.deviceData.iccid,
    password: instance.deviceData.password,
    aesIv: instance.deviceData.aes ? instance.deviceData.aes.iv || '' : '',
    aesKey: instance.deviceData.aes ? instance.deviceData.aes.key || '' : '',
    certificateDate: '',
    webfleetAccount:
      instance.deviceData.third_party_services && instance.deviceData.third_party_services.length > 0
        ? instance.deviceData.third_party_services[0].account
        : '',
    webfleetUsername:
      instance.deviceData.third_party_services && instance.deviceData.third_party_services.length > 0
        ? instance.deviceData.third_party_services[0].username
        : '',
    webfleetPassword:
      instance.deviceData.third_party_services && instance.deviceData.third_party_services.length > 0
        ? instance.deviceData.third_party_services[0].password
        : '',
    webfleetObjectNumber:
      instance.deviceData.third_party_services && instance.deviceData.third_party_services.length > 0
        ? instance.deviceData.third_party_services[0].object_number
        : '',
    webfleetObjectUid:
      instance.deviceData.third_party_services && instance.deviceData.third_party_services.length > 0
        ? instance.deviceData.third_party_services[0].object_uid
        : '',
    notes: instance.deviceData.notes,
    isGPSEnabled: true,
    enabled: true,
    odometerAdjustment: '',
    frigoHoursAdjustment: '',
    latestOdometerRaw: '',
    latestEngineTimeRaw: '',
    newOdometerOffset: 0,
    newEngineTimeOffset: 0,
    carplate: '',
    linkedTo: instance.deviceData.linked_to,
    devicesList: [],
    usersTableData: [],
  }),
  methods: {
    btnSaveOdometer: async function () {
      this.newOdometerOffset = this.odometerAdjustment - this.latestOdometerRaw;
      await saveOdometer(this.serialNumber, Number(this.odometerAdjustment), this.newOdometerOffset);
    },
    btnSaveEngineTime: async function () {
      this.newEngineTimeOffset = this.frigoHoursAdjustment - this.latestEngineTimeRaw;
      await saveFrigoHours(this.serialNumber, Number(this.frigoHoursAdjustment), this.newEngineTimeOffset);
    },

    editUser: function (e) {
      router.push({
        name: 'editUser',
        params: { userId: e._id, userData: e },
      });
    },
    editUserCtrl: async function (e, i) {
      e.preventDefault();
      let routeData = router.resolve({ name: 'editUser', params: { userId: i.item._id, userData: i.item } });
      window.open(routeData.href, '_blank');
    },
    addUser: function (e) {
      if (this.users?.includes(e?._id)) this.$emit('user-repeat');

      if (e && !this.users.includes(e?._id)) {
        this.userIds.push(e?._id);
        this.usersTableData.push(e);

        let indexToDelete = this.usersToRemoveDevice.indexOf(e?._id);
        this.usersToRemoveDevice.splice(indexToDelete, 1);
        this.$emit('user-no-repeat');
      }
    },
    deleteRow: function (item) {
      if (item.userId) {
        this.usersToRemoveDevice.push(item.userId);
      } else {
        this.usersToRemoveDevice.push(item._id);
      }
      let itemId = item.userId ?? item._id;

      //DELETE IN DEVICES
      let indexToDeleteDevices = this.users.indexOf(itemId);
      this.users.splice(indexToDeleteDevices, 1);

      //DELETE IN DEVICES-TABLE-DATA
      let indexToDelete = this.usersTableData.indexOf(item);
      this.usersTableData.splice(indexToDelete, 1);

      // ELIMINA CUALQUIER USUARIO VINCULADO A TRAVES DE SHARED_BY
      this.usersTableData = this.usersTableData.filter((user) => {
        return !user.devices.some(
          (device) => device.shared_by && device.shared_by.userId === itemId && this.serialNumber === device.device_sn,
        );
      });

      // ELIMINA LOS USUARIOS VINCULADOS EN THIS.USERS
      this.users = this.users.filter((userId) => {
        return !this.usersTableData.some((user) => user._id === userId);
      });

      this.$emit('deleted-correctly');
    },
    validate: function () {
      return this.$refs.deviceForm.validate();
    },
    randomHEX(number) {
      if (number) {
        this.password = parseInt(number).toString(16).toUpperCase();
      } else {
        let n = (Math.random() * 0xfffff * 1000000).toString(16);
        this.password = n.slice(0, 5).toUpperCase();
      }
    },
    randomAlphanumeric(lenght) {
      let result = '';
      for (let i = 0; i < lenght; i++) {
        let random = Math.random().toString(36).substr(2, 1);
        if (parseInt(Math.random() * 10) % 2 == 0) {
          random = random.toUpperCase();
        }
        result += random;
      }
      return result;
    },
  },

  mounted: async function (e) {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
    this.latestOdometerRaw = this.deviceData.last_frames?.frame_gps?.odometer.raw;
    this.latestEngineTimeRaw = this.deviceData.last_frames?.frame_frigo?.engine_time.raw;
    this.devicesList = await getAllDevices();
    this.$emit('setDevices', this.devicesList);
    this.usersToChoose = await getAllUsers();
    this.usersTableData = await getDeviceUsers(this.deviceData._id);
    this.isTableLoading = false;
    this.companiesToChoose = await getAllCompanies();

    if (!this.deviceData.device_sn) {
      this.randomHEX();
      this.aesIv = this.randomAlphanumeric(16);
      this.aesKey = this.randomAlphanumeric(16);
    } else {
      if (this.deviceData.companies_related) {
        for (let company of this.deviceData.companies_related) {
          if (company.type_name == 'distributor') {
            this.selectedDistributor = await getCompany(company.company_id);
          }
          if (company.type_name == 'workshop') {
            this.selectedWorkshop = await getCompany(company.company_id);
          }
          if (company.type_name == 'company') {
            this.selectedCompany = await getCompany(company.company_id);
          }
        }
      }
      if (this.deviceData.last_frames.frame_frigo?.engine_time) {
        this.frigoHoursAdjustment = this.deviceData.last_frames.frame_frigo.engine_time.offset_applied.toFixed();
      }
      if (this.deviceData.device_data_from_frame.carplate) {
        this.carplate = this.deviceData.device_data_from_frame.carplate;
      }
      if (this.deviceData.last_frames.frame_gps?.odometer) {
        this.odometerAdjustment = this.deviceData.last_frames.frame_gps.odometer.offset_applied.toFixed(3);
      }
      for (let user of this.usersTableData) {
        this.users.push(user._id);
      }
      this.isGPSEnabled = this.deviceData.gps_enabled;
      this.enabled = this.deviceData.enabled;
      this.certificateDate = this.deviceData.certificate_date
        ? new Date(this.deviceData.certificate_date).toJSON().replace(/\//g, '-').split('T')[0]
        : '';
    }
    this.isLoadingUsers = false;
  },

  computed: {
    editing() {
      return this.$route.name === 'editDevice';
    },

    showLinkTracker() {
      return this.selectedModel === 'apache' || this.selectedModel === 'tmb' || this.selectedModel === 'andra';
    },
    resendFramesTo() {
      let linked = '';
      if (this.deviceData.linked_to) {
        linked = this.deviceData.linked_to;
      }
      return linked.toString();
    },
    usersTableHeader() {
      return [
        { text: this.$t('user.atribute.name'), value: 'name' },
        { text: this.$t('user.atribute.email'), value: 'email' },
        { text: this.$t('user.atribute.roles.roles'), value: 'type' },
        { text: '', value: 'actions' },
      ];
    },

    getModels() {
      return [
        { name: 'Apache', value: 'apache' },
        { name: 'Andra', value: 'andra' },
        { name: 'tmb', value: 'tmb' },
        { name: 'Tracker', value: 'tracker' },
      ];
    },

    getSubmodels() {
      if (this.selectedModel == 'apache') {
        return [
          { name: 'Core', value: 'core' },
          { name: 'Trailer', value: 'trailer' },
          { name: 'Storage', value: 'storage' },
        ];
      }
      if (this.selectedModel == 'tracker') {
        return [
          { name: this.$t('device.submodels.truck'), value: 'truck' },
          { name: this.$t('device.submodels.car'), value: 'car' },
        ];
      }
    },

    getVehicleTypes() {
      return [
        { name: this.$t('device.submodels.delivery'), value: 'delivery' },
        { name: this.$t('device.submodels.long_distance'), value: 'trailer' },
      ];
    },

    passwordRules() {
      return [
        (value) => !!value || this.$t('user.password.error.empty_password'),
        (value) => (value && value.length >= 6) || this.$t('user.password.error.invalid_password'),
      ];
    },
    textRules() {
      return [(value) => !!value || this.$t('general.error.empty_value')];
    },
    emailRules() {
      return [(value) => (!!value && /.+@.+/.test(value)) || this.$t('general.error.not_valid_email')];
    },
    notificationEmailsRules() {
      return [
        (value) => {
          if (value) return (!!value && /.+@.+/.test(value)) || this.$t('general.error.not_valid_email');
          else return true;
        },
      ];
    },
  },
};
</script>
