<template id="template">
  <div v-if="!isMobile" id="container-full-screen" class="container-sample">
    <img :src="logo" class="logo" contain />
    <div class="body-container">
      <div align="center">
        <h1>{{ $t('user.atribute.validate_email') }}</h1>
        <br />
      </div>
      <template>
        <div id="middle-section" class="middle-section">
          <div class="text-section">
            <h3>{{ $t('user.atribute.code_validate') }}</h3>
          </div>
        </div>
      </template>
      <template>
        <div>
          <div class="ma-auto position-relative" style="max-width: 300px">
            <v-otp-input ref="otpInput" autofocus v-model="otp" :disabled="loading" @finish="onFinish"></v-otp-input>
            <v-overlay absolute :value="loading">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-overlay>
          </div>
          <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="2000">
            {{ text }}
          </v-snackbar>
        </div>
      </template>
    </div>
    <v-footer :padless="true" fixed>
      <v-card flat tile width="100%" color="#3B444F" class="lighten-1 text-right">
        <v-card-text> </v-card-text>
      </v-card>
    </v-footer>
  </div>

  <!-- Mobile view -->

  <v-container v-else id="container-mobile" class="container-mobile">
    <img :src="logo" class="logo" contain />
    <div class="body-container">
      <div align="center">
        <h1>{{ $t('user.atribute.validate_email') }}</h1>
        <br />
      </div>
      <template>
        <div id="middle-section" class="middle-section">
          <div class="text-section">
            <h3>{{ $t('user.atribute.code_validate') }}</h3>
          </div>
        </div>
      </template>
      <template>
        <div>
          <div class="ma-auto position-relative" style="max-width: 300px">
            <v-otp-input ref="otpInput" v-model="otp" :disabled="loading" @finish="onFinish"></v-otp-input>
            <v-overlay absolute :value="loading">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-overlay>
          </div>
          <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="2000">
            {{ text }}
          </v-snackbar>
        </div>
      </template>
      <div class="text-center ma-2"></div>
    </div>
    <v-footer :padless="true" fixed>
      <v-card flat tile width="100%" color="#3B444F" class="lighten-1 text-right">
        <v-card-text> </v-card-text>
      </v-card>
    </v-footer>
  </v-container>
</template>

<script>
import { isActivatedEmail, deleteValidationCode, checkCode } from '../viewModels/userViewModel';
const VueI18n = require('../plugins/lang/index');
const ls = require('localstorage-slim');

export default {
  data: () => ({
    switchValue: true,
    notificationsEmailsLength: 5,
    logo: require(process.env.VUE_APP_LOGO_VALIDATION),
    email: '',
    user: null,
    loading: false,
    otp: '',
    text: '',
    expectedOtp: '',
    snackbar: false,
    snackbarColor: 'default',
  }),

  mounted: async function () {},

  methods: {
    async onFinish(value) {
      this.loading = true;
      let notificationEmail = decodeURIComponent(window.atob(this.$route.params.email));
      this.expectedOtp = await checkCode(value, notificationEmail);

      let isValidate = this.expectedOtp?.validate?.some((i) => i.code == value && i.email == notificationEmail);

      if (isValidate) {
        setTimeout(() => {
          this.loading = false;
          this.snackbarColor = 'success';
          this.text = `${VueI18n.default.t('user.atribute.code')} ${value} ${VueI18n.default.t('user.atribute.is_correct')}`;
          this.snackbar = true;
        }, 2000);
        const valueToRemove = notificationEmail;

        for (let i = 1; i <= this.notificationsEmailsLength; i++) {
          const itemToDelete = ls.get(i);
          const email = itemToDelete?.email;

          if (email === valueToRemove) {
            ls.remove(i);
            break;
          }
        }

        let payload = {
          user_email: this.expectedOtp.user_email,
          switchValue: this.switchValue,
          notificationEmail: notificationEmail,
        };

        await isActivatedEmail(payload);
        await deleteValidationCode(notificationEmail);
        setTimeout(() => {
          window.close();
        }, 4000);
      } else {
        this.loading = false;
        this.snackbarColor = 'warning';
        this.snackbar = true;
        this.text = `${VueI18n.default.t('user.atribute.code')} ${value} ${VueI18n.default.t('user.atribute.is_incorrect')}`;
        this.otp = '';
        this.$nextTick(() => {
          this.$refs.otpInput.focus();
        });
      }
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>
