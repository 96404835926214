<template>
  <v-container>
    <v-row id="user-view-desktop">
      <v-dialog v-model="isActive" max-width="32rem" @click:outside="cancelCreate">
        <v-card class="create-customer-modal pa-4 mx-lg-auto">
          <h1 class="mb-3">{{ $t('shared.create_account') }}</h1>
          <h5 class="mb-3">{{ $t('shared.max_clients') }}</h5>
          <v-text-field
            v-model="name"
            type="text"
            class="mx-5"
            :label="this.$t('shared.name')"
            outlined
            autofocus
            required></v-text-field>
          <v-text-field
            v-model="company"
            type="text"
            class="mx-5"
            :label="this.$t('shared.company')"
            outlined
            required></v-text-field>
          <v-text-field
            v-model="phone"
            type="number"
            class="mx-5"
            :label="this.$t('shared.number')"
            outlined
            required></v-text-field>
          <v-text-field
            type="email"
            v-model="email"
            class="mx-5"
            :label="this.$t('shared.email')"
            placeholder="example@email.com"
            outlined
            :rules="emailsRules"
            required></v-text-field>
          <v-row class="d-flex" style="justify-content: space-around">
            <v-btn :loading="loadingButton" @click="create" color="primary" class="mr-3">
              <font-awesome-icon class="white--text mr-1" icon="fa-solid fa-user-plus" />
              {{ $t('shared.creater') }}
            </v-btn>
            <v-btn @click="cancelCreate" class="mb-5" color="red">
              <v-icon class="white--text mr-1">mdi-exit-to-app</v-icon>
              <span class="white--text">{{ $t('general.form.exit') }}</span>
            </v-btn>
          </v-row>
        </v-card>
        <v-snackbar min-height="5rem" v-model="snackbar" :timeout="timeout" color="success">
          {{ $t('shared.successfully_created') }}
          <template v-slot:action="{ attrs }">
            <v-btn color="black" text v-bind="attrs" @click="cancelCreate"> {{ $t('shared.close') }} </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar min-height="5rem" v-model="snackbar2" :timeout="timeout" color="error">
          {{ $t('shared.fail_to_create') }}
          <template v-slot:action="{ attrs2 }">
            <v-btn color="black" text v-bind="attrs2" @click="snackbar2 = false"> {{ $t('shared.close') }} </v-btn>
          </template>
        </v-snackbar>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<style>
.title {
  font-size: large;
}
</style>

<script>
import { getUser } from '../../viewModels/userViewModel';
import { createClient } from '../../viewModels/clientViewModel';
import { addClient } from '../../viewModels/clientViewModel';
import { CreateClientException } from '../../server/petitions/clients/createClient.js';
import { validEmail } from '../../utils/utils';

export default {
  components: {},
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    snackbar: false,
    snackbar2: false,
    timeout: 5000,
    name: '',
    company: '',
    phone: '',
    email: '',
    loadingButton: false,
  }),
  computed: {
    emailsRules() {
      return [
        (value) => {
          if (value) return (!!value && validEmail(value)) || this.$t('general.error.not_valid_email');
          else return true;
        },
      ];
    },
  },

  methods: {
    clearModal() {
      this.name = '';
      this.company = '';
      this.phone = '';
      this.email = '';
    },

    create: async function () {
      this.loadingButton = true;
      let user = await getUser();
      let name = this.name;
      let company_id = '';
      let email = this.email;
      let notification_emails = [];
      let phone = this.phone;
      let password = this.email;
      let restricted = false;
      let notes = '';
      let devices_id = [];
      let roles = 'client';
      let platform = user.platform;
      let unit = user.unit;
      let restrictions = [];
      let max_clients = 5;
      let locale = user.locale;
      const payload = {
        name,
        company_id,
        email,
        notification_emails,
        phone,
        password,
        notes,
        devices_id,
        accept_privacy_policy: false,
        clients_ids: [],
        type: roles,
        platform,
        unit,
        restrictions,
        restricted,
        locale,
        max_clients,
      };
      try {
        let res = await createClient(payload);
        if (res == CreateClientException.emailAlreadyInClient) {
          this.snackbar2 = true;
          setTimeout(() => {
            this.loadingButton = false;
          }, 3000);
        } else {
          this.snackbar = true;
          await addClient(name, email);
          setTimeout(() => {
            this.loadingButton = false;
            this.clearModal();
            this.$emit('cancel-create');
          }, 3000);
        }
      } catch (e) {
        console.error(e);
      }
    },
    cancelCreate: function () {
      this.$emit('cancel-create');
    },
  },
};
</script>
