const PetitionError = require('../../petitionError');
const { put } = require('../../httpRequester');

class ModifyBeaconNotesByUserException extends PetitionError {
  static errorToChangeNotes = 'ERROR_TO_CHANGE_NOTES';

  constructor(code) {
    super('Modify notes beacon by user', code);
  }
}

async function modifyBeaconNotesByUser(token, payload) {
  const response = await put('users/notes_beacon', payload, token);

  if (response.result !== ModifyBeaconNotesByUserException.success) {
    throw new ModifyBeaconNotesByUserException(response.result);
  } else {
    return response.beacon;
  }
}

module.exports = { modifyBeaconNotesByUser, ModifyBeaconNotesByUserException };
