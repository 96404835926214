const PetitionError = require('../../petitionError');
const { get } = require('../../httpRequester');

class GetAllUserMaintenancePetitionException extends PetitionError {
  constructor(code) {
    super(' Get All Maintenances ', code);
  }
}

async function getAllUserMaintenancePetition(user) {
  const response = await get(`maintenances/${user.email}`, user.token);

  if (response.result !== GetAllUserMaintenancePetitionException.success) {
    throw new GetAllUserMaintenancePetitionException(response.result);
  } else {
    return response.response_maintenance;
  }
}

module.exports = { getAllUserMaintenancePetition, GetAllUserMaintenancePetitionException };
