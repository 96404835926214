import Vue from 'vue';
import App from './app.vue';
import './App.scss';
import vuetify from './plugins/vuetify';
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import router from './plugins/router';
import store from './plugins/store';
require('./plugins/fontawesome');
import i18n from './plugins/lang';
import CountryFlag from 'vue-country-flag';
import Notifications from 'vue-notification';

export const eventBus = new Vue();

Vue.component('country-flag', CountryFlag);
Vue.directive('longpress', {
  bind(el, binding, vNode) {
    // Make sure expression provided is a function
    if (typeof binding.value !== 'function') {
      // Fetch name of component
      const compName = vNode.context.name;
      // pass warning to console
      let warn = `[longpress:] provided expression '${binding.expression}' is not a function, but has to be`;
      if (compName) {
        warn += `Found in component '${compName}' `;
      }

      console.warn(warn);
    }

    // Define variable
    let pressTimer = null;

    // Define funtion handlers
    // Create timeout ( run function after 1s )

    const handler = (e) => {
      binding.value(e);
    };

    const start = (e) => {
      if (e.type === 'click' && e.button !== 0) {
        return;
      }

      if (pressTimer === null) {
        pressTimer = setTimeout(() => {
          // Run function
          handler();
        }, 1000);
      }
    };

    // Cancel Timeout
    const cancel = (e) => {
      // Check if timer has a value or not
      if (pressTimer !== null) {
        clearTimeout(pressTimer);
        pressTimer = null;
      }
    };
    // Run Function
    // const handler = (e) => {
    //   binding.value(e);
    // };

    // Add Event listeners
    el.addEventListener('mousedown', start);
    el.addEventListener('touchstart', start);
    // Cancel timeouts if this events happen
    el.addEventListener('click', cancel);
    el.addEventListener('mouseout', cancel);
    el.addEventListener('touchend', cancel);
    el.addEventListener('touchcancel', cancel);
  },
});

Vue.config.productionTip = false;

Vue.use(vuetify);
Vue.use(Notifications);

new Vue({
  vuetify,
  icons: {
    iconfont: 'mdi',
  },
  router,
  store,
  i18n,
  Notifications,
  render: (h) => h(App),
}).$mount('#app');
