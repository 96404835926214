const PetitionError = require('../../petitionError');
const { getAnonymous } = require('../../httpRequester');

class getUserNotificationsException extends PetitionError {
  constructor(code) {
    super('Get Notification', code);
  }
}

async function getUserNotifications(token, user_email) {
  const response = await getAnonymous(`notifications/get_notifications_user?user_email=${user_email}&token=${token}`);
  if (response.result !== getUserNotificationsException.success) {
    return new getUserNotificationsException(response.result);
  }
  return response;
}

async function getUserNotificationsBySn(token) {
  const response = await getAnonymous(`notifications/?token=${token}`);
  if (response.result !== getUserNotificationsException.success) {
    return new getUserNotificationsException(response.result);
  }
  return response;
}

module.exports = { getUserNotifications, getUserNotificationsBySn, getUserNotificationsException };
