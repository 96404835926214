const userViewModel = require('./userViewModel');
const { getUserPOIS } = require('../server/petitions/poi/getUserPOIS');
const { saveUserPOI } = require('../server/petitions/poi/saveUserPOI');
const { deleteUserPOI } = require('../server/petitions/poi/deletePOI');

const poiViewModel = function () {
  async function savePOI(poi) {
    const user = await userViewModel.getUser();
    const token = await userViewModel.getToken();
    const res = await saveUserPOI(user, token, poi);
    return res;
  }

  async function getPOIS() {
    const user = await userViewModel.getUser();
    const token = userViewModel.getToken();
    const res = await getUserPOIS(user, token);
    return res;
  }

  async function deletePOI(name, lat, lng) {
    const token = await userViewModel.getToken();
    user = await userViewModel.getUserByToken(token);
    const res = await deleteUserPOI(token, user.email, name, lat, lng);
  }

  return {
    savePOI,
    getPOIS,
    deletePOI,
  };
};

module.exports = poiViewModel();
