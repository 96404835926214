const brokerConnection = require('../../brokerConnection/brokerConnection');
const PetitionError = require('../../petitionError');

class GetUpdatedFramesException extends PetitionError {
  static userNotHaveDevices = 'USER_NOT_HAVE_DEVICES';

  constructor(code) {
    super('Get user devices Exception', code);
  }
}

async function getUpdatedFrames(device_sn, onDeviceObtained) {
  const topic = `update/frame/${device_sn}`;

  await brokerConnection.suscribeToUpdate(topic, (msg) => {
    const frame = JSON.parse(msg.toString());
    onDeviceObtained(frame.type, frame.message);
  });
}

module.exports = { getUpdatedFrames, GetUpdatedFramesException };
