const VueI18n = require('../../plugins/lang/index');

const handleFooter = () => {
  const createNotification = (notification, carplate, device_sn) => {
    return {
      group: 'foo',
      title:
        notification.type == 'temp' || 'door'
          ? VueI18n.default.t('notifications.alert')
          : VueI18n.default.t('general.form.certificate'),
      text:
        notification.type == 'temp'
          ? `${carplate} - ${device_sn} <br>${VueI18n.default.t('notifications.temperatureOutOfRange')}`
          : notification.type == 'door'
          ? `${carplate} - ${device_sn} <br>${VueI18n.default.t('notifications.openDoor')}`
          : `${carplate} - ${device_sn} <br>${VueI18n.default.t('notifications.certificates')}`,
      duration: 8000,
      max: 5,
      ignoreDuplicates: true,
    };
  };
  const footerController = (notification, i, notifications, notify) => {
    let carplate = notification.device_sn == i.device_sn ? i.alias : '';

    const types = ['temp', 'door', 'certs'];
    types.map((i) => {
      if (notification.type == i) notify(createNotification(notification, carplate, notification.device_sn));
    });

    // EVITAR PUSH NOTIFICACIONES DUPLICADAS
    const isExistId = notifications.find((item) => item._id === notification._id);
    if (!isExistId) notifications.push(notification);
  };

  return { footerController };
};

module.exports = handleFooter();
