const MQTT = require('mqtt');

const optionsTSL = {
  protocol: 'wss',
  rejectUnauthorized: false,
  secureProtocol: 'TLSv1_2_method',
};
// const uri = "wss://localhost:8083/mqtt" // develop
// const uri = 'wss://server.termografoapache.com:8083/mqtt'; // production
// const uri = 'wss://serveraux.termografoapache.com:8083/mqtt'; // production
const uri = process.env.VUE_APP_URI ? process.env.VUE_APP_URI : 'wss://mqtt.termografoapache.com:8083/mqtt'; // production

/*
Estructura
{
    topic:"",
    callback:(msg){}
}
*/
// Lista de callbacks, lista de topics a los que se esta subscrito
const listCallbacksOnMessageReceived = [];

const BrokerConnection = function () {
  let _connection;

  async function initializeBroker(onInit) {
    _connection = MQTT.connect(uri, optionsTSL);

    _connection.on('error', (error) => {
      console.log(error);
    });
    _connection.on('message', (topic, msg) => {
      listCallbacksOnMessageReceived.forEach((element) => {
        if (topic === element.topicSubscribed) {
          element.callback(msg);
        }
      });
    });
    _connection.on('connect', () => {
      onInit();
    });
  }

  async function checkIfBrokerIsInitAndInitIfNot() {
    const promiseConnection = new Promise((resolve) => {
      if (_connection == null) {
        initializeBroker(() => {
          resolve();
        });
      } else {
        resolve();
      }
    });

    await promiseConnection;
  }

  async function suscribeToUpdate(topic, proccessData) {
    await checkIfBrokerIsInitAndInitIfNot();

    _connection.subscribe(topic, (err, granted) => {
      _addCallback(topic, topic, proccessData);
    });
  }

  async function sendAndWaitResponse(topic, token, mesageRequest, onResponseReceived) {
    await checkIfBrokerIsInitAndInitIfNot();
    const topicRequest = `request/${topic}/${token}`;
    const topicResponse = `response/${topic}/${token}`;

    _connection.subscribe(topicResponse, () => {});
    _addCallback(topic, topicResponse, (msg) => {
      stopReceiving(topic);
      onResponseReceived(msg);
    });

    connec_connectiontion.publish(topicRequest, mesageRequest);
  }

  async function sendAndWaitMultipleResponses(topic, token, mesageRequest, onResponseReceived) {
    await checkIfBrokerIsInitAndInitIfNot();

    const topicRequest = `request/${topic}/${token}`;
    const topicResponse = `response/${topic}/${token}`;

    _connection.subscribe(topicResponse, (err, granted) => {
      _addCallback(topic, topicResponse, onResponseReceived);

      _connection.publish(topicRequest, mesageRequest, (err) => {
        console.log(`Publishing error: ${err}`);
      });
    });
  }

  function stopReceiving(topic) {
    const topicSubscribed = _removeCallbackAndGetTopicSubscribed(topic);

    _connection.unsubscribe(topicSubscribed, () => {});
  }

  function _addCallback(topicGeneral, topicSubscribed, callback) {
    listCallbacksOnMessageReceived.push({
      topicGeneral,
      topicSubscribed,
      callback,
    });
  }

  function _removeCallbackAndGetTopicSubscribed(topic) {
    const indexesToRemove = [];
    let topicSubscribed;
    listCallbacksOnMessageReceived.forEach((element, index) => {
      if (element.topicGeneral == topic) {
        indexesToRemove.push(index);
        topicSubscribed = element.topicSubscribed;
      }
    });
    indexesToRemove.forEach((indexRemove) => {
      listCallbacksOnMessageReceived.splice(indexRemove, 1);
    });
    return topicSubscribed;
  }

  return {
    initializeBroker,
    sendAndWaitResponse,
    sendAndWaitMultipleResponses,
    stopReceiving,
    suscribeToUpdate,
  };
};

module.exports = BrokerConnection();
