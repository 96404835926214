<template>
  <v-container v-if="!isMobile" id="container-dashboard" style="height: 100%" fluid>
    <v-row no-gutters id="content-row">
      <v-snackbar v-model="noRoute" color="orange darken-1" timeout="2000">
        <v-icon class="mb-2">mdi-alert</v-icon>
        <span class="font-weight-bold text-subtitle-1 ml-3">{{ this.$t('dashboard.device_card.no_movement_today') }}</span>

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="noRoute = false"> {{ $t('dashboard.device_card.close') }} </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="noMovement" color="orange darken-1" timeout="2000">
        <v-icon class="mb-2">mdi-alert</v-icon>
        <span class="font-weight-bold text-subtitle-1 ml-3">{{ this.$t('dashboard.device_card.stop_today') }}</span>

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="noMovement = false"> {{ $t('dashboard.device_card.close') }} </v-btn>
        </template>
      </v-snackbar>
      <v-col id="device-list" cols="auto" style="width: 20%" class="device-list-col">
        <DeviceList
          style="overflow: clip"
          class="dashboardObject-deviceList"
          @onShowInfo="onShowDeviceInfo"
          @onApplyFilter="typeFilter"
          @onSearch="searchFilter"
          @onExitRoutes="sendExitRoutes"
          @change="update"
          ref="devicesList" />
      </v-col>
      <v-col id="colDeviceInfo" class="col col-auto" data-cy="colDeviceInfo">
        <v-expand-x-transition hide-on-leave="true">
          <DeviceInfoList
            :myPOIS="myPOIS"
            data-cy="deviceInfoList"
            :key="'A' + updateMyComponent"
            class="dashboardObject"
            v-bind:device="deviceSelected"
            v-show="closeButtons"
            @onCloseInfo="onCloseDeviceInfo"
            @onShowInfo="onShowDeviceInfo"
            @onDrawRoute="drawRoute"
            ref="deviceInfoList"
            @updateOdometer="updateMyComponent"
            id="device-info" />
        </v-expand-x-transition>
      </v-col>
      <context-menu :display="showMouseOver" ref="mouseover">
        <MouseOverCard
          class="mouseover"
          v-if="showMouseOver"
          :device="deviceForQuickCard"
          :position="position"
          @onDrawRoute="drawRoute"
          :key="deviceForQuickCard.device_sn" />
      </context-menu>

      <v-col id="dashboard-temperatures-scroll">
        <router-view
          class="dashboardObject2"
          ref="dashboardView"
          @onMarkerTap="onShowDeviceInfo"
          @mouseOverDevice="mouseoverDevice"
          @onInit="initializeDevices"
          @contextmenu="openContextMenu"
          @openTicketDialog="openTicket">
        </router-view>
        <Movement
          v-bind:device="deviceSelected"
          :key="'AB' + updateMyComponent"
          id="dashboard-movement-line"
          v-if="showMovementBar"
          ref="movementBar">
        </Movement>
      </v-col>
    </v-row>

    <context-menu :display="showContextMenu" ref="menu">
      <ul v-click-outside="closeContextMenu">
        <li style="list-style-type: none">
          <POIDialog ref="poiDialog" v-model="editedPOI" @newPOI="clearPOI" @savePOI="savePOI" :title="$t('dashboard.new_poi')" />
        </li>
        <li v-if="deviceSelected" style="list-style-type: none" @click="drawBestRoute">
          <v-btn color="primary" dark class="mb-2 ml-2" @click="drawBestRoute">
            {{ this.$t('dashboard.device_card.route_to_here') }}
          </v-btn>
        </li>
        <li v-if="deviceSelected" style="list-style-type: none" @click="drawBestRouteTo">
          <v-col cols="auto">
            <v-dialog transition="dialog-top-transition" max-width="600" v-model="dialog">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-on="on"> {{ $t('dashboard.device_card.route_to') }} </v-btn>
              </template>
              <template>
                <v-card
                  :style="{
                    color: colorTheme,
                    background: backgroundcolorTheme,
                  }"
                  :dark="theme === 'dark'">
                  <v-toolbar :style="{ color: colorTheme }" color="primary">{{ $t('general.form.address') }}</v-toolbar>
                  <v-text-field style="margin: 0.5rem 1rem 0 1rem" autofocus required v-model="address"></v-text-field>
                  <v-card-actions class="justify-end">
                    <v-btn color="primary" dark class="mb-2 ml-2" @click="drawBestRouteTo">
                      {{ $t('general.form.accept') }}
                    </v-btn>
                    <v-btn color="primary" dark class="mb-2 ml-2" @click="dialog = false">
                      {{ $t('general.form.cancel') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
        </li>
      </ul>
    </context-menu>
    <TicketDialog
      :isActive="ticketDialog"
      v-on:cancel-dialog="closeDialog"
      :device="showTicketDevice ? showTicketDevice : { device_sn: null, device_data_from_frame: { carplate: null } }">
    </TicketDialog>

    <!-- <UpdatesDialog :isActive="updatesDialog" v-on:accept-dialog="acceptDialog">
    </UpdatesDialog> -->
  </v-container>

  <!-- Mobile view -->

  <v-container v-else id="container-dashboard-mobile" style="height: 100%" fluid>
    <v-row no-gutters id="content-row" class="content-row-mobile">
      <v-col v-show="selectDeviceList" id="device-list" class="device-list-mobile" cols="auto">
        <DeviceList
          class="dashboardObject-deviceList"
          @showMyDevicelist="showDeviceList"
          @dontShow="dontshowDeviceList"
          @onShowInfo="onShowDeviceInfo"
          @onApplyFilter="typeFilter"
          @onSearch="searchFilter"
          @closeButtons="dontshowDeviceList"
          @change="update"
          ref="devicesList" />
      </v-col>
      <v-col id="colDeviceInfo" class="col col-auto">
        <v-expand-x-transition v-show="selectDeviceInfoList">
          <DeviceInfoList
            :myPOIS="myPOIS"
            :key="'B' + updateMyComponent"
            class="dashboardObject"
            v-bind:device="deviceSelected"
            v-show="closeButtons"
            @onCloseInfo="onCloseDeviceInfo"
            @onDrawRoute="drawRoute"
            @closeButtons="dontshowDeviceList"
            ref="deviceInfoList" />
        </v-expand-x-transition>
      </v-col>

      <v-col class="device-view" id="container-dashboard">
        <div class="showMap"></div>
        <router-view
          style="position: initial !important; border-radius: 0px"
          v-longpress="longHold"
          content-class="dashboardObjectMobile"
          ref="dashboardView"
          @onMarkerTap2="onShowDeviceInfo"
          @onMarkerTap="onShowDeviceInfo"
          @onInit="initializeDevices"
          @contextmenuMobile="openContextMenu"
          @openTicketDialog="openTicket"></router-view>
        <Movement
          v-bind:device="deviceSelected"
          :key="'AB' + updateMyComponent"
          id="dashboard-movement-line-mobile"
          v-if="showMovementBar"
          ref="movementBar">
        </Movement>
      </v-col>
      <context-menu :display="showContextMenu" v-if="permission" ref="menu">
        <ul>
          <li style="list-style-type: none">
            <POIDialog
              ref="poiDialog"
              v-model="editedPOI"
              @newPOI="clearPOI"
              @savePOI="savePOI"
              :title="$t('dashboard.new_poi')" />
          </li>
          <li v-if="deviceSelected" style="list-style-type: none" @click="drawBestRoute">
            <v-btn color="primary" dark class="mb-2 ml-2" @click="drawBestRoute">
              {{ this.$t('dashboard.device_card.route_to_here') }}
            </v-btn>
          </li>
        </ul>
      </context-menu>
    </v-row>
  </v-container>
</template>

<script>
import { getUserDevices, getUserDevicesLittleByLittle, reset } from '../../viewModels/devicesViewModel';
import { getUser, getUpdatedUser, getToken } from '../../viewModels/userViewModel';
import { getUpdatedFrames } from '../../server/petitionsMqtt/devices/updateDevices';
import { unsuscribeUpdates } from '../../server/petitionsMqtt/devices/unsuscribeUpdates';
import DeviceList from '../../components/dashboard/deviceList/devicesList.vue';
import DeviceInfoList from '../../components/dashboard/deviceInfoList/deviceInfoList.vue';
import MouseOverCard from '../../components/dashboard/deviceCard/mouseOverCard/mouseOverCard.vue';
import TicketDialog from '../../components/dashboard/ticketDialogo/ticketDialog.vue';
import ContextMenu from '../../components/dashboard/contextMenu.vue';
import PoiConfiguration from '../../components/dashboard/poiConfiguration.vue';
import { eventBus } from '../../main';
import { getAllPOIS } from '../../server/petitions/pois/getAllPOIS';
import Movement from '../../components/dashboard/movementLine.vue';
import POIDialog from '../../components/dashboard/pois/poiDialog.vue';
import { postPOI } from '../../server/petitions/pois/postPOI';
import { omit } from '../../utils/utils';
const ls = require('localstorage-slim');

let SN;
export default {
  components: {
    DeviceList,
    DeviceInfoList,
    ContextMenu,
    PoiConfiguration,
    MouseOverCard,
    TicketDialog,
    Movement,
    POIDialog,
  },

  data: () => ({
    backgroundcolorTheme: ls.get('theme') === 'dark' ? 'grey !important' : '',
    colorTheme: ls.get('theme') === 'dark' ? 'white !important' : '',
    theme: ls.get('theme') ?? 'ligth',
    configuration: false,
    update1: 0,
    updateMyComponent: 0,
    showDeviceInfo: true,
    deviceSelected: null,
    view: null,
    deviceList: [],
    showOnTop: 7,
    selectDeviceList: false,
    selectDeviceInfoList: false,
    searchBox: false,
    noRoute: false,
    noMovement: false,
    showContextMenu: false,
    position: null,
    permission: false,
    firstClickOutside: true,
    deviceForQuickCard: null,
    user: null,
    token: null,
    ticketDialog: false,
    closeButtons: true,
    myPOIS: [],
    openTicketDialog: false,
    closeCardProps: false,
    defaultPOI: {
      name: '',
      address: '',
      latitude: 0,
      longitude: 0,
      radius: 50,
    },
    editedPOI: {
      name: '',
      address: '',
      latitude: 0,
      longitude: 0,
      radius: 50,
    },
    dialog: false,
    address: '',
    //showMouseOverCard: store.state.showMouseOver,
    //updatesDialog: false,
    routeName: '',
  }),

  methods: {
    closeContextMenu() {
      this.showContextMenu = false;
    },
    clearPOI() {
      this.editedPOI = Object.assign({}, this.defaultPOI);
      this.editedPOI.latitude = this.position.lat;
      this.editedPOI.longitude = this.position.lng;
      this.showContextMenu = false;
    },
    async savePOI() {
      const poi = { ...omit(this.editedPOI, 'category'), userId: this.user.userId };
      const resultCreatedPOI = await postPOI(this.token, poi);

      this.clearPOI();
      this.$refs.poiDialog.close();
      this.showContextMenu = false;
      this.updateMyPois();
    },
    openTicket() {
      this.$refs.deviceInfoList.openTicketDialog();
    },

    drawBestRoute() {
      this.$refs.dashboardView.drawBestRoute(this.deviceSelected, this.position);
      this.showContextMenu = false;
      eventBus.$emit('closecontextmenuMobile');
      eventBus.$emit('cleanSearch');
    },
    drawBestRouteTo() {
      this.$refs.dashboardView.drawBestRouteTo(this.deviceSelected, this.address);
      this.dialog = false;
    },

    updateMyPois() {
      this.$refs.dashboardView.addPoiToMap(this.user);
    },

    closeDialog: function () {
      this.ticketDialog = false;
    },

    longHold() {
      eventBus.$emit('longHold');
      this.permission = true;
    },

    closeConfiguration: function () {
      this.configuration = false;
      this.update1++;
      this.permission = false;
      this.firstClickOutside = true;
    },

    openContextMenu(bothPositions) {
      this.showContextMenu = true;
      this.position = bothPositions.position;
      let e = bothPositions.a;
      this.$refs.menu.open(e);
    },

    onCloseDeviceInfo() {
      this.closeButtons = false;
    },

    update() {
      this.updateMyComponent++;
    },

    onShowDeviceInfo(device, isFromMap = false) {
      // if (device.device_sn !== SN) {
      // SN = device.device_sn;
      let isParam = true;
      this.showDeviceInfo = true;
      this.closeButtons = true;
      this.deviceSelected = device;
      this.$store.dispatch('setSelectedDevice', device);
      this.$refs.deviceInfoList.updateComponent();
      this.$refs.dashboardView.focusOnDevice(device);
      this.$refs.devicesList.selectDevice(device, isParam);
      // }
    },

    mouseoverDevice(device, position) {
      if (device) {
        this.$store.dispatch('setMouseOver', true);
        this.deviceForQuickCard = device;
        this.position = position.position;
        let e = position.a;
        this.$refs.mouseover.open(e);
      }
    },

    showDeviceList() {
      this.selectDeviceList = !this.selectDeviceList;
      this.closeButtons = false;
    },

    dontshowDeviceList() {
      this.selectDeviceList = false;
      this.closeButtons = false;
    },

    drawRoute(locations, device, dates) {
      if (!locations || locations.length === 0) this.noRoute = true;
      else if (locations.length === 1 && locations[0] != 'movement') this.noMovement = true;
      else {
        // this.$refs.movementBar.focusOnDevice(device, dates);
        this.$refs.dashboardView.drawPolyline(locations, device, dates);
      }
    },
    sendExitRoutes() {
      this.$refs.dashboardView?.exitRoutes();
      if (typeof this.$refs.dashboardView.exitFastestRoutes == 'function') {
        this.$refs.dashboardView.exitFastestRoutes();
      }
    },

    showInMap() {
      let devicesList = getUserDevices();
      devicesList.forEach((device, index) => {
        let isLast = index == devicesList.length - 1;

        // this.$refs.dashboardView.addDeviceToMap(device, isLast);
        if (this.$refs.dashboardView) {
          if (this.routeName == 'map') {
            this.$refs.dashboardView.addDeviceToMap(device, isLast, this.user);
          }
        }
        if (isLast) {
          this.deviceList = devicesList;
        }
      });
      this.$route.name == 'map';
    },

    async initializeDevices() {
      let devicesList = getUserDevices();
      if (devicesList == null || devicesList.length == 0) {
        devicesList = [];

        getUserDevicesLittleByLittle((device, isLast) => {
          device.select = false;
          if (this.$refs.devicesList) {
            this.$refs.devicesList.addDeviceToList(device, isLast);
          }
          if (this.$refs.dashboardView) {
            if (this.routeName == 'map') {
              this.$refs.dashboardView.addDeviceToMap(device, isLast, this.user);
            }
          }
          if (isLast) {
            this.deviceList = devicesList;
          }
        });
      } else {
        devicesList.forEach((device, index) => {
          let isLast = index == devicesList.length - 1;

          this.$refs.dashboardView.addDeviceToMap(device, isLast, this.user);
          this.$refs.devicesList.addDeviceToList(device, isLast);
          if (isLast) {
            this.deviceList = devicesList;
          }
        });
      }
      setTimeout(this.suscribeToDevices, 6000);
    },

    async reloadDevices() {
      reset();
      this.cleanSearch;
      this.$store.dispatch('setCurrentUser', null);
      this.user = await getUpdatedUser();
      this.$store.dispatch('setCurrentUser', this.user);

      let devicesList = getUserDevices();

      if (this.$refs.devicesList) {
        this.$refs.devicesList.reset();
        this.$refs.dashboardView.resetMap();
        await getUserDevicesLittleByLittle((device, isLast) => {
          device.select = false;
          if (this.$refs.devicesList) {
            this.$refs.devicesList.addDeviceToList(device, isLast);
          }

          if (this.$refs.dashboardView) {
            if (this.routeName == 'map') {
              this.$refs.dashboardView.addDeviceToMap(device, isLast, this.user);
            }
          }
          if (isLast) {
            this.deviceList = devicesList;
          }
        });
      }
    },

    cleanSearch() {
      this.$refs.devicesList.cleanSearch();
    },

    suscribeToDevices() {
      let devicesList = this.deviceList;
      if (devicesList.length == 0) {
        devicesList = this.$refs.devicesList.devicesList;
      }
      for (let i = 0; i < devicesList.length; i++) {
        let device_sn = devicesList[i].device_sn;
        getUpdatedFrames(device_sn, (type, frame) => {
          let isDevice = (device) => device.device_sn == device_sn;
          let indexDevice = devicesList.findIndex(isDevice);
          let deviceUpdated = devicesList[indexDevice];
          switch (type) {
            case 'gps':
              deviceUpdated.last_frames.frame_gps = frame;
              if (!this.searchBox) {
                this.view.updateDeviceToMap(deviceUpdated);
              }
              if (this.deviceSelected && deviceUpdated.device_sn == this.deviceSelected.device_sn) {
                this.$refs.dashboardView.followDevice(frame.latitude, frame.longitude);
              }
              break;
            case 'frigo':
              deviceUpdated.last_frames.frame_frigo = frame;
              break;
            case 'status':
              deviceUpdated.last_frames.frame_status = frame;
              break;
            default:
              break;
          }
        });
      }
    },

    permissions() {
      if (this.permission) {
        if (this.firstClickOutside) {
          this.firstClickOutside = false;
          return;
        }
        this.permission = false;
        this.firstClickOutside = true;
        // this.openedMenu=false
      }
    },
    typeFilter(filter) {
      this.$refs.dashboardView.resetMap();
      this.$refs.devicesList.reset();

      let devicesList = getUserDevices();
      devicesList.forEach((device, index) => {
        let isLast = index == devicesList.length - 1;

        if (filter.temp && device.model != 'tracker') {
          this.addDevice(device, isLast);
        }

        if (filter.gps && device.model == 'tracker') {
          this.addDevice(device, isLast);
        }
        if (filter.frigo && device.last_frames.frame_status) {
          if (device.last_frames.frame_status.engine_on) {
            this.addDevice(device, isLast);
          }
        }
        if (filter.door && device.last_frames.frame_status) {
          if (device.last_frames.frame_status.door) {
            this.addDevice(device, isLast);
          }
        }
      });
    },

    searchFilter(options) {
      if (options.search != '') {
        this.searchBox = true;
      } else {
        this.searchBox = false;
      }

      this.$refs.dashboardView.resetMap();
      this.$refs.devicesList.reset();

      let devicesList = getUserDevices();
      devicesList.forEach((device, index) => {
        let tag;
        let carplate;
        let name;
        let isLast = index == devicesList.length - 1;
        if (device.device_data_from_frame.carplate) {
          carplate = device.device_data_from_frame.carplate.toUpperCase();
        }
        if (device.tags[0]) {
          tag = device.tags[0].toUpperCase();
        }

        for (let userDevice of this.user.devices) {
          if (userDevice.device_sn == device.device_sn) {
            if (userDevice.name) {
              name = userDevice.name.toUpperCase();
            }
          }
        }

        let sn = device.device_sn;

        sn = sn.toString();
        //!!!!!!!!-----TAG-----!!!!!!!!
        if (tag) {
          if (tag.indexOf(options.search.toUpperCase()) != -1 || sn.indexOf(options.search) != -1) {
            this.addDevice(device, isLast);
          } else {
            this.removeDevice(device, isLast, index);
          }
        }
        //!!!!!!!!-----NAME-----!!!!!!!!
        if (name) {
          if (name.indexOf(options.search.toUpperCase()) != -1 || sn.indexOf(options.search) != -1) {
            this.addDevice(device, isLast);
          } else {
            this.removeDevice(device, isLast, index);
          }
        }
        //!!!!!!!!-----CARPLATE-----!!!!!!!!
        if (carplate) {
          if (carplate.indexOf(options.search.toUpperCase()) != -1 || sn.indexOf(options.search) != -1) {
            this.addDevice(device, isLast);
          } else {
            this.removeDevice(device, isLast, index);
          }
        }
        //!!!!!!!!-----SN-----!!!!!!!!
        else {
          if (sn.indexOf(options.search) != -1) {
            this.addDevice(device, isLast);
          } else {
            this.removeDevice(device, isLast, index);
          }
        }
      });
      this.$refs.devicesList.forceRender();
    },
    addDevice(device, isLast) {
      if (this.$refs.devicesList) {
        this.$refs.devicesList.addDeviceToList(device, isLast);
      }
      if (this.$refs.dashboardView) {
        this.$refs.dashboardView.addDeviceToMap(device, isLast, this.user);
      }
    },
    removeDevice(device, isLast, index) {
      if (this.$refs.devicesList) {
        this.$refs.devicesList.removeDeviceFromList(device, isLast, index);
      }
      if (this.$refs.dashboardView) {
        this.$refs.dashboardView.removeDeviceFromMap(device, isLast, index);
      }
    },
    isMobile2() {
      return this.$vuetify.breakpoint.mobile;
    },
    // acceptDialog() {
    //   this.updatesDialog = false;
    //   markUpdateSeen();
    // },
  },

  computed: {
    currentRouteName() {
      return this.$route.name == 'map';
    },
    showMovementBar() {
      let device = this.$store.state.selectedDevice;
      if (device && this.$route.name == 'map') {
        return true;
      } else {
        return false;
      }
    },
    showMouseOver() {
      return this.$store.state.showMouseOver;
    },

    showTicketDevice() {
      return this.$store.state.mouseOverDevice;
    },
    updatePOI() {},
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  beforeMount() {
    eventBus.$on('ticket', (deviceTicket) => {
      this.$store.dispatch('setMouseOverDevice', deviceTicket);
      this.ticketDialog = true;
      this.$store.dispatch('setMouseOver', false);
    });
  },
  destroyed() {
    this.$store.dispatch('setSelectedDevice', null);
  },
  mounted: async function () {
    this.routeName = this.$route.name;
    eventBus.$on('newPOIFromMouseOverCard', () => {
      this.selectDeviceList = false;
      this.closeButtons = false;
    });
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
      this.colorTheme = theme === 'dark' ? 'white !important' : '';
      this.backgroundcolorTheme = theme === 'dark' ? 'grey !important' : '';
    });
    this.view = this.$refs.dashboardView;
    this.user = await getUser();
    this.$store.dispatch('setCurrentUser', this.user);
    this.token = getToken();
    this.myPOIS = await getAllPOIS(this.token, this.user.userId);
    this.suscribeToNotifications;
    eventBus.$on('longHoldAfterLongHold', () => {
      if ((this.permission, !this.firstClickOutside)) {
        this.firstClickOutside = true;
      }
    });

    //COMENTADO PORQUE DUPLICA DISPOSITIVOS EN EL MAPA -----------
    // eventBus.$on('focusOnThisDevice', (device) => {
    //   console.log(device, 'RECIBO DEVICE PARA LLAMAR FUNCTION DE MAP');
    //   setTimeout(() => {
    //     this.$refs.dashboardView?.focusOnDeviceFromNavbar(device);
    //   }, 200);
    // });

    eventBus.$on('route', (deviceRoute) => {
      this.$store.dispatch('setMouseOverDevice', deviceRoute);
      eventBus.$emit('routeAsked', deviceRoute);
      this.$store.dispatch('setMouseOver', false);
    });

    eventBus.$on('closeDeviceList', (device) => {
      let isParam = false;
      this.deviceSelected = null;
      if (typeof this.$refs.devicesList !== 'undefined') {
        this.$refs.devicesList.selectDevice(device, isParam);
      }
    });

    eventBus.$on('reloadDevices', () => {
      this.reloadDevices();
      // this.cleanSearch();
      this.onCloseDeviceInfo();
      // this.$refs.dashboardView.focusOnDevice();
    });
    //this.user = user.devices;
  },

  beforeDestroy() {
    this.deviceSelected = null;
    for (let i = 0; i < this.deviceList.length; i++) {
      let device_sn = this.deviceList[i].device_sn;
      unsuscribeUpdates(device_sn);
    }
  },
};
</script>
