<template>
  <v-card
    :key="device.device_sn"
    class="mx-2 pa-1 temp-card"
    @click="showInfo(device)"
    :ripple="{ class: `primary--text` }"
    outlined
    :style="isSelectedCard">
    <div class="point" :style="isSelected"></div>

    <v-row class="card-temperatures-title">
      <v-col class="pb-0">
        <v-tooltip :disabled="tagName ? false : true" bottom open-delay="700" color="rgba(0, 0, 0, 0.85)">
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="header-tempCard-device rounded white elevation-2 grey--text font-weight-bold mr-1">
              <div class="header-tempCard-device-contain d-flex justify-space-between ml-3 text-truncate">
                <span class="tempCard-device-title">{{ carplate }}</span>
                <div>
                  <v-chip
                    color="blue-grey lighten-5"
                    class="elevation-1 px-1"
                    style="max-width: 100%; margin-right: 3px"
                    x-small
                    label>
                    <v-tooltip bottom open-delay="500" color="rgba(0, 0, 0, 0.85)">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon @mouseover="getBeaconTooltip" v-bind="attrs" v-on="on" small :class="informBeaconNearby">
                          mdi-link-variant
                        </v-icon>
                      </template>
                      <span> {{ beaconTooltip }}</span>
                    </v-tooltip>
                  </v-chip>

                  <!-- //CANBUS -->
                  <!-- <v-chip
                    color="blue-grey lighten-5"
                    class="elevation-1 px-1"
                    style="max-width: 100%; margin-right: 3px"
                    x-small
                    label>
                    <v-tooltip bottom open-delay="500" color="rgba(0, 0, 0, 0.85)">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon @mouseover="getCanbusTooltip" v-bind="attrs" v-on="on" small :class="informCanbusOn">
                          mdi-truck-flatbed
                        </v-icon>
                      </template>
                      <span>Canbus {{ canbusTooltip }}</span>
                    </v-tooltip>
                  </v-chip> -->

                  <v-chip
                    color="blue-grey lighten-5"
                    class="elevation-1 px-1"
                    style="max-width: 100%; margin-right: 3px"
                    x-small
                    label>
                    <font-awesome-icon
                      icon="fa-solid fa-circle-exclamation"
                      class="indicador-exclamation"
                      v-if="deviceHasNotifications" />

                    <v-tooltip bottom open-delay="500" color="rgba(0, 0, 0, 0.85)">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          @mouseover="getFrigoTimestampTooltip"
                          @click="temperatureView"
                          v-bind="attrs"
                          v-on="on"
                          small
                          :class="isFrigoActive">
                          mdi-wifi
                        </v-icon>
                      </template>
                      <span>{{ frigoTimestampTooltip }}</span>
                    </v-tooltip>
                  </v-chip>

                  <v-chip
                    color="blue-grey lighten-5"
                    class="elevation-1 px-1"
                    style="max-width: 100%; margin-right: 3px"
                    x-small
                    label>
                    <v-tooltip bottom open-delay="500" color="rgba(0, 0, 0, 0.85)">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon @mouseover="getEngineTooltip" v-bind="attrs" v-on="on" small :class="informEngineOn">
                          mdi-snowflake
                        </v-icon>
                      </template>
                      <span>{{ $t('dashboard.temp.alarms.engine') }} {{ engineTooltip }}</span>
                    </v-tooltip>
                  </v-chip>
                </div>
              </div>
            </div>
          </template>
          <span>{{ tagName }}</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row class="card-temperatures-title" style="display: flex; justify-content: flex-end">
      <v-chip
        v-if="tag"
        color="green"
        class="elevation-1 px-1"
        style="max-width: 100%; margin-right: 19px; font-weight: bold"
        x-small
        label>
        <v-tooltip bottom open-delay="500" color="rgba(0, 0, 0, 0.85)">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"> {{ tag }}</span>
          </template>
          <span> {{ $t('user.configuration.label') }}</span>
        </v-tooltip>
      </v-chip>
    </v-row>

    <v-row class="card-temperatures-label text-sm-caption">
      <v-col
        v-if="device.last_frames.frame_gps"
        class="device-frame-gps d-flex flex-nowrap justify-space-between font-weight-medium pt-2">
        <v-tooltip bottom open-delay="700" color="rgba(0, 0, 0, 0.85)">
          <template v-slot:activator="{ on, attrs }">
            <span
              @mouseover="getGpsTimestampTooltip"
              v-bind="attrs"
              v-on="on"
              class="d-flex align-center"
              :class="locationClass"
              style="max-width: 90%">
              <span v-if="!isMobile">{{ getGpsHourTimestamp }}</span>
              <v-icon small :class="locationClass"> mdi-map-marker </v-icon>
              <span class="d-inline-block text-truncate" style="max-width: 60%">
                {{ getGeocode }}
              </span>
            </span>
          </template>
          <span>{{ gpsTimestampTooltip }} </span>
        </v-tooltip>
      </v-col>

      <v-col v-if="!device.last_frames.frame_gps" class="device-no-frame-gps d-flex flex-nowrap font-weight-medium pt-0">
        <v-icon small :class="noGPSClass">mdi-map-marker-off</v-icon>
        <span :class="noGPSClass" style="max-width: 80%"> {{ $t('dashboard.device_card.no_gps') }} </span></v-col
      >
    </v-row>

    <v-container class="card-temperatures-temperature ma-1">
      <v-row>
        <v-col class="d-flex justify-center pa-1">
          <v-tooltip bottom open-delay="500" color="rgba(0, 0, 0, 0.85)">
            <!-- Habría que refactorizar todo este archivo. Hay mucho código repetido y está acoplado a sólo 4 sensores. -->
            <template
              v-if="
                device.last_frames.frame_frigo.probes_temperature.p1 &&
                !isNaN(device.last_frames.frame_frigo.probes_temperature.p1)
              "
              v-slot:activator="{ on, attrs }">
              <v-chip small v-bind="attrs" v-on="on">
                {{
                  !isNaN(device.last_frames.frame_frigo.probes_temperature.p1)
                    ? device.last_frames.frame_frigo.probes_temperature.p1.toFixed(1)
                    : 'No data'
                }}{{
                  device.device_data_from_frame.probes_type.p1 == 'hum'
                    ? '% RH'
                    : device.device_data_from_frame.probes_type.p1 == 'temp'
                    ? 'ºC'
                    : ' AQ'
                }}
              </v-chip>
            </template>
            <span>{{ getProbe1Name }}</span>
          </v-tooltip>
        </v-col>

        <v-divider v-if="device.last_frames.frame_frigo.probes_temperature.p2" vertical></v-divider>

        <v-col class="d-flex justify-center pa-1">
          <v-tooltip bottom open-delay="500" color="rgba(red)">
            <template
              v-if="
                device.last_frames.frame_frigo.probes_temperature.p2 &&
                !isNaN(device.last_frames.frame_frigo.probes_temperature.p2)
              "
              v-slot:activator="{ on, attrs }">
              <v-chip small v-bind="attrs" v-on="on">
                {{
                  !isNaN(device.last_frames.frame_frigo.probes_temperature.p2)
                    ? device.last_frames.frame_frigo.probes_temperature.p2.toFixed(1)
                    : 'No data'
                }}{{
                  device.device_data_from_frame.probes_type.p2 == 'hum'
                    ? '% RH'
                    : device.device_data_from_frame.probes_type.p2 == 'temp'
                    ? 'ºC'
                    : ' AQ'
                }}
              </v-chip>
            </template>
            <span>{{ getProbe2Name }}</span>
          </v-tooltip>
        </v-col>

        <v-divider v-if="hasThirdProbe" vertical></v-divider>

        <v-col class="d-flex justify-center pa-1" v-if="hasThirdProbe">
          <v-tooltip bottom open-delay="500" color="rgba(0, 0, 0, 0.85)">
            <template
              v-if="
                device.last_frames.frame_frigo.probes_temperature.p3 &&
                !isNaN(device.last_frames.frame_frigo.probes_temperature.p3)
              "
              v-slot:activator="{ on, attrs }">
              <v-chip small v-bind="attrs" v-on="on">
                {{ device.last_frames.frame_frigo.probes_temperature.p3.toFixed(1)
                }}{{
                  device.device_data_from_frame.probes_type.p3 == 'hum'
                    ? '% RH'
                    : device.device_data_from_frame.probes_type.p3 == 'temp'
                    ? 'ºC'
                    : ' AQ'
                }}
              </v-chip>
            </template>
            <span>{{ getProbe3Name }}</span>
          </v-tooltip>
        </v-col>

        <v-divider v-if="hasForthProbe" vertical></v-divider>

        <v-col class="d-flex justify-center pa-1" v-if="hasForthProbe">
          <v-tooltip bottom open-delay="500" color="rgba(0, 0, 0, 0.85)">
            <template
              v-if="
                device.last_frames.frame_frigo.probes_temperature.p4 &&
                !isNaN(device.last_frames.frame_frigo.probes_temperature.p4)
              "
              v-slot:activator="{ on, attrs }">
              <v-chip small v-bind="attrs" v-on="on">
                {{ device.last_frames.frame_frigo.probes_temperature.p4.toFixed(1)
                }}{{
                  device.device_data_from_frame.probes_type.p4 == 'hum'
                    ? '% RH'
                    : device.device_data_from_frame.probes_type.p4 == 'temp'
                    ? 'ºC'
                    : ' AQ'
                }}
                <!-- Hasta aquí -->
              </v-chip>
            </template>
            <span>{{ getProbe4Name }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { getAlarms } from '../../../../viewModels/alarmsViewModel';
import { getUser, getToken } from '../../../../viewModels/userViewModel';
import { checkNotifications, gpsHourTimestamp } from '../../../../utils/device';
import { getReverseGeocode } from '../../../../viewModels/devicesViewModel';
import router from '../../../../plugins/router';
import { getAllPOIS } from '../../../../server/petitions/pois/getAllPOIS';
import { getBeacon } from '../../../../viewModels/adminViewModel';
const VueI18n = require('../../../../plugins/lang/index');
export default {
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    hasThirdProbe: false,
    hasForthProbe: false,
    noGPSClass: '',
    locationClass: '',
    tagClass: 'light-blue darken-2',
    linkedMessage: '',
    tagName: null,
    user: null,
    token: null,
    carplate: '',
    geocode: VueI18n.default.t('general.table.loading'),
    deviceProbeConfig: null,
    gpsTimestampTooltip: VueI18n.default.t('general.table.loading'),
    frigoTimestampTooltip: VueI18n.default.t('general.table.loading'),
    engineTooltip: '',
    beaconTooltip: '',
    beacon: null,
    beaconWithAlias: null,
    // canbusTooltip: '', //CANBUS
    insidePoi: false,
    myPOIS: null,
    deviceHasNotifications: false,
    alias: '',
    speed: 15,
    beaconNearby: false,
    minutesDeducted: 3 * 60000,
    tag: null,
  }),

  mounted: async function () {
    this.device.select = false;
    this.user = this.$store.state.currentUser ?? (await getUser());
    this.token = getToken();
    this.myPOIS = await getAllPOIS(this.token, this.user.userId);
    this.getCarPlate();

    if (this.device.last_frames.frame_frigo.probes_temperature.p3) {
      this.hasThirdProbe = true;
    }
    if (this.device.last_frames.frame_frigo.probes_temperature.p4) {
      this.hasForthProbe = true;
    }

    for (let device of this.user.devices) {
      if (device.device_id == this.device.device_id) {
        this.tag = device.tags[0];

        this.deviceProbeConfig = device.probes_config;
        // if (device.tags.name) {
        //   this.tagClass = device.tags.color;
        //   this.tagName = device.tags.name;
        // }
      }
    }

    if (this.device.last_frames.frame_gps) {
      let actualTime = new Date().getTime();
      let frameTime = new Date(this.device.last_frames.frame_gps.gps_timestamp).getTime();

      if (actualTime - frameTime > 3600000) {
        this.locationClass = 'grey--text';
      } else if (actualTime - frameTime > 1200000) {
        this.locationClass = 'orange--text';
      } else {
        this.locationClass = 'green--text';
      }
    }
    if (!this.device.last_frames.frame_gps) {
      this.noGPSClass = 'grey--text';
    }

    if (this.device.linked_to) {
    }

    if (this.device.last_frames.frame_gps) {
      if (this.device.last_frames.frame_gps.geocode) {
        this.geocode = this.device.last_frames.frame_gps.geocode;
      } else if (this.device.last_frames.frame_gps.latitude && this.device.last_frames.frame_gps.longitude) {
        this.geocode = await getReverseGeocode(
          this.device.last_frames.frame_gps.latitude,
          this.device.last_frames.frame_gps.longitude,
          this.device.device_sn,
        );
        this.device.last_frames.frame_gps.geocode = this.geocode;
      }
    }
    if (this.myPOIS?.length > 0 && this.device.last_frames.frame_gps) {
      for (let poi of this.myPOIS) {
        const distance = this.distanceCoordinates(poi, this.device.last_frames.frame_gps);
        if (distance <= poi.radius) {
          this.insidePoi = true;
        }
      }
    }
    this.hasNotifications();
    this.getBeaconTooltip();
  },

  methods: {
    async hasNotifications() {
      if (this.device.last_frames.frame_status) {
        let alarmConfig;
        const alarms = await getAlarms();
        for (let alarm of alarms) {
          if (alarm.device_sn == this.device.device_sn && alarm.type == 'temp') {
            alarmConfig = alarm.probes_config;
          }
        }
        let notifications = checkNotifications(
          alarmConfig,
          this.device.last_frames.frame_frigo.probes_temperature,
          this.device.last_frames.frame_status,
          this.insidePoi,
        );
        this.deviceHasNotifications = notifications.hasNotifications;
      }
    },
    distanceCoordinates(coords1, coords2) {
      var ML = (((coords1.latitude + coords2.latitude) / 2) * Math.PI) / 180;
      var KPDlat = 111.13209 - 0.56605 * Math.cos(2 * ML) + 0.0012 * Math.cos(4 * ML);
      var KPDlon = 111.41513 * Math.cos(ML) - 0.09455 * Math.cos(3 * ML) + 0.00012 * Math.cos(5 * ML);

      var NS = KPDlat * (coords1.latitude - coords2.latitude);
      var EW = KPDlon * (coords1.longitude - coords2.longitude);

      return 1000 * Math.sqrt(NS * NS + EW * EW);
    },
    capitalizeName(name) {
      return name[0].toUpperCase() + name.toLowerCase().slice(1);
    },
    temperatureView: function () {
      router.push({ name: 'temperatures' });
    },

    showInfo(device) {
      this.$emit('onShowInfo', device);
    },
    getCarPlate() {
      this.carplate = this.device.alias;
    },
    formatDate: function (date) {
      let months = [
        this.$t('general.months.january'),
        this.$t('general.months.february'),
        this.$t('general.months.march'),
        this.$t('general.months.april'),
        this.$t('general.months.may'),
        this.$t('general.months.june'),
        this.$t('general.months.july'),
        this.$t('general.months.august'),
        this.$t('general.months.september'),
        this.$t('general.months.october'),
        this.$t('general.months.november'),
        this.$t('general.months.december'),
      ];
      let splitedDate = date.split('-');
      let formatedDate = `${new Date(date).getDate()} ${this.$t('general.form.of')} ${
        months[new Date(date).getMonth()]
      } ${this.$t('general.form.of')} ${splitedDate[0]}`;
      return formatedDate;
    },
    getTimestampTooltip(timestampDate, actualDate) {
      const oneDay = 24 * 60 * 60 * 1000;
      const date = new Date(timestampDate);
      const formatedDate = this.formatDate(timestampDate);
      const diffDays = Math.round(Math.abs((actualDate - date) / oneDay));

      if (diffDays < 1) {
        const diffHours = Math.round(Math.abs((actualDate - date) / 1000 / 3600));
        if (diffHours > 1) {
          return this.$t('dashboard.details.last_measure_hours', {
            hours: diffHours,
          });
        }
        const diffMinutes = Math.round(Math.abs((actualDate - date) / 1000 / 60));
        if (diffMinutes > 1) {
          return this.$t('dashboard.details.last_measure_minutes', {
            minutes: diffMinutes,
          });
        }
        return this.$t('dashboard.details.last_measure_recently');
      }

      if (diffDays < 7) {
        return this.$t('dashboard.details.last_measure_in_days', {
          days: diffDays,
        });
      }

      return this.$t('dashboard.details.last_measure_date', {
        date: formatedDate,
      });
    },
    getGpsTimestampTooltip() {
      this.gpsTimestampTooltip = this.getTimestampTooltip(this.device.last_frames.frame_gps.gps_timestamp, new Date());
    },
    getFrigoTimestampTooltip() {
      this.frigoTimestampTooltip = this.getTimestampTooltip(this.device.last_frames.frame_frigo.device_timestamp, new Date());
    },
    getEngineTooltip() {
      if (this.isEngineOn) {
        this.engineTooltip = 'ON';
      } else {
        this.engineTooltip = 'OFF';
      }
    },
    async getBeaconTooltip() {
      const isNearby = await this.determineBeaconNearby();
      this.beaconNearby = isNearby;
      if (isNearby) {
        this.alias
          ? (this.beaconTooltip = `${this.$t('admin.menu.connected_tractorhead')}: ${this.alias} `)
          : (this.beaconTooltip = `${this.$t('admin.menu.connected_tractorhead')} `);
      } else {
        this.beaconTooltip = `${this.$t('admin.menu.not_connected_tractorhead')}`;
      }
    },
    async determineBeaconNearby() {
      this.user = await getUser();

      this.beacon = this.device.last_frames.frame_status?.beacons?.beacon1 ?? null;
      if (this.beacon?.macAddress) {
        // this.beaconWithAlias = await getBeacon(this.device.last_frames.frame_status?.beacons?.beacon1?.macAddress);
        this.beaconWithAlias = null;
      }

      const lastFrameTimestamp = this.device.last_frames.frame_status?.device_timestamp;
      const beaconDeviceTimestamp = this.beacon?.device_timestamp;

      const timeCondition =
        new Date(beaconDeviceTimestamp).getTime() >= new Date(lastFrameTimestamp).getTime() - this.minutesDeducted;

      const speedCondition = this.device.last_frames.frame_gps?.speed >= this.speed;

      if (this.user.beacons?.length > 0) {
        for (const beacon of this.user.beacons) {
          if (beacon.mac_address == this.beacon?.macAddress && timeCondition && speedCondition) {
            this.alias = this.beaconWithAlias?.alias;
            return true;
          }
        }
      }
    },
    //CANBUS
    // getCanbusTooltip() {
    //   if (this.isCanbusOn) {
    //     this.canbusTooltip = 'ON';
    //   } else {
    //     this.canbusTooltip = 'OFF';
    //   }
    // },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    model() {
      return this.capitalizeName(this.device.model);
    },
    isSelected() {
      if (this.device.select) {
        return {
          width: '5px',
          height: '48px',
          background: '#2962FF',
          float: 'left',
          position: 'absolute',
          right: '-14px',
          top: '28px',
          'border-radius': '40%',
          'box-shadow': 'rgb(38, 57, 77) 0px 20px 30px -10px',
        };
      }
    },
    isSelectedCard() {
      if (this.device.select) {
        return {
          'box-shadow': 'rgb(38, 57, 77) 0px 20px 30px -10px',
        };
      }
    },
    getGeocode() {
      return this.geocode;
    },

    getGpsHourTimestamp() {
      if (!this.device.last_frames.frame_gps) {
        return 'No data';
      }
      return gpsHourTimestamp(this.device.last_frames.frame_gps.gps_timestamp);
    },
    isFrigoActive() {
      const actualDate = new Date();
      const date = new Date(this.device.last_frames.frame_frigo.device_timestamp);
      const diffMinutes = Math.round(Math.abs((actualDate - date) / 1000 / 60));

      if (diffMinutes < 10) {
        return 'frigoActive blue--text';
      }
      if (diffMinutes < 45) {
        return 'blue--text';
      }
      return 'grey--text';
    },

    informEngineOn() {
      if (this.isEngineOn) {
        return 'motorActive blue--text';
      }
      return 'grey--text';
    },
    informBeaconNearby() {
      return this.beaconNearby ? 'blue--text' : 'grey--text';
    },
    //CANBUS
    // informCanbusOn() {
    //   if (this.isCanbusOn) {
    //     return 'blue--text';
    //   }
    //   return 'grey--text';
    // },
    isEngineOn() {
      let date = new Date(this.device.last_frames.frame_status.device_timestamp);
      let tenMoreMinutes = new Date(date.setMinutes(date.getMinutes() + 10));
      if (tenMoreMinutes >= new Date()) {
        return this.device.last_frames.frame_status.engine_on;
      } else {
        return false;
      }
    },
    isCanbusOn() {
      return this.device.last_frames.frame_CAN;
    },

    getProbe1Name() {
      return `${this.$t('alarms.probe')} 1`;
    },
    getProbe2Name() {
      return `${this.$t('alarms.probe')} 2`;
    },
    getProbe3Name() {
      return `${this.$t('alarms.probe')} 3`;
    },
    getProbe4Name() {
      return `${this.$t('alarms.probe')} 4`;
    },
  },
  updated() {
    this.getBeaconTooltip();
    this.hasNotifications();
  },
};
</script>
