const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class CreateCompanyException extends PetitionError {
  static errorUserEmailAlreadyInUse = 'EMAIL_ALREADY_IN_USER';

  static cifAlreadyInUser = 'CIF_ALREADY_IN_USER';

  static companyAlreadyExists = 'COMPANY_ALREADY_EXISTS';

  constructor(code) {
    super('Create Company', code);
  }
}

async function createCompany(company_name, company_cif, company_phone, company_email, company_notes, token) {
  // TODO add security
  const payload = {
    company_name,
    company_cif,
    company_phone,
    company_email,
    company_notes,
  };

  const response = await post('companies/create_company', payload, token);

  if (response.result !== CreateCompanyException.success) {
    throw new CreateCompanyException(response.result);
  } else {
    return response.company; // TO DO transform
  }
}

module.exports = {
  createCompany,
  CreateCompanyException,
};
