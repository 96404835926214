<template>
  <v-container style="padding: 0">
    <div id="map"></div>
    <v-card id="idList" max-width="500" class="mx-auto" v-show="seeMarkerList">
      <v-toolbar style="height: 3vh; display: flex; justify-content: center; align-items: center" color="indigo" dark>
        <v-toolbar-title style="font-size: larger"> {{ $t('dashboard.devices_list') }}</v-toolbar-title>
      </v-toolbar>
      <v-list id="idList2" style="overflow-y: auto" class="pb-2" :max-height="getHeight">
        <v-list-item
          style="height: 25px; display: flex; justify-content: center; align-items: center; min-height: 0px !important"
          v-for="item in aliasItem"
          :key="item.alias">
          <v-list-item-icon style="margin: 0 !important ; align-self: center" :class="{ 'svg-icon-dark': theme === 'dark' }">
            <!-- <v-list-item-icon
            style="margin: 0 !important; line-height: 0%; display: flex"
            :class="{ 'svg-icon-dark': theme === 'dark' }"> -->
            <svg
              id="course-svg"
              class="svg-icon-module__svg___Gd7iB svg-icon-module__default-color___zK2Yx wf-object-position-status-icon-module__direction-icon___iTkWs wf-object-position-status-icon-module__use-currentColor___cPN4o"
              enable-background="new 0 0 48 48"
              height="16"
              version="1.1"
              viewBox="0 0 48 48"
              width="16"
              x="0px"
              y="0px"
              :style="{ transform: `rotate(${item.course}deg)` }">
              <path
                d="M37.2 37.6L25.1 9c-.2-.5-.5-1-1.1-1-.6 0-.9.5-1.1 1L10.8 37.6c-.4.9 0 1.9.9 2.3.5.2 1 .2 1.4
                    0L24 34.7l10.9 5.1c.4.2.9.2 1.4 0 .9-.3 1.3-1.3.9-2.2z"></path>
            </svg>
          </v-list-item-icon>
          <v-list-item-content style="margin: 0 !important">
            <v-list-item-title style="font-size: small" v-text="item.alias"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon style="margin: 0 !important; align-self: center">
            <v-icon size="medium" :color="item.color"> mdi-circle </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-card>

    <div
      :style="
        !isMobile
          ? 'position: absolute; top: 0; right: 0; margin: 1.5rem 1.5rem 0 0'
          : 'position: absolute; top: 50px; right: 0; margin: 1.5rem 1.5rem 0 0'
      ">
      <DropDownPois />
    </div>

    <div
      id="zoomControl"
      :style="
        !isMobile
          ? 'position: absolute; top: 24em; right: 0; margin: 0rem 1.5rem 0 0'
          : 'position: absolute; top: 26em; right: 0; margin: 0rem 1.5rem 0 0'
      ">
      <ZoomControl />
    </div>

    <div
      v-if="poiData !== null"
      id="poiDataFormOnMap"
      :style="
        !isMobile
          ? 'position: absolute; top: 0; right: 0; margin: 0rem 0 0 0; zIndex: 1'
          : 'position: absolute; top: 1em; right: 0; margin: 0rem 0 0 0; zIndex: 1'
      ">
      <POIDataFormOnMap :value="poiData" />
    </div>
    <AlertMap ref="alertmap"></AlertMap>
    <v-snackbar v-model="snackbar" color="red" :timeout="5000">
      {{ $t('dashboard.connection_failed') }}
    </v-snackbar>
  </v-container>
</template>

<script>
import DropDownPois from '../dropDownPois.vue';
import ZoomControl from '../zoomControl.vue';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import MarkerWithLabel from '@googlemaps/markerwithlabel';
import { getAllPOIS } from '../../server/petitions/pois/getAllPOIS';
import { eventBus } from '../../main';
import { getToken, getUser } from '../../viewModels/userViewModel';
import { formatDate } from '../../utils/utils';
import { getAllPOICategories } from '../../server/petitions/pois/getAllPOICategories';
import { getAddress, getCoordinates } from '../../server/petitions/devices/getGeocode';
const ls = require('localstorage-slim');
import nightstyle from '../../utils/handlerMap';
import AlertMap from '../../components/alertMap.vue';
import { arrayWithColorsDark, arrayWithColorsLight } from '../../utils/routesColors';
import POIDataFormOnMap from '../../components/forms/poisForm/poiDataFormOnMap.vue';
import { faBlackTie } from '@fortawesome/free-brands-svg-icons';

const VueI18n = require('../../plugins/lang/index');

var timeoutID;
var mouseOverInfoWindow = false;

export default {
  props: {
    lat: { type: Number },
    lng: { type: Number },
  },
  components: { DropDownPois, ZoomControl, AlertMap, POIDataFormOnMap },
  data: () => ({
    poiData: null,
    arrayWithColorsDark,
    arrayWithColorsLight,
    allColorsToPolyLine: [],
    allPolylines: [],
    arrayToRoute: [],
    overlays: [],
    color: '',
    thereIsRoute: false,
    theme: ls.get('theme') ?? 'light',
    poiCategoriesFromDb: null,
    token: '',
    text: 1,
    map: null,
    markerList: [],
    markerCluster: null,
    routesActive: false,
    devicePath: null,
    exitRouteBtn: null,
    exitFastestRouteBtn: null,
    UIexitBtn: null,
    position: null,
    display: null,
    service: null,
    user: null,
    selectDevice: null,
    locationCollection: null,
    marker: null,
    isBeacon: false,
    minutesDeducted: 3 * 60000,
    markerDevice: null,
    markerList2: [],
    colorMarkerClusterer: '#2962ff',
    isMarkerClusterer: null,
    isClicked: false,
    aliasItem: [],
    hoverBoxOverlay: null,
    hoverBoxDiv: null,
    seeMarkerList: false,
    uniqueIdCounter: 0,
    latCollection: [],
    lngCollection: [],
    speed: 15,
    snackbar: false,
  }),
  beforeMount() {
    eventBus.$on('cleanMarkerList', () => {
      this.seeMarkerList = false;
    });
    this.markerList2 = [];
  },
  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
      this.hoverBoxDiv = document.getElementById('idList');
      this.resetMap();

      loader.loadCallback(async (e) => {
        if (e) {
          console.error('ERROR LOADER', e);
        } else if (this.theme === 'dark') {
          //--------MAPA PC Y MOBILE CUANDO CAMBIO A MODO NOCHE------------
          this.map = new google.maps.Map(document.getElementById('map'), {
            //-------------MAPA PC Y MOBILE CUANDO CAMBIO A MODO OSCURO------------------
            center: {
              lat: this.lat ? this.lat : this.isMobile ? 50.044469425671146 : 46.52977,
              lng: this.lng ? this.lng : this.isMobile ? 7.979211439362778 : 7.976387,
            },
            scaleControl: true,
            streetViewControl: false, //con true: Aparece muñeco googleMaps
            // streetViewControlOptions: {
            //   position: google.maps.ControlPosition.RIGHT_CENTER,
            // },
            rotateControlOptions: {
              position: google.maps.ControlPosition.RIGHT_CENTER,
            },
            zoomControl: false, // con true : Aparece botón de zoom + - googleMaps
            zoomControlOptions: {
              position: google.maps.ControlPosition.RIGHT_CENTER,
            },
            zoom: this.lat ? 19 : this.isMobile ? 3.5 : 5,
            fullscreenControl: false,
            mapTypeControl: false,
            gestureHandling: 'greedy',
            styles: nightstyle,
            rotateControl: false,
          });

          this.map.addListener('dblclick', () => {
            this.activateZoomSatellite();
          });

          this.map.addListener('zoom_changed', () => {
            this.activateZoomSatellite();
          });

          if (this.thereIsRoute === false) {
            this.activateRightClick();
            this.activateTouchLongClick();
            this.addPoiToMap(this.user);
            this.$emit('onInit');
            if (!this.lat && !this.lng) {
              setTimeout(() => {
                this.centerMapFunction(this.latCollection, this.lngCollection);
              }, 1000);
            }
          }
        } else {
          this.map = new google.maps.Map(document.getElementById('map'), {
            //--------MAPA PC Y MOBILE CUANDO CAMBIO A MODO DÍA------------
            center: {
              lat: this.lat ? this.lat : this.isMobile ? 50.044469425671146 : 46.52977,
              lng: this.lng ? this.lng : this.isMobile ? 7.979211439362778 : 7.976387,
            },
            scaleControl: true,
            streetViewControl: false,
            // streetViewControlOptions: {
            //   position: google.maps.ControlPosition.RIGHT_CENTER,
            // },
            rotateControlOptions: {
              position: google.maps.ControlPosition.RIGHT_CENTER,
            },
            zoomControl: false, // con true : Aparece botón de zoom + - googleMaps
            zoomControlOptions: {
              position: google.maps.ControlPosition.RIGHT_CENTER,
            },
            zoom: this.lat ? 19 : this.isMobile ? 3.5 : 5,
            fullscreenControl: false,
            mapTypeControl: false,
            gestureHandling: 'greedy',
            rotateControl: false,
          });

          this.map.addListener('dblclick', () => {
            this.activateZoomSatellite();
          });

          this.map.addListener('zoom_changed', () => {
            this.activateZoomSatellite();
          });

          if (this.thereIsRoute === false) {
            this.activateRightClick();
            this.activateTouchLongClick();
            this.addPoiToMap(this.user);
            this.$emit('onInit');
            if (!this.lat && !this.lng) {
              setTimeout(() => {
                this.centerMapFunction(this.latCollection, this.lngCollection);
              }, 1000);
            }
          }
        }
      });
    });
    this.token = getToken();
    this.user = this.$store.state.currentUser ? this.$store.state.currentUser : await getUser();
    this.poiCategoriesFromDb = await getAllPOICategories(this.token, this.user.userId);

    const loader = new Loader({
      apiKey: 'AIzaSyA0ioGKPYfQrBkjUO4oHyjVi7UKtg96kMc',
      version: 'weekly',
      language: 'es',
      libraries: ['places'],
    });
    loader.loadCallback(async (e) => {
      if (e) {
        console.error('ERROR LOADER', e);
      } else if (this.theme === 'dark') {
        this.map = new google.maps.Map(document.getElementById('map'), {
          //------------------MAPA MODO OSCURO PC Y MOBILE-----------------------
          center: {
            lat: this.lat ? this.lat : this.isMobile ? 50.044469425671146 : 46.52977,
            lng: this.lng ? this.lng : this.isMobile ? 7.979211439362778 : 7.976387,
          },
          scaleControl: true,
          streetViewControl: false, //con true: Aparece muñeco googleMaps
          // streetViewControlOptions: {
          //   position: google.maps.ControlPosition.RIGHT_CENTER,
          // },
          rotateControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
          },
          zoomControl: false, // con true : Aparece botón de zoom + - googleMaps
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
          },
          zoom: this.lat ? 19 : this.isMobile ? 3.5 : 5,
          fullscreenControl: false,
          mapTypeControl: false,
          gestureHandling: 'greedy',
          styles: nightstyle,
          rotateControl: false,
        });

        this.map.addListener('dblclick', () => {
          this.activateZoomSatellite();
        });

        this.map.addListener('zoom_changed', () => {
          this.activateZoomSatellite();
        });

        if (this.thereIsRoute === false) {
          this.activateRightClick();
          this.activateTouchLongClick();
          this.addPoiToMap(this.user);
          if (!this.lat && !this.lng) {
            setTimeout(() => {
              this.centerMapFunction(this.latCollection, this.lngCollection);
            }, 1000);
          }
        }
      } else {
        this.map = new google.maps.Map(document.getElementById('map'), {
          // ----------------MAPA MODO DÍA PC Y MOBILE---------------------
          center: {
            lat: this.lat ? this.lat : this.isMobile ? 50.044469425671146 : 46.52977,
            lng: this.lng ? this.lng : this.isMobile ? 7.979211439362778 : 7.976387,
          },
          scaleControl: true,
          streetViewControl: false,
          // streetViewControlOptions: {
          //   position: google.maps.ControlPosition.RIGHT_CENTER,
          // },
          rotateControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
          },
          zoomControl: false, // con true : Aparece botón de zoom + - googleMaps
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
          },
          zoom: this.lat ? 19 : this.isMobile ? 3.5 : 5,
          fullscreenControl: false,
          mapTypeControl: false,
          gestureHandling: 'greedy',
          rotateControl: false,
        });

        this.map.addListener('dblclick', () => {
          this.activateZoomSatellite();
        });

        this.map.addListener('zoom_changed', () => {
          this.activateZoomSatellite();
        });

        if (this.thereIsRoute === false) {
          this.activateRightClick();
          this.activateTouchLongClick();
          this.addPoiToMap(this.user);
          if (!this.lat && !this.lng) {
            setTimeout(() => {
              if (this.latCollection.length > 0 && this.lngCollection.length > 0) {
                this.centerMapFunction(this.latCollection, this.lngCollection);
              } else {
                this.snackbar = true;
              }
            }, 1000);
          }
        }
      }
    });

    eventBus.$on('reloadDevices', () => {
      this.exitFastestRoutes();
      this.addPoiToMap(this.user);
      this.exitRoutes();
    });

    eventBus.$on('zoom-in', () => {
      this.seeMarkerList = false;
      this.map.setZoom(this.map.getZoom() + 1);
      this.activateZoomSatellite();
    });
    eventBus.$on('zoom-out', () => {
      this.seeMarkerList = false;

      this.map.setZoom(this.map.getZoom() - 1);

      this.activateZoomSatellite();
    });

    eventBus.$on('zoomMap', () => {
      if (this.map) {
        this.map.setCenter({
          lat: 46.52977,
          lng: 7.976387,
        });
        this.map.setZoom(5);
      }
    });

    //CERRAR EL POIDATAFORM
    eventBus.$on('closePoiDataFormOnMap', () => {
      this.poiData = null;
    });

    //GUARDAMOS POI Y CERRAMOS POIDATAFORM
    eventBus.$on('savePoiDataFormOnMap', () => {
      this.poiData = null;
      setTimeout(() => {
        this.addPoiToMap(this.user);
      }, 100);
    });

    //PASAMOS PROPS A POIDATAFORM
    eventBus.$on('updatePOI', (editedPOI, isCreated) => {
      this.poiData = editedPOI;
      this.poiData.isCreated = isCreated;

      // HACEMOS ZOOM CUANDO VAMOS A CREAR POI
      this.map.setZoom(19);
    });

    eventBus.$on('reciveDevice', (selectedDevice) => {
      this.selectDevice = selectedDevice.device_sn;
    });

    eventBus.$on('longHold', () => {
      eventBus.$emit('longHoldAfterLongHold');
    });

    eventBus.$on('categories-map', (category) => {
      this.poisBtn(category);
    });
    eventBus.$on('visible-change', (isVisible) => {
      if (isVisible) {
        this.addPoiToMap(this.user);
      } else {
        let filterMarker = this.markerList.filter((objeto) => objeto.hasOwnProperty('poi_category'));

        for (let i = 0; i < filterMarker.length; i++) {
          filterMarker[i].setMap(null);
        }

        filterMarker = [];
      }
    });

    eventBus.$on('closecontextmenuMobile', () => {
      this.thereIsRoute = true;
    });

    eventBus.$on('reloadMapCorrectly', () => {
      setTimeout(() => {
        this.centerMapFunction(this.latCollection, this.lngCollection);
      }, 1000);
    });

    this.$forceUpdate();
    this.$emit('onInit');
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    rightBounds() {
      return (78.8 / 100) * screen.width;
    },
    botBounds() {
      return (70.09 / 100) * screen.height;
    },

    getHeight() {
      return this.aliasItem.length <= 2 ? '68px' : this.aliasItem.length <= 5 ? '135px' : '200px';
    },
  },

  methods: {
    formatDate(date) {
      let months = [
        this.$t('general.months.january'),
        this.$t('general.months.february'),
        this.$t('general.months.march'),
        this.$t('general.months.april'),
        this.$t('general.months.may'),
        this.$t('general.months.june'),
        this.$t('general.months.july'),
        this.$t('general.months.august'),
        this.$t('general.months.september'),
        this.$t('general.months.october'),
        this.$t('general.months.november'),
        this.$t('general.months.december'),
      ];
      let splitedDate = date.split('-');
      let formatedDate = `${new Date(date).getDate()} ${this.$t('general.form.of')} ${
        months[new Date(date).getMonth()]
      } ${this.$t('general.form.of')} ${splitedDate[0]}`;
      return formatedDate;
    },
    activateRightClick() {
      this.map.addListener('rightclick', (mapsMouseEvent) => {
        this.position = mapsMouseEvent.latLng.toJSON();
        let a = {
          clientX: mapsMouseEvent.domEvent.clientX,
          clientY: mapsMouseEvent.domEvent.clientY,
        };

        let bothPositions = {
          a: a,
          position: this.position,
        };
        this.$emit('contextmenu', bothPositions);
      });
    },
    activateTouchLongClick() {
      this.thereIsRoute = false;
      this.map.addListener('click', (mapsMouseEvent) => {
        if (this.thereIsRoute === false) {
          this.position = mapsMouseEvent.latLng.toJSON();
          let a = {
            clientX: mapsMouseEvent.domEvent.clientX,
            clientY: mapsMouseEvent.domEvent.clientY,
          };

          let bothPositions = {
            a: a,
            position: this.position,
          };
          this.$emit('contextmenuMobile', bothPositions);
        }
      });
    },

    activateZoomSatellite() {
      if (this.map) {
        if (this.map.getZoom() >= 18) {
          this.map.setMapTypeId('satellite');
        } else {
          this.map.setMapTypeId('roadmap');
        }
      }
    },

    generateUniqueId() {
      return `customMarker_${++this.uniqueIdCounter}`;
    },

    async addDeviceToMap(device, isLast, user) {
      if (device.last_frames && device.last_frames.frame_gps) {
        const lat = device.last_frames.frame_gps.latitude;
        const lng = device.last_frames.frame_gps.longitude;

        this.latCollection.push(lat);
        this.lngCollection.push(lng);

        let deviceStatus = this.checkDeviceStatus(device);
        for (let deviceUser of user.devices) {
          if (deviceUser.device_sn == device.device_sn) {
            device.name = deviceUser.name;
          }
        }

        //BEACONS-------
        const isThereBeacon = device.last_frames.frame_status?.beacons?.beacon1 ?? null;

        const lastFrameTimestamp = device.last_frames.frame_status.device_timestamp;
        const beaconDeviceTimestamp = isThereBeacon?.device_timestamp;

        const timeCondition =
          new Date(beaconDeviceTimestamp).getTime() >= new Date(lastFrameTimestamp).getTime() - this.minutesDeducted;

        const speedCondition = device.last_frames.frame_gps?.speed >= this.speed;

        //Esto debería ser siempre cuando se crea el dispositivo un array vacio.
        if (user.beacons?.length > 0) {
          for (const beacon of user.beacons) {
            if (beacon.mac_address == isThereBeacon?.macAddress && timeCondition && speedCondition) {
              this.isBeacon = true;
              break;
            } else {
              this.isBeacon = false;
            }
          }
        }

        //LABEL
        const rotation = device.last_frames.frame_gps.course;
        const deviceName = device.name
          ? device.name
          : device.device_data_from_frame.carplate
          ? device.device_data_from_frame.carplate.toString()
          : device.device_sn.toString();

        const labelDiv = document.createElement('div');
        labelDiv.style.display = 'flex';
        labelDiv.style.alignItems = 'center';
        labelDiv.style.maxWidth = '13em';
        labelDiv.style.width = '100%';
        labelDiv.style.height = '1em';
        labelDiv.style.padding = '0.5em';
        labelDiv.style.justifyContent = 'center';

        const pictureLabel = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        pictureLabel.setAttribute(
          'class',
          'svg-icon-module__svg___Gd7iB svg-icon-module__default-color___zK2Yx wf-object-position-status-icon-module__direction-icon___iTkWs wf-object-position-status-icon-module__use-currentColor___cPN4o',
        );
        pictureLabel.setAttribute('enable-background', 'new 0 0 48 48');
        pictureLabel.setAttribute('height', '13');
        pictureLabel.setAttribute('version', '1.1');
        pictureLabel.setAttribute('viewBox', '0 0 48 48');
        pictureLabel.setAttribute('width', '16');
        pictureLabel.setAttribute('x', '0px');
        pictureLabel.setAttribute('y', '0px');
        pictureLabel.setAttribute('style', `transform: rotate(${rotation}deg)`);

        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        path.setAttribute(
          'd',
          'M37.2 37.6L25.1 9c-.2-.5-.5-1-1.1-1-.6 0-.9.5-1.1 1L10.8 37.6c-.4.9 0 1.9.9 2.3.5.2 1 .2 1.4 0L24 34.7l10.9 5.1c.4.2.9.2 1.4 0 .9-.3 1.3-1.3.9-2.2z',
        );
        pictureLabel.appendChild(path);

        const labelText = document.createElement('div');
        labelText.innerHTML = `<span style="color: #000000 !important">${deviceName}</span>`;
        labelText.style.fontSize = '0.73rem';

        labelDiv.appendChild(pictureLabel);
        labelDiv.appendChild(labelText);

        this.markerDevice = new MarkerWithLabel({
          icon: {
            url:
              device.model !== 'tracker' && this.isBeacon // TIENE BALIZA
                ? `https://pro.termografoapache.com/assets/markers/beacon_connect.png`
                : device.model !== 'tracker' && !this.isBeacon //NO TIENE BALIZA
                ? `https://pro.termografoapache.com/assets/markers/${deviceStatus}-truck.png`
                : device.submodel == 'car'
                ? `https://pro.termografoapache.com/assets/markers/${deviceStatus}-car.png`
                : `https://pro.termografoapache.com/assets/markers/${deviceStatus}-head.png`,

            scaledSize: new google.maps.Size(24, 32),
            labelOrigin: new google.maps.Point(10, 45),
            labelContent: device.name
              ? device.name
              : device.device_data_from_frame.carplate
              ? device.device_data_from_frame.carplate.toString()
              : device.device_sn.toString(),
          },
          position: { lat: lat, lng: lng },
          zIndex: 3,
          map: this.map,
          labelContent: labelDiv,
          labelClass: 'carplate', // the CSS class for the label
          labelStyle: { opacity: 1.0, color: deviceStatus, course: device.last_frames.frame_gps.course },
        });

        this.markerDevice.sn = device.device_sn;

        this.markerList.push(this.markerDevice);
        this.markerList2.push(this.markerDevice);
        this.markerDevice.addListener('click', () => {
          this.$emit('onMarkerTap', device, true);
        });

        this.markerDevice.addListener('click', (markerEvent) => {
          this.markerDevice.setAnimation(google.maps.Animation.BOUNCE);
          setTimeout(function () {
            this.markerDevice.setAnimation(null);
          }, 2000);
          if (markerEvent.latLng) {
            this.position = markerEvent.latLng.toJSON();
          }

          if (markerEvent.domEvent) {
            let a = {
              clientX: markerEvent.domEvent.clientX - 150,
              clientY: markerEvent.domEvent.clientY + 30,
            };

            let bothPositions = {
              a: a,
              position: this.position,
            };

            if (a.clientX > this.rightBounds) {
              bothPositions = {
                a: {
                  clientX: this.rightBounds,
                  clientY: markerEvent.domEvent.clientY + 30,
                },
                position: this.position,
              };
            }

            if (a.clientY > this.botBounds) {
              bothPositions = {
                a: {
                  clientX: markerEvent.domEvent.clientX + 27.5,
                  clientY: this.botBounds - 35,
                },
                position: this.position,
              };
            }

            if (a.clientX > this.rightBounds && a.clientY > this.botBounds) {
              bothPositions = {
                a: {
                  clientX: markerEvent.domEvent.clientX - 320,
                  clientY: markerEvent.domEvent.clientY - 320,
                },
                position: this.position,
              };
            }
            this.$emit('mouseOverDevice', device, bothPositions);
          }
        });

        this.markerDevice.addListener('mouseout', () => {
          this.$emit('mouseout', device, true);
        });
      }
      if (this.isMarkerClusterer) {
        this.isMarkerClusterer.clearMarkers();
        this.isMarkerClusterer.setMap(null);
      }
      this.isMarkerClusterer = new MarkerClusterer({
        markers: this.markerList2,
        map: this.map,
        minimumClusterSize: 2,
        renderer: {
          render: ({ count, position }, stats) => {
            const color = this.colorMarkerClusterer;
            const svg = window.btoa(`
            <svg  fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
            <circle  cx="120" cy="120" opacity=".8" r="70" />
             </svg>`);

            let customerMarker = new google.maps.Marker({
              position,
              icon: {
                url: `data:image/svg+xml;base64,${svg}`,
                scaledSize: new google.maps.Size(50, 50),
              },
              label: {
                text: String(count),
                color: 'rgba(255,255,255,0.9)',
                fontSize: '12px',
              },
              zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
              minZoom: 5,
              maxZoom: 10,
            });

            customerMarker.myOwnId = this.generateUniqueId();

            google.maps.event.addListener(customerMarker, 'mouseover', () => {
              // MUESTRA EL LISTADO DE CADA MARKER CORRECTAMENTE
              this.isMarkerClusterer.clusters.forEach((cluster) => {
                if (cluster.marker?.myOwnId === customerMarker.myOwnId && !this.isClicked) {
                  this.showHoverBox(position, cluster.markers);
                }
              });
            });

            let cardWithListMap = document.getElementById('map');
            cardWithListMap.addEventListener('click', () => {
              if (!this.isClicked) this.hideHoverBox();
            });

            google.maps.event.addListener(customerMarker, 'click', () => {
              this.isClicked = true;
              setTimeout(() => {
                this.hideHoverBox();
                this.isClicked = false;
              }, 200);
            });

            return customerMarker;
          },
        },
      });
    },

    showHoverBox(position, markers) {
      this.seeMarkerList = true;

      this.hoverBoxOverlay = null;
      this.hoverBoxOverlay = new google.maps.OverlayView();
      let that = this.hoverBoxOverlay;

      this.hoverBoxOverlay.draw = () => {
        let div = this.hoverBoxDiv;
        this.hoverBoxDiv = document.getElementById('idList') ?? this.hoverBoxDiv;
        div = this.hoverBoxDiv;
        if (!div) div = this.hoverBoxDiv;
        div.style.position = 'absolute';

        this.aliasItem = [];
        markers.forEach((marker) => {
          if (marker.sn)
            this.aliasItem.push({
              alias: marker.icon.labelContent,
              color: marker.labelStyle.color,
              course: marker.labelStyle.course,
            });
        });

        const panes = that.getPanes();
        panes.overlayImage.appendChild(div);

        const point = that.getProjection().fromLatLngToDivPixel(position);
        if (point) {
          div.style.left = `${point.x + 15}px`;
          div.style.top = `${point.y - 260}px`;
        }
      };

      this.hoverBoxOverlay.setMap(this.map);
    },

    hideHoverBox() {
      this.seeMarkerList = false;

      if (this.hoverBoxDiv) {
        this.hoverBoxDiv.style.display = 'none';
      }
    },

    getZoomLevel(zoomLevels, radius) {
      const foundLevel = zoomLevels.find((level) => radius < level.maxRadius);
      return foundLevel ? foundLevel.zoom : null;
    },

    addPoiToMap: async function (user) {
      const token = getToken();
      let pois = await getAllPOIS(token, user.userId);

      if (pois) {
        for (let poi of pois) {
          let lat = poi.latitude;
          let lng = poi.longitude;

          this.marker = new MarkerWithLabel({
            icon: {
              url:
                poi.category?.icon !== undefined && poi.category?.icon !== ''
                  ? poi.category.icon
                  : 'https://pro.termografoapache.com/assets/markers/pi.png',
              scaledSize: new google.maps.Size(32, 32),
              labelOrigin: new google.maps.Point(10, 45),
            },
            position: { lat, lng },
            map: this.map,
            labelClass: 'labelPoi',
            poi_category: poi.category?.name,
            labelContent: poi.name,
            labelStyle: { _id: poi._id },
          });
          let circle = new google.maps.Circle({
            editable: true,
            draggable: true,
            radius: Number(poi.radius),
            fillColor: this.theme === 'dark' ? 'white' : '##fffcfc',
            strokeColor: this.theme === 'dark' ? 'white' : 'black',
            fillOpacity: 0.1,
            strokeOpacity: 0.2,
            strokeWeight: 3,
            center: { lat, lng },
          });

          const center = circle.getCenter();

          let radius = circle.getRadius();

          const metersToDegrees = 0.0000089;

          const northEast = new google.maps.LatLng(
            center.lat() + radius * metersToDegrees,
            center.lng() + (radius * metersToDegrees) / Math.cos((center.lat() * Math.PI) / 180),
          );
          const southWest = new google.maps.LatLng(
            center.lat() - radius * metersToDegrees,
            center.lng() - (radius * metersToDegrees) / Math.cos((center.lat() * Math.PI) / 180),
          );

          const bounds = new google.maps.LatLngBounds(southWest, northEast);
          circle.bindTo('center', this.marker, 'position');
          circle.bindTo('map', this.marker, 'map');
          if (this.isMobile) {
            //POIS MOVIL
            circle.addListener('dblclick', (circleEvent) => {
              hoverInfoWindow.close();

              this.map.setCenter({
                lat: lat,
                lng: lng,
              });

              this.poiData = {
                name: poi.name,
                address: '', //Mirar de hacer la llamada para que salga el nombre de la calle.
                latitude: circle.getCenter()?.toJSON().lat,
                longitude: circle.getCenter()?.toJSON().lng,
                radius: circle.getRadius().toFixed(0),
                poi_category: poi.category ?? null,
                _id: poi._id,
              };
            });
          } else {
            const zoomLevels = [
              { maxRadius: 25, zoom: 20.8 },
              { maxRadius: 50, zoom: 20.1 },
              { maxRadius: 100, zoom: 18.8 },
              { maxRadius: 185, zoom: 17.8 },
              { maxRadius: 265, zoom: 17.4 },
              { maxRadius: 375, zoom: 16.8 },
              { maxRadius: 500, zoom: 16.5 },
              { maxRadius: 1000, zoom: 15.5 },
            ];

            //PC
            circle.addListener('click', (circleEvent) => {
              hoverInfoWindow.close();

              this.map.setCenter({
                lat: lat,
                lng: lng,
              });
              // CAMBIA LA LOGICA DE ZOOM DE POIS - PC
              const zoomLevel = this.getZoomLevel(zoomLevels, circle.radius);
              if (zoomLevel !== null) {
                this.map.setZoom(zoomLevel);
              }

              this.poiData = {
                name: poi.name,
                address: '', //Mirar de hacer la llamada para que salga el nombre de la calle.
                latitude: circle.getCenter()?.toJSON().lat,
                longitude: circle.getCenter()?.toJSON().lng,
                radius: circle.getRadius().toFixed(0),
                poi_category: poi.category ?? null,
                _id: poi._id,
              };
            });
          }

          ['bounds_changed', 'dragend'].forEach((eventName) => {
            circle.addListener(eventName, () => {
              hoverInfoWindow.close();

              let radiusEditabled = circle.getRadius();
              let radiusDifference = Math.abs(radiusEditabled - radius);

              if (radiusDifference >= 5) {
                radiusEditabled > radius ? this.map.setZoom(this.map.getZoom() - 1) : this.map.setZoom(this.map.getZoom() + 1);

                radius = radiusEditabled;
              }

              this.poiData = {
                name: poi.name,
                address: '',
                latitude: circle.getCenter()?.toJSON().lat,
                longitude: circle.getCenter()?.toJSON().lng,
                radius: circle.getRadius().toFixed(0),
                poi_category: poi.category ?? null,
                _id: poi._id,
              };
            });
          });

          //----------------INFOWINDOWW---------------------------
          let contentStyle = this.theme === 'dark' ? 'background-color: grey;' : 'background-color: black;';

          let hoverInfoWindow = new google.maps.InfoWindow({
            content: `<div style="font-weight: bold; padding:0.5em ; color: white; ${contentStyle} ">${VueI18n.default.t(
              'general.form.click_on_poi',
            )}</div>`,
          });

          circle.addListener('mouseover', (e) => {
            hoverInfoWindow.setPosition(circle.getCenter());
            hoverInfoWindow.open(this.map);
          });

          circle.addListener('mouseout', function () {
            hoverInfoWindow.close();
          });
          //----------------INFOWINDOWW---------------------------

          if (!this.isMobile) {
            circle.addListener('dblclick', (mapsMouseEvent) => {
              this.map.setCenter(circle.getCenter());
              this.map.setZoom(this.map.getZoom() + 12);
            });
          }

          this.marker.addListener('dblclick', (mapsMouseEvent) => {
            const zoomLevelsMobile = [
              { maxRadius: 30, zoom: 19 },
              { maxRadius: 50, zoom: 18.2 },
              { maxRadius: 100, zoom: 17.1 },
              { maxRadius: 200, zoom: 16 },
              { maxRadius: 300, zoom: 15.7 },
              { maxRadius: 500, zoom: 15 },
              { maxRadius: 1000, zoom: 14 },
            ];

            const zoomLevelsPc = [
              { maxRadius: 50, zoom: 20 },
              { maxRadius: 100, zoom: 18 },
              { maxRadius: 200, zoom: 17 },
              { maxRadius: 500, zoom: 16 },
              { maxRadius: 1000, zoom: 15 },
            ];

            this.map.setCenter(circle.getCenter());
            if (this.isMobile) {
              //CAMBIAR LA LOGICA DE ZOOM MOVIL
              const zoomLevelMobile = this.getZoomLevel(zoomLevelsMobile, circle.radius);
              if (zoomLevelMobile !== null) {
                this.map.setZoom(zoomLevelMobile);
              }
            } else {
              //CAMBIAR LA LOGICA DE ZOOM PC
              const zoomLevelPc = this.getZoomLevel(zoomLevelsPc, circle.radius);
              if (zoomLevelPc !== null) {
                this.map.setZoom(zoomLevelPc);
              }
            }
          });

          this.markerList.forEach((singleMarker) => {
            if (singleMarker.labelStyle?._id === this.marker.labelStyle?._id) {
              singleMarker.setMap(null);
            }
          });

          this.markerList.push(this.marker);
        }
      }
    },

    updateDeviceToMap(device) {
      if (!this.routesActive) {
        const lat = device.last_frames.frame_gps.latitude;
        const lng = device.last_frames.frame_gps.longitude;
        let deviceStatus = this.checkDeviceStatus(device);

        for (let deviceUser of this.user.devices) {
          if (deviceUser.device_sn == device.device_sn) {
            device.name = deviceUser.name;
          }
        }
        //---BEACONS----
        const isThereBeacon = device.last_frames.frame_status?.beacons?.beacon1 ?? null;

        const lastFrameTimestamp = device.last_frames.frame_status.device_timestamp;
        const beaconDeviceTimestamp = isThereBeacon?.device_timestamp;

        const timeCondition =
          new Date(beaconDeviceTimestamp).getTime() >= new Date(lastFrameTimestamp).getTime() - this.minutesDeducted;

        const speedCondition = device.last_frames.frame_gps?.speed >= this.speed;

        if (this.user.beacons?.length > 0) {
          for (const beacon of this.user.beacons) {
            if (beacon.mac_address == isThereBeacon?.macAddress && timeCondition && speedCondition) {
              this.isBeacon = true;
              break;
            } else {
              this.isBeacon = false;
            }
          }
        }
        /////---

        //LABEL
        //Meter en función!
        const rotation = device.last_frames.frame_gps.course;
        const deviceName = device.name
          ? device.name
          : device.device_data_from_frame.carplate
          ? device.device_data_from_frame.carplate.toString()
          : device.device_sn.toString();

        const labelDiv = document.createElement('div');
        labelDiv.style.display = 'flex';
        labelDiv.style.alignItems = 'center';
        labelDiv.style.maxWidth = '10em';
        labelDiv.style.width = '100%';
        labelDiv.style.height = '1em';
        labelDiv.style.padding = '0.5em';
        labelDiv.style.justifyContent = 'center';

        const pictureLabel = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        pictureLabel.setAttribute(
          'class',
          'svg-icon-module__svg___Gd7iB svg-icon-module__default-color___zK2Yx wf-object-position-status-icon-module__direction-icon___iTkWs wf-object-position-status-icon-module__use-currentColor___cPN4o',
        );
        pictureLabel.setAttribute('enable-background', 'new 0 0 48 48');
        pictureLabel.setAttribute('height', '13');
        pictureLabel.setAttribute('version', '1.1');
        pictureLabel.setAttribute('viewBox', '0 0 48 48');
        pictureLabel.setAttribute('width', '16');
        pictureLabel.setAttribute('x', '0px');
        pictureLabel.setAttribute('y', '0px');
        pictureLabel.setAttribute('style', `transform: rotate(${rotation}deg)`);

        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        path.setAttribute(
          'd',
          'M37.2 37.6L25.1 9c-.2-.5-.5-1-1.1-1-.6 0-.9.5-1.1 1L10.8 37.6c-.4.9 0 1.9.9 2.3.5.2 1 .2 1.4 0L24 34.7l10.9 5.1c.4.2.9.2 1.4 0 .9-.3 1.3-1.3.9-2.2z',
        );
        pictureLabel.appendChild(path);

        const labelText = document.createElement('div');
        labelText.innerHTML = `<span style="color: #000000 !important">${deviceName}</span>`;
        labelText.style.fontSize = '0.73rem';

        labelDiv.appendChild(pictureLabel);
        labelDiv.appendChild(labelText);

        let futureMarker = new MarkerWithLabel({
          icon: {
            url:
              device.model !== 'tracker' && this.isBeacon // TIENE BALIZA
                ? `https://pro.termografoapache.com/assets/markers/beacon_connect.png`
                : device.model !== 'tracker' && !this.isBeacon //NO TIENE BALIZA
                ? `https://pro.termografoapache.com/assets/markers/${deviceStatus}-truck.png`
                : device.submodel == 'car'
                ? `https://pro.termografoapache.com/assets/markers/${deviceStatus}-car.png`
                : `https://pro.termografoapache.com/assets/markers/${deviceStatus}-head.png`,
            scaledSize: new google.maps.Size(24, 32),
            labelOrigin: new google.maps.Point(10, 45),
            labelContent: device.name
              ? device.name
              : device.device_data_from_frame.carplate
              ? device.device_data_from_frame.carplate.toString()
              : device.device_sn.toString(),
          },
          position: { lat: lat, lng: lng },
          zIndex: 3,
          map: this.map,
          labelContent: labelDiv,
          labelClass: 'carplate',
          labelStyle: { opacity: 1.0, color: deviceStatus, course: device.last_frames.frame_gps.course },
        });

        futureMarker.sn = device.device_sn;

        futureMarker.addListener('click', () => {
          this.$emit('onMarkerTap', device, true);
        });

        for (let index in this.markerList) {
          if (this.markerList[index].sn == device.device_sn) {
            this.markerList[index].setMap(null);
            this.markerCluster?.removeMarker(this.markerList[index]);
            this.markerList.splice(index, 1);
            this.markerList.push(futureMarker);

            //REEMPLAZAMOS EL MARKER
            let indexMarker = this.markerList2.findIndex((marker) => marker.sn === futureMarker.sn);
            this.markerList2.splice(indexMarker, 1, futureMarker);

            this.markerCluster?.addMarker(futureMarker);
          }
        }

        futureMarker.addListener('click', (markerEvent) => {
          futureMarker.setAnimation(google.maps.Animation.BOUNCE);
          setTimeout(function () {
            futureMarker.setAnimation(null);
          }, 2000);
          if (markerEvent.latLng) {
            this.position = markerEvent.latLng.toJSON();
          }

          if (markerEvent.domEvent) {
            let a = {
              clientX: markerEvent.domEvent.clientX - 150,
              clientY: markerEvent.domEvent.clientY + 30,
            };

            let bothPositions = {
              a: a,
              position: this.position,
            };

            if (a.clientX > this.rightBounds) {
              bothPositions = {
                a: {
                  clientX: this.rightBounds,
                  clientY: markerEvent.domEvent.clientY + 30,
                },
                position: this.position,
              };
            }

            if (a.clientY > this.botBounds) {
              bothPositions = {
                a: {
                  clientX: markerEvent.domEvent.clientX + 27.5,
                  clientY: this.botBounds - 35,
                },
                position: this.position,
              };
            }

            if (a.clientX > this.rightBounds && a.clientY > this.botBounds) {
              bothPositions = {
                a: {
                  clientX: markerEvent.domEvent.clientX - 320,
                  clientY: markerEvent.domEvent.clientY - 320,
                },
                position: this.position,
              };
            }
            this.$emit('mouseOverDevice', device, bothPositions);
          }
        });

        futureMarker.addListener('mouseout', () => {
          this.$emit('mouseout', device, true);
        });

        if (this.isMarkerClusterer) {
          this.isMarkerClusterer.clearMarkers();
          this.isMarkerClusterer.setMap(null);
        }
        this.isMarkerClusterer = new MarkerClusterer({
          markers: this.markerList2,
          map: this.map,
          renderer: {
            render: ({ count, position }, stats) => {
              const color = this.colorMarkerClusterer;
              const svg = window.btoa(`
            <svg  fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
            <circle  cx="120" cy="120" opacity=".8" r="70" />
             </svg>`);

              let customerMarker = new google.maps.Marker({
                position,
                icon: {
                  url: `data:image/svg+xml;base64,${svg}`,
                  scaledSize: new google.maps.Size(50, 50),
                },
                label: {
                  text: String(count),
                  color: 'rgba(255,255,255,0.9)',
                  fontSize: '12px',
                },
                zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                minZoom: 5,
                maxZoom: 10,
              });

              customerMarker.myOwnId = this.generateUniqueId();

              google.maps.event.addListener(customerMarker, 'mouseover', () => {
                // LISTADO DE CADA MARKER
                this.isMarkerClusterer.clusters.forEach((cluster) => {
                  if (cluster.marker?.myOwnId === customerMarker.myOwnId && !this.isClicked) {
                    this.showHoverBox(position, cluster.markers);
                  }
                });
              });

              let cardWithListMap = document.getElementById('map');
              cardWithListMap.addEventListener('click', () => {
                if (!this.isClicked) this.hideHoverBox();
              });

              google.maps.event.addListener(customerMarker, 'click', () => {
                this.isClicked = true;
                setTimeout(() => {
                  this.hideHoverBox();
                  this.isClicked = false;
                }, 200);
              });

              return customerMarker;
            },
          },
        });
      }
    },
    removeDeviceFromMap(device, isLast, index) {
      this.markerList.splice(index, 0);

      // if (isLast) {
      //   this.markerCluster = new MarkerClusterer(this.map, this.markerList, {
      //     imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
      //     maxZoom: 15,
      //   });
      // }
    },
    resetMap() {
      this.user = this.$store.state.currentUser;
      this.markerCluster?.clearMarkers();
      for (let i = 0; i < this.markerList.length; i++) {
        this.markerList[i].setMap(null);
      }
      for (let i = 0; i < this.markerList2.length; i++) {
        this.markerList2[i].setMap(null);
      }
      this.markerList = [];
      this.markerList2 = [];
    },
    resetMapWithoutPois() {
      this.user = this.$store.state.currentUser;
      this.markerCluster?.clearMarkers();
      let filterMarkerWithoutPois = this.markerList.filter((marker) => !marker.hasOwnProperty('poi_category'));

      for (let i = 0; i < filterMarkerWithoutPois.length; i++) {
        filterMarkerWithoutPois[i].setMap(null);
      }
      filterMarkerWithoutPois = [];
    },
    followDevice(lat, lng) {
      if (!this.routesActive) {
        this.map.setCenter({ lat: lat, lng: lng });
      }
    },
    focusOnDeviceFromNavbar(device) {
      let deviceLatitude = device.last_frames.frame_gps.latitude;
      let deviceLongitude = device.last_frames.frame_gps.longitude;

      // EL PROBLEMA ESTA EN QUE MAP ES NULL
      if (this.map) {
        this.map.setCenter({
          lat: 46.52977,
          lng: 7.976387,
          // lat: deviceLatitude,
          // lng: deviceLongitude,
        });
        this.map.setZoom(5);
      }
      this.$store.dispatch('setSelectedDevice', null);
    },
    focusOnDevice(device) {
      this.markerList.map((i) => {
        if (device.device_sn === i.sn) {
          i.label.className = 'newCarplate marker-label';
        } else {
          i.label.className = 'carplate marker-label';
        }
      });
      if (device?.last_frames?.frame_gps) {
        const lat = device.last_frames.frame_gps.latitude;
        const lng = device.last_frames.frame_gps.longitude;

        this.map.setCenter({ lat, lng });
        this.map.setZoom(19); //Before 14
        this.map.setMapTypeId('satellite'); //Vista satelite al seleccionar device
      } else {
        const lat = 46.52977;
        const lng = 7.976387;

        this.map.setCenter({ lat, lng });
        this.map.setZoom(5);
      }
    },
    checkDeviceStatus(device) {
      let actualTime = new Date().getTime();
      let frameTime = new Date(device.last_frames.frame_gps.gps_timestamp).getTime();
      let actualSpeed = device.last_frames.frame_gps.speed;
      let resultTime = actualTime - frameTime;

      if (resultTime >= 2700000) {
        return 'red';
      } else if (resultTime > 600000) {
        return 'orange';
      } else {
        return actualSpeed > 18 ? 'green' : 'orange';
      }
    },
    exitRoutesBtn(buttonsDiv) {
      // Set CSS for the control border.
      this.exitRouteBtn = buttonsDiv;
      const exitUI = document.createElement('div');
      exitUI.style.backgroundColor = '#ff9b00 ';
      exitUI.style.border = '2px solid #ff9b00';
      exitUI.style.borderRadius = '6px';
      exitUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
      exitUI.style.cursor = 'pointer';
      exitUI.style.marginTop = '6.5rem';
      exitUI.style.marginRight = '1.5rem';
      exitUI.style.marginBottom = '1rem';
      exitUI.style.textAlign = 'center';
      buttonsDiv.appendChild(exitUI);
      // Set CSS for the control interior.
      const exitText = document.createElement('div');
      exitText.style.color = 'rgb(25,25,25)';
      exitText.style.fontFamily = 'Roboto,Arial,sans-serif';
      exitText.style.fontSize = '18px';
      exitText.style.lineHeight = '38px';
      exitText.style.paddingLeft = '9px';
      exitText.style.paddingRight = '9px';
      exitText.style.backgroundColor = 'ff9b00';
      exitText.innerHTML = `<i class='mdi mdi-close' ></i> ${this.$t('dashboard.return_to_map')}`;
      exitUI.appendChild(exitText);
      this.UIexitBtn = exitUI;
      // Setup the click event listeners: simply set the map to Chicago.
      exitUI.addEventListener('click', () => {
        eventBus.$emit('cleanSearch');
        this.exitRoutes();
        this.addPoiToMap(this.user);
      });
    },
    exitFastRouteBtn(buttonsDiv) {
      // Set CSS for the control border.
      this.exitFastestRouteBtn = buttonsDiv;
      const exitUI = document.createElement('div');
      exitUI.style.backgroundColor = '#ff9b00 ';
      exitUI.style.border = '2px solid #ff9b00';
      exitUI.style.borderRadius = '6px';
      exitUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
      exitUI.style.cursor = 'pointer';
      exitUI.style.marginTop = '6.5rem';
      exitUI.style.marginRight = '1.5rem';
      exitUI.style.marginBottom = '1rem';
      exitUI.style.textAlign = 'center';
      buttonsDiv.appendChild(exitUI);
      // Set CSS for the control interior.
      const exitText = document.createElement('div');
      exitText.style.color = 'rgb(25,25,25)';
      exitText.style.fontFamily = 'Roboto,Arial,sans-serif';
      exitText.style.fontSize = '18px';
      exitText.style.lineHeight = '38px';
      exitText.style.paddingLeft = '9px';
      exitText.style.paddingRight = '9px';
      exitText.style.backgroundColor = 'ff9b00';
      exitText.innerHTML = `<i class='mdi mdi-close' ></i> ${this.$t('dashboard.return_to_map')}`;
      exitUI.appendChild(exitText);
      this.UIexitBtn = exitUI;
      // Setup the click event listeners: simply set the map to Chicago.
      exitUI.addEventListener('click', () => {
        // this.thereIsRoute = false;
        this.exitFastestRoutes();
        this.addPoiToMap(this.user);
        this.activateRightClick();
        this.activateTouchLongClick(); // Si lo activo, al clicar se abre el dialog. Si desactivo, no se abre el dialog después nunca más.
      });
    },
    exitRouteToBtn(directions, buttonsDiv) {
      // Set CSS for the control border.
      this.exitFastestRouteBtn = buttonsDiv;
      const routeEelement = document.createElement('div');
      routeEelement.style.backgroundColor = '#fff ';
      routeEelement.style.border = '2px solid ##fff';
      routeEelement.style.borderRadius = '6px';
      routeEelement.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
      routeEelement.style.cursor = 'pointer';
      routeEelement.style.marginTop = '1rem';
      routeEelement.style.marginLeft = '1rem';
      routeEelement.style.marginBottom = '1rem';
      routeEelement.style.textAlign = 'center';
      routeEelement.style.height = '5rem';
      routeEelement.style.width = '15rem';
      routeEelement.style.fontFamily = 'Roboto,Arial,sans-serif';
      routeEelement.style.fontSize = '18px';
      buttonsDiv.appendChild(routeEelement);

      // Set CSS for the control interior.
      const exitText = document.createElement('div');
      exitText.style.color = 'rgb(25,25,25)';
      exitText.style.fontFamily = 'Roboto,Arial,sans-serif';
      exitText.style.fontSize = '18px';
      exitText.style.backgroundColor = 'ff9b00';
      exitText.innerHTML = `
        <p  class='mdi mdi-close' style='color: black !important;'>  ${this.$t('dashboard.route_information')}</p>
        <i>${this.$t('dashboard.distance')}: ${directions.routes[0].legs[0].distance.text}</i><br>
        <i>${this.$t('dashboard.duration')}: ${directions.routes[0].legs[0].duration.text}</i>
        `;
      routeEelement.appendChild(exitText);
      this.UIexitBtn = routeEelement;
      // Setup the click event listeners: simply set the map to Chicago.
      routeEelement.addEventListener('click', () => {
        eventBus.$emit('cleanSearch');
        this.exitFastestRoutes();
        this.addPoiToMap(this.user);
      });
    },
    addOpenInfoWindowListeners(infowindow) {
      var infoWindowElement = document.querySelector('.gm-style .gm-style-iw')?.parentNode;

      infoWindowElement?.addEventListener('mouseleave', function () {
        infowindow.close();
        mouseOverInfoWindow = false;
      });

      infoWindowElement?.addEventListener('mouseover', function () {
        mouseOverInfoWindow = true;
      });
    },
    async drawPolyline(locations, device, date) {
      this.resetMap();
      if (this.devicePath !== null) {
        this.exitRoutes();
      }
      if (this.display !== null) {
        this.exitFastestRoutes();
      }
      const token = getToken();
      let sumLat = 0;
      let sumLng = 0;
      let framesCollection = [];
      let locationsLength = locations.length;

      this.locationCollection = locations;

      var infowindow = new google.maps.InfoWindow();

      let indexToMarker = 0;

      for (let i = 0; i < locationsLength; i++) {
        if (locations[i].type == 'movement') {
          locations[i].frames.map((element) => {
            framesCollection.push({ type: 'movement', ...element });
            sumLat += element.lat;
            sumLng += element.lng;
          });
        }
        this.selectDevice = device.device_sn;
        if (locations[i].type == 'stop') {
          let frame = locations[i].frames.at(0);
          framesCollection.push({ type: 'stop', ...frame });
          if (i == locationsLength - 1) {
            indexToMarker += 1;
            this.setEndMarker(frame.lat, frame.lng, locations[i].init, locations[i].framesFrigo, infowindow, indexToMarker);
          } else {
            indexToMarker += 1;
            this.setStopMarker(locations[i], device, infowindow, indexToMarker);
          }
        }
        if (locations[i].type == 'pause') {
          indexToMarker += 1;
          framesCollection.push({ type: 'pause', ...locations[i].frames[0] });
          this.setPauseMarker(locations[i].frames[0], device, infowindow, locations[i], indexToMarker);
        }
      }
      sumLat /= locationsLength;
      sumLng /= locationsLength;

      this.routesActive = true;
      // TODO: esto colocará un marker encima del marker del primer bloque (generalmente STOP)
      this.setInitMarker(locations[0].positionInitLat, locations[0].positionInitLng, infowindow);

      this.addDeviceToMap(device, '', this.user);
      this.addPoiToMap(this.user);
      //this.map.setCenter({ lat: locations.at(-1).frames.at(-1).lat, lng: locations.at(-1).frames.at(-1).lng });

      const exitRoute = document.createElement('div');
      exitRoute.style.display = 'flex';
      exitRoute.style.flexDirection = 'column';
      this.exitRoutesBtn(exitRoute);

      this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(exitRoute);

      let route = framesCollection;

      this.arrayToRoute = [];
      let indice = 0;
      for (let i = 0; i < route.length; i++) {
        if (route[i].type == 'movement') {
          this.arrayToRoute.push(route[i]);
        }
        if (route[i].type != 'movement') {
          this.arrayToRoute.push(route[i]);
          await this.getRandomColor();

          //TRAMOS DE COLORES ( RUTA )
          this.devicePath = new google.maps.Polyline({
            path: this.arrayToRoute,
            geodesic: true,
            strokeColor: this.allColorsToPolyLine[indice - 1],
            strokeOpacity: 1.0,
            strokeWeight: 4,
          });

          indice += 1;
          this.devicePath.setMap(this.map);
          this.allPolylines.push(this.devicePath);

          /**
           * [] AÑADIR NUMEROS A CADA RUTA (HOOVER)
           */
          // let contentString = `<div>RUTA ${indice - 1} </div>`;

          // this.devicePath.addListener('mouseover', (event) => {
          //   if (timeoutID) clearTimeout(timeoutID);
          //   infowindow.setContent(contentString);
          //   infowindow.setPosition(event.latLng);
          //   infowindow.open(this.map);
          //   this.addOpenInfoWindowListeners(infowindow);
          // });

          // this.devicePath.addListener('mouseout', () => {
          //   timeoutID = setTimeout(function () {
          //     if (!mouseOverInfoWindow) {
          //       infowindow.close();
          //     }
          //   }, 400);
          // });
          /**
           * []HASTA AQUI
           */

          this.arrayToRoute = [];
          this.arrayToRoute.push(route[i]);
        }
      }

      let lastFrame = null;
      let sumDistanceBetweeenFrames = 0;
      let lastDistanceBetweenFrames = 0;
      const MARKER_EACH_KM = 30;
      let bounds = new google.maps.LatLngBounds();
      let i = -1;
      let todrawArrow = 1;
      framesCollection.map((frame) => {
        todrawArrow++;
        if (frame.type !== 'movement') {
          i++;
        }
        if (todrawArrow % 3 == 0) {
          //ARROW MARKERS
          let marker = new google.maps.Marker({
            position: { lat: frame.lat, lng: frame.lng },
            map: this.map,
            zIndex: 1,
            icon: {
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              scale: 3,
              fillColor: this.allColorsToPolyLine[i],
              fillOpacity: 1,
              strokeWeight: 0.5,
              rotation: frame.course,
              anchor: new google.maps.Point(0, 2),
            },
            visible: false,
            visibilityAllowed: sumDistanceBetweeenFrames > MARKER_EACH_KM,
            type: frame.type,
          });

          if (sumDistanceBetweeenFrames > MARKER_EACH_KM) sumDistanceBetweeenFrames = 0;

          this.markerList.push(marker);
          bounds.extend(marker.position);
          if (lastFrame != null) {
            lastDistanceBetweenFrames = this.getDistanceFromLatLonInKm(frame.lat, frame.lng, lastFrame.lat, lastFrame.lng);
            sumDistanceBetweeenFrames += lastDistanceBetweenFrames;
          }
          let thisContext = this;
          marker.addListener('mouseover', function (mapsMouseEvent) {
            if (timeoutID) clearTimeout(timeoutID);
            const addZero = (i) => {
              if (i < 10) {
                i = '0' + i;
              }
              return i;
            };

            const date = new Date(frame.gps_timestamp);
            const h = addZero(date.getHours());
            const m = addZero(date.getMinutes());
            const s = addZero(date.getSeconds());
            const formatedDate = h + ':' + m + ':' + s;

            let arrowLabel = `
                  <div style=" background-color:white">
                    <h4>${VueI18n.default.t('dashboard.device')}: ${device?.device_sn}</h4>
                    <b>${VueI18n.default.t('dashboard.speed')}:</b> ${frame.speed.toFixed(0)} km/h <br>
                    <b>${VueI18n.default.t('dashboard.hour')}:</b> ${formatedDate} <br>
                   </div>
            `;

            infowindow.setContent(arrowLabel);
            infowindow.open(this.map, marker);
            try {
              thisContext.addOpenInfoWindowListeners(infowindow);
            } catch (ex) {
              console.log(ex);
            }
          });

          marker.addListener('mouseout', () => {
            timeoutID = setTimeout(function () {
              if (!mouseOverInfoWindow) {
                infowindow.close();
              }
            }, 400);
          });

          lastFrame = frame;
        }
      });
      this.map.fitBounds(bounds);
      //this.fitAllMarkers();

      //TODO
      /* Change markers on zoom */
      google.maps.event.addListener(this.map, 'zoom_changed', () => {
        var zoom = this.map.getZoom();
        // iterate over markers and call setVisible

        let count = 0;
        this.markerList.forEach((marker) => {
          if (marker.type == 'movement') marker.setVisible(false);
          if (zoom < 13) {
            if (marker.visibilityAllowed) {
              if (zoom < 6) {
                marker.setVisible(false);
              } else if (zoom < 7) {
                marker.setVisible(count % 4 == 0);
              } else if (zoom < 9) {
                marker.setVisible(count % 3 == 0);
              } else if (zoom >= 9) {
                marker.setVisible(true);
              }

              count++;
            }
          } else {
            marker.setVisible(true);
          }
        });

        if (zoom % 2 == 0) {
          // solo centramos el mapa cada 2 zooms
          const closestMarker = this.getClosestMarkerToCenter();

          this.map.setCenter(closestMarker.position);
        }
      });
      this.map.addListener('click', function () {
        infowindow.close();
      });
    },
    getClosestMarkerToCenter() {
      function rad(x) {
        return (x * Math.PI) / 180;
      }
      var lat = this.map.center.lat();
      var lng = this.map.center.lng();
      var R = 6371; // radius of earth in km
      var distances = [];
      var closest = -1;
      for (let i = 0; i < this.markerList.length; i++) {
        var mlat = this.markerList[i].position.lat();
        var mlng = this.markerList[i].position.lng();
        var dLat = rad(mlat - lat);
        var dLong = rad(mlng - lng);
        var a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(rad(lat)) * Math.cos(rad(lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        distances[i] = d;
        if (closest == -1 || d < distances[closest]) {
          closest = i;
        }
      }
      if (closest == -1) return null;
      // this.map.setZoom(10);
      return this.markerList[closest];
    },
    async centerMapFunction(lat, lng) {
      //OBTENER EL VALOR MAS ALTO DEL ARRAY
      let latMax = Math.max(...lat);
      let latMin = Math.min(...lat);

      let lngMax = Math.max(...lng);
      let lngMin = Math.min(...lng);

      let bounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(latMin, lngMin), // Esquina inferior izquierda
        new google.maps.LatLng(latMax, lngMax), // Esquina superior derecha
      );

      // Centrar el mapa y ajustar el zoom automáticamente
      this.map.fitBounds(bounds);
    },

    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      var R = 6371; // Radius of the earth in km
      var dLat = this.deg2rad(lat2 - lat1); // deg2rad below
      var dLon = this.deg2rad(lon2 - lon1);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c; // Distance in km
      return d;
    },

    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    drawBestRoute(device, position) {
      if (this.devicePath !== null) {
        this.exitRoutes();
      }
      if (this.display !== null) {
        this.exitFastestRoutes();
      }
      this.resetMap();

      this.addDeviceToMap(device, '', this.user);
      const exitRoute = document.createElement('div');
      exitRoute.style.display = 'flex';
      exitRoute.style.flexDirection = 'column';
      this.exitFastRouteBtn(exitRoute);
      this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(exitRoute);

      var directionsService = new google.maps.DirectionsService();
      var directionsDisplay = new google.maps.DirectionsRenderer({ suppressMarkers: true });

      this.display = directionsDisplay;
      this.service = directionsService;

      //add map
      this.display.setMap(this.map);
      const refAlert = async () => {
        await this.$refs.alertmap.open(this.$t('general.form.connection failed'), this.$t('general.form.correct_address'), {
          color: this.theme === 'dark' ? 'grey' : 'red',
        });
      };
      // set the new
      this.service.route(
        {
          origin: { lat: device.last_frames.frame_gps.latitude, lng: device.last_frames.frame_gps.longitude }, //db waypoint start
          destination: { lat: position.lat, lng: position.lng }, //db waypoint end
          travelMode: google.maps.TravelMode.DRIVING,
        },
        function (response, status) {
          if (status === google.maps.DirectionsStatus.OK) {
            directionsDisplay.setDirections(response);
          } else {
            refAlert();
          }
        },
      );
    },
    async drawBestRouteTo(device, position) {
      const refAlert = async () => {
        await this.$refs.alertmap.open(this.$t('general.form.connection failed'), this.$t('general.form.correct_address'), {
          color: this.theme === 'dark' ? 'grey' : 'red',
        });
      };
      let address = await getCoordinates(position);

      if (!address) return refAlert();

      if (this.devicePath !== null) {
        this.exitRoutes();
      }
      if (this.display !== null) {
        this.exitFastestRoutes();
      }

      const routeTo = document.createElement('div');
      routeTo.style.display = 'flex';
      routeTo.style.flexDirection = 'column';
      this.exitFastestRouteBtn = routeTo;

      this.resetMap();

      this.addDeviceToMap(device, '', this.user);

      var directionsService = new google.maps.DirectionsService();
      var directionsDisplay = new google.maps.DirectionsRenderer({ suppressMarkers: true, map: this.map });

      directionsDisplay.addListener('directions_changed', () => {
        const directions = directionsDisplay.getDirections();

        this.exitRouteToBtn(directions, routeTo);
        this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(routeTo);
      });
      this.display = directionsDisplay;
      this.service = directionsService;

      //add map
      // this.display.setMap(this.map)

      // set the new

      this.service.route(
        {
          origin: { lat: device.last_frames.frame_gps.latitude, lng: device.last_frames.frame_gps.longitude }, //db waypoint start
          destination: { lat: address.coordinates.latitude, lng: address.coordinates.longitude }, //db waypoint end
          travelMode: google.maps.TravelMode.DRIVING,
        },
        function (response, status) {
          if (status === google.maps.DirectionsStatus.OK) {
            directionsDisplay.setDirections(response);
          } else {
            refAlert();
          }
        },
      );
    },

    poisBtn(category) {
      //CATEGORIA SELECCIONADA
      const selectedOption = category;
      //MARCAS QUE SEAN POIS
      let filterCategoryWithoutDevices = this.markerList.filter((poi) => poi.hasOwnProperty('poi_category'));
      // CREAMOS ARRAY PARA CATEGORIAS
      let arrayWithCategories = [];
      //INTRODUCIMOS CATEGORIAS EN ARRAY
      filterCategoryWithoutDevices.forEach((poi) => {
        if (poi.poi_category && !arrayWithCategories.includes(poi.poi_category)) {
          arrayWithCategories.push(poi.poi_category);
        }
      });
      //POIS QUE COINCIDA CON LA CATEGORIA SELECCIONADA
      let filterCategory = this.markerList.filter(
        (poi) => poi.hasOwnProperty('poi_category') && poi.poi_category === selectedOption,
      );
      //ELIMINAMOS TODOS LOS POIS (SIN ELIMINAR LOS DISPOSITIVOS)
      for (let i = 0; i < filterCategoryWithoutDevices.length; i++) {
        filterCategoryWithoutDevices[i].setMap(null);
      }
      //AÑADIMOS AL MAPA LOS POIS QUE CONTIENEN LA CATEGORIA SELECCIONADA
      for (let i = 0; i < filterCategory.length; i++) {
        filterCategory[i].setMap(this.map);
      }
      filterCategory = [];
      filterCategoryWithoutDevices = [];
    },

    exitRoutes() {
      if (this.exitRouteBtn !== null) {
        this.exitRouteBtn.removeChild(this.UIexitBtn);
        this.exitRouteBtn = null;
        this.resetMap();
        this.devicePath.setMap(null);
        this.$emit('onInit');
        this.overlays.forEach((overlay) => {
          overlay.setMap(null);
        });
        this.overlays = [];

        this.allPolylines.forEach((polyline) => {
          polyline.setMap(null);
        });
        this.allPolylines = [];
        this.color = '';
        this.allColorsToPolyLine = [];
      }
    },

    exitFastestRoutes() {
      if (this.exitFastestRouteBtn !== null) {
        if (this.exitFastestRouteBtn.contains(this.UIexitBtn)) this.exitFastestRouteBtn.removeChild(this.UIexitBtn);
        this.exitFastestRouteBtn = null;
        this.resetMap();
        this.display.setMap(null);
        this.$emit('onInit');
      }
    },

    async setInitMarker(lat, lng, infowindow) {
      let initialMovement = [];
      this.locationCollection.forEach((element) => {
        element.type == 'movement' ? initialMovement.push(element) : VueI18n.default.t('dashboard.no_data');
      });

      const locationAddress = await getAddress(lat, lng, getToken());
      const locationGeocode = locationAddress?.addressData;

      let initLabel =
        ` <div style=" background-color:white">
        <h3>${VueI18n.default.t('dashboard.start')}</h3>` +
        `<div>S/N:${this.selectDevice}</div>` +
        `<div>${VueI18n.default.t('dashboard.started_date')}: ${formatDate(initialMovement[0].init)}</div>
        ${
          locationGeocode?.displayFormatted
            ? `<div>${VueI18n.default.t('dashboard.position')}: ${locationGeocode.displayFormatted}</div>`
            : ''
        }
        </div>
        `;

      let marker = new google.maps.Marker({
        position: { lat: lat, lng: lng },
        zIndex: 10,
        icon: {
          url: `https://pro.termografoapache.com/assets/markers/start-circle.png`,
          scaledSize: new google.maps.Size(20, 20),
          labelOrigin: new google.maps.Point(10, 45),
        },
        map: this.map,
      });

      this.markerList.push(marker);

      marker.addListener('mouseover', () => {
        if (timeoutID) clearTimeout(timeoutID);
        infowindow.setContent(initLabel);
        infowindow.open({
          anchor: marker,
          map,
          shouldFocus: false,
        });

        this.addOpenInfoWindowListeners(infowindow);
      });

      marker.addListener('mouseout', () => {
        timeoutID = setTimeout(function () {
          if (!mouseOverInfoWindow) {
            infowindow.close();
          }
        }, 400);
      });
    },
    async drawChart(marker, infowindow) {
      const temperatureFrames = marker.context.temperatureFrames;

      const markerPosition = marker.getPosition();

      const locationAddress = await getAddress(markerPosition.lat(), markerPosition.lng(), getToken());
      const locationGeocode = locationAddress?.addressData;

      let stopLabel = `
      <div style=" background-color:white">
         <div style="font-size: 16px; color: rgba(0, 0, 0, 0.87); font-weight: bold; background-color:white">
          ${VueI18n.default.t('dashboard.stop')}
        </div>
        <div>S/N:${this.selectDevice}</div>
        <div>${VueI18n.default.t('dashboard.stop_date')}: ${marker.context.stopInit}</div>
          ${
            locationGeocode?.displayFormatted
              ? `<div>${VueI18n.default.t('dashboard.position')}: ${locationGeocode.displayFormatted}</div>`
              : ''
          }
        </div>
      `;

      let rootElement = document.createElement('div');
      rootElement.innerHTML = stopLabel;

      if (temperatureFrames.length > 0 && Object.keys(temperatureFrames?.at(0).probes_temperature).length > 0) {
        var data = new google.visualization.DataTable();
        data.addColumn('timeofday', this.$t('reports.types.temp_ticket'));

        Object.keys(temperatureFrames?.at(0).probes_temperature).forEach((probe) => {
          data.addColumn('number', this.$t('device.last_frames.frame_frigo.' + probe));
        });

        let rows = [];
        temperatureFrames.forEach((frame) => {
          let date = new Date(frame.device_timestamp);
          rows.push([[date.getHours(), date.getMinutes(), date.getSeconds()], ...Object.values(frame.probes_temperature)]);
        });
        data.addRows(rows);

        var options = {
          chart: {
            title: `${VueI18n.default.t('dashboard.temperatures')} (°C)`,
          },
          theme: {
            titlePosition: 'in',
          },
          titleTextStyle: {
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '16',
            bold: true,
          },
          width: 500,
          height: 300,
          vAxis: {
            format: '#,##0.0 °C',
          },
          hAxis: { format: 'HH:mm', gridlines: { count: -1 } },
        };
        let node = document.createElement('div'),
          chart = new google.charts.Line(node);

        chart.draw(data, google.charts.Line.convertOptions(options));

        node.style.marginTop = '10px';
        rootElement.appendChild(node);
      }
      infowindow.setContent(rootElement);
      infowindow.open({
        anchor: marker,
        map: marker.map,
        shouldFocus: false,
      });

      this.addOpenInfoWindowListeners(infowindow);
    },

    async getRandomColor() {
      if (this.theme === 'light') {
        const randomIndex = Math.floor(Math.random() * this.arrayWithColorsLight.length);
        return this.arrayWithColorsLight[randomIndex];
      } else {
        const randomIndex = Math.floor(Math.random() * this.arrayWithColorsDark.length);
        return this.arrayWithColorsDark[randomIndex];
      }
    },

    async setOverlay(marker, indexToMarker) {
      this.color = await this.getRandomColor();
      while (this.allColorsToPolyLine.at(-1) === this.color) {
        this.color = await this.getRandomColor();
      }
      this.allColorsToPolyLine.push(this.color);
      let overlay = new google.maps.OverlayView();
      let randomColor = this.color;
      let theme = this.theme;
      overlay.onAdd = function () {
        let div = document.createElement('div');
        div.style.position = 'absolute';
        div.style.zIndex = '2';
        div.style.backgroundColor = randomColor;
        // div.style.borderRadius = '50%'; // to get circle
        div.style.width = '20px';
        div.style.height = '20px';
        div.style.textAlign = 'center';
        div.style.lineHeight = '20px';
        div.style.color = theme === 'light' ? 'white' : 'black';
        div.style.fontSize = '11px';
        div.innerHTML = indexToMarker.toString();

        let panes = this.getPanes();
        panes.markerLayer.appendChild(div);
        // panes.overlayLayer.appendChild(div); // ASI EL OVERLAY QUEDA DEBAJO DE LAS FLECHAS

        this.div_ = div;
      };

      overlay.draw = function () {
        let position = marker.getPosition();
        let projection = this.getProjection();
        let point = projection.fromLatLngToDivPixel(position);
        let div = this.div_;
        div.style.left = point.x + 'px';
        div.style.top = point.y + 'px';
      };

      overlay.onRemove = function () {
        if (this.div_) {
          this.div_.parentNode.removeChild(this.div_);
          this.div_ = null;
        }
      };

      overlay.setMap(this.map);

      this.overlays.push(overlay);
    },
    async setEndMarker(lat, lng, date, framesFrigo, infowindow, indexToMarker) {
      let marker = new google.maps.Marker({
        position: { lat, lng },
        zIndex: 5,
        icon: {
          url: `https://pro.termografoapache.com/assets/markers/end-circle-black.png`,
          scaledSize: new google.maps.Size(20, 20),
          labelOrigin: new google.maps.Point(10, 45),
        },
        map: this.map,
        context: {
          temperatureFrames: framesFrigo,
          stopInit: formatDate(date),
        },
      });

      this.markerList.push(marker);
      await this.setOverlay(marker, indexToMarker);

      marker.addListener('mouseover', async () => {
        if (timeoutID) clearTimeout(timeoutID);
        await this.drawChart(marker, infowindow);
      });

      marker.addListener('mouseout', () => {
        timeoutID = setTimeout(function () {
          if (!mouseOverInfoWindow) {
            infowindow.close();
          }
        }, 400);
      });
    },
    async setStopMarker(stopMovement, device, infowindow, indexToMarker) {
      let temperatureFrames = stopMovement.framesFrigo;

      let marker = new google.maps.Marker({
        position: { lat: stopMovement.positionInitLat, lng: stopMovement.positionInitLng },
        zIndex: 5,
        icon: {
          url: `https://pro.termografoapache.com/assets/markers/stop-circle.png`,
          scaledSize: new google.maps.Size(20, 20),
          labelOrigin: new google.maps.Point(10, 45),
        },
        map: this.map,

        context: {
          temperatureFrames,
          stopInit: formatDate(stopMovement.init),
        },
      });

      this.markerList.push(marker);
      await this.setOverlay(marker, indexToMarker);

      marker.addListener('mouseover', async () => {
        if (timeoutID) clearTimeout(timeoutID);
        await this.drawChart(marker, infowindow);
      });

      marker.addListener('mouseout', () => {
        timeoutID = setTimeout(function () {
          if (!mouseOverInfoWindow) {
            infowindow.close();
          }
        }, 400);
      });
    },

    async setPauseMarker(pauseMovement, device, infowindow, movement, indexToMarker) {
      let temperatureFrames = movement.framesFrigo;

      let marker = new google.maps.Marker({
        position: { lat: pauseMovement.lat, lng: pauseMovement.lng },
        zIndex: 5,
        icon: {
          url: `https://pro.termografoapache.com/assets/markers/pause-circle.png`,
          scaledSize: new google.maps.Size(20, 20),
          labelOrigin: new google.maps.Point(10, 45),
        },
        map: this.map,
        context: {
          temperatureFrames,
          stopInit: formatDate(movement.init),
        },
      });

      this.markerList.push(marker);

      marker.addListener('mouseover', async () => {
        if (timeoutID) clearTimeout(timeoutID);
        await this.drawChart(marker, infowindow);
      });

      marker.addListener('mouseout', () => {
        timeoutID = setTimeout(function () {
          if (!mouseOverInfoWindow) {
            infowindow.close();
          }
        }, 400);
      });

      this.markerList.push(marker);
      await this.setOverlay(marker, indexToMarker);
    },
    fitAllMarkers() {
      var bounds = new google.maps.LatLngBounds();
      for (var i in this.markerList) // your marker list here
        bounds.extend(this.markerList[i].position); // your marker position, must be a LatLng instance

      this.map.fitBounds(bounds); // map should be your map class
    },
  },
};
</script>

<style>
.newCarplate {
  border: 0.6px solid rgb(4, 255, 4);
  border-radius: 5px;
  background: #c1dde6;
  font-weight: bold;
  font-size: 14px;
  color: #4354e7;
  margin-top: 5px;
  margin-left: -2em;
  z-index: 5 !important;
}

.labelPoi {
  text-align: center;
  padding: 0.2em;
  height: 1.8vh;
  max-width: 14em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.6px solid #b4b4b4;
  border-radius: 5px;
  background: #feffff;
  font-weight: bold;
  font-size: 0.73rem;
  color: #000000;
  margin-top: 5px;
  margin-left: -1em;
}
.carplate {
  border: 0.6px solid #b4b4b4;
  border-radius: 5px;
  background: #feffff;
  font-weight: bold;
  font-size: 14px;
  color: #000000;
  margin-top: 5px;
  margin-left: -2em;
}

#labelTextId {
  color: #000000 !important;
}

.gm-ui-hover-effect {
  display: none !important;
}

/* EVITA EL FONDO BLANCO Y BORDE-SOMBREADO POR DEFECTO*/
.gm-style .gm-style-iw-c {
  background-color: transparent !important;
  box-shadow: none !important;
}

/* EVITA PADDING EN BLANCO DEL INFOWIDNOW */
.gm-style-iw-d {
  overflow: hidden !important;
}

.svg-icon-dark svg {
  fill: white;
}
</style>
