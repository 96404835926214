// const constants = require('../plugins/lang/constants');
const isActivatedEmailPetition = require('../server/petitions/users/isActivatedEmail');
const isActivatedAllEmailPetition = require('../server/petitions/users/isActivatedAllEmail');
const { loginByEmail } = require('../server/petitions/authentication/loginByEmail');
const { loginByToken } = require('../server/petitions/authentication/loginByToken');
const { logoutByToken } = require('../server/petitions/authentication/logoutByToken');
const { modifyUserItselfPetition } = require('../server/petitions/users/modifyUserItself');
const { modifyMetricsPetition } = require('../server/petitions/users/modifyMetrics');
const { checkingUserCode } = require('../server/petitions/users/checkingUserCode');
const { deleteCode } = require('../server/petitions/users/deleteCode');
const { getPCName } = require('../utils/utils');
const { markUpdateSeenPetition } = require('../server/petitions/users/markUpdateSeen');
const { changePasswordPetition } = require('../server/petitions/users/changePasswordPetition');
const { getImagePetition } = require('../server/petitions/users/getImagePetition');
const { modifyNotificationEmailsPetition } = require('../server/petitions/users/modifyNotificationEmailsPetition');
const { deleteNotificationEmailsPetition } = require('../server/petitions/users/deleteNotificationEmailsPetition');

const { changeLanguagePetition } = require('../server/petitions/users/changeLanguagePetition');

const { newPlatformPetition } = require('../server/petitions/users/newPlatformPetition');
const { validationEmail } = require('../server/petitions/users/validationEmail');

const AUTH_TOKEN_KEY = 'authToken';

const UserSingleton = function () {
  let user = null;

  async function getUserByToken(token) {
    try {
      const _user = await loginByToken(token);
      return _user;
    } catch (e) {
      console.error('ERRROR: ', e);
      return null;
    }
  }

  async function login(email, password) {
    const name = getPCName();
    user = await loginByEmail(email, password, name);
    SetToken(user.token);
    return user;
  }

  async function logout() {
    try {
      const token = GetToken();
      await logoutByToken(token);
      RemoveToken();
      user = null;
    } catch (e) {
      console.error('ERRROR: ', e);

      return null;
    }
  }

  function GetToken() {
    const token = localStorage.getItem(AUTH_TOKEN_KEY);
    return token;
  }

  function SetToken(token) {
    localStorage.setItem(AUTH_TOKEN_KEY, token);
  }

  function RemoveToken() {
    localStorage.removeItem(AUTH_TOKEN_KEY);
  }

  async function Get() {
    try {
      if (user != null) {
        return user;
      }

      const token = GetToken();

      if (token == null) {
        return null;
      }
      user = await getUserByToken(token);
      return user;
    } catch (e) {
      return e;
    }
  }
  async function getUserToShareDevices() {
    try {
      const token = GetToken();

      if (token == null) {
        return null;
      }
      user = await getUserByToken(token);
      return user;
    } catch (e) {
      return e;
    }
  }
  async function getUpdatedUser() {
    try {
      const token = GetToken();
      if (token == null) {
        return null;
      }
      user = await getUserByToken(token);
      return user;
    } catch (e) {
      return e;
    }
  }

  async function isUserLogged() {
    if (user != null) {
      return true;
    }
    const _user = await Get();
    return _user != null;
  }

  async function changeLanguage(view, email, code) {
    view.$i18n.locale = code;
    view.$vuetify.lang.current = code;
    const token = GetToken();

    try {
      const user = await changeLanguagePetition(code, email, token);
    } catch (error) {
      console.log(error);
    }
  }

  function changeUserLanguage(view, code) {
    view.$i18n.locale = code;
    view.$vuetify.lang.current = code;
  }

  function setLanguageToUserDefault(view) {
    let language;

    if (user != null) {
      language = user.locale;
    } else {
      language = navigator.language.split('-')[0];
    }

    changeUserLanguage(view, language);
  }

  async function modifyUserItself(name, phone, email, notification_emails, unit, base64) {
    const token = GetToken();

    const updatedUser = await modifyUserItselfPetition(name, phone, email, notification_emails, unit, token, base64);

    user = updatedUser;
  }

  async function modifyMetrics(email, unit) {
    const token = GetToken();

    const updatedUser = await modifyMetricsPetition(email, unit, token);

    user = updatedUser;
  }

  async function modifyNotificationEmails(email, emails) {
    const token = GetToken();

    const updatedUser = await modifyNotificationEmailsPetition(email, emails, token);
    return updatedUser;
  }

  async function deleteNotificationEmails(userEmail, emailToDelete) {
    const token = GetToken();

    const updatedUser = await deleteNotificationEmailsPetition(userEmail, emailToDelete, token);
    return updatedUser;
  }

  async function changePassword(email, password) {
    try {
      const token = GetToken();
      const user = await changePasswordPetition(email, password, token);
    } catch (error) {
      console.log(error);
    }
  }

  async function sendUserInfoOldPlatform(data) {
    try {
      await newPlatformPetition(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function markUpdateSeen() {
    const token = GetToken();

    await markUpdateSeenPetition(token);
  }

  async function getImage(email) {
    const token = GetToken();

    const res = await getImagePetition(token, email);
    return res.image;
  }

  async function sendValidationEmail(token, email, randomCode) {
    return await validationEmail(token, email, randomCode);
  }

  async function isActivatedEmail(payload) {
    await isActivatedEmailPetition.isActivatedEmail(payload);
  }

  async function isActivatedAllEmail(payload, token) {
    await isActivatedAllEmailPetition.isActivatedAllEmail(payload, token);
  }

  async function deleteValidationCode(email) {
    await deleteCode(email);
  }

  async function checkCode(code, email) {
    try {
      return await checkingUserCode(code, email);
    } catch (e) {
      return e;
    }
  }

  return {
    getUser: Get,
    isUserLogged,
    loginUserByEmail: login,
    logout,
    getToken: GetToken,
    changeLanguage,
    setLanguageToUserDefault,
    modifyUserItself,
    markUpdateSeen,
    getUserByToken,
    changePassword,
    sendUserInfoOldPlatform,
    getImage,
    modifyMetrics,
    modifyNotificationEmails,
    getUpdatedUser,
    sendValidationEmail,
    deleteNotificationEmails,
    isActivatedEmail,
    isActivatedAllEmail,
    changeUserLanguage,
    getUserToShareDevices,
    checkCode,
    deleteValidationCode,
  };
};

module.exports = UserSingleton();
