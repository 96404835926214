const PetitionError = require('../../petitionError');
const { getAnonymous } = require('../../httpRequester');

class GetFramesForLiveTempException extends PetitionError {
  constructor(code) {
    super('Get Frames For Live Temp', code);
  }
}

async function getFramesForLiveTemp(device_sn, token) {
  // const timezoneOffset = new Date().getTimezoneOffset() / -60;
  const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const response = await getAnonymous(
    `devices/get_frames_for_live_temp?device_sn=${device_sn}&timezone_offset=${timezoneOffset}&token=${token}`,
  );
  // No es anónima, uso el token, pero así puedo mandarle por query el id también.

  if (response.result !== GetFramesForLiveTempException.success) {
    throw new GetFramesForLiveTempException(response.result);
  } else {
    return response.mileage; // TO DO transform
  }
}

// class GetFramesForLiveTempException extends PetitionError {
//   constructor(code) {
//     super('Get Frames For Live Temp', code);
//   }
// }

module.exports = {
  getFramesForLiveTemp,
  GetFramesForLiveTempException,
};
