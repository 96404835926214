const PetitionError = require('../../petitionError');
const { getAnonymous } = require('../../httpRequester');

class GetLocationsTodayException extends PetitionError {
  constructor(code) {
    super(' Get locations today: ', code);
  }
}

async function getLocationsTodayPetition(device_sn, token) {
  const timezone = new Date().getTimezoneOffset() / -60;

  const response = await getAnonymous(`devices/get_locations_today?device_sn=${device_sn}&token=${token}&timezone=${timezone}`);
  // No es anónima, uso el token, pero así puedo mandarle por query el id también.

  if (response.result !== GetLocationsTodayException.success) {
    throw (new GetLocationsTodayException(response.result));
  } else {
    return response.locations; // TO DO transform
  }
}

// class GetLocationsTodayException extends PetitionError {
//   constructor(code) {
//     super(' Get locations today: ', code);
//   }
// }

module.exports = {
  getLocationsTodayPetition,
  GetLocationsTodayException,
};
