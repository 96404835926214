const PetitionError = require('../../petitionError');
const { postRaw } = require('../../httpRequester');
const { downloadFile } = require('../../../utils/utils');

class GetTemperatureTicketException extends PetitionError {
  static unknownDevice = 'UNKNOWN_DEVICE';

  constructor(code) {
    super('Get Temperature Ticket', code);
  }
}

async function downloadConfig(payload, token) {
  const response = await postRaw('download/config', payload, token);

  if (response.status !== 200) {
    const result = await response.json();
    throw (new GetTemperatureTicketException(result.result));
  } else {
    downloadFile(response, payload);
  }
}

// class GetTemperatureTicketException extends PetitionError {
//   static unknownDevice = 'UNKNOWN_DEVICE';

//   constructor(code) {
//     super('Get Temperature Ticket', code);
//   }
// }

module.exports = { downloadConfig, GetTemperatureTicketException };
