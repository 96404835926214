<template>
    <div v-if="!isMobile" class="text-center">
        <v-dialog v-model="dialog" width="55%" persistent>
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ $t("user.actions.change_password") }}</span>
                    <h5 class="password_explanation">
                        {{ $t("user.actions.change_password_info") }}
                    </h5>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="passwordForm" v-model="validForm" lazy-validation>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        label="Password*"
                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append="() => (showPassword = !showPassword)"
                                        :type="showPassword ? 'password' : 'text'"
                                        required
                                        v-model="psw"
                                        v-bind:hint="$t('user.password.hint')"
                                        :rules="passwordRules"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        label=" Confirm Password*"
                                        type="password"
                                        v-model="confirmPassword"
                                        v-bind:rules="[psw === confirmPassword || $t('user.password.error.passwords_not_match')]"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6"> </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-container class="px-0" fluid>
                        <v-checkbox class="ml-4" v-model="checkbox" :label="$t('user.password.conditions')"></v-checkbox>
                    </v-container>
                    <a href="../terms" target="_blank">
                        <v-btn color="blue darken-1" text class="mx-lg-6" elevation="2">
                            <v-icon class="mr-4" dark v-bind="attrs" v-on="on"> mdi-help-circle-outline </v-icon>
                            Términos y Condiciones
                        </v-btn>
                    </a>
                    <v-btn color="blue darken-1" text @click="changePassword" class="mx-lg-6" elevation="2">
                        <v-icon>mdi-content-save</v-icon>
                        {{ this.$t("dashboard.configuration.accept") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

    <div v-else class="text-center">
        <v-dialog v-model="dialog" width="55%" persistent>
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ $t("user.actions.change_password") }}</span>
                    <h5 class="password_explanation">
                        {{ $t("user.actions.change_password_info") }}
                    </h5>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="passwordForm" v-model="validForm" lazy-validation>
                            <v-text-field
                                label="Password*"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="() => (showPassword = !showPassword)"
                                :type="showPassword ? 'password' : 'text'"
                                required
                                v-model="psw"
                                v-bind:hint="$t('user.password.hint')"
                                :rules="passwordRules"
                            ></v-text-field>
                            <v-text-field
                                label=" Confirm Password*"
                                type="password"
                                v-model="confirmPassword"
                                v-bind:rules="[psw === confirmPassword || $t('user.password.error.passwords_not_match')]"
                                required
                            ></v-text-field>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-container class="px-0" fluid>
                    <v-checkbox class="ml-4" v-model="checkbox" :label="$t('user.password.conditions')"></v-checkbox>
                </v-container>
                <a href="../terms" target="_blank">
                    <v-btn color="blue darken-1" text class="ma-2" elevation="2">
                        <v-icon class="mr-2"> mdi-help-circle-outline </v-icon>
                        Términos<br> y Condiciones
                    </v-btn>
                </a>
                <v-btn color="blue darken-1" text @click="changePassword" class="ma-5" elevation="2">
                    <v-icon>mdi-content-save</v-icon>
                    {{ this.$t("dashboard.configuration.accept") }}
                </v-btn>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import userViewModel from "../viewModels/userViewModel";
export default {
    data: () => ({
        dialog: true,
        psw: "",
        confirmPassword: "",
        validForm: false,
        showPassword: String,
        checkbox: false,
    }),

    methods: {
        async changePassword() {
            let isValid = this.$refs.passwordForm.validate();
            if (isValid && this.checkbox === true) {
                try {
                    const user = await userViewModel.getUser();
                    this.dialog = false;
                    await userViewModel.changePassword(user.email, this.psw);
                } catch (error) {
                    console.log(error);
                }
            }
        },
    },
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.mobile;
        },
        passwordRules() {
            return [
                (value) => !!value || this.$t("user.password.error.empty_password"),
                (value) => (value && value.length >= 6) || this.$t("user.password.error.invalid_password"),
            ];
        },
    },
};
</script>


<style>
.password_explanation {
    word-break: normal !important;
}
</style>