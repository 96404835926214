const PetitionError = require('../../petitionError');
const { get } = require('../../httpRequester');

class GetCompaniesException extends PetitionError {
  constructor(code) {
    super('Get Companies', code);
  }
}

async function getCompanies(token) { // TODO add security
  const response = await get('companies/get_companies', token);

  if (response.result !== GetCompaniesException.success) {
    throw (new GetCompaniesException(response.result));
  } else {
    return response.companies; // TO DO transform
  }
}

// class GetCompaniesException extends PetitionError {
//   constructor(code) {
//     super('Get Companies', code);
//   }
// }

module.exports = {
  getCompanies,
  GetCompaniesException,
};
