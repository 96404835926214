const PetitionError = require('../../petitionError');
const { del } = require('../../httpRequester');

class DeleteCompanyException extends PetitionError {
  static unknownCompany = 'UNKNOWN_COMPANY';

  static userSharesDevices = 'USER_SHARES_DEVICES';

  constructor(code) {
    super('Delete Company', code);
  }
}

async function deleteCompany(id, token, mode) { // TODO add security
  const params = {
    token,
    id,
    mode,
  };

  const response = await del('companies/delete_company', params);

  if (response.result !== DeleteCompanyException.success) {
    throw (new DeleteCompanyException(response.result));
  }
}

// class DeleteCompanyException extends PetitionError {
//   static unknownCompany = 'UNKNOWN_COMPANY';

//   static userSharesDevices = 'USER_SHARES_DEVICES';

//   constructor(code) {
//     super('Delete Company', code);
//   }
// }

module.exports = {
  deleteCompany,
  DeleteCompanyException,
};
