const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class ModifyDeviceByAdminException extends PetitionError {
  static serialNumberAlreadyInDevice = 'SERIALNUMBER_ALREADY_IN_DEVICE';

  constructor(code) {
    super('Modify device by admin', code);
  }
}

async function modifyDeviceByAdmin(token, payload) {
  const response = await post('devices/modify_device', payload, token);

  if (response.result !== ModifyDeviceByAdminException.success) {
    throw new ModifyDeviceByAdminException(response.result);
  } else {
    return response.device; // TODO transform
  }
}

module.exports = {
  modifyDeviceByAdmin,
  ModifyDeviceByAdminException,
};
