<template>
  <div class="ml-5">
    <div class="user-configuration-container">
      <div class="user-configuration-form-title">
        <h1 class="my-5 text-center">{{ $t('user.atribute.notification_validation') }}</h1>
      </div>
      <v-row justify="center">
        <v-col :cols="isMobile ? 6 : 2">
          <v-btn
            :disabled="telegramNotifications"
            x-small
            color="blue"
            class="d-inline text-white mb-5"
            @click="redirectToTelegram">
            <font-awesome-icon :icon="['fab', 'telegram']" class="mr-2 fa-xl" color="white" />
            <span class="white--text">{{ $t('user.atribute.activate_telegram') }}</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col :cols="isMobile ? 8 : 3">
          <v-text-field
            :dark="theme == 'dark'"
            dense
            outlined
            class="rounded-lg"
            type="text"
            :disabled="maxNotificationEmails"
            :label="$t('user.atribute.notification_emails')"
            v-model="notificationEmail"
            :rules="notificationEmailsRules">
          </v-text-field>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col :cols="isMobile ? 8 : 3">
          <v-switch
            :dark="theme == 'dark'"
            :label="switchActivatedEmail ? $t('user.atribute.activated_emails') : $t('user.atribute.deactivated_emails')"
            v-if="user?.validationEmail?.length > 0 && user.validationEmail?.some((i) => i.is_validate === true)"
            v-model="switchActivatedEmail"
            @click.native.prevent="activateEmail"></v-switch>
        </v-col>
      </v-row>

      <v-list-item :dark="theme == 'dark'" v-for="(item, i) in user?.validationEmail" :key="i">
        <v-row justify="center">
          <v-col :cols="isMobile ? 10 : 4">
            <v-list-item-title style="font-size: smaller" v-if="typeof item == 'string'" v-text="item"> </v-list-item-title>
            <v-list-item-title
              :style="isMobile ? { 'font-size': 'smaller' } : {}"
              v-if="typeof item == 'object'"
              v-text="item.email">
            </v-list-item-title>
          </v-col>
          <v-col cols="2" style="display: flex; justify-content: flex-end">
            <span
              v-if="item.is_validate == false"
              :style="{ color: theme === 'dark' ? 'white' : 'black', 'font-size': isMobile ? 'smaller' : '' }"
              >{{ $t('user.atribute.pending') }}</span
            >
            <v-icon color="red" @click="deleteEmail(item, i)"> mdi-delete </v-icon>
          </v-col>
        </v-row>
      </v-list-item>

      <div class="mt-5 ml-5">
        <v-row justify="center" class="user-configuration-buttons">
          <v-col :cols="isMobile ? 4 : 2">
            <v-btn small color="red" class="d-inline ml-2 text-white" @click="dashboardView">
              <v-icon class="white--text">mdi-exit-to-app</v-icon>
              <span class="white--text">{{ $t('general.form.exit') }}</span>
            </v-btn>
          </v-col>

          <v-col :cols="isMobile ? 4 : 2">
            <template>
              <div class="text-center">
                <v-bottom-sheet v-model="sheet" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      v-if="theme === 'dark'"
                      :dark="theme == 'dark'"
                      :disabled="!notificationEmail"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="save">
                      <v-icon>mdi-content-save</v-icon>{{ $t('dashboard.tickets.send') }}
                    </v-btn>
                    <v-btn v-else small :disabled="!notificationEmail" color="primary" v-bind="attrs" v-on="on" @click="save">
                      <v-icon>mdi-content-save</v-icon>{{ $t('dashboard.tickets.send') }}
                    </v-btn>
                  </template>
                  <v-sheet style="background-color: rgb(255, 255, 255)" v-if="handler" class="text-center" height="200px">
                    <v-btn class="mt-6" text color="error" @click="sheet = !sheet"> {{ $t('general.form.close') }} </v-btn>
                    <div style="color: rgb(133, 133, 207)" class="py-3">
                      {{ $t('user.atribute.notice_email') }}
                    </div>
                  </v-sheet>
                  <v-sheet style="background-color: rgb(238, 237, 237)" v-else-if="isnotEmail" class="text-center" height="200px">
                    <v-btn class="mt-6" text color="error" @click="sheet = !sheet"> {{ $t('general.form.close') }} </v-btn>
                    <div style="color: red" class="py-3">{{ $t('user.atribute.valid_email') }}</div>
                  </v-sheet>
                  <v-sheet style="background-color: rgb(238, 237, 237)" v-else class="text-center" height="200px">
                    <v-btn class="mt-6" text color="error" @click="sheet = !sheet"> {{ $t('general.form.close') }} </v-btn>
                    <div style="color: red" class="py-3">{{ $t('user.atribute.email_in_use') }}</div>
                  </v-sheet>
                </v-bottom-sheet>
              </div>
            </template>
          </v-col>
        </v-row>
      </div>
    </div>
    <Confirm ref="confirm"></Confirm>
    <Confirm ref="deleteConfirm"></Confirm>
  </div>
</template>
<script>
import router from '../../../plugins/router/index';
import { deleteNotificationEmails, getUser, getUserByToken, sendValidationEmail } from '../../../viewModels/userViewModel.js';
import { validEmail } from '../../../utils/utils';
import { eventBus } from '../../.././main';
const ls = require('localstorage-slim');
import Confirm from '../../../components/confirm.vue';
import { isActivatedAllEmail } from '../../../viewModels/userViewModel';

export default {
  components: { Confirm },
  data: () => ({
    randomCode: '',
    switchActivatedEmail: false,
    user: null,
    theme: ls.get('theme') ?? 'ligth',
    isnotEmail: false,
    handler: true,
    sheet: false,
    notificationEmail: '',
    token: '',
    telegramDeepLink: process.env.VUE_APP_TELEGRAM_BOT,
    telegramNotifications: false,
  }),

  methods: {
    async redirectToTelegram() {
      const isValidateTelegram = await this.$refs.confirm.open(
        this.$t('general.form.activate_telegram'),
        this.$t('general.form.telegram_text'),
        {
          color: 'green',
        },
      );
      if (isValidateTelegram) {
        window.open(`${this.telegramDeepLink}?start=${this.user.userId}`, '_blank');
      } else {
        return;
      }
    },
    codeGenerator() {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let codeString = '';
      this.randomCode = '';
      for (let i = 0; i < 6; i++) {
        codeString += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      this.randomCode = codeString;
      return codeString;
    },
    async activateEmail() {
      let payload = {
        user_email: this.user.email,
        switchValue: this.switchActivatedEmail,
      };
      if (this.switchActivatedEmail) {
        this.switchActivatedEmail = false;
        const isValidate = await this.$refs.confirm.open(
          this.$t('general.form.email_activated'),
          this.$t('general.form.activate_email'),
          {
            color: 'green',
          },
        );
        if (isValidate) {
          this.switchActivatedEmail = true;
          await isActivatedAllEmail(payload, this.token);
        }
      } else {
        this.switchActivatedEmail = true;
        const isValidateToActivate = await this.$refs.confirm.open(
          this.$t('general.form.email_deactivated'),
          this.$t('general.form.deactivate_email'),
          {
            color: 'red',
          },
        );
        if (isValidateToActivate) {
          this.switchActivatedEmail = false;
          await isActivatedAllEmail(payload, this.token);
        }
      }
    },
    async save() {
      let res;

      if (!validEmail(this.notificationEmail)) {
        this.isnotEmail = true;
        this.handler = false;
        return;
      }

      if (
        validEmail(this.notificationEmail) &&
        !this.user?.validationEmail?.some((item) => item.email === this.notificationEmail)
      ) {
        this.isnotEmail = false;
        this.handler = true;

        if (!this.user.validationEmail) this.user.validationEmail = [];
        this.user?.validationEmail.push({ email: this.notificationEmail, is_validate: false });

        this.codeGenerator();

        //SEND EMAIL
        res = await sendValidationEmail(this.token, this.notificationEmail, this.randomCode);

        //VUEX
        // this.$store.dispatch('setNotificationsEmails', { email: this.notificationEmail, pending: true });
        // this.$store.commit('RESET_NOTIFICATIONS'); //------- LIMPIAR  STORE!!!!----------------------------------
        this.notificationEmail = '';
      } else {
        this.isnotEmail = false;
        this.handler = false;
      }
    },

    deleteEmail: async function (item, index) {
      if (
        await this.$refs.deleteConfirm.open(
          this.$t('user.form.delete_notification_email'),
          this.$t('reports.programmed.delete_info'),
          {
            color: 'red',
          },
        )
      ) {
        this.user?.validationEmail.splice(index, 1);
        await deleteNotificationEmails(this.email, item.email || item);
      }
    },
    dashboardView: function () {
      router.push({ name: 'dashboard' });
    },
  },
  computed: {
    isDarkMode() {
      this.theme = ls.get('theme');

      return this.theme;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    maxNotificationEmails() {
      return this.user?.validationEmail?.length >= 5;
    },
    notificationEmailsRules() {
      return [
        (value) => {
          if (value) return (!!value && validEmail(value)) || this.$t('general.error.not_valid_email');
          else return true;
        },
      ];
    },
  },

  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });

    this.user = await getUser();
    this.email = this.user.email;
    this.token = this.user.token;

    this.switchActivatedEmail = this.user.validationEmail?.some((i) => i.is_activate == true);
    this.telegramNotifications = this.user.restrictions.features.telegram_notifications;
    //VUEX
    // const emailsPendings = this.$store.state.notificationsEmails;
    // emailsPendings.map((i) => {
    //   this.notificationsEmails.push(i);
    // });
  },
};
</script>
