<template>
  <v-dialog
    scrollable
    v-model="isActive"
    max-width="55rem"
    @click:outside="cancelRoutes"
    @keydown.esc="cancelRoutes"
    class="ticket-view-modal">
    <v-card class="ticket-view-card">
      <v-card-title color="primary" class="ticket-view-modal-title headline" style="justify-content: space-between">
        <span class="ticket-view-modal-title-text"> {{ $t('dashboard.device_card.routes') }} </span>
        <span @click="cancelRoutes" class="ticket-view-modal-title-btn">
          <font-awesome-icon style="color: red; cursor: pointer" icon="fa-solid fa-circle-xmark" class="close-ticket-promp" />
        </span>
      </v-card-title>
      <v-card-title color="primary" class="caption pt-0">
        S/N: {{ device.device_sn }}
        <template v-if="Number(device.device_sn) !== Number(device.alias)"> - {{ device.alias }} </template>
      </v-card-title>
      <v-card-title color="primary">{{ $t('dashboard.device_card.modal_routes') }}</v-card-title>

      <v-divider class="py-1 mx-2" style="border-color: #607d8b"></v-divider>
      <v-card-text class="ticket-view-card-text">
        <v-form class="ticket-view-form" ref="ticketForm" lazy-validation>
          <v-row class="ticket-view-row">
            <v-col class="ticket-calendar-promp mb-2">
              <v-toolbar class="ticket-calendar-toolbar my-1" dense rounded elevation="2">
                <v-icon color="primary" class="icon-calendar-modal"> mdi-calendar-range </v-icon>
                <v-toolbar-title v-if="dates.length == 0" class="text-calendar-modal text-sm-body-2 mx-2">
                  <span class="text-calendar-modal-title">
                    {{ $t('reports.select_a_date') }}
                  </span>
                </v-toolbar-title>
                <v-toolbar-title v-else class="text-sm-body-2 mx-2">
                  <strong>
                    <span>
                      {{ new Date(dates[0]) > new Date(dates[1]) == true ? formatDate(dates[1]) : formatDate(dates[0]) }}
                    </span>
                  </strong>
                  <span class="px-2">
                    {{ $t('general.form.until') }}
                  </span>
                  <strong>
                    <span>
                      {{ new Date(dates[0]) < new Date(dates[1]) == false ? formatDate(dates[0]) : formatDate(dates[1]) }}
                    </span>
                  </strong>
                  <span>
                    {{ howManyDaysSelected() }}
                  </span>
                </v-toolbar-title>
              </v-toolbar>
              <v-date-picker
                class="ticket-calendar-picker"
                :max="new Date().toISOString().substr(0, 10)"
                :show-current="new Date().toISOString().substr(0, 10)"
                color="primary"
                show-adjacent-months
                full-width
                no-title
                first-day-of-week="1"
                elevation="2"
                v-model="dates"
                range
                required>
              </v-date-picker>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-form>
        <v-container class="text-md-center" style="display: flex; justify-content: space-around">
          <v-btn class="mx-lg-6" color="primary" elevation="2" x-large @click="showRoute"
            ><v-icon>mdi-truck-outline </v-icon> {{ $t('dashboard.device_card.see_routes') }}</v-btn
          >
          <v-btn class="mx-lg-6" color="error" elevation="2" x-large @click="cancelRoutes"
            ><v-icon>mdi-cancel</v-icon>{{ this.$t('dashboard.configuration.cancel') }}</v-btn
          >
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<style>
.title {
  font-size: large;
}
</style>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    device: {
      type: Object,
    },
  },
  data: () => ({
    frigoHours: null,
    dates: [],
  }),
  computed: {
    mileageRules() {
      return [(value) => Number(value) <= 999999999 || this.$t('dashboard.configuration.max_mileage')];
    },
    getEngineHours() {
      if (this.device) {
        if (this.device.last_frames) {
          if (this.device.last_frames.frame_frigo) {
            return (this.device.last_frames.frame_frigo.engine_time.offset_applied / 60).toFixed(0);
          }
        }
      }
    },
  },

  methods: {
    howManyDaysSelected: function () {
      if (!this.dates[1]) {
        return '';
      }
      const oneDay = 24 * 60 * 60 * 1000;
      const firstDate = new Date(this.dates[0]);
      const secondDate = new Date(this.dates[1]);

      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

      return `(${diffDays + 1} ${this.$t('reports.days')})`;
    },
    formatDate: function (date) {
      if (!date) {
        return '. . .';
      }

      let months = [
        this.$t('general.months.january'),
        this.$t('general.months.february'),
        this.$t('general.months.march'),
        this.$t('general.months.april'),
        this.$t('general.months.may'),
        this.$t('general.months.june'),
        this.$t('general.months.july'),
        this.$t('general.months.august'),
        this.$t('general.months.september'),
        this.$t('general.months.october'),
        this.$t('general.months.november'),
        this.$t('general.months.december'),
      ];
      let splitedDate = date.split('-');
      let formatedDate = `${splitedDate[2]} ${this.$t('general.form.of')} ${months[new Date(date).getMonth()]} ${this.$t(
        'general.form.of',
      )} ${splitedDate[0]}`;
      return formatedDate;
    },
    cancelRoutes: function () {
      this.dates = [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      ];
      this.$emit('cancel-routes');
    },

    showRoute() {
      let correctDayFormat = [];

      this.dates[0] > this.dates[1] == true ? correctDayFormat.push(this.dates[1]) : correctDayFormat.push(this.dates[0]);

      this.dates[0] < this.dates[1] == false ? correctDayFormat.push(this.dates[0]) : correctDayFormat.push(this.dates[1]);

      this.$emit('showRoute', correctDayFormat);
      this.$emit('cancel-routes');
      this.dates = [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      ];
    },
  },

  mounted: async function () {
    this.dates = [
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    ];
  },
};
</script>
