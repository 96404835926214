<template>
  <v-container class="pa-0">
    <v-row class="breadcrumbs-row-create-user">
      <v-col>
        <div class="breadcrumbs-container" style="margin: auto">
          <v-row style="justify-content: center; text-align: center">
            <h1>{{ $t('user.form.new_user') }}</h1>
          </v-row>
          <v-row class="breadcrumbs" style="justify-content: center">
            <v-breadcrumbs :items="items" class="pl-0">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                  {{ item.text.toUpperCase() }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <div class="container-create-user" style="max-width: 80%; margin-left: auto !important; margin-right: auto !important">
      <h2 class="d-flex justify-start my-2">
        {{ $t('user.form.details') }}
      </h2>

      <UserDataForm ref="userData" forCompany="0"></UserDataForm>

      <v-row class="buttoms-edit-user-mobile d-flex justify-center mt-10">
        <v-btn color="primary" class="d-flex justify-space-between mx-15" @click="createUser">
          <v-icon>mdi-content-save</v-icon>{{ $t('general.form.save') }}
        </v-btn>

        <v-btn color="red" class="d-flex justify-space-between mx-15" @click="goBack">
          <v-icon class="white--text mr-1">mdi-exit-to-app</v-icon>
          <span class="white--text">{{ $t('general.form.exit') }}</span>
        </v-btn>
      </v-row>

      <ErrorDialog
        :isActive="errorDialog"
        v-bind:tittleText="$t('general.dialog.error.title')"
        :messageText="errorText"
        v-bind:acceptButtonText="$t('general.dialog.error.accept_button')"
        v-on:accept-dialog="errorDialog = false">
      </ErrorDialog>
    </div>
  </v-container>
</template>

<script>
import router from '../../../../plugins/router/index';
import UserDataForm from '../../../../components/forms/userDataForm/userDataForm.vue';
import { createUser } from '../../../../viewModels/adminViewModel.js';
import { CreateUserException } from '../../../../server/petitions/users/createUser';
import ErrorDialog from '../../../../components/errorDialog.vue';

export default {
  components: {
    UserDataForm,
    ErrorDialog,
  },
  data: () => ({
    errorDialog: false,
    errorText: '',
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '../../../dashboard/temperatures',
      },
      {
        text: 'Usuarios',
        disabled: false,
        href: '/admin/users',
      },
      {
        text: 'Crear usuario',
        disabled: true,
        href: 'admin/users/create',
      },
    ],
  }),
  methods: {
    goBack: function () {
      router.push({ name: 'users' });
    },
    createUser: async function () {
      try {
        var userDataF = this.$refs.userData;
        if (userDataF.devices.length == 0) {
          this.errorDialog = true;
          this.errorText = this.$t('general.dialog.error.no_devices');
          return;
        }
        if (userDataF.validate()) {
          let name = userDataF.name;
          let company_id = userDataF.selectedCompany;
          let email = userDataF.email;
          let notification_emails = userDataF.notificationsEmails;
          let phone = userDataF.phone;
          let password = userDataF.email;
          let notes = userDataF.notes;
          let devices_id = userDataF.devices;
          let roles = userDataF.selectedRoles;
          let platform = userDataF.selectedPlatform;
          let unit = userDataF.selectedUnit;
          let restrictions = userDataF.selectedRestrictions;
          let restricted = userDataF.restricted;
          let max_clients = userDataF.maxClients;

          await createUser(
            name,
            company_id,
            email,
            notification_emails,
            phone,
            password,
            notes,
            devices_id,
            roles,
            platform,
            unit,
            restrictions,
            restricted,
            max_clients,
          );
          this.goBack();
        }
      } catch (e) {
        switch (e.code) {
          case CreateUserException.unknownError:
          case CreateUserException.incorrectParameters:
          case CreateUserException.invalidToken:
          case CreateUserException.expiredToken:
            console.error(e);
            this.errorText = this.$t('general.error.unexpected_error');
            this.errorDialog = true;
            break;
          case CreateUserException.emailAlreadyInUser:
            this.errorText = this.$t('user.add_modify.error.email_already_in_use');
            this.errorDialog = true;
            break;
          default:
            console.error('CATCH ERROR:', e);
        }
      }
    },
  },
};
</script>
