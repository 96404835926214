<template>
  <div class="mt-5 mx-5">
    <v-row class="mx-2 row-device-container">
      <v-col class="d-flex justify-start pb-0">
        <div class="breadcrumbs-container ml-2">
          <v-row>
            <h1>
              {{ $t('device.form.devices') }}
            </h1>
          </v-row>
          <v-row class="breadcrumbs">
            <v-breadcrumbs :items="items" class="pl-0">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                  {{ item.text.toUpperCase() }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-row>
        </div>
      </v-col>

      <v-col class="btn-new-device">
        <v-btn color="primary" class="float-right" @click="createDevice">
          <v-icon class="mr-1">mdi-plus</v-icon>
          {{ $t('device.form.new_device') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="row-device-table mt-0">
      <v-col class="col-device-table pt-0">
        <v-data-table
          data-cy="devicesTable"
          :headers="devicesTableHeader"
          :items="devicesTableData"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          class="device-table elevation-1 ma-4 mt-0"
          :loading="isTableLoading"
          :search="search"
          v-bind:loading-text="$t('general.table.loading')"
          @click:row="editDevice"
          @contextmenu:row="editDeviceRight">
          <template v-slot:top class="search-device-template">
            <v-text-field
              style="width: 100%"
              autofocus
              v-model="search"
              v-bind:label="$t('device.form.search_device')"
              class="search-device-panel"
              prepend-inner-icon=" ">
            </v-text-field>
          </template>
          <template v-slot:[`item.device_data_from_frame.iccid`]="{ item }">
            <div class="icon-table-edit">
              <a
                target="_blank"
                :href="
                  'https://m2mmanager.matooma.com/es/home/parc-management/devices?simcardIccid=' +
                  item.device_data_from_frame.iccid
                "
                class="expansion-panel-link"
                style="cursor: pointer">
                {{ item.device_data_from_frame.iccid }}
              </a>
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="icon-table-edit">
              <v-icon medium color="error" @click.stop="openDialog(item)"> mdi-delete </v-icon>
            </div>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            :dark="theme === 'dark'"
            :style="{
              color: theme === 'dark' ? 'white !important' : '',
            }"
            v-model="page"
            :length="pageCount"></v-pagination>
        </div>
      </v-col>
    </v-row>

    <ConfirmationDialog
      :isActive="deleteDialog"
      v-on:cancel-dialog="closeDialog"
      v-on:accept-dialog="deleteDevice"
      v-bind:tittleText="$t('general.dialog.confirmation.title')"
      :messageText="dialogText"
      v-bind:cancelButtonText="$t('general.dialog.confirmation.button.cancel')"
      v-bind:acceptButtonText="$t('general.dialog.confirmation.button.accept_delete')">
    </ConfirmationDialog>

    <ErrorDialog
      :isActive="errorDialog"
      v-bind:tittleText="$t('general.dialog.error.title')"
      :messageText="errorText"
      v-bind:acceptButtonText="$t('general.dialog.error.accept_button')"
      v-on:accept-dialog="errorDialog = false">
    </ErrorDialog>
  </div>
</template>

<script>
import { getAllDevices, deleteDevice, getCompany, getDevice } from '../../../viewModels/adminViewModel.js';
import { GetAllDevicesException } from '../../../server/petitions/devices/getAllDevices';
import ConfirmationDialog from '../../../components/confirmationDialog.vue';
import ErrorDialog from '../../../components/errorDialog.vue';
import router from '../../../plugins/router/index';
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';

export default {
  components: {
    ConfirmationDialog,
    ErrorDialog,
  },

  data: () => ({
    theme: ls.get('theme') ?? 'ligth',
    page: 1,
    pageCount: 0,
    itemsPerPage: 8,
    isTableLoading: true,
    search: '',
    devicesTableData: [],
    deleteDialog: false,
    dialogText: '',
    deviceToDelete: null,
    errorDialog: false,
    errorText: '',
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '../dashboard/temperatures',
      },
      {
        text: 'Dispositivos',
        disabled: true,
        href: 'admin/devices',
      },
    ],
  }),

  methods: {
    editDevice: async function (e) {
      const device = await getDevice(e.device_sn);
      router.push({
        name: 'editDevice',
        params: { device_sn: e.device_sn, deviceData: device },
      });
    },
    editDeviceRight: async function (e, i) {
      e.preventDefault();
      const device = await getDevice(i.item.device_sn);
      let routeData = router.resolve({ name: 'editDevice', params: { device_sn: i.item.device_sn, deviceData: device } });
      window.open(routeData.href, '_blank');
    },
    editDevice_BACKUP: function (e) {
      router.push({
        name: 'editDevice',
        params: { deviceId: e._id, deviceData: e },
      });
    },
    createDevice: function () {
      router.push({ name: 'createDevice' });
    },
    openDialog: function (e) {
      this.dialogText = this.$t('device.delete.warning.device_will_delete', {
        device_sn: e.device_sn,
      });

      this.deviceToDelete = e;
      this.deleteDialog = true;
    },

    closeDialog: function () {
      this.deleteDialog = false;
      this.deviceToDelete = null;
    },
    deleteDevice: async function () {
      // try {
      //   await deleteDevice(this.deviceToDelete._id);
      //   this.deviceToDelete = null;
      //   this.deleteDialog = false;
      // } catch (e) {
      //   switch (e.code) {
      //     case DeleteUserException.unknownError:
      //     case DeleteUserException.incorrectParameters:
      //     case DeleteUserException.invalidToken:
      //     case DeleteUserException.expiredToken:
      //       console.error(e);
      //       this.errorText = this.$t("general.error.unexpected_error");
      //       this.errorDialog = true;
      //       break;
      //     default:
      //       console.error("CATCH ERROR:", e);
      //   }
      // }
    },
  },

  mounted: async function () {
    try {
      eventBus.$on('changeTheme', (theme) => {
        this.theme = theme;
      });
      this.devicesTableData = await getAllDevices();
      this.isTableLoading = false;
      for (let i = 0; i < this.devicesTableData.length; i++) {
        if (this.devicesTableData[i].companies_related) {
          //Este if no debería de hacer falta
          for (let company of this.devicesTableData[i].companies_related) {
            let userCompany = await getCompany(company.company_id);
            if (company.type_name == 'distributor') {
              this.devicesTableData[i].distributor = userCompany.name;
            }
            if (company.type_name == 'company') {
              this.devicesTableData[i].company = userCompany.name;
            }
          }
        }
      }
    } catch (e) {
      switch (e.code) {
        case GetAllDevicesException.unknownError:
        case GetAllDevicesException.incorrectParameters:
        case GetAllDevicesException.invalidToken:
        case GetAllDevicesException.expiredToken:
          console.error(e);
          this.errorText = this.$t('general.error.unexpected_error');
          this.errorDialog = true;
          break;
        default:
          console.error('CATCH ERROR:', e);
      }
    }
  },

  computed: {
    devicesTableHeader() {
      return [
        {
          text: this.$t('device.atribute.serial_number'),
          value: 'device_sn',
          width: '6rem',
          divider: true,
        },
        {
          text: this.$t('device.atribute.model'),
          value: 'model',
          width: '5rem',
          divider: true,
        },
        {
          text: this.$t('device.atribute.distributor'),
          value: 'distributor',
          width: '5rem',
          divider: true,
        },
        {
          text: this.$t('device.atribute.company'),
          value: 'company',
          width: '8rem',
          divider: true,
        },
        {
          text: this.$t('device.atribute.carplate'),
          value: 'device_data_from_frame.carplate',
          width: '6rem',
          divider: true,
        },
        {
          text: this.$t('device.atribute.key'),
          value: 'password',
          width: '4rem',
          divider: true,
        },
        {
          text: this.$t('device.atribute.notes'),
          value: 'notes',
          width: '10rem',
          divider: true,
        },
        {
          text: 'ICCID',
          value: 'iccid',
          width: '12rem',
          divider: true,
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          width: '2rem',
          divider: true,
          sortable: false,
        },
      ];
    },
  },
};
</script>
