const PetitionError = require('../../petitionError');
const { getRaw } = require('../../httpRequester');

class GetAllCertificateException extends PetitionError {
  constructor(code) {
    super(' ERROR : Error when getting the certificate', code);
  }
}

async function getAllCertificate(token, userId) {
  const data = await getRaw(`devices/${userId}/certificate`, token);
  const response = await data.json();

  if (response.result !== GetAllCertificateException.success) {
    throw new GetAllCertificateException(response.result);
  } else {
    return response.certificate; // TO DO transform
  }
}

module.exports = {
  getAllCertificate,
  GetAllCertificateException,
};
