const PetitionError = require('../../petitionError');
const { postRaw } = require('../../httpRequester');

class SendTemperatureTicketException extends PetitionError {
  static unknownDevice = 'UNKNOWN_DEVICE';

  static noDataInDate = 'NO_DATA_IN_DATE';

  constructor(code) {
    super('Get Temperature Ticket', code);
  }
}

async function sendTemperatureTicket(
  email,
  date_from,
  date_to,
  temperature_interval,
  device_sn,
  carplate,
  cif,
  user,
  token,
  user_email,
  probes,
) {
  const dateTimeFormat = Intl.DateTimeFormat().resolvedOptions();
  const payload = {
    email,
    date_from,
    date_to,
    temperature_interval,
    device_sn,
    carplate,
    cif,
    user,
    timezone: new Date().getTimezoneOffset() / 60,
    user_email,
    probes,
    dateTimeFormat,
  };
  const response = await postRaw('reports/send_temperature_ticket', payload, token);

  if (response.status !== 200) {
    const result = await response.json();
    throw new SendTemperatureTicketException(result.result);
  } else {
    return response;
  }
}

module.exports = { sendTemperatureTicket, SendTemperatureTicketException };
