const PetitionError = require("../../petitionError");
const { postRaw } = require("../../httpRequester");

class ChangePasswordException extends PetitionError {
    constructor(code) {
        super("Unable to change password", code);
    }
}

async function changePasswordPetition(email, password, token) {
    const payload = { email, password };
    const response = await postRaw("users/change_password", payload, token);
    return response;
}

// class ChangePasswordException extends PetitionError {
//   constructor(code) {
//     super('Unable to change password', code);
//   }
// }

module.exports = {
    changePasswordPetition,
    ChangePasswordException,
};
