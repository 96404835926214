const PetitionError = require('../../petitionError');
const { getRaw } = require('../../httpRequester');

class AllScheduledReportsException extends PetitionError {
  static unknownDevice = 'UNKNOWN_DEVICE';

  static noDataInDate = 'NO_DATA_IN_DATE';

  constructor(code) {
    super('Get all scheduled Reports', code);
  }
}

async function getAllScheduledReports(token, email) {
  const data = await getRaw(`reports/${email}/getScheduledReports`, token);
  const response = await data.json();
  return response.result;
}

// class AllScheduledReportsException extends PetitionError {
//   static unknownDevice = 'UNKNOWN_DEVICE';

//   static noDataInDate = 'NO_DATA_IN_DATE';

//   constructor(code) {
//     super('Get all scheduled Reports', code);
//   }
// }

module.exports = {
  getAllScheduledReports,
  AllScheduledReportsException,
};
