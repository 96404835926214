<template>
  <div class="my-custom-dialog-class">
    <v-dialog
      v-if="!isMobile"
      v-model="isActive"
      :width="lengthProbes.length == 2 ? 800 : ''"
      :max-width="
        lengthProbes.length == 4 ? '73rem' : lengthProbes.length > 2 ? '70rem' : lengthProbes.length == 1 ? '40rem' : ''
      "
      scrollable
      @click:outside="cancelDialog"
      @keydown.esc="cancelDialog"
      class="ticket-view-modal">
      <v-card class="ticket-view-card">
        <v-card-title color="primary" class="ticket-view-modal-title headline" style="justify-content: space-between">
          <span class="ticket-view-modal-title-text"> {{ $t('thermography.temperature_alarm') }} </span>
          <span @click="cancelDialog" class="ticket-view-modal-title-btn">
            <font-awesome-icon style="color: red; cursor: pointer" icon="fa-solid fa-circle-xmark" class="close-ticket-promp" />
          </span>
        </v-card-title>
        <v-card-title color="primary" class="caption pt-0"> S/N: {{ device.device_sn }} </v-card-title>
        <v-divider class="mb-2"></v-divider>

        <v-card-text class="ticket-view-card-text">
          <v-form class="ticket-view-form" ref="ticketForm" v-model="formValid" lazy-validation>
            <v-row class="ticket-view-row">
              <v-col :cols="lengthProbes.length == 1 ? 5 : lengthProbes.length == 4 ? 6 : 7" class="col-selects">
                <span
                  :style="{
                    marginTop: lengthProbes.length == 1 ? '1.7em' : lengthProbes.length == 3 ? '3.9em' : '1.8em',
                  }"
                  class="d-flex ticket-row-time-description font-weight-bold">
                  {{ $t('alarms.click_to_go_alarms_view') }}
                </span>
                <a
                  style="margin-top: 1em; text-transform: uppercase"
                  @click="alarmsViewNav"
                  class="d-flex ticket-row-time-description font-weight-bold link-style">
                  {{ $t('alarms.alarms') }}
                </a>
                <v-text-field
                  dense
                  :dark="theme === 'dark'"
                  background-color="transparent"
                  maxlength="20"
                  :rules="inputRules"
                  v-model="name"
                  :label="$t('alarms.valid_name')"
                  outlined
                  autofocus
                  :style="{
                    color: theme === 'dark' ? 'white !important' : '',
                    marginTop: lengthProbes.length == 1 ? '' : '0.5em',
                  }"></v-text-field>
                <h3
                  :style="{
                    color: theme === 'dark' ? 'white !important' : '',
                    marginBottom: lengthProbes.length == 2 ? '1.2em' : '',
                    marginTop: lengthProbes.length == 2 ? '1.2em' : '',
                  }">
                  {{ $t('alarms.select_temperature_range') }}
                </h3>
                <v-row v-if="device.device_data_from_frame.probes_type">
                  <v-col
                    v-if="probe == 'temp'"
                    v-for="(probe, indexProbe) in transformedProbesType"
                    :key="indexProbe"
                    :cols="lengthProbes.length === 1 ? 12 : lengthProbes.length === 2 ? 6 : lengthProbes.length === 3 ? 4 : 6">
                    <LimitsFast
                      :ref="indexProbe"
                      :num="indexProbe"
                      :probe="indexProbe"
                      @enableButton="switchButton"
                      @disableButton="disableButton"
                      @emptyErrorRange="disableButton"
                      @limits="saveLimits"
                      @emptyErrorInterval="disableButton"></LimitsFast>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                :style="{
                  marginTop: lengthProbes.length == 1 ? '0.8em' : '',
                }"
                :cols="lengthProbes.length == 1 ? 7 : lengthProbes.length == 4 ? 6 : 5"
                class="ticket-calendar-promp mb-2">
                <v-toolbar height="80em" class="ticket-calendar-toolbar my-8" dense rounded elevation="2">
                  <v-icon color="primary" class="icon-calendar-modal"> mdi-calendar-range </v-icon>
                  <v-toolbar-title v-if="dates.length == 0" :class="isFormValid" class="text-calendar-modal text-sm-body-2 mx-2">
                    <span style="width: 100%; white-space: normal" class="text-calendar-modal-title">
                      {{ $t('reports.select_a_date') }}
                    </span>
                  </v-toolbar-title>
                  <v-toolbar-title v-else class="text-sm-body-2 mx-2" :class="isFormValid">
                    <strong>
                      <span style="width: 100%; white-space: normal">
                        {{
                          new Date(dates[0]).getTime() > new Date(dates[1]).getTime() == true
                            ? formatDate(dates[1])
                            : formatDate(dates[0])
                        }}
                      </span>
                    </strong>
                    <span>
                      {{ $t('general.form.until') }}
                    </span>
                    <strong>
                      <span style="width: 100%; white-space: normal">
                        {{
                          new Date(dates[0]).getTime() < new Date(dates[1]).getTime() == false
                            ? formatDate(dates[0])
                            : formatDate(dates[1])
                        }}
                      </span>
                    </strong>
                    <span>
                      {{ howManyDaysSelected() }}
                    </span>
                  </v-toolbar-title>
                </v-toolbar>
                <v-date-picker
                  :style="{
                    width: lengthProbes.length == 2 ? '60em' : '',
                  }"
                  :disabled="programed === 'always'"
                  class="ticket-calendar-picker"
                  :min="new Date().toISOString()"
                  :show-current="new Date().toISOString().substring(0, 10)"
                  color="primary"
                  show-adjacent-months
                  full-width
                  no-title
                  first-day-of-week="1"
                  elevation="2"
                  v-model="dates"
                  range
                  required
                  @input="validateRange">
                  <v-alert v-if="invalidDate" dismissible border="right" type="error">{{
                    $t('dashboard.tickets.rangeMaxDate')
                  }}</v-alert>
                </v-date-picker>
                <span
                  :style="{
                    marginTop: lengthProbes.length == 4 ? '5.5em' : '',
                  }"
                  class="d-flex ticket-row-time-description font-weight-bold">
                  {{ $t('alarms.select_alarm_ends') }}
                </span>
                <v-radio-group style="margin-top: -2px" :style="{ backgroundColor: 'transparent' }" v-model="programed">
                  <v-card :style="{ backgroundColor: 'transparent', padding: '0.2em' }" class="d-flex justify-center">
                    <v-radio class="small-label-radio" :label="$t('alarms.always')" value="always"></v-radio>
                    <v-radio
                      class="small-label-radio mb-1 ml-2"
                      :label="$t('alarms.select_start_end')"
                      value="selectDays"></v-radio>
                  </v-card>
                </v-radio-group>
                <span v-if="lengthProbes.length == 4" class="font-weight-bold">
                  {{ $t('alarms.select_days_active_alarm_notifications') }}
                </span>
                <v-radio-group v-if="lengthProbes.length == 4" :style="{ backgroundColor: 'transparent' }" v-model="days">
                  <v-card :style="{ backgroundColor: 'transparent' }" class="d-flex justify-center">
                    <v-radio class="small-label-radio" :label="$t('alarms.all_days_everyday')" value="everyday"></v-radio>
                    <v-radio
                      class="small-label-radio mb-1 ml-2"
                      :label="$t('alarms.monday_to_friday')"
                      value="workingDays"></v-radio>
                  </v-card>
                </v-radio-group>
                <span v-if="lengthProbes.length == 4" class="d-flex ticket-row-time-description font-weight-bold">
                  {{ $t('alarms.select_hours') }}
                </span>
                <v-toolbar
                  v-if="lengthProbes.length == 4"
                  max-width="350"
                  max-height="40"
                  :style="{ backgroundColor: 'transparent' }"
                  :dark="theme === 'dark'">
                  <v-row style="margin-top: -1.5em" class="d-flex justify-center">
                    <v-col cols="3">
                      <v-text-field :dark="theme === 'dark'" v-model="startingTime" type="time"> </v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field :dark="theme === 'dark'" v-model="endingTime" type="time"> </v-text-field>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </v-col>
            </v-row>
            <v-row v-if="lengthProbes.length !== 4">
              <v-col cols="12">
                <div
                  :style="{
                    marginTop: lengthProbes.length == 2 ? '0.8em' : '',
                  }"
                  class="d-flex justify-center">
                  <span class="font-weight-bold">
                    {{ $t('alarms.select_days_active_alarm_notifications') }}
                  </span>
                </div>
              </v-col>
              <v-col cols="12">
                <div style="margin-top: -2.5em" class="d-flex justify-center">
                  <v-radio-group :style="{ backgroundColor: 'transparent' }" v-model="days">
                    <v-card :style="{ backgroundColor: 'transparent' }" class="d-flex justify-center">
                      <v-radio class="small-label-radio" :label="$t('alarms.all_days_everyday')" value="everyday"></v-radio>
                      <v-radio
                        class="small-label-radio mb-1 ml-2"
                        :label="$t('alarms.monday_to_friday')"
                        value="workingDays"></v-radio>
                    </v-card>
                  </v-radio-group>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="lengthProbes.length !== 4" style="margin-top: -2em">
              <v-col cols="12">
                <div class="d-flex justify-center">
                  <span class="d-flex ticket-row-time-description font-weight-bold">
                    {{ $t('alarms.select_hours') }}
                  </span>
                </div>
              </v-col>
              <v-col cols="12">
                <div style="margin-top: -1.5em" class="d-flex justify-center">
                  <v-toolbar max-width="350" max-height="40" :style="{ backgroundColor: 'transparent' }" :dark="theme === 'dark'">
                    <v-row style="margin-top: -1.5em" class="d-flex justify-center">
                      <v-col cols="3">
                        <v-text-field :dark="theme === 'dark'" v-model="startingTime" type="time"> </v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field :dark="theme === 'dark'" v-model="endingTime" type="time"> </v-text-field>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div
                  :style="{
                    marginTop: lengthProbes.length == 4 ? '1.5em' : '',
                  }"
                  class="d-flex justify-center">
                  <v-btn class="btn" :disabled="filledButton" color="success" @click="create">
                    {{ $t('general.form.save') }}
                  </v-btn>
                  <v-btn class="btn" color="red" @click="cancelDialog" text> {{ $t('general.form.exit') }} </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>

      <v-snackbar v-model="snackbar" :timeout="timeout" color="success">
        {{ $t('alarms.successfully_saved') }}
        <template v-slot:action="{ attrs }">
          <v-btn color="black" text v-bind="attrs">{{ $t('dashboard.tickets.close') }}</v-btn>
        </template>
      </v-snackbar>

      <v-snackbar v-model="redSnackbar" :timeout="timeout" color="error">
        {{ $t('alarms.error_saving_alarm') }}
        <template v-slot:action="{ attrs }">
          <v-btn color="black" text v-bind="attrs"> X </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
    <v-dialog
      v-if="isMobile"
      fullscreen
      v-model="isActive"
      max-width="70%"
      scrollable
      @click:outside="cancelDialog"
      @keydown.esc="cancelDialog"
      class="ticket-view-modal">
      <v-card class="ticket-view-card">
        <v-card-title color="primary" class="ticket-view-modal-title headline" style="justify-content: space-between">
          <span style="font-size: small" class="ticket-view-modal-title-text"> {{ $t('thermography.temperature_alarm') }} </span>
          <span @click="cancelDialog" class="ticket-view-modal-title-btn">
            <font-awesome-icon style="color: red; cursor: pointer" icon="fa-solid fa-circle-xmark" class="close-ticket-promp" />
          </span>
        </v-card-title>

        <v-card-title style="font-size: small" color="primary" class="caption pt-0"> S/N: {{ device.device_sn }} </v-card-title>

        <v-divider class="mb-2"></v-divider>

        <v-card-text class="ticket-view-card-text">
          <v-form class="ticket-view-form" ref="ticketForm" v-model="formValid" lazy-validation>
            <v-row class="ticket-view-row">
              <v-col cols="12" class="col-selects">
                <span @click="alarmsViewNav" class="d-flex ticket-row-time-description font-weight-bold">
                  {{ $t('alarms.click_to_go_alarms_view') }}
                </span>
                <a
                  style="margin-top: 1em; text-transform: uppercase"
                  @click="alarmsViewNav"
                  class="d-flex ticket-row-time-description font-weight-bold link-style">
                  {{ $t('alarms.alarms') }}
                </a>
                <v-text-field
                  dense
                  background-color="transparent"
                  maxlength="20"
                  :rules="inputRules"
                  v-model="name"
                  :label="$t('alarms.valid_name')"
                  outlined
                  autofocus
                  style="margin-top: 0.5em"></v-text-field>
                <h3
                  :style="{
                    color: theme === 'dark' ? 'white !important' : '',
                    marginBottom: '1em',
                    fontSize: 'smaller',
                  }">
                  {{ $t('alarms.select_temperature_range') }}
                </h3>

                <v-row style="max-height: 22em" v-if="device.device_data_from_frame.probes_type">
                  <v-col cols="12">
                    <v-carousel hide-delimiters style="width: 100%; height: 27rem">
                      <v-carousel-item
                        v-for="(probe, indexProbe) in transformedProbesType"
                        :key="indexProbe"
                        v-if="probe == 'temp'"
                        reverse-transition="fade-transition"
                        transition="fade-transition">
                        <LimitsFast
                          @enableButton="switchButton"
                          @disableButton="disableButton"
                          @emptyErrorRange="disableButton"
                          :probe="indexProbe"
                          @limits="saveLimits"
                          :ref="indexProbe"
                          :num="indexProbe"
                          @emptyErrorInterval="disableButton">
                        </LimitsFast>
                      </v-carousel-item>
                    </v-carousel>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="ticket-calendar-promp mb-2 mt-5">
                <v-toolbar height="80em" class="ticket-calendar-toolbar my-1" dense rounded elevation="2">
                  <v-icon color="primary" class="icon-calendar-modal"> mdi-calendar-range </v-icon>
                  <v-toolbar-title v-if="dates.length == 0" :class="isFormValid" class="text-calendar-modal text-sm-body-2 mx-2">
                    <span style="width: 100%; white-space: normal" class="text-calendar-modal-title">
                      {{ $t('reports.select_a_date') }}
                    </span>
                  </v-toolbar-title>
                  <v-toolbar-title v-else class="text-sm-body-2 mx-2" :class="isFormValid">
                    <strong>
                      <span style="width: 100%; white-space: normal; font-size: small">
                        {{
                          new Date(dates[0]).getTime() > new Date(dates[1]).getTime() == true
                            ? formatDate(dates[1])
                            : formatDate(dates[0])
                        }}
                      </span>
                    </strong>
                    <span style="font-size: small">
                      {{ $t('general.form.until') }}
                    </span>
                    <strong>
                      <span style="width: 100%; white-space: normal; font-size: small">
                        {{
                          new Date(dates[0]).getTime() < new Date(dates[1]).getTime() == false
                            ? formatDate(dates[0])
                            : formatDate(dates[1])
                        }}
                      </span>
                    </strong>
                    <span style="font-size: small">
                      {{ howManyDaysSelected() }}
                    </span>
                  </v-toolbar-title>
                </v-toolbar>
                <v-date-picker
                  :disabled="programed === 'always'"
                  class="ticket-calendar-picker"
                  :min="new Date().toISOString()"
                  :show-current="new Date().toISOString().substring(0, 10)"
                  color="primary"
                  show-adjacent-months
                  full-width
                  no-title
                  first-day-of-week="1"
                  elevation="2"
                  v-model="dates"
                  range
                  required
                  @input="validateRange">
                  <v-alert v-if="invalidDate" dismissible border="right" type="error">{{
                    $t('dashboard.tickets.rangeMaxDate')
                  }}</v-alert>
                </v-date-picker>
                <span style="margin-bottom: -1em" class="d-flex ticket-row-time-description font-weight-bold">
                  {{ $t('alarms.select_alarm_ends') }}
                </span>
                <v-radio-group :style="{ backgroundColor: 'transparent' }" v-model="programed">
                  <v-card :style="{ backgroundColor: 'transparent', padding: '0.2em' }" class="d-flex justify-center">
                    <v-radio class="small-label-radio" :label="$t('alarms.always')" value="always"></v-radio>
                    <v-radio
                      class="small-label-radio mb-1 ml-2"
                      :label="$t('alarms.select_start_end')"
                      value="selectDays"></v-radio>
                  </v-card>
                </v-radio-group>
                <span
                  :style="lengthProbes.length == 1 ? 'margin-top: 2.9em' : ''"
                  style="margin-bottom: -1em"
                  class="d-flex ticket-row-time-description font-weight-bold">
                  {{ $t('alarms.select_days_active_alarm_notifications') }}
                </span>
                <v-radio-group :style="{ backgroundColor: 'transparent' }" v-model="days">
                  <v-card :style="{ backgroundColor: 'transparent' }" class="d-flex justify-center">
                    <v-radio class="small-label-radio" :label="$t('alarms.all_days_everyday')" value="everyday"></v-radio>
                    <v-radio
                      class="small-label-radio mb-1 ml-2"
                      :label="$t('alarms.monday_to_friday')"
                      value="workingDays"></v-radio>
                  </v-card>
                </v-radio-group>
                <span style="margin-top: 1em" class="d-flex ticket-row-time-description font-weight-bold">
                  {{ $t('alarms.select_hours') }}
                </span>
                <v-toolbar :style="{ backgroundColor: 'transparent' }" :dark="theme === 'dark'">
                  <v-row class="d-flex justify-space-around mt-3">
                    <v-col cols="4">
                      <v-text-field :dark="theme === 'dark'" v-model="startingTime" type="time"> </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field :dark="theme === 'dark'" v-model="endingTime" type="time"> </v-text-field>
                    </v-col>
                  </v-row>
                </v-toolbar>
                <div v-if="lengthProbes.length !== 1" class="mt-4 d-flex justify-center">
                  <v-btn class="btn" :disabled="filledButton" color="success" @click="create">
                    {{ $t('general.form.save') }}
                  </v-btn>
                  <v-btn class="btn" color="red" @click="cancelDialog" text> {{ $t('general.form.exit') }} </v-btn>
                </div>
              </v-col>
            </v-row>

            <div v-if="lengthProbes.length == 1" class="mt-4 d-flex justify-center">
              <v-btn class="btn" :disabled="filledButton" color="success" @click="create">
                {{ $t('general.form.save') }}
              </v-btn>
              <v-btn class="btn" color="red" @click="cancelDialog" text> {{ $t('general.form.exit') }} </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>

      <v-snackbar v-model="snackbar" :timeout="timeout" color="success">
        {{ $t('alarms.successfully_saved') }}
        <template v-slot:action="{ attrs }">
          <v-btn color="black" text v-bind="attrs">{{ $t('dashboard.tickets.close') }}</v-btn>
        </template>
      </v-snackbar>

      <v-snackbar v-model="redSnackbar" :timeout="timeout" color="error">
        {{ $t('alarms.error_saving_alarm') }}
        <template v-slot:action="{ attrs }">
          <v-btn color="black" text v-bind="attrs"> X </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
  </div>
</template>

<script>
import router from '../../../plugins/router/index';
import { getUser } from '../../../viewModels/userViewModel';
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';
import { validateDateRange } from '../../../utils/utils';
import LimitsFast from '../../alarms/alarmLimitsFast.vue';
import { saveAlarms } from '../../../viewModels/alarmsViewModel';

export default {
  components: {
    LimitsFast,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    device: {
      type: Object,
    },
  },
  data: () => ({
    programed: 'always',
    lengthProbes: [],
    activeButton: false,
    weekday: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
    workdays: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
    days: 'everyday',
    maxNumberOfSensors: 8,
    name: '',
    user: null,
    probes_config: {},
    theme: ls.get('theme') ?? 'ligth',
    filledButton: true,
    interval: 10,
    formValid: false,
    isFormValid: '',
    dates: [],
    startingTime: '00:00',
    endingTime: '23:59',
    email: '',
    timeout: 4000,
    snackbar: false,
    redSnackbar: false,
    invalidDate: false,
  }),
  methods: {
    create: async function () {
      let arrayDeviceSn = [];
      arrayDeviceSn.push(this.device.device_sn);
      let alarms = {
        type: 'temp',
        probes_config: this.probes_config,
        mileage: null,
        selected_days: this.days,
        start_day: new Date(this.dates.sort()[0]),
        end_day: new Date(new Date(this.dates[1]).setHours(23, 59, 0, 0)),
        device_sn: arrayDeviceSn,
        email: this.user.email,
        name: this.name,
        interval: this.interval,
        frigo: null,
        notification_emails: this.user.notificationsEmails,
        locale: this.user.locale,
        platform: this.user.platform,
        startingTime: this.startingTime,
        endingTime: this.endingTime,
      };

      if (this.days == 'everyday') {
        alarms.selected_days = this.weekday;
      }

      if (this.days == 'workingDays') {
        alarms.selected_days = this.workdays;
      }

      if (this.programed == 'always') {
        alarms.start_day = new Date();
        alarms.end_day = new Date(new Date().setFullYear(2099));
        this.end_day = alarms.end_day;
      }
      this.formatDate();
      const res = await saveAlarms(alarms);
      alarms._id = res.alarm._id;

      if (res.result === 'SUCCESS') {
        this.snackbar = true;
      } else {
        this.redSnackbar = true;
      }
      setTimeout(() => {
        this.cancelDialog();
      }, 2000);
    },
    alarmsViewNav() {
      router.push({
        name: 'alarm',
      });
    },

    checkButtonState() {
      if (this.name && this.days && this.activeButton && (this.dates.length > 0 || this.programed === 'always')) {
        this.filledButton = false;
      } else {
        this.filledButton = true;
      }
    },
    switchButton() {
      this.activeButton = true;
      this.checkButtonState();
    },
    disableButton() {
      this.activeButton = false;
      this.filledButton = true;
    },
    saveLimits(max, min, num, setPoint, errorRange, interval) {
      this.interval = interval;
      num = parseInt(num);

      const key = `p${num}`;
      if (num >= 1 && num <= this.maxNumberOfSensors) {
        this.probes_config[key] = {
          max,
          min,
          set_point: setPoint,
          error_range: errorRange,
        };
      }
    },
    cancelDialog: function () {
      this.cleanInput();
      this.$emit('cancel-dialog');
    },
    cleanInput() {
      this.startingTime = '00:00';
      this.endingTime = '23:59';
      this.dates = [];
      this.name = '';
      this.days = 'everyday';
      this.interval = 10;
      this.programed = 'always';
      eventBus.$emit('cleanLimits');
    },
    formatDate: function (date) {
      if (!date) {
        return '. . .';
      }

      let months = [
        this.$t('general.months.january'),
        this.$t('general.months.february'),
        this.$t('general.months.march'),
        this.$t('general.months.april'),
        this.$t('general.months.may'),
        this.$t('general.months.june'),
        this.$t('general.months.july'),
        this.$t('general.months.august'),
        this.$t('general.months.september'),
        this.$t('general.months.october'),
        this.$t('general.months.november'),
        this.$t('general.months.december'),
      ];
      let splitedDate = date.split('-');
      let formatedDate = `${splitedDate[2]} ${this.$t('general.form.of')} ${months[new Date(date).getMonth()]} ${this.$t(
        'general.form.of',
      )} ${splitedDate[0]}`;
      return formatedDate;
    },
    howManyDaysSelected: function () {
      if (!this.dates[1]) {
        return '';
      }
      const oneDay = 24 * 60 * 60 * 1000;
      const firstDate = new Date(this.dates[0]);
      const secondDate = new Date(this.dates[1]);

      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
      return `(${diffDays + 1} ${this.$t('reports.days')})`;
    },
    validateRange() {
      this.invalidDate = false;

      let validateDate = validateDateRange(this.dates);
      if (validateDate) {
        this.invalidDate = true;
        this.dates = validateDate;
      }
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    transformedProbesType() {
      const originalProbes = this.device.device_data_from_frame.probes_type;
      this.lengthProbes = Object.values(originalProbes).filter((i) => i == 'temp');

      return Object.keys(originalProbes).reduce((newObj, key) => {
        // Extraemos el número del key ( De 'p1' a '1')
        const newKey = key.replace('p', '');
        newObj[newKey] = originalProbes[key];
        return newObj;
      }, {});
    },
    inputRules() {
      return [(value) => value.length <= 19 || 'Max 20 character'];
    },
  },
  watch: {
    name() {
      this.checkButtonState();
    },
    days() {
      this.checkButtonState();
    },
    dates() {
      this.checkButtonState();
    },
    activeButton() {
      this.checkButtonState();
    },
    programed() {
      this.checkButtonState();
    },
  },
  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });

    this.user = await getUser();
  },
};
</script>

<style>
.small-label-radio .v-label {
  font-size: 0.8em;
}

.link-style {
  margin-bottom: 1em;
  cursor: pointer;
  font-size: medium;
}

.link-style:hover {
  color: #c4d5df;
  text-decoration: underline;
}
</style>
