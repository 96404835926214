const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class CreateCertificateException extends PetitionError {
  constructor(code) {
    super(' ERROR : Error when saving the certificate', code);
  }
}

async function createCertificate(token, userId) {
  const response = await post('devices/certificate', userId, token);

  if (response.result !== CreateCertificateException.success) {
    throw new CreateCertificateException(response.result);
  } else {
    return response; // TO DO transform
  }
}

module.exports = {
  createCertificate,
  CreateCertificateException,
};
