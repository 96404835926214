const brokerConnection = require('../../brokerConnection/brokerConnection');
const PetitionError = require('../../petitionError');

class GetUpdatedNotificationsException extends PetitionError {
  static userNotHaveNotifications = 'USER_NOT_HAVE_NOTIFICATIONS';

  constructor(code) {
    super('Get user notifications Exception', code);
  }
}

async function getUpdatedNotifications(user, isSuscribed, onDeviceObtained) {
  const topic = `notifications/update/${user}`;

  if (!isSuscribed) {
    await brokerConnection.suscribeToUpdate(topic, (msg) => {
      const notification = JSON.parse(msg.toString());
      onDeviceObtained(notification);
    });
  }
  return topic;
}
async function getUpdatedNotificationsBySn(device_sn, isSuscribed, onDeviceObtained) {
  const topic = `notifications/update/${device_sn}`;

  if (!isSuscribed) {
    await brokerConnection.suscribeToUpdate(topic, (msg) => {
      const notification = JSON.parse(msg.toString());
      onDeviceObtained(notification);
    });
  }
  return topic;
}

module.exports = { getUpdatedNotifications, GetUpdatedNotificationsException, getUpdatedNotificationsBySn };
