<template>
  <v-container class="pa-0 create-device-container">
    <v-row>
      <v-col class="create-device-col">
        <div class="breadcrumbs-container breadcrumbs-container-create-device">
          <v-row style="justify-content: center; text-align: center">
            <h1>{{ $t('device.form.new_beacon') }}</h1>
          </v-row>
          <v-row class="breadcrumbs" style="justify-content: center">
            <v-breadcrumbs :items="items" class="pl-0">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                  {{ item.text.toUpperCase() }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <div class="container-create-device" style="max-width: 80%; margin-left: auto !important; margin-right: auto !important">
      <h2 class="d-flex justify-start my-2">
        {{ $t('user.form.details') }}
      </h2>

      <BeaconDataForm class="edit-device-firts-container" ref="beaconData"></BeaconDataForm>

      <v-row class="buttoms-edit-user-mobile d-flex justify-center mt-10">
        <v-btn color="primary" class="d-flex justify-space-between mx-15" @click="createBeacon">
          <v-icon class="mr-1">mdi-content-save</v-icon>{{ $t('general.form.save') }}
        </v-btn>

        <v-btn color="red" class="d-flex justify-space-between mx-15" @click="goBack">
          <v-icon class="white--text mr-1">mdi-exit-to-app</v-icon>
          <span class="white--text">{{ $t('general.form.exit') }}</span>
        </v-btn>
      </v-row>

      <v-snackbar v-model="snackbar" :timeout="timeout" color="success">
        {{ snackbarMessage }}

        <template v-slot:action="{ attrs }">
          <v-btn color="black" text v-bind="attrs" @click="snackbar = false"> Cerrar </v-btn>
        </template>
      </v-snackbar>
      <ErrorDialog
        :isActive="errorDialog"
        v-bind:tittleText="$t('general.dialog.error.title')"
        :messageText="errorText"
        v-bind:acceptButtonText="$t('general.dialog.error.accept_button')"
        v-on:accept-dialog="errorDialog = false">
      </ErrorDialog>
    </div>
  </v-container>
</template>

<script>
import router from '../../../../plugins/router/index';
import BeaconDataForm from '../../../../components/forms/beaconDataForm/beaconDataForm.vue';
import { createBeacon } from '../../../../viewModels/adminViewModel.js';
import { CreateBeaconException } from '../../../../server/petitions/beacons/createBeacon';
import ErrorDialog from '../../../../components/errorDialog.vue';

export default {
  components: {
    BeaconDataForm,
    ErrorDialog,
  },
  data: () => ({
    snackbar: false,
    timeout: 2000,
    snackbarMessage: '',
    errorDialog: false,
    errorText: '',
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '../../../dashboard/temperatures',
      },
      {
        text: 'Balizas',
        disabled: false,
        href: '/admin/beacons',
      },
      {
        text: 'Crear baliza',
        disabled: true,
        href: 'admin/beacons/create',
      },
    ],
  }),
  methods: {
    goBack: function () {
      router.push({ name: 'beacons' });
    },

    createBeacon: async function () {
      try {
        var beaconDataForm = this.$refs.beaconData;

        if (beaconDataForm.validate()) {
          let mac_address = beaconDataForm.macAddress;
          let alias = beaconDataForm.alias;
          //   let model = beaconDataForm.selectedModel; ------ESTO ES PARA EL MODELO-----
          let category = beaconDataForm.selectedCategory;
          let notes = beaconDataForm.notes;
          let users = beaconDataForm.users;

          const payload = {
            mac_address,
            alias,
            // model, ------ESTO ES PARA EL MODELO-----
            category,
            notes,
            users,
          };
          await createBeacon(payload);

          this.snackbar = true;
          this.snackbarMessage = `${this.$t('admin_menu.created_beacon')}`;

          setTimeout(() => {
            this.goBack();
          }, 2000);
        }
      } catch (e) {
        this.errorText = this.$t('general.error.unexpected_error');
        this.errorDialog = true;
        switch (e.code) {
          case CreateBeaconException.unknownError:
          case CreateBeaconException.incorrectParameters:
          case CreateBeaconException.invalidToken:
          case CreateBeaconException.expiredToken:
            console.error(e);

            break;
          default:
            console.error('CATCH ERROR:', e);
        }
      }
    },
  },
};
</script>
