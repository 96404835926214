const PetitionError = require('../../petitionError');
const { postAnonymous } = require('../../httpRequester');

class IsActivatedAllEmailException extends PetitionError {
  constructor(code) {
    super('Change is_activated in Notifications emails', code);
  }
}

async function isActivatedAllEmail(payload, token) {
  const response = await postAnonymous(
    `users/change-activated-notifications?email=${payload.user_email}&switchValue=${payload.switchValue}&token=${token}`,
  );

  if (response.result !== IsActivatedAllEmailException.success) {
    throw new IsActivatedAllEmailException(response.result);
  } else {
    return response.user;
  }
}
module.exports = {
  isActivatedAllEmail,
  IsActivatedAllEmailException,
};
