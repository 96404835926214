<template>
  <v-dialog v-model="isActive" max-width="20rem" @click:outside="cancelDialog" @keydown.esc="cancelDialog">
    <v-card>
      <v-card-title color="primary" class="headline">{{
        $t('device.immobilise_vehicle') + device.device_data_from_frame.carplate
      }}</v-card-title>
      <v-card-title color="primary" class="caption pt-0">S/N: {{ device.device_sn }}</v-card-title>
      <v-divider class="mb-2"></v-divider>
      <v-card-text>
        <v-switch
          @change="turnOff"
          v-model="switchEnabled"
          color="red"
          :label="$t('device.request_immobilisation')"
          :loading="warning"
          inset></v-switch>

        <p color="primary" class="text-lg">{{ $t('device.warning') }}</p>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="timeout" color="success">
      {{ $t('device.orderSent') }}

      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs"> Cerrar </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="snackbarError" :timeout="timeout" color="error">
      {{ $t('device.orderError') }}

      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs"> X </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { turnOff } from '../../viewModels/devicesViewModel';
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    device: {
      type: Object,
    },
  },
  data: () => ({
    switchEnabled: false,
    warning: false,
    timeout: 4000,
    snackbar: false,
    snackbarError: false,
  }),
  methods: {
    async turnOff() {
      const res = await turnOff(this.device.device_sn, this.switchEnabled);

      if (res.result !== 'SUCCESS') {
        this.snackbarError = true;
      } else {
        this.warning = true;
        this.snackbar = true;
        setTimeout(() => {
          this.$emit('cancel-dialog');
          this.warning = false;
        }, 2000);
      }
    },
    cancelDialog: function () {
      this.$emit('cancel-dialog');
    },
    getFlag() {
      if (this.device.stop_flag) this.switchEnabled = this.device.stop_flag;
    },
  },
  computed: {},
  mounted() {},
};
</script>
