const brokerConnection = require('../../brokerConnection/brokerConnection');
const PetitionError = require('../../petitionError');

class UnsuscribeUpdates extends PetitionError {
  static userNotHaveDevices = 'USER_NOT_HAVE_DEVICES';

  constructor(code) {
    super('Unsuscribe updates Exception', code);
  }
}

async function unsuscribeUpdates(device_sn) {
  const topic = `update/frame/${device_sn}`; // TODO cambiar el topico por update/frame/<nº de serie>

  brokerConnection.stopReceiving(topic);
}

// class UnsuscribeUpdates extends PetitionError {
//   static userNotHaveDevices = 'USER_NOT_HAVE_DEVICES';

//   constructor(code) {
//     super('Unsuscribe updates Exception', code);
//   }
// }

module.exports = {
  unsuscribeUpdates,
  UnsuscribeUpdates,
};
