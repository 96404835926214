const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class SaveAlarmPetitionException extends PetitionError {
  constructor(code) {
    super('Create alarm', code);
  }
}

async function saveAlarmPetition(alarm, token) {
  const payload = { alarm };
  const response = await post('alarm', payload, token);

  if (response.result !== SaveAlarmPetitionException.success) {
    return response.result;
  }
  return response; // TODO transform
}

module.exports = {
  saveAlarmPetition,
  SaveAlarmPetitionException,
};
