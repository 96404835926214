const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class CreateDeviceException extends PetitionError {
  static serialNumberAlreadyInDevice = 'SERIALNUMBER_ALREADY_IN_DEVICE';

  constructor(code) {
    super('Create Device', code);
  }
}

async function createDevice(token, payload) {
  const response = await post('devices/create_device', payload, token);

  if (response.result !== CreateDeviceException.success) {
    throw new CreateDeviceException(response.result);
  } else {
    return response.device; // TODO transform
  }
}

module.exports = {
  createDevice,
  CreateDeviceException,
};
