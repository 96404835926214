<template>
  <v-container id="edit-data-user">
    <v-form class="edit-data-form" v-model="formValid" lazy-validation>
      <v-row>
        <v-col @click="goBack" class="d-flex justify-start my-2" style="cursor: pointer">
          <v-icon>mdi-chevron-left</v-icon>
          <h1>{{ userData.name }}</h1>
        </v-col>
      </v-row>
      <div class="form-edit">
        <v-row class="form-edit-row d-flex mt-5">
          <v-col class="mt-2" cols="3">
            <label class="font-weight-black float-left">{{ $t('user.atribute.name') }}*</label>
          </v-col>
          <v-col cols="9">
            <v-text-field dense outlined class="rounded-lg" type="text" v-model="name" :rules="textRules" required></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="forCompany == 0" class="form-edit-row d-flex">
          <v-col cols="3">
            <label class="mt-2 font-weight-black float-left">{{ $t('user.atribute.company') }}</label>
          </v-col>
          <v-col cols="9">
            <v-autocomplete
              :items="companies"
              item-text="name"
              item-value="_id"
              outlined
              dense
              v-model="selectedCompany"
              class="rounded-lg"
              :loading="isLoadingCompanies"></v-autocomplete>
          </v-col>
        </v-row>

        <v-row class="form-edit-row d-flex">
          <v-col cols="3">
            <label class="mt-2 font-weight-black float-left">{{ $t('user.atribute.email') }}*</label>
          </v-col>
          <v-col cols="9">
            <v-text-field dense outlined class="rounded-lg" type="text" v-model="email" :rules="emailRules"></v-text-field>
          </v-col>
        </v-row>

        <v-row class="form-edit-row d-flex">
          <v-col cols="3">
            <label class="mt-2 font-weight-black float-left">{{ $t('user.atribute.notification_emails') }}</label>
          </v-col>
          <v-col cols="9">
            <v-text-field
              :disabled="maxNotificationEmails"
              dense
              outlined
              class="rounded-lg"
              type="text"
              v-bind:label="$t('user.notification_emails.hint')"
              v-model="notificationEmail"
              :rules="emailRules"
              append-icon="mdi-plus"
              @click:append="addNotificationEmail()">
            </v-text-field>
          </v-col>
        </v-row>

        <v-row class="form-edit-row d-flex justify-center">
          <v-col cols="5" style="min-width: 359px">
            <v-list-item v-for="(item, i) in notificationsEmails" :key="i" style="padding-left: 0px">
              <v-list-item-title v-text="item"></v-list-item-title>
              <v-icon color="red" @click="deleteEmail(i)">mdi-delete</v-icon>
            </v-list-item>
          </v-col>
        </v-row>

        <Confirm ref="confirm"></Confirm>

        <v-row class="form-edit-row d-flex">
          <v-col class="mt-2" cols="3">
            <label class="font-weight-black float-left">{{ $t('user.atribute.phone') }}</label>
          </v-col>
          <v-col cols="9">
            <v-text-field
              dense
              :rules="phoneRules"
              oninput="value=value.replace(/^[0-9]{10}$|^[-]$/, '')"
              outlined
              class="rounded-lg"
              type="number"
              v-model.number="phone">
            </v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="!editingUser" class="form-edit-row d-flex">
          <v-col cols="3" class="form-edit-row-h5">
            <label class="mt-2 font-weight-black float-left">{{ $t('user.password.name') }}*</label>
            <br /><br />
            <h5>
              {{ $t('user.atribute.misc.default_password') + '   ' + email }}
            </h5>
          </v-col>
          <v-col cols="9" class="form-edit-row-h5">
            <v-text-field
              dense
              outlined
              readonly
              class="rounded-lg"
              type="password"
              v-model="email"
              hint="Al menos 6 carácteres"
              :rules="passwordRules"
              required
              autocomplete="new-password"></v-text-field>
            <h5>
              {{ $t('user.atribute.misc.default_password') + '   ' + email }}
            </h5>
          </v-col>
        </v-row>

        <v-row class="form-edit-row">
          <v-col cols="3"> </v-col>
          <v-col cols="9" class="form-edit-row-h5">
            <v-checkbox v-model="restricted" v-bind:label="$t('user.atribute.access')" @change="restrictAccess"></v-checkbox>
            <h5 class="remark">{{ $t('user.form.restrict_warning') }}</h5>
            <h5>{{ $t('user.form.restrict_explanation') }}</h5>
            <h5>
              Si se decide a limitar el acceso a un usuario, por favor mandad siempre email a Administración ({{
                administrative
              }}) y a soporte.
            </h5>
          </v-col>
        </v-row>

        <Confirm ref="confirm2"></Confirm>

        <br /><br />

        <v-row class="form-edit-row d-flex">
          <v-col cols="3">
            <label class="mt-2 font-weight-black float-left">{{ $t('user.atribute.notes') }}</label>
          </v-col>
          <v-col cols="9">
            <v-text-field
              dense
              outlined
              counter
              :maxlength="maxCharacters"
              :rules="notesRules"
              class="rounded-lg"
              type="text"
              v-model="notes"></v-text-field>
          </v-col>
          <v-col cols="3">
            <label class="mt-2 font-weight-black float-left">{{ $t('user.atribute.roles.roles') }}</label>
          </v-col>

          <v-col cols="9">
            <v-select
              dense
              outlined
              item-text="name"
              item-value="value"
              v-model="selectedRoles"
              :items="refreshRoles"
              v-bind:label="$t('user.form.select_role')"
              :rules="[(v) => !!v || $t('general.error.empty_value')]"
              required
              class="rounded-lg"></v-select>
          </v-col>

          <v-col cols="3">
            <label class="mt-2 font-weight-black float-left">{{ $t('user.atribute.platform') }}</label>
          </v-col>

          <v-col cols="9">
            <v-select
              dense
              outlined
              v-model="selectedPlatform"
              :items="platformsToChoose"
              v-bind:label="$t('user.form.select_platform')"
              :rules="[(v) => !!v || $t('general.error.empty_value')]"
              required
              class="rounded-lg"></v-select>
          </v-col>

          <v-col cols="3">
            <label class="mt-2 font-weight-black float-left">{{ $t('user.atribute.unit.unit') }}</label>
          </v-col>
          <v-col cols="9">
            <v-select
              dense
              outlined
              item-text="name"
              item-value="value"
              v-model="selectedUnit"
              :items="refreshUnits"
              v-bind:label="$t('user.form.select_unit')"
              :rules="[(v) => !!v || $t('general.error.empty_value')]"
              required
              class="rounded-lg"></v-select>
          </v-col>

          <v-col cols="3">
            <label class="mt-2 font-weight-black float-left">{{ $t('user.atribute.restrictions.restrictions') }}</label>
          </v-col>
          <v-col cols="9">
            <v-select
              dense
              outlined
              multiple
              item-text="name"
              item-value="value"
              v-model="selectedRestrictions"
              :items="refreshRestrictions"
              v-bind:label="$t('user.form.select_restrictions')"
              class="rounded-lg"></v-select>
          </v-col>
        </v-row>
      </div>

      <v-row class="d-flex mt-10">
        <v-btn color="primary" class="d-flex justify-space-between mx-15" @click="save">
          <v-icon>mdi-content-save</v-icon>{{ $t('general.form.save') }}
        </v-btn>
        <v-btn color="red" class="d-flex justify-space-between mx-15" @click="goBack">
          <v-icon class="white--text">mdi-exit-to-app</v-icon>
          <span class="white--text">{{ $t('general.form.exit') }}</span>
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import {
  modifyUserByAdmin,
  getAllCompanies,
  getAllDevices,
  getAllUserDevices,
  getDevice,
} from '../../../../viewModels/adminViewModel';
import Confirm from '../../../../components/confirm.vue';
import router from '../../../../plugins/router/index';
import { ModifyUserByAdminException } from '../../../../server/petitions/users/modifyUserByAdmin';

export default {
  components: {
    Confirm,
  },

  props: {
    forCompany: {
      type: String,
    },
    userData: {
      type: Object,
      default: () => ({}),
    },
    editingUser: {
      type: Boolean,
      default: false,
    },
  },

  data: (instance) => ({
    user: null,

    formValid: false,

    selectedRoles: instance.userData.type,
    selectedPlatform: instance.userData.platform,
    selectedUnit: instance.userData.unit,
    selectedRestrictions: instance.userData.restrictions,

    platformsToChoose: [],

    devicesToChoose: [],

    devices: [],
    isLoadingDevices: true,
    formValid: false,

    devicesToAdd: [],
    devicesToRemove: [],
    name: instance.userData.name,
    companies: [],
    selectedCompany: instance.userData.company_id,
    isLoadingCompanies: true,
    email: instance.userData.email || '',
    firstTime: true,
    notificationEmail: null,
    notificationsEmails: instance.userData.notification_emails || [],
    phone: instance.userData.phone,
    password: '',
    restricted: instance.userData.restricted || false,
    notes: instance.userData.notes,
    maxCharacters: 500,
    devicesTableData: [],
    isTableLoading: true,
    administrative: 'Daniel Ordoñez',
  }),

  methods: {
    save: async function () {
      try {
        var userFormData = this.$refs.userData;

        if (userFormData.validate()) {
          let user_name = userFormData.name;
          let user_phone = userFormData.phone;
          let user_email = userFormData.email;
          let user_company = userFormData.selectedCompany;
          let user_notification_emails = userFormData.notificationsEmails;
          let user_restricted = userFormData.restricted;
          let user_devices_id = userFormData.devicesToAdd;
          let user_removable_id = userFormData.devicesToRemove;
          let user_notes = userFormData.notes;
          let user_roles = userFormData.selectedRoles;
          let user_platform = userFormData.selectedPlatform;
          let user_unit = userFormData.selectedUnit;
          let user_restrictions = userFormData.selectedRestrictions;

          await modifyUserByAdmin(
            this.userData._id,
            user_name,
            user_phone,
            user_email,
            user_company,
            user_notification_emails,
            user_restricted,
            user_devices_id,
            user_removable_id,
            user_notes,
            user_roles,
            user_platform,
            user_unit,
            user_restrictions,
          );
          if (this.$vuetify.breakpoint.mobile) {
            this.goBack();
          } else {
            this.snackbar = true;
          }
        }
      } catch (e) {
        switch (e.code) {
          case ModifyUserByAdminException.unknownError:
          case ModifyUserByAdminException.incorrectParameters:
          case ModifyUserByAdminException.invalidToken:
          case ModifyUserByAdminException.expiredToken:
            console.error(e);
            this.errorText = this.$t('general.error.unexpected_error');
            this.errorDialog = true;
            break;
          case ModifyUserByAdminException.emailAlreadyInUser:
            this.errorText = this.$t('user.add_modify.error.email_already_in_use');
            this.errorDialog = true;
            break;
          default:
            console.error('CATCH ERROR:', e);
        }
      }
    },

    goBack: function () {
      router.push({
        name: 'editUser',
        params: { userId: this.userData._id, userData: this.userData },
      });
    },

    EditDataUser: function () {
      router.push({
        name: 'editDataUser',
        params: { userId: this.userData._id, userData: this.userData },
      });
    },

    editDevice: async function (e) {
      const device = await getDevice(e.device_sn);
      router.push({
        name: 'editDevice',
        params: { device_sn: e.device_sn, deviceData: device },
      });
    },

    editDeviceCtrl: async function (e, i) {
      e.preventDefault();
      const device = await getDevice(i.item.device_sn);
      let routeData = router.resolve({ name: 'editDevice', params: { device_sn: i.item.device_sn, deviceData: device } });
      window.open(routeData.href, '_blank');
    },

    validateEmail() {
      return this.notificationEmail.includes('@');
    },

    addDevice: function (e) {
      if (!this.devices.includes(e._id)) {
        this.devices.push(e._id);
        this.devicesTableData.push(e);
        this.devicesToAdd.push(e._id);
      }
    },

    async deleteEmail(index) {
      if (
        await this.$refs.confirm.open(this.$t('user.form.delete_notification_email'), this.$t('reports.programmed.delete_info'), {
          color: 'red',
        })
      ) {
        this.notificationsEmails.splice(index, 1);
      }
    },

    async restrictAccess() {
      if (this.restricted) {
        if (
          await this.$refs.confirm2.open(this.$t('user.form.restrict_access:'), this.$t('user.form.restrict_info'), {
            color: 'red',
          })
        ) {
        } else {
          this.restricted = false;
        }
      }
    },

    addNotificationEmail: function () {
      if (this.validateEmail()) {
        this.notificationsEmails.push(this.notificationEmail);

        this.notificationEmail = '';
        return;
      }
    },

    getPlatforms: function () {
      return ['apachepro', 'tmb', 'sor'];
    },

    validate: function () {
      return this.$refs.userForm.validate();
    },

    deleteRow: function (item) {
      this.devicesToRemove.push(item._id);
      let indexToDelete = this.devicesTableData.indexOf(item);
      this.devices.splice(indexToDelete, 1);
      this.devicesTableData.splice(indexToDelete, 1);
    },
  },

  mounted: async function () {
    this.devicesToChoose = await getAllDevices();

    this.isLoadingDevices = false;
    if (this.userData.devices) {
      let userDevices = await getAllUserDevices(this.userData.devices);
      for (let dev of this.userData.devices) {
        for (let [index, devicee] of userDevices.entries()) {
          if (dev.device_sn == devicee.device_sn) {
            userDevices[index].names = dev.name;
          }
        }
      }
      this.devicesTableData = userDevices;
      for (let userDevice of userDevices) {
        this.devices.push(userDevice._id);
      }
    }
    this.companies = await getAllCompanies();
    this.isLoadingCompanies = false;
    this.platformsToChoose = this.getPlatforms();
    if (!this.selectedUnit) {
      this.selectedUnit = 'metric';
    }
    if (!this.selectedRoles) {
      this.selectedRoles = 'user';
    }
    if (!this.selectedPlatform) {
      this.selectedPlatform = 'apachepro';
    }
    this.isTableLoading = false;
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    maxNotificationEmails() {
      return this.notificationsEmails.length >= 5;
    },

    devicesTableHeader() {
      return [
        {
          text: this.$t('device.atribute.serial_number'),
          value: 'device_sn',
        },
        { text: this.$t('device.atribute.carplate'), value: 'names' },
        { text: this.$t('device.atribute.key'), value: 'password' },
        { text: this.$t('device.atribute.notes'), value: 'notes' },
        { text: '', value: 'actions' },
      ];
    },

    passwordRules() {
      return [
        (value) => !!value || this.$t('user.password.error.empty_password'),
        (value) => (value && value.length >= 6) || this.$t('user.password.error.invalid_password'),
      ];
    },

    textRules() {
      return [
        (value) => !!value || this.$t('general.error.empty_value'),
        (value) => (value && value.length <= 45) || this.$t('user.atribute.misc.name_rules'),
      ];
    },

    emailRules() {
      return [
        (value) => {
          if (value) return (!!value && /.+@.+/.test(value)) || this.$t('general.error.not_valid_email');
          else return true;
        },
      ];
    },

    phoneRules() {
      return [
        (value) => {
          if (value) return !isNaN(parseFloat(value)) || this.$t('general.error.must_be_number');
          else return true;
        },
      ];
    },

    refreshRestrictions() {
      return [
        { name: this.$t('user.atribute.restrictions.login'), value: 'login' },
        { name: this.$t('user.atribute.restrictions.map'), value: 'map' },
        { name: this.$t('user.atribute.restrictions.temp'), value: 'temp' },
        {
          name: this.$t('user.atribute.restrictions.reports'),
          value: 'reports',
        },
      ];
    },
    refreshUnits() {
      return [
        { name: this.$t('user.atribute.unit.metric'), value: 'metric' },
        { name: this.$t('user.atribute.unit.imperial'), value: 'imperial' },
      ];
    },

    refreshRoles() {
      return [
        { name: this.$t('user.atribute.roles.admin'), value: 'admin' },
        { name: this.$t('user.atribute.roles.user'), value: 'user' },
      ];
    },

    defaultUnit() {
      return [(value) => !!value || (this.selectedUnit = this.$t('user.atribute.unit.metric'))];
    },

    notificationEmailsRules() {
      return [
        (value) => {
          if (value) return (!!value && /.+@.+/.test(value)) || this.$t('general.error.not_valid_email');
          else return true;
        },
      ];
    },

    notesRules() {
      return [
        (value) => {
          if (value) return value.length <= 499 || this.$t('user.atribute.misc.notes_limit');
          else return true;
        },
      ];
    },
  },
};
</script>
