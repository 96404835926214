<template>
  <v-dialog :dark="theme === 'dark' ? true : false" v-model="dialog" max-width="50%">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <POIDataForm ref="poiDataForm" v-model="value" />
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close"> {{ $t('general.dialog.confirmation.button.cancel') }} </v-btn>
        <v-btn color="blue darken-1" text @click="save"> {{ $t('general.form.save') }} </v-btn>
      </v-card-actions>
    </v-card>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark class="mb-2 ml-2" @click="newPOI" v-bind="attrs" v-on="on">
        <v-icon class="mr-1">mdi-plus</v-icon> {{ $t('dashboard.poi') }}
      </v-btn>
    </template>
  </v-dialog>
</template>

<script>
import POIDataForm from '../../../components/forms/poisForm/poiDataForm.vue';
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';

export default {
  components: {
    POIDataForm,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      theme: ls.get('theme') ?? 'ligth',
      dialog: false,
    };
  },
  methods: {
    newPOI() {
      this.$refs.poiDataForm?.reset();
      this.$emit('newPOI');
    },
    close() {
      this.dialog = false;
      this.$emit('closeDialog');
    },
    open() {
      this.dialog = true;
    },
    save() {
      if (this.$refs.poiDataForm.isFormValid()) {
        this.$emit('savePOI');
        this.close();
      }
    },
  },
  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
  },
};
</script>
