const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class CreateMaintenanceException extends PetitionError {
  constructor(code) {
    super(' ERROR : Error when updating the maintenance', code);
  }
}

async function createMaintenancePetition(token, payload) {
  const response = await post('maintenances/', payload, token);

  if (response.result !== CreateMaintenanceException.success) {
    throw new CreateMaintenanceException(response.result);
  } else {
    return response;
  }
}

module.exports = { createMaintenancePetition, CreateMaintenanceException };
