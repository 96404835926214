<template>
  <v-dialog v-model="isActive" max-width="500px" @click:outside="acceptDialog">
    <v-card>
      <v-card-title class="headline error white--text">{{ tittleText }}</v-card-title>
      <v-card-text class="my-4">{{ messageText }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="acceptDialog">{{ acceptButtonText }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      isActive: {
        type: Boolean,
        default: false
      },
      tittleText: {
        type: String
      },
      messageText: {
        type: String
      },
      acceptButtonText: {
        type: String
      },
    },
    data: () => ({
      
    }),
    methods: {
      acceptDialog: function(){
        this.$emit('accept-dialog')
      }
    }
  };
</script>