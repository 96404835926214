<template>
  <v-card class="elevation-2 ma-2">
    <v-card-text class="pt-0">
      <!-- <div class="text-overline font-weight-bold">
        {{ $t('device.last_frames.can_bus.canbus') }} - {{ device?.last_frames.frame_CAN.payloadCAN.canType }}
      </div> -->
      <div class="text-overline font-weight-bold">EBS</div>

      <v-divider class="py-1 mx-2" style="border-color: #607d8b"></v-divider>

      <div class="ml-1 mb-2 d-flex flex-column">
        <span class="mb-1">
          <v-icon :class="checkCANBUS" x-small> mdi-circle </v-icon>
          {{ canbusTimestamp }}
        </span>
      </div>

      <span class="mb-1" :class="isPinaOn ? 'font-weight-bold' : ''">
        <v-chip label x-small class="px-1 py-3" :color="isPinaOn ? 'rgb(33, 150, 243, 0.7)' : ''">
          <v-icon small> mdi-truck-flatbed </v-icon>
        </v-chip>
        {{ $t('device.last_frames.can_bus.tractor_unit') }}
      </span>

      <v-row v-if="device.last_frames.frame_CAN.payloadCAN" class="pt-5">
        <v-col>
          <!-- eslint-disable-next-line -->
          <v-row class="justify-space-around" v-for="(row, index) in getNumberOfColums.firstCol" v-bind:key="row.key">
            <v-chip label small class="justify-center ma-1 white--text blue-grey lighten-1" style="width: 50%">
              {{ row.name }}
            </v-chip>
            <v-chip
              :color="device?.last_frames.frame_CAN.payloadCAN.tyres[row.info].alarms.press ? 'rgb(255, 142, 142)' : 'blue-grey'"
              label
              small
              class="justify-center ma-1 lighten-4"
              style="width: 35%">
              <span>{{ device ? device.last_frames.frame_CAN.payloadCAN.tyres[row.info].steerInfo.press : '...' }} Bar</span>
            </v-chip>
          </v-row>
        </v-col>

        <v-divider v-if="device.last_frames.frame_CAN.payloadCAN" style="width: 10%" vertical></v-divider>

        <v-col>
          <!-- eslint-disable-next-line -->
          <v-row v-for="(row, index) in getNumberOfColums.secondCol" v-bind:key="row.key" class="justify-space-around">
            <v-chip label small class="justify-center ma-1 white--text blue-grey lighten-1" style="width: 50%">
              {{ row.name }}
            </v-chip>
            <v-chip
              :color="device?.last_frames.frame_CAN.payloadCAN.tyres[row.info].alarms.press ? 'rgb(255, 142, 142)' : 'blue-grey'"
              label
              small
              class="justify-center ma-1 lighten-4"
              style="width: 35%">
              <span>{{ device ? device.last_frames.frame_CAN.payloadCAN.tyres[row.info].steerInfo.press : '...' }} Bar</span>
            </v-chip>
          </v-row>
        </v-col>
      </v-row>

      <div class="d-flex flex-row my-6" v-if="getWeight != null">
        <div class="d-flex justify-center flex-column" style="width: 100%">
          <span class="d-flex justify-center"> {{ $t('device.last_frames.can_bus.trailer_weight') }} </span>
          <v-chip label small class="justify-center ma-1 blue-grey lighten-4" style="width: 95%">
            <span> {{ getWeight }} kg </span>
          </v-chip>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
const VueI18n = require('../../../../plugins/lang/index');

export default {
  components: {},
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
    canbusTimestamp: {
      type: String,
    },
    frigoClass: {
      type: String,
    },
    isPinaOn: {
      type: Boolean,
    },
    status: {
      type: Object,
    },
  },
  data: () => ({
    firstCANCol: [],
    secondCANCol: [],
  }),
  computed: {
    checkCANBUS() {
      if (this.status.canbus) {
        return 'green--text';
      }
      return 'gray--text';
    },
    getWeight() {
      return this.device?.last_frames?.frame_CAN?.payloadCAN?.weight ?? null;
    },
    getNumberOfColums() {
      this.firstCANCol = [];
      this.secondCANCol = [];

      if (this.device?.last_frames?.frame_CAN?.payloadCAN) {
        for (let tyre in this.device.last_frames.frame_CAN.payloadCAN.tyres) {
          if (this.device.last_frames.frame_CAN.payloadCAN.tyres[tyre]) {
            const index = tyre.at(-1);
            if (index % 2 === 1) {
              this.firstCANCol.push({
                name: `${VueI18n.default.t('device.last_frames.can_bus.tyres', {
                  tyre: index,
                })}`,

                info: tyre,
                hasInfo: true,
                key: index,
              });
            } else {
              this.secondCANCol.push({
                name: `${VueI18n.default.t('device.last_frames.can_bus.tyres', {
                  tyre: index,
                })}`,
                info: tyre,
                hasInfo: true,
                key: index,
              });
            }
          }
        }
        return { firstCol: this.firstCANCol, secondCol: this.secondCANCol };
      }
    },
  },
  methods: {},
  mounted: async function () {
    if (this.device) {
      this.getNumberOfColums;
    }
  },
};
</script>
