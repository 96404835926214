const PetitionError = require('../../petitionError');
const { delAnonymous } = require('../../httpRequester');

class DeleteCodeException extends PetitionError {
  constructor(code) {
    super('Delete code', code);
  }
}

async function deleteCode(email) {
  const response = await delAnonymous(`users/validation_code?email=${email}`);
  if (response.result !== DeleteCodeException.success) {
    throw new DeleteCodeException(response.result);
  } else {
    return response.response_code;
  }
}

module.exports = {
  deleteCode,
  DeleteCodeException,
};
