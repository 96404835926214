import Vue from 'vue';
import VueI18n from 'vue-i18n';
import constants from './constants';
import en from './en_EN.json';
import es from './es_ES.json';
import fr from './fr_FR.json';
import de from './de_DE.json';
import pt from './pt_PT.json';
import it from './it_IT.json';

Vue.use(VueI18n);

export default new VueI18n({
  locale: constants.default_locale,
  messages: {
    en,
    es,
    fr,
    de,
    pt,
    it,
  },
});
