<template>
  <v-dialog v-model="isActive" max-width="55rem" @click:outside="cancelOdometer" @keydown.esc="cancelOdometer">
    <v-card class="ticket-view-card">
      <v-row>
        <v-card-title color="primary" class="text-h4 font-weight-black"
          >Configurar Odómetro para el vehículo:
          <br />
          {{
            device.name
              ? device.name
              : device.device_data_from_frame.carplate
              ? device.device_data_from_frame.carplate
              : device.device_sn
          }}</v-card-title
        >
      </v-row>

      <v-card-title color="primary" class="caption pt-0">S/N: {{ device.device_sn }}</v-card-title>
      <v-divider class="py-1 mx-2" style="border-color: #607d8b"></v-divider>
      <v-card-title color="primary" class="caption pt-0">Odómetro:</v-card-title>
      <v-text-field v-model="odometer" :rules="mileageRules" :label="odometerOffset" solo class="mx-6" suffix="km"></v-text-field>
      <v-form>
        <v-container class="text-md-center">
          <v-btn class="mx-lg-6" color="primary" elevation="2" x-large @click="save"
            ><v-icon>mdi-content-save</v-icon>{{ this.$t('dashboard.configuration.accept') }}</v-btn
          >
          <v-btn class="mx-lg-6" color="error" elevation="2" x-large @click="cancelOdometer"
            ><v-icon>mdi-cancel</v-icon>{{ this.$t('dashboard.configuration.cancel') }}</v-btn
          >
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<style>
.title {
  font-size: large;
}
</style>

<script>
import { saveOdometer } from '../../viewModels/devicesViewModel';
import { eventBus } from '../../main';
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    device: {
      type: Object,
    },
    titleInput: {
      type: Boolean,
    },
  },
  data: () => ({
    odometer: null,
  }),
  computed: {
    mileageRules() {
      return [(value) => Number(value) <= 999999999 || this.$t('dashboard.configuration.max_mileage')];
    },
    odometerOffset() {
      if (this.device.last_frames && this.device.last_frames.frame_gps) {
        return this.device.last_frames.frame_gps.odometer
          ? this.device.last_frames.frame_gps.odometer.offset_applied.toFixed(3)
          : 'No data';
      }
    },
  },

  methods: {
    cancelOdometer: function () {
      this.$emit('cancel-odometer');
    },
    save: async function () {
      let offset = this.odometer - this.device.last_frames.frame_gps.odometer.raw;
      if (this.odometer) {
        let res = await saveOdometer(this.device.device_sn, this.odometer, offset);
        if (res.result !== 'SUCCESS') {
        } else {
        }
      }
      this.$emit('cancel-odometer');
    },
  },

  mounted: async function () {},
};
</script>
