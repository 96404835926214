const PetitionError = require('../../petitionError');
const { get } = require('../../httpRequester');

async function logoutByToken(token) {
  const response = await get('authentication/logout', token);

  /* if (response.result != LogoutByTokenException.success) {
        throw (new LogoutByTokenException(response.result))
    } else {
        return response.user //TODO transform
    }
 */
}

class LogoutByTokenException extends PetitionError {
  constructor(code) {
    super('Logout By Token', code);
  }
}

module.exports = {
  logoutByToken,
  LogoutByTokenException,
};
