const PetitionError = require('../../petitionError');
const { del } = require('../../httpRequester');

class DeleteNotificationEmailsException extends PetitionError {
  constructor(code) {
    super('Delete email', code);
  }
}

async function deleteNotificationEmailsPetition(userEmail, emailToDelete, token) {
  const payload = {
    email: userEmail,
    email_to_delete: emailToDelete,
  };

  const response = await del('users/delete_notification_emails', payload, token);

  if (response.result !== DeleteNotificationEmailsException.success) {
    throw new DeleteNotificationEmailsException(response.result);
  } else {
    return response;
  }
}

module.exports = {
  deleteNotificationEmailsPetition,
  DeleteNotificationEmailsException,
};
