<template>
  <v-container v-if="!isMobile">
    <v-row id="user-view-desktop">
      <v-col class="header-title pb-0" cols="9">
        <div class="breadcrumbs-container">
          <v-row>
            <h1>
              {{ userData.name }}
            </h1>
          </v-row>
          <v-row class="breadcrumbs">
            <v-breadcrumbs :items="items" class="pl-0">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                  {{ item.text.toUpperCase() }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-row>
        </div>
      </v-col>

      <v-col class="pt-0">
        <UserDataForm
          @device-repeat="deviceRepeat"
          @device-no-repeat="deviceNoRepeat"
          @delete-success="deleteSuccess"
          :editingUser="true"
          class="edit-user-data-form"
          ref="userData"
          forCompany="0"
          :userData="userData"></UserDataForm>

        <v-row class="edit-user-buttons d-flex justify-center mt-10">
          <v-btn :disabled="disabledButton" color="primary" class="d-flex justify-space-between mx-15" @click="save">
            <v-icon>mdi-content-save</v-icon>{{ $t('general.form.save') }}
          </v-btn>
          <v-btn color="red" class="d-flex justify-space-between mx-15" @click="goBack">
            <v-icon class="white--text">mdi-exit-to-app</v-icon>
            <span class="white--text">{{ $t('general.form.exit') }}</span>
          </v-btn>
        </v-row>
      </v-col>

      <v-col cols="3" class="user-actions pt-0">
        <v-expansion-panels class="card-stadistics expansion-panels my-2">
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <v-row no-gutters>
                  <v-col>
                    {{ $t('user.stadistics.stadistics') }}
                  </v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="expansion-panel-content">
              <div class="expansion-panel-body">
                <div class="expansion-panel-info text-center">
                  <p class="expansion-panel-info-title">{{ $t('user.stadistics.devices') }}:</p>
                </div>
                <div class="atribute-date expansion-panel-info">
                  <p class="expansion-panel-info-title">{{ $t('user.stadistics.total_devices') }}</p>
                  <span class="expansion-panel-info-text">{{ userData.devices.length }}</span>
                </div>

                <div class="atribute-date expansion-panel-info">
                  <p class="expansion-panel-info-title">{{ $t('user.stadistics.visible_devices') }}</p>
                  <span class="expansion-panel-info-text">{{ visibleDevices }}</span>
                </div>

                <v-divider class="mt-2"></v-divider>

                <div class="expansion-panel-info text-center">
                  <p class="expansion-panel-info-title">{{ $t('user.stadistics.device_with_model') }}:</p>
                </div>

                <div class="atribute-date expansion-panel-info">
                  <p class="expansion-panel-info-title">{{ $t('user.stadistics.model_core') }}</p>
                  <span class="expansion-panel-info-text">{{ coreModel }}</span>
                </div>

                <div class="atribute-date expansion-panel-info">
                  <p class="expansion-panel-info-title">{{ $t('user.stadistics.model_trailer') }}</p>
                  <span class="expansion-panel-info-text">{{ trailerModel }}</span>
                </div>

                <div class="atribute-date expansion-panel-info">
                  <p class="expansion-panel-info-title">{{ $t('user.stadistics.model_storage') }}</p>
                  <span class="expansion-panel-info-text"> {{ storageModel }}</span>
                </div>

                <div class="atribute-date expansion-panel-info">
                  <p class="expansion-panel-info-title">{{ $t('user.stadistics.model_tracker') }}</p>
                  <span class="expansion-panel-info-text">{{ trackerModel }}</span>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-card elevation="3" class="card-actions ma-3 mt-0">
          <v-container class="card-actions ma-3 mt-0">
            <v-row class="d-flex flex-column justify-space-around">
              <v-btn @click="passwordView" class="ma-3 d-flex justify-center" color="primary">
                <font-awesome-icon icon="fa-solid fa-pen-to-square" class="mr-2" />
                {{ $t('user.actions.change_password') }}
              </v-btn>

              <v-btn @click.stop="openDialog()" class="ma-3 d-flex justify-center" color="error" disabled>
                <font-awesome-icon icon="fa-solid fa-trash" class="mr-2" />
                {{ $t('user.actions.delete_user') }}
              </v-btn>

              <v-btn class="ma-3 d-flex justify-center white--text" color="#20c997" disabled>
                {{ $t('user.actions.log_actions') }}
              </v-btn>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="2000">
      {{ text }}
    </v-snackbar>

    <ErrorDialog
      :isActive="errorDialog"
      v-bind:tittleText="$t('general.dialog.error.title')"
      :messageText="errorText"
      v-bind:acceptButtonText="$t('general.dialog.error.accept_button')"
      v-on:accept-dialog="errorDialog = false">
    </ErrorDialog>

    <ConfirmationDialog
      :isActive="deleteDialog"
      v-on:cancel-dialog="closeDialog"
      v-on:accept-dialog="deleteUser(deleteMode)"
      v-bind:tittleText="$t('general.dialog.confirmation.title')"
      :messageText="dialogText"
      v-bind:cancelButtonText="$t('general.dialog.confirmation.button.cancel')"
      v-bind:acceptButtonText="$t('general.dialog.confirmation.button.accept_delete')">
    </ConfirmationDialog>
  </v-container>

  <!-- Mobile View -->

  <v-container v-else class="edit-user-container-mobile mt-5">
    <v-row class="header-title">
      <div class="breadcrumbs-container">
        <v-row class="breadcrumbs-title">
          <h1>
            {{ userData.name }}
          </h1>
        </v-row>
        <v-row class="breadcrumbs">
          <v-breadcrumbs :items="items" class="pl-0">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-row>
      </div>
    </v-row>

    <v-row class="card-actions card-actions-user-mobile mt-3 mb-4">
      <v-row class="card-actions-row">
        <v-btn @click="passwordView" class="card-actions-button ma-3 d-flex justify-center" color="primary">
          <font-awesome-icon icon="fa-solid fa-pen-to-square" class="mr-2" />
          {{ $t('user.actions.change_password') }}
        </v-btn>
        <v-btn class="card-actions-button ma-3 d-flex justify-center white--text" color="#20c997" disabled>
          {{ $t('user.actions.log_actions') }}
        </v-btn>
        <v-btn @click.stop="openDialog()" class="card-actions-button ma-3 d-flex justify-center" color="error" disabled>
          <font-awesome-icon icon="fa-solid fa-trash" class="mr-2" />
          {{ $t('user.actions.delete_user') }}
        </v-btn>
      </v-row>
    </v-row>

    <UserDataForm :editingUser="true" ref="userData" forCompany="0" :userData="userData"></UserDataForm>

    <v-row class="user-actions mx-3 mt-2">
      <v-expansion-panels class="card-stadistics expansion-panels mb-2">
        <v-expansion-panel class="expansion-panel">
          <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <v-row no-gutters>
                <v-col>
                  {{ $t('user.stadistics.stadistics') }}
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>

          <v-expansion-panel-content class="expansion-panel-content">
            <div class="pa-4">
              <span class="d-flex justify-space-between ma-3">
                <h3>{{ $t('user.stadistics.total_devices') }}</h3>
                {{ userData.devices.length }}
              </span>
              <span class="d-flex justify-space-between ma-3">
                <h3>{{ $t('user.stadistics.visible_devices') }}</h3>
                {{ visibleDevices }}
              </span>
              <v-divider class="my-6"></v-divider>
              <span class="d-flex justify-space-between ma-3">
                <h4>
                  {{ $t('user.stadistics.device_with_model') }}
                  <strong>{{ $t('user.stadistics.model_core') }} </strong>
                </h4>
                {{ coreModel }}
              </span>
              <span class="d-flex justify-space-between ma-3">
                <h4>
                  {{ $t('user.stadistics.device_with_model') }}
                  <strong>{{ $t('user.stadistics.model_trailer') }}</strong>
                </h4>
                {{ trailerModel }}
              </span>
              <span class="d-flex justify-space-between ma-3">
                <h4>
                  {{ $t('user.stadistics.device_with_model') }}
                  <strong>{{ $t('user.stadistics.model_storage') }}</strong>
                </h4>
                {{ storageModel }}
              </span>
              <span class="d-flex justify-space-between ma-3">
                <h4>
                  {{ $t('user.stadistics.device_with_model') }}
                  <strong>{{ $t('user.stadistics.model_tracker') }}</strong>
                </h4>
                {{ trackerModel }}
              </span>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <v-row class="btn-mobile-admin d-flex mt-10" style="justify-content: space-around">
      <v-btn :disabled="disabledButton" color="primary" class="d-flex" @click="save">
        <v-icon>mdi-content-save</v-icon>{{ $t('general.form.save') }}
      </v-btn>
      <v-btn color="red" class="d-flex" @click="goBack">
        <v-icon class="white--text">mdi-exit-to-app</v-icon>
        <span class="white--text">{{ $t('general.form.exit') }}</span>
      </v-btn>
    </v-row>

    <ErrorDialog
      :isActive="errorDialog"
      v-bind:tittleText="$t('general.dialog.error.title')"
      :messageText="errorText"
      v-bind:acceptButtonText="$t('general.dialog.error.accept_button')"
      v-on:accept-dialog="errorDialog = false">
    </ErrorDialog>

    <ConfirmationDialog
      :isActive="deleteDialog"
      v-on:cancel-dialog="closeDialog"
      v-on:accept-dialog="deleteUser(deleteMode)"
      v-bind:tittleText="$t('general.dialog.confirmation.title')"
      :messageText="dialogText"
      v-bind:cancelButtonText="$t('general.dialog.confirmation.button.cancel')"
      v-bind:acceptButtonText="$t('general.dialog.confirmation.button.accept_delete')">
    </ConfirmationDialog>
  </v-container>
</template>

<script>
import router from '../../../../plugins/router/index';
import UserDataForm from '../../../../components/forms/userDataForm/userDataForm.vue';
import { modifyUserByAdmin, getAllUserDevices, deleteUser, modifyAliasByAdmin } from '../../../../viewModels/adminViewModel';
import { ModifyUserByAdminException } from '../../../../server/petitions/users/modifyUserByAdmin';
import ErrorDialog from '../../../../components/errorDialog.vue';
import ConfirmationDialog from '../../../../components/confirmationDialog.vue';

import { eventBus } from '../../../../main';

export default {
  components: {
    UserDataForm,
    ErrorDialog,
    ConfirmationDialog,
  },
  props: {
    userData: {
      type: Object,
    },
  },
  data: () => ({
    disabledButton: false,
    errorDialog: false,
    errorText: '',
    deleteDialog: false,
    dialogText: '',
    deleteMode: 'soft',
    visibleDevices: 0,
    coreModel: 0,
    trailerModel: 0,
    storageModel: 0,
    trackerModel: 0,
    snackbar: false,
    snackbar2: false,
    snackbar3: false,
    snackbarColor: 'default',
    text: '',
    timeout: 4000,
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '../../../dashboard/temperatures',
      },
      {
        text: 'Usuarios',
        disabled: false,
        href: '/admin/users',
      },
      {
        text: 'Usuario',
        disabled: true,
        href: '',
      },
    ],
    deviceDuple: false,
    deviceNoDuple: false,
    deleteDevice: false,
  }),

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    deviceRepeat() {
      this.deviceDuple = true;
      if (this.deviceDuple) {
        this.snackbar = true;
        this.snackbarColor = 'error';
        this.text = this.$t('user.form.device_already_in_list');
      }
    },

    deviceNoRepeat() {
      this.deviceNoDuple = true;
      this.deleteDevice = false;
    },

    deleteSuccess() {
      this.deleteDevice = true;
      this.deviceNoDuple = false;
    },
    saveAlias: async function () {
      try {
        let userFormData = this.$refs.userData;

        const payload = { userId: userFormData.userId, device_sn: userFormData.device_sn, alias: userFormData.alias };

        await modifyAliasByAdmin(payload);

        if (this.deleteDevice) {
          this.snackbar = true;
          this.snackbarColor = 'success';
          this.text = this.$t('user.form.successfully_deleted');
        }
        if (this.deviceNoDuple) {
          this.snackbar = true;
          this.snackbarColor = 'success';
          this.text = this.$t('user.form.successfully_saved');
        }
      } catch (e) {
        this.snackbar = true;
        this.snackbarColor = 'error';
        this.text = this.$t('general.error.modifying_error');

        switch (e.code) {
          case ModifyUserByAdminException.unknownError:
          case ModifyUserByAdminException.incorrectParameters:
          case ModifyUserByAdminException.invalidToken:
          case ModifyUserByAdminException.expiredToken:
            console.error(e);
            this.errorText = this.$t('general.error.unexpected_error');
            this.errorDialog = true;
            break;
          case ModifyUserByAdminException.emailAlreadyInUser:
            this.errorText = this.$t('user.add_modify.error.email_already_in_use');
            this.errorDialog = true;
            break;

          default:
            console.error('CATCH ERROR:', e);
        }
      }
    },
    save: async function () {
      this.disabledButton = true;
      try {
        var userFormData = this.$refs.userData;

        if (userFormData.validate()) {
          let user_name = userFormData.name;
          let user_phone = userFormData.phone;
          let user_email = userFormData.email;
          let user_company = userFormData.selectedCompany;
          let user_notification_emails = userFormData.notificationsEmails;
          let user_restricted = userFormData.restricted;
          let user_devices_sn = userFormData.devicesToAdd;
          let user_removable_sn = userFormData.devicesToRemove;
          let user_notes = userFormData.notes;
          let user_roles = userFormData.selectedRoles;
          let user_platform = userFormData.selectedPlatform;
          let user_unit = userFormData.selectedUnit;
          let user_restrictions = userFormData.selectedRestrictions;
          let max_clients = userFormData.maxClients;

          const response = await modifyUserByAdmin(
            this.userData._id,
            user_name,
            user_phone,
            user_email,
            user_company,
            user_notification_emails,
            user_restricted,
            user_devices_sn,
            user_removable_sn,
            user_notes,
            user_roles,
            user_platform,
            user_unit,
            user_restrictions,
            max_clients,
          );

          if (this.$vuetify.breakpoint.mobile) {
            this.goBack();
          }

          if (response === 'SUCCESS') {
            this.snackbar = true;
            this.snackbarColor = 'success';
            this.text = this.$t('user.form.successfully_modified');
            setTimeout(() => {
              this.disabledButton = false;
            }, 2500);
          }
        }
      } catch (e) {
        this.snackbar = true;
        this.snackbarColor = 'error';
        this.text = this.$t('general.error.modifying_error');
        setTimeout(() => {
          this.disabledButton = false;
        }, 2500);

        switch (e.code) {
          case ModifyUserByAdminException.unknownError:
          case ModifyUserByAdminException.incorrectParameters:
          case ModifyUserByAdminException.invalidToken:
          case ModifyUserByAdminException.expiredToken:
            console.error(e);
            this.errorText = this.$t('general.error.unexpected_error');
            this.errorDialog = true;
            break;
          case ModifyUserByAdminException.emailAlreadyInUser:
            this.errorText = this.$t('user.add_modify.error.email_already_in_use');
            this.errorDialog = true;
            break;

          default:
            console.error('CATCH ERROR:', e);
        }
      }
    },
    passwordView: function () {
      router.push({
        name: 'changeUserPassword',
        params: { userId: this.userData._id, userData: this.userData },
      });
    },
    EditDataUser: function () {
      router.push({
        name: 'editDataUser',
        params: { userId: this.userData._id, userData: this.userData },
      });
    },
    goBack: function () {
      router.push({ name: 'users' });
    },
    openDialog: function () {
      this.dialogText = this.$t('user.delete.warning.user_will_delete', {
        name: this.userData.name,
        email: this.userData.email,
      });
      this.deleteDialog = true;
    },
    closeDialog: function () {
      this.deleteDialog = false;
      this.deleteMode = 'soft';
    },
    deleteUser: async function (mode) {
      try {
        await deleteUser(this.userData._id, mode);

        this.deleteDialog = false;
        this.goBack();
      } catch (e) {
        switch (e.code) {
          case DeleteUserException.unknownError:
          case DeleteUserException.incorrectParameters:
          case DeleteUserException.invalidToken:
          case DeleteUserException.expiredToken:
            console.error(e);
            this.errorText = this.$t('general.error.unexpected_error');
            this.errorDialog = true;
            break;
          case DeleteUserException.userSharesDevices:
            this.dialogText = this.$t('user.delete.error.user_shares_devices');
            this.deleteMode = 'hard';
            this.deleteDialog = true;
            break;
          default:
            console.error('CATCH ERROR:', e);
        }
      }
    },
  },

  updated() {},

  mounted: async function () {
    eventBus.$on('saveAlias', () => {
      this.saveAlias();
    });
    let userDevices = await getAllUserDevices(this.userData.devices);

    let devicesVisible = 0;
    let modelCore = 0;
    let modelTrailer = 0;
    let modelStorage = 0;
    let modelTracker = 0;

    for (let device of userDevices) {
      if (device.enabled == true) {
        devicesVisible += 1;
      }

      if (device.submodel == 'core') {
        modelCore += 1;
      }

      if (device.submodel == 'trailer') {
        modelTrailer += 1;
      }

      if (device.submodel == 'storage') {
        modelStorage += 1;
      }

      if (device.model == 'tracker') {
        modelTracker += 1;
      }
    }

    this.visibleDevices = devicesVisible;
    this.coreModel = modelCore;
    this.trailerModel = modelTrailer;
    this.storageModel = modelStorage;
    this.trackerModel = modelTracker;
  },
};
</script>
