const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class DeleteUserPOIException extends PetitionError {
  static errorDeletePOI = 'ERROR';

  constructor(code) {
    super('There is an error', code);
  }
}

async function deleteUserPOI(token, email, name, lat, lng) {
  const payload = {
    name, lat, lng, email,
  };

  const response = await post('users/delete_poi', payload, token);

  if (response.result !== DeleteUserPOIException.success) {
    return (new DeleteUserPOIException(response));
  }
  return response;
}

// class DeleteUserPOIException extends PetitionError {
//   static errorDeletePOI = 'ERROR';

//   constructor(code) {
//     super('There is an error', code);
//   }
// }

module.exports = {
  deleteUserPOI,
  DeleteUserPOIException,
};
