<template>
  <v-dialog
    v-model="isActive"
    max-width="50rem"
    @click:outside="cancelDialog"
    @keydown.esc="cancelDialog"
    class="ticket-view-modal">
    <v-card class="ticket-view-card" style="width: 100%" lazy-validation>
      <div id="device-data-form-container">
        <v-card-title color="primary" class="ticket-view-modal-title headline" style="justify-content: space-between">
          <div></div>
          <span class="ticket-view-modal-title-text">
            {{ $t('general.form.deviceCertificates') + ' ' + deviceData.device_sn }}
          </span>
          <span @click="cancelDialog" class="ticket-view-modal-title-btn">
            <font-awesome-icon style="color: red; cursor: pointer" icon="fa-solid fa-circle-xmark" class="close-ticket-promp" />
          </span>
        </v-card-title>
        <div style="margin-top: 5px" class="text-center pt-2"></div>

        <div class="device-data-form-table">
          <v-data-table
            :headers="certsTableHeader"
            :items="deviceData.certificate"
            item-key="name"
            hide-default-footer
            :page.sync="page"
            @page-count="pageCount = $event"
            :items-per-page="itemsPerPage"
            :loading="isTableLoading"
            class="elevation-1 ma-4 mt-0"
            style="min-height: 100px; max-height: 400px">
            <template #item.certificateUrl="{ value }">
              <a target="_blank" :href="value"> {{ $t('general.table.showCertificate') }} </a>
            </template>
          </v-data-table>
          <div style="margin-top: 25px" class="text-center pt-12">
            <v-pagination style="margin-bottom: 20px" v-model="page" :length="pageCount"></v-pagination>
          </div>
          <div style="margin-top: 2px" class="text-center pt-1"></div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    deviceData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: (instance) => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    formValid: false,
    isLoadingCerts: true,
    isTableLoading: true,

    serialNumber: instance.deviceData.device_sn,
    name: instance.deviceData.name,

    enabled: true,
    isActive: false,
  }),
  methods: {
    openWindow: async (event) => {
      window.open(event.certificateUrl);
    },
    cancelDialog: function () {
      this.$emit('cancel-dialog');
    },
    setActiveModal: function (active) {
      this.isActive = active;
    },
  },

  mounted: async function (e) {
    this.isTableLoading = false;
    this.enabled = this.deviceData.enabled;
    this.isLoadingCerts = false;
  },
  computed: {
    certsTableHeader() {
      return [
        { text: this.$t('general.form.name_certificate'), value: 'name' },
        { text: this.$t('general.form.certificate'), value: 'certificateUrl' },
        { text: this.$t('general.form.next_inspection'), value: 'nextInspectionDate' },
        { text: '', value: 'actions' },
      ];
    },
  },
};
</script>
