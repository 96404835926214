<template>
  <v-dialog v-model="isActive" max-width="55rem" @click:outside="cancelSetpoint" @keydown.esc="cancelSetpoint">
    <v-card class="ticket-view-card">
      <v-row>
        <v-card-title color="primary" class="texth5 font-weight-black">
          {{ this.$t('dashboard.configuration.config_setpoint') }} {{ device.device_sn }}
        </v-card-title>
      </v-row>
      <v-card-title color="primary" class="caption pt-0"></v-card-title>

      <v-divider class="py-1 mx-2" style="border-color: #607d8b"></v-divider>
      <v-card-title color="primary" class="caption pt-0">{{ $t('dashboard.configuration.setpoint') }} </v-card-title>
      <v-text-field
        @keydown.enter="save"
        background-color="transparent"
        :style="{
          color: theme === 'dark' ? 'white !important' : '',
        }"
        v-model.number="setpoint"
        :label="getSetpoint"
        solo
        class="mx-6 input_number"
        suffix="ºC"
        type="number"
        min="0"
        :rules="maxNameLengthRule">
      </v-text-field>

      <h5
        :style="{
          color: theme === 'dark' ? 'white !important' : '',
        }"
        style="font-style: italic"
        class="ml-4"
        v-if="pretrip">
        {{ $t('dashboard.configuration.pretrip_warning') }}
      </h5>
      <v-form>
        <v-container class="text-md-center">
          <v-btn :disabled="disabledButton" class="mx-lg-6" color="primary" elevation="2" x-large @click="save">
            <v-icon>mdi-content-save</v-icon>
            {{ this.$t('dashboard.configuration.send') }}
          </v-btn>
          <v-btn class="mx-lg-6" color="error" elevation="2" x-large @click="cancelSetpoint">
            <v-icon>mdi-cancel</v-icon>
            {{ this.$t('dashboard.configuration.cancel') }}
          </v-btn>
        </v-container>
      </v-form>
    </v-card>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="2000">
      {{ text }}
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { saveSetpoint } from '../../viewModels/devicesViewModel';
import { eventBus } from '../../main';
const ls = require('localstorage-slim');
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    device: {
      type: Object,
    },
    titleInput: {
      type: Boolean,
    },
  },
  data: () => ({
    snackbarColor: 'default',
    disabledButton: false,
    snackbar: false,
    text: '',
    setpoint: '',
    theme: ls.get('theme') ?? 'ligth',
    pretrip: false,
  }),
  computed: {
    maxNameLengthRule() {
      this.setpoint = this.device.user_actions?.setpoint ?? this.getSetpoint;

      return [
        (value) => Number(value) >= -25 || this.$t('dashboard.configuration.temp_min_setpoint'),
        (value) => Number(value) <= 25 || this.$t('dashboard.configuration.temp_max_setpoint'),
      ];
    },
    getSetpoint() {
      return this.device?.last_frames?.frame_frigo_machine?.frigo_machine?.temp_setpoint.toFixed(0);
    },
  },

  methods: {
    cancelSetpoint: function () {
      this.$emit('cancel-setpoint');
    },
    save: async function () {
      this.disabledButton = true;

      try {
        const payload = {
          device_sn: this.device.device_sn,
          setpoint: this.setpoint,
        };

        let res = await saveSetpoint(payload);

        if (res.result === 'SUCCESS') {
          this.snackbar = true;
          this.snackbarColor = 'success';
          this.text = this.$t('dashboard.configuration.request_sent');

          setTimeout(() => {
            this.disabledButton = false;
            this.cancelSetpoint();
            this.pretrip = false;
          }, 2500);

          if (this.pretrip) {
            eventBus.$emit('pretrip-received', this.setpoint, this.pretrip);
          } else {
            eventBus.$emit('pretrip-received', this.setpoint, this.pretrip);
          }
        }
      } catch (e) {
        this.snackbar = true;
        this.snackbarColor = 'error';
        this.text = this.$t('dashboard.configuration.error_req');
        setTimeout(() => {
          this.disabledButton = false;
          this.cancelSetpoint();
        }, 2500);
      }
    },
  },

  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });

    eventBus.$on('isPretrip', () => {
      this.pretrip = true;
    });
  },
};
</script>
