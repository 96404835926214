<template>
  <div id="app">
    <v-row>
      <v-col sm="8" lg="12" class="mb-4 controls">
        <v-sheet :dark="theme === 'dark' ? true : false">
          <v-row sm="12" lg="12">
            <div style="display: flex; width: 100%" class="mt-1">
              <v-btn elevation="0" fab small left color="primary" class="mt-2 ml-5" @click="$refs.calendar.prev()">
                <v-icon dark>mdi-chevron-left</v-icon>
              </v-btn>

              <v-select
                v-model="weekday"
                :items="weekdays"
                dense
                outlined
                hide-details
                :label="$t('general.calendar.weeks')"
                class="ma-2"></v-select>
              <v-btn elevation="0" fab small right color="primary" class="mt-2" @click="$refs.calendar.next()">
                <v-icon dark>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <br /><br />
            <!-- <v-select v-model="type" :items="types" dense outlined hide-details class="ma-2" label="Tipo"></v-select>
              <br /> -->
            <br />
          </v-row>
        </v-sheet>
        <v-sheet :dark="theme === 'dark' ? true : false" class="mt-2">
          <v-container fluid>
            <v-select
              v-model="selectedDevices"
              :items="alerts"
              multiple
              chips
              hide-details
              dense
              :menu-props="{ maxHeight: '400' }"
              persistent-hint
              :label="$t('general.calendar.device')"
              class="ma-2">
              <template v-slot:selection="{ item, index }">
                <v-chip :style="{ 'background-color': theme === 'dark' ? 'grey' : '' }" v-if="index < 3">
                  <span>{{ item }}</span>
                </v-chip>
                <span v-if="index == 3" class="grey--text text-caption"> (+{{ selectedDevices.length - 1 }}) </span>
              </template>
              <template v-slot:prepend-item>
                <v-list-item ripple @mousedown.prevent :title="$t('general.calendar.select_all')" @click="toggle">
                  <v-list-item-action>
                    <v-icon :color="selectedDevices.length > 0 ? 'indigo darken-4' : ''">
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> {{ $t('general.calendar.select_all') }} </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-container>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row> </v-row>
    <v-col sm="12" lg="9" class="pl-4">
      <v-sheet :dark="theme === 'dark' ? true : false" height="auto" width="135%">
        <EventDialog v-model="newEvent" ref="eventDialog" @eventDialog:submit="addEventToAlarms" />
        <v-menu
          transition="scale-transition"
          location="end"
          v-model="selectedOpen"
          :activator="selectedElement"
          offset-y
          :close-on-content-click="false"
          content-class="v_menu_custom"
          ref="parentMenuRef">
          <v-card width="350px" style="display: flex; flex-direction: column; align-items: center">
            <!-- Agregar Funcionalidades Editar y Eliminar, de momento no se puede.-->
            <v-toolbar :color="colorCard" dark style="display: flex; justify-content: center; width: 100%">
              <div style="display: flex; flex-direction: row; align-items: center">
                <div style="margin-right: 20px">
                  <v-icon>mdi-devices</v-icon>
                </div>
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-btn class="mx-lg-2" :color="selectedEvent.color" elevation="0" small @click="() => eventRemove(selectedEvent)"
                  ><v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </div>
            </v-toolbar>
            <v-card-text style="display: flex; justify-content: center">
              <div>
                <span>{{ $t('general.calendar.serial_number') }} </span>
                <span v-html="selectedEvent.device_sn"></span>
              </div>
            </v-card-text>
            <v-card-text style="display: flex; justify-content: center">
              <div>
                <span>{{ $t('general.calendar.description') }}</span>
                <span v-html="selectedEvent.description"></span>
              </div>
            </v-card-text>
            <v-card-text style="display: flex; justify-content: center">
              <div>
                <span>{{ $t('general.calendar.from') }}</span>
                <span v-html="selectedEventStart"></span>
              </div>
            </v-card-text>
            <v-card-text style="display: flex; justify-content: center">
              <div>
                <span>{{ $t('general.calendar.to') }}</span>
                <span v-html="selectedEventEnd"></span>
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-calendar
          ref="calendar"
          @click:event="showEvent"
          @click:day="(event) => assignEvent(event)"
          v-model="value"
          :items="alerts"
          :weekdays="weekday"
          :type="type"
          :event-ripple="false"
          :events="alarmsFromSelectedDevices"
          :color="color"
          @click="dialog">
        </v-calendar>
        <!-- <v-calendar
          ref="calendar"
          @click:event="showEvent"
          @click:day="(event) => assignEvent(event)"
          v-model="value"
          :items="alerts"
          :weekdays="weekday"
          :type="type"
          :event-ripple="false"
          :events="alarmsFromSelectedDevices"
          :color="color"
          @click="dialog"
          @mousedown:event="startDrag"
          @mousedown:time="startTime"
          @mousemove:time="mouseMove"
          @mouseup:time="endDrag"
          @mouseleave.native="cancelDrag"
          :short-months="shortMonths"
          :short-weekdays="shortWeekdays">
        </v-calendar> -->
      </v-sheet>
    </v-col>
  </div>
</template>
<style>
.v_menu_custom {
  max-width: 350px !important;
  min-width: 350px !important;
}
</style>
<script>
import { getReminders, saveReminder, deleteReminder } from '../../../viewModels/reminderViewModel';
import EventDialog from './eventDialog.vue';
import { getUser } from '../../../viewModels/userViewModel';
import { getUserDevices } from '../../../server/petitions/users/getUserDevices';
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';

export default {
  components: {
    // Certs,
    EventDialog,
  },
  data: (instance) => ({
    newEvent: {
      name: '',
      color: '#4CAF50',
      start: '',
      end: '',
      description: '',
      device_sn: Number(),
      devices: [],
      locale: '',
      notification_emails: [],
      platform: '',
      interval: 10,
      selectedDevice: [],
      // id: '',
      userId: '',
    },
    defaultEvent: {
      name: '',
      color: '#4CAF50',
      start: '',
      end: '',
      description: '',
      device_sn: Number(),
      devices: [],
      locale: '',
      notification_emails: [],
      platform: '',
      interval: 10,
      selectedDevice: [],
      // id: '',
      userId: '',
    },
    theme: ls.get('theme') ?? 'ligth',
    type: 'month',
    // types: [
    //   { text: 'Dia', value: 'day' },
    //   { text: '4 días', value: '4day' },
    //   { text: 'Semama', value: 'week' },
    //   { text: 'Mes', value: 'month' },
    // ],
    selectedDevices: [],
    alerts: [],
    weekday: [1, 2, 3, 4, 5, 6, 0],
    weekdays: [
      { text: 'Dom - Sáb', value: [0, 1, 2, 3, 4, 5, 6] },
      { text: 'Lun - Dom', value: [1, 2, 3, 4, 5, 6, 0] },
      { text: 'Lun - Vie', value: [1, 2, 3, 4, 5] },
    ],
    value: '',
    events: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'primary', 'orange', 'grey darken-1'],
    color: 'green',
    reminderCollection: null,
    deviceCollection: null,
    // shortMonths: false,
    // shortWeekdays: false,

    hexCode: '#4CAF50',

    description: null,
    dialog: false,
    reminderName: null,
    device_sn: null,
    selectedOpen: false,
    selectedElement: {},
    selectedEvent: {},
    // focus: '',
    colorCard: '',
    selectedEventStart: '',
    selectedEventEnd: '',
  }),
  methods: {
    async eventRemove(select) {
      let res = await deleteReminder(select.id);

      if (res.result == 'SUCCESS') {
        const index = this.reminderCollection.findIndex((element) => element._id == select.id);
        index !== -1 ? this.reminderCollection.splice(index, 1) : null;
        this.$refs.parentMenuRef.isActive = false;
      }
    },
    //FUNCIONA
    async addEventToAlarms() {
      this.newEvent.locale = this.user.locale;
      this.newEvent.platform = this.user.platform;
      this.newEvent.userId = this.user.userId;
      this.newEvent.start = this.newEvent.start;

      this.newEvent.end = this.newEvent.end;
      this.newEvent.device_sn = Number(this.newEvent.selectedDevice.split('-')[0]);
      this.newEvent.notification_emails = this.user.notificationsEmails;

      const { selectedDevice, devices, ...newObject } = this.newEvent;

      let response = await saveReminder(newObject);

      this.newEvent.start = new Date(this.newEvent.start).toISOString().split('-' && 'T')[0];
      this.newEvent.end = new Date(this.newEvent.end).toISOString().split('-' && 'T')[0];
      this.newEvent.id = response.id;

      this.reminderCollection.push(this.newEvent);
    },

    async assignEvent(event) {
      this.newEvent = Object.assign({}, this.defaultEvent);
      this.dialog = true;
      this.newEvent.start = event.date;
      this.newEvent.end = event.date;
      this.newEvent.devices = this.deviceCollection.map((element) => `${element.device_sn}-${element.alias}`);

      if (this.events.length == 1) this.newEvent.device_sn = Number(this.events[0].device_sn);
      this.$refs.eventDialog.setActiveModal(true);
    },

    // FUNCIONA
    showEvent({ nativeEvent, event }) {
      this.selectedEventStart = new Date(event.start).toISOString().split('-' && 'T')[0];
      this.selectedEventEnd = new Date(event.end).toISOString().split('-' && 'T')[0];
      this.colorCard = event.color;
      this.device_sn = event.device_sn;
      this.reminderName = event.name;

      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)));
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    // async selectedDay({ day }) {
    //   this.focus = day;
    //   this.type = 'day';
    // },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllDevices) {
          this.selectedDevices = [];
        } else {
          this.selectedDevices = this.alerts.slice();
        }
      });
    },
  },

  computed: {
    maxlength() {
      return [(v) => v.length < 2 || this.$t('general.calendar.maxLength')];
    },
    alarmsFromSelectedDevices() {
      let result = [];
      let reminderFiltered = this.reminderCollection?.filter((reminder) => this.selectedDevices.includes(reminder.device_sn));

      if (reminderFiltered !== undefined && reminderFiltered.length > 0) {
        // this.events = [];
        for (const reminder of reminderFiltered) {
          const dateStartSplit = reminder.start.split('-' && 'T');
          const dateEndSplit = reminder.end.split('-' && 'T');

          const formatDateStart = dateStartSplit[0];
          const formatDateEnd = dateEndSplit[0];
          const allDay = this.rnd(0, 3) === 0;

          result.push({
            name: reminder.name,
            start: formatDateStart,
            end: formatDateEnd,
            color: reminder.color ?? this.colors[this.rnd(0, this.colors.length - 1)],
            timed: !allDay,
            device_sn: reminder.device_sn,
            description: reminder.description ?? '',
            id: reminder._id,
          });
        }
      }
      return result;
    },
    likesAllDevices() {
      return this.selectedDevices.length === this.alerts.length;
    },
    likesSomeDevices() {
      return this.selectedDevices.length > 0 && !this.likesAllDevices;
    },
    icon() {
      if (this.likesAllDevices) return 'mdi-close-box';
      if (this.likesSomeDevices) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },

  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
    this.user = await getUser();
    this.deviceCollection = await getUserDevices(this.user.token, this.user.userId);

    this.reminderCollection = await getReminders();

    this.deviceCollection.map(async (device) => {
      this.selectedDevices.push(device.device_sn);
      this.alerts.push(device.device_sn);
    });
  },
};
</script>
