const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class ModifyMetricsException extends PetitionError {
  constructor(code) {
    super('Modify User By Itslef', code);
  }
}

async function modifyMetricsPetition(user_email, user_unit, token) {
  const payload = {
    email: user_email,
    unit: user_unit,
  };

  const response = await post('users/modify_metrics', payload, token);

  if (response.result !== ModifyMetricsException.success) {
    throw new ModifyMetricsException(response.result);
  } else {
    return response.user; // TODO transform
  }
}

module.exports = {
  modifyMetricsPetition,
  ModifyMetricsException,
};
