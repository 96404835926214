const PetitionError = require('../../petitionError');
const { get } = require('../../httpRequester');

class getAllReminderException extends PetitionError {
  constructor(code) {
    super('Get all reminder', code);
  }
}

async function getAllReminder(userId, token) {
  const response = await get(`reminder/${userId}`, token);
  if (response.result !== getAllReminderException.success) {
    return new getAllReminderException(response.result);
  }
  return response.response_reminder; // TODO transform
}

module.exports = {
  getAllReminder,
  getAllReminderException,
};
