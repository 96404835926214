const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class SaveSetpointDeviceException extends PetitionError {
  static errorToSetpoint = 'ERROR_TO_MODIFIED_SETPOINT';

  constructor(code) {
    super('Save setpoint', code);
  }
}

async function saveSetpointDevice(token, payload) {
  const response = await post('devices/setpoint', payload, token);
  if (response.result !== SaveSetpointDeviceException.success) {
    return new SaveSetpointDeviceException(response.result);
  }
  return response; // TODO transform
}

module.exports = {
  saveSetpointDevice,
  SaveSetpointDeviceException,
};
