<template>
  <v-container class="my-3">
    <h1 class="d-flex justify-start">Historial de Informes</h1>
    <v-divider></v-divider>
    <v-data-table
      :loading="isTableLoading"
      :headers="reportsTableHeader"
      :items="notificationTable"
      :items-per-page="10"
      class="elevation-1"
      :search="search"
      v-bind:loading-text="$t('general.table.loading')"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon medium class="mr-1" color="error" @click.stop="deleteRow(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Buscar informe"
          class="mx-4"
        ></v-text-field>
      </template>
    </v-data-table>
  </v-container>
</template>


<script>
export default {
  data: () => ({}),
  methods: {},
  computed: {
    reportsTableHeader() {
      return [
        {
          text: "Nombre",
          value: "name",
          width: "7rem",
          divider: true,
        },
        {
          text: "Tipo",
          value: "type",
          width: "5rem",
          divider: true,
        },
        {
          text: "Fecha y hora",
          value: "date",
          width: "5rem",
          divider: true,
        },
      ];
    },
  },
};
</script>
