const PetitionError = require('../../petitionError');
const { getRaw } = require('../../httpRequester');

class GetAdminCertificateException extends PetitionError {
  constructor(code) {
    super(' ERROR : Error when getting the certificate', code);
  }
}

async function getAdminCertificate(token, deviceSn) {
  const data = await getRaw(`devices/${deviceSn}/admin_certificate`, token);
  const response = await data.json();

  if (response.result !== GetAdminCertificateException.success) {
    throw new GetAdminCertificateException(response.result);
  } else {
    return response.certificate; // TO DO transform
  }
}

module.exports = { getAdminCertificate, GetAdminCertificateException };
