class PetitionError extends Error {
  static success = 'SUCCESS';

  static incorrectParameters = 'INCORRECT_PARAMETERS';

  static invalidToken = 'INVALID_TOKEN';

  static expiredToken = 'EXPIRED_TOKEN';

  static notEnoutghPermissions = 'NOT_ENOUTGH_PERMISSIONS';

  static unknownError = 'UNKNOWN_ERROR';

  constructor(petitionName, errorCode) {
    super(`Error in ${petitionName}: ${errorCode}`);

    this.code = errorCode;
  }
}

module.exports = PetitionError;
