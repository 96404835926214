const PetitionError = require('../../petitionError');
const { postRaw } = require('../../httpRequester');
const { downloadReport } = require('../../../utils/utils');

class GetTemperatureTicketException extends PetitionError {
  static unknownDevice = 'UNKNOWN_DEVICE';

  static noDataInDate = 'NO_DATA_IN_DATE';

  constructor(code) {
    super('Get Temperature Ticket', code);
  }
}

async function getTemperatureTicket(
  format,
  date_from,
  date_to,
  temperature_interval,
  device_sn,
  carplate,
  cif,
  token,
  user_email,
  probes,
) {
  const alias = carplate == null || carplate == '' ? device_sn : carplate;
  const dateTimeFormat = Intl.DateTimeFormat().resolvedOptions();
  const payload = {
    format,
    date_from,
    date_to,
    temperature_interval,
    device_sn,
    carplate: alias,
    cif,
    timezone: new Date().getTimezoneOffset() / 60,
    token,
    user_email,
    probes,
    dateTimeFormat,
  };

  const response = await postRaw('reports/get_temperature_ticket', payload, token);
  if (response.status !== 200) {
    const result = await response.json();
    throw new GetTemperatureTicketException(result.result);
  } else {
    const fileName = `Ticket-${alias}`;
    downloadReport(response, fileName);
  }
}

module.exports = {
  getTemperatureTicket,
  GetTemperatureTicketException,
};
