const PetitionError = require('../../petitionError');
const { get } = require('../../httpRequester');

class MarkUpdateSeenPetitionException extends PetitionError {
  constructor(code) {
    super('Mark Update Seen', code);
  }
}

async function markUpdateSeenPetition(token) { // TODO add security
  const response = await get('users/mark_update_seen', token);

  if (response.result !== MarkUpdateSeenPetitionException.success) {
    throw (new MarkUpdateSeenPetitionException(response.result));
  } else {
    return response.users; // TO DO transform
  }
}

// class MarkUpdateSeenPetitionException extends PetitionError {
//   constructor(code) {
//     super('Mark Update Seen', code);
//   }
// }

module.exports = {
  markUpdateSeenPetition,
  MarkUpdateSeenPetitionException,
};
