const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class ModifyUserByAdminException extends PetitionError {
  static emailAlreadyInUser = 'EMAIL_ALREADY_IN_USER';

  constructor(code) {
    super('Modify User By Admin', code);
  }
}

async function modifyUserByAdmin(
  user_id,
  user_name,
  user_phone,
  user_email,
  user_company,
  user_notification_emails,
  user_restricted,
  user_devices_sn,
  user_removable_sn,
  user_notes,
  user_roles,
  user_platform,
  user_unit,
  user_restrictions,
  token,
  max_clients,
) {
  const payload = {
    user_id,
    name: user_name,
    phone: user_phone,
    email: user_email,
    notification_emails: user_notification_emails,
    company_id: user_company,
    restricted: user_restricted,
    devices_sn: user_devices_sn,
    removable_sn: user_removable_sn,
    roles: user_roles,
    notes: user_notes,
    platform: user_platform,
    unit: user_unit,
    restrictions: user_restrictions,
    max_clients: max_clients,
  };

  const response = await post('users/modify_by_admin', payload, token);

  if (response.result !== ModifyUserByAdminException.success) {
    throw new ModifyUserByAdminException(response.result);
  } else {
    return response.result;
  }
}
async function modifyDeviceUserByAdmin(token, payload) {
  const response = await post('users/modify_device_by_admin', payload, token);

  if (response.result !== ModifyUserByAdminException.success) {
    throw new ModifyUserByAdminException(response.result);
  } else {
    return response.user.value; // TODO transform
  }
}

module.exports = { modifyUserByAdmin, ModifyUserByAdminException, modifyDeviceUserByAdmin };
