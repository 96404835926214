<template>
  <v-card class="elevation-2 ma-2">
    <v-card-text class="pt-0">
      <div class="text-overline font-weight-bold">
        {{ $t('dashboard.device_card.approved') }} <br />
        {{ $t('dashboard.device_card.approved2') }}
      </div>

      <v-divider class="py-1 mx-2" style="border-color: #607d8b"></v-divider>

      <div class="ml-1 mb-2 d-flex flex-column">
        <span class="mb-1">
          <v-icon :class="checkFrigo" x-small> mdi-circle </v-icon>
          {{ frigoTimestamp }}
        </span>
      </div>

      <v-row>
        <v-col>
          <!-- eslint-disable-next-line -->
          <v-row class="justify-space-around" v-for="(row, index) in getNumberOfColums.firstCol" v-bind:key="row.key">
            <v-chip
              @click="openConfiguration"
              v-if="types[row.probe]"
              label
              small
              class="justify-center ma-1 white--text blue-grey lighten-1"
              style="width: 50%">
              {{ row.name }}
            </v-chip>
            <v-chip
              @click="temperatureView"
              v-if="types[row.probe]"
              label
              small
              class="justify-center ma-1 blue-grey lighten-4"
              style="width: 35%">
              <span
                >{{ temp[row.probe] }}
                {{ types[row.probe] == 'hum' ? '%' : types[row.probe] == 'temp' ? 'ºC' : types[row.probe] == 'airq' ? 'AQ' : '' }}
              </span>
            </v-chip>
          </v-row>
        </v-col>

        <v-divider style="width: 10%" vertical></v-divider>

        <v-col>
          <!-- eslint-disable-next-line -->
          <v-row v-for="(row, index) in getNumberOfColums.secondCol" v-bind:key="row.key" class="justify-space-around">
            <v-chip
              v-if="types[row.probe]"
              @click="openConfiguration"
              label
              small
              class="justify-center ma-1 white--text blue-grey lighten-1"
              style="width: 50%">
              {{ row.name }}
            </v-chip>
            <v-chip
              @click="temperatureView"
              v-if="types[row.probe]"
              label
              small
              class="justify-center ma-1 blue-grey lighten-4"
              style="width: 35%">
              <span>
                {{ temp[row.probe] }}
                {{ types[row.probe] == 'hum' ? '%' : types[row.probe] == 'temp' ? 'ºC' : types[row.probe] == 'airq' ? 'AQ' : '' }}
              </span>
            </v-chip>
          </v-row>
        </v-col>
      </v-row>

      <div class="d-flex flex-row my-6">
        <div class="d-flex justify-center flex-column" style="width: 100%">
          <span class="d-flex justify-center">
            {{ $t('dashboard.temp.engine_today') }}
          </span>
          <v-chip label small class="justify-center ma-1 blue-grey lighten-4" style="width: 95%">
            <span> {{ getTodayEngine }}</span>
          </v-chip>
        </div>

        <v-divider style="width: 10%" vertical></v-divider>

        <div class="d-flex flex-column" style="width: 100%">
          <span class="d-flex justify-center">
            {{ $t('dashboard.temp.engine_hours') }}
          </span>
          <v-chip @click="openFrigoHours" label small class="justify-center ma-1 blue-grey lighten-4" style="width: 95%">
            <span>{{ getEngineHours }}</span>
          </v-chip>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { eventBus } from '../../../../main';
import router from '../../../../plugins/router/index';

export default {
  components: {},
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
    todaysEngineHours: {},
    frigoTimestamp: {
      type: String,
    },
    status: {
      type: Object,
    },
  },
  data: () => ({
    firstColumn: [],
    secondColumn: [],
    frigoHours: null,
    devices: [],
    types: null,
    temp: null,
  }),
  computed: {
    getNumberOfColums() {
      this.firstColumn = [];
      this.secondColumn = [];

      function flattenObject(obj, result = {}) {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
              flattenObject(obj[key], result);
            } else {
              result[key] = obj[key];
            }
          }
        }

        return result;
      }

      const unifiedProbesObject = flattenObject(this.device.last_frames.frame_frigo.probes);
      for (const i in unifiedProbesObject) {
        const probeNumber = i.split('p')[1];
        const probeName = this.device.probes_config?.[i]?.name
          ? this.device.probes_config[i].name
          : `${this.$t('device.last_frames.frame_frigo.probe')} ${probeNumber}`;

        this[probeNumber % 2 === 1 ? 'firstColumn' : 'secondColumn'].push({
          probe: i,
          name: probeName,
          hasTemp: true,
          key: probeNumber,
        });
      }
      return { firstCol: this.firstColumn, secondCol: this.secondColumn };
    },
    getTodayEngine() {
      if (!this.device.last_frames.frame_frigo) {
        return 'No data';
      }
      if (this.todaysEngineHours < 0) {
        return '0 h';
      }
      if (this.todaysEngineHours > 24) {
        return 'Error';
      }
      return this.todaysEngineHours + ' h';
    },
    checkFrigo() {
      if (this.status.frigo) {
        return 'green--text';
      }
      return 'gray--text';
    },
    getEngineHours() {
      let conditionalReturn;
      if (this.device.last_frames.frame_frigo && this.frigoHours == null) {
        conditionalReturn = this.device.last_frames.frame_frigo.engine_time.offset_applied.toFixed(0) + ' h';
      } else {
        conditionalReturn = this.frigoHours + ' h';
      }
      return conditionalReturn;
    },
  },
  methods: {
    openFrigoHours() {
      this.$emit('openFrigoHours');
    },
    openConfiguration() {
      this.$emit('openConfiguration');
    },
    temperatureView() {
      router.push({
        name: 'temperatures',
        params: { device: this.device },
      });
      if (this.isMobile) {
        this.$emit('onCloseInfo');
      }
    },
  },
  mounted: async function () {
    this.getNumberOfColums;
    this.types = Object.fromEntries(
      Object.entries(this.device.device_data_from_frame.probes_type).filter(([key, value]) => value !== null),
    );
    this.temp = Object.fromEntries(
      Object.entries(this.device.last_frames.frame_frigo.probes_temperature).filter(([key, value]) => value !== null),
    );
  },

  beforeUpdate() {},
  updated() {
    eventBus.$on('refresh-frigoHours', (frigo_hours) => {
      this.frigoHours = frigo_hours;
    });
  },
};
</script>
