const getAllNotificationsPetition = require('../server/petitions/notifications/getUserNotifications');
const updateAllNotificationsPetition = require('../server/petitions/notifications/updateUserNotifications');
const userViewModel = require('./userViewModel');

const notificationsViewModel = function () {
  let notifications = [];

  async function getUserNotifications() {
    if (notifications.length === 0) {
      const token = userViewModel.getToken();
      const user = await userViewModel.getUser();
      const user_email = user.email;

      notifications = await getAllNotificationsPetition.getUserNotifications(token, user_email);
      return notifications;
    }
    return notifications;
  }

  async function getUserNotificationsBySn() {
    const token = userViewModel.getToken();
    notifications = await getAllNotificationsPetition.getUserNotificationsBySn(token);

    return notifications;
  }

  function addNewNotification(newNotification) {
    let isAlreadyInside = false;
    for (const notification of notifications.notifications) {
      if (notification.date == newNotification.date && notification.type == newNotification.type) {
        isAlreadyInside = true;
      }
    }
    if (!isAlreadyInside) {
      notifications.notifications.push(newNotification);
    }
  }

  async function updateNotification(id, state) {
    const token = userViewModel.getToken();

    notifications = await updateAllNotificationsPetition.updateUserNotifications(token, id, state);
    return notifications;
  }

  return { getUserNotifications, getUserNotificationsBySn, addNewNotification, updateNotification };
};

module.exports = notificationsViewModel();
