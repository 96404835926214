<template>
  <div>
    <Navbar @showDeviceList="showDeviceList" :class="showBanner ? 'navbar-with-banner' : ''"></Navbar>
    <v-banner shaped v-if="showBanner" class="fixed-banner">
      {{ computedBannerMessage }}
      <v-icon slot="icon" color="warning" size="22"> mdi-calendar-clock </v-icon>
      <v-icon @click="closeBanner" size="22" class="close-icon"> mdi-close</v-icon>
    </v-banner>
    <div v-if="firstTime"><ChangePassword /></div>
    <div v-if="restricted"><UserRestricted /></div>
    <UpdatesDialog :isActive="updatesDialog" v-on:accept-dialog="acceptDialog"> </UpdatesDialog>
    <v-main id="container-general-dashboard" style="overflow: hidden">
      <router-view ref="dashboard"></router-view>
      <Footer></Footer>
    </v-main>
  </div>
</template>

<script>
import ChangePassword from './views/changePassword.vue';
import UserRestricted from './views/userRestricted.vue';
import Navbar from './components/navBar/navbar.vue';
import userviewModel from './viewModels/userViewModel';
import BrokerConnection from './server/brokerConnection/brokerConnection';
import UpdatesDialog from './components/updatesDialog.vue';
import Footer from './components/footer/footer.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    ChangePassword,
    UserRestricted,
    UpdatesDialog,
    Footer,
  },
  data: () => ({
    firstTime: false,
    updatesDialog: false,
    restricted: false,
    showBanner: false,
    day: '',
    hour: '',
  }),
  methods: {
    setUserDefaultLanguage: function () {
      userviewModel.setLanguageToUserDefault(this);
    },
    showDeviceList: function () {
      this.$refs.dashboard.showDeviceList();
    },
    initializeBrokerConnection: async function () {
      await BrokerConnection.initializeBroker();
    },

    acceptDialog() {
      this.updatesDialog = false;
      userviewModel.markUpdateSeen();
    },
    closeBanner() {
      this.showBanner = false;
    },
  },

  async mounted() {
    const user = await userviewModel.getUser();
    if (user.firstTime) {
      this.firstTime = true;
      this.updatesDialog = false;
    }

    if (user.updating_system.is_update) {
      this.day = user.updating_system.day;
      this.hour = user.updating_system.hour;
      this.showBanner = true;
    }

    if (user.restricted) {
      this.restricted = true;
    } else {
      this.updatesDialog = user.update_seen ? false : true;
    }
    //
    this.setUserDefaultLanguage();
    //this.initializeBrokerConnection();
  },

  computed: {
    computedBannerMessage() {
      return this.$t('general.next_maintenance', {
        day: this.day,
        hour: this.hour,
      });
    },
  },
};
</script>

<style>
.navbar-with-banner {
  position: fixed !important;
  top: 27px !important;
}

.fixed-banner {
  position: fixed;
  top: -20px;
  z-index: 1000;
  width: 100% !important;
  background-color: transparent !important;
}

.close-icon {
  position: fixed !important;
  right: 5px !important;
  cursor: pointer;
}
</style>
