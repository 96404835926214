<template>
  <v-form ref="companyForm" v-model="formValid" lazy-validation>
    <v-row class="d-flex mt-5">
      <v-col cols="3">
        <label class="mt-2 font-weight-black float-left">{{ $t('company.atribute.name') }}*</label>
      </v-col>
      <v-col cols="9">
        <v-text-field dense outlined class="rounded-lg" type="text" v-model="companyName" :rules="textRules" required>
        </v-text-field>
      </v-col>
    </v-row>

    <v-row class="d-flex">
      <v-col cols="3">
        <label class="mt-2 font-weight-black float-left">{{ $t('company.atribute.email') }}</label>
      </v-col>
      <v-col cols="9">
        <v-text-field dense outlined class="rounded-lg" type="text" v-model="companyEmail" :rules="emailRules"></v-text-field>
      </v-col>
    </v-row>

    <v-row class="d-flex">
      <v-col cols="3">
        <label class="mt-2 font-weight-black float-left">{{ $t('company.atribute.phone') }}</label>
      </v-col>
      <v-col cols="9">
        <v-text-field dense outlined class="rounded-lg" type="tel" :rules="phoneRules" v-model="companyPhone" maxlength="9">
        </v-text-field>
      </v-col>
    </v-row>

    <v-row class="d-flex">
      <v-col cols="3">
        <label class="mt-2 font-weight-black float-left">{{ $t('company.atribute.cif') }}*</label>
      </v-col>
      <v-col cols="9">
        <v-text-field dense outlined class="rounded-lg" type="text" v-model="companyCIF" :rules="cifRules" maxlength="9">
        </v-text-field>
      </v-col>
    </v-row>

    <v-row class="d-flex">
      <v-col cols="3">
        <label class="mt-2 font-weight-black float-left">{{ $t('company.atribute.notes') }}</label>
      </v-col>
      <v-col cols="9">
        <v-text-field
          dense
          outlined
          counter
          :maxlength="maxCharacters"
          :rules="notesRules"
          class="rounded-lg"
          type="text"
          v-model="companyNotes">
        </v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: {
    companyData: {
      type: Object,
      default: () => ({}),
    },
    editingCompany: {
      type: Boolean,
      default: false,
    },
  },
  data: (instance) => ({
    isLoadingDevices: true,
    formValid: false,

    companyName: instance.companyData.name,
    companyPhone: instance.companyData.phone,
    companyEmail: instance.companyData.email,
    companyCIF: instance.companyData.cif,
    companyNotes: instance.companyData.notes,
    maxCharacters: 500,
  }),
  methods: {
    addDevice: function () {},
    addNotificationEmail: function () {
      this.notificationsEmails.push(this.notificationEmail);
      this.notificationEmail = '';
    },
    deleteEmail: function (index) {
      this.notificationsEmails.splice(index, 1);
    },
    validate: function () {
      return this.$refs.companyForm.validate();
    },
  },
  mounted: async function () {
    this.devices = '';
    this.companies = '';
  },
  computed: {
    textRules() {
      return [(value) => !!value || this.$t('general.error.empty_value')];
    },
    cifRules() {
      return [
        (value) =>
          (!!value && /^([a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1})|(\d{8}[a-zA-Z]{1})|([a-zA-Z]{1}\d{7}[a-zA-Z]{1})$/.test(value)) ||
          this.$t('general.error.not_valid_cif'),
      ];
    },
    emailRules() {
      return [(value) => (!!value && /.+@.+/.test(value)) || this.$t('general.error.not_valid_email')];
    },

    phoneRules() {
      return [(value) => (!!value && /^[0-9]*$/.test(value) && value.length === 9) || this.$t('general.error.must_be_number')];
    },

    notesRules() {
      return [(value) => /^.{0,499}$/.test(value) || this.$t('user.atribute.misc.notes_limit')];
    },
  },
};
</script>
