const deviceUtils = function () {
  function checkNotifications(probesConfig, probesTemperature, frameStatus, insidePoi) {
    const notifications = {
      hasNotifications: false,
      doorOpen: false,
      p1Min: false,
      p1Max: false,
      p2Min: false,
      p2Max: false,
      insidePoi: false,
    };

    if (probesConfig && probesTemperature) {
      if (probesConfig.p1) {
        if (probesConfig.p1.min) {
          if (probesConfig.p1.min > probesTemperature.p1) {
            notifications.hasNotifications = true;
            notifications.p1Min = true;
          }
        }
        if (probesConfig.p1.max) {
          if (probesConfig.p1.max < probesTemperature.p1) {
            notifications.hasNotifications = true;
            notifications.p1Max = true;
          }
        }
      }
      if (probesConfig.p2) {
        if (probesConfig.p2.min) {
          if (probesConfig.p2.min > probesTemperature.p2) {
            notifications.hasNotifications = true;
            notifications.p2Min = true;
          }
        }
        if (probesConfig.p2.max) {
          if (probesConfig.p2.max < probesTemperature.p2) {
            notifications.hasNotifications = true;
            notifications.p2Max = true;
          }
        }
      }
    }

    if (frameStatus && frameStatus.door) {
      notifications.hasNotifications = true;
      notifications.doorOpen = true;
    }

    if (insidePoi) {
      notifications.hasNotifications = true;
      notifications.insidePoi = true;
    }

    return notifications;
  }

  function getGpsHourTimestamp(gps_timestamp) {
    const date = new Date(gps_timestamp);
    const today = new Date();
    const day = 60 * 60 * 24 * 1000;
    const diff = Math.round(Math.abs((today - date) / day));
    let time;
    if (diff >= 1) {
      let dayNumber = date.getDate();
      if (dayNumber.toString().length < 2) {
        dayNumber = `0${dayNumber}`;
      }
      let month = date.getMonth() + 1;
      if (month.toString().length < 2) {
        month = `0${month}`;
      }
      time = `${dayNumber}-${month}`;
    } else {
      let hours = date.getHours();
      if (hours.toString().length < 2) hours = `0${hours}`;
      let minutes = date.getMinutes();
      if (minutes.toString().length < 2) minutes = `0${minutes}`;
      time = `${hours}:${minutes}`;
    }
    return time;
  }

  return {
    checkNotifications,
    gpsHourTimestamp: getGpsHourTimestamp,
  };
};

module.exports = deviceUtils();
