<template>
  <v-container v-if="!isMobile" class="container-report-desktop">
    <v-row class="container-report-desktop-row my-3">
      <v-col cols="7">
        <h1 class="d-flex justify-start">
          {{ $t('reports.create') }}
        </h1>

        <v-divider
          :style="{
            'background-color': theme === 'dark' ? 'white !important' : '',
          }"></v-divider>
        <v-row>
          <v-col>
            <!-- <h3 class="d-flex justify-start">
              {{ $t("reports.gps_reports") }}
            </h3> -->
            <v-checkbox
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="km_count"
              :label="$t('reports.types.mileage')"></v-checkbox>
            <v-checkbox
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="driving_hours"
              :label="$t('reports.types.working_time')"></v-checkbox>
            <v-checkbox
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="map_route"
              :label="$t('reports.types.map_route')"></v-checkbox>
            <v-checkbox
              @click="uncheckAll('allPiSum')"
              v-model="reportsTypeSelected"
              hide-details
              value="pi_sum"
              :label="$t('reports.types.pois')"></v-checkbox>
            <v-checkbox
              @click="uncheckAll('allDelivery')"
              v-model="reportsTypeSelected"
              hide-details
              value="poi_delivery"
              :label="$t('reports.types.delivery_poi')"></v-checkbox>
            <v-checkbox
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="journey_sum"
              :label="$t('reports.types.journey_summary')"></v-checkbox>
          </v-col>
          <v-col>
            <!-- <h3 class="d-flex justify-start">
                {{ $t("reports.frigo_reports") }}
              </h3> -->
            <v-checkbox
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="temp_report"
              :label="$t('reports.types.temp_ticket')"></v-checkbox>
            <v-checkbox
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="humidity_report"
              :label="$t('reports.types.humidity_report')"></v-checkbox>
            <v-checkbox
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="temp_graph"
              :label="$t('reports.types.temp_graph')"></v-checkbox>
            <v-checkbox
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="humidity_graph"
              :label="$t('reports.types.humidity_graph')"></v-checkbox>

            <v-checkbox
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="engine_hours"
              :label="$t('reports.types.engine_hours')"></v-checkbox>
            <v-checkbox
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="open_doors"
              :label="$t('reports.types.open_doors')"></v-checkbox>
            <v-checkbox
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="custom"
              :label="$t('reports.types.custom')"></v-checkbox>
          </v-col>
        </v-row>
        <v-row class="px-6" v-if="selectedDevicesProbes.length > 0">
          <v-label>{{ $t('reports.probes') }}</v-label>
          <v-checkbox
            v-for="probe in selectedDevicesProbes"
            :key="probe"
            :label="$t(`device.last_frames.frame_frigo.${probe}`)"
            :value="probe"
            v-model="selectedProbes"
            hide-details
            class="ma-2"></v-checkbox>
        </v-row>
        <v-row>
          <h5>
            <v-icon color="primary">mdi-information-outline</v-icon>
          </h5>
          <span class="report-duration-info">{{ $t('reports.duration_info') }}</span>
        </v-row>
      </v-col>

      <v-col cols="5" class="container-report-calendar">
        <v-date-picker
          :min="d"
          :max="maxDays"
          :show-current="new Date().toISOString().substr(0, 10)"
          color="primary"
          show-adjacent-months
          full-width
          no-title
          first-day-of-week="1"
          elevation="5"
          v-model="dates"
          range
          @input="validateRange">
          <v-alert v-if="invalidDate" dismissible border="right" type="error">{{ $t('dashboard.tickets.rangeMaxDate') }}</v-alert>
        </v-date-picker>

        <v-toolbar class="my-1" dense prominent rounded>
          <v-icon color="primary">mdi-calendar-range</v-icon>
          <v-row v-if="dates.length == 0" class="text-sm-body-2 mx-2">
            <v-col :style="{ color: colorTheme }" cols="12">{{ $t('reports.select_a_date') }}</v-col>
          </v-row>
          <v-row v-else class="text-sm-body-2 mx-2" :style="{ 'align-items': 'center', color: colorTheme }">
            <v-col cols="5">
              <strong
                >{{ new Date(dates[0]) > new Date(dates[1]) == true ? formatDate(dates[1]) : formatDate(dates[0]) }}
              </strong>
              <v-text-field v-model="startingTime" type="time" />
            </v-col>
            <v-col cols="2">{{ $t('general.form.until') }}</v-col>
            <v-col cols="5">
              <strong
                >{{ new Date(dates[0]) < new Date(dates[1]) == false ? formatDate(dates[0]) : formatDate(dates[1]) }}
              </strong>
              <v-text-field v-model="endingTime" type="time" />
            </v-col>
          </v-row>
        </v-toolbar>
      </v-col>
    </v-row>

    <v-row v-if="invalidReport">
      <v-col cols="10">
        <v-alert
          v-if="invalidReport"
          border="top"
          color="red lighten-2"
          prominent
          type="error"
          dark
          v-click-outside="removeAlert">
          {{ errorField }}
        </v-alert>
      </v-col>
    </v-row>
    <v-divider
      :style="{
        'background-color': theme === 'dark' ? 'white !important' : '',
      }"
      v-if="invalidReport"></v-divider>

    <v-row>
      <v-col cols="10">
        <v-autocomplete
          :dark="theme === 'dark'"
          v-if="frigoDevices.length > 0"
          v-model="selectedFrigoDevice"
          @change="selectFrigoDevice"
          :items="searchApaches"
          color="primary"
          hide-no-data
          hide-selected
          item-text="device_sn"
          item-value="device_sn"
          :label="$t('device.form.search_device')"
          prepend-icon="mdi-magnify"></v-autocomplete>
        <div v-if="frigoDevices.length === 0 && !noDevices">
          <v-autocomplete prepend-icon="mdi-loading mdi-spin" :label="$t('device.form.loading')"></v-autocomplete>
        </div>
        <div v-if="noDevices">
          <v-autocomplete :label="$t('reports.no_devices')"></v-autocomplete>
        </div>
      </v-col>
      <v-col cols="1" class="d-flex align-center justify-center">
        <v-tooltip bottom open-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" @click="checkAll('frigo')" class="btn-report-marked">
              <v-icon v-bind="attrs" v-on="on"> mdi-checkbox-marked </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('reports.select_all') }}</span>
        </v-tooltip>
      </v-col>
      <v-col cols="1" class="d-flex align-center justify-center">
        <v-tooltip bottom open-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="error" @click="uncheckAll('frigo')" class="btn-report-marked">
              <v-icon v-bind="attrs" v-on="on"> mdi-checkbox-blank </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('reports.deselect_all') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row class="container-report-desktop-devices px-6">
      <v-checkbox
        :disabled="reportsTypeSelected[0] == 'poi_delivery'"
        v-for="device in searchApaches"
        :key="Number(device.split(' ~ ')[1])"
        :label="device.split(' ~ ')[0]"
        v-model="frigoDevicesSelected"
        :search-input.sync="frigoSearchInput"
        :value="Number(device.split(' ~ ')[1])"
        hide-details
        class="ma-2"></v-checkbox>
    </v-row>
    <v-row class="mt-6">
      <v-col cols="10">
        <v-autocomplete
          :dark="theme === 'dark'"
          v-if="trackerDevices.length > 0"
          v-model="selectedTrackerDevice"
          @change="selectTrackerDevice"
          :items="searchTrackers"
          color="primary"
          hide-no-data
          hide-selected
          item-text="device_sn"
          item-value="_id"
          :label="$t('device.form.search_tracker')"
          prepend-icon="mdi-magnify"></v-autocomplete>
        <div v-if="trackerDevices.length === 0 && !noTrackers">
          <v-autocomplete prepend-icon="mdi-loading mdi-spin" :label="$t('device.form.loading')"></v-autocomplete>
        </div>
        <div v-if="noTrackers">
          <v-autocomplete :label="$t('reports.no_trackers')"></v-autocomplete>
        </div>
      </v-col>
      <v-col cols="1" class="d-flex align-center justify-center">
        <v-tooltip bottom open-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" @click="checkAll('tracker')" class="btn-report-marked">
              <v-icon v-bind="attrs" v-on="on"> mdi-checkbox-marked </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('reports.select_all') }}</span>
        </v-tooltip>
      </v-col>
      <v-col cols="1" class="d-flex align-center justify-center">
        <v-tooltip bottom open-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="error" @click="uncheckAll('tracker')" class="btn-report-error">
              <v-icon v-bind="attrs" v-on="on"> mdi-checkbox-blank </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('reports.deselect_all') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row class="container-report-desktop-tracker px-6">
      <v-checkbox
        :disabled="reportsTypeSelected[0] == 'poi_delivery'"
        v-for="device in searchTrackers"
        :key="Number(device.split(' ~ ')[1])"
        :label="device.split(' ~ ')[0]"
        v-model="trackerDevicesSelected"
        :search-input.sync="trackerSearchInput"
        :value="Number(device.split(' ~ ')[1])"
        hide-details
        class="ma-2">
      </v-checkbox>
    </v-row>
    <v-row class="d-flex justify-center mt-6">
      <v-btn color="primary" class="d-flex justify-space-between mx-15" @click="generate">
        <v-icon class="mr-1">mdi-content-save</v-icon>
        {{ $t('general.form.generate') }}
      </v-btn>

      <v-btn color="red" class="d-flex justify-space-between mx-15" @click="discard">
        <v-icon class="white--text mr-1"> mdi-delete </v-icon>
        <span class="white--text">{{ $t('general.form.clean') }}</span>
      </v-btn>
    </v-row>
  </v-container>

  <!-- Mobile view -->

  <v-container v-else class="container-report-mobile">
    <v-row class="my-3">
      <v-col>
        <h1 class="d-flex justify-start">
          {{ $t('reports.create') }}
        </h1>
        <v-divider
          :style="{
            'background-color': theme === 'dark' ? 'white !important' : '',
          }"></v-divider>
        <v-row class="my-4"
          >.
          <v-col>
            <v-checkbox
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="km_count"
              :label="$t('reports.types.mileage')"></v-checkbox>
            <v-checkbox
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="driving_hours"
              :label="$t('reports.types.working_time')"></v-checkbox>
            <v-checkbox
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="map_route"
              :label="$t('reports.types.map_route')"></v-checkbox>
            <v-checkbox
              @click="uncheckAll('allPiSum')"
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="pi_sum"
              :label="$t('reports.types.pois')"></v-checkbox>
            <v-checkbox
              @click="uncheckAll('allDelivery')"
              v-model="reportsTypeSelected"
              hide-details
              value="poi_delivery"
              :label="$t('reports.types.delivery_poi')"></v-checkbox>
            <v-checkbox
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="journey_sum"
              :label="$t('reports.types.journey_summary')"></v-checkbox>
            <v-checkbox
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="custom"
              :label="$t('reports.types.custom')"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="temp_report"
              :label="$t('reports.types.temp_ticket')"></v-checkbox>
            <v-checkbox
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="humidity_report"
              :label="$t('reports.types.humidity_report')"></v-checkbox>
            <v-checkbox
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="temp_graph"
              :label="$t('reports.types.temp_graph')"></v-checkbox>
            <v-checkbox
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="humidity_graph"
              :label="$t('reports.types.humidity_graph')"></v-checkbox>

            <v-checkbox
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="engine_hours"
              :label="$t('reports.types.engine_hours')"></v-checkbox>
            <v-checkbox
              :disabled="reportsTypeSelected.some((i) => i == 'poi_delivery' || i == 'pi_sum')"
              v-model="reportsTypeSelected"
              hide-details
              value="open_doors"
              :label="$t('reports.types.open_doors')"></v-checkbox>
          </v-col>
        </v-row>
      </v-col>

      <v-row cols="5" class="container-report-calendar-mobile">
        <v-date-picker
          min="2020-01-01"
          :max="new Date().toISOString().substr(0, 10)"
          :show-current="new Date().toISOString().substr(0, 10)"
          color="primary"
          show-adjacent-months
          full-width
          no-title
          first-day-of-week="1"
          elevation="5"
          v-model="dates"
          range
          class="data-picker"></v-date-picker>

        <v-toolbar class="my-1" dense rounded>
          <v-icon color="primary" class="report-subtitle-icon-calendar">mdi-calendar-range</v-icon>
          <v-toolbar-title v-if="dates.length == 0" class="report-subtitle-calendar text-sm-body-2 mx-2">
            {{ $t('reports.select_a_date') }}
          </v-toolbar-title>
          <v-toolbar-title v-else class="text-sm-body-2 mx-2">
            <strong>{{ new Date(dates[0]) > new Date(dates[1]) == true ? formatDate(dates[1]) : formatDate(dates[0]) }}</strong>
            {{ $t('general.form.until') }}
            <strong>{{ new Date(dates[0]) < new Date(dates[1]) == false ? formatDate(dates[0]) : formatDate(dates[1]) }}</strong>
            {{ howManyDaysSelected() }}
          </v-toolbar-title>
        </v-toolbar>
      </v-row>
    </v-row>

    <v-divider
      :style="{
        'background-color': theme === 'dark' ? 'white !important' : '',
      }"></v-divider>

    <v-row>
      <v-col cols="10" class="selector-report-devices">
        <v-autocomplete
          :dark="theme === 'dark'"
          v-if="frigoDevices.length > 0"
          v-model="selectedFrigoDevice"
          @change="selectFrigoDevice"
          :items="searchBarDevices"
          color="primary"
          hide-no-data
          hide-selected
          item-text="device_sn"
          item-value="device_sn"
          :label="$t('device.form.search_device')"
          prepend-icon="mdi-magnify"></v-autocomplete>
        <div v-if="frigoDevices.length === 0 && !noDevices">
          <v-autocomplete prepend-icon="mdi-loading mdi-spin" :label="$t('device.form.loading')"></v-autocomplete>
        </div>
        <div v-if="noDevices">
          <v-autocomplete :label="$t('reports.no_devices')"></v-autocomplete>
        </div>
      </v-col>
    </v-row>

    <v-row class="px-6 container-report-mobile-devices">
      <v-checkbox
        v-for="(device, index) in frigoDevices"
        :key="device.id"
        :label="devicesNames[index] || device.device_data_from_frame.carplate || device.device_sn.toString()"
        v-model="frigoDevicesSelected"
        :search-input.sync="frigoSearchInput"
        :value="device.device_sn"
        hide-details
        class="ma-2"></v-checkbox>
    </v-row>

    <v-row class="mt-6">
      <v-col cols="10">
        <v-autocomplete
          :dark="theme === 'dark'"
          v-if="trackerDevices.length > 0"
          v-model="selectedTrackerDevice"
          @change="selectTrackerDevice"
          :items="searchBarTrackers"
          color="primary"
          hide-no-data
          hide-selected
          item-text="device_sn"
          item-value="_id"
          :label="$t('device.form.search_tracker')"
          prepend-icon="mdi-magnify"></v-autocomplete>
        <div v-if="trackerDevices.length === 0 && !noTrackers">
          <v-autocomplete prepend-icon="mdi-loading mdi-spin" :label="$t('device.form.loading')"></v-autocomplete>
        </div>
        <div v-if="noTrackers">
          <v-autocomplete :label="$t('reports.no_trackers')"></v-autocomplete>
        </div>
      </v-col>
    </v-row>

    <v-row class="px-6 container-report-mobile-tracker">
      <v-checkbox
        v-for="(device, index) in trackerDevices"
        :key="device.id"
        :label="trackersNames[index] || device.device_data_from_frame.carplate || device.device_sn.toString()"
        v-model="trackerDevicesSelected"
        :search-input.sync="trackerSearchInput"
        :value="device.device_sn"
        hide-details
        class="ma-2"></v-checkbox>
    </v-row>
    <br />
    <v-alert v-if="invalidReport" border="top" color="red lighten-2" prominent type="error" dark v-click-outside="removeAlert">
      {{ errorField }}
    </v-alert>

    <v-col class="d-flex justify-center mt-5">
      <v-btn color="primary" class="d-flex justify-space-between ma-8" @click="generate">
        <v-icon>mdi-content-save</v-icon>{{ $t('general.form.generate') }}
      </v-btn>

      <v-btn color="red" class="d-flex justify-space-between ma-8" @click="discard">
        <v-icon class="white--text">mdi-exit-to-app</v-icon>
        <span class="white--text">{{ $t('general.form.exit') }}</span>
      </v-btn>
    </v-col>
    <br />
    <v-spacer></v-spacer>
  </v-container>
</template>

<script>
import { getAllUserDevices } from '../../viewModels/adminViewModel';
import { getUserDevices } from '../../server/petitions/users/getUserDevices';
import { getUser } from '../../viewModels/userViewModel';
import store from '../../plugins/store';
import { validateDateRange, maxDate } from '../../utils/utils';
const ls = require('localstorage-slim');
import { eventBus } from '../../main';

export default {
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    colorTheme: ls.get('theme') === 'dark' ? 'white !important' : '',
    theme: ls.get('theme') ?? 'ligth',
    d: '1900-01-01',
    userDevices: [],
    devicesNames: [],
    dates: [],
    trackersNames: [],
    reportsTypeSelected: [],
    frigoDevices: [],
    selectedFrigoDevice: '',
    frigoSearchInput: '',
    frigoDevicesSelected: [],
    trackerDevices: [],
    selectedTrackerDevice: '',
    trackerSearchInput: '',
    trackerDevicesSelected: [],
    unknown: '',
    errorField: '',
    errorTracker: null,
    invalidReport: false,
    loading: 'Loading data...',
    noTrackers: false,
    noDevices: false,
    allFieldsOk: false,
    searchBarDevices: [],
    searchBarTrackers: [],
    selectedProbes: [],
    startingTime: '00:00',
    endingTime: '23:59',
    devices: [],
    invalidDate: false,
    searchApaches: [],
    searchTrackers: [],
  }),

  methods: {
    discard: function () {
      this.dates = [];
      this.reportsTypeSelected = [];
      this.frigoDevicesSelected = [];
      this.trackerDevicesSelected = [];
    },
    verifyFields() {
      if (!this.dates.length) {
        this.errorField = this.$t('reports.missing.dates');
        this.invalidReport = true;
        return;
      }

      if (!this.reportsTypeSelected.length) {
        this.errorField = this.$t('reports.missing.report_type');
        this.invalidReport = true;
        return;
      }
      if (
        !this.frigoDevicesSelected.length &&
        !this.trackerDevicesSelected.length &&
        this.reportsTypeSelected[0] !== 'poi_delivery'
      ) {
        this.errorField = this.$t('reports.missing.device');
        this.invalidReport = true;
        return;
      }
      if (this.trackerDevicesSelected.length >= 1 && this.frigoDevicesSelected.length == 0) {
        let selectedReports;
        let reportsTypeCollection = [
          'engine_hours',
          'custom',
          'temp_graph',
          'temp_report',
          'humidity_report',
          'humidity_graph',
          'open_doors',
        ];
        let checkReportsValidation = this.reportsTypeSelected.some((value) => {
          selectedReports = value;
          return reportsTypeCollection.includes(value);
        });

        if (checkReportsValidation) {
          if (this.reportsTypeSelected.length >= 2) {
            this.errorField = this.$t('reports.missing.wrong_devices');
            this.invalidReport = true;
          } else {
            this.errorField = this.$t('reports.missing.wrong_device') + ' ' + this.$t(`reports.types.${selectedReports}`);
            this.invalidReport = true;
          }
          return;
        }
      }
      this.allFieldsOk = true;
    },

    markCheckboxFromSearchDevices() {
      this.frigoDevicesSelected = [...new Set(this.frigoDevicesSelected)];

      for (let [index, device] of this.frigoDevicesSelected.entries()) {
        if (this.searchApaches.find((element) => element === device)) {
          const index = this.searchApaches.indexOf(device);
          this.frigoDevicesSelected[index] = this.frigoDevices[index].device_sn;
        }
      }
    },

    markCheckboxFromSearchTrackers() {
      this.trackerDevicesSelected = [...new Set(this.trackerDevicesSelected)];

      for (let [index, device] of this.trackerDevicesSelected.entries()) {
        if (this.searchTrackers.find((element) => element === device)) {
          const index = this.searchTrackers.indexOf(device);
          this.trackerDevicesSelected[index] = this.trackerDevices[index].device_sn;
        }
      }
    },

    filterOnlySerialNumbers() {
      this.frigoDevicesSelected = [...new Set(this.frigoDevicesSelected)].filter(this.onlyNumericValues);

      this.trackerDevicesSelected = [...new Set(this.trackerDevicesSelected)].filter(this.onlyNumericValues);
    },

    generate: async function () {
      this.allFieldsOk = false;
      this.filterOnlySerialNumbers();
      let userDevicesSn;
      if (this.reportsTypeSelected[0] === 'poi_delivery') {
        userDevicesSn = this.frigoDevices.map((i) => i.device_sn).concat(this.trackerDevices.map((i) => i.device_sn));
      } else {
        userDevicesSn = this.frigoDevicesSelected.concat(this.trackerDevicesSelected);
      }
      this.verifyFields();

      if (this.allFieldsOk) {
        let devicesCarplate = [];
        let devicesModel = [];
        let devicesName = [];
        for (let deviceSn of userDevicesSn) {
          this.devices.map((device) => {
            if (deviceSn == device.device_sn) devicesCarplate.push(device.alias);
          });
          for (let userDevice of this.userDevices) {
            if (deviceSn == userDevice.device_sn) {
              devicesModel.push(userDevice.model);
              devicesName.push(userDevice.name ?? null);
            }
          }
        }
        const date_from = this.dates[0] > this.dates[1] == true ? this.dates[1] : this.dates[0];
        const date_to = this.dates[0] < this.dates[1] == false ? this.dates[0] : this.dates[1];
        let routeData = this.$router.resolve({
          name: 'loader',
          query: {
            type: 'report',
            dateFrom: date_from,
            dateTo: date_to,
            startingTime: this.startingTime,
            endingTime: this.endingTime,
            devices_sn: userDevicesSn,
            probes: this.selectedProbes,
            carplate: devicesCarplate,
            name: devicesName,
            model: devicesModel,
            km_count: this.reportsTypeSelected.includes('km_count'),
            driving_hours: this.reportsTypeSelected.includes('driving_hours'),
            map_route: this.reportsTypeSelected.includes('map_route'),
            pi_sum: this.reportsTypeSelected.includes('pi_sum'),
            poi_delivery: this.reportsTypeSelected.includes('poi_delivery'),
            engine_hours: this.reportsTypeSelected.includes('engine_hours'),
            temp_graph: this.reportsTypeSelected.includes('temp_graph'),
            journey_sum: this.reportsTypeSelected.includes('journey_sum'),
            temp_report: this.reportsTypeSelected.includes('temp_report'),
            humidity_report: this.reportsTypeSelected.includes('humidity_report'),
            open_doors: this.reportsTypeSelected.includes('open_doors'),
            custom: this.reportsTypeSelected.includes('custom'),
            humidity_graph: this.reportsTypeSelected.includes('humidity_graph'),
          },
        });
        window.open(routeData.href, '_blank');
        // this.discard();
      }
    },
    selectFrigoDevice: function () {
      this.frigoDevicesSelected.push(this.selectedFrigoDevice);
      this.$nextTick(() => {
        this.frigoSearchInput = '';
        this.selectedFrigoDevice = null;
      });
    },
    selectTrackerDevice: function () {
      this.trackerDevicesSelected.push(this.selectedTrackerDevice);
      this.$nextTick(() => {
        this.trackerSearchInput = '';
        this.selectedTrackerDevice = null;
      });
    },
    checkAll: function (type) {
      if (type == 'tracker') {
        for (let device of this.userDevices) {
          if (device.model == 'tracker') {
            this.trackerDevicesSelected.push(device.device_sn);
          }
        }
      }
      if (type == 'frigo') {
        for (let device of this.userDevices) {
          if (device.model !== 'tracker') {
            this.frigoDevicesSelected.push(device.device_sn);
          }
        }
      }
    },
    onlyNumericValues(value) {
      if (typeof value === 'number') {
        return value;
      }
    },
    uncheckAll: function (type) {
      if (type == 'allDelivery') {
        this.trackerDevicesSelected = [];
        this.frigoDevicesSelected = [];

        if (this.reportsTypeSelected.includes('poi_delivery')) this.reportsTypeSelected = ['poi_delivery'];
      }

      if (type == 'allPiSum') {
        if (this.reportsTypeSelected.includes('pi_sum')) this.reportsTypeSelected = ['pi_sum'];
      }
      if (type == 'tracker') this.trackerDevicesSelected = [];

      if (type == 'frigo') this.frigoDevicesSelected = [];
    },
    formatDate: function (date) {
      if (!date) {
        return '. . .';
      }

      let months = [
        this.$t('general.months.january'),
        this.$t('general.months.february'),
        this.$t('general.months.march'),
        this.$t('general.months.april'),
        this.$t('general.months.may'),
        this.$t('general.months.june'),
        this.$t('general.months.july'),
        this.$t('general.months.august'),
        this.$t('general.months.september'),
        this.$t('general.months.october'),
        this.$t('general.months.november'),
        this.$t('general.months.december'),
      ];
      let splitedDate = date.split('-');
      let formatedDate = `${splitedDate[2]} ${this.$t('general.form.of')} ${months[new Date(date).getMonth()]} ${this.$t(
        'general.form.of',
      )} ${splitedDate[0]}`;
      return formatedDate;
    },
    howManyDaysSelected: function () {
      if (!this.dates[1]) {
        return '';
      }
      const oneDay = 24 * 60 * 60 * 1000;
      const firstDate = new Date(this.dates[0]);
      const secondDate = new Date(this.dates[1]);

      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
      return `(${diffDays + 1} ${this.$t('reports.days')})`;
    },

    async setPreselectedDevice() {
      let preselectedDevice;
      if (preselectedDevice.model) {
        if (preselectedDevice.model == 'tracker') {
          this.trackerDevicesSelected.push(preselectedDevice.device_sn);
        } else {
          this.frigoDevicesSelected.push(preselectedDevice.device_sn);
        }
      }
    },

    removeAlert() {
      this.invalidReport = false;
    },
    updateSearchBarWithCustomNames() {
      for (let [index, device] of this.frigoDevices.entries()) {
        this.searchBarDevices.push(
          this.devicesNames[index]
            ? this.devicesNames[index] + ' / ' + device.device_sn.toString()
            : device.device_sn.toString() ||
                device.device_data_from_frame.carplate + ' / ' + device.device_sn.toString() ||
                device.device_sn.toString(),
        );
      }

      for (let [index, device] of this.trackerDevices.entries()) {
        this.searchBarTrackers.push(
          this.trackersNames[index]
            ? this.trackersNames[index] + ' / ' + device.device_sn.toString()
            : device.device_sn.toString() ||
                device.device_data_from_frame.carplate + ' / ' + device.device_sn.toString() ||
                device.device_sn.toString(),
        );
      }
    },
    validateRange() {
      this.invalidDate = false;
      let validateDate = validateDateRange(this.dates);
      if (validateDate) {
        this.invalidDate = true;
        this.dates = validateDate;
      }
    },
  },

  watch: {
    // whenever question changes, this function will run
    selectedFrigoDevice: function () {
      this.markCheckboxFromSearchDevices();
    },

    selectedTrackerDevice: function () {
      this.markCheckboxFromSearchTrackers();
    },

    selectedDevicesProbes: function (newVal, oldVal) {
      this.selectedProbes = newVal;
    },
  },

  computed: {
    maxDays() {
      return maxDate(this.dates);
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    dateRangeText() {
      return this.dates.join(' ~ ');
    },

    selectedDevices() {
      return this.frigoDevicesSelected.map((deviceSn) => this.userDevices.find((device) => device.device_sn == deviceSn));
    },

    selectedDevicesProbes() {
      let probes = [];
      this.selectedDevices.forEach((device) => {
        if (device) {
          Object.entries(device.device_data_from_frame.probes_type).forEach(([probeName, probeValue]) => {
            if (probeValue != null && probes.indexOf(probeName) < 0) {
              probes = [...probes, probeName];
            }
          });
        }
      });
      return probes;
    },
  },

  beforeMount() {
    if (store.state?.reportDevice) {
      if (store.state.reportDevice.model != 'tracker') {
        this.frigoDevicesSelected.push(store.state.reportDevice.device_sn);
      } else {
        this.trackerDevicesSelected.push(store.state.reportDevice.device_sn);
      }
    }
  },

  beforeDestroy() {
    this.$store.dispatch('setReportDevice', this.device.device_sn);
  },

  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
      this.colorTheme = theme === 'dark' ? 'white !important' : '';
    });
    let user = await getUser();
    this.devices = await getUserDevices(user.token, user.userId);
    this.devices.map((i) => {
      if (i.model == 'tracker') {
        this.searchTrackers.push(i.alias + ' ~ ' + i.device_sn);
      } else {
        this.searchApaches.push(i.alias + ' ~ ' + i.device_sn);
      }
    });

    this.userDevices = await getAllUserDevices(user.devices);

    for (let device of user.devices) {
      if (device.model != 'tracker') {
        this.devicesNames.push(device.name);
      } else {
        this.trackersNames.push(device.name);
      }
    }

    for (let device of this.userDevices) {
      if (device.model == 'tracker') {
        this.trackerDevices.push(device);
      } else {
        this.frigoDevices.push(device);
      }
    }

    if (this.trackerDevices.length === 0) {
      this.noTrackers = true;
    }
    if (this.frigoDevices.length === 0) {
      this.noDevices = true;
    }
    this.updateSearchBarWithCustomNames();
    //this.setPreselectedDevice();
  },
};
</script>
