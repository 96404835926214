const OSM_ADDRESS_COMPONENTS_ALIASES = {
  house_number: ['street_number', 'housenumber'],
  house: ['building', 'public_building', 'isolated_dwelling', 'farmland', 'allotments'],
  road: [
    'footway',
    'street',
    'street_name',
    'residential',
    'path',
    'pedestrian',
    'road_reference',
    'road_reference_intl',
    'square',
    'place',
  ],
  hamlet: ['locality', 'croft'],
  village: [],
  neighbourhood: [
    'suburb',
    'city_district',
    'district',
    'quarter',
    'borough',
    'city_block',
    'residential',
    'commercial',
    'industrial',
    'houses',
    'subdistrict',
    'subdivision',
    'ward',
  ],
  postal_city: [],
  city: ['town', 'township'],
  municipality: ['local_administrative_area', 'subcounty'],
  county: ['county_code', 'department'],
  state_district: [],
  postcode: ['postal_code', 'partial_postcode'],
  state: ['province', 'state_code'],
  region: [],
  island: [],
  archipelago: [],
  country: ['country_name', 'countryName'],
  country_code: [],
  continent: [],
};

const findAlias = (osmAddress, component) =>
  osmAddress[OSM_ADDRESS_COMPONENTS_ALIASES[component]?.find((alias) => osmAddress[alias])];

/**
 * Returns an object with the normalized Address (applying aliases)
 * @param osmAddress Open Street Maps address object
 * @param fields Object defining which fields to return. Object values indicates the key to return. Defaults are given.
 */
const normalizedAddress = (osmAddress, fields = { road: 'street', city: 'city', state: 'state', country: 'countryName' }) => {
  if (!osmAddress) return null;
  return Object.fromEntries(
    Object.entries(fields).map(([key, val]) => [val, osmAddress[key] ?? findAlias(osmAddress, key) ?? '']),
  );
};

/**
 * Returns a string represeting the address that contains the fields provided as a param
 * @param osmAddress Open Street Maps address object
 * @param fields Object defining which fields to return. Object values indicates the key to return. Defaults are given.
 */
const shortAddress = (osmAddress, fields = { road: 'street', city: 'city', state: 'state', country: 'countryName' }) => {
  if (!osmAddress) return '';
  return Object.values(normalizedAddress(osmAddress, fields))
    .filter((value) => value != '')
    .join(', ');
};

module.exports = {
  shortAddress,
  normalizedAddress,
};
