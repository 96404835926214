<template>
  <v-dialog v-model="isActive" max-width="60rem" scrollable @click:outside="acceptDialog">
    <v-card>
      <v-card-title class="headline white--text" style="background-color: #2962ff">
        {{ $t('general.dialog.updates.title') }}
      </v-card-title>

      <v-card-text class="my-4 font-weight-bold flex-grow-1">
        <div class="my-4">
          <v-divider class="my-1"></v-divider>
          <h2 class="d-flex align-center ml-5">
            <v-icon class="mr-1">mdi-bookmark-outline</v-icon>{{ release
            }}<span class="text-subtitle-1 mx-2">{{ date_2_4_0 }}</span>
          </h2>
          <v-divider class="my-1"></v-divider>
          <div class="px-10 ma-2">
            {{ $t('general.dialog.updates.added') }}:
            <ul class="mx-1 my-3">
              <li>{{ $t('general.dialog.updates.2_4_0.0') }}</li>
              <li>{{ $t('general.dialog.updates.2_4_0.1') }}</li>
              <li>{{ $t('general.dialog.updates.2_4_0.2') }}</li>
              <li>{{ $t('general.dialog.updates.2_4_0.3') }}</li>
              <li>{{ $t('general.dialog.updates.2_4_0.4') }}</li>
              <li>{{ $t('general.dialog.updates.2_4_0.5') }}</li>
            </ul>
          </div>
        </div>

        <template>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="d-flex align-center ml-5">
                  <v-icon class="mr-1">mdi-bookmark-outline</v-icon>{{ release_2_3_2
                  }}<span class="text-subtitle-1 mx-2">{{ date_2_3_2 }}</span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="px-10 ma-2">
                  {{ $t('general.dialog.updates.added') }}:
                  <ul class="mx-1 my-3">
                    <li>{{ $t('general.dialog.updates.2_3_1.0') }}</li>
                    <li>{{ $t('general.dialog.updates.2_3_2.1') }}</li>
                    <li>{{ $t('general.dialog.updates.2_3_2.2') }}</li>
                    <li>{{ $t('general.dialog.updates.2_3_2.3') }}</li>
                  </ul>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="d-flex align-center ml-5">
                  <v-icon class="mr-1">mdi-bookmark-outline</v-icon>{{ release_2_3_3
                  }}<span class="text-subtitle-1 mx-2">{{ date_2_3_3 }}</span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="px-10 ma-2">
                  {{ $t('general.dialog.updates.added') }}:
                  <ul class="mx-1 my-3">
                    <li>{{ $t('general.dialog.updates.2_3_3.0') }}</li>
                    <li>{{ $t('general.dialog.updates.2_3_3.1') }}</li>
                    <li>{{ $t('general.dialog.updates.2_3_3.2') }}</li>
                    <li>{{ $t('general.dialog.updates.2_3_3.3') }}</li>
                    <li>{{ $t('general.dialog.updates.2_3_3.4') }}</li>
                    <li>{{ $t('general.dialog.updates.2_3_3.5') }}</li>
                    <li>{{ $t('general.dialog.updates.2_3_3.6') }}</li>
                  </ul>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="d-flex align-center ml-5">
                  <v-icon class="mr-1">mdi-bookmark-outline</v-icon>{{ release_2_3_1
                  }}<span class="text-subtitle-1 mx-2">{{ date_2_3_1 }}</span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="px-10 ma-2">
                  Anterior actualización {{ date_2_3_1 }}:
                  <ul class="mx-1 my-3">
                    <ul class="mx-1 my-3">
                      <li>{{ $t('general.dialog.updates.2_3_1.0') }}</li>
                      <li>{{ $t('general.dialog.updates.2_3_1.1') }}</li>
                      <li>{{ $t('general.dialog.updates.2_3_1.2') }}</li>
                      <li>{{ $t('general.dialog.updates.2_3_1.3') }}</li>
                      <li>{{ $t('general.dialog.updates.2_3_1.4') }}</li>
                      <li>{{ $t('general.dialog.updates.2_3_1.5') }}</li>
                      <li>{{ $t('general.dialog.updates.2_3_1.6') }}</li>
                    </ul>
                  </ul>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="acceptDialog">{{ $t('general.dialog.error.accept_button') }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    tittleText: {
      type: String,
    },
    acceptButtonText: {
      type: String,
    },
  },
  data: () => ({
    release: process.env.VUE_APP_RELEASE,
    date_2_3_3: '15/09/2023',
    date_2_3_2: '21/08/2023',
    date_2_3_1: '20/07/2023',
    date_2_4_0: '03/11/2023',
    release_2_3_1: 'V2.3.1',
    release_2_3_2: 'V2.3.2',
    release_2_3_3: 'V2.3.3',
  }),
  methods: {
    acceptDialog: function () {
      this.$emit('accept-dialog');
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>
