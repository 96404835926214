<template>
  <v-dialog v-model="isActive" max-width="100em" @click:outside="cancelCreate">
    <v-card :class="computedClass" class="create-customer-modal pa-4 mx-lg-auto">
      <h1 class="mb-3">{{ $t('alarms.add_new_alarm') }}</h1>

      <template>
        <v-stepper v-model="e1">
          <v-stepper-header :style="{ 'background-color': theme === 'dark' ? '#14262b' : '' }">
            <v-stepper-step :class="theme === 'dark' ? 'white--text' : ''" :complete="e1 > 1" step="1">
              <span> {{ $t('alarms.name') }}</span>
            </v-stepper-step>
            <v-divider
              :style="{
                'background-color': theme === 'dark' ? 'white !important' : '',
              }">
            </v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">
              <span>{{ $t('alarms.alarm_type') }}</span>
            </v-stepper-step>

            <v-divider
              :style="{
                'background-color': theme === 'dark' ? 'white !important' : '',
              }">
            </v-divider>

            <v-stepper-step :complete="e1 > 3" step="3">
              <span> {{ $t('alarms.device') }} </span>
            </v-stepper-step>

            <v-divider
              :style="{
                'background-color': theme === 'dark' ? 'white !important' : '',
              }">
            </v-divider>

            <v-stepper-step v-if="selectedAlarm == this.$t('alarms.temperature')" :complete="e1 > 4" step="4">
              <span> {{ $t('alarms.range') }}</span>
            </v-stepper-step>

            <v-divider
              :style="{
                'background-color': theme === 'dark' ? 'white !important' : '',
              }"
              v-if="selectedAlarm == this.$t('alarms.temperature')">
            </v-divider>

            <v-stepper-step v-if="selectedAlarm == this.$t('alarms.kilometres')" :complete="e1 > 4" step="4">
              <span>{{ $t('alarms.kilometres') }}</span>
            </v-stepper-step>

            <v-stepper-step v-if="selectedAlarm == this.$t('alarms.frigo_hours_maintenance')" :complete="e1 > 4" step="4">
              <span>{{ $t('alarms.frigo_hours_maintenance') }}</span>
            </v-stepper-step>

            <v-divider
              :style="{
                'background-color': theme === 'dark' ? 'white !important' : '',
              }"
              v-if="
                selectedAlarm == this.$t('alarms.kilometres') || selectedAlarm == this.$t('alarms.frigo_hours_maintenance')
              "></v-divider>

            <v-stepper-step
              v-if="selectedAlarm !== this.$t('alarms.kilometres') && selectedAlarm !== this.$t('alarms.frigo_hours_maintenance')"
              :complete="e1 > step4"
              :step="step4">
              <span> {{ $t('alarms.select_days') }}</span>
            </v-stepper-step>

            <v-divider
              :style="{
                'background-color': theme === 'dark' ? 'white !important' : '',
              }"
              v-if="
                selectedAlarm !== this.$t('alarms.kilometres') && selectedAlarm !== this.$t('alarms.frigo_hours_maintenance')
              "></v-divider>

            <v-stepper-step v-if="selectedAlarm === this.$t('alarms.temperature')" :step="step5" :complete="e1 > step5">
              <span>{{ $t('alarms.program') }}</span>
            </v-stepper-step>
            <v-divider
              :style="{
                'background-color': theme === 'dark' ? 'white !important' : '',
              }"></v-divider>

            <v-stepper-step :step="step6">
              <span>{{ $t('alarms.create') }}</span>
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items :style="{ 'background-color': theme === 'dark' ? '#14262b' : '' }">
            <v-stepper-content step="1">
              <h3 class="mb-3">
                {{ $t('alarms.alarm_name') }}
              </h3>

              <v-text-field
                background-color="transparent"
                @change="refreshDevices"
                maxlength="20"
                :rules="inputRules"
                autofocus
                v-model="name"
                :label="$t('alarms.name')"
                solo>
              </v-text-field>

              <v-btn class="btn" v-if="name" color="primary" @click="e1 = 2"> {{ $t('alarms.continue') }} </v-btn>
              <v-btn class="btn" v-else disabled color="primary"> {{ $t('alarms.continue') }} </v-btn>

              <v-btn class="btn" text @click="cancelCreate"> {{ $t('alarms.back') }} </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <h3 class="mb-3">{{ $t('alarms.select_alarm_type') }}</h3>

              <v-select
                :dark="theme === 'dark'"
                background-color="transparent"
                v-model="selectedAlarm"
                :items="alarmType"
                :label="$t('alarms.alarms')"
                solo>
              </v-select>
              <v-btn class="btn" v-if="selectedAlarm" color="primary" @click="e1 = 3"> {{ $t('alarms.continue') }} </v-btn>
              <v-btn class="btn" v-else disabled color="primary"> {{ $t('alarms.continue') }} </v-btn>

              <v-btn class="btn" @click="e1 = 1" text> {{ $t('alarms.back') }} </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <h3 class="mb-3">{{ $t('alarms.select_devices') }}</h3>
              <v-select
                v-model="selectedDevice"
                :items="this.selectedAlarm == this.$t('alarms.temperature') ? apacheCollection : collectionDevice"
                multiple
                chips
                hide-details
                dense
                :menu-props="{ maxHeight: '400' }"
                persistent-hint
                :label="$t('alarms.device')"
                class="ma-2"
                @change="checkStatus">
                <template v-slot:selection="{ item, index }">
                  <v-chip :style="{ 'background-color': theme === 'dark' ? 'grey' : '' }" v-if="index < 3">
                    <span>{{ item }}</span>
                  </v-chip>
                  <span v-if="index == 3" class="grey--text text-caption"> (+{{ selectedDevice.length - 1 }}) </span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent :title="$t('general.calendar.select_all')" @click="toggle">
                    <v-list-item-action>
                      <v-icon @click="uncheckAll('all')" :color="selectedDevice != null ? 'indigo darken-4' : ''">
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> {{ $t('general.calendar.select_all') }} </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>

              <v-btn class="btn" v-if="selectedDevice" color="primary" @click="e1 = 4"> {{ $t('alarms.continue') }} </v-btn>
              <v-btn class="btn" v-else disabled color="primary"> {{ $t('alarms.continue') }} </v-btn>

              <v-btn class="btn" text @click="e1 = 2"> {{ $t('alarms.back') }} </v-btn>
            </v-stepper-content>
            <v-stepper-content v-if="selectedAlarm == this.$t('alarms.temperature')" step="4">
              <h3 class="mb-3">{{ $t('alarms.select_temperature_range') }}</h3>
              <v-row v-if="probesType" class="content-probes mt-2" style="justify-content: space-around">
                <Limits
                  v-for="(probe, indexProbe) in this.probesTypeRenamed"
                  :key="indexProbe"
                  @enableButton="switchButton"
                  @disableButton="disableButton"
                  @emptyErrorRange="disableButton"
                  :probe="indexProbe"
                  @limits="saveLimits"
                  class="row-probe"
                  :ref="indexProbe"
                  :num="indexProbe"
                  @emptyErrorInterval="disableButton">
                </Limits>
              </v-row>
              <div class="mt-4">
                <v-btn class="btn" :disabled="filledButton" color="primary" @click="e1 = 5">
                  {{ $t('alarms.continue') }}
                </v-btn>
                <v-btn class="btn" @click="e1 = 3" text> {{ $t('alarms.back') }} </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content v-if="selectedAlarm == this.$t('alarms.kilometres')" step="4">
              <h3 class="mb-3">{{ $t('alarms.indicate_mileage_trigger_alarm') }}</h3>
              <v-text-field
                background-color="transparent"
                type="number"
                v-model="mileage"
                :label="$t('alarms.kilometres')"
                solo></v-text-field>

              <div class="mt-4">
                <v-btn class="btn" v-if="mileage" color="primary" @click="e1 = 5"> {{ $t('alarms.continue') }} </v-btn>
                <v-btn class="btn" disabled v-else color="primary"> {{ $t('alarms.continue') }} </v-btn>

                <v-btn class="btn" @click="e1 = 3" text> {{ $t('alarms.back') }} </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content v-if="selectedAlarm == this.$t('alarms.frigo_hours_maintenance')" step="4">
              <h3 class="mb-3">{{ $t('alarms.indicate_frigo_hours_maintenance_activate_alarm') }}</h3>
              <v-text-field
                background-color="transparent"
                type="number"
                v-model="frigoHours"
                :label="this.$t('alarms.frigo_hours_maintenance')"
                solo></v-text-field>

              <div class="mt-4">
                <v-btn class="btn" v-if="frigoHours" color="primary" @click="e1 = 5"> {{ $t('alarms.continue') }} </v-btn>
                <v-btn class="btn" disabled v-else color="primary"> {{ $t('alarms.continue') }} </v-btn>

                <v-btn class="btn" @click="e1 = 3" text> {{ $t('alarms.back') }} </v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content v-if="selectedAlarm === this.$t('alarms.temperature')" :step="5">
              <h3 class="mb-3">{{ $t('alarms.select_days_active_alarm') }}</h3>

              <v-radio-group :style="{ backgroundColor: 'transparent' }" v-model="days" @change="checkStatus">
                <v-card :style="{ backgroundColor: 'transparent' }" class="d-flex justify-center">
                  <v-radio :label="$t('alarms.all_days')" value="everyday"></v-radio>
                  <v-radio class="mx-4" :label="$t('alarms.monday_to_friday')" value="workingDays"></v-radio>
                  <v-radio class="mb-2" :label="$t('alarms.select_days')" value="selectDays"></v-radio
                ></v-card>
              </v-radio-group>
              <div v-if="days == 'selectDays'" @change="checkStatus">
                <v-card :style="{ backgroundColor: 'transparent' }" class="d-flex justify-center">
                  <v-checkbox
                    @change="updateSelected"
                    class="mr-2"
                    v-model="selected"
                    :label="$t('alarms.monday')"
                    :value="'monday'"></v-checkbox>
                  <v-checkbox
                    @change="updateSelected"
                    class="mr-2"
                    v-model="selected"
                    :label="$t('alarms.tuesday')"
                    :value="'tuesday'"></v-checkbox>
                  <v-checkbox
                    @change="updateSelected"
                    class="mr-2"
                    v-model="selected"
                    :label="$t('alarms.wednesday')"
                    :value="'wednesday'"></v-checkbox>
                  <v-checkbox
                    @change="updateSelected"
                    class="mr-2"
                    v-model="selected"
                    :label="$t('alarms.thursday')"
                    :value="'thursday'"></v-checkbox>
                  <v-checkbox
                    @change="updateSelected"
                    class="mr-2"
                    v-model="selected"
                    :label="$t('alarms.friday')"
                    :value="'friday'"></v-checkbox>
                  <v-checkbox
                    @change="updateSelected"
                    class="mr-2"
                    v-model="selected"
                    :label="$t('alarms.saturday')"
                    :value="'saturday'"></v-checkbox>
                  <v-checkbox
                    @change="updateSelected"
                    class="mr-2"
                    v-model="selected"
                    :label="$t('alarms.sunday')"
                    :value="'sunday'"></v-checkbox>
                </v-card>
              </div>
              <v-toolbar :style="{ backgroundColor: 'transparent' }" class="my-6" style="height: 100px">
                <v-row class="ticket-row-time" style="height: 50px">
                  <v-col>
                    <span class="d-flex ticket-row-time-description font-weight-bold mb-4">
                      {{ $t('alarms.select_hours') }}
                    </span>

                    <v-row class="d-flex justify-space-around">
                      <v-col cols="4">
                        <v-text-field
                          :dark="theme === 'dark'"
                          v-model="startingTime"
                          :label="
                            formatDate(dates[0]) > formatDate(dates[1]) == true
                              ? formatDate(dates[1])
                              : formatDate(dates[0]) + ' ' + $t('alarms.at') + ':'
                          "
                          type="time">
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          :dark="theme === 'dark'"
                          v-model="endingTime"
                          :label="
                            formatDate(dates[0]) < formatDate(dates[1]) == false
                              ? formatDate(dates[0])
                              : formatDate(dates[1]) + ' ' + $t('alarms.at') + ':'
                          "
                          type="time">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-toolbar>

              <div class="mt-4">
                <v-btn
                  class="btn"
                  v-if="days == 'everyday' || days == 'workingDays' || this.selected.length >= 1"
                  color="primary"
                  @click="e1 = step4 + 1">
                  {{ $t('alarms.continue') }}
                </v-btn>
                <v-btn class="btn" v-else disabled color="primary"> {{ $t('alarms.continue') }} </v-btn>

                <v-btn class="btn" @click="e1 = step4 - 1" text> {{ $t('alarms.back') }} </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content
              v-if="selectedAlarm !== this.$t('alarms.kilometres') && selectedAlarm !== this.$t('alarms.frigo_hours_maintenance')"
              :step="step5">
              <h3 class="mb-3">{{ $t('alarms.select_alarm_ends') }}</h3>
              <v-row class="mb-5" justify="center">
                <v-radio-group v-model="programed" @change="updatedBeforeCreate">
                  <v-col> <v-radio :label="$t('alarms.always')" value="always"></v-radio> </v-col>
                  <v-col> <v-radio :label="$t('alarms.select_start_end')" value="days"></v-radio> </v-col>
                </v-radio-group>
                <div v-if="programed == 'days'">
                  <v-date-picker
                    :dark="theme === 'dark'"
                    :min="new Date().toISOString()"
                    :show-current="new Date().toISOString().substring(0, 10)"
                    color="primary"
                    show-adjacent-months
                    full-width
                    no-title
                    first-day-of-week="1"
                    v-model="dates"
                    range
                    required>
                  </v-date-picker>
                  <v-toolbar :dark="theme === 'dark'" class="my-1" dense rounded>
                    <v-icon color="primary">mdi-calendar-range</v-icon>
                    <v-toolbar-title v-if="dates.length == 0" class="text-sm-body-2 mx-2">
                      {{ $t('reports.select_a_date') }}
                    </v-toolbar-title>
                    <v-toolbar-title
                      v-else
                      class="text-sm-body-2 mx-2"
                      style="display: flex; width: 100%; justify-content: space-between"
                      @change="updatedBeforeCreate">
                      <strong>
                        {{ $t('alarms.from') }}
                        <span>
                          {{ new Date(dates[0]) > new Date(dates[1]) == true ? formatDate(dates[1]) : formatDate(dates[0]) }}
                        </span>
                        {{ $t('general.form.until') }}

                        <span>
                          {{ new Date(dates[0]) < new Date(dates[1]) == false ? formatDate(dates[0]) : formatDate(dates[1]) }}
                        </span>

                        <span>{{ howManyDaysSelected() }} </span>
                      </strong>
                      <strong> </strong>
                      <span>{{ $t('alarms.program_day_alarm') }}</span>
                    </v-toolbar-title>
                  </v-toolbar>
                </div>
              </v-row>
              <div class="mt-4">
                <v-btn class="btn" v-if="programed == 'always' || this.dates.length > 0" color="primary" @click="e1 = step5 + 1">
                  {{ $t('alarms.continue') }}
                </v-btn>
                <v-btn class="btn" disabled v-else color="primary"> {{ $t('alarms.continue') }} </v-btn>

                <v-btn class="btn" @click="e1 = step5 - 1" text> {{ $t('alarms.back') }} </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content :step="step6">
              <div>
                <h3 class="mb-3">{{ $t('alarms.alarm_resum') }}</h3>
                <ul
                  :style="{
                    color: theme === 'dark' ? 'white !important' : '',
                  }">
                  <li>{{ $t('alarms.alarm_type') }}: {{ this.selectedAlarm }}</li>
                  <li>{{ $t('alarms.alarm_name') }}: {{ this.name }}</li>
                  <li>{{ $t('alarms.serial_number') }}: {{ this.selectedDevice }}</li>
                  <li>{{ $t('alarms.notification_emails') }}: {{ this.notificationCollection.toString() }}</li>
                  <li
                    v-if="
                      programed !== 'always' &&
                      this.selectedAlarm !== this.$t('alarms.kilometres') &&
                      this.selectedAlarm !== this.$t('alarms.frigo_hours_maintenance')
                    ">
                    {{ $t('alarms.start_day_alarm') }}: {{ $t('alarms.at') }} {{ this.startingTime }}
                    {{ $t('alarms.of_the_day') }}
                    {{ new Date(dates[0]) < new Date(dates[1]) == true ? formatDate(dates[0]) : formatDate(dates[1]) }}
                  </li>
                  <li
                    v-if="
                      programed !== 'always' &&
                      this.selectedAlarm !== this.$t('alarms.kilometres') &&
                      this.selectedAlarm !== this.$t('alarms.frigo_hours_maintenance')
                    ">
                    {{ $t('alarms.end_day_alarm') }}: {{ $t('alarms.at') }} {{ this.endingTime }} {{ $t('alarms.of_the_day') }}
                    {{ new Date(dates[1]) > new Date(dates[0]) == true ? formatDate(dates[1]) : formatDate(dates[0]) }}
                  </li>
                  <li
                    v-if="
                      programed == 'always' &&
                      this.selectedAlarm !== this.$t('alarms.kilometres') &&
                      this.$t('alarms.frigo_hours_maintenance')
                    ">
                    {{ $t('alarms.alarm_always_active') }}
                  </li>
                  <li v-if="days == 'everyday'">{{ $t('alarms.selected_days') }} : {{ $t('alarms.all_days') }}</li>
                  <li v-if="days == 'workingDays'">{{ $t('alarms.selected_days') }} : {{ $t('alarms.monday_to_friday') }}</li>
                  <li v-if="days == 'selectDays'">
                    {{ $t('alarms.selected_days') }} :
                    <span>{{ this.selectedTranslated.join(', ') }}</span>
                  </li>
                  <li v-if="selectedAlarm !== this.$t('alarms.frigo_hours_maintenance') && selectedAlarm !== this.alarmType[0]">
                    {{ $t('alarms.km_active_alarm') }}: {{ this.mileage }}
                    {{ $t('user.atribute.unit.metric') }}
                  </li>
                  <li v-if="selectedAlarm !== this.alarmType[0] && selectedAlarm !== this.$t('alarms.kilometres')">
                    {{ $t('alarms.frigo_hours_maintenance') }}: {{ this.frigoHours }}h
                  </li>
                  <span
                    v-if="
                      selectedAlarm !== this.$t('alarms.kilometres') &&
                      selectedAlarm !== this.$t('alarms.frigo_hours_maintenance')
                    ">
                    <li v-for="(probe, indexProbe) in this.probesConfigRenamed" :key="indexProbe">
                      {{ $t('alarms.probe') }}: {{ indexProbe }}, {{ $t('alarms.max_temperature') }}: {{ probe.max }},
                      {{ $t('alarms.min_temperature') }}: {{ probe.min }}, {{ $t('alarms.selected_temperature') }}:
                      {{ probe.set_point }}, {{ $t('alarms.error_range') }}: {{ probe.error_range }}
                    </li>
                  </span>
                </ul>
                <h4
                  :style="{
                    color: theme === 'dark' ? 'white !important' : '',
                  }">
                  {{ $t('alarms.alert_message_active_alarm') }}
                </h4>
              </div>
              <div class="mt-4">
                <v-btn class="btn" color="green" @click="create"> {{ $t('alarms.create') }} </v-btn>
                <v-btn class="btn" @click="e1 = step6 - 1" text> {{ $t('alarms.back') }} </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </template>
    </v-card>
  </v-dialog>
</template>
<style>
.title {
  font-size: large;
}
.btn {
  margin-right: 2rem;
}
</style>

<script>
import { getUser } from '../../viewModels/userViewModel';
import { getMyFullDevices } from '../../viewModels/devicesViewModel';
import { saveAlarms } from '../../viewModels/alarmsViewModel';
import { eventBus } from '../../main';
import Limits from './alarmLimits.vue';
import { getUserDevices } from '../../server/petitions/users/getUserDevices';

const ls = require('localstorage-slim');
const VueI18n = require('../../plugins/lang/index');

export default {
  components: {
    Limits,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    theme: ls.get('theme') ?? 'ligth',
    maxNumberOfSensors: 8,
    selectedDevice: null,
    interval: 10,
    name: '',
    user: null,
    devices: [],
    alarmType: [
      VueI18n.default.t('alarms.temperature'),
      VueI18n.default.t('alarms.kilometres'),
      VueI18n.default.t('alarms.frigo_hours_maintenance'),
    ],
    e1: 1,
    selected: [],
    loadingButton: false,
    selectedAlarm: null,
    step4: 4,
    step5: 5,
    step6: 6,
    days: 'everyday',
    dates: [],
    programed: 'always',
    probesDevice: null,
    mileage: null,
    frigoHours: null,
    device_sn: null,
    probes_config: {},
    filledButton: true,
    startingTime: '00:00',
    endingTime: '23:59',
    notifications_emails: null,
    probesType: null,
    selectedDeviceModel: null,
    notificationCollection: [],
    probesTypeRenamed: [],
    probesConfigRenamed: [],
    weekday: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
    myDevices: null,
    trackerCollection: [],
    apacheCollection: [],
    collectionDevice: [],
    lengthProbes: null,
    isMarked: false,
  }),

  computed: {
    computedClass() {
      const maxWidth = window.matchMedia('(max-width: 1440px)').matches;
      if (this.selectedAlarm === this.$t('alarms.temperature') && this.e1 > 3 && this.e1 < 5 && maxWidth) {
        return 'custom-v-card';
      }
      return '';
    },

    selectedTranslated() {
      return this.selected.map((select) => {
        return this.$t(`alarms.${select}`);
      });
    },
    inputRules() {
      return [(value) => value.length <= 19 || 'Max 20 character'];
    },
    allDevices() {
      let lengthSelectedDevices =
        this.selectedAlarm == this.$t('alarms.temperature') ? this.apacheCollection.length : this.collectionDevice.length;
      if (this.selectedDevice) return this.selectedDevice?.length === lengthSelectedDevices;
    },
    someDevices() {
      if (this.selectedDevice) return this.selectedDevice?.length > 0 && !this.allDevices;
    },
    icon() {
      if (this.allDevices) return 'mdi-close-box';
      if (this.someDevices) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },

  methods: {
    uncheckAll: function (type) {
      if (type == 'all') this.selectedDevice = [];
    },
    toggle() {
      this.$nextTick(() => {
        if (this.allDevices || this.isMarked) {
          this.selectedDevice = [];
          this.isMarked = false;
        } else {
          let selectAllDevice =
            this.selectedAlarm == this.$t('alarms.temperature') ? this.apacheCollection : this.collectionDevice;
          this.selectedDevice = selectAllDevice;
          this.checkStatus();
          this.isMarked = true;
        }
      });
    },

    async getSelectedDeviceData() {
      let deviceToSend = null;

      for (let userDevice of this.user.devices) {
        if (this.selectedDevice?.some((i) => Number(i.split('~')[1]) == Number(userDevice.device_sn))) {
          deviceToSend = [userDevice];
        }
      }
      if (deviceToSend !== null) {
        let devices = await getMyFullDevices(deviceToSend);
        let lenghtArr = 0;
        for (const i of devices) {
          let probesTemp = Object.values(i.last_frames.frame_frigo.probes.temp).length;

          if (probesTemp > lenghtArr) {
            lenghtArr = probesTemp;
            this.probesType = i.last_frames.frame_frigo.probes.temp;
            this.lengthProbes = probesTemp;
          }
        }
      } else {
        return deviceToSend;
      }
    },

    async getMyDevices() {
      this.myDevices = await getMyFullDevices();
      return this.myDevices;
    },

    switchButton() {
      this.filledButton = false;
    },
    disableButton() {
      this.filledButton = true;
    },
    saveLimits(max, min, num, setPoint, errorRange, interval) {
      this.interval = interval;
      num = parseInt(num);

      const key = `p${num}`;
      if (num >= 1 && num <= this.maxNumberOfSensors) {
        this.probes_config[key] = {
          max,
          min,
          set_point: setPoint,
          error_range: errorRange,
        };
      }
      this.updatedBeforeCreate();
    },
    formatDate: function (date) {
      if (!date) {
        return '. . .';
      }
      let months = [
        this.$t('general.months.january'),
        this.$t('general.months.february'),
        this.$t('general.months.march'),
        this.$t('general.months.april'),
        this.$t('general.months.may'),
        this.$t('general.months.june'),
        this.$t('general.months.july'),
        this.$t('general.months.august'),
        this.$t('general.months.september'),
        this.$t('general.months.october'),
        this.$t('general.months.november'),
        this.$t('general.months.december'),
      ];
      let splitedDate = date.split('-');
      let formatedDate = `${splitedDate[2]} ${this.$t('general.form.of')} ${months[new Date(date).getMonth()]} ${this.$t(
        'general.form.of',
      )} ${splitedDate[0]}`;
      return formatedDate;
    },
    howManyDaysSelected: function () {
      if (!this.dates[1]) {
        return '';
      }
      const oneDay = 24 * 60 * 60 * 1000;
      const firstDate = new Date(this.dates[0]);
      const secondDate = new Date(this.dates[1]);
      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
      return `(${diffDays + 1} ${this.$t('reports.days')})`;
    },
    oneMoreFiel: function () {
      if (this.selectedDeviceModel == 'tracker') {
        this.step4 = 4;
        this.step5 = 5;
        this.step6 = 5;
      }
      if (this.selectedAlarm == this.$t('alarms.temperature')) {
        this.step4 = 5;
        this.step5 = 6;
        this.step6 = 7;
      } else {
        this.step4 = 4;
        this.step5 = 5;
        this.step6 = 5;
      }
    },

    // Hay que revisar el como se selecciona el dia para acabar la alarma
    updateSelected() {
      this.selected.length >= 1 ? (this.filledButton = true) : '';
    },
    async checkStatus() {
      this.oneMoreFiel();

      await this.getSelectedDeviceData();

      let renameKeys = (keysMap) =>
        Object.keys(this.probesType).reduce(
          (acc, key) => ({
            ...acc,
            ...{ [keysMap[key] || key]: this.probesType[key] },
          }),
          {},
        );
      this.probesTypeRenamed = renameKeys(
        { p1: '1', p2: '2', p3: '3', p4: '4', p5: '5', p6: '6', p7: '7', p8: '8' },
        this.probesType,
      );
    },

    async refreshDevices() {
      this.myDevices;
    },

    updatedBeforeCreate() {
      this.probes_config;
      let renameKeys = (keysMap, object) =>
        Object.keys(object).reduce(
          (acc, key) => ({
            ...acc,
            ...{ [keysMap[key] || key]: object[key] },
          }),
          {},
        );
      this.probesConfigRenamed = renameKeys(
        { p1: '1', p2: '2', p3: '3', p4: '4', p5: '5', p6: '6', p7: '7', p8: '8' },
        this.probes_config,
      );
    },

    create: async function () {
      this.loadingButton = true;
      let alarms = {
        type: this.selectedAlarm,
        probes_config: this.probes_config,
        mileage: this.mileage,
        selected_days: this.selected,
        start_day: new Date(this.dates.sort()[0]),
        end_day: new Date(new Date(this.dates[1]).setHours(23, 59, 0, 0)),
        device_sn: [],
        email: this.user.email,
        name: this.name,
        interval: this.interval,
        frigo: this.frigoHours,
        notification_emails: this.user.notificationsEmails,
        locale: this.user.locale,
        platform: this.user.platform,
        startingTime: this.startingTime,
        endingTime: this.endingTime,
      };

      if (this.selectedAlarm == this.alarmType[0]) {
        alarms.type = 'temp';
      }

      if (this.selectedAlarm == this.$t('alarms.kilometres') || this.selectedDeviceModel == 'tracker') {
        alarms.type = 'odometer';
        alarms.start_day = new Date();
        alarms.end_day = new Date(new Date().setFullYear(2099)).toISOString();
        alarms.selected_days = this.weekday;
      }

      if (this.selectedAlarm == this.$t('alarms.frigo_hours_maintenance')) {
        alarms.type = 'frigo';
        alarms.start_day = new Date();
        alarms.end_day = new Date(new Date().setFullYear(2099)).toISOString();
        alarms.selected_days = this.weekday;
      }

      if (this.days == 'everyday') {
        alarms.selected_days = this.weekday;
      }

      if (this.days == 'workingDays') {
        alarms.selected_days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
      }
      if (this.programed == 'always') {
        alarms.start_day = new Date();
        alarms.end_day = new Date(new Date().setFullYear(2099));
        this.end_day = alarms.end_day;
      }

      for (let userDevice of this.user.devices) {
        if (this.selectedDevice?.some((i) => Number(i.split('~')[1]) == Number(userDevice.device_sn))) {
          alarms.device_sn.push(userDevice.device_sn);
        }
      }
      this.formatDate();
      const res = await saveAlarms(alarms);
      alarms._id = res.alarm._id;

      this.$emit('cancel-create');
    },
    cancelCreate: function () {
      this.$emit('cancel-create');
    },
  },

  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
    this.dates = [
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    ];
    this.user = await getUser();
    if (this.user.notificationsEmails.length == 0) {
      this.$emit('userEmail');
    }
    this.getMyDevices;
    this.notificationCollection = this.user.notificationsEmails;

    this.devices = await getUserDevices(this.user.token, this.user.userId);
    this.devices.map((i) => {
      if (i.model == 'tracker') {
        this.trackerCollection.push(i.alias + ' ~ ' + i.device_sn);
      } else {
        this.apacheCollection.push(i.alias + ' ~ ' + i.device_sn);
      }
      this.collectionDevice = this.trackerCollection.concat(this.apacheCollection);
    });
  },
};
</script>
