<template>
  <v-container v-if="!isMobile" :key="$route.fullPath">
    <v-row class="edit-device-firts-container">
      <v-col class="header-title pb-0" cols="9">
        <div class="breadcrumbs-container">
          <v-row>
            <h1>
              {{ $t('admin.menu.beacon') + ' ' + beacon.mac_address }}
            </h1>
          </v-row>
          <v-row class="breadcrumbs mb-0">
            <v-breadcrumbs :items="items" class="pl-0 pb-0">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                  {{ item.text.toUpperCase() }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row class="edit-device-firts-container">
      <v-col class="pt-0">
        <BeaconDataForm
          class="edit-device-data-form"
          ref="beaconDataForm"
          :beaconData="beacon"
          @deleted-correctly="deletedCorrectly"
          @user-repeat="userRepeat"
          @user-no-repeat="userNoRepeat">
        </BeaconDataForm>

        <v-row class="edit-device-buttons d-flex justify-center mt-10">
          <v-btn :disabled="disabledButton" color="primary" class="d-flex justify-space-between mx-15" @click="save">
            <v-icon>mdi-content-save</v-icon>{{ $t('general.form.save') }}
          </v-btn>
          <v-btn color="red" class="d-flex justify-space-between mx-15" @click="goBack">
            <v-icon class="white--text">mdi-exit-to-app</v-icon>
            <span class="white--text">{{ $t('general.form.exit') }}</span>
          </v-btn>
        </v-row>
      </v-col>

      <v-col cols="3" class="device-actions pt-0">
        <v-expansion-panels class="card-frame-frigo expansion-panels mb-2">
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <v-row no-gutters>
                  <v-col>{{ $t('admin.menu.last_beacon_frame') }}</v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="expansion-panel-content">
              <div class="expansion-panel-body">
                <div v-if="beacon.last_seen" class="atribute-date expansion-panel-info">
                  <span class="expansion-panel-info-text text-tooltip" style="color: #b41c5c">
                    {{ getGpsTimestampTooltip }}
                    <br />
                    <v-divider class="my-2"></v-divider>
                    {{ $t('admin.menu.seen_by') }} {{ $t('admin.menu.device') }} {{ beacon.seen_by }}
                  </span>
                </div>
                <div v-else class="atribute-date expansion-panel-info">
                  <span class="expansion-panel-info-text text-tooltip" style="color: #b41c5c">
                    {{ $t('general.error.no_data') }}
                  </span>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"> {{ $t('general.form.close') }} </v-btn>
      </template>
    </v-snackbar>

    <ErrorDialog
      :isActive="errorDialog"
      v-bind:tittleText="$t('general.dialog.error.title')"
      :messageText="errorText"
      v-bind:acceptButtonText="$t('general.dialog.error.accept_button')"
      v-on:accept-dialog="errorDialog = false">
    </ErrorDialog>
  </v-container>

  <!-- Mobile View -->

  <v-container v-else class="edit-device-container-mobile mt-5">
    <v-row class="header-title">
      <div class="breadcrumbs-container">
        <v-row>
          <h1>
            {{ $t('admin.menu.beacon') + ' ' + beacon.mac_address }}
          </h1>
        </v-row>
        <v-row class="breadcrumbs breadcrumbs-edit-device-row">
          <v-breadcrumbs :items="items" class="pl-0">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-row>
      </div>
    </v-row>

    <BeaconDataForm ref="beaconDataForm" :beaconData="beacon"></BeaconDataForm>

    <v-row class="device-actions mx-3 mt-2">
      <v-expansion-panels class="card-frame-frigo expansion-panels mb-2">
        <v-expansion-panel class="expansion-panel">
          <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <v-row no-gutters>
                <v-col> {{ $t('admin.menu.last_beacon_frame') }} </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>

          <v-expansion-panel-content class="expansion-panel-content">
            <div class="expansion-panel-body">
              <div class="atribute-date expansion-panel-info">
                <div v-if="beacon.last_seen" class="atribute-date expansion-panel-info">
                  <span class="expansion-panel-info-text text-tooltip" style="color: #b41c5c">
                    {{ getGpsTimestampTooltip }}
                    <br />
                    <v-divider class="my-2"></v-divider>
                    {{ $t('admin.menu.device') }} {{ beacon.seen_by }}
                  </span>
                </div>
                <div v-else class="atribute-date expansion-panel-info">
                  <span class="expansion-panel-info-text text-tooltip" style="color: #b41c5c">
                    {{ $t('general.error.no_data') }}
                  </span>
                </div>
              </div>

              <v-divider class="my-2"></v-divider>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <v-row class="d-flex mt-10" style="justify-content: space-around">
      <v-btn :disabled="disabledButton" color="primary" class="d-flex justify-space-between" @click="save">
        <v-icon>mdi-content-save</v-icon>{{ $t('general.form.save') }}
      </v-btn>
      <v-btn color="red" class="d-flex justify-space-between" @click="goBack">
        <v-icon class="white--text">mdi-exit-to-app</v-icon>
        <span class="white--text">{{ $t('general.form.exit') }}</span>
      </v-btn>
    </v-row>

    <ErrorDialog
      :isActive="errorDialog"
      v-bind:tittleText="$t('general.dialog.error.title')"
      :messageText="errorText"
      v-bind:acceptButtonText="$t('general.dialog.error.accept_button')"
      v-on:accept-dialog="errorDialog = false">
    </ErrorDialog>
  </v-container>
</template>

<script>
import router from '../../../../plugins/router/index';
import BeaconDataForm from '../../../../components/forms/beaconDataForm/beaconDataForm.vue';
import { modifyBeaconByAdmin, getBeacon } from '../../../../viewModels/adminViewModel';
import { ModifyBeaconByAdminException } from '../../../../server/petitions/beacons/modifyBeaconByAdmin';
import ErrorDialog from '../../../../components/errorDialog.vue';
import { formatDates } from '../../../../utils/utils';

export default {
  components: {
    BeaconDataForm,
    ErrorDialog,
  },
  props: {
    beaconData: {
      type: Object,
    },
    mac_address: {
      type: String,
    },
  },
  data: () => ({
    beacon: {},
    disabledButton: false,
    dataFormBefore: null,
    isDeletedUser: false,
    snackbarMessage: '',
    isUserNoDuplicate: false,
    errorDialog: false,
    errorText: '',
    snackbar: false,
    timeout: 4000,
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '../../../dashboard/temperatures',
      },
      {
        text: 'Balizas',
        disabled: false,
        href: '/admin/beacons',
      },
      {
        text: 'Baliza',
        disabled: true,
        href: '',
      },
    ],
  }),

  methods: {
    deletedCorrectly() {
      this.snackbarMessage = this.$t('user.form.user_deleted');
      this.isDeletedUser = true;
    },
    userRepeat() {
      this.isUserNoDuplicate = true;
      this.snackbarMessage = this.$t('user.form.user_repeat');
      this.snackbar = true;
    },
    userNoRepeat() {
      this.snackbarMessage = this.$t('user.form.user_saved');
      this.isUserNoDuplicate = true;
    },
    save: async function () {
      this.disabledButton = true;

      try {
        let beaconDataForm = this.$refs.beaconDataForm;

        if (beaconDataForm.validate()) {
          let mac_address = beaconDataForm.macAddress;
          let alias = beaconDataForm.alias;
          // let model = beaconDataForm.selectedModel; ------ESTO ES PARA EL MODELO-----
          let category = beaconDataForm.selectedCategory;
          let notes = beaconDataForm.notes;
          let users = beaconDataForm.users;
          let usersToRemoveBeacon = beaconDataForm.usersToRemoveBeacon;

          const payload = {
            mac_address,
            alias,
            // model,------ESTO ES PARA EL MODELO-----
            category,
            notes,
            users,
            usersToRemoveBeacon,
          };
          let res = await modifyBeaconByAdmin(payload);
          if (this.$vuetify.breakpoint.mobile) this.goBack();
          if (this.isUserNoDuplicate) this.snackbar = true;
          if (this.isDeletedUser) this.snackbar = true;
          if (
            res.alias !== this.dataFormBefore.alias ||
            // res.model !== this.dataFormBefore.model || ------ ESTO ES PARA EL MODELO -----
            res.category !== this.dataFormBefore.category ||
            res.notes !== this.dataFormBefore.notes
          ) {
            this.snackbar = true;
            this.snackbarMessage = `${this.$t('admin_menu.modified_data')}`;

            this.dataFormBefore = {
              mac_address: mac_address,
              alias: alias,
              // model: model,------ESTO ES PARA EL MODELO-----
              category: category,
              notes: notes,
            };
          }
        }
        setTimeout(() => {
          this.disabledButton = false;
        }, 4000);
      } catch (e) {
        this.errorText = this.$t('general.error.unexpected_error');
        this.errorDialog = true;
        setTimeout(() => {
          this.disabledButton = false;
        }, 4000);
        switch (e.code) {
          case ModifyBeaconByAdminException.unknownError:
          case ModifyBeaconByAdminException.incorrectParameters:
          case ModifyBeaconByAdminException.invalidToken:
          case ModifyBeaconByAdminException.expiredToken:
            console.error(e);

            break;
          default:
            console.error('CATCH ERROR:', e);
        }
      }
    },
    goBack: function () {
      router.push({ name: 'beacons' });
    },

    getTimestampTooltip(timestampDate, actualDate) {
      const oneDay = 24 * 60 * 60 * 1000;
      const date = new Date(timestampDate);
      const formatedDate = this.formatDates(timestampDate);
      const diffDays = Math.round(Math.abs((actualDate - date) / oneDay));

      if (diffDays < 1) {
        const diffHours = Math.round(Math.abs((actualDate - date) / 1000 / 3600));
        if (diffHours > 1) {
          return this.$t('dashboard.details.last_measure_hours', {
            hours: diffHours,
          });
        }
        const diffMinutes = Math.round(Math.abs((actualDate - date) / 1000 / 60));
        if (diffMinutes > 1) {
          return this.$t('dashboard.details.last_measure_minutes', {
            minutes: diffMinutes,
          });
        }
        return this.$t('dashboard.details.last_measure_recently');
      }

      if (diffDays < 7) {
        return this.$t('dashboard.details.last_measure_in_days', {
          days: diffDays,
        });
      }

      return this.$t('dashboard.details.last_measure_date', {
        date: formatedDate,
      });
    },

    formatDates,
  },

  mounted: async function () {
    this.beacon = await getBeacon(this.$route.params.mac_address);

    this.dataFormBefore = {
      mac_address: this.beacon.mac_address,
      alias: this.beacon.alias,
      // model: this.beacon.model,------ESTO ES PARA EL MODELO-----
      category: this.beacon.category,
      notes: this.beacon.notes,
    };
  },

  computed: {
    snackbarColor() {
      return this.snackbarMessage === this.$t('user.form.user_repeat') ? 'red' : 'success';
    },
    getGpsTimestampTooltip() {
      return this.getTimestampTooltip(this.beacon.last_seen, new Date());
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>
