const PetitionError = require('../../petitionError');
const { postAnonymous } = require('../../httpRequester');

class IsActivatedEmailException extends PetitionError {
  constructor(code) {
    super('Change is Activated Notifications email', code);
  }
}

async function isActivatedEmail(payload) {
  const response = await postAnonymous(
    `users/activate-notifications?email=${payload.user_email}&switchValue=${payload.switchValue}&notificationEmail=${payload.notificationEmail}`,
  );

  if (response.result !== IsActivatedEmailException.success) {
    throw new IsActivatedEmailException(response.result);
  } else {
    return response.user;
  }
}

module.exports = {
  isActivatedEmail,
  IsActivatedEmailException,
};
