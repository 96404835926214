const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class saveFrigoHoursDeviceException extends PetitionError {
  static serialNumberAlreadyInDevice = 'SERIALNUMBER_ALREADY_IN_DEVICE';

  constructor(code) {
    super('Save Frigo Hours', code);
  }
}

async function saveFrigoHoursDevice(token, device_sn, frigoHours, offset) {
  const payload = {
    frigoHours,
    device_sn,
    offset,
  };
  const response = await post('devices/save_frigo', payload, token);
  if (response.result !== saveFrigoHoursDeviceException.success) {
    return (new saveFrigoHoursDeviceException(response.result));
  }
  return response; // TODO transform
}

// class saveFrigoHoursDeviceException extends PetitionError {
//   static serialNumberAlreadyInDevice = 'SERIALNUMBER_ALREADY_IN_DEVICE';

//   constructor(code) {
//     super('Save Frigo Hours', code);
//   }
// }

module.exports = {
  saveFrigoHoursDevice,
  saveFrigoHoursDeviceException,
};
