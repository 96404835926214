<template>
  <v-dialog v-model="isActive" max-width="70rem" @click:outside="cancelConfiguration">
    <v-card>
      <v-form>
        <v-container>
          <h1 class="my-5 mx-10">{{ this.$t('dashboard.configuration.add_poi') }}</h1>
          <v-text-field
            label="Nombre"
            placeholder="Nombre"
            outlined
            :rules="poiNameRules"
            class="ma-10"
            v-model="name"></v-text-field>
          <v-text-field
            label="Radio (metros)"
            placeholder="Radio"
            outlined
            type="number"
            :rules="phoneRules"
            oninput="value=value.replace(/^[0-9]{10}$|^[-]$/, '')"
            class="mx-10"
            v-model="radius"></v-text-field>
          <h4 v-if="position" class="my-5 mx-10">Latitud: {{ position.lat }}</h4>
          <h4 v-if="position" class="my-5 mx-10">Longitud: {{ position.lng }}</h4>
        </v-container>

        <v-container class="text-md-center">
          <v-btn class="mx-lg-6 pa-5" color="primary" elevation="2" x-large @click="save"
            ><v-icon>mdi-content-save</v-icon>{{ this.$t('dashboard.configuration.accept') }}</v-btn
          >
          <v-btn class="mx-lg-6 pa-5" color="third" elevation="2" x-large @click="cancelConfiguration"
            ><v-icon>mdi-cancel</v-icon>{{ this.$t('dashboard.configuration.cancel') }}</v-btn
          >
        </v-container>
      </v-form>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="timeout" color="success">
      Guardado con éxito

      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="cancelConfiguration"> Cerrar </v-btn>
      </template>
    </v-snackbar>
    <v-alert v-if="alert" border="top" color="red lighten-2" prominent type="error" dark @click:outside="alert = false">
      {{ this.$t('pois_incorrect_setting') }}
    </v-alert>
  </v-dialog>
</template>
<style>
.title {
  font-size: large;
}
</style>

<script>
import { savePOI } from '../../viewModels/poiViewModel';
import { addPoiToMap } from '../../components/dashboard/map.vue';
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    position: {
      type: Object,
    },
  },
  data: () => ({
    timeout: 2000,
    snackbar: false,
    update: 0,
    name: null,
    radius: null,
    alert: false,
  }),
  computed: {
    phoneRules() {
      return [(value) => (!isNaN(parseFloat(value)) && value >= 10 && value <= 999) || this.$t('general.error.must_be_number')];
    },
    poiNameRules() {
      return [
        (value) => !!value || this.$t('general.error.empty_value'),
        (value) => (value && value.length <= 40) || this.$t('user.atribute.misc.account_rules'),
      ];
    },
  },

  methods: {
    cancelConfiguration: function () {
      this.$emit('cancel-configuration');
    },
    save: async function () {
      if (!this.isPoiNameValid(this.name) || !this.isRadiusNameValid(this.radius)) {
        this.alert = true;
        return;
      }

      let poiToSave = {
        name: this.name,
        radius: this.radius,
        lat: this.position.lat,
        lng: this.position.lng,
      };
      try {
        let res = await savePOI(poiToSave);
        if (res.result !== 'SUCCESS') {
          this.alert = !this.alert;
          this.update++;
        } else {
          this.$emit('updatePOI');
          this.snackbar = !this.snackbar;
          this.alert = false;
          this.$emit('cancel-configuration');
        }
      } catch (error) {
        console.log('Error', error);
      }
    },

    isPoiNameValid(poiName) {
      if (this.name) {
        return poiName.length <= 40;
      } else {
        return false;
      }
    },

    isRadiusNameValid(radius) {
      if (this.radius) {
        return radius >= 10 && radius < 1000;
      } else {
        return false;
      }
    },
  },

  mounted: async function () {},
};
</script>
