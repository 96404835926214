const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class SaveReminderPetitionException extends PetitionError {
  constructor(code) {
    super('Create reminder', code);
  }
}

async function saveReminderPetition(reminder, token) {
  const payload = { reminder };
  const response = await post('reminder', payload, token);

  if (response.result !== SaveReminderPetitionException.success) {
    return response.result;
  }
  console.log(response);
  return response; // TODO transform
}

module.exports = {
  saveReminderPetition,
  SaveReminderPetitionException,
};
