<template>
  <div class="ml-5">
    <div class="user-configuration-container">
      <v-form class="user-configuration-form" ref="userConfigForm" v-model="formValid" lazy-validation>
        <div class="user-configuration-form-title">
          <v-row>
            <h1 class="my-5 text-center">
              {{ $t('user.configuration.name') }}
            </h1>
          </v-row>
        </div>
        <div class="user-configuration-row">
          <div class="user-configuration-data">
            <h3 :style="{ color: colorTheme }" class="mb-4 text-center">
              {{ $t('user.atribute.title') }}
            </h3>
            <v-row justify="center">
              <v-col :cols="isMobile ? 8 : 4">
                <v-text-field
                  :dark="theme === 'dark'"
                  dense
                  outlined
                  class="rounded-lg"
                  type="text"
                  :label="$t('user.atribute.name')"
                  v-model="name"
                  :rules="textRules"
                  required>
                </v-text-field>
              </v-col>
              <v-col :cols="isMobile ? 8 : 4">
                <v-text-field
                  :dark="theme === 'dark'"
                  dense
                  outlined
                  class="rounded-lg"
                  type="text"
                  :label="$t('user.atribute.phone')"
                  v-model="phone">
                </v-text-field>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col :cols="isMobile ? 8 : 8">
                <v-text-field
                  :dark="theme === 'dark'"
                  dense
                  outlined
                  class="rounded-lg"
                  type="email"
                  :label="$t('user.atribute.email')"
                  v-model="email"
                  :rules="emailRules">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" v-if="this.rol !== 'client' && this.companyName" class="row-card">
              <v-col :cols="isMobile ? 8 : 4">
                <v-text-field
                  :dark="theme === 'dark'"
                  :hint="$t('user.misc.company.admin')"
                  readonly
                  dense
                  outlined
                  class="rounded-lg"
                  type="text"
                  :label="$t('company.name')"
                  v-model="companyName">
                </v-text-field>
              </v-col>
              <v-col :cols="isMobile ? 8 : 4" class="col-card">
                <v-text-field
                  :dark="theme === 'dark'"
                  :hint="$t('user.misc.cif.admin')"
                  readonly
                  dense
                  outlined
                  class="rounded-lg"
                  type="text"
                  :label="$t('company.atribute.cif')"
                  v-model="companyCif">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="row-card" style="display: flex; justify-content: center">
              <v-col :cols="isMobile ? 8 : 3">
                <v-card
                  :style="{
                    'background-color': theme === 'dark' ? '#808080 !important' : '',
                    height: '17vh',
                  }"
                  outlined
                  @drop.prevent="onDrop($event)"
                  @dragover.prevent="dragover = true"
                  @dragenter.prevent="dragover = true"
                  @dragleave.prevent="dragover = false">
                  <v-container fluid>
                    <v-row dense>
                      <v-col style="display: flex; justify-content: flex-end">
                        <v-btn v-if="resImage" x-small color="red" @click="deleteLogo">
                          <v-icon small class="white--text">mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="3">
                        <v-file-input
                          style="width: 3rem"
                          :prepend-icon="resImage ? '' : 'mdi-camera'"
                          hide-input
                          v-model="image"
                          :dark="theme === 'dark'"
                          :rules="imageRules"
                          accept="image/png, image/jpeg"
                          @change="customFileValidation(image)" />
                      </v-col>

                      <v-col cols="4">
                        <div class="image-card">
                          <img style="max-width: 90px; max-height: 80px" class="image-fit pb-3" v-bind:src="resImage" />
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="mt-5">
          <v-row justify="center" class="user-configuration-buttons">
            <v-col :cols="isMobile ? 4 : 2">
              <v-btn small color="red" class="d-inline text-white" @click="dashboardView">
                <v-icon class="white--text">mdi-exit-to-app</v-icon>
                <span class="white--text">{{ $t('general.form.exit') }}</span>
              </v-btn>
            </v-col>
            <v-col :cols="isMobile ? 4 : 2">
              <v-btn small color="primary" class="d-inline ml-2" @click="save">
                <v-icon>mdi-content-save</v-icon>{{ $t('general.form.save') }}
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <v-alert v-if="validReport" border="top" color="green lighten-2" prominent type="success" dark @click="closeAlert">
          <v-col class="grow"> <v-icon>mdi-emoticon-happy</v-icon> {{ $t('general.form.saved') }}</v-col>
        </v-alert>
      </v-form>
    </div>
  </div>
</template>

<script>
import { getUser, modifyUserItself, getImage } from '../../../viewModels/userViewModel';
import { ModifyUserItselfException } from '../../../server/petitions/users/modifyUserItself.js';
import router from '../../../plugins/router/index';
import { getCompany } from '../../../viewModels/adminViewModel';
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';

export default {
  data: () => ({
    colorTheme: ls.get('theme') === 'dark' ? 'white !important' : '',
    theme: ls.get('theme') ?? 'ligth',
    resImage: null,
    base64: '',
    image: null,
    formValid: false,
    userId: '',
    name: '',
    email: '',
    rol: '',
    notificationsEmails: [],
    validReport: false,
    success: true,
    phone: '',
    selectedUnit: '',
    emailsInStandBy: [],
    errorDialog: false,
    errorText: '',
    companyCif: '',
    companyName: '',
    items: [
      {
        text: 'Home',
        disabled: false,
        href: 'dashboard/temperatures',
      },
      {
        text: 'Configuración',
        disabled: true,
        href: 'configuration',
      },
    ],
    dragover: false,
    isFileValid: true,
  }),

  beforeDestroy() {
    if (this.emailsInStandBy.length > 0) {
      for (let email of this.emailsInStandBy) {
        this.notificationsEmails.push(email);
      }
    }
  },

  methods: {
    deleteLogo() {
      this.image = null;
      this.base64 = '';
      this.resImage = '';
    },
    async convertBase64(file) {
      const prom = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
      return prom;
    },

    async changeImage() {
      const file = this.image;
      this.base64 = await this.convertBase64(file);
      this.resImage = this.base64;
    },
    async onDrop(event) {
      this.dragover = false;

      if (event.dataTransfer.files.length == 0) {
        return;
      }

      if (event.dataTransfer.files.length > 1) {
        this.uploadErrors.push(this.$t('dashboard.max_files'));
        return;
      }

      const file = event.dataTransfer.files[0];

      this.image = file;
      await this.customFileValidation(file);
    },

    activateAlert() {
      setTimeout(() => {
        this.validReport = true;
        this.closeAlert();
      }, 2000);
    },
    closeAlert() {
      this.success = false;
      this.validReport = false;
      // this.dashboardView();
    },
    dashboardView: function () {
      router.push({ name: 'dashboard' });
    },
    save: async function () {
      if (this.$refs.userConfigForm.validate()) {
        try {
          await modifyUserItself(this.name, this.phone, this.email, this.notificationsEmails, this.selectedUnit, this.base64);
          this.activateAlert();
        } catch (e) {
          console.error('CATCH ERROR:', e);

          switch (e.code) {
            case ModifyUserItselfException.unknownError:
            case ModifyUserItselfException.incorrectParameters:
            case ModifyUserItselfException.invalidToken:
            case ModifyUserItselfException.expiredToken:
              console.error(e);
              this.errorText = this.$t('general.error.unexpected_error');
              this.errorDialog = true;
              break;
            case ModifyUserItselfException.emailAlreadyInUser:
              this.errorText = this.$t('user.add_modify.error.email_already_in_use');
              this.errorDialog = true;
              break;
            default:
              console.error('CATCH ERROR:', e);
          }
        }
        this.validReport = true;
      }
    },
    async customFileValidation(value) {
      if (value === null) return (this.isFileValid = true);

      const acceptedTypes = ['image/png', 'image/jpeg', 'application/pdf'];
      const isValidType = value ? acceptedTypes.includes(value.type) : false;
      const isValidSize = value ? value.size < 5000000 : false;

      if (!isValidType) {
        this.isFileValid = false;
        this.$emit('file-changed', this.isFileValid);
        return this.$t('general.error.empty_value');
      }
      if (!isValidSize) {
        this.isFileValid = false;
        this.$emit('file-changed', this.isFileValid);
        return this.$t('general.form.maxSize');
      }

      this.isFileValid = true;
      this.$emit('file-changed', this.isFileValid);
      await this.changeImage();
      return true;
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    textRules() {
      return [(value) => !!value || this.$t('general.error.empty_value')];
    },
    emailRules() {
      return [(value) => (!!value && /.+@.+/.test(value)) || this.$t('general.error.not_valid_email')];
    },
    imageRules() {
      return [(value) => !value || value.size < 500000 || this.$t('general.error.image')];
    },
  },

  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
      this.colorTheme = theme === 'dark' ? 'white !important' : '';
    });
    let user = await getUser();
    this.resImage = await getImage(user.email);
    this.base64 = this.resImage;
    this.userId = user.userId;
    this.name = user.name;
    this.email = user.email;
    this.rol = user.type;
    this.notificationsEmails = user.notification_emails || user.notificationsEmails || [];
    this.phone = user.phone;
    this.selectedUnit = user.unit;

    let company = await getCompany(user.companyId);
    if (company) {
      this.companyCif = company.cif;
      this.companyName = company.name;
    }
  },
};
</script>
