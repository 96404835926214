const PetitionError = require('../../petitionError');
const { getAnonymous } = require('../../httpRequester');

class getSimInfoPetitionException extends PetitionError {
  constructor(code) {
    super(' Get locations today: ', code);
  }
}

async function getSimInfoPetition(id, currentDate, dataDate) {
  const response = await getAnonymous(`matooma/get_sim_info/${id}/${dataDate}/${currentDate}`);

  if (response.result !== getSimInfoPetitionException.success) {
    throw new getSimInfoPetitionException(response.result);
  } else {
    return response;
  }
}

async function getSimInfoMatooma(id) {
  const response = await getAnonymous(`matooma/sim_info_matooma/${id}`);

  if (response.result !== getSimInfoPetitionException.success) {
    throw new getSimInfoPetitionException(response.result);
  } else {
    return response;
  }
}

module.exports = {
  getSimInfoPetition,
  getSimInfoMatooma,
  getSimInfoPetitionException,
};
