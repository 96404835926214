<template>
  <div class="container-buttons-header elevation-2">
    <v-row class="d-flex justify-space-between" style="height: 4rem">
      <span
        :style="{ color: colorTheme }"
        class="device-name d-inline-block text-md-subtitle-1 font-weight-bold black--text mt-8 ml-6 mr-6 mb-2 text-truncate"
        style="max-width: 60%"
        v-on:click="openConfiguration">
        {{ carplate }}
        <v-icon
          :style="{
            color: theme === 'dark' ? 'white !important' : '',
          }"
          class="ml-2"
          x-small
          >mdi-pencil</v-icon
        >
      </span>
      <v-btn id="btn-close-infolist" data-cy="close" icon elevation="2" class="mt-6 mr-5 white" x-small fab @click="closeInfo">
        <v-icon style="max-width: 20%">mdi-close</v-icon>
      </v-btn>
    </v-row>

    <v-row class="mx-3 my-1 text-caption font-weight-bold black--text"> S/N: {{ device ? device.device_sn : '' }} </v-row>

    <v-divider class="py-3 mx-2" style="border-color: #ffff"></v-divider>

    <v-row class="d-flex justify-space-around mx-1 mb-1">
      <v-btn
        class="butons-header white"
        v-if="device.last_frames.frame_frigo"
        width="40%"
        @click="openTicketDialog"
        small
        data-cy="temperatureTicketButton">
        <font-awesome-icon class="buttons-header-icon icon-receipt" icon="fa-solid fa-receipt" />
        <span class="butons-header-text">
          {{ $t('dashboard.device_card.ticket') }}
        </span>
      </v-btn>
      <v-btn
        v-show="device.model == 'tracker'"
        :disabled="user?.type == 'client' || restrictions?.stop_vehicle"
        @click="openTurnOff"
        class="butons-header white"
        width="40%"
        small>
        <font-awesome-icon class="buttons-header-icon" icon="fa-solid fa-road-lock" />
        <span class="butons-header-text">{{ $t('device.immobilise') }} </span>
      </v-btn>
      <v-btn disabled v-if="user?.type == 'client'" class="butons-header white" width="40%" small data-cy="reportButton">
        <font-awesome-icon icon="fa-solid fa-file-contract" class="buttons-header-icon icon-report" />
        <span class="butons-header-text">
          {{ $t('dashboard.device_card.report') }}
        </span>
      </v-btn>
      <v-btn v-else class="butons-header white" width="40%" @click="reportsView" small data-cy="reportButton">
        <font-awesome-icon icon="fa-solid fa-file-contract" class="buttons-header-icon icon-report" />
        <span class="butons-header-text">
          {{ $t('dashboard.device_card.report') }}
        </span>
      </v-btn>
    </v-row>

    <v-row class="d-flex justify-space-around mx-1 mb-1">
      <v-btn
        class="butons-header white"
        width="40%"
        @click="showRoute"
        :loading="routeLoading"
        :disabled="routeLoading"
        small
        data-cy="routesButton">
        <font-awesome-icon class="butons-header-icon icon-route" icon="fa-solid fa-route" />
        <span class="butons-header-text">
          {{ $t('dashboard.device_card.routes') }}
        </span>
      </v-btn>
      <v-btn
        class="butons-header white"
        width="40%"
        small
        id="termografia-btn"
        @click="temperatureView"
        data-cy="temperaturesButton">
        <font-awesome-icon class="buttons-header-icon icon-termo" icon="fa-solid fa-temperature-quarter" />
        <span class="butons-header-text">
          {{ $t('dashboard.device_card.termo') }}
        </span>
      </v-btn>
    </v-row>

    <v-row class="d-flex justify-space-around pb-4 mb-2 mx-1">
      <v-btn class="butons-header white" @click="openApacheBase" width="40%" small data-cy="apacheBaseButton">
        <font-awesome-icon class="buttons-header-icon icon-mobile" icon="fa-solid fa-mobile-screen-button" />
        <span class="butons-header-text">
          {{ $t('dashboard.device_card.clients_app') }}
        </span>
      </v-btn>
      <v-btn class="butons-header white" width="40%" @click="openCreateAlarmDialog" small :disabled="device.model === 'tracker'">
        <v-icon color="black" class="buttons-header-icon icon-confi" small> mdi-cog </v-icon>
        <span class="butons-header-text">
          {{ $t('dashboard.device_card.alarm') }}
        </span>
      </v-btn>
    </v-row>
    <v-row disabled v-if="device?.certificate && user?.type !== 'client'" class="d-flex justify-space-around pb-4 mb-2 mx-1">
      <v-btn class="butons-header white" @click="documentationView" width="90px" small>
        <font-awesome-icon class="buttons-header-icon icon-mobile" icon="fa fa-book" />
        <span class="ml-2"> {{ $t('dashboard.device_card.certificates') }} </span>
      </v-btn>
    </v-row>
    <Certs ref="certModal" v-on:cancel-dialog="closeModalCerts" :deviceData="device ?? ''" />
  </div>
</template>

<style>
.device-name {
  cursor: pointer;
  text-decoration: none;
  border: none;
  display: rgb(241, 243, 244);
}
.button:hover {
  color: none;
}
</style>

<script>
import router from '../../../../../plugins/router/index';
import Certs from '../../../../dashboard/certs/certs.vue';
import { getUser } from '../../../../../viewModels/userViewModel';
const ls = require('localstorage-slim');
import { eventBus } from '../../../../../main';
const VueI18n = require('../../../../../plugins/lang/index');

export default {
  components: { Certs },
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
    carplate: {},
    routeLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    colorTheme: ls.get('theme') === 'dark' ? 'white !important' : '',
    theme: ls.get('theme') ?? 'ligth',
    geocode: VueI18n.default.t('general.table.loading'),
    restrictions: null,
    user: null,
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    closeInfo() {
      this.$emit('onCloseInfo');
    },

    openConfiguration() {
      this.$emit('openConfiguration');
    },

    openCreateAlarmDialog() {
      this.$emit('openCreateAlarmDialog');
    },

    openTicketDialog() {
      this.$emit('openTicketDialog');
    },

    openTurnOff() {
      this.$emit('openTurnOff');
    },

    reportsView() {
      this.$emit('reports');
      router.push({
        name: 'reports',
      });
      this.$store.dispatch('setReportDevice', this.device);
    },
    documentationView() {
      this.$refs.certModal.setActiveModal(true);
    },
    closeModalCerts: function () {
      this.$refs.certModal.setActiveModal(false);
    },

    showRoute() {
      this.$emit('showRoute');
      if (this.isMobile) {
        this.$emit('closeButtons');
      }
    },

    temperatureView() {
      eventBus.$emit('cleanSearch');

      router.push({
        name: 'temperatures',
        params: { device: this.device },
      });

      if (this.isMobile) {
        this.$emit('closeButtons');
      }
    },

    openApacheBase() {
      const url_base = process.env.VUE_APP_CHOFER;
      window.open(`${url_base}autologin?deviceId=${this.device.device_sn}&secret=${this.device.password}&redirect=clients`);
    },
  },
  mounted: async function () {
    this.user = this.$store.state.currentUser ?? (await getUser());
    this.restrictions = this.user?.restrictions.features;

    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
      this.colorTheme = theme === 'dark' ? 'white !important' : '';
    });
  },
};
</script>
