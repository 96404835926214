<template>
  <div v-if="!isMobile">
    <template v-if="getDevices == null">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </template>
    <template v-else>
      <div style="height: 100%; overflow-y: none">
        <v-row id="search-device-list" class="d-flex align-center ma-2" style="height: 36px">
          <v-col cols="10" class="pa-0">
            <v-text-field
              hide-details
              prepend-icon="mdi-magnify"
              single-line
              class="write-search pa-0"
              v-model="searchBar"
              @keyup="searchDevice"
              style="margin-left: 20px"
              data-cy="deviceSearcher">
            </v-text-field>
          </v-col>

          <v-spacer></v-spacer>

          <v-col cols="2" class="d-flex align-center justify-center pa-0">
            <v-menu :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn data-cy="filter" icon color="blue-grey" v-bind="attrs" v-on="on">
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>

              <v-list class="filter-search-device-list">
                <v-list-item data-cy="temperaturesFilter">
                  <v-checkbox
                    @change="applyFilter"
                    v-model="filter.temp"
                    :label="$t('dashboard.device_card.temperatures')"></v-checkbox>
                </v-list-item>
                <v-list-item>
                  <v-checkbox
                    @change="applyFilter"
                    v-model="filter.gps"
                    :label="$t('dashboard.general.localization')"></v-checkbox>
                </v-list-item>
                <v-list-item>
                  <v-checkbox @change="applyFilter" v-model="filter.frigo" label="Motor frigo encendido"></v-checkbox>
                </v-list-item>
                <v-list-item>
                  <v-checkbox @change="applyFilter" v-model="filter.door" label="Puerta abierta"></v-checkbox>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-virtual-scroll
          id="container-temp-card"
          bench="200"
          :items="getDevices"
          item-height="103"
          class="blue-grey"
          style="overflow-x: none !important"
          :key="scrollKey">
          <template v-slot:default="{ item }">
            <TempCard
              :key="`${item.device_sn}-temp`"
              id="temp-card"
              data-cy="tempCard"
              v-if="item.last_frames.frame_frigo"
              :device="item"
              @onShowInfo="showInfo" />
            <GpsCard
              :key="`${item.device_sn}-gps`"
              data-cy="gpsCard"
              v-else-if="item.last_frames.frame_gps"
              :device="item"
              @onShowInfo="showInfo"
              id="temp-card" />
            <NoDataCard :key="`${item.device_sn}-nodata`" v-else :device="item" @onShowInfo="showInfo" id="temp-card" />
          </template>
        </v-virtual-scroll>
      </div>
    </template>
  </div>

  <!-- Mobile view -->

  <div v-else>
    <template v-if="getDevices == null">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </template>
    <template v-else>
      <div style="height: 100%; overflow-y: none" class="device-list-style">
        <v-col cols="2" class="d-flex align-center justify-center pa-0">
          <v-btn
            id="btn-mobile-device"
            icon
            elevation="2"
            class="mt-1 ml-3 grey"
            x-small
            fab
            @click="closeBar"
            data-cy="close-devices-list">
            <v-icon style="max-width: 20%">mdi-close</v-icon>
          </v-btn>
        </v-col>
        <v-row id="search-device-list" class="d-flex align-center ma-2" style="height: 36px">
          <v-col cols="10" class="pa-0">
            <v-text-field
              hide-details
              prepend-icon="mdi-magnify"
              single-line
              class="pa-0"
              v-model="searchBar"
              @keyup="searchDevice">
            </v-text-field>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-virtual-scroll
          id="container-temp-card"
          bench="200"
          :items="getDevices"
          item-height="103"
          class="blue-grey"
          style="overflow-x: none !important"
          :key="scrollKey">
          <template id="container-temp-card" v-slot:default="{ item }">
            <TempCard
              :key="`${item.device_sn}-temp`"
              id="temp-card"
              v-if="item.last_frames.frame_frigo"
              :device="item"
              @onShowInfo="showInfo" />
            <GpsCard
              :key="`${item.device_sn}-gps`"
              id="temp-card"
              v-else-if="item.last_frames.frame_gps"
              :device="item"
              @onShowInfo="showInfo" />
            <NoDataCard :key="`${item.device_sn}-nodata`" id="temp-card" v-else :device="item" @onShowInfo="showInfo" />
          </template>
        </v-virtual-scroll>
      </div>
    </template>
  </div>
</template>

<script>
import TempCard from '../deviceCard/tempCard/tempCard.vue';
import GpsCard from '../deviceCard/gpsCard/gpsCard.vue';
import NoDataCard from '../deviceCard/noDataCard/noDataCard.vue';
import router from '../../../plugins/router/index';
import { eventBus } from '../../../main';

export default {
  components: {
    TempCard,
    GpsCard,
    NoDataCard,
  },
  data: () => ({
    loading: true,
    closeListDevice: true,
    devicesList: [],
    devicesToUse: [],
    devicesListClone: [],
    filter: {
      temp: true,
      gps: true,
      frigo: false,
      door: false,
    },
    searchBar: '',
    scrollKey: 0,
    lastSelected: null,
  }),

  mounted: async function () {
    this.getDevices;
    this.devicesListClone = this.devicesList;
    eventBus.$on('cleanSearch', () => {
      this.cleanSearch();
    });
  },

  computed: {
    getDevices() {
      this.devicesList.sort((a, b) => {
        if (a.alias?.toString().toLowerCase() < b.alias?.toString().toLowerCase()) return -1;
        if (a.alias?.toString().toLowerCase() > b.alias?.toString().toLowerCase()) return 1;
        return 0;
      });

      return this.devicesList;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },

  methods: {
    lineChart() {},
    closeBar() {
      this.$emit('dontShow');
    },
    onCLoseListDevice() {
      this.closeListDevice = false;
    },
    forceRender() {
      this.scrollKey += 1;
    },
    addDeviceToList(device, isLast) {
      this.isLoading = !isLast;
      const found = this.devicesList.find((deviceList) => deviceList.device_sn == device.device_sn);
      if (!found) {
        this.devicesList.push(device);
      }
    },
    removeDeviceFromList(device, isLast, index) {
      this.isLoading = !isLast;
      this.devicesList.splice(index, 0);
    },
    showInfo(device) {
      eventBus.$emit('closePoiDataFormOnMap');

      if (this.lastSelected && this.lastSelected != device) {
        for (let index = 0; index < this.devicesList.length; index++) {
          const element = this.devicesList[index];
          element.device_sn == this.lastSelected.device_sn;
          this.devicesList[index].select = false;
        }
      }
      this.lastSelected = device;

      if (device.model == 'tracker') {
        this.$emit('onShowInfo', device);
        router.push({
          name: 'map',
        });
      }
      this.$emit('onShowInfo', device);
    },

    selectDevice(device, param) {
      if (device) {
        this.$emit('onExitRoutes');
        let index = this.devicesList.indexOf(device);

        this.devicesList[index].select = param;
        if (this.lastSelected && this.lastSelected != device) {
          let index = this.devicesList.indexOf(this.lastSelected);
          this.devicesList[index].select = false;
        }
        this.lastSelected = device;
      }
    },
    cleanSearch() {
      this.searchBar = '';
    },

    applyFilter() {
      this.$emit('onApplyFilter', this.filter);
    },
    reset() {
      this.devicesList = [];
    },
    searchDevice() {
      this.$emit('onSearch', { search: this.searchBar, filter: this.filter });
    },
    isMobile2() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>
