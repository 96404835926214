const PetitionError = require('../../petitionError');
const { getAnonymous } = require('../../httpRequester');

class GetLocationsByDayException extends PetitionError {
  constructor(code) {
    super(' Get locations by Day: ', code);
  }
}

async function getLocationsByDayPetition(device_sn, dates, token) {
  const timezone = new Date().getTimezoneOffset() / -60;

  const response = await getAnonymous(
    `devices/locations_by_day?device_sn=${device_sn}&token=${token}&startDate=${dates[0]}&endDate=${dates[1]}&timezone=${timezone}`,
  );
  // No es anónima, uso el token, pero así puedo mandarle por query el id también.

  if (response.result !== GetLocationsByDayException.success) {
    throw new GetLocationsByDayException(response.result);
  } else {
    return response.locations; // TO DO transform
  }
}

// class GetLocationsByDayException extends PetitionError {
//   constructor(code) {
//     super(' Get locations ByDay: ', code);
//   }
// }

module.exports = {
  getLocationsByDayPetition,
  GetLocationsByDayException,
};
