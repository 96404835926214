const PetitionError = require('../../petitionError');
const { delRaw } = require('../../httpRequester');

class DeletePOICategoryException extends PetitionError {
  constructor(code) {
    super('Delete POI Category', code);
  }
}

async function deletePOICategory(token, poiCategoryId) {
  const response = await delRaw(`poiCategories/${poiCategoryId}`, token);

  if (response.result !== DeletePOICategoryException.success) {
    return response.result;
  }
  return response;
}

module.exports = {
  deletePOICategory,
  DeletePOICategoryException,
};
