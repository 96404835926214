<template>
  <v-container v-if="!isMobile" class="admin-container-desktop pa-2">
    <v-row class="admin-content">
      <v-col cols="2" class="menu-admin">
        <v-navigation-drawer permanent class="panel-left-admin">
          <v-toolbar-title class="mb-5" style="margin-top: 2rem; margin-left: 1em"
            ><b>{{ $t('admin_menu.name') }}</b>
          </v-toolbar-title>
          <v-divider></v-divider>

          <v-tabs vertical class="tab-vertical">
            <v-tab to="/admin/devices" data-cy="adminDevices" class="panel-left-btn d-flex justify-space-between">
              <v-icon>mdi-cellphone-link</v-icon>
              <span>{{ $t('admin_menu.devices') }}</span>
              <v-icon>mdi-chevron-right</v-icon>
            </v-tab>
            <v-divider></v-divider>
            <v-tab to="/admin/beacons" data-cy="adminBeacons" class="panel-left-btn d-flex justify-space-between">
              <v-icon>mdi-bluetooth</v-icon>
              <span>{{ $t('admin_menu.beacons') }}</span>
              <v-icon>mdi-chevron-right</v-icon>
            </v-tab>
            <v-divider></v-divider>
            <v-tab to="/admin/users" data-cy="adminUsers" class="panel-left-btn d-flex justify-space-between">
              <v-icon>mdi-account-multiple</v-icon>
              <span>{{ $t('admin_menu.users') }}</span>
              <v-icon>mdi-chevron-right</v-icon>
            </v-tab>
            <v-divider></v-divider>
            <v-tab to="/admin/companies" data-cy="adminCompanies" class="panel-left-btn d-flex justify-space-between">
              <v-icon>mdi-briefcase-account</v-icon>
              <span>{{ $t('admin_menu.companies') }}</span>
              <v-icon>mdi-chevron-right</v-icon>
            </v-tab>
            <v-divider></v-divider>
            <v-tab to="/admin/search" class="panel-left-btn d-flex justify-space-between">
              <v-icon>mdi-archive-search-outline</v-icon>
              <span>{{ $t('admin_menu.search') }}</span>
              <v-icon>mdi-chevron-right</v-icon>
            </v-tab>
            <v-divider></v-divider>
            <v-tab to="/admin/simcards" data-cy="adminSimcards" class="panel-left-btn d-flex justify-space-between">
              <v-icon>mdi-sim</v-icon>
              <span>{{ $t('admin_menu.sim_cards') }}</span>
              <v-icon>mdi-chevron-right</v-icon>
            </v-tab>
          </v-tabs>
        </v-navigation-drawer>
      </v-col>
      <v-col cols="10" class="admin-table">
        <router-view style="z-index: -1"></router-view>
      </v-col>
    </v-row>
  </v-container>

  <!-- Mobile View -->

  <v-container v-else class="admin-container-mobile">
    <router-view style="z-index: -1" class="admin-content-mobile"></router-view>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
  methods: {},
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>
