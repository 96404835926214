const PetitionError = require("../../petitionError");
const { get } = require("../../httpRequester");

class GetUsersException extends PetitionError {
    constructor(code) {
        super("Get Users", code);
    }
}

async function getUsers(token) {
    // TODO add security
    const response = await get("users/", token);

    if (response.result !== GetUsersException.success) {
        throw new GetUsersException(response.result);
    } else {
        return response.users; // TO DO transform
    }
}

// class GetUsersException extends PetitionError {
//   constructor(code) {
//     super('Get Users', code);
//   }
// }

module.exports = {
    getUsers,
    GetUsersException,
};
