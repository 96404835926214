const PetitionError = require('../../petitionError');
const { del } = require('../../httpRequester');

class DeleteMaintenanceException extends PetitionError {
  constructor(code) {
    super(' ERROR : Error when delete the maintenance', code);
  }
}

async function deleteMaintenancePetition(token, payload) {
  const response = await del('maintenances/', payload, token);

  if (response.result !== DeleteMaintenanceException.success) {
    throw new DeleteMaintenanceException(response.result);
  } else {
    return response;
  }
}

module.exports = { deleteMaintenancePetition, DeleteMaintenanceException };
