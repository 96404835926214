const { getTemperatureTicket } = require('../server/petitions/reports/getTemperatureTicket');

const { sendTemperatureTicket } = require('../server/petitions/reports/sendTemperatureTicket');

const { scheduleReport } = require('../server/petitions/reports/scheduleReport');
const { deleteScheduledReport } = require('../server/petitions/reports/deleteScheduledReport');

const { getAllScheduledReports } = require('../server/petitions/reports/getAllScheduledReports');

const { createDocsReport } = require('../server/petitions/reports/createDocsReport');

const userViewModel = require('./userViewModel');
const { downloadConfig } = require('../server/petitions/reports/getConfig');

let reports = []; // Esto estaba dentro de la variable REportsViewModel (línea 19), pero las funciones internas no la reconocían. Al sacarlo ya funciona pero no sé qué consecuencias podrá tener.

const ReportsViewModel = function () {
  // let reports = []
  async function getTicket(format, date_from, date_to, temperature_interval, device_sn, carplate, cif, email, probes) {
    try {
      const token = userViewModel.getToken();
      await getTemperatureTicket(
        format,
        date_from,
        date_to,
        temperature_interval,
        device_sn,
        carplate,
        cif,
        token,
        email,
        probes,
      );
    } catch (e) {
      console.error('ERRROR: ', e);
      return null;
    }
  }
  async function getConfig(payload) {
    try {
      const token = userViewModel.getToken();
      await downloadConfig(payload, token);
    } catch (e) {
      console.error('ERRROR: ', e);

      return null;
    }
  }

  async function sendTicketByEmail(email, date_from, date_to, temperature_interval, device_sn, carplate, cif, probes) {
    try {
      const user = await userViewModel.getUser();
      const token = userViewModel.getToken();
      const res = await sendTemperatureTicket(
        email,
        date_from,
        date_to,
        temperature_interval,
        device_sn,
        carplate,
        cif,
        user,
        token,
        user.email,
        probes,
      );
      return res;
    } catch (e) {
      console.error('ERRROR: ', e);

      return null;
    }
  }

  async function programReport(report) {
    try {
      if (reports === undefined) {
        reports = [];
      }
      const token = userViewModel.getToken();
      const user = await userViewModel.getUser();

      reports.push(report);
      const response = await scheduleReport(report, token, user.email);
      return response;
    } catch (e) {
      console.error('ERRROR: ', e);
      return null;
    }
  }

  async function getProgrammedReports() {
    if (reports.length === 0) {
      const user = await userViewModel.getUser();
      const token = userViewModel.getToken();
      response = await getAllScheduledReports(token, user.email);
      reports = response;
    }
    if (reports === undefined) {
      reports = [];
    }
    return reports;
  }

  async function deleteProgrammedReport(report) {
    try {
      const token = userViewModel.getToken();
      const user = await userViewModel.getUser();
      const response = await deleteScheduledReport(report, token, user.email);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  function reset() {
    reports = [];
  }

  const reportDocs = async (docs) => {
    try {
      const token = userViewModel.getToken();
      const response = await createDocsReport(token, docs);

      return response;
    } catch (error) {
      console.log(error);
    }
  };
  return {
    getTicket,
    getConfig,
    sendTicketByEmail,
    programReport,
    getProgrammedReports,
    deleteProgrammedReport,
    reset,
    reportDocs,
  };
};

module.exports = ReportsViewModel();
