const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class saveOdometerDeviceException extends PetitionError {
  static serialNumberAlreadyInDevice = 'SERIALNUMBER_ALREADY_IN_DEVICE';

  constructor(code) {
    super('Save Odometer', code);
  }
}

async function saveOdometerDevice(token, device_sn, odometer, offset) {
  const payload = {
    odometer,
    device_sn,
    offset,
  };
  const response = await post('devices/save_odometer', payload, token);
  if (response.result !== saveOdometerDeviceException.success) {
    return new saveOdometerDeviceException(response.result);
  }
  return response; // TODO transform
}

// class saveOdometerDeviceException extends PetitionError {
//   static serialNumberAlreadyInDevice = 'SERIALNUMBER_ALREADY_IN_DEVICE';

//   constructor(code) {
//     super('Save Odometer', code);
//   }
// }

module.exports = {
  saveOdometerDevice,
  saveOdometerDeviceException,
};
