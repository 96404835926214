<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="breadcrumbs-container breadcrumbs-container-edit-company">
          <v-row style="justify-content: center;text-align: center;">
            <h1>{{ companyData.name }}</h1>
          </v-row>
          <v-row class="breadcrumbs" style="justify-content: center;">
            <v-breadcrumbs :items="items" class="pl-0">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                  {{ item.text.toUpperCase() }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-row>
        </div>

        <CompanyDataForm :editingCompany="true" ref="companyData" forCompany="0" :companyData="companyData" style="width: 70%;margin: auto;">
        </CompanyDataForm>
      </v-col>
    </v-row>

    <v-col class="d-flex mt-10" style="justify-content: space-around;">
      <v-btn color="primary" class="d-flex justify-space-between" @click="save">
        <v-icon>mdi-content-save</v-icon>{{ $t('general.form.save') }}
      </v-btn>
      <v-btn color="red" class="d-flex justify-space-between" @click="goBack">
        <v-icon class="white--text">mdi-exit-to-app</v-icon>
        <span class="white--text">{{ $t('general.form.exit') }}</span>
      </v-btn>
    </v-col>


    <ErrorDialog :isActive="errorDialog" v-bind:tittleText="$t('general.dialog.error.title')" :messageText="errorText"
      v-bind:acceptButtonText="$t('general.dialog.error.accept_button')" v-on:accept-dialog="errorDialog = false">
    </ErrorDialog>
  </v-container>
</template>

<script>
import router from '../../../../plugins/router/index';
import CompanyDataForm from '../../../../components/forms/companyDataForm/companyDataForm.vue';
import { modifyCompanyByAdmin } from '../../../../viewModels/adminViewModel';
import ErrorDialog from '../../../../components/errorDialog.vue';
import { ModifyCompanyException } from '../../../../server/petitions/companies/modifyCompanyByAdmin';

export default {
  components: {
    CompanyDataForm,
    ErrorDialog,
  },
  props: {
    companyData: {
      type: Object,
    },
  },
  data: () => ({
    errorDialog: false,
    errorText: '',
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '../../../dashboard/temperatures',
      },
      {
        text: 'Empresas',
        disabled: false,
        href: '/admin/companies',
      },
      {
        text: 'Empresa',
        disabled: true,
        href: '/admin/companies/edit',
      }
    ],
  }),
  methods: {
    save: async function () {
      try {
        var companyFormData = this.$refs.companyData;

        if (companyFormData.validate()) {
          let company_name = companyFormData.companyName;
          let company_cif = companyFormData.companyCIF;
          let company_phone = companyFormData.companyPhone;
          let company_email = companyFormData.companyEmail;
          let company_notes = companyFormData.companyNotes;

          await modifyCompanyByAdmin(
            this.companyData._id,
            company_name,
            company_cif,
            company_phone,
            company_email,
            company_notes,
          );
          this.goBack();
        }
      } catch (e) {
        switch (e.code) {
          case ModifyCompanyException.unknownError:
          case ModifyCompanyException.invalidToken:
          case ModifyCompanyException.expiredToken:
            console.error(e);
            this.errorText = this.$t('general.error.unexpected_error');
            this.errorDialog = true;
            break;
          case ModifyCompanyException.incorrectParameters:
            console.error(e);
            this.errorText = this.$t('general.error.incorrect_parameters');
            this.errorDialog = true;
            break;
          case ModifyCompanyException.unknownCompany:
            this.errorText = this.errorText = this.$t('company.delete.error.unknown_company');
            this.errorDialog = true;
            break;
          case ModifyCompanyException.nameAlreadyInCompany:
            this.errorText = this.$t('company.add_modify.name_already_in_company');
            this.errorDialog = true;
            break;
          case ModifyCompanyException.cifAlreadyInCompany:
            this.errorText = this.$t('company.add_modify.cif_already_in_company');
            this.errorDialog = true;
            break;
          default:
            console.error('CATCH ERROR:', e);
        }
      }
    },
    passwordView: function () {
      router.push({
        name: 'changeUserPassword',
        params: { userId: this.userData._id, userData: this.userData },
      });
    },
    goBack: function () {
      router.push({ name: 'companies' });
    },
  },
};
</script>
