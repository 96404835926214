const PetitionError = require('../../petitionError');
const { getAnonymous } = require('../../httpRequester');

class getAllSimsException extends PetitionError {
  constructor(code) {
    super(' Get All Sims ', code);
  }
}

async function getAllSims(token) {
  const response = await getAnonymous(`simcards/all_sims?token=${token}`);

  if (response.result !== getAllSimsException.success) {
    throw new getAllSimsException(response.result);
  } else {
    return response.simCards;
  }
}

module.exports = {
  getAllSimsPetition: getAllSims,
  getAllSimsException,
};
