const brokerConnection = require('../../brokerConnection/brokerConnection');
const PetitionError = require('../../petitionError');

class GetUserDevicesException extends PetitionError {
  static userNotHaveDevices = 'USER_NOT_HAVE_DEVICES';

  constructor(code) {
    super('Get user devices Exception', code);
  }
}

async function getUserdevices(token, onDeviceObtained) {
  const payload = {};
  const topic = 'devices/get_devices';
  const payloadString = JSON.stringify(payload);

  await brokerConnection.sendAndWaitMultipleResponses(topic, token, payloadString, (msg) => {
    const response = JSON.parse(msg.toString());
    if (response.result !== GetUserDevicesException.success) {
      throw new GetUserDevicesException(response.result);
    }

    if (response.isLast) {
      brokerConnection.stopReceiving(topic);
    }

    onDeviceObtained(response.device, response.isLast); // TODO transform
  });
}

module.exports = {
  getUserdevices,
  GetUserDevicesException,
};
