const PetitionError = require('../../petitionError');
const { delRaw } = require('../../httpRequester');

class DeleteReminderPetitionException extends PetitionError {
  constructor(code) {
    super('Delete reminder', code);
  }
}

async function deleteReminderPetition(token, id) {
  const response = await (await delRaw(`reminder/${id}`, token)).json();

  if (response.result !== DeleteReminderPetitionException.success) {
    return response.result;
  }
  return response;
}

module.exports = {
  deleteReminderPetition,
  DeleteReminderPetitionException,
};
