<template>
  <v-container v-if="!isMobile" class="d-flex justify-center align-center">
    <v-dialog v-model="alert" width="40%">
      <v-alert class="ma-0" v-model="alert" border="top" colored-border type="warning" dismissible>
        {{ alertMessage }}
      </v-alert>
    </v-dialog>
    <v-card class="d-flex px-4" style="margin-top: 8rem" elevation="10" height="100%" width="50%">
      <v-row class="justify-center">
        <div class="ma-2">
          <v-img class="mx-3 pt-5 mt-3" contain max-width="400" :src="logo"></v-img>
        </div>

        <v-card-text class="py-0">
          <v-form @submit.prevent="validateUser()" ref="loginForm" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" class="px-8">
                <v-text-field
                  v-model="loginEmail"
                  label="Email"
                  @change="onEmailChange"
                  :rules="nameRules"
                  :error-messages="emailIncorrectMessage"
                  required
                  autocomplete="email"
                  data-cy="email"></v-text-field>
              </v-col>
              <v-col cols="12" class="px-8">
                <v-text-field
                  v-model="loginPassword"
                  @change="onPasswordChange"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (showPassword = !showPassword)"
                  :type="showPassword ? 'password' : 'text'"
                  v-bind:label="$t('user.password.name')"
                  v-bind:hint="$t('user.password.hint')"
                  counter
                  :rules="passwordRules"
                  :error-messages="passwordIncorrectMessage"
                  required
                  data-cy="password"
                  autocomplete="password"></v-text-field>
                <p class="mt-3 text-md-center font-weight-thin">{{ $t('login.manufacturers') }}</p>
              </v-col>

              <v-col class="d-flex justify-center">
                <v-btn type="submit" x-large color="primary" :loading="loggingUser">
                  {{ $t('login.login_button') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>

    <v-footer :padless="true" fixed>
      <v-card flat tile width="100%" color="#3B444F" class="lighten-1 text-right">
        <v-card-text>
          <v-menu :offset-y="true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="mx-4" icon>
                <v-icon color="white" size="24px"> mdi-web </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="language in languages" @click="changeLanguage(language.code)" v-bind:key="language.code">
                <v-list-item-avatar>
                  <country-flag :country="language.icon_code" />
                </v-list-item-avatar>

                <v-list-item-title>{{ language.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-container>

  <v-container v-else class="d-flex justify-center align-center">
    <v-dialog v-model="alert" width="40%">
      <v-alert class="ma-0" v-model="alert" border="top" colored-border type="warning" dismissible>
        {{ alertMessage }}
      </v-alert>
    </v-dialog>
    <v-card class="d-flex px-4" style="margin-top: 4rem" elevation="10" height="100%">
      <v-row class="justify-center">
        <div class="mt-5">
          <v-img contain max-width="250" :src="logo"></v-img>
        </div>

        <v-card-text>
          <v-form @submit.prevent="validateUser()" ref="loginForm" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" class="px-8">
                <v-text-field
                  v-model="loginEmail"
                  label="Email"
                  @change="onEmailChange"
                  :rules="nameRules"
                  :error-messages="emailIncorrectMessage"
                  required
                  autocomplete="email"></v-text-field>
              </v-col>
              <v-col cols="12" class="px-8">
                <v-text-field
                  v-model="loginPassword"
                  @change="onPasswordChange"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (showPassword = !showPassword)"
                  :type="showPassword ? 'password' : 'text'"
                  v-bind:label="$t('user.password.name')"
                  v-bind:hint="$t('user.password.hint')"
                  counter
                  :rules="passwordRules"
                  :error-messages="passwordIncorrectMessage"
                  required
                  autocomplete="password"></v-text-field>
              </v-col>

              <v-col class="d-flex justify-center">
                <v-btn type="submit" x-large color="primary" :loading="loggingUser">
                  {{ $t('login.login_button') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>

    <v-footer :padless="true" fixed>
      <v-card flat tile width="100%" color="#3B444F" class="lighten-1 text-right">
        <v-card-text>
          <v-menu :offset-y="true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="mx-4" icon>
                <v-icon color="white" size="24px"> mdi-web </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="language in languages" @click="changeLanguage(language.code)" v-bind:key="language.code">
                <v-list-item-avatar>
                  <country-flag :country="language.icon_code" />
                </v-list-item-avatar>

                <v-list-item-title>{{ language.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-container>
</template>

<script>
import router from '../plugins/router/index';
import { LoginByEmailException } from '../server/petitions/authentication/loginByEmail';
import userViewModel from '../viewModels/userViewModel';
import constants from '../plugins/lang/constants';

export default {
  data: () => ({
    languages: constants.locales,
    showPassword: String,
    valid: false,
    alert: false,
    alertMessage: '',
    loginEmail: '',
    loginPassword: '',
    passwordIncorrectMessage: null,
    emailIncorrectMessage: null,
    loggingUser: false,
    logo: require(process.env.VUE_APP_LOGO_COLOR),
  }),
  methods: {
    validateUser: async function () {
      var isValid = this.$refs.loginForm.validate();
      if (isValid) {
        try {
          this.loggingUser = true;
          var loggedUser = await userViewModel.loginUserByEmail(this.loginEmail, this.loginPassword);
          this.loggingUser = false;
          router.push({ path: '/' });
        } catch (e) {
          switch (e.code) {
            case LoginByEmailException.errorIncorrectPassword:
              this.passwordIncorrectMessage = this.$t('user.password.error.incorrect_password');

              break;
            case LoginByEmailException.errorIncorrectEmail:
              this.emailIncorrectMessage = this.$t('user.email.error.incorrect_email');

              break;
            default:
              console.error('CATCH ERROR:', e);
          }
          this.loggingUser = false;
        }
      }
    },

    onPasswordChange: function () {
      this.passwordIncorrectMessage = null;
    },
    onEmailChange: function () {
      this.emailIncorrectMessage = null;
    },
    changeLanguage: function (code) {
      userViewModel.changeUserLanguage(this, code);
    },

    setUserDefaultLanguage: function () {
      userViewModel.setLanguageToUserDefault(this);
    },
  },
  mounted() {
    this.setUserDefaultLanguage();
  },
  computed: {
    nameRules() {
      return [(text) => !!text || this.$t('login.error.empty_user')];
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    passwordRules() {
      return [
        (text) => !!text || this.$t('user.password.error.empty_password'),
        (text) => (text && text.length >= 5) || this.$t('user.password.error.invalid_password'),
      ];
    },
  },
};
</script>
<style scoped></style>
