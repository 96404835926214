const PetitionError = require('../../petitionError');
const { getAnonymous } = require('../../httpRequester');
const ls = require('localstorage-slim');

class GetFramesTimeLineException extends PetitionError {
  constructor(code) {
    super(' Get time line today: ', code);
  }
}

// Añadimos el parámetro dates

async function getFramesTimeLine(device_sn, token, dates, resolveGeocode = false) {
  const timezone = new Date().getTimezoneOffset() / 60;

  const endpoint = `devices/get_frames_time_line?device_sn=${device_sn}&token=${token}&timezone=${timezone}&dates=${dates}&resolveGeocode=${resolveGeocode}`;

  //ls.flush();
  const responseFromLS = ls.get(endpoint);

  if (responseFromLS) {
    return responseFromLS;
  }

  const response = await getAnonymous(endpoint);

  // No es anónima, uso el token, pero así puedo mandarle por query el id también.

  if (response.result !== GetFramesTimeLineException.success) {
    throw new GetFramesTimeLineException(response.result);
  } else {
    ls.set(endpoint, response.timeline ?? [], { ttl: 60 });

    return response.timeline; // TO DO transform
  }
}

module.exports = {
  getFramesTimeLine,
  GetFramesTimeLineException,
};
