const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class CreateBeaconException extends PetitionError {
  static macAddressAlreadyInBeacon = 'MAC_ADDRESS_ALREADY_IN_BEACON';

  constructor(code) {
    super('Create Beacon', code);
  }
}

async function createBeacon(token, payload) {
  const response = await post('beacons/', payload, token);

  if (response.result !== CreateBeaconException.success) {
    throw new CreateBeaconException(response.result);
  } else {
    return response.beacon;
  }
}

module.exports = { createBeacon, CreateBeaconException };
