<template>
  <v-form ref="poiForm" lazy-validation>
    <v-row>
      <v-col cols="12">
        <v-text-field autofocus v-model="value.name" :label="$t('shared.name')" :rules="validations.nameRules"> </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="value.address" :label="$t('shared.address')" :rules="validations.addressRules"> </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="value.latitude"
              :label="$t('device.last_frames.frame_gps.latitude')"
              :rules="validations.latitudeRules"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="value.longitude"
              :label="$t('device.last_frames.frame_gps.longitude')"
              :rules="validations.longitudeRules"></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-text-field v-model="value.radius" :label="$t('dashboard.poi_radius')" :rules="validations.radiusRules" type="number">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          v-model="value.categoryId"
          :disabled="isUpdating"
          :items="poiCategories"
          color="blue-grey lighten-2"
          :label="$t('dashboard.pois_categories.category')"
          item-text="name"
          item-value="_id">
          <template v-slot:selection="data">
            <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="clear()">
              <v-avatar left>
                <v-img :src="data.item.icon"></v-img>
              </v-avatar>
              {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template>
              <v-list-item-avatar>
                <img :src="data.item.icon" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { eventBus } from '../../../main';
import { getToken, getUser } from '../../../viewModels/userViewModel';
import { getAllPOICategories } from '../../../server/petitions/pois/getAllPOICategories';

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data: (instance) => ({
    poiCategories: [],
    isUpdating: false,
    defaultIcon: '/assets/markers/pi.png',
  }),
  methods: {
    reset() {
      this.$refs.poiForm?.resetValidation();
    },
    clear() {
      this.value.categoryId = '';
    },
    isFormValid() {
      return this.$refs.poiForm.validate();
    },
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
  },
  mounted: async function () {
    this.currentUser = this.$store.state.currentUser ?? (await getUser());
    this.token = getToken();

    const poiCategoriesFromDb = await getAllPOICategories(this.token, this.currentUser.userId);

    this.poiCategories = poiCategoriesFromDb.map((poiCategoryFromDb) => {
      if (!poiCategoryFromDb.icon) poiCategoryFromDb.icon = this.defaultIcon;

      return poiCategoryFromDb;
    });
  },
  created: function () {
    eventBus.$on('reloadPois', async () => {
      const poiCategoriesFromDb = await getAllPOICategories(this.token, this.currentUser.userId);

      this.poiCategories = poiCategoriesFromDb.map((poiCategoryFromDb) => {
        if (!poiCategoryFromDb.icon) poiCategoryFromDb.icon = this.defaultIcon;
        return poiCategoryFromDb;
      });
    });
  },
  computed: {
    validations() {
      const addressRules = [];
      if ((this.value.longitude == '' && this.value.latitude == '') || (this.value.longitude == 0 && this.value.latitude == 0)) {
        addressRules.push((value) => !!value || this.$t('general.error.empty_value'));
      }

      return {
        nameRules: [
          (value) => !!value || this.$t('general.error.empty_value'),
          (value) => (value && value.length <= 18) || this.$t('dashboard.poi_name_length'),
        ],
        radiusRules: [
          (value) => (!isNaN(parseFloat(value)) && value >= 10 && value <= 999) || this.$t('general.error.must_be_number'),
        ],
        addressRules,
      };
    },
  },
};
</script>
