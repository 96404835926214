const PetitionError = require('../../petitionError');
const { del } = require('../../httpRequester');

class DeleteCertificateException extends PetitionError {
  constructor(code) {
    super(' ERROR : Error when deleting the certificate', code);
  }
}

async function deleteCertificate(token, certificateId, deviceSn) {
  const payload = { certificateId, deviceSn };

  const response = await del('devices/certificate', payload, token);

  if (response.result !== DeleteCertificateException.success) {
    throw new DeleteCertificateException(response.result);
  } else {
    return response; // TO DO transform
  }
}

module.exports = {
  deleteCertificate,
  DeleteCertificateException,
};
