<template>
  <v-row id="content-temperatures" class="ma-2 justify-center text-caption">
    <div v-if="p1 != '----' || p2 != '----'">
      <div v-if="!isMobile" class="d-flex justify-end contain-temp-card">
        <v-col class="tooltip text-center" data-cy="tooltip">
          <v-sheet class="temperature-card" elevation="1" rounded :class="myclass" :style="cardTemperatureStyle1">
            <div id="temperature-card-content">
              <span class="tooltiptext" data-cy="tooltiptext">{{ this.$t('dashboard.probes.probe1') }}</span>
              <div id="temp-ll">
                {{ p1 }}
              </div>
            </div>
            <!-- <font-awesome-icon icon="fa-solid fa-temperature-three-quarters" class="temperature-card-icon icon-thermometer" /> -->
          </v-sheet>
        </v-col>
        <v-col class="tooltip" data-cy="tooltip">
          <v-sheet class="temperature-card" elevation="1" rounded :class="myclass" :style="cardTemperatureStyle1">
            <div id="temperature-card-content">
              <span class="tooltiptext" data-cy="tooltiptext">{{ this.$t('dashboard.probes.probe2') }}</span>
              <div id="temp-ll">
                {{ p2 }}
              </div>
            </div>
            <!-- <font-awesome-icon icon="fa-solid fa-temperature-three-quarters" class="temperature-card-icon icon-thermometer" /> -->
          </v-sheet>
          {{ getFrigoTimestampTooltip }}
        </v-col>
      </div>
      <div v-else class="d-flex justify-end contain-temp-card">
        <v-col class="tooltip" data-cy="tooltip">
          <v-sheet class="temperature-card" elevation="1" rounded :class="myclass" :style="cardTemperatureStyle2">
            <div id="temperature-card-content">
              <span class="tooltiptext" data-cy="tooltiptext">{{ this.$t('dashboard.probes.probe1') }}</span>
              <div id="temp-ll">
                {{ p1 }}
              </div>
            </div>
            <!-- <font-awesome-icon icon="fa-solid fa-temperature-three-quarters" class="temperature-card-icon icon-thermometer" /> -->
          </v-sheet>
        </v-col>
        <v-col class="tooltip" data-cy="tooltip">
          <v-sheet class="temperature-card" elevation="1" rounded :class="myclass" :style="cardTemperatureStyle2">
            <div id="temperature-card-content">
              <span class="tooltiptext" data-cy="tooltiptext">{{ this.$t('dashboard.probes.probe2') }}</span>
              <div id="temp-ll">
                {{ p2 }}
              </div>
            </div>
            <!-- <font-awesome-icon icon="fa-solid fa-temperature-three-quarters" class="temperature-card-icon icon-thermometer" /> -->
          </v-sheet>
          {{ getFrigoTimestampTooltip }}
        </v-col>
      </div>
    </div>

    <!--  -->
    <div v-if="this.p3 != '----' || this.p4 != '----'">
      <div v-if="!isMobile" class="d-flex justify-end contain-temp-card">
        <v-col v-if="this.p3 != '----'" class="tooltip">
          <v-sheet class="temperature-card" elevation="1" rounded :class="myclass" :style="cardTemperatureStyle1">
            <div id="temperature-card-content">
              <span class="tooltiptext" data-cy="tooltiptext">{{ this.$t('dashboard.probes.probe3') }}</span>
              <div id="temp-ll">
                {{ p3 }}
              </div>
            </div>
            <!-- <v-icon class="temperature-card-icon icon-water" light> mdi-water-percent </v-icon> -->
          </v-sheet>
        </v-col>
        <v-col v-if="this.p4 != '----'" class="tooltip">
          <v-sheet class="temperature-card" elevation="1" rounded :class="myclass" :style="cardTemperatureStyle1">
            <div id="temperature-card-content">
              <span class="tooltiptext" data-cy="tooltiptext">{{ this.$t('dashboard.probes.probe4') }}</span>
              <div id="temp-ll">
                {{ p4 }}
              </div>
            </div>
            <!-- <v-icon class="temperature-card-icon icon-water" light> mdi-water-percent</v-icon> -->
          </v-sheet>
          {{ getFrigoTimestampTooltip }}
        </v-col>
      </div>
      <div v-else class="d-flex justify-end contain-temp-card">
        <v-col class="tooltip" data-cy="tooltip">
          <v-sheet class="temperature-card" elevation="1" rounded :class="myclass" :style="cardTemperatureStyle2">
            <div id="temperature-card-content">
              <span class="tooltiptext" data-cy="tooltiptext">{{ this.$t('dashboard.probes.probe3') }}</span>
              <div id="temp-ll">
                {{ p3 }}
              </div>
            </div>
            <!-- <font-awesome-icon icon="fa-solid fa-droplet" class="temperature-card-icon icon-water" /> -->
          </v-sheet>
        </v-col>
        <v-col class="tooltip" data-cy="tooltip">
          <v-sheet class="temperature-card" elevation="1" rounded :class="myclass" :style="cardTemperatureStyle2">
            <div id="temperature-card-content">
              <span class="tooltiptext" data-cy="tooltiptext">{{ this.$t('dashboard.probes.probe4') }}</span>
              <div id="temp-ll">
                {{ p4 }}
              </div>
            </div>
            <!-- <font-awesome-icon icon="fa-solid fa-droplet" class="temperature-card-icon icon-water" /> -->
          </v-sheet>
          {{ getFrigoTimestampTooltip }}
        </v-col>
      </div>
    </div>
  </v-row>
</template>

<script>
export default {
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
    p1: {
      type: String,
      default: '----',
    },
    p2: {
      type: String,
      default: '----',
    },
    p3: {
      type: String,
      default: '----',
    },
    p4: {
      type: String,
      default: '----',
    },
    p5: {
      type: String,
      default: '----',
    },
    p6: {
      type: String,
      default: '----',
    },
    p7: {
      type: String,
      default: '----',
    },
    p8: {
      type: String,
      default: '----',
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    getFrigoTimestampTooltip() {
      if (this.selectedDevice) {
        const actualDate = new Date();
        const oneDay = 24 * 60 * 60 * 1000;
        const date = new Date(this.selectedDevice.last_frames.frame_frigo.device_timestamp);
        const formatedDate = this.formatDate(this.selectedDevice.last_frames.frame_frigo.device_timestamp);
        const diffDays = Math.round(Math.abs((actualDate - date) / oneDay));

        if (diffDays < 1) {
          const diffHours = Math.round(Math.abs((actualDate - date) / 1000 / 3600));
          if (diffHours > 1) {
            return this.$t('dashboard.details.last_measure_hours', {
              hours: diffHours,
            });
          }
          const diffMinutes = Math.round(Math.abs((actualDate - date) / 1000 / 60));
          if (diffMinutes > 1) {
            return this.$t('dashboard.details.last_measure_minutes', {
              minutes: diffMinutes,
            });
          }
          return this.$t('dashboard.details.last_measure_recently');
        }

        if (diffDays < 7) {
          return this.$t('dashboard.details.last_measure_in_days', {
            days: diffDays,
          });
        }

        return this.$t('dashboard.details.last_measure_date', {
          date: formatedDate,
        });
      }
    },
  },
  data() {
    return {
      myclass: 'd-flex justify-center align-center white--text font-weight-bold text-h5',
      cardTemperatureStyle1: {
        background: '#607D8B',
        width: '15em',
        height: '4rem',
      },
      cardTemperatureStyle2: {
        background: '#78909C',
        width: '5em',
        height: '3rem',
        styele: 'font-size: 16px',
      },
    };
  },
};
</script>
