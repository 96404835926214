const PetitionError = require('../../petitionError');
const { delRaw } = require('../../httpRequester');

class DeletePOIException extends PetitionError {
  constructor(code) {
    super('Delete POI', code);
  }
}

async function deletePOI(token, poiId) {
  const response = await delRaw(`pois/${poiId}`, token);

  if (response.result !== DeletePOIException.success) {
    return response.result;
  }
  return response;
}

module.exports = {
  deletePOI,
  DeletePOIException,
};
