<template>
  <div data-cy="deviceInfoList" class="device-info-list-mobile blue-grey lighten-5">
    <template v-if="!device"></template>
    <template v-else style="max-height: 100%">
      <ButtonsHeader
        :user="user"
        :device="device"
        :carplate="carplate"
        :routeLoading="routeLoading"
        @onCloseInfo="closeInfo"
        @dontShow="closeListDevice"
        @openTicketDialog="openTicketDialog"
        @openCreateAlarmDialog="openCreateAlarmDialog"
        @openTurnOff="openTurnOff"
        @showRoute="openRoutes"
        @openConfiguration="openConfiguration"
        @closeButtons="closeButtons">
      </ButtonsHeader>

      <v-list id="content-device-List" class="overflow-y-auto blue-grey lighten-5" width="370">
        <AlarmCard
          id="alarm-card"
          v-if="openNotifications"
          :isDoorOpen="isDoorOpen"
          :isTemp1Low="isTemp1Low"
          :isTemp2Low="isTemp2Low"
          :isTemp1High="isTemp1High"
          :isTemp2High="isTemp2High"
          :isInsidePoi="isInsidePoi"
          :key="device.device_sn + 'A'">
        </AlarmCard>

        <StateCard
          v-if="device.last_frames.frame_status"
          id="status-card"
          data-cy="statusCard"
          :device="device"
          :batteryPercentage="batteryPercentage"
          :batteryLevel="batteryLevel"
          :batteryColor="batteryColor"
          :isContactOn="isContactOn"
          :isCustomOn="isCustomOn"
          :stateTimestamp="getStateTimestamp"
          :status="status"
          :key="device.device_sn + 'B'"
          :voltage="voltage">
        </StateCard>

        <PositionCard
          v-if="device.last_frames.frame_gps"
          id="position-card"
          data-cy="positionCard"
          :device="device"
          :linkInfo="linkInfo"
          :status="status"
          :odometer="odometer"
          :todayMileage="todayMileage"
          :gpsTimestamp="gpsTimestamp"
          :geocode="geocode"
          @showRoute="openRoutes"
          @openOdometer="openOdometer"
          :key="device.device_sn + 'C'">
        </PositionCard>

        <TemperatureCard
          v-if="device.last_frames.frame_frigo"
          id="temperature-card"
          data-cy="temperatureCard"
          :status="status"
          :key="device.device_sn ? device.device_sn + 'D' : 'D'"
          :device="device"
          :todaysEngineHours="todaysEngineHours"
          :frigoTimestamp="frigoTimestamp"
          @openFrigoHours="openFrigoHours"
          @openConfiguration="openConfiguration">
        </TemperatureCard>

        <CanbusCard
          v-if="device.last_frames.frame_CAN"
          id="cambus-card"
          :status="status"
          :device="device"
          :canbusTimestamp="canbusTimestamp"
          :isPinaOn="isPinaOn"
          :key="device.device_sn + 'E'">
        </CanbusCard>

        <ColdMachineCard
          v-if="device.last_frames.frame_frigo_machine"
          id="coldMachine-card"
          :status="status"
          :device="device"
          :coldMachineTimestamp="coldMachineTimestamp"
          @openModalSetPoint="openModalSetPoint"
          :key="device.device_sn + 'CO'">
        </ColdMachineCard>

        <!-- <Timeline
          :key="'C' + device.device_sn"
          id="time-line-card"
          :device="device ? device : { device_sn: null, device_data_from_frame: { carplate: null } }"
          @onTimeLine="timeLine">
        </Timeline> -->
      </v-list>
    </template>

    <TicketDialog
      :isActive="ticketDialog"
      v-on:cancel-dialog="closeDialog"
      :device="device ? device : { device_sn: null, device_data_from_frame: { carplate: null } }">
    </TicketDialog>

    <AlarmDialog
      :isActive="alarmDialog"
      v-on:cancel-dialog="closeDialog"
      :device="device ? device : { device_sn: null, device_data_from_frame: { carplate: null } }">
    </AlarmDialog>

    <TurnOffDialog
      :key="device ? 'D' + device.device_sn : 'D'"
      ref="turnOff"
      :isActive="turnOff"
      v-on:cancel-dialog="closeTurnOff"
      :device="device ? device : { device_sn: '', device_data_from_frame: { carplate: null } }">
    </TurnOffDialog>

    <Configuration
      :key="device ? 'C' + device.device_sn + update : 'C' + update"
      :isActive="configuration"
      v-on:cancel-configuration="closeConfiguration"
      :device="device ? device : null"
      :titleInput="true">
    </Configuration>

    <Odometer
      :key="device ? 'B' + device.device_sn + update : 'B' + update"
      :isActive="changeOdometer"
      v-on:cancel-odometer="closeOdometer"
      :device="device ? device : { device_sn: null, device_data_from_frame: { carplate: null } }"
      :titleInput="true">
    </Odometer>

    <FrigoHours
      :key="device ? 'A' + device.device_sn + update : 'A' + update"
      :isActive="changeFrigoHours"
      v-on:cancel-frigo="closeFrigoHours"
      :device="device ? device : { device_sn: null, device_data_from_frame: { carplate: null } }"
      :titleInput="true">
    </FrigoHours>

    <SetPointModal
      :key="device ? 'R' + device.device_sn + update : 'R' + update"
      :isActive="changeSetpoint"
      v-on:cancel-setpoint="closeSetPoint"
      :device="device ? device : { device_sn: null, device_data_from_frame: { carplate: null } }"
      :titleInput="true">
    </SetPointModal>

    <Routes
      :key="device ? 'Z' + device.device_sn + update : 'Z' + update"
      :isActive="activateRoutes"
      v-on:cancel-routes="closeRoutes"
      :device="device ? device : { device_sn: '', device_data_from_frame: { carplate: null } }"
      @showRoute="showRoute">
    </Routes>
  </div>
</template>

<script>
import router from '../../../plugins/router/index';
import { getUser } from '../../../viewModels/userViewModel';
import { getAlarms } from '../../../viewModels/alarmsViewModel';

import { calculateTodayMileage, calculateTodaysEngineHours, getReverseGeocode } from '../../../viewModels/devicesViewModel';
import { checkNotifications } from '../../../utils/device';
import { getLocationsToday, getLocationsByDay, getFramesDevice, getLinkedTracker } from '../../../viewModels/devicesViewModel';
import { eventBus } from '../../../main';
import TicketDialog from '../ticketDialogo/ticketDialog.vue';
import AlarmDialog from '../alarmDialog/alarmDialog.vue';
import TurnOffDialog from '../turnOffDialog.vue';
import Configuration from '../configuration/configuration.vue';
import Odometer from '../odometer.vue';
import FrigoHours from '../frigoHours.vue';
import SetPointModal from '../setPointModal.vue';
import Routes from '../routes.vue';
import AlarmCard from '../deviceInfoList/deviceInfoListComponents/alarmCard/alarmsCard.vue';
import StateCard from '../deviceInfoList/deviceInfoListComponents/stateCard.vue';
import PositionCard from '../deviceInfoList/deviceInfoListComponents/positionCard.vue';
import ButtonsHeader from '../deviceInfoList/deviceInfoListComponents/ButtonHeader/buttonsHeader.vue';
import TemperatureCard from '../deviceInfoList/deviceInfoListComponents/temperatureCard.vue';
import CanbusCard from '../deviceInfoList/deviceInfoListComponents/canbusCard.vue';
import ColdMachineCard from '../deviceInfoList/deviceInfoListComponents/coldMachineCard.vue';
import { getUpdatedFrames } from '../../../server/petitionsMqtt/devices/updateDevices';
const VueI18n = require('../../../plugins/lang/index');

const MOVEMENT = 'movement';

export default {
  components: {
    TicketDialog,
    AlarmDialog,
    TurnOffDialog,
    Configuration,
    Odometer,
    FrigoHours,
    SetPointModal,
    AlarmCard,
    StateCard,
    PositionCard,
    ButtonsHeader,
    TemperatureCard,
    CanbusCard,
    Routes,
    ColdMachineCard,
  },
  props: {
    device: {
      type: Object,
      default: () => ({ device_sn: null, device_data_from_frame: { carplate: null } }),
    },
    update: {
      type: Number,
      default: 0,
    },
    myPOIS: {
      type: Array,
      default: [],
    },
  },
  data: () => ({
    ticketDialog: false,
    alarmDialog: false,
    turnOff: false,
    configuration: false,
    odometer: 0,
    changeOdometer: false,
    changeFrigoHours: false,
    changeSetpoint: false,
    activateRoutes: false,
    linkInfo: false,
    user: null,
    actualDate: new Date(),
    unit: 'km',
    todaysMileage: null,
    todaysEngineHours: 0,
    status: {
      gps: false,
      status: false,
      frigo: false,
      canbus: false,
      coldMachine: false,
    },
    geocode: VueI18n.default.t('general.table.loading'),
    km: 'km',
    isContactOn: false,
    isEngineOn: false,
    isCustomOn: false,
    isPinaOn: false,
    isDoorOpen: false,
    isTemp1Low: false,
    isTemp1High: false,
    isTemp2Low: false,
    isTemp2High: false,
    isInsidePoi: false,
    batteryLevel: 100,
    batteryPercentage: 0,
    batteryColor: '',
    routeLoading: false,
    todayMileage: 0 + ' km',
    carplate: '',
    frigoTimestamp: '',
    canbusTimestamp: '',
    coldMachineTimestamp: '',
    gpsTimestamp: '',
    stateTimestamp: '',
    notifications: {},
    openNotifications: false,
    movements: [],
    voltage: 0,
  }),
  mounted: async function () {
    if (this.device) {
      if (this.device.last_frames) {
        this.getTodayMileage;
        this.getPlate;
        this.getCANBUSTimestamp;
        if (this.device.last_frames.frame_gps) {
          this.getGpsTimestamp;
          this.calculateTodayMotorHours;
          this.getOdometer;
          this.getGeocode;
        }
        if (this.device.last_frames.frame_status) {
          this.getStateTimestamp;
          this.checkBattery();
          this.checkAlarms();
        }
        this.calculateMyMileage;
        this.hasNotifications();
        if (this.device.last_frames.frame_frigo) {
          this.checkFrigo;
          this.getFrigoTimestamp;
        }

        if (this.device.last_frames.frame_frigo_machine) {
          this.getColdMachineTimestamp;
        }
      }
    }
    this.user = this.$store.state.currentUser ?? (await getUser());
    this.units = this.user.unit ?? null;
  },
  beforeMount: async function () {
    eventBus.$on('routeAsked', (device) => {
      this.showRouteFromMap(device);
    });
  },

  methods: {
    closeInfo() {
      this.$emit('onCloseInfo');
    },

    closeListDevice() {
      this.$emit('dontShow');
    },

    closeButtons() {
      this.$emit('closeButtons');
    },

    showRoute: async function (dates) {
      let serialNumber = this.device.device_sn;

      this.routeLoading = true;
      if (this.currentRouteName != 'map') {
        router.push({
          name: 'map',
        });
      }
      let locations = await getFramesDevice(serialNumber, dates);
      this.routeLoading = false;
      this.$emit('onDrawRoute', locations, this.device, dates);

      eventBus.$emit('movementLineRoute', locations);
      eventBus.$emit('reciveDevice', this.device);

      if (this.isMobile) {
        this.$emit('onCloseInfo');
      }
    },

    showRouteFromMap: async function (device) {
      const serialNumber = device.device_sn;

      let locations = await getLocationsToday(serialNumber);
      this.routeLoading = false;
      this.$emit('onDrawRoute', locations, device);
      if (this.isMobile) {
        this.$emit('onCloseInfo');
      }
    },

    openTicketDialog() {
      this.ticketDialog = true;
    },
    openCreateAlarmDialog() {
      this.alarmDialog = true;
    },
    openTurnOff() {
      this.$refs.turnOff.getFlag();
      this.turnOff = true;
    },

    openConfiguration() {
      this.configuration = true;
    },

    closeConfiguration: function () {
      this.configuration = false;
    },

    openOdometer() {
      this.changeOdometer = true;
    },

    closeOdometer: function () {
      this.changeOdometer = false;
    },

    openFrigoHours() {
      this.changeFrigoHours = true;
    },

    openModalSetPoint() {
      this.changeSetpoint = true;
    },

    closeFrigoHours: function () {
      this.changeFrigoHours = false;
    },
    closeSetPoint: function () {
      this.changeSetpoint = false;
    },

    openRoutes() {
      this.activateRoutes = true;
    },

    closeRoutes: function () {
      this.activateRoutes = false;
    },

    closeDialog: function () {
      this.ticketDialog = false;
      this.alarmDialog = false;
    },

    closeTurnOff: function () {
      this.turnOff = false;
    },

    getTimestampTooltip(timestampDate, actualDate, type) {
      const oneDay = 24 * 60 * 60 * 1000;
      const date = new Date(timestampDate);
      const formatedDate = this.formatDate(timestampDate);
      const diffDays = Math.round(Math.abs((actualDate - date) / oneDay));
      if (diffDays < 1) {
        const diffHours = Math.round(Math.abs((actualDate - date) / 1000 / 3600));
        if (diffHours > 1) {
          return this.$t('dashboard.details.last_measure_hours', {
            hours: diffHours,
          });
        }
        const diffMinutes = Math.round(Math.abs((actualDate - date) / 1000 / 60));
        if (diffMinutes > 1) {
          this.status[type] = true;
          return this.$t('dashboard.details.last_measure_minutes', {
            minutes: diffMinutes,
          });
        }
        this.status[type] = true;
        return this.$t('dashboard.details.last_measure_recently');
      }

      if (diffDays < 7) {
        return this.$t('dashboard.details.last_measure_in_days', {
          days: diffDays,
        });
      }

      return this.$t('dashboard.details.last_measure_date', {
        date: formatedDate.date,
      });
    },
    formatDate(deviceTimestamp) {
      let date = new Date(deviceTimestamp);
      let minute = date.getMinutes();
      if (minute.toString().length < 2) minute = '0' + minute;
      let hour = date.getHours();
      if (hour.toString().length < 2) hour = '0' + hour;
      let day = date.getDate();
      if (day.toString().length < 2) day = '0' + day;
      let month = date.getMonth() + 1;
      if (month.toString().length < 2) month = '0' + month;
      let year = date.getFullYear();

      let formatedDate = {
        date: `${day}/${month}/${year}`,
        hours: `${hour}:${minute}`,
      };
      return formatedDate;
    },
    frigoEngineStatus() {
      return this.device.last_frames.frame_status.engine_on;
    },
    _resetAlarms() {
      this.isContactOn = false;
      this.isEngineOn = false;
      this.isCustomOn = false;
      this.isPinaOn = false;
    },
    checkAlarms() {
      this._resetAlarms();
      if (
        new Date().getTime() - new Date(this.device.last_frames.frame_status.device_timestamp).getTime() <
        1000 * 60 * 60 * 24
      ) {
        if (this.device.last_frames.frame_status.contact) {
          this.isContactOn = true;
        }
        if (this.device.last_frames.frame_CAN) {
          if (this.device.last_frames.frame_CAN.payloadCAN) {
            if (this.device.last_frames.frame_CAN.payloadCAN.canStatus?.tebs) {
              this.isPinaOn = true;
            }
          }
        }
        if (this.device.last_frames.frame_status.engine_on) {
          this.isEngineOn = true;
          eventBus.$emit('EngineOn');
        }
        if (this.device.last_frames.frame_status.custom) {
          this.isCustomOn = true;
        }
      }
    },

    updateComponent() {
      if (this.$store.state.selectedDevice.device_data_from_frame?.battery_linked?.battery) {
        this.calculateVoltage(this.$store.state.selectedDevice.device_data_from_frame?.battery_linked?.battery);
      } else {
        this.calculateVoltage(this.$store.state.selectedDevice.last_frames?.frame_status.battery_voltage);
      }

      this.actualDate = new Date();
      (this.todaysMileage = null),
        (this.todaysEngineHours = 0),
        (this.status = {
          gps: false,
          status: false,
          frigo: false,
          canbus: false,
          coldMachine: false,
        });
      this.isContactOn = false;
      this.isEngineOn = false;
      this.isCustomOn = false;
      this.isPinaOn = false;

      this.isDoorOpen = false;
      (this.isTemp1Low = false),
        (this.isTemp1High = false),
        (this.isTemp2Low = false),
        (this.isTemp2High = false),
        (this.isInsidePoi = false),
        (this.batteryLevel = 100);
    },

    async checkBattery() {
      await getUpdatedFrames(this.$store.state.selectedDevice?.device_sn, (type, message) => {
        if (message.battery_linked?.battery) this.calculateVoltage(message.battery_linked?.battery);

        if (this.$store.state.selectedDevice.device_sn == message.device_sn && message.battery_voltage) {
          this.calculateVoltage(message.battery_voltage);
        }
      });
    },
    calculateVoltage: function (volt) {
      this.voltage = Number(volt);

      const battery = (a, b, c) => {
        return (b * c) / a;
      };
      // Desde esta linea 07/08/2204 hasta la linea que pone hasta aquí 😅
      //24V
      if (this.voltage >= 24) {
        this.batteryPercentage = 100;
        // } else if (this.voltage >= 25) {
        //   this.batteryPercentage = 80;
        // } else if (this.voltage >= 24.5) {
        //   this.batteryPercentage = 50;
        // } else if (this.voltage >= 24) {
        //   this.batteryPercentage = 25;
      } else if (this.voltage < 24 && this.voltage >= 16) {
        let a = 24;
        let b = 100;
        this.batteryPercentage = Math.floor(battery(a, b, this.voltage));
        // this.batteryPercentage = 0;
      }
      // 12V
      else if (this.voltage >= 12 && this.voltage < 16) {
        this.batteryPercentage = 100;
        // } else if (this.voltage >= 12.2) {
        //   this.batteryPercentage = 80;
        // } else if (this.voltage >= 12) {
        //   this.batteryPercentage = 50;
        // } else if (this.voltage >= 11.9) {
        //   this.batteryPercentage = 25;
      } else {
        let a = 12;
        let b = 100;
        this.batteryPercentage = Math.floor(battery(a, b, this.voltage));
        // this.batteryPercentage = 0;
      }
      // ECUACION ANTERIOR A 18/07/2024
      // const vmin = 11.5;
      // const vmax = 14;
      //   this.batteryPercentage = Math.round(((this.voltage - vmin) / (vmax - vmin)) * 100);
      //   if (this.batteryPercentage > 100) {
      //     this.batteryPercentage = 100;
      //   } else if (this.batteryPercentage < 0) {
      //     this.batteryPercentage = 0;
      //   } else {
      //     this.batteryPercentage = this.batteryPercentage;
      //   }
      //Hasta aquí 07/08/2204
      const gradientColors = [
        { color: 'background: linear-gradient(to left, #D96307, #FA0700)', percentage: 10 },
        { color: 'background: linear-gradient(to left, #F0B905, #D96307)', percentage: 20 },
        { color: 'background: linear-gradient(to left, #B6D907, #F0B905)', percentage: 30 },
        { color: 'background: linear-gradient(to left, #8BFC08, #B6D907)', percentage: 40 },
        { color: 'background: linear-gradient(to left, #08FC24, #8BFC08)', percentage: 50 },
        { color: '#00FC08', percentage: 60 },
      ];

      this.batteryColor =
        gradientColors.find((tone) => this.batteryPercentage <= tone.percentage)?.color ||
        gradientColors[gradientColors.length - 1].color;
    },

    async getTracker(tracker) {
      return await getLinkedTracker(tracker);
    },
    distanceCoordinates(coords1, coords2) {
      var ML = (((coords1.latitude + coords2.latitude) / 2) * Math.PI) / 180;
      var KPDlat = 111.13209 - 0.56605 * Math.cos(2 * ML) + 0.0012 * Math.cos(4 * ML);
      var KPDlon = 111.41513 * Math.cos(ML) - 0.09455 * Math.cos(3 * ML) + 0.00012 * Math.cos(5 * ML);

      var NS = KPDlat * (coords1.latitude - coords2.latitude);
      var EW = KPDlon * (coords1.longitude - coords2.longitude);

      return 1000 * Math.sqrt(NS * NS + EW * EW);
    },
    async checkMyNotifications() {
      if (this.myPOIS?.length > 0 && this.device.last_frames.frame_gps) {
        for (let poi of this.myPOIS) {
          const distance = this.distanceCoordinates(poi, this.device.last_frames.frame_gps);
          if (distance <= poi.radius) {
            var insidePoi = true;
          }
        }
      }

      let alarmConfig;
      const alarms = await getAlarms();
      for (let alarm of alarms) {
        if (alarm.device_sn == this.device.device_sn && alarm.type == 'temp') {
          alarmConfig = alarm.probes_config;
        }
      }
      let probes_temp = this.device.last_frames.frame_frigo ? this.device.last_frames.frame_frigo.probes_temperature : null;
      this.notifications = checkNotifications(alarmConfig, probes_temp, this.device.last_frames.frame_status, insidePoi);

      if (this.notifications.doorOpen) {
        this.isDoorOpen = true;
      }
      if (this.notifications.p1Min) {
        this.isTemp1Low = true;
      }
      if (this.notifications.p1Max) {
        this.isTemp1High = true;
      }
      if (this.notifications.p2Min) {
        this.isTemp2Low = true;
      }
      if (this.notifications.p2Max) {
        this.isTemp2High = true;
      }
      if (this.notifications.insidePoi) {
        this.isInsidePoi = true;
      }
    },
    hasNotifications() {
      this.checkMyNotifications();
      this.openNotifications = this.notifications.hasNotifications;
    },
  },

  computed: {
    calculateTodayMotorHours: async function () {
      if (this.device) {
        if (this.device.last_frames.frame_frigo) {
          this.todaysEngineHours = await calculateTodaysEngineHours(
            this.device.last_frames.frame_frigo.engine_time.raw,
            this.device.device_sn,
          );
        }
      }
    },

    getGeocode: async function () {
      this.geocode = VueI18n.default.t('general.table.loading');
      if (this.device) {
        if (this.device.last_frames.frame_gps.geocode) {
          this.geocode = this.device.last_frames.frame_gps.geocode;
        } else if (this.device.last_frames.frame_gps.latitude && this.device.last_frames.frame_gps.longitude) {
          this.geocode = await getReverseGeocode(
            this.device.last_frames.frame_gps.latitude,
            this.device.last_frames.frame_gps.longitude,
            this.device.device_sn,
          );
        } else {
          this.geocode = 'No geocode';
        }
      }
    },

    calculateMyMileage: async function () {
      if (this.device?.last_frames.frame_gps) {
        const serialNumber = this.device.device_sn;
        this.todaysMileage = await calculateTodayMileage(serialNumber);
      }
    },

    getGpsTimestamp() {
      this.gpsTimestamp = this.getTimestampTooltip(this.device.last_frames.frame_gps.gps_timestamp, new Date(), 'gps');
    },

    getStateTimestamp() {
      this.stateTimestamp = this.getTimestampTooltip(this.device.last_frames.frame_status.device_timestamp, new Date(), 'status');
      return this.stateTimestamp;
    },

    currentRouteName() {
      return this.$route.name;
    },

    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    getPlate() {
      if (this.user && this.device) {
        for (let userDevice of this.user.devices) {
          if (userDevice.device_id == this.device.device_id) {
            this.carplate = userDevice.name || this.device.device_data_from_frame.carplate || this.device.device_sn;
            eventBus.$emit('carplate', this.carplate);
          }
        }
      } else {
        this.carplate = '';
      }
    },

    getOdometer() {
      if (this.device && this.device.last_frames && this.device.last_frames.frame_gps) {
        if (!this.device.last_frames.frame_gps.odometer.offset_applied) {
          this.odometer = 'No Data';
        }
        if (this.units == 'imperial') {
          const convertedOdometer = ((this.device.last_frames.frame_gps.odometer.offset_applied * 1000) / 1609.34).toFixed(2);
          this.odometer = convertedOdometer + ' ml';
        } else {
          if (this.device.last_frames.frame_gps.odometer.offset_applied) {
            this.km = ' km';
          }
          this.odometer = this.device.last_frames.frame_gps.odometer.offset_applied
            ? this.device.last_frames.frame_gps.odometer.offset_applied.toFixed() + this.km
            : 'No Data' + this.km;
        }
      } else {
        this.odometer = 'No data';
      }
    },

    async getTodayMileage() {
      this.movements = await getFramesDevice(this.device.device_sn);
      eventBus.$emit('movementLine', this.movements);

      var mileageSinceMidnight = 0;
      this.movements?.forEach((e) => {
        if (e.type == MOVEMENT) mileageSinceMidnight += e.distance;
      });

      if (this.units == 'imperial') {
        this.todayMileage = ((mileageSinceMidnight * 1000) / 1609.34).toFixed(0) + ' ml';
      }

      this.todayMileage = Math.abs(mileageSinceMidnight.toFixed(0)) + ' km';
    },

    checkStatus() {
      if (this.status.status) {
        return 'green--text';
      }
      return 'gray--text';
    },

    getFrigoTimestamp() {
      this.frigoTimestamp = this.getTimestampTooltip(this.device.last_frames.frame_frigo.device_timestamp, new Date(), 'frigo');
    },

    getCANBUSTimestamp() {
      if (this.device.last_frames.frame_CAN) {
        this.canbusTimestamp = this.getTimestampTooltip(this.device.last_frames.frame_CAN.device_timestamp, new Date(), 'canbus');
      }
    },

    getColdMachineTimestamp() {
      if (this.device.last_frames.frame_frigo_machine) {
        this.coldMachineTimestamp = this.getTimestampTooltip(
          this.device.last_frames.frame_frigo_machine.device_timestamp,
          new Date(),
          'coldMachine',
        );
      }
    },
  },

  watch: {
    device: function () {
      this.linkInfo = false;
    },
  },
  async updated() {
    if (this.device) {
      if (this.device.last_frames) {
        this.getTodayMileage;
        this.getPlate;
        this.getCANBUSTimestamp;
        if (this.device.last_frames.frame_gps) {
          this.getGpsTimestamp;
          this.getOdometer;
          this.getGeocode;
        }
        if (this.device.last_frames.frame_status) {
          this.getStateTimestamp;
          this.checkBattery();
        }
        this.hasNotifications();
        if (this.device.last_frames.frame_frigo) {
          this.checkFrigo;
          this.getFrigoTimestamp;
        }

        if (this.device.last_frames.frame_frigo_machine) {
          this.getColdMachineTimestamp;
        }
      }
    }
  },

  beforeUpdate() {
    this.calculateTodayMotorHours;
    this.calculateMyMileage;
    if (this.device?.last_frames?.frame_status) {
      this.checkAlarms();
    }
  },
};
</script>
