<template>
  <v-row
    :style="
      isMobile
        ? 'position: absolute; bottom: 95px; right: 0; padding-bottom: 2rem'
        : 'position: absolute; bottom: 0; right: 0; padding-bottom: 2rem '
    ">
    <audio v-if="isIOS" ref="notificationSound" src="../../../assets/mixkitpop.mp3" muted style="display: none"></audio>
    <video v-else ref="notificationSound" src="../../../assets/mixkitpop.mp3" autoplay muted style="display: none"></video>

    <v-col>
      <v-speed-dial
        v-model="fab"
        :top="top"
        :bottom="bottom"
        :right="right"
        :left="left"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
        style="margin-right: 0.5rem">
        <template v-slot:activator>
          <v-btn v-model="fab" color="blue darken-2" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else-if="newNotifications" color="red">mdi-email-send-outline</v-icon>
            <v-icon v-else color="rgba(255,255,255,1)">mdi-email-open-multiple-outline</v-icon>
          </v-btn>
        </template>
        <v-col>
          <v-col>
            <v-btn style="align-items: normal" fab dark small color="yellow">
              <v-icon @click="approvePermissions">mdi-check-decagram</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn style="align-items: normal" fab dark small color="red">
              <v-icon @click="deleteNotifications">mdi-notification-clear-all</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn fab dark small color="orange">
              <v-icon v-if="vElement" @click="ONSound">mdi-volume-off</v-icon>
              <v-icon v-else @click="ONSound">mdi-volume-high</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <v-badge :content="actualizedMessages" :value="messages" left overlap bordered color="green">
              <v-btn fab dark small color="green">
                <v-icon @click="notificationsView">mdi-email-alert-outline</v-icon>
              </v-btn>
            </v-badge>
          </v-col>
        </v-col>
      </v-speed-dial>
    </v-col>
    <Confirm ref="confirm"></Confirm>
  </v-row>
</template>
<script>
const { formatDate } = require('../../utils/utils');
import { getUserDevices } from '../../server/petitions/users/getUserDevices';
import { getUpdatedNotificationsBySn } from '../../server/petitionsMqtt/notifications/updateNotifications';
import { getUser } from '../../viewModels/userViewModel';
import { eventBus } from '../../main';
import Confirm from '../../components/confirm.vue';
import { footerController } from './handlerFooter';

export default {
  components: { Confirm },

  data: () => ({
    isIOS: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
    oneIsDeleted: false,
    newNotifications: false,
    messages: 0,
    direction: 'top',
    fab: false,
    hover: false,
    top: true,
    right: true,
    bottom: false,
    left: false,
    transition: 'slide-y-reverse-transition',
    vElement: true,
    notifications: [],
    isDeleted: false,
  }),
  methods: {
    notificationsView() {
      this.$router.push({
        name: 'notifications',
      });
    },
    ONSound() {
      if (this.isIOS) {
        this.$refs.notificationSound.muted = !this.$refs.notificationSound.muted;
        this.vElement = !this.vElement;
      } else {
        let videoElement = document.querySelector('video');
        videoElement.muted = !videoElement.muted;
        this.vElement = videoElement.muted;
      }
    },
    async showFooter() {
      const isSuscribed = false;
      this.deviceCollection = (await getUserDevices(this.user.token, this.user.userId)).map((i) => {
        getUpdatedNotificationsBySn(i.device_sn, isSuscribed, (notification) => {
          this.$refs.notificationSound.play();
          this.newNotifications = true;

          footerController(notification, i, this.notifications, this.$notify);
        });
      });
    },
    async deleteNotifications() {
      const isValidateToDelete = await this.$refs.confirm.open(
        this.$t('general.form.delete-All-Notifications'),
        this.$t('general.form.deleteAllNotifications'),
        {
          color: 'red',
        },
      );

      if (isValidateToDelete) {
        eventBus.$emit('removeAllNotifications');

        this.isDeleted = true;
      }
    },
    async approvePermissions() {
      const isValidateToApprove = await this.$refs.confirm.open(
        this.$t('general.form.approvePermissions'),
        this.$t('general.form.aprove-permissions'),
        {
          color: 'green',
        },
      );
      if (isValidateToApprove) {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
          }
        });
      }
    },
  },
  mounted: async function () {
    this.view = this.$refs.dashboardView;
    this.user = await getUser();
    this.showFooter();
  },
  created: async function () {
    eventBus.$on('removeOneNotification', async () => {
      this.oneIsDeleted = true;
      return;
    });
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    actualizedMessages: {
      get: function () {
        if (this.notifications.length > 99) {
          return (this.messages = '+99');
        } else if (this.isDeleted) {
          this.messages = 0;
          this.notifications.length = 0;
          this.newNotifications = false;
          this.isDeleted = false;
        } else if (this.oneIsDeleted) {
          this.oneIsDeleted = false;
          this.messages = this.messages - 1;
          if (this.messages == 0) {
            this.newNotifications = false;
          }
          return this.messages;
        } else {
          const filteredNotifications = [];
          const uniqueNotifications = new Set();

          for (const notification of this.notifications) {
            const key = `${notification.type}-${formatDate(notification.date)}-${notification.device_sn}`;

            if (!uniqueNotifications.has(key)) {
              filteredNotifications.push(notification);
              uniqueNotifications.add(key);
            }
          }

          this.messages = filteredNotifications.length;
          return this.messages;
        }
      },
      set: function (newValue) {
        this.messages = newValue;
      },
    },
  },
};
</script>
