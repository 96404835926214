const PetitionError = require("../../petitionError");
const { postRaw } = require("../../httpRequester");

class ChangeLanguageException extends PetitionError {
    constructor(code) {
        super("Unable to change password", code);
    }
}

async function changeLanguagePetition(language, email, token) {
    const payload = { email, language };
    const response = await postRaw("users/change_language", payload, token); // payload, token
    return response;
}

// class ChangeLanguageException extends PetitionError {
//   constructor(code) {
//     super('Unable to change password', code);
//   }
// }

module.exports = {
    changeLanguagePetition,
    ChangeLanguageException,
};
