<template>
  <v-dialog v-model="isActive" max-width="500px" @click:outside="cancelDialog">
    <v-card>
      <v-card-title color="primary" class="headline">{{ tittleText }}</v-card-title>
      <v-card-text>{{ messageText }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="cancelDialog">{{ cancelButtonText }}</v-btn>
        <v-btn color="error" text @click="acceptDialog">{{ acceptButtonText }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    tittleText: {
      type: String,
    },
    messageText: {
      type: String,
    },
    cancelButtonText: {
      type: String,
    },
    acceptButtonText: {
      type: String,
    },
  },
  data: () => ({}),
  methods: {
    cancelDialog: function () {
      this.$emit('cancel-dialog');
    },
    acceptDialog: function () {
      this.$emit('accept-dialog');
    },
  },
};
</script>
