<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="fill-height d-flex flex-column mt-4 align-center">
        <v-card>
          <v-container
            :style="{ color: theme === 'dark' ? 'white' : '', 'background-color': theme === 'dark' ? '#0d1a26a8' : '' }">
            <h4>{{ $t('dashboard.poi_header_right_click') }}</h4>
            <h4>{{ $t('dashboard.poi_header_zoom') }}</h4>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :sort-by="['name']"
          :sort-desc="false"
          :loading="isTableLoading"
          :footerProps="{ 'items-per-page-options': [5] }"
          :headers="poisTableHeader"
          :items="pois"
          :items-per-page="5"
          class="elevation-1"
          :search="search"
          v-bind:loading-text="$t('general.table.loading')">
          <template v-slot:item.category.icon="{ item }">
            <v-img
              :src="item.category.icon != '' ? item.category.icon : emptyCategory.icon"
              max-width="32"
              :title="item.category.name"></v-img>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-row>
              <v-icon small class="mr-1" color="primary" @click="editItem(item)"> mdi-square-edit-outline </v-icon>
              <v-icon small class="mr-1" color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
              <v-icon small class="mr-1" color="green" @click="goToMap(item)"> mdi-earth </v-icon>
            </v-row>
          </template>
          <template v-slot:top>
            <v-toolbar
              :style="{ color: theme === 'dark' ? 'white' : '', 'background-color': theme === 'dark' ? '#ffffff1a' : '' }"
              flat>
              <v-text-field type="text" :label="$t('dashboard.pois_categories.search_for_pois')" v-model="search"></v-text-field>
              <v-spacer></v-spacer>
              <POICategoriesDialog />

              <POIDialog ref="poiDialog" v-model="editedPOI" @newPOI="clearPOI" @savePOI="savePOI" :title="poiFormTitle" />

              <Confirm ref="confirm"></Confirm>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="errors.length > 0">
      <v-list-item v-for="(error, key) in errors" :key="key">
        <v-list-item-content class="red">
          <v-list-item-title class="ml-2 text-center">
            <span>{{ error }}</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-row>
    <v-row>
      <v-col style="text-align: center">
        <POIsImportDialog :btnText="$t('dashboard.poisImport')" @pois-imported="onPOIsImported" />
        <vue-json-to-csv
          :json-data="poisForExport"
          csv-title="pois"
          :labels="{
            name: { title: this.$t('shared.name') },
            address: { title: this.$t('shared.address') },
            latitude: { title: this.$t('device.last_frames.frame_gps.latitude') },
            longitude: { title: this.$t('device.last_frames.frame_gps.longitude') },
            radius: { title: this.$t('dashboard.poi_radius') },
            category: { title: this.$t('dashboard.pois_categories.category') },
          }">
          <v-btn color="primary" dark class="mb-2 ml-2">
            <v-icon class="mr-1">mdi-download</v-icon> {{ $t('dashboard.download') }}
          </v-btn>
        </vue-json-to-csv>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { postPOI, importPOIs } from '../../../server/petitions/pois/postPOI';
import { getAllPOIS } from '../../../server/petitions/pois/getAllPOIS';
import { deletePOI } from '../../../server/petitions/pois/deletePOI';
import POIDialog from '../../../components/dashboard/pois/poiDialog.vue';
import POIsImportDialog from '../../../components/dashboard/pois/poisImportDialog.vue';
import POICategoriesDialog from '../../../components/dashboard/pois/poiCategoriesDialog.vue';
import Confirm from '../../../components/confirm.vue';
import { getToken, getUser } from '../../../viewModels/userViewModel';
import { omit } from '../../../utils/utils';
import VueJsonToCsv from 'vue-json-to-csv';
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';
import router from '../../../plugins/router/index';

export default {
  components: {
    POIDialog,
    POICategoriesDialog,
    Confirm,
    POIsImportDialog,
    VueJsonToCsv,
  },
  data: () => ({
    theme: ls.get('theme') ?? 'ligth',
    isTableLoading: true,
    pois: [],
    search: '',
    editedIndex: -1,
    defaultPOI: {
      name: '',
      address: '',
      latitude: 0,
      longitude: 0,
      radius: 50,
    },
    editedPOI: {
      name: '',
      address: '',
      latitude: 0,
      longitude: 0,
      radius: 50,
    },
    token: '',
    currentUser: {},
    emptyCategory: {
      icon: '/assets/markers/pi.png',
    },
    importerDialog: false,
    errors: [],
  }),
  methods: {
    addPoiToMap() {},
    removeDeviceFromMap() {},
    focusOnDevice() {},
    followDevice() {},
    addDeviceToMap(device, isLast) {},
    updateDeviceToMap() {},
    resetMap() {},
    close() {
      this.$nextTick(() => {
        this.clearPOI();
      });
    },
    clearPOI() {
      this.editedPOI = Object.assign({}, this.defaultPOI);
      this.editedIndex = -1;
      this.errors = [];
    },
    async savePOI() {
      this.isTableLoading = true;
      this.errors = [];

      const poi = { ...omit(this.editedPOI, 'category'), userId: this.currentUser.userId };
      const resultCreatedPOI = await postPOI(this.token, poi);

      if (resultCreatedPOI == 11000) {
        this.errors.push(this.$t('dashboard.unable_create'));
        this.isTableLoading = false;
        return;
      }

      this.editedPOI = resultCreatedPOI.poi;

      if (!this.editedPOI.category) {
        this.editedPOI.category = this.emptyCategory;
      }

      if (this.editedIndex > -1) {
        Object.assign(this.pois[this.editedIndex], this.editedPOI);
      } else {
        this.pois.push(this.editedPOI);
      }

      this.loadAllPOIS();

      this.close();
      this.isTableLoading = false;
    },

    editItem: async function (item) {
      this.editedIndex = this.pois.indexOf(item);
      this.editedPOI = Object.assign({}, item);

      this.$refs.poiDialog.open();
    },
    deleteItem: async function (item) {
      if (
        (await this.$refs.confirm.open(
          this.$t('dashboard.delete_poi'),
          `${this.$t('dashboard.delete_poi_confirm')} ${item.name}?`,
          {
            color: 'red',
          },
        )) &&
        !this.isTableLoading
      ) {
        this.isTableLoading = true;

        await deletePOI(this.token, item._id);

        const indexToDelete = this.pois.indexOf(item);
        this.pois.splice(indexToDelete, 1);
        this.isTableLoading = false;
      }
    },
    goToMap: async function (item) {
      if (
        (await this.$refs.confirm.open(this.$t('dashboard.go_to_map'), `${this.$t('dashboard.go_to_map_confirm')} `, {
          color: 'green',
        })) &&
        !this.isTableLoading
      ) {
        router.push({
          name: 'map',
          params: { lat: item.latitude, lng: item.longitude },
        });
      }
    },
    onPOIsImported: async function (pois) {
      this.isTableLoading = true;
      pois.forEach((poi) => console.log(poi));

      const importResult = await importPOIs(
        this.token,
        pois.map((poi) => ({ ...poi, userId: this.currentUser.userId })),
      );

      this.loadAllPOIS();

      this.isTableLoading = false;
    },

    loadAllPOIS: async function () {
      const poisFromDB = await getAllPOIS(this.token, this.currentUser.userId);
      this.pois = poisFromDB.map((poi) => {
        if (!poi.category) {
          poi.category = this.emptyCategory;
        }

        return poi;
      });
    },
  },
  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
    this.currentUser = this.$store.state.currentUser ?? (await getUser());
    this.token = getToken();

    await this.loadAllPOIS();

    this.isTableLoading = false;

    this.$emit('onInit');
  },
  computed: {
    poisForExport() {
      return this.pois.map((poi) => {
        return {
          ...poi,
          category: poi.category?.name,
        };
      });
    },
    poiFormTitle() {
      return this.editedIndex === -1 ? this.$t('dashboard.new_poi') : this.$t('dashboard.edit_poi');
    },
    poisTableHeader() {
      return [
        {
          text: '',
          value: 'category.icon',
          width: '2%',
          divider: false,
        },
        {
          text: this.$t('shared.name'),
          value: 'name',
          width: '23%',
          divider: true,
        },
        {
          text: this.$t('shared.address'),
          value: 'address',
          width: '30%',
          divider: true,
        },
        {
          text: this.$t('device.last_frames.frame_gps.latitude'),
          value: 'latitude',
          width: '15%',
          divider: true,
        },
        {
          text: this.$t('device.last_frames.frame_gps.longitude'),
          value: 'longitude',
          width: '15%',
          divider: true,
        },
        {
          text: this.$t('dashboard.poi_radius'),
          value: 'radius',
          width: '7%',
          divider: true,
        },
        {
          text: '',
          value: 'actions',
          width: '10%',
          divider: true,
          sortable: false,
        },
      ];
    },
  },
};
</script>
