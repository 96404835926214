const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class ShareMyDeviceException extends PetitionError {
  static serialNumberAlreadyInDevice = 'SERIALNUMBER_ALREADY_IN_DEVICE';

  constructor(code) {
    super('Create Device', code);
  }
}

async function shareMyDevice(payload, token) {
  const response = await post('devices/share_device', payload, token);

  if (response.result !== ShareMyDeviceException.success) {
    throw new ShareMyDeviceException(response.result);
  } else {
    return response; // TODO transform
  }
}

module.exports = { shareMyDevice,  ShareMyDeviceException };
