const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class CreateUserException extends PetitionError {
  static emailAlreadyInUser = 'EMAIL_ALREADY_IN_USER';

  constructor(code) {
    super('Create User', code);
  }
}

async function createUser(
  name,
  company_id,
  email,
  notification_emails,
  phone,
  password,
  notes,
  devices_id,
  roles,
  platform,
  unit,
  restrictions,
  restricted,
  token,
  max_clients,
) {
  const payload = {
    name,
    company_id,
    email,
    notification_emails,
    phone,
    password,
    notes,
    devices_id,
    accept_privacy_policy: false,
    clients_ids: [],
    type: roles,
    platform,
    unit,
    restrictions,
    restricted,
    max_clients,
  };

  const response = await post('users/', payload, token);

  if (response.result !== CreateUserException.success) {
    throw new CreateUserException(response.result);
  } else {
    return response.user; // TODO transform
  }
}

module.exports = { createUser, CreateUserException };
