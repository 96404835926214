const PetitionError = require('../../petitionError');
const { postAnonymous } = require('../../httpRequester');

class LoginByEmailException extends PetitionError {
  static errorIncorrectPassword = 'INCORRECT_PASSWORD';

  static errorIncorrectEmail = 'UNKNOWN_EMAIL';

  constructor(code) {
    super('Login By Email', code);
  }
}

async function loginByEmail(email, password, name, platform) {
  // TODO add security
  const payload = {
    email,
    password,
    name,
    platform,
  };
  const response = await postAnonymous('authentication/login_by_email', payload);

  if (response.result !== PetitionError.success) {
    throw new LoginByEmailException(response.result);
  } else {
    if (response.user.platform != process.env.VUE_APP_PLATFORM) {
      throw new LoginByEmailException('UNKNOWN_EMAIL');
    }
    return response.user;
  }
}

module.exports = {
  loginByEmail,
  LoginByEmailException,
};
