const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class DeleteSharedDevicePetitionException extends PetitionError {
  static errorDeleteSharedDevice = 'ERROR';

  constructor(code) {
    super('There is an error', code);
  }
}

async function deleteSharedDevice(token, user, email, sn) {
  const payload = { user, email, sn };

  const response = await post('users/delete_shared_device', payload, token);

  if (response.result !== DeleteSharedDevicePetitionException.success) {
    return (new DeleteSharedDevicePetitionException(response));
  }
  return response;
}

// class DeleteSharedDevicePetitionException extends PetitionError {
//   static errorDeleteSharedDevice = 'ERROR';

//   constructor(code) {
//     super('There is an error', code);
//   }
// }

module.exports = {
  deleteSharedDevice,
  DeleteSharedDevicePetitionException,
};
