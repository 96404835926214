const PetitionError = require('../../petitionError');
const { put } = require('../../httpRequester');

class ModifyBeaconAliasByUserException extends PetitionError {
  static errorToChangeAlias = 'ERROR_TO_CHANGE_ALIAS';

  constructor(code) {
    super('Modify alias beacon by user', code);
  }
}

async function modifyBeaconAliasByUser(token, payload) {
  const response = await put('users/alias_beacon', payload, token);

  if (response.result !== ModifyBeaconAliasByUserException.success) {
    throw new ModifyBeaconAliasByUserException(response.result);
  } else {
    return response.beacon;
  }
}

module.exports = { modifyBeaconAliasByUser, ModifyBeaconAliasByUserException };
