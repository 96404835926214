const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class AddClientPetitionException extends PetitionError {
  static errorAddClient = 'ERROR';

  constructor(code) {
    super('There is an error', code);
  }
}

async function addNewClient(token, user, name, email) {
  const payload = {
    emailUser: user.email,
    name,
    email,
  };

  const response = await post('clients/add_client', payload, token);

  if (response.result !== AddClientPetitionException.success) {
    return (response);
  }
  return response;
}

// class AddClientPetitionException extends PetitionError {
//   static errorAddClient = 'ERROR';

//   constructor(code) {
//     super('There is an error', code);
//   }
// }

module.exports = {
  addNewClient,
  AddClientPetitionException,
};
