const PetitionError = require('../../petitionError');
const { del } = require('../../httpRequester');

class RemoveBeaconFromUserException extends PetitionError {
  constructor(code) {
    super('Remove beacon from user', code);
  }
}

async function removeBeaconFromUser(payload, token) {
  const response = await del(`users/delete_beacon`, token, payload);
  if (response.result !== RemoveBeaconFromUserException.success) {
    throw new RemoveBeaconFromUserException(response.result);
  } else {
    return response.user;
  }
}

module.exports = { removeBeaconFromUser, RemoveBeaconFromUserException };
