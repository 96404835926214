//TODO ARCHIVO NUEVO

const { postAnonymous } = require('../../httpRequester');
const PetitionError = require('../../petitionError');

class UpdateUserNotificationsException extends PetitionError {
  constructor(code) {
    super('Update Notification', code);
  }
}

//NUEVO
async function updateUserNotifications(token, id, state) {
  const payload = {
    id,
    state,
  };
  const response = await postAnonymous(`notifications/update?token=${token}`, payload);
  if (response.result !== UpdateUserNotificationsException.success) {
    return new UpdateUserNotificationsException(response.result);
  }
  return response;
}

module.exports = { updateUserNotifications, UpdateUserNotificationsException };
