<template>
  <v-container id="user-edit-password">
    <v-row>
      <span @click="goBack" class="d-flex justify-start my-2 ml-9" style="cursor: pointer">
        <v-icon>mdi-chevron-left</v-icon>
      </span>
      <span style="font-size: 26px;">{{ userData.name }}</span>
    </v-row>

    <v-form ref="passwordForm" class="contain-user-edit-password" v-model="formValid" lazy-validation>
      <h2 class="d-flex justify-start my-6">
        {{ $t('user.actions.change_password') }}
      </h2>

      <v-row class="d-flex align-center">
        <label class="font-weight-black float-left">{{ $t('user.password.new_password') }}*</label>
      </v-row>

      <v-row cols="5">
        <v-text-field
          dense
          outlined
          class="rounded-lg"
          type="password"
          v-model="password"
          v-bind:hint="$t('user.password.hint')"
          :rules="passwordRules"
          :error-messages="passwordIncorrectMessage"
          required></v-text-field>
      </v-row>

      <v-row class="d-flex align-center">
        <label class="font-weight-black float-left">
          {{ $t('user.password.repeat_password') }}*
        </label>
      </v-row>

      <v-row cols="5">
        <v-text-field
          dense
          outlined
          class="rounded-lg"
          type="password"
          v-model="confirmPassword"
          v-bind:rules="[password === confirmPassword || $t('user.password.error.passwords_not_match')]"
          required></v-text-field>
      </v-row>
    </v-form>

    <v-row class="d-flex justify-center mt-10 btn-edit-user">
      <v-btn color="primary" class="btn-edit d-flex justify-space-between mx-15" @click="changePassword">
        <v-icon>mdi-content-save</v-icon>
        {{ $t('general.form.save') }}
      </v-btn>
      <v-btn color="red" class="btn-edit d-flex justify-space-between mx-15" @click="goBack">
        <v-icon class="white--text">mdi-exit-to-app</v-icon>
        <span class="white--text">
          {{ $t('general.form.exit') }}
        </span>
      </v-btn>
    </v-row>

    <ErrorDialog
      :isActive="errorDialog"
      v-bind:tittleText="$t('general.dialog.error.title')"
      :messageText="errorText"
      v-bind:acceptButtonText="$t('general.dialog.error.accept_button')"
      v-on:accept-dialog="errorDialog = false">
    </ErrorDialog>
  </v-container>
</template>

<script>
import router from '../../../../plugins/router/index';
import { changeUserPasswordByAdmin } from '../../../../viewModels/adminViewModel';
import { ChangeUserPasswordByAdminException } from '../../../../server/petitions/users/changeUserPasswordByAdmin.js';
import ErrorDialog from '../../../../components/errorDialog.vue';

export default {
  props: {
    userData: {
      type: Object,
    },
  },
  components: {
    ErrorDialog,
  },
  data: () => ({
    password: '',

    confirmPassword: '',

    passwordIncorrectMessage: '',
    formValid: false,

    errorDialog: false,
    errorText: '',
  }),
  methods: {
    changePassword: async function () {
      let isValid = this.$refs.passwordForm.validate();
      if (isValid) {
        try {
          await changeUserPasswordByAdmin(this.userData._id, this.password);
          this.goBack();
        } catch (e) {
          switch (e.code) {
            case ChangeUserPasswordByAdminException.errorSamePassword:
              this.passwordIncorrectMessage = this.$t('user.password.error.password_must_be_different');
              this.password = '';
              this.confirmPassword = '';
              break;
            case ChangeUserPasswordByAdminException.unknownError:
            case ChangeUserPasswordByAdminException.incorrectParameters:
            case ChangeUserPasswordByAdminException.invalidToken:
            case ChangeUserPasswordByAdminException.expiredToken:
              console.error(e);
              this.errorText = this.$t('general.error.unexpected_error');
              this.errorDialog = true;
              break;
            default:
              console.error('CATCH ERROR:', e);
          }
        }
      }
    },
    goBack: function () {
      router.push({
        name: 'editUser',
        params: { userId: this.userData._id, userData: this.userData },
      });
    },
  },
  computed: {
    passwordRules() {
      return [
        (value) => !!value || this.$t('user.password.error.empty_password'),
        (value) => (value && value.length >= 6) || this.$t('user.password.error.invalid_password'),
      ];
    },
  },
};
</script>
