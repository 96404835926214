<template>
  <div class="mt-5 mx-10">
    <v-row class="mx-2 row-device-container">
      <v-col class="d-flex justify-start pb-0">
        <div class="breadcrumbs-container ml-2">
          <v-row>
            <h1>
              {{ $t('admin.menu.beacons') }}
            </h1>
          </v-row>
          <v-row class="breadcrumbs">
            <v-breadcrumbs :items="items" class="pl-0">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                  {{ item.text.toUpperCase() }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-row>
        </div>
      </v-col>

      <v-col class="btn-new-device">
        <v-btn color="primary" class="float-right" @click="createBeacon">
          <v-icon class="mr-1">mdi-plus</v-icon>
          {{ $t('admin.menu.beacon') }}
        </v-btn>
      </v-col>
    </v-row>

    <template>
      <div v-if="allBeacons.length === 0">
        <v-autocomplete prepend-icon="mdi-loading mdi-spin" :label="$t('device.form.loading')"></v-autocomplete>
      </div>
      <v-data-table
        v-if="allBeacons.length > 0"
        :headers="beaconsHeader"
        data-cy="beaconsTable"
        :items="allBeacons"
        @click:row="editBeacon"
        :search="search"
        item-key="_id"
        :items-per-page="10"
        sort-by="last_seen"
        :sort-desc="true"
        class="sim-table table-cursor elevation-1"
        cursor="pointer"
        style="overflow: auto; overflow-x: hidden; min-height: 100px; max-height: 750px">
        <template v-slot:top>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('admin.menu.search-beacons')"
            single-line
            hide-details
            prepend-inner-icon=" "></v-text-field>
        </template>

        <template v-slot:[`item.mac_address`]="{ item }">
          <span
            v-if="hoursSinceLastSeen(item.last_seen) <= 2"
            class="mdi mdi-circle icon-green"
            :title="$t('admin.menu.in_use')"></span>
          <span v-else class="mdi mdi-circle icon-red" :title="$t('admin.menu.not_used')"></span>
          {{ item.mac_address }}
        </template>

        <template v-slot:[`item.user`]="{ item }">
          <div class="user-email" v-for="user in item.user_name" :key="user._id">
            {{ user }}
            <v-divider></v-divider>
          </div>
        </template>

        <template v-slot:[`item.last_seen`]="{ item }">
          <div style="display: flex; flex-direction: column">
            <span>{{ formatDate(new Date(new Date(item.last_seen).getTime() + 60 * 60 * 1000).toISOString()) }}</span>
            <span v-if="item.seen_by" style="font-weight: bold"> {{ $t('admin.menu.beacons_device') }} {{ item.seen_by }} </span>
          </div>
        </template>

        <template v-slot:[`item.alias`]="{ item }">
          {{ item.alias }}
        </template>
      </v-data-table>
    </template>
    <br />
    <v-snackbar v-model="snackbar" :timeout="timeout" color="success">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"> {{ $t('general.form.close') }} </v-btn>
      </template>
    </v-snackbar>
    <Confirm ref="confirm"></Confirm>
  </div>
</template>

<script>
import Confirm from '../../../components/confirm.vue';
import { getToken } from '../../../viewModels/userViewModel';
import router from '../../../plugins/router/index';
import { getAllBeacons, getBeacon } from '../../../viewModels/adminViewModel.js';
import { formatDate } from '../../../utils/utils';

export default {
  components: {
    Confirm,
  },

  data() {
    return {
      items: [
        {
          text: 'Home',
          disabled: false,
          href: '../dashboard/temperatures',
        },
        {
          text: 'Balizas',
          disabled: true,
          href: 'admin/beacons',
        },
      ],
      allBeacons: [],
      search: '',
      token: '',
      usersWithBeacons: null,
      snackbar: false,
      timeout: 2000,
      snackbarMessage: '',
    };
  },

  mounted: async function () {
    this.allBeacons = await getAllBeacons();
    this.token = getToken();
  },

  methods: {
    createBeacon: function () {
      router.push({ name: 'createBeacon' });
    },
    hoursSinceLastSeen(lastSeen) {
      const lastSeenDate = new Date(lastSeen);
      const now = new Date();
      const difference = now - lastSeenDate;
      return difference / (1000 * 60 * 60);
    },
    editBeacon: async function (e) {
      const beacon = await getBeacon(e.mac_address);
      router.push({
        name: 'editBeacon',
        params: { mac_address: e.mac_address, beaconData: beacon },
      });
    },

    formatDate,
  },

  computed: {
    beaconsHeader() {
      return [
        {
          text: `${this.$t('admin_menu.mac_address')}`,
          value: 'mac_address',
          width: '8rem',
          divider: true,
        },

        {
          text: `${this.$t('admin_menu.user')}`,
          value: 'user',
          width: '6rem',
          divider: true,
        },
        {
          text: `${this.$t('admin_menu.seen_last')}`,
          value: 'last_seen',
          width: '8rem',
          divider: true,
        },

        {
          text: `${this.$t('company.atribute.name')}`,
          value: 'alias',
          width: '8rem',
          divider: true,
        },
      ];
    },
  },
};
</script>

<style>
.user-email {
  font-size: smaller;
}
</style>
