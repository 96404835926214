const getAllDevicesPetition = require('../server/petitions/devices/getAllDevices');
const getDevicePetition = require('../server/petitions/devices/getDevice');
const getBeaconPetition = require('../server/petitions/beacons/getBeacon');
const getCompaniesPetition = require('../server/petitions/companies/getCompanies');
const getUsersPetition = require('../server/petitions/users/getUsers');

const deleteCompanyPetition = require('../server/petitions/companies/deleteCompany');
const deleteUserPetition = require('../server/petitions/users/deleteUser');

const createCompanyPetition = require('../server/petitions/companies/createCompany');
const createUserPetition = require('../server/petitions/users/createUser');
const createDevicePetition = require('../server/petitions/devices/createDevice');
const createBeaconPetition = require('../server/petitions/beacons/createBeacon');

const changePasswordByAdminPetition = require('../server/petitions/users/changeUserPasswordByAdmin');
const modifyCompanyByAdminPetition = require('../server/petitions/companies/modifyCompanyByAdmin');
const modifyUserByAdminPetition = require('../server/petitions/users/modifyUserByAdmin');
const modifyBeaconAliasPetition = require('../server/petitions/users/modifyBeaconAlias');
const modifyBeaconNotesPetition = require('../server/petitions/users/modifyBeaconNotes');

const modifyDeviceByAdminPetition = require('../server/petitions/devices/modifyDeviceByAdmin');
const modifyBeaconByAdminPetition = require('../server/petitions/beacons/modifyBeaconByAdmin');
const assignDeviceToUserPetition = require('../server/petitions/users/assignDeviceToUser');
const assignBeaconToUserPetition = require('../server/petitions/users/assignBeaconToUser');
const removeDeviceFromUserPetition = require('../server/petitions/users/removeDeviceFromUser');
const removeBeaconFromUserPetition = require('../server/petitions/users/removeBeaconFromUser');
const { getDailyDataPetition } = require('../server/petitions/matooma/getDailyDataPetition');
const { getAllCarrierSims } = require('../server/petitions/matooma/getAllSimsPetition');
const { getAllSimsPetition } = require('../server/petitions/simCards/getAllSims');
const { getAllBeaconsPetition } = require('../server/petitions/beacons/getAllBeacons');

const { getSimInfoPetition, getSimInfoMatooma } = require('../server/petitions/matooma/getSimInfoPetition');

const { getToken } = require('./userViewModel');
const { usersDevices } = require('../server/petitions/users/usersDevices');

const AdminViewModel = function () {
  let device;
  let devices;
  let beacon;
  let beacons;
  let users;
  let companies;

  async function getAllDevices() {
    const token = getToken();

    if (devices == null) {
      devices = await getAllDevicesPetition.getAllDevices(token);
      return devices;
    }
    return devices;
  }

  async function reset() {
    device = null;
    devices = null;
    users = null;
    companies = null;
    beacons = null;
  }

  async function getDevice(device_sn) {
    const token = getToken();

    return (device = await getDevicePetition.getDevice(token, device_sn));
  }

  async function getBeacon(mac_address) {
    const token = getToken();

    return (beacon = await getBeaconPetition.getBeacon(token, mac_address));
  }

  async function getAllCompanies() {
    if (companies == null) {
      const token = getToken();
      companies = await getCompaniesPetition.getCompanies(token);
      return companies;
    }
    return companies;
  }

  async function getCompany(company_id) {
    if (companies == null) {
      await getAllCompanies();
    }

    const index = companies.map((item) => item._id).indexOf(company_id);

    return companies[index];
  }

  async function getAllUsers() {
    // if (users == null) {
    const token = getToken();
    users = await getUsersPetition.getUsers(token);
    return users;

    // } else {
    //     return users;
    // }
  }

  async function getUser(user_id) {
    if (users == null) {
      await getAllUsers();
    }
    const index = users.map((item) => item._id).indexOf(user_id);

    return users[index];
  }

  async function getAllUserDevices(user_devices) {
    if (devices == null) {
      await getAllDevices();
    }
    const userDevices = [];

    for (const device of user_devices) {
      const index = devices.map((item) => item._id).indexOf(device.device_id);
      userDevices.push(devices[index]);
    }
    return userDevices;
  }

  const getUsersDevices = async () => {
    const token = getToken();
    return await usersDevices(token);
  };

  async function getDeviceUsers(device_id) {
    if (users == null) {
      await getAllUsers();
    }
    const deviceUsers = [];
    for (const owner of users) {
      if (owner.devices) {
        for (const device of owner.devices) {
          if (device.device_id == device_id) {
            deviceUsers.push(owner);
          }
        }
      }
    }
    return deviceUsers;
  }

  async function getBeaconUsers(beacon_id) {
    if (!users) {
      await getAllUsers();
    }
    return users.filter((owner) => owner.beacons?.some((beacon) => beacon.beacon_id === beacon_id));
  }

  async function createNewCompany(company_name, company_cif, company_phone, company_email, company_notes) {
    if (companies == null) await getAllCompanies();

    const token = getToken();

    const companyNew = await createCompanyPetition.createCompany(
      company_name,
      company_cif,
      company_phone,
      company_email,
      company_notes,
      token,
    );
    companies.push(companyNew);
    return companyNew;
  }

  async function createUser(
    name,
    company_id,
    email,
    notification_emails,
    phone,
    password,

    notes,
    devices_id,
    roles,
    platform,
    unit,
    restrictions,
    restricted,
    max_clients,
  ) {
    if (users == null) {
      await getAllUsers();
    }

    const token = getToken();

    const newUser = await createUserPetition.createUser(
      name,
      company_id,
      email,
      notification_emails,
      phone,
      password,

      notes,
      devices_id,
      roles,
      platform,
      unit,
      restrictions,
      restricted,
      token,
      max_clients,
    );
    users.push(newUser);
  }

  async function modifyUserByAdmin(
    user_id,
    user_name,
    user_phone,
    user_email,
    user_company,
    user_notification_emails,
    user_restricted,
    user_devices_sn,
    user_removable_sn,
    user_notes,
    user_roles,
    user_platform,
    user_unit,
    user_restrictions,
    max_clients,
  ) {
    const token = getToken();

    const result = await modifyUserByAdminPetition.modifyUserByAdmin(
      user_id,
      user_name,
      user_phone,
      user_email,
      user_company,
      user_notification_emails,
      user_restricted,
      user_devices_sn,
      user_removable_sn,
      user_notes,
      user_roles,
      user_platform,
      user_unit,
      user_restrictions,
      token,
      max_clients,
    );
    users = await getAllUsers();

    const index = users.map((item) => item._id).indexOf(user_id);

    users[index] = result;

    return result;
  }
  async function modifyAliasByAdmin(payload) {
    const token = getToken();
    const result = await modifyUserByAdminPetition.modifyDeviceUserByAdmin(token, payload);
    return result;
  }

  async function modifyBeaconAlias(payload) {
    const token = getToken();
    const result = await modifyBeaconAliasPetition.modifyBeaconAliasByUser(token, payload);
    return result;
  }

  async function modifyBeaconNotes(payload) {
    const token = getToken();
    const result = await modifyBeaconNotesPetition.modifyBeaconNotesByUser(token, payload);
    return result;
  }

  async function changeUserPasswordByAdmin(user_id, password) {
    const token = getToken();
    await changePasswordByAdminPetition.changeUserPasswordByAdmin(user_id, password, token);
  }

  async function changePassword() {
    // const token = getToken()
  }

  async function deleteUser(user_id, mode) {
    if (users == null) {
      await getAllUsers();
    }
    const token = getToken();
    await deleteUserPetition.deleteUser(user_id, mode, token);

    const indexToDelete = users.map((item) => item._id).indexOf(user_id);
    users.splice(indexToDelete, 1);
  }

  async function deleteCompany(company_id, mode) {
    if (companies == null) {
      await getAllCompanies();
    }
    const token = getToken();
    await deleteCompanyPetition.deleteCompany(company_id, token, mode);

    const indexToDelete = companies.map((item) => item._id).indexOf(company_id);
    companies.splice(indexToDelete, 1);
  }

  async function modifyCompanyByAdmin(company_id, company_name, company_cif, company_phone, company_email, company_notes) {
    if (companies == null) {
      companies = await getAllCompanies();
    }

    const token = getToken();

    await modifyCompanyByAdminPetition.modifyCompany(
      company_id,
      company_name,
      company_cif,
      company_phone,
      company_email,
      company_notes,
      token,
    );

    const equalId = (company) => company._id == company_id;
    const found = companies.findIndex(equalId);

    if (company_name != companies[found].name) {
      companies[found].name = company_name;
    }
    if (company_cif != companies[found].cif) {
      companies[found].cif = company_cif;
    }
    if (company_phone != companies[found].phone) {
      companies[found].phone = company_phone;
    }

    if (company_email != companies[found].email) {
      companies[found].email = company_email;
    }

    if (company_notes != companies[found].notes) {
      companies[found].notes = company_notes;
    }
  }

  async function createDevice(payload) {
    if (devices == null) {
      await getAllDevices();
    }
    const token = getToken();

    const newDevice = await createDevicePetition.createDevice(token, payload);
    devices.push(newDevice);
    await assignDeviceToUserPetition.assignDeviceToUser(newDevice._id, payload.users, newDevice.device_sn, token);
  }

  async function createBeacon(payload) {
    if (beacons == null) {
      beacons = await getAllBeacons();
    }
    const token = getToken();

    const newBeacon = await createBeaconPetition.createBeacon(token, payload);
    beacons.push(newBeacon);

    if (payload.users) {
      await assignBeaconToUserPetition.assignBeaconToUser(
        newBeacon._id,
        payload.users,
        newBeacon.mac_address,
        newBeacon.alias,
        newBeacon.notes,
        newBeacon.category,
        token,
      );
    }
  }

  async function modifyDeviceByAdmin(payload) {
    const token = getToken();

    const modifiedDevice = await modifyDeviceByAdminPetition.modifyDeviceByAdmin(token, payload);

    if (devices == null || users == null) {
      await getAllDevices();
      await getAllUsers();
    }

    if (payload.usersToModify.length > 0) {
      await assignDeviceToUserPetition.assignDeviceToUser(
        modifiedDevice._id,
        payload.usersToModify,
        modifiedDevice.device_sn,
        token,
      );

      for (const user of payload.usersToModify) {
        const index = users.map((item) => item._id).indexOf(user);

        const userDevicesIds = [];

        for (const device of users[index].devices) {
          userDevicesIds.push(device.device_id);
        }

        if (!userDevicesIds.includes(modifiedDevice._id)) {
          users[index].devices.push({
            device_id: modifiedDevice._id,
            name: null,
            type: 'owned_device',
            tags: [],
            map_icon: '',
            is_favorite: false,
            alarms: [],
            probes_config: {
              p1: null,
              p2: null,
              p3: null,
              p4: null,
              p5: null,
              p6: null,
              p7: null,
              p8: null,
            },
          });
        }
      }
    }

    if (payload.usersToRemoveDevice.length > 0) {
      await removeDeviceFromUserPetition.removeDeviceFromUser(
        modifiedDevice._id,
        payload.usersToRemoveDevice,
        token,
        modifiedDevice.device_sn,
      );

      for (const user of payload.usersToRemoveDevice) {
        const index = users.map((item) => item._id).indexOf(user);

        const userDevicesIds = [];

        for (const device of users[index].devices) {
          userDevicesIds.push(device.device_id);
        }

        users[index].devices.splice(userDevicesIds.indexOf(modifiedDevice._id), 1);
      }
    }

    const index = devices.map((item) => item._id).indexOf(modifiedDevice._id);

    devices[index] = modifiedDevice;
  }

  async function modifyBeaconByAdmin(payload) {
    const token = getToken();
    const modifiedBeacon = await modifyBeaconByAdminPetition.modifyBeaconByAdmin(token, payload);

    if (!beacons || !users) {
      beacons = await getAllBeacons();
      users = await getAllUsers();
    }

    // ASIGNAR BEACON A USUARIOS
    if (payload.users.length > 0) {
      await assignBeaconToUserPetition.assignBeaconToUser(
        modifiedBeacon._id,
        payload.users,
        modifiedBeacon.mac_address,
        modifiedBeacon.alias,
        modifiedBeacon.notes,
        modifiedBeacon.category,
        token,
      );

      payload.users.forEach((user) => {
        const userObj = users.find((item) => item._id === user);
        if (userObj && !userObj.beacons.some((b) => b.beacon_id === modifiedBeacon._id)) {
          userObj.beacons.push({
            beacon_id: modifiedBeacon._id,
            mac_address: modifiedBeacon.mac_address,
            alias: modifiedBeacon.alias,
          });
        }
      });
    }

    // REMOVER BEACON DE USUARIOS
    if (payload.usersToRemoveBeacon?.length > 0) {
      await removeBeaconFromUserPetition.removeBeaconFromUser(token, payload);

      payload.usersToRemoveBeacon.forEach((user) => {
        const userObj = users.find((item) => item._id === user);
        if (userObj) {
          const beaconIndex = userObj.beacons.findIndex((b) => b.beacon_id === modifiedBeacon._id);
          if (beaconIndex > -1) {
            userObj.beacons.splice(beaconIndex, 1);
          }
        }
      });
    }

    // ACTUALIZAR BEACON DE LA LISTA GENERAL
    const beaconIndex = beacons.findIndex((item) => item._id === modifiedBeacon._id);
    if (beaconIndex > -1) {
      beacons[beaconIndex] = modifiedBeacon;
    }

    return modifiedBeacon;
  }

  async function getDailyData(id) {
    const res = await getDailyDataPetition(id);
    return res;
  }

  async function getAllSims() {
    const token = getToken();

    const res = await getAllSimsPetition(token);
    return res;
  }

  async function getAllSimsCollection() {
    const token = getToken();

    const res = await getAllCarrierSims(token);
    return res;
  }

  async function getAllBeacons() {
    const token = getToken();

    const res = await getAllBeaconsPetition(token);
    return res;
  }

  async function getSimInfo(id, currentDate, dataDate) {
    const res = await getSimInfoPetition(id, currentDate, dataDate);
    return res;
  }

  async function getSimInfoForMatooma(id) {
    const res = await getSimInfoMatooma(id);
    return res;
  }

  return {
    getUsersDevices,
    getAllDevices,
    getAllCompanies,
    getAllUsers,
    deleteCompany,
    modifyCompanyByAdmin,
    getAllUserDevices,
    getDeviceUsers,
    getBeaconUsers,
    createDevice,
    createBeacon,
    createNewCompany,
    createUser,
    modifyUserByAdmin,
    modifyAliasByAdmin,
    modifyBeaconAlias,
    modifyBeaconNotes,
    changeUserPasswordByAdmin,
    deleteCompany,
    deleteUser,
    getUser,
    getCompany,
    getDevice,
    getBeacon,
    modifyDeviceByAdmin,
    modifyBeaconByAdmin,
    reset,
    getDailyData,
    getAllSims,
    getAllSimsCollection,
    getAllBeacons,
    getSimInfo,
    getSimInfoForMatooma,
  };
};

module.exports = AdminViewModel();
