<template>
  <v-container ref="itvForm" class="shared-container-desktop pa-2">
    <div style="width: 100%" id="device-data-form-container">
      <v-card-title color="primary" class="ticket-view-modal-title headline" style="justify-content: center">
        <span class="ticket-view-modal-title-text" :style="{ color: theme === 'dark' ? 'white' : '' }">
          {{ $t('dashboard.device_card.itv').toUpperCase() }}
        </span>
      </v-card-title>

      <div class="device-data-form-table">
        <v-spacer></v-spacer>
        <v-text-field
          :dark="theme === 'dark'"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('nav_bar.menu.search')"
          single-line
          hide-details></v-text-field>
        <!-- :hide-default-footer="isSmallWindow" -->
        <v-data-table
          :disable-sort="isMobile"
          @click:row="editMaintenance"
          hide-default-footer
          sort-by="next_inspection_date"
          :sort-desc="false"
          :search="search"
          :items="itvCollection"
          :headers="itvTableHeader"
          item-key="_id"
          :page.sync="page"
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          :loading="isTableLoading"
          class="elevation-1">
          <template v-slot:[`item.itv_periodicity`]="{ item }"> {{ item.itv_periodicity }} m </template>
          <template v-slot:[`item.last_inspection_date`]="{ item }"> {{ formatDateTable(item.last_inspection_date) }} </template>
          <template v-slot:[`item.next_inspection_date`]="{ item }">
            <span
              v-if="item.state?.toLowerCase() === 'active'"
              class="mdi mdi-circle icon-green pr-2"
              :title="$t('admin.menu.active')">
            </span>
            <span
              v-if="item.state?.toLowerCase() === 'expired'"
              class="mdi mdi-circle icon-red pr-2"
              :title="$t('nav_bar.menu.expired')">
            </span>
            <span
              v-if="item.state?.toLowerCase() === 'next'"
              class="mdi mdi-circle icon-orange pr-2"
              :title="$t('nav_bar.menu.next')">
            </span>
            {{ formatDateTable(item.next_inspection_date) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-row style="display: flex; justify-content: center; align-items: center">
              <!-- <a :href="item.files" target="_blank">
                <v-icon small color="primary">mdi-file-eye</v-icon>
              </a> -->

              <a>
                <v-icon small color="success" @click.stop="renewRow(item)"> mdi-update </v-icon>
              </a>

              <a>
                <v-icon small color="error" @click.stop="deleteRow(item)"> mdi-delete </v-icon>
              </a>
            </v-row>
          </template>
        </v-data-table>

        <div class="text-center pt-2">
          <v-pagination
            dense
            :dark="theme === 'dark'"
            :style="{
              color: theme === 'dark' ? 'white !important' : '',
              ' margin-bottom': '20px',
            }"
            v-model="page"
            :length="pageCount"></v-pagination>
        </div>
      </div>
    </div>
    <Confirm ref="confirm"></Confirm>
    <MaintenanceModal v-if="isActive" ref="modalTask" :maintenance="maintenance" @closeMaintenanceDialog="isActive = false" />
  </v-container>
</template>

<script>
import MaintenanceModal from '../../../components/forms/maintenanceModal.vue';
import Confirm from '../../../components/confirm.vue';
const ls = require('localstorage-slim');
import { updateMaintenance, deleteMaintenance, getAllUserMaintenance } from '../../../viewModels/maintenanceViewModel';
import { eventBus } from '../../../main';
import { getUser } from '../../../viewModels/userViewModel';
import { getUserDevices } from '../../../server/petitions/users/getUserDevices';
import { updateMaintenanceDates, formatDateTable, formatLastInspectionDate } from '../../../utils/utils';

export default {
  components: { Confirm, MaintenanceModal },
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    theme: ls.get('theme') ?? 'ligth',
    isSmallWindow: window.innerWidth <= 1550 ? true : false,
    windowWidth: window.innerWidth,
    isTableLoading: true,
    page: 1,
    itemsPerPage: window.innerWidth <= 1550 ? 5 : 10,
    pageCount: 5,
    page: 1,
    user: null,
    itvCollection: [],
    search: '',
    userDevices: null,
    isActive: false,
    maintenance: null,
  }),
  mounted: async function () {
    window.addEventListener('resize', this.handleResize);

    this.user = await getUser();
    let maintenances = await getAllUserMaintenance(this.user);
    this.userDevices = await getUserDevices(this.user.token, this.user.userId);

    if (maintenances != null) {
      this.itvCollection = maintenances.filter(
        (maintenance) =>
          maintenance.type == 'ITV' && this.userDevices.some((device) => device.device_sn === maintenance.device_sn),
      );
    }

    this.updateItvCollection();

    eventBus.$on('resultMaintenance', (doc, isCreate) => {
      if (!doc.certificateUrl && doc[0].type === 'ITV') {
        doc.forEach(async (device) => {
          if (isCreate) {
            this.itvCollection.push(device);
          } else {
            this.itvCollection = this.itvCollection.map((i) => (i._id == device._id ? device : i));
          }
        });
      }
      this.updateItvCollection();
    });

    eventBus.$on('changeLanguageEvent', async (lang) => {
      this.updateItvCollection();
    });

    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });

    this.isTableLoading = false;
  },

  methods: {
    formatDateTable,
    formatLastInspectionDate,
    editMaintenance: async function (maintenance) {
      this.isActive = true;
      this.maintenance = maintenance;
      setTimeout(() => {
        this.$refs.modalTask.setActiveModal(true);
      }, 100);
    },

    updateItvCollection: async function () {
      this.itvCollection.forEach(async (doc, index) => {
        doc.index = index;
        this.userDevices.filter((i) => {
          if (i.device_sn == doc.device_sn) {
            doc.alias = i.alias;
          }
        });
        updateMaintenanceDates(doc);
      });
    },

    deleteRow: async function (item) {
      if (
        await this.$refs.confirm.open(
          `${this.$t('general.dialog.confirmation.button.accept_delete')}: ${item.name}`,
          this.$t('general.form.delete_ITV'),
          {
            color: 'red',
          },
        )
      ) {
        let indexToDelete = this.itvCollection.indexOf(item);
        this.itvCollection.splice(indexToDelete, 1);

        await deleteMaintenance(item);
        eventBus.$emit('removeMaintenance', item);
      }
    },

    renewRow: async function (item) {
      let snToArray = [];
      snToArray.push(item.device_sn);
      item.devices = snToArray;

      item.prevState = item.state;
      if (
        await this.$refs.confirm.open(`${this.$t('general.form.renew')}: ${item.name}`, this.$t('general.form.renew_ITV'), {
          color: 'green',
        })
      ) {
        eventBus.$emit('updateMaintenance', item);

        let newInspectionDate = this.formatDateTableItv(new Date(), item.itv_periodicity);

        item.next_inspection_date = item.next_inspection_date.replace(item.next_inspection_date, newInspectionDate);

        item.last_inspection_date = formatLastInspectionDate(new Date());

        updateMaintenanceDates(item);

        this.updateMaintenance(item);
      }
    },

    formatDateTableItv(date, periodicity) {
      let month = date.getMonth() + 1 + periodicity; // Sumamos la periodicidad al mes actual
      let year = date.getFullYear();

      // Ajustar meses y años si se pasa de 12
      if (month > 12) {
        let extraYears = Math.floor(month / 12); // Cuántos años extras tenemos
        month = month % 12; // El mes actual después de ajustar los años
        year += extraYears; // Ajustamos el año

        // ¿Y si la suma nos deja en un mes 0? Eso sería diciembre del año anterior
        if (month === 0) {
          month = 12; // Ajustamos a diciembre
          year -= 1; // Retrocedemos un año, porque técnicamente estamos en diciembre del año anterior
        }
      }

      // Formateo final
      const day = date.getDate().toString().padStart(2, '0');
      month = month.toString().padStart(2, '0'); // Asegúrate de que el mes tenga dos dígitos
      return `${year}/${month}/${day}`;
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    updateMaintenance: async function (payload) {
      try {
        await updateMaintenance(payload);
      } catch (error) {
        switch (e.code) {
          case CreateMaintenanceException.unknownError:
          case CreateMaintenanceException.incorrectParameters:
          case CreateMaintenanceException.invalidToken:
          case CreateMaintenanceException.expiredToken:
            console.error(e);
            this.errorText = this.$t('general.error.unexpected_error');
            this.errorDialog = true;
            break;
          default:
            console.error('CATCH ERROR:', e);
        }
      }
    },
  },
  watch: {
    date(val) {
      this.form.nextInspectionDate = this.formatDate(this.date);
    },
    windowWidth(newWidth, oldWidth) {
      this.isSmallWindow = newWidth <= 1550;
      this.itemsPerPage = this.isSmallWindow ? 5 : 10;
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    itvTableHeader() {
      return [
        {
          text: this.$t('dashboard.device_card.name_task'),
          value: 'name',
          cellClass: 'headerClass',
          class: 'headerClass',
          alignment: 'center',
          width: this.isSmallWindow ? '14%' : '10%',
          divider: true,
        },
        {
          text: this.$t('general.form.alias_device'),
          value: 'alias',
          cellClass: 'headerClass',
          class: 'headerClass',
          alignment: 'center',
          width: this.isSmallWindow ? '14%' : '8%',
          divider: true,
        },
        {
          text: this.$t('dashboard.device_card.brand'),
          value: 'brand',
          cellClass: 'headerClass',
          class: 'headerClass',
          alignment: 'center',
          width: this.isSmallWindow ? '12%' : '5%',
          divider: true,
        },
        {
          text: this.$t('general.form.every'),
          value: 'itv_periodicity',
          cellClass: 'headerClass',
          class: 'headerClass',
          alignment: 'center',
          width: '5%',
          divider: true,
        },
        {
          text: this.$t('general.form.last_ITV'),
          value: 'last_inspection_date',
          cellClass: 'headerClass',
          class: 'headerClass',
          alignment: 'center',
          width: this.isSmallWindow ? '11%' : '6%',
          divider: true,
        },
        {
          text: this.$t('general.form.next_ITV'),
          value: 'next_inspection_date',
          cellClass: 'headerClass',
          class: 'headerClass',
          alignment: 'center',
          width: this.isSmallWindow ? '14%' : '7%',
          divider: true,
        },
        {
          text: this.$t('general.form.notes'),
          value: 'notes',
          cellClass: 'headerClass',
          class: 'headerClass',
          alignment: 'center',
          width: this.isSmallWindow ? '12%' : '9%',
          divider: true,
        },
        {
          text: this.$t('general.form.validity'),
          cellClass: 'headerClass',
          class: 'headerClass',
          value: 'remainingDays',
          alignment: 'center',
          width: this.isSmallWindow ? '10.5%' : '3%',
          divider: true,
        },

        {
          text: '',
          cellClass: 'headerClass',
          class: 'headerClass',
          value: 'actions',
          alignment: 'center',
          width: this.isSmallWindow ? '9%' : '5%',
          divider: true,
        },
      ];
    },
  },
};
</script>
<style scoped>
.headerClass {
  font-size: smaller !important;
  text-align: center;
}
::v-deep .v-icon.notranslate.v-data-table-header__icon {
  display: none;
}
::v-deep .v-pagination__item.v-pagination__item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5vh;
}

::v-deep .v-pagination__navigation.v-pagination__navigation {
  height: 2.5vh;
}
</style>
