<template>
  <v-form ref="deviceForm" v-model="formValid" lazy-validation>
    <div id="device-data-form-container">
      <div class="device-data-form-table">
        <v-data-table
          :headers="certsTableHeader"
          :items="certTableData"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          :loading="isTableLoading"
          @click:row="openWindow"
          class="elevation-1 ma-4 mt-0"
          style="overflow: auto; min-height: 100px; max-height: 400px">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="mr-1" color="error" @click.stop="deleteRow(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination :dark="theme === 'dark' ? true : false" v-model="page" :length="pageCount"></v-pagination>
        </div>
      </div>
      <div class="expansion-panel-edit-data">
        <div id="container-edit-device" class="mt-2">
          <v-row class="edit-data-user edit-data-device">
            <v-col cols="12" class="edit-data-right">
              <v-select
                :items="items"
                prepend-icon="mdi-text"
                v-model="name"
                :label="$t('dashboard.device_card.select_name')"
                dense></v-select>
            </v-col>
          </v-row>
          <v-row class="edit-data-user edit-data-device">
            <v-col cols="8">
              <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="nextInspectionDate"
                    prepend-icon="mdi-calendar"
                    readonly
                    :label="$t('general.form.last_validation')"
                    v-bind="attrs"
                    v-on="on"
                    @blur="formatDate(nextInspectionDate)">
                  </v-text-field>
                </template>
                <v-date-picker v-model="date" max="2100-01-01" min="1990-01-01"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4" class="mt-2">
              <v-text-field
                dense
                outlined
                class="rounded-lg"
                :label="$t('general.form.days_in_advance')"
                type="number"
                v-model="days"></v-text-field>
            </v-col>
          </v-row>
          <div></div>
          <div class="user-configuration-data">
            <v-row class="row-card">
              <v-col cols="12">
                <v-card
                  class="d-flex mb-4"
                  outlined
                  @drop.prevent="onDrop($event)"
                  @dragover.prevent="dragover = true"
                  @dragenter.prevent="dragover = true"
                  @dragleave.prevent="dragover = false">
                  <v-row class="d-flex flex-column" dense align="center" justify="center">
                    <v-file-input
                      v-model="file"
                      :dark="theme === 'dark' ? true : false"
                      :rules="fileRules"
                      accept="image/png, image/jpeg, application/pdf"
                      prepend-icon="mdi-cloud-upload"
                      :label="$t('general.form.certificate')"
                      @change="changeFile"></v-file-input>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
    <Confirm ref="confirm"></Confirm>
  </v-form>
</template>

<script>
const VueI18n = require('../../../plugins/lang/index');
import { eventBus } from '../../../main';
import Confirm from '../../confirm.vue';
const ls = require('localstorage-slim');

export default {
  props: {
    deviceData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: (instance) => ({
    dragover: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    items: [
      VueI18n.default.t('dashboard.device_card.termo'),
      VueI18n.default.t('device.last_frames.frame_frigo.p1'),
      VueI18n.default.t('device.last_frames.frame_frigo.p2'),
      VueI18n.default.t('device.last_frames.frame_frigo.p3'),
      VueI18n.default.t('device.last_frames.frame_frigo.p4'),
      'ITV',
    ],
    formValid: false,
    isTableLoading: true,
    certs: [],
    certToRemove: [],

    serialNumber: instance.deviceData.device_sn,
    name: instance.deviceData.name,

    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    nextInspectionDate: instance.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    ),
    days: 30,
    url: '',
    file: null,
    resFile: null,
    enabled: true,
    certTableData: [],
    theme: ls.get('theme') ?? 'ligth',
  }),
  methods: {
    onDrop(event) {
      this.dragover = false;

      if (event.dataTransfer.files.length == 0) {
        return;
      }

      if (event.dataTransfer.files.length > 1) {
        this.uploadErrors.push(this.$t('dashboard.max_files'));
        return;
      }

      // this.removeInputFile();
      const file = event.dataTransfer.files[0];

      // if (!this.validFileMimeType(file)) {
      //   const errorMsg = this.$t('dashboard.invalid_mime').replace('$1', file.name);
      //   this.uploadErrors.push(errorMsg);
      //   return;
      // }

      this.file = file;
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${year}/${month}/${day}`;
    },
    async convertBase64(file) {
      const prom = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
      return prom;
    },

    deleteRow: async function (item) {
      if (
        await this.$refs.confirm.open('Delete', this.$t('general.form.delete_certificate'), {
          color: 'red',
        })
      ) {
        const certificateId = item._id;
        const deviceSn = this.deviceData.device_sn;

        let indexToDelete = this.certTableData.indexOf(item);
        this.certToRemove.push(this.certs[indexToDelete]);
        this.certs.splice(indexToDelete, 1);
        this.certTableData.splice(indexToDelete, 1);

        this.$emit('removeCert', certificateId, deviceSn);
      }
    },
    validate: function () {
      return this.$refs.deviceForm.validate();
    },

    openWindow: async (e) => {
      window.open(e.certificateUrl);
    },
  },
  mounted: async function (e) {
    this.certTableData = this.deviceData.certificate;
    this.isTableLoading = false;
    this.enabled = this.deviceData.enabled;
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
  },
  computed: {
    async changeFile(e) {
      const file = this.file;
      if (file != null) {
        this.base64 = await this.convertBase64(file);
        this.resFile = this.base64;
      }
    },
    editing() {
      return this.$route.name === 'editDevice';
    },
    certsTableHeader() {
      return [
        { text: this.$t('general.form.name_certificate'), value: 'name' },
        { text: this.$t('general.form.alias'), value: 'alias' },
        { text: this.$t('general.form.certificate'), value: 'certificateUrl' },
        { text: this.$t('general.form.next_inspection'), value: 'nextInspectionDate' },
        { text: '', value: 'actions' },
      ];
    },
    fileRules() {
      return [(value) => !value || value.size < 1000000 || this.$t('general.form.maxSize')];
    },
    textRules() {
      return [(value) => !!value || this.$t('general.error.empty_value')];
    },
  },
  watch: {
    date(val) {
      this.nextInspectionDate = this.formatDate(this.date);
    },
  },
  components: { Confirm },
};
</script>
