<template>
  <div class="mt-5 mx-auto" style="max-width: 550px">
    <v-toolbar class="search-contain" style="border-radius: 20px; background: transparent !important">
      <v-autocomplete
        style="border-radius: 20px"
        v-model="select"
        :loading="isLoading"
        :items="userSearch"
        :search-input-sync="search"
        cache-items
        class="search-device mx-8"
        flat
        autofocus
        hide-no-data
        hide-details
        solo
        :label="$t('alarms.select_devices')"
        @change="editDevice">
      </v-autocomplete>
    </v-toolbar>
    <ErrorDialog
      :isActive="errorDialog"
      v-bind:tittleText="$t('general.dialog.error.title')"
      :messageText="errorText"
      v-bind:acceptButtonText="$t('general.dialog.error.accept_button')"
      v-on:accept-dialog="errorDialog = false">
    </ErrorDialog>
  </div>
</template>

<script>
import { getUsersDevices, getDevice } from '../../../viewModels/adminViewModel';
import { GetUsersException } from '../../../server/petitions/users/getUsers';
import ConfirmationDialog from '../../../components/confirmationDialog';
import ErrorDialog from '../../../components/errorDialog';
import router from '../../../plugins/router/index';
import { getUserDevicesForSearch } from '../../../server/petitions/users/getUserDevices';
import { getUser } from '../../../viewModels/userViewModel';

export default {
  components: {
    ConfirmationDialog,
    ErrorDialog,
  },
  data: () => ({
    isLoading: true,
    search: null,
    select: null,
    userSearch: [],
    deleteDialog: false,
    dialogText: '',
    userToDelete: null,
    deleteMode: 'soft',
    usersArray: [],
    errorDialog: false,
    errorText: '',
  }),
  methods: {
    editDevice: async function (e) {
      router.push({
        name: 'editDevice',
        params: { device_sn: parseInt(e.split('--')[1]) },
      });
    },
  },
  mounted: async function () {
    try {
      let user = await getUser();
      this.usersArray = await getUserDevicesForSearch(user.token, user.userId);
      this.usersArray.map((i) => {
        this.userSearch.push(i.alias.toString().concat('--', i.device_sn));
      });
      this.isLoading = false;
    } catch (e) {
      switch (e.code) {
        case GetUsersException.unknownError:
        case GetUsersException.incorrectParameters:
        case GetUsersException.invalidToken:
        case GetUsersException.expiredToken:
          console.error(e);
          this.dialogText = this.$t('general.error.unexpected_error');
          this.errorDialog = true;
          break;
        default:
          console.error('CATCH ERROR:', e);
      }
    }
  },
};
</script>
<style>
.search-device {
  background: #f7f7f739;
}
</style>
