const PetitionError = require('../../petitionError');
const { del } = require('../../httpRequester');

class DeleteAlarmsPetitionException extends PetitionError {
  constructor(code) {
    super('Create alarm', code);
  }
}

async function deleteAlarmsPetition(token, id, email, device_sn) {
  const payload = {
    id,
    email,
    device_sn,
  };

  const response = await del('alarm', payload, token);

  if (response.result !== DeleteAlarmsPetitionException.success) {
    return response.result;
  }
  return response; // TODO transform
}

module.exports = {
  deleteAlarmsPetition,
  DeleteAlarmsPetitionException,
};
