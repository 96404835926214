const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class turnOffDeviceException extends PetitionError {
  static serialNumberAlreadyInDevice = 'SERIALNUMBER_ALREADY_IN_DEVICE';

  constructor(code) {
    super('Turn Off', code);
  }
}

async function turnOffDevice(device_sn, stopFlag, token) {
  const payload = {
    device_sn: device_sn,
    stopFlag: stopFlag,
  };
  const response = await post('devices/turnOff', payload, token);
  if (response.result !== turnOffDeviceException.success) {
    return new turnOffDeviceException(response.result);
  }
  return response; // TODO transform
}

module.exports = {
  turnOffDevice,
  turnOffDeviceException,
};
