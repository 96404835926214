<template>
  <v-col class="containCol">
    <v-card
      id="customizedCard"
      :elevation="theme === 'dark' ? 12 : 2"
      :dark="theme === 'dark'"
      :style="`  background-color: ${theme === 'dark' ? ' transparent' : 'white'};`"
      class="mx-auto my-3"
      max-width="160"
      :height="setPointError ? '235' : '220'">
      <v-card-title style="font-size: small">{{ this.$t('dashboard.limits.probe') + ' ' + num }}(Cº)</v-card-title>
      <v-card-text ref="probe">
        <v-text-field
          class="small-label"
          dense
          v-model.number="setPoint"
          ref="setPoint"
          type="number"
          :label="$t('dashboard.configuration.set_point')"
          :rules="tempRules"></v-text-field>
        <v-select
          dense
          :items="errorRangeItems"
          ref="Error Range"
          :label="$t('dashboard.configuration.error_range')"
          v-model="errorRange"
          :style="{ marginTop: setPointError ? '20px' : '0' }"></v-select>
        <v-text-field
          class="medium-label"
          :disabled="setPoint == null"
          type="number"
          dense
          v-model="interval"
          :label="$t('dashboard.configuration.interval_in_min')"></v-text-field>
      </v-card-text>
      <v-card-actions style="justify-content: center">
        <v-btn elevation="3" outlined color="red" x-small @click="reset" width="145"> {{ $t('alarms.remove_alarm') }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
const ls = require('localstorage-slim');
import { eventBus } from '../../main';

export default {
  props: {
    num: {
      type: String,
    },
    probe: {
      type: String,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    setPointError: false,
    theme: ls.get('theme') ?? 'ligth',
    interval: 10,
    setPoint: null,
    errorRangeItems: [2, 3, 4, 5],
    errorRange: 2,
    max: 99,
    min: null,
  }),

  emits: {
    enableButton: null,
    disableButton: null,
  },

  methods: {
    reset() {
      this.$refs.setPoint.reset();
      this.interval = 10;
      this.errorRange = 2;
    },
    checkButtonStatus() {
      const isSetPointValid = Number(this.setPoint) <= 99 && Number(this.setPoint) >= -99;
      const isIntervalValid = this.interval <= 180 && this.interval >= 5;
      const isSetPointDefined = this.setPoint !== null && this.setPoint !== '';
      const isIntervalDefined = this.interval !== null && this.interval !== '';

      if (isSetPointValid && isIntervalValid) {
        this.$emit('enableButton');
      } else {
        this.$emit('disableButton');
      }

      if (!isSetPointDefined) this.$emit('emptyErrorRange');

      if (!isIntervalDefined) this.$emit('emptyErrorInterval');

      this.$emit('limits', this.max, this.min, this.num, this.setPoint, this.errorRange, Number(this.interval));
    },
    resetLimits() {
      this.setPoint = null;
      this.interval = 10;
      this.errorRange = 2;
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    isNumber() {
      return typeof this.setPoint;
    },

    tempRules() {
      if (Number(this.setPoint) > 99 || Number(this.setPoint) < -99) {
        this.$emit('disableButton');
      }
      return [
        (value) => Number(value) >= -99 || this.$t('dashboard.configuration.temp_min'),
        (value) => Number(value) <= 99 || this.$t('dashboard.configuration.temp_max'),
      ];
    },

    maxRange() {
      return this.setPoint + Number(this.errorRange);
    },

    minRange() {
      return this.setPoint - Number(this.errorRange);
    },
  },
  watch: {
    setPoint(value) {
      this.setPointError = Number(value) > 99 || Number(value) < -99;
    },
  },

  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
    eventBus.$on('cleanLimits', this.resetLimits);

    // STYLE FOR CUSTOMCARD
    const CONTAINER = document.querySelector('.containCol');
    const CARDS = document.querySelectorAll('#customizedCard');

    const CONFIG = {
      proximity: 40,
      spread: 80,
      blur: 20,
      gap: 32,
      vertical: false,
      opacity: 0,
    };

    const PROXIMITY = 10;

    const UPDATE = (event) => {
      // Get the angle based on the center point of the card and pointer position
      for (const CARD of CARDS) {
        // Check the card against the proximity and then start updating
        const CARD_BOUNDS = CARD.getBoundingClientRect();
        // Get distance between pointer and outerbounds of card
        if (
          event?.x > CARD_BOUNDS.left - CONFIG.proximity &&
          event?.x < CARD_BOUNDS.left + CARD_BOUNDS.width + CONFIG.proximity &&
          event?.y > CARD_BOUNDS.top - CONFIG.proximity &&
          event?.y < CARD_BOUNDS.top + CARD_BOUNDS.height + CONFIG.proximity
        ) {
          // If within proximity set the active opacity
          CARD.style.setProperty('--active', 1);
        } else {
          CARD.style.setProperty('--active', CONFIG.opacity);
        }
        const CARD_CENTER = [CARD_BOUNDS.left + CARD_BOUNDS.width * 0.5, CARD_BOUNDS.top + CARD_BOUNDS.height * 0.5];
        let ANGLE = (Math.atan2(event?.y - CARD_CENTER[1], event?.x - CARD_CENTER[0]) * 180) / Math.PI;
        ANGLE = ANGLE < 0 ? ANGLE + 360 : ANGLE;
        CARD.style.setProperty('--start', ANGLE + 90);
      }
    };

    document.body.addEventListener('pointermove', UPDATE);

    const RESTYLE = () => {
      CONTAINER.style.setProperty('--gap', CONFIG.gap);
      CONTAINER.style.setProperty('--blur', CONFIG.blur);
      CONTAINER.style.setProperty('--spread', CONFIG.spread);
      CONTAINER.style.setProperty('--direction', CONFIG.vertical ? 'column' : 'row');
    };

    RESTYLE();
    UPDATE();
  },

  updated: function () {
    this.max = this.maxRange;
    this.min = this.minRange;
    this.checkButtonStatus();
  },
};
</script>

<style>
label:hover {
  cursor: pointer;
}
h5 {
  font-weight: normal;
}
.small-label .v-label {
  font-size: 0.65em;
}
.medium-label .v-label {
  font-size: 0.85em;
}
.v-select {
  transition: margin-top 0.2s ease-in-out;
}

/* STYLE FOR CUSTOMCARD */
@font-face {
  font-family: 'Geist Sans';
  src: url('https://assets.codepen.io/605876/GeistVF.ttf') format('truetype');
}

:root {
  --bg: hsl(246 44% 7%);
  --border: hsl(280 10% 50% / 1);
  --card: hsl(237 36% 10%);
  --color: hsl(240 18% 80%);
  --border-width: 2px;
  --border-radius: 12px;
  --gradient: conic-gradient(
    from 180deg at 50% 70%,
    hsla(0, 0%, 98%, 1) 0deg,
    #eec32d 72.0000010728836deg,
    #ec4b4b 144.0000021457672deg,
    #709ab9 216.00000858306885deg,
    #4dffbf 288.0000042915344deg,
    hsla(0, 0%, 98%, 1) 1turn
  );
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

@property --start {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

.containCol {
  --spread: 60;
}

#customizedCard {
  --active: 0.15;
  --start: 0;
  background: var(--card);
  aspect-ratio: 330 / 400;
  border-radius: var(--border-radius);
  gap: 0.25rem;
}

#customizedCard:is(:hover, :focus-visible) {
  z-index: 2;
}

#customizedCard::before {
  position: absolute;
  inset: 0;
  border: var(--border-width) solid transparent;
  content: '';
  border-radius: var(--border-radius);
  pointer-events: none;
  background: var(--border);
  background-attachment: fixed;
  border-radius: var(--border-radius);
  mask: linear-gradient(#0000, #0000),
    conic-gradient(
      from calc(((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 1.5)) * 1deg),
      hsl(0 0% 100% / 0.15) 0deg,
      white,
      hsl(0 0% 100% / 0.15) calc(var(--spread) * 2.5deg)
    );
  mask-clip: padding-box, border-box;
  mask-composite: intersect;
  opacity: var(--active);
  transition: opacity 1s;
}

#customizedCard::after {
  --bg-size: 100%;
  content: '';
  pointer-events: none;
  position: absolute;
  background: var(--gradient);
  background-attachment: fixed;
  border-radius: var(--border-radius);
  opacity: var(--active, 0);
  transition: opacity 1s;
  --alpha: 0;
  inset: 0;
  border: var(--border-width) solid transparent;
  mask: linear-gradient(#0000, #0000),
    conic-gradient(
      from calc(((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 0.5)) * 1deg),
      #0000 0deg,
      #fff,
      #0000 calc(var(--spread) * 0.5deg)
    );
  filter: brightness(1.5);
  mask-clip: padding-box, border-box;
  mask-composite: intersect;
}
</style>
