<template>
  <v-container v-if="!isMobile" class="report-container-desktop pa-2">
    <v-row class="report-content">
      <v-col cols="2" class="menu-report">
        <v-navigation-drawer permanent class="panel-left-report">
          <v-toolbar-title class="mb-5" style="margin-top: 2rem; margin-left: 1em"
            ><b>{{ $t('reports.navbar.menu') }}</b></v-toolbar-title
          >
          <v-divider></v-divider>

          <v-tabs vertical class="tab-vertical">
            <v-tab to="/reports/create" class="panel-left-btn d-flex justify-space-between">
              <v-icon>mdi-file-outline</v-icon>
              <span>{{ $t('reports.navbar.create') }}</span>
              <v-icon>mdi-chevron-right</v-icon>
            </v-tab>
            <v-divider></v-divider>
            <v-tab to="/reports/program" class="panel-left-btn d-flex justify-space-between">
              <v-icon>mdi-clipboard-clock-outline</v-icon>
              <span>{{ $t('reports.navbar.program') }}</span>
              <v-icon>mdi-chevron-right</v-icon>
            </v-tab>
            <v-divider></v-divider>
            <!-- <v-tab to="/reports/historic" class="d-flex justify-space-between">
              <v-icon>mdi-history</v-icon>
              {{ $t("reports.navbar.historic") }}<v-icon>mdi-chevron-right</v-icon>
            </v-tab>
            <v-divider></v-divider> -->
          </v-tabs>
        </v-navigation-drawer>
      </v-col>
      <v-col cols="10" class="report-table">
        <router-view style="z-index: -1"></router-view>
      </v-col>
    </v-row>
  </v-container>

  <v-container v-else class="report-container-mobile">
    <!-- <v-navigation-drawer permanent fixed style="z-index: 0;">
          <v-toolbar-title class="mb-5" style="margin-top: 8rem; margin-left: 1em;"
            ><b>{{ $t("reports.navbar.menu") }}</b></v-toolbar-title
          >
          <v-divider></v-divider>
          <v-tabs vertical>
            <v-tab to="/reports/create" class="d-flex justify-space-between">
              <v-icon>mdi-file-outline</v-icon> {{ $t("reports.navbar.create") }}
              <v-icon>mdi-chevron-right</v-icon>
            </v-tab>
            <v-divider></v-divider>
            <v-tab to="/reports/program" class="d-flex justify-space-between">
              <v-icon>mdi-clipboard-clock-outline</v-icon> {{ $t("reports.navbar.program") }}<v-icon>mdi-chevron-right</v-icon>
            </v-tab>
            <v-divider></v-divider>
            <v-tab to="/reports/historic" class="d-flex justify-space-between">
              <v-icon>mdi-history</v-icon>
              {{ $t("reports.navbar.historic") }}<v-icon>mdi-chevron-right</v-icon>
            </v-tab>
            <v-divider></v-divider>
          </v-tabs>
        </v-navigation-drawer> -->
    <router-view style="z-index: -1" class="report-content-mobile"></router-view>
  </v-container>
</template>

<script>
export default {
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({}),
  methods: {},
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>