<template>
  <v-container class="pa-0 create-device-container">
    <div class="container-create-device" style="margin-left: auto !important; margin-right: auto !important">
      <Certificates @file-changed="updateFileValidation" @removeDoc="removeDoc" @updatedCert="updateCert" ref="devicesData" />
      <ErrorDialog
        :isActive="errorDialog"
        v-bind:tittleText="$t('general.dialog.error.title')"
        :messageText="errorText"
        v-bind:acceptButtonText="$t('general.dialog.error.accept_button')"
        v-on:accept-dialog="errorDialog = false">
      </ErrorDialog>
    </div>
  </v-container>
</template>
<script>
import router from '../../../plugins/router/index';
import Certificates from './certificates.vue';
import ErrorDialog from '../../../components/errorDialog.vue';
import { removeCertificate, saveCertificate, getCertificates, updateCertificate } from '../../../viewModels/devicesViewModel';
import { CreateCertificateException } from '../../../server/petitions/devices/createCertificate';
import { getUser } from '../../../viewModels/userViewModel';
import { getUserDevices } from '../../../server/petitions/users/getUserDevices';
import { reportDocs } from '../../../viewModels/reportsViewModel';
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';

export default {
  components: {
    Certificates,
    ErrorDialog,
  },
  props: {
    deviceData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    theme: ls.get('theme') ?? 'ligth',
    errorDialog: false,
    errorText: '',
    user: null,
    devices: [],
    isFileValid: false,
    docs: [],
    error: false,
    messageError: '',
    isLoading: false,
    interval: {},
    value: 0,
  }),
  methods: {
    updateFileValidation(value) {
      this.isFileValid = value;
    },
    goBack: function () {
      router.push({ name: 'temperatures' });
    },
    saveCertificate: async function () {
      try {
        var documentationDataForm = this.$refs.devicesData.form;
        if (this.$refs.devicesData.validate()) {
          let serialNumberSplited = Number(this.$refs.devicesData.form.selectedDevice.split('-')[0]);
          let documentName = documentationDataForm.name;
          let nextInspectionDate = documentationDataForm.nextInspectionDate;
          let days = documentationDataForm.days;
          let base64 = this.$refs.devicesData.base64;
          let id = this.$refs.devicesData.deviceID;

          let alias;
          this.devices = await getUserDevices(this.user.token, this.user.userId);
          this.devices.map((i) => {
            if (i.device_sn == serialNumberSplited) alias = i.alias;
          });

          let result = await saveCertificate(base64, documentName, serialNumberSplited, nextInspectionDate, days, id, alias);

          this.$refs.devicesData.documentsTable.push(result.certificate.at(-1));

          this.$refs.devicesData.form = this.$refs.devicesData.resetForm;
          await this.$refs.devicesData.deviceToSelect();
        }
      } catch (e) {
        switch (e.code) {
          case CreateCertificateException.unknownError:
          case CreateCertificateException.incorrectParameters:
          case CreateCertificateException.invalidToken:
          case CreateCertificateException.expiredToken:
            console.error(e);
            this.errorText = this.$t('general.error.unexpected_error');
            this.errorDialog = true;
            break;
          default:
            console.error('CATCH ERROR:', e);
        }
      }
    },
    removeDoc: async function (docId, deviceSn) {
      try {
        await removeCertificate(docId, deviceSn);
        await this.$refs.devicesData.deviceToSelect();
      } catch (error) {
        switch (e.code) {
          case CreateCertificateException.unknownError:
          case CreateCertificateException.incorrectParameters:
          case CreateCertificateException.invalidToken:
          case CreateCertificateException.expiredToken:
            console.error(e);
            this.errorText = this.$t('general.error.unexpected_error');
            this.errorDialog = true;
            break;
          default:
            console.error('CATCH ERROR:', e);
        }
      }
    },
    updateCert: async function (payload) {
      try {
        await updateCertificate(payload);
        await this.$refs.devicesData.deviceToSelect();
      } catch (error) {
        switch (e.code) {
          case CreateCertificateException.unknownError:
          case CreateCertificateException.incorrectParameters:
          case CreateCertificateException.invalidToken:
          case CreateCertificateException.expiredToken:
            console.error(e);
            this.errorText = this.$t('general.error.unexpected_error');
            this.errorDialog = true;
            break;
          default:
            console.error('CATCH ERROR:', e);
        }
      }
    },

    createReportDocs: async function () {
      this.isLoading = true;
      if (this.docs.length > 0) {
        this.interval = setInterval(() => {
          if (this.value === 100) {
            return (this.value = 0);
          }
          this.value += 10;
        }, 750);
        await reportDocs(this.docs).then(() => {
          clearInterval(this.interval);
          this.isLoading = false;
          this.value = 0;
        });
      } else {
        this.error = true;
        this.isLoading = false;
        this.messageError = this.$t('general.error.without_documentation');
      }
    },
  },
  mounted: async function (e) {
    eventBus.$on('reportMaintenanceDocs', () => {
      this.createReportDocs();
    });
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
    this.user = await getUser();

    let deviceDocuments = await getCertificates(this.user.userId);

    deviceDocuments
      .flatMap((i) => i.certificates || []) //desmonto la array anidada y filtro null/undefined
      .filter((c) => c) // filtro falsy values (null, undefined, '', 0, false, NaN)
      .forEach((c) => this.docs.push(c));

    this.$refs.devicesData.documentsTable = this.docs;
    this.devices = await getUserDevices(this.user.token, this.user.userId);
  },
};
</script>
