const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class RemoveDeviceFromUserException extends PetitionError {
  constructor(code) {
    super('Remove device from user', code);
  }
}

async function removeDeviceFromUser(device_id, users_ids, token, device_sn) {
  const payload = { device_id, users_ids, device_sn };

  const response = await post('users/update_devices', payload, token);

  if (response.result !== RemoveDeviceFromUserException.success) {
    throw new RemoveDeviceFromUserException(response.result);
  } else {
    return response.user; // TODO transform
  }
}

module.exports = { removeDeviceFromUser, RemoveDeviceFromUserException };
