const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class CreateClientException extends PetitionError {
  static emailAlreadyInClient = 'EMAIL_ALREADY_IN_USER';

  constructor(code) {
    super('Create Client', code);
  }
}

async function createClient(token, payload) {
  const response = await post('users/', payload, token);

  if (response.result !== CreateClientException.success) {
    return response.result;
  }
  return response; // TODO transform
}

module.exports = { createClient, CreateClientException };
