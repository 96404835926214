<template id="template">
  <div v-if="!isMobile" id="container-full-screen" class="container-sample">
    <img :src="logo" class="logo" contain />
    <div class="body-container">
      <div align="center">
        <h1>{{ $t('user.new_platform.platform') }} {{ service }}</h1>
        <br />
      </div>
      <template>
        <div id="middle-section" class="middle-section">
          <v-dialog v-model="dialog2" width="1500" height="auto">
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                <v-img :src="require('../../../assets/images/alarms.png')" class="my-3" contain width="12rem" />
              </v-card-title>
              <v-btn color="primary" text @click="dialog2 = false"> X </v-btn>
            </v-card>
          </v-dialog>
          <div class="text-section">
            <h3>{{ $t('user.new_platform.update_credentials') }}</h3>
          </div>
          <div style="width: 35%" class="pa-4 mx-lg-auto">
            <div class="id_div">Su Usuario es: {{ username }}</div>
            <div class="email-container">
              <v-tooltip v-model="info" absolute>
                <span>{{ emailExplanation }}</span>
              </v-tooltip>
              <v-text-field
                dense
                outlined
                required
                class="rounded-lg"
                type="email"
                placeholder="email de contacto"
                v-model="email"
                :rules="emailRules"
                append-outer-icon="mdi-help-circle "
                @click:append-outer="explainEmail">
              </v-text-field>

              <v-alert
                v-if="emailInfoRequired"
                border="top"
                color="deep-purple accent-1"
                prominent
                type="info"
                dark
                v-click-outside="removeEmailAlert">
                {{ emailExplanation }}
              </v-alert>
            </div>
            <br />
            <v-alert
              v-if="invalidInfo"
              border="top"
              color="red lighten-2"
              prominent
              type="error"
              dark
              v-click-outside="removeInfoAlert">
              {{ invalidInfoExplanation }}
            </v-alert>
            <div class="button">
              <v-btn @click="sendUserInfo">Enviar</v-btn>
            </div>
          </div>
          <br />
          <v-snackbar v-model="snackbar" :multi-line="multiLine" color="green lighten-2">
            <v-icon>mdi-emoticon-happy</v-icon> {{ snackbarText }}

            <template v-slot:action="{ attrs }">
              <v-btn color="black" text v-bind="attrs" @click="snackbar = false"> Cerrar </v-btn>
            </template>
          </v-snackbar>
        </div>
      </template>
      <div class="text-center ma-2"></div>
    </div>
    <v-footer :padless="true" fixed>
      <v-card flat tile width="100%" color="#3B444F" class="lighten-1 text-right">
        <v-card-text> </v-card-text>
      </v-card>
    </v-footer>
  </div>

  <!-- Mobile view -->

  <v-container v-else id="container-mobile" class="container-mobile">
    <img :src="logo" class="logo" contain />
    <div class="body-container">
      <div align="center">
        <h1>{{ $t('user.new_platform.platform') }} {{ service }}</h1>
        <br />
      </div>
      <template>
        <div id="middle-section" class="middle-section">
          <v-dialog v-model="dialog2" width="1500" height="auto">
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                <v-img :src="require('../../../assets/images/alarms.png')" class="my-3" contain width="12rem" />
              </v-card-title>
              <v-btn color="primary" text @click="dialog2 = false"> X </v-btn>
            </v-card>
          </v-dialog>
          <div class="text-section">
            <h3>{{ $t('user.new_platform.update_credentials') }}</h3>
          </div>
          <div class="input-container">
            <div class="id_div">Su Usuario es: {{ username }}</div>
            <div class="email-container">
              <v-tooltip v-model="info" absolute>
                <span>{{ emailExplanation }}</span>
              </v-tooltip>
              <v-text-field
                dense
                outlined
                required
                class="rounded-lg"
                type="email"
                placeholder="email de contacto"
                v-model="email"
                :rules="emailRules"
                append-outer-icon="mdi-help-circle "
                @click:append-outer="explainEmail">
              </v-text-field>

              <v-alert
                v-if="emailInfoRequired"
                border="top"
                color="deep-purple accent-1"
                prominent
                type="info"
                dark
                v-click-outside="removeEmailAlert">
                {{ emailExplanation }}
              </v-alert>
            </div>
            <br />
            <v-alert
              v-if="invalidInfo"
              border="top"
              color="red lighten-2"
              prominent
              type="error"
              dark
              v-click-outside="removeInfoAlert">
              {{ invalidInfoExplanation }}
            </v-alert>
            <div class="button">
              <v-btn @click="sendUserInfo">Enviar</v-btn>
            </div>
          </div>
          <br />
          <v-snackbar v-model="snackbar" :multi-line="multiLine" color="green lighten-2">
            <v-icon>mdi-emoticon-happy</v-icon> {{ snackbarText }}

            <template v-slot:action="{ attrs }">
              <v-btn color="black" text v-bind="attrs" @click="snackbar = false"> Cerrar </v-btn>
            </template>
          </v-snackbar>
        </div>
      </template>
      <div class="text-center ma-2"></div>
    </div>
    <v-footer :padless="true" fixed>
      <v-card flat tile width="100%" color="#3B444F" class="lighten-1 text-right">
        <v-card-text> </v-card-text>
      </v-card>
    </v-footer>
  </v-container>
</template>

<script>
import { sendUserInfoOldPlatform } from '../../viewModels/userViewModel';
import constants from '../../plugins/lang/constants';

export default {
  data: () => ({
    logo: require(process.env.VUE_APP_LOGO),
    service: process.env.VUE_APP_SERVICE_NAME,
    languages: constants.locales,
    email: '',
    dialog: false,
    dialog2: false,
    dialog3: false,
    emailExplanation: 'El email al que se le envirán las nuevas credenciales',
    accountExplanation: 'El nombre que se utiliza en la plataforma, aparece arriba a la derecha al iniciar sesión.',
    invalidInfoExplanation:
      'La información introducida en el campo "Cuenta en la plataforma" antigua o "email" es incorrecta o incompleta',
    info: false,
    invalidInfo: false,
    emailInfoRequired: false,
    accountInfoRequired: false,
    multiLine: true,
    snackbar: false,
    snackbarText: 'Hemos recibido tu email. En breves nos pondremos en contacto contigo.',
    username: '',
  }),

  mounted() {
    this.decodeBase64();
  },

  methods: {
    decodeBase64() {
      this.username = decodeURIComponent(window.atob(this.$route.params.id));
    },

    explainEmail() {
      this.emailInfoRequired = true;
    },

    explainAccount() {
      this.accountInfoRequired = true;
    },

    async sendUserInfo() {
      if (this.validEmail(this.email)) {
        const data = { email: this.email, username: this.username };
        this.clearFields();
        this.snackbar = !this.snackbar;
        await sendUserInfoOldPlatform(data);
      } else {
        this.invalidInfo = true;
      }
    },

    clearFields() {
      this.email = '';
    },

    validEmail: function (email) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    removeEmailAlert(event) {
      this.emailInfoRequired = false;
    },

    removeAccountAlert() {
      this.accountInfoRequired = false;
    },

    removeInfoAlert() {
      this.invalidInfo = false;
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    emailRules() {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return [
        (value) => {
          if (value) return pattern.test(value) || this.$t('general.error.not_valid_email');
          else return true;
        },
      ];
    },

    accountRules() {
      return [
        (value) => !!value || this.$t('general.error.empty_value'),
        (value) => (value && value.length <= 40) || this.$t('user.atribute.misc.account_rules'),
      ];
    },
  },
};
</script>
