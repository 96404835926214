<template>
  <div v-if="!isMobile">
    <v-container class="my-3">
      <v-row>
        <v-col>
          <h1 class="d-flex justify-start">{{ $t('shared.customer') }}</h1>
          <h3
            v-if="maxClients"
            :style="{
              color: theme === 'dark' ? 'white !important' : '',
            }"
            class="d-flex justify-start">
            {{ $t('shared.maxCustomers') }}
          </h3>
        </v-col>
        <v-col>
          <v-btn
            v-if="maxClients"
            :style="{
              color: theme === 'dark' ? 'white !important' : '',
              'background-color': theme === 'dark' ? '#ffffff1a !important' : '',
            }"
            disabled
            @click="openCreate"
            class="float-right"
            color="primary"
            ><v-icon class="mr-3">mdi-account-plus</v-icon>{{ $t('shared.create') }}</v-btn
          >
          <v-btn v-else @click="openCreate" class="float-right" color="primary"
            ><v-icon class="mr-3">mdi-account-plus</v-icon>{{ $t('shared.create') }}</v-btn
          >
          <v-btn
            disabled
            v-if="maxClients"
            :style="{
              color: theme === 'dark' ? 'white !important' : '',
              'background-color': theme === 'dark' ? '#ffffff1a !important' : '',
            }"
            @click="openAdd"
            class="float-right mr-3"
            color="primary"
            ><v-icon class="mr-3">mdi-account-box-multiple</v-icon>{{ $t('shared.add') }}</v-btn
          >
          <v-btn v-else data-cy="addsClient" @click="openAdd" class="float-right mr-3" color="primary"
            ><v-icon class="mr-3">mdi-account-box-multiple</v-icon>{{ $t('shared.add') }}</v-btn
          >
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-data-table
        :loading="isTableLoading"
        :headers="clientsTableHeader"
        :items="clientTable"
        hide-default-footer
        :page.sync="page"
        @page-count="pageCount = $event"
        :items-per-page="itemsPerPage"
        class="shared-data-table elevation-1"
        :search="search"
        v-bind:loading-text="$t('general.table.loading')">
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon data-cy="delete" medium class="mr-1" color="error" @click.stop="deleteRow(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:top>
          <v-text-field
            style="width: 100%"
            v-model="search"
            :label="$t('shared.search_customer')"
            prepend-inner-icon=" "></v-text-field>
        </template>
        <div class="text-center pt-2">
          <v-pagination :dark="theme === 'dark'" v-model="page" :length="pageCount"></v-pagination>
        </div>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination :dark="theme === 'dark'" v-model="page" :length="pageCount"></v-pagination>
      </div>
    </v-container>
    <Confirm ref="confirm"></Confirm>
    <CreateCustomer :key="update + 'A'" :isActive="create" v-on:cancel-create="closeCreate"></CreateCustomer>
    <AddCustomer :key="update2 + 'C'" :isActive="add" v-on:cancel-add="closeAdd"></AddCustomer>
  </div>

  <!-- Mobile View -->

  <div v-else>
    <v-container class="my-3 shared-cutomer-mobile">
      <v-row class="shared-cutomer-row">
        <v-col class="shared-cutomer-col">
          <h1 class="d-flex justify-start">{{ $t('shared.customer') }}</h1>
          <h3 v-if="maxClients" class="d-flex justify-start">{{ $t('shared.maxCustomers') }}</h3>
        </v-col>

        <v-col class="shared-cutomer-col shared-cutomer-col-btns">
          <div>
            <v-btn v-if="maxClients" disabled @click="openCreate" class="float-right mr-3" color="primary">
              <v-icon class="mr-3">mdi-account-plus</v-icon>
              {{ $t('shared.customer_create') }}
            </v-btn>
            <v-btn v-else @click="openCreate" class="float-right mr-3" color="primary">
              <v-icon class="mr-3">mdi-account-plus</v-icon>
              {{ $t('shared.customer_create') }}
            </v-btn>
          </div>

          <div>
            <v-btn disabled v-if="maxClients" @click="openAdd" class="float-right mr-3 mb-3" color="primary">
              <v-icon class="mr-3">mdi-account-box-multiple</v-icon>
              {{ $t('shared.add') }}
            </v-btn>
            <v-btn v-else @click="openAdd" class="float-right mr-3 mb-3" color="primary">
              <v-icon class="mr-3">mdi-account-box-multiple</v-icon>
              {{ $t('shared.add') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-data-table
        :loading="isTableLoading"
        :headers="clientsTableHeader"
        :items="clientTable"
        hide-default-footer
        :page.sync="page"
        @page-count="pageCount = $event"
        :items-per-page="itemsPerPage"
        class="elevation-1"
        :search="search"
        v-bind:loading-text="$t('general.table.loading')">
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-1" color="error" @click.stop="deleteRow(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:top>
          <v-text-field
            style="width: 100%"
            v-model="search"
            :label="$t('shared.search_customer')"
            prepend-inner-icon=" "></v-text-field>
        </template>
        <div id="pagination" class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </div>
      </v-data-table>
    </v-container>
    <Confirm ref="confirm"></Confirm>
    <CreateCustomer :key="update + 'B'" :isActive="create" v-on:cancel-create="closeCreate"></CreateCustomer>
    <AddCustomer :key="update2 + 'D'" :isActive="add" v-on:cancel-add="closeAdd"></AddCustomer>
  </div>
</template>

<script>
import Confirm from '../../../components/confirm.vue';
import CreateCustomer from '../../../components/shared/createCustomer.vue';
import AddCustomer from '../../../components/shared/addCustomer/addCustomer.vue';
import { getCustomers } from '../../../viewModels/clientViewModel';
import { deleteCustomers } from '../../../viewModels/clientViewModel';
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';
import { getUser } from '../../../viewModels/userViewModel';

export default {
  components: {
    CreateCustomer,
    AddCustomer,
    Confirm,
  },
  data: () => ({
    theme: ls.get('theme') ?? 'ligth',
    clientTable: [],
    search: '',
    create: false,
    add: false,
    update: 0,
    update2: 1,
    isTableLoading: true,
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    user: null,
  }),
  methods: {
    openCreate() {
      this.create = true;
    },
    closeCreate: function () {
      this.create = !this.create;
      this.update++;
    },
    openAdd() {
      this.add = true;
    },
    closeAdd: function () {
      this.add = !this.add;

      this.update2++;
    },
    deleteRow: async function (item) {
      if (
        await this.$refs.confirm.open(this.$t('general.form.delete-client'), this.$t('general.form.delete-customer'), {
          color: 'red',
        })
      ) {
        let indexToDelete = this.clientTable.indexOf(item);
        this.clientTable.splice(indexToDelete, 1);
        let res = await deleteCustomers(item.email);
      }
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    maxClients() {
      if (this.clientTable.length >= this.user?.restrictions?.features?.max_clients && this.clientTable.length > 0) return true;
    },

    clientsTableHeader() {
      return [
        {
          text: this.$t('shared.name'),
          value: 'name',
          width: '7rem',
          divider: true,
        },
        {
          text: 'E-mail',
          value: 'email',
          width: '5rem',
          divider: true,
        },
        {
          text: '',
          value: 'actions',
          width: '3rem',
          divider: true,
          sortable: false,
        },
      ];
    },

    getClients: async function () {
      try {
        this.res = await getCustomers();
        this.clientTable = this.res;
        this.isTableLoading = false;
      } catch (e) {
        console.error(e);
      }
    },
  },
  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
    this.user = await getUser();

    this.getClients;
  },
};
</script>
