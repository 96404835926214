const constants = {
  default_locale: 'es',
  locales: [
    {
      icon_code: 'es',
      code: 'es',
      name: 'Español',
    },
    {
      icon_code: 'gb',
      code: 'gb',
      name: 'English',
    },
    {
      icon_code: 'fr',
      code: 'fr',
      name: 'Français',
    },
    {
      icon_code: 'de',
      code: 'de',
      name: 'Deutsch',
    },
    {
      icon_code: 'pt',
      code: 'pt',
      name: 'Portuguese',
    },
    {
      icon_code: 'it',
      code: 'it',
      name: 'Italian',
    },
  ],
};
// https://www.npmjs.com/package/vue-country-flag

export default constants;
