<template>
  <v-card
    :style="{ backgroundColor: backgroundColorTheme }"
    id="bg"
    v-click-outside="closeCard"
    class="mx-auto"
    max-height="20.5rem">
    <div class="features">
      <v-btn
        v-if="device.last_frames.frame_frigo"
        small
        class="justify-end"
        :style="{ color: theme === 'dark' ? 'white !important' : 'black' }"
        text
        @click="showTemperatureInfo"
        >{{ $t('nav_bar.menu.temps') }}</v-btn
      >
      <v-btn
        small
        class="justify-end"
        :style="{ color: theme === 'dark' ? 'white !important' : 'black' }"
        text
        @click="showRouteInfo"
        >{{ $t('nav_bar.menu.position') }}</v-btn
      >

      <v-btn
        small
        class="justify-center"
        :style="{ color: theme === 'dark' ? 'white !important' : 'black' }"
        text
        @click="closeCard"
        >x</v-btn
      >
    </div>
    <v-card-text>
      <h1
        class="mb-1"
        v-if="!isCarplateLong"
        :style="{
          color: theme === 'dark' ? 'white !important' : '',
          textAlign: 'center',
        }">
        {{ carplate }}
      </h1>
      <h2
        class="mb-1"
        v-if="isCarplateLong"
        :style="{
          color: theme === 'dark' ? 'white !important' : '',
          textAlign: 'center',
        }">
        {{ carplate }}
      </h2>

      <h5 :style="{ color: theme === 'dark' ? 'grey !important' : '' }" class="features">
        <b> s/n: {{ device.device_sn }}</b>
        <!-- <b>{{ $t('device.mousecard.model') }}: {{ capModelName(device.model) }}</b> -->
        <b v-if="isBeacon">{{ $t('admin.menu.tractorhead') }}: {{ alias }}</b>
      </h5>
      <h5
        :class="locationClass"
        v-if="(route && device.last_frames.frame_gps) || (device.last_frames.frame_gps && !device.last_frames.frame_frigo)">
        <b>
          <v-icon :class="locationClass" color="green" small left> mdi-map-marker </v-icon
          >{{ device.last_frames.frame_gps.geocode ? device.last_frames.frame_gps.geocode : $t('device.mousecard.No_info') }}
        </b>
      </h5>

      <div v-if="temperature && device.last_frames.frame_frigo">
        <v-tooltip top v-if="device.last_frames.frame_frigo.probes_temperature">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-if="device.last_frames.frame_frigo.probes_temperature.p1"
              class="ma-2"
              color="indigo darken-3"
              text-color="white"
              v-bind="attrs"
              v-on="on">
              <v-icon v-if="device.device_data_from_frame.probes_type.p1 == 'hum'"> mdi-water-percent</v-icon>
              <v-icon v-if="device.device_data_from_frame.probes_type.p1 == 'airq'"> mdi-weather-windy</v-icon>
              <v-icon v-if="device.device_data_from_frame.probes_type.p1 == 'temp'"> mdi-thermometer-lines</v-icon>
              <b>
                {{
                  device.last_frames.frame_frigo.probes_temperature.p1
                    ? device.last_frames.frame_frigo.probes_temperature.p1.toFixed(1)
                    : ''
                }}
              </b>
              <v-icon v-if="device.device_data_from_frame.probes_type.p1 == 'hum'"> mdi-percent</v-icon>
              <b class="ml-1" v-if="device.device_data_from_frame.probes_type.p1 == 'airq'"> AQ</b>
              <v-icon v-if="device.device_data_from_frame.probes_type.p1 == 'temp'"> mdi-temperature-celsius</v-icon>
            </v-chip>
          </template>
          <span>
            {{
              !device.probes_config
                ? $t('device.mousecard.probe1')
                : device.probes_config.p1
                ? device.probes_config.p1.name
                : $t('device.mousecard.probe1')
            }}
          </span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              class="ma-2"
              v-if="device.last_frames.frame_frigo.probes_temperature.p2"
              color="indigo darken-3"
              text-color="white"
              v-bind="attrs"
              v-on="on">
              <v-icon v-if="device.device_data_from_frame.probes_type.p2 == 'hum'"> mdi-water-percent</v-icon>
              <v-icon v-if="device.device_data_from_frame.probes_type.p2 == 'airq'"> mdi-weather-windy</v-icon>
              <v-icon v-if="device.device_data_from_frame.probes_type.p2 == 'temp'"> mdi-thermometer-lines</v-icon>
              <b>
                {{
                  device.last_frames.frame_frigo.probes_temperature.p2
                    ? device.last_frames.frame_frigo.probes_temperature.p2.toFixed(1)
                    : ''
                }}</b
              >
              <v-icon v-if="device.device_data_from_frame.probes_type.p2 == 'hum'"> mdi-percent</v-icon>
              <b class="ml-1" v-if="device.device_data_from_frame.probes_type.p2 == 'airq'"> AQ</b>
              <v-icon v-if="device.device_data_from_frame.probes_type.p2 == 'temp'"> mdi-temperature-celsius</v-icon>
            </v-chip>
          </template>
          <span>
            {{
              !device.probes_config
                ? $t('device.mousecard.probe2')
                : device.probes_config.p2
                ? device.probes_config.p2.name
                : $t('device.mousecard.probe2')
            }}
          </span>
        </v-tooltip>
        <div>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="ma-2"
                v-if="device.last_frames.frame_frigo.probes_temperature.p3"
                color="indigo darken-3"
                text-color="white"
                v-bind="attrs"
                v-on="on">
                <v-icon v-if="device.device_data_from_frame.probes_type.p3 == 'hum'"> mdi-water-percent</v-icon>
                <v-icon v-if="device.device_data_from_frame.probes_type.p3 == 'airq'"> mdi-weather-windy</v-icon>
                <v-icon v-if="device.device_data_from_frame.probes_type.p3 == 'temp'"> mdi-thermometer-lines</v-icon>
                <b>
                  {{
                    device.last_frames.frame_frigo.probes_temperature.p3
                      ? device.last_frames.frame_frigo.probes_temperature.p3.toFixed(1)
                      : ''
                  }}</b
                >
                <v-icon v-if="device.device_data_from_frame.probes_type.p3 == 'hum'"> mdi-percent</v-icon>
                <b class="ml-1" v-if="device.device_data_from_frame.probes_type.p3 == 'airq'"> AQ</b>
                <v-icon v-if="device.device_data_from_frame.probes_type.p3 == 'temp'"> mdi-temperature-celsius</v-icon>
              </v-chip>
            </template>
            <span>
              {{
                !device.probes_config
                  ? $t('device.mousecard.probe3')
                  : device.probes_config.p3
                  ? device.probes_config.p3.name
                  : $t('device.mousecard.probe3')
              }}
            </span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="ma-2"
                v-if="device.last_frames.frame_frigo.probes_temperature.p4"
                color="indigo darken-3"
                text-color="white"
                v-bind="attrs"
                v-on="on">
                <v-icon v-if="device.device_data_from_frame.probes_type.p4 == 'hum'"> mdi-water-percent</v-icon>
                <v-icon v-if="device.device_data_from_frame.probes_type.p4 == 'airq'"> mdi-weather-windy</v-icon>
                <v-icon v-if="device.device_data_from_frame.probes_type.p4 == 'temp'"> mdi-thermometer-lines</v-icon>
                <b>
                  {{
                    device.last_frames.frame_frigo.probes_temperature.p4
                      ? device.last_frames.frame_frigo.probes_temperature.p4.toFixed(1)
                      : ''
                  }}</b
                >
                <v-icon v-if="device.device_data_from_frame.probes_type.p4 == 'hum'"> mdi-percent</v-icon>
                <b class="ml-1" v-if="device.device_data_from_frame.probes_type.p4 == 'airq'"> AQ</b>
                <v-icon v-if="device.device_data_from_frame.probes_type.p4 == 'temp'"> mdi-temperature-celsius</v-icon>
              </v-chip>
            </template>
            <span>
              {{
                !device.probes_config
                  ? $t('device.mousecard.probe4')
                  : device.probes_config.p4
                  ? device.probes_config.p4.name
                  : $t('device.mousecard.probe4')
              }}
            </span>
          </v-tooltip>
        </div>
      </div>

      <div
        v-if="(route && device.last_frames.frame_gps) || (device.last_frames.frame_gps && !device.last_frames.frame_frigo)"
        style="display: flex; justify-content: space-evenly">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-chip class="ma-2" color="indigo darken-3" text-color="white" v-bind="attrs" v-on="on">
              <v-icon left>mdi-road </v-icon>
              <b>
                {{
                  device.last_frames.frame_gps.odometer.offset_applied
                    ? `${device.last_frames.frame_gps.odometer.offset_applied.toFixed(0)} km`
                    : $t('device.mousecard.not_available')
                }}
              </b>
            </v-chip>
          </template>
          <span> {{ $t('device.mousecard.odometer') }}</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-chip class="ma-2" color="indigo darken-3" text-color="white" v-bind="attrs" v-on="on">
              <v-icon left>mdi-speedometer </v-icon>
              <b> {{ device.last_frames.frame_gps.speed.toFixed(0) }} km/h. </b>
            </v-chip>
          </template>
          <span> {{ $t('device.mousecard.speed') }}</span>
        </v-tooltip>
        <br />
        <h5 v-if="isOdometreBadSet" class="infoKm">
          <v-icon class="warning">mdi-information-variant</v-icon>{{ this.$t('dashboard.mouseover.odometer_bad_set_1') }}
        </h5>
        <h5 v-if="isOdometreBadSet" class="infoKm">
          {{ this.$t('dashboard.mouseover.odometer_bad_set_2') }}
        </h5>
      </div>
    </v-card-text>
    <v-card-actions class="d-flex flex-column mb-6">
      <div v-if="temperature && device.last_frames.frame_frigo">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              :style="{ 'border-radius': '0', 'text-transform': 'uppercase' }"
              @click="getTicket"
              class="ma-2"
              color="primary"
              text-color="white"
              v-bind="attrs"
              v-on="on"
              :disabled="routeLoading"
              :loading="routeLoading">
              <v-icon left>mdi-script-text-outline </v-icon>
              <b>
                {{ $t('device.mousecard.ticket') }}
              </b>
            </v-chip>
          </template>
          <span> {{ $t('device.mousecard.print_temperature_ticket') }}</span>
        </v-tooltip>
      </div>

      <div v-if="route || !device.last_frames.frame_frigo" style="display: flex">
        <v-tooltip>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              :style="{ 'border-radius': '0', 'text-transform': 'uppercase' }"
              @click="showRoute"
              color="primary"
              class="ma-2"
              text-color="white"
              v-bind="attrs"
              v-on="on"
              :disabled="routeLoading"
              :loading="routeLoading">
              <v-icon left>mdi-road-variant </v-icon>
              <b>
                {{ $t('device.mousecard.show_route') }}
              </b>
            </v-chip>
          </template>
        </v-tooltip>
        <v-tooltip>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              :style="{ 'border-radius': '0', 'text-transform': 'uppercase' }"
              @click="newPOI"
              color="primary"
              class="ma-2"
              text-color="white"
              v-bind="attrs"
              v-on="on">
              <v-icon left>mdi-plus </v-icon>
              <b>
                {{ $t('device.mousecard.new_pdi') }}
              </b>
            </v-chip>
          </template>
        </v-tooltip>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
//cambiar color de geolocalización según estado del vehículo.
import { eventBus } from '../../../../main';
import { getFramesDevice } from '../../../../viewModels/devicesViewModel';
import { getToken, getUser } from '../../../../viewModels/userViewModel';
import { omit } from '../../../../utils/utils';
import { postPOI } from '../../../../server/petitions/pois/postPOI';
import { getBeacon } from '../../../../viewModels/adminViewModel';

const ls = require('localstorage-slim');

export default {
  data() {
    return {
      token: '',
      currentUser: {},
      editedPOI: {
        name: '',
        address: this.device.last_frames.frame_gps.geocode
          ? this.device.last_frames.frame_gps.geocode
          : $t('device.mousecard.No_info'),
        latitude: this.device.last_frames.frame_gps.latitude
          ? this.device.last_frames.frame_gps.latitude
          : $t('device.mousecard.No_info'),
        longitude: this.device.last_frames.frame_gps.longitude
          ? this.device.last_frames.frame_gps.longitude
          : $t('device.mousecard.No_info'),
        radius: 50,
      },
      emptyCategory: {
        icon: '/assets/markers/pi.png',
      },
      hasBeenPoiToggleOpened: false,
      isPoiToggleOpen: false,
      backgroundColorTheme: ls.get('theme') === 'dark' ? 'black !important' : '',
      theme: ls.get('theme') ?? 'ligth',
      user: null,
      show: true,
      temperature: true,
      route: false,
      locationClass: '',
      scopeTarget: '',
      carplate: '',
      routeLoading: false,
      isBeacon: false,
      alias: '',
      speed: 15,
      beacon: null,
      beaconWithAlias: null,
      minutesDeducted: 3 * 60000,
    };
  },

  props: ['device'],

  mounted: async function () {
    this.token = getToken();
    this.currentUser = this.$store.state.currentUser ?? (await getUser());

    eventBus.$on('closeNewPoiDataForm', () => {
      setTimeout(() => {
        this.isPoiToggleOpen = false;
      }, 500);
    });
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
      this.colorTheme = theme === 'dark' ? 'white !important' : '';
    });
    let target = document.getElementById('bg');
    if (target) {
      this.scopeTarget = target;
      this.scopeTarget.addEventListener('mousedown', this.onGrab);
    }
    this.updateMovementMapColors();
    this.user = this.$store.state.currentUser;

    this.checkBeacon();
    this.getCarPlate;
  },

  beforeUpdate: async function () {
    this.user = this.$store.state.currentUser;
    this.getCarPlate;
    this.updateMovementMapColors();
  },

  methods: {
    newPOI() {
      this.closeCard();
      eventBus.$emit('newPOIFromMouseOverCard');
      eventBus.$emit('updatePOI', this.editedPOI, true);
    },
    updateMovementMapColors() {
      let actualTime = new Date().getTime();
      let frameTime = new Date(this.device.last_frames.frame_gps.gps_timestamp).getTime();

      if (actualTime - frameTime > 3600000) {
        this.locationClass = 'grey--text';
      } else if (actualTime - frameTime > 1200000) {
        this.locationClass = 'orange--text';
      } else {
        this.locationClass = 'green--text';
      }
    },

    getTicket() {
      eventBus.$emit('ticket', this.device);
    },

    closeCard() {
      if (this.hasBeenPoiToggleOpened && this.isPoiToggleOpen) return;
      if (this.hasBeenPoiToggleOpened && !this.isPoiToggleOpen) {
        this.$store.dispatch('setMouseOver', false);
        this.hasBeenPoiToggleOpened = false;
      }
      if (!this.hasBeenPoiToggleOpened) this.$store.dispatch('setMouseOver', false);
    },

    showTemperatureInfo() {
      (this.temperature = true), (this.route = false);
    },

    showRouteInfo() {
      this.route = true;
      this.temperature = false;
    },

    showRoute: async function () {
      // test
      let serialNumber = this.device.device_sn;

      this.routeLoading = true;
      let dates = [new Date(new Date().setHours(0, 0, 0, 0)), new Date(new Date().setHours(23, 59, 0, 0))];
      let locations = await getFramesDevice(serialNumber, dates);

      this.routeLoading = false;
      this.$emit('onDrawRoute', locations, this.device, dates);
      this.closeCard();
    },
    capModelName(model) {
      return model[0].toUpperCase() + model.toLowerCase().slice(1);
    },

    onDrag(e) {
      let originalStyles = window.getComputedStyle(this.scopeTarget);
      this.scopeTarget.style.left = parseInt(originalStyles.left) + e.movementX + 'px';
      this.scopeTarget.style.top = parseInt(originalStyles.top) + e.movementY + 'px';
    },

    onLetGo() {
      document.removeEventListener('mousemove', this.onDrag);
      document.removeEventListener('mouseup', this.onLetGo);
    },

    onGrab() {
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.onLetGo);
    },
    async checkBeacon() {
      this.beacon = this.device.last_frames.frame_status?.beacons?.beacon1 ?? null;
      //To get Alias Beacon
      if (this.beacon?.macAddress) {
        this.beaconWithAlias = await getBeacon(this.device.last_frames.frame_status?.beacons?.beacon1?.macAddress);
      }

      const lastFrameTimestamp = this.device.last_frames.frame_status?.device_timestamp;
      const beaconDeviceTimestamp = this.beacon?.device_timestamp;

      this.isBeacon = false;
      const timeCondition =
        new Date(beaconDeviceTimestamp).getTime() >= new Date(lastFrameTimestamp).getTime() - this.minutesDeducted;

      const speedCondition = this.device.last_frames.frame_gps?.speed >= this.speed;

      //Esto debería ser siempre cuando se crea el dispositivo un array vacio.
      if (this.user.beacons?.length > 0) {
        this.user.beacons.map((i) => {
          if (i.mac_address == this.beacon?.macAddress && timeCondition && speedCondition) {
            this.isBeacon = true;
            this.alias = this.beaconWithAlias?.alias ?? this.beaconWithAlias?.mac_address;
          }
        });
      }
    },
  },
  computed: {
    getCarPlate() {
      for (let userDevice of this.user.devices) {
        if (userDevice.device_id == this.device.device_id) {
          this.carplate = userDevice.name || this.device.device_data_from_frame.carplate || this.device.device_sn;
        }
      }
    },
    isOdometreBadSet() {
      if (this.device) {
        if (this.device.last_frames.frame_gps.odometer.offset_applied) {
          return this.device.last_frames.frame_gps.odometer.offset_applied.toFixed(0) > 1250000;
        }
      }
    },
    isCarplateLong() {
      if (this.device.device_data_from_frame.carplate) {
        return this.device.device_data_from_frame.carplate.length >= 15;
      }
      return false;
    },
  },
  updated() {
    this.checkBeacon();
  },
};
</script>

<style>
.infoKm {
  color: black;
}

.features {
  display: flex;
  justify-content: space-between;
  color: black;
}

#bg {
  position: relative;
  color: black;
}

#bg::before {
  content: '';
  background-size: cover;
  /* background-color: white; */
  transform: scale(1.1);
  opacity: 0.3;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  min-width: 100%;
  max-width: 100%;
}
h1 {
  position: relative;
  color: black;
  min-width: 100%;
  max-width: 100%;
}

h2 {
  position: relative;
  color: black;
  min-width: 100%;
  max-width: 100%;
}
</style>
