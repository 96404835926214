const PetitionError = require('../../petitionError');
const { getAnonymous } = require('../../httpRequester');

class getDailyDataPetitionException extends PetitionError {
  constructor(code) {
    super(' Get locations today: ', code);
  }
}

async function getDailyDataPetition(id) {
  const response = await getAnonymous(`matooma/get_daily_data/${id}`);
  // No es anónima, uso el token, pero así puedo mandarle por query el id también.

  if (response.result !== getDailyDataPetitionException.success) {
    throw (new getDailyDataPetitionException(response.result));
  } else {
    return response.data; // TO DO transform
  }
}

// class getDailyDataPetitionException extends PetitionError {
//   constructor(code) {
//     super(' Get locations today: ', code);
//   }
// }

module.exports = {
  getDailyDataPetition,
  getDailyDataPetitionException,
};
