const PetitionError = require('../../petitionError');
const { getAnonymous } = require('../../httpRequester');

class GetStartOfTheDayEngineHoursException extends PetitionError {
  constructor(code) {
    super('Get Start Of The Day Engine Hours', code);
  }
}

async function getStartOfTheDayEngineHours(device_sn, token) {
  //muestra en que zona horaria te encuentras
  const dateTimeFormat = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const response = await getAnonymous(
    `devices/get_start_of_the_day_engine_hours?device_sn=${device_sn}&token=${token}&dateTimeFormat=${dateTimeFormat}`,
  );
  // No es anónima, uso el token, pero así puedo mandarle por query el id también.

  if (response.result !== GetStartOfTheDayEngineHoursException.success) {
    throw new GetStartOfTheDayEngineHoursException(response.result);
  } else {
    return response.hours; // TO DO transform
  }
}

// class GetStartOfTheDayEngineHoursException extends PetitionError {
//   constructor(code) {
//     super('Get Start Of The Day Engine Hours', code);
//   }
// }

module.exports = {
  getStartOfTheDayEngineHours,
  GetStartOfTheDayEngineHoursException,
};
