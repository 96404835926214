<template>
  <div class="notifications-div">
    <v-card class="mx-auto pad-class elevation-1" style="overflow: auto">
      <v-toolbar color="rgb(9, 208, 126)" dark>
        <v-toolbar-title>{{ $t('nav_bar.notifications') }}</v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>

      <v-list>
        <v-list-item v-model="selected" style="display: flex; flex-direction: column">
          <template v-for="(group, groupIndex) in groupedNotifications">
            <v-list v-if="group.length > 0" style="width: 100%">
              <v-list-group>
                <template v-slot:activator>
                  <v-list-item-title>
                    {{ $t('shared.sn') }} {{ group[0]?.device_sn }} - {{ group[0]?.carplate }} - {{ group.length }}
                    {{ $t('nav_bar.notifications') }}
                  </v-list-item-title>
                </template>

                <v-list-item v-model="groupedNotifications" v-for="(item, itemIndex) in group" :key="item._id">
                  <template v-if="item.state !== 'deleted'" v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-col cols="12" sm="12" md="6">
                        <v-list-item-subtitle class="subtitle-dark"
                          >{{ item.device_sn }} - {{ item.carplate }}</v-list-item-subtitle
                        >
                      </v-col>

                      <v-col cols="12" sm="12" md="4">
                        <v-list-item-subtitle class="subtitle-dark">{{ item.date }}</v-list-item-subtitle>
                      </v-col>

                      <v-list-item-title v-text="item.type"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="icon-line">
                      <v-list-item-action-text v-text="item.action"></v-list-item-action-text>
                      <v-icon
                        v-if="item.state === 'read'"
                        medium
                        class="mr-1"
                        color="green"
                        @click="addToUnreadItem(item)"
                        :title="$t('notifications.read')"
                        >mdi-email-open</v-icon
                      >
                      <v-icon
                        v-if="item.state === 'unread' || item.state == 'pending'"
                        medium
                        class="mr-1"
                        color="blue"
                        @click="addToReadItem(item)"
                        :title="$t('notifications.pending')"
                        >mdi-email</v-icon
                      >
                      <v-icon medium class="mr-1" color="error" @click="deleteItem(item)" :title="$t('notifications.delete')"
                        >mdi-email-remove</v-icon
                      >
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-list-group>

              <v-divider
                class="divider-line"
                v-if="groupIndex < groupedNotifications.length - 1"
                :key="`divider-${groupIndex}`"></v-divider>
            </v-list>
            <div v-show="HaveGroup">----{{ group[0] }}----</div>
          </template>
        </v-list-item>
      </v-list>
    </v-card>
    <Confirm ref="confirm"></Confirm>
  </div>
</template>

<script>
import { getUserNotificationsBySn, updateNotification } from '../../viewModels/notificationsViewModel';
import Confirm from '../../components/confirm.vue';
import { getUserDevices } from '../../server/petitions/users/getUserDevices';
import { getUser } from '../../viewModels/userViewModel';
import { getUpdatedNotificationsBySn } from '../../server/petitionsMqtt/notifications/updateNotifications';
import { eventBus } from '../../main';
import { notificationController } from './handlerNotifications';

export default {
  data() {
    return {
      removedNotifications: new Set(),
      HaveGroup: false,
      selected: [],
      headers: [
        {
          align: 'start',
          sortable: false,
        },
      ],
      notifications: [],

      groupSn: [],
      deviceCollection: null,
    };
  },

  mounted: async function () {
    const notificationsBrought = await getUserNotificationsBySn();
    this.notifications = notificationsBrought.notifications;

    //RECIBE-EVENTBUS
    eventBus.$on('removeAllNotifications', async () => {
      const notificationsCopy = [...this.notifications];

      const updatePromises = [];

      for (let i = 0; i < notificationsCopy.length; i++) {
        const notification = notificationsCopy[i];

        if (notification.state !== 'pending') {
          notification.state = 'deleted';

          const indexToDelete = this.notifications.indexOf(notification);

          this.notifications.splice(indexToDelete, 1);
          const updatePromise = updateNotification(notification._id, notification.state);
          updatePromises.push(updatePromise);
        }
      }
      await Promise.all(updatePromises);
    });

    const user = await getUser();
    this.deviceCollection = await getUserDevices(user.token, user.userId);
    this.notifications.map(async (i) => {
      if (i.state == 'unread' || i.state == 'pending') {
        notificationController(i, this.deviceCollection, this.groupSn);
      }

      await updateNotification(i._id, i.state);
    });
  },
  updated: async function () {
    await getUserNotificationsBySn();
    const isSuscribed = false;
    this.deviceCollection.map(async (i) => {
      await getUpdatedNotificationsBySn(i.device_sn, isSuscribed, (notification) => {
        const foundNotification = this.notifications.find((notific) => notific._id == notification._id);
        if (!foundNotification) {
          this.notifications.unshift(notification);
        }
        if (notification.state == 'unread' || notification.state == 'pending') {
          notificationController(notification, this.deviceCollection, this.groupSn);
        }
      });
    });
  },

  methods: {
    deleteItem: async function (item) {
      if (
        await this.$refs.confirm.open('Delete', this.$t('general.form.deleteNotification'), {
          color: 'red',
        })
      ) {
        this.removedNotifications.add(`${item.date}-${item.type}-${item.device_sn}`);

        item.state = 'deleted';
        await updateNotification(item._id, item.state);

        const indexToDelete = this.notifications.indexOf(item);

        this.notifications.splice(indexToDelete, 1);
        eventBus.$emit('removeOneNotification');

        if (item.state == 'deleted') {
          notificationController(item, this.deviceCollection, this.groupSn);
        }
      }
    },
    addToUnreadItem: async function (notification) {
      notification.state = 'pending';

      await updateNotification(notification._id, notification.state);
    },
    addToReadItem: async function (item) {
      item.state = 'read';

      await updateNotification(item._id, item.state);
    },
  },

  computed: {
    groupedNotifications() {
      const groups = {};

      this.notifications.forEach((item) => {
        if (!groups[item.device_sn]) {
          groups[item.device_sn] = [];
        }

        const hasSameDate = groups[item.device_sn].some(
          (existingItem) => existingItem.date === item.date && existingItem.type === item.type,
        );

        if (!hasSameDate && !this.removedNotifications.has(`${item.date}-${item.type}-${item.device_sn}`)) {
          groups[item.device_sn].push(item);
        }
      });

      return Object.values(groups);
    },
  },
  components: { Confirm },
};
</script>
