const mainUrl = process.env.VUE_APP_MAINURL ? process.env.VUE_APP_MAINURL : 'https://server.termografoapache.com:3000/'; // production
const matoomaUrl = 'https://api.matooma.com/';
const base64AUTH = 'SW1FS1FydGR3Yzl3WlhMZzVrcHpwT2tqYl9RYTp2bkV0RmtaR0tfZzFwbDhJRmF3aVdwbDhMcTRh';
let token = '4180e03a-3b0d-344e-b38a-d02d2ce1befe';

export async function getAnonymous(link) {
  const url = new URL(mainUrl + link);

  const response = await fetch(url);
  const result = await response.json();
  return result;
}

export async function postAnonymous(link, payload) {
  const url = new URL(mainUrl + link);

  const postSettings = {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(url, postSettings);
  const result = await response.json();

  return result;
}

export async function getMatoomaToken() {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Basic ${base64AUTH}`);
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
  myHeaders.append('Cookie', 'cookiesession1=678A3E17WXYZABCDEFGHIJKLMNPQ1A26');
  myHeaders.append('Access-Control-Allow-Origin', window.location.origin);
  myHeaders.append('Accept', 'application/json');

  const urlencoded = new URLSearchParams();
  urlencoded.append('grant_type', 'client_credentials');

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow',
  };
  const response = await fetch(`${matoomaUrl}token`, requestOptions);
  const respons = await response.text();
  const result = JSON.parse(respons);
  token = result.access_token;

  return token;
}

export async function getDailyConsumtion() {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Cookie', 'cookiesession1=678A3E17WXYZABCDEFGHIJKLMNPQ1A26');
  myHeaders.append('Accept', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    `${matoomaUrl}v1/consumptions/daily/8931088918023813609?start=2020-07-01&end=2021-07-01`,
    requestOptions,
  );
  const respons = await response.text();
  let result = JSON.parse(respons);
  if (result.fault) {
    if (result.fault.code === 900901) {
      await getMatoomaToken();
      result = getDailyConsumtion();
    }
  }

  return result;
}

export async function get(link, token) {
  const url = new URL(mainUrl + link);
  const params = { token };
  url.search = new URLSearchParams(params).toString();

  const response = await fetch(url);
  const result = await response.json();
  return result;
}

export async function post(link, payload, token) {
  const url = new URL(mainUrl + link);
  const params = { token };
  url.search = new URLSearchParams(params).toString();

  const postSettings = {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(url, postSettings);
  const result = await response.json();
  return result;
}

export async function put(link, payload, token) {
  const url = new URL(mainUrl + link);
  const params = { token };
  url.search = new URLSearchParams(params).toString();

  const postSettings = {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(url, postSettings);
  const result = await response.json();
  return result;
}

export async function del(link, payload, token) {
  const url = new URL(mainUrl + link);
  const params = { token };

  url.search = new URLSearchParams(params).toString();

  const deleteSettings = {
    method: 'DELETE',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(url, deleteSettings);
  const result = await response.json();
  return result;
}

export async function delAnonymous(link, payload) {
  const url = new URL(mainUrl + link);

  const postSettings = {
    method: 'DELETE',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(url, postSettings);
  const result = await response.json();

  return result;
}

export async function delRaw(link, token) {
  const url = new URL(mainUrl + link);
  const params = { token };

  url.search = new URLSearchParams(params).toString();

  const deleteSettings = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(url, deleteSettings);

  return response;
}

export async function externalGet(externalUrl) {
  let result = null;
  try {
    const response = await fetch(externalUrl);
    result = await response.json();
  } catch (e) {
    console.log(e);
  }

  return result;
}

export async function postRaw(link, payload, token) {
  const url = new URL(mainUrl + link);
  const params = { token };
  url.search = new URLSearchParams(params).toString();

  const postSettings = {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(url, postSettings);

  return response;
}

export async function getRaw(link, token) {
  const url = new URL(mainUrl + link);
  const params = { token };
  url.search = new URLSearchParams(params).toString();

  const getSettings = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(url, getSettings);

  return response;
}
