<template>
  <v-container ref="deviceForm" class="shared-container-desktop pa-2">
    <div style="width: 100%" id="device-data-form-container">
      <v-card-title color="primary" class="ticket-view-modal-title headline" style="justify-content: center">
        <span class="ticket-view-modal-title-text" :style="{ color: theme === 'dark' ? 'white' : '' }">
          {{ $t('dashboard.device_card.certificates').toUpperCase() }}
        </span>
      </v-card-title>
      <div class="device-data-form-table">
        <v-spacer></v-spacer>
        <v-text-field
          :dark="theme === 'dark'"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('nav_bar.menu.search')"
          single-line
          hide-details>
        </v-text-field>
        <v-data-table
          :disable-sort="isMobile"
          @click:row="editMaintenance"
          hide-default-footer
          sort-by="nextInspectionDate"
          :sort-desc="false"
          :search="search"
          :headers="certsTableHeader"
          :items="docs"
          item-key="_id"
          :page.sync="page"
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          :loading="isTableLoading"
          class="elevation-1 mt-0">
          <!-- EDITAR COLUMNA (EJEMPLO, NO BORRAR!!) -->
          <!-- <template v-slot:[`item.name`]="{ item }">
          <v-row style="display: flex; justify-content: flex-start">
            <v-icon v-if="!isActive" @click="editItem(item)" class="pr-2">mdi-pencil-outline</v-icon>
            <v-text-field
              style="width: 2rem !important; font-size: small"
              v-model="editedItem.name"
              dense
              @keydown.enter="save(item)"
              @keydown.esc="close(item)"
              single-line
              :autofocus="true"
              :label="item.name"
              v-if="isActive && editedItemId == item._id" />
            <v-icon small v-if="isActive && editedItemId == item._id" color="red" @click="close"> mdi-window-close </v-icon>
            <v-icon small v-if="isActive && editedItemId == item._id" color="primary" @click="save(item)">
              mdi-content-save
            </v-icon>
            <span v-if="!(isActive && editedItemId == item._id)">{{ item.name }}</span>
          </v-row>
        </template> -->

          <template v-slot:[`item.nextInspectionDate`]="{ item }">
            <span
              v-if="item.state?.toLowerCase() === 'active'"
              class="mdi mdi-circle icon-green pr-2"
              :title="$t('admin.menu.active')">
            </span>
            <span
              v-if="item.state?.toLowerCase() === 'expired'"
              class="mdi mdi-circle icon-red pr-2"
              :title="$t('nav_bar.menu.expired')">
            </span>
            <span
              v-if="item.state?.toLowerCase() === 'next'"
              class="mdi mdi-circle icon-orange pr-2"
              :title="$t('nav_bar.menu.next')">
            </span>
            {{ formatDateTable(item.nextInspectionDate) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-row style="display: flex; justify-content: center; align-items: center">
              <a style="display: flex; justify-content: center; align-items: center" :href="item.certificateUrl" target="_blank">
                <v-icon small color="primary">mdi-file-eye</v-icon>
              </a>

              <a style="display: flex; justify-content: center; align-items: center">
                <v-icon small color="success" @click.stop="renewRow(item)">mdi-update</v-icon>
              </a>

              <a style="display: flex; justify-content: center; align-items: center">
                <v-icon small class="mr-1" color="error" @click.stop="deleteRow(item)"> mdi-delete </v-icon>
              </a>
            </v-row>
          </template>
        </v-data-table>
        <div v-if="!isMobile" class="text-center pt-2" style="display: flex; justify-content: center">
          <v-btn color="primary" class="d-flex justify-space-between mx-15" @click="reportDocs">
            <v-icon class="mr-1">mdi-file-document</v-icon>
            {{ $t('nav_bar.menu.report') }}
          </v-btn>
          <v-pagination
            :dark="theme === 'dark'"
            :style="{
              color: theme === 'dark' ? 'white !important' : '',
            }"
            v-model="page"
            :length="pageCount"></v-pagination>
        </div>
        <div v-else class="text-center pt-3" style="display: flex; flex-direction: column; justify-content: center">
          <v-btn color="primary" class="d-flex justify-center mx-15" @click="reportDocs">
            <v-icon class="mr-1">mdi-file-document</v-icon>
            {{ $t('nav_bar.menu.report') }}
          </v-btn>
          <v-pagination
            class="pt-3"
            :dark="theme === 'dark'"
            :style="{
              color: theme === 'dark' ? 'white !important' : '',
            }"
            v-model="page"
            :length="pageCount"></v-pagination>
        </div>
      </div>
    </div>
    <Confirm ref="confirm"></Confirm>
    <MaintenanceModal
      v-if="isActiveModal"
      ref="modalTask"
      :maintenance="maintenance"
      @closeMaintenanceDialog="isActiveModal = false" />
  </v-container>
</template>

<script>
import MaintenanceModal from '../../../components/forms/maintenanceModal.vue';
import Confirm from '../../../components/confirm.vue';
import { getUser } from '../../../viewModels/userViewModel';
import { getUserDevices } from '../../../server/petitions/users/getUserDevices';
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';
import { getCertificates } from '../../../viewModels/devicesViewModel';
import { formatDateTable, updateMaintenanceDates } from '../../../utils/utils'; // formatDateTable se utiliza en código comentado

export default {
  components: { Confirm, MaintenanceModal },
  data: () => ({
    maintenance: null,
    isActiveModal: false,
    editedItemId: '',
    isActive: false,
    isSmallWindow: window.innerWidth <= 1550 ? true : false,
    windowWidth: window.innerWidth,
    theme: ls.get('theme') ?? 'ligth',
    page: 1,
    pageCount: 5,
    itemsPerPage: window.innerWidth <= 1550 ? 5 : 10,
    isTableLoading: true,
    user: null,
    device: [],
    docs: [],
    deviceID: '',
    isFileValid: false,
    search: '',
    editedItem: {
      index: 0,
      name: '',
    },
    editedIndex: -1,
    isRenew: false,
  }),
  mounted: async function () {
    window.addEventListener('resize', this.handleResize);
    this.user = await getUser();
    this.getCertificateCollection();

    eventBus.$on('resultMaintenance', (doc, isCreate) => {
      if (doc.certificateUrl) {
        if (isCreate) {
          this.docs.push(doc);
        } else {
          this.docs = this.docs.map((i) => (i._id == doc._id ? doc : i));
        }
      }
    });

    eventBus.$on('changeTheme', (themes) => {
      this.theme = themes;
    });

    eventBus.$on('changeLanguageEvent', async (lang) => {
      this.docs.forEach((doc, index) => {
        doc.index = index;
        updateMaintenanceDates(doc);
      });
    });

    this.deviceToSelect();
    this.isTableLoading = false;
  },
  methods: {
    formatDateTable,
    editMaintenance: async function (maintenance) {
      this.isActiveModal = true;
      maintenance.isRenew = this.isRenew;
      this.maintenance = maintenance;
      this.isRenew = false;
      setTimeout(() => {
        this.$refs.modalTask.setActiveModal(true);
      }, 100);
    },
    async save(item) {
      this.isTableLoading = true;

      Object.assign(this.docs[this.editedIndex], this.editedItem);
      let payload = { _id: item._id, device_sn: item.device_sn, new_inspection_date: item.nextInspectionDate, name: item.name };
      this.$emit('updatedCert', payload);
      this.close();
      this.isTableLoading = false;
    },

    close() {
      setTimeout(() => {
        this.isActive = false;
        this.editedIndex = -1;
      }, 300);
    },

    // EDITAR COLUMNA (EJEMPLO, NO BORRAR!!)
    // async editItem(item) {
    //   this.isActive = true;
    //   this.editedIndex = this.docs.indexOf(item);
    //   this.editedItem = Object.assign({}, item);
    //   this.editedItemId = item._id;
    // },

    reportDocs() {
      eventBus.$emit('reportMaintenanceDocs');
    },

    deleteRow: async function (item) {
      if (
        await this.$refs.confirm.open(
          `${this.$t('general.dialog.confirmation.button.accept_delete')}: ${item.name}`,
          this.$t('general.form.delete_certificate'),
          {
            color: 'red',
          },
        )
      ) {
        const certificateId = item._id;
        const deviceSn = item.device_sn;

        let indexToDelete = this.docs.indexOf(item);
        this.docs.splice(indexToDelete, 1);

        this.$emit('removeDoc', certificateId, deviceSn);
        eventBus.$emit('removeMaintenance', item);
      }
    },
    renewRow: async function (item) {
      if (
        await this.$refs.confirm.open(
          `${this.$t('general.form.renew')}: ${item.name}`,
          ` ${this.$t('general.form.renew_certificate')} ${this.$t('general.form.renew_and_delete_cert')}`,
          {
            color: 'green',
          },
        )
      ) {
        //RENOVAR CERTIFICADO
        item.newInspectionDate = new Date();
        this.isRenew = true;
        this.editMaintenance(item);
      }
    },

    async deviceToSelect() {
      this.isFileValid = true;
      this.device = [];
      let deviceCollection = await getUserDevices(this.user.token, this.user.userId);
      deviceCollection.map((element) => {
        if (element.certificates <= 4) {
          const { deviceId, device_sn, alias } = element;

          return this.device.push({ id: `${device_sn}-${alias}`, name: alias, deviceId });
        }
      });
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    async getCertificateCollection() {
      let deviceDocuments = await getCertificates(this.user.userId);
      deviceDocuments
        .flatMap((i) => i.certificates || []) //desmonto la array anidada y filtro null/undefined
        .filter((c) => c) // filtro falsy values (null, undefined, '', 0, false, NaN)
        .forEach((c) => this.docs.push(c));

      // Filtramos donde coincida el SN del certificado con el SN del dispositivo.
      //La matrícula ( alias ) del certificado será igual que la matrícula del dispositivo con el cual ha coincidido el SN.
      this.docs.forEach((doc, index) => {
        deviceDocuments.filter((i) => {
          if (i.device_sn == doc.device_sn) doc.alias = i.alias;
        }),
          (doc.index = index);
        // this.updateCert(doc);
        updateMaintenanceDates(doc);
      });
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    certsTableHeader() {
      return [
        {
          text: this.$t('dashboard.device_card.name_task'),
          value: 'name',
          cellClass: 'headerClass',
          class: 'headerClass',
          alignment: 'center',
          width: this.isSmallWindow ? '16%' : '18%',
          divider: true,
        },
        {
          text: this.$t('general.form.alias_device'),
          value: 'alias',
          cellClass: 'headerClass',
          class: 'headerClass',
          alignment: 'center',
          width: this.isSmallWindow ? '16%' : '18%',
          divider: true,
        },
        {
          text: this.$t('general.form.next_inspection'),
          value: 'nextInspectionDate',
          cellClass: 'headerClass',
          class: 'headerClass',
          alignment: 'center',
          width: '16%',
          divider: true,
        },
        {
          text: this.$t('general.form.validity'),
          cellClass: 'headerClass',
          class: 'headerClass',
          value: 'remainingDays',
          alignment: 'center',
          width: '5%',
          divider: true,
        },
        {
          text: '',
          cellClass: 'headerClass',
          class: 'headerClass',
          value: 'actions',
          alignment: 'center',
          width: '8%',
          divider: true,
        },
      ];
    },
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      this.isSmallWindow = newWidth <= 1550;
      this.itemsPerPage = this.isSmallWindow ? 5 : 10;
    },
  },
};
</script>
<style scoped>
.headerClass {
  font-size: smaller !important;
  text-align: center;
}
::v-deep .v-icon.notranslate.v-data-table-header__icon {
  display: none;
}
::v-deep .v-pagination__item.v-pagination__item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5vh;
}

::v-deep .v-pagination__navigation.v-pagination__navigation {
  height: 2.5vh;
}
</style>
