<template>
  <v-card class="elevation-2 ma-2">
    <v-card-text class="pt-0">
      <router-link to="/notifications">
        <div
          :style="{
            color: theme === 'dark' ? 'white !important' : '',
          }"
          class="link text-overline font-weight-bold">
          {{ $t('nav_bar.notifications') }}
        </div>
      </router-link>

      <v-divider class="py-1 mx-2" style="border-color: #607d8b"></v-divider>

      <div v-if="isDoorOpen" class="ml-1 mb-2 d-flex flex-column">
        <span class="font-weight-bold">
          <font-awesome-icon icon="fa-solid fa-circle-exclamation" class="indicador-exclamation" />
          {{ $t('dashboard.temp.alarms.door') }}
        </span>
      </div>

      <div v-if="isTemp1Low" class="ml-1 mb-2 d-flex flex-column">
        <span class="font-weight-bold">
          <font-awesome-icon icon="fa-solid fa-circle-exclamation" class="indicador-exclamation" />
          {{ $t('dashboard.temp.alarms.temp_1low') }}
        </span>
      </div>
      <div v-if="isTemp1High" class="ml-1 mb-2 d-flex flex-column">
        <span class="font-weight-bold">
          <font-awesome-icon icon="fa-solid fa-circle-exclamation" class="indicador-exclamation" />
          {{ $t('dashboard.temp.alarms.temp_1high') }}
        </span>
      </div>
      <div v-if="isTemp2Low" class="ml-1 mb-2 d-flex flex-column">
        <span class="font-weight-bold">
          <font-awesome-icon icon="fa-solid fa-circle-exclamation" class="indicador-exclamation" />
          {{ $t('dashboard.temp.alarms.temp_2low') }}
        </span>
      </div>
      <div v-if="isTemp2High" class="ml-1 mb-2 d-flex flex-column">
        <span class="font-weight-bold">
          <font-awesome-icon icon="fa-solid fa-circle-exclamation" class="indicador-exclamation" />
          {{ $t('dashboard.temp.alarms.temp_2high') }}
        </span>
      </div>
      <div v-if="isInsidePoi" class="ml-1 mb-2 d-flex flex-column">
        <span class="font-weight-bold">
          <font-awesome-icon icon="fa-solid fa-circle-exclamation" class="indicador-exclamation" />
          {{ $t('dashboard.temp.alarms.pdi') }}
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>
<style>
.link {
  color: black;
}
</style>
<script>
const ls = require('localstorage-slim');
import { eventBus } from '../../../../../main';

export default {
  components: {},
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
    isDoorOpen: {
      type: Boolean,
      default: false,
    },
    isTemp1Low: {
      type: Boolean,
      default: false,
    },
    isTemp2Low: {
      type: Boolean,
      default: false,
    },
    isTemp1High: {
      type: Boolean,
      default: false,
    },
    isTemp2High: {
      type: Boolean,
      default: false,
    },
    isInsidePoi: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    theme: ls.get('theme') ?? 'ligth',
  }),
  computed: {},
  methods: {},
  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
  },
};
</script>
