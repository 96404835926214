<template>
  <v-dialog v-model="isActive" max-width="40rem" @click:outside="cancelConfiguration" @keydown.esc="cancelConfiguration">
    <v-card class="pa-4 mx-lg-auto configuration-view-card">
      <v-row style="justify-content: center" class="confi-view-row">
        <v-card-title color="primary" class="confi-view-card-text text-h4 font-weight-black">
          {{ $t('user.configuration.device') }} -
          <span class="ml-1">
            {{ device && device.device_sn ? device.device_sn : 0 }}
          </span>
        </v-card-title>
      </v-row>

      <v-container id="container-edit-device" style="padding-top: 0px; padding-bottom: 0px">
        <v-col cols="12" md="12">
          <v-text-field
            dense
            v-model="name"
            id="Input"
            append-icon="mdi-border-color"
            class="font-weight-black"
            :hint="$t('user.configuration.add_name')"
            :rules="maxNameLengthRule">
          </v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field
            dense
            :label="$t('user.configuration.label')"
            v-model="tag"
            id="labelInput"
            append-icon="mdi-border-color"
            class="font-weight-black"
            :hint="$t('user.configuration.add_label')"
            :rules="maxLabelNameLengthRule">
          </v-text-field>
        </v-col>
      </v-container>
      <v-form v-if="Object.keys(probes).filter((key) => probes[key] !== null && probes[key] !== 'disconnected').length > 0">
        <v-container id="container-probes" style="padding-top: 0px">
          <v-card-title dense color="primary" class="confi-subtitle-style" style="padding-bottom: 0px">
            {{ $t('dashboard.configuration.subtitle') }}:
          </v-card-title>
          <v-divider class="mb-10"></v-divider>
          <section :class="probesClass">
            <v-text-field
              v-for="field in probeFields"
              v-if="probes[field?.key]"
              :key="field.key"
              dense
              type="text"
              v-model="probesObject[field.key]"
              outlined
              :label="field.label" />
          </section>

          <v-row class="content-probes mt-2" style="justify-content: space-around"> </v-row>
        </v-container>
      </v-form>
      <v-form v-if="!probes">
        <div class="d-flex justify-center">
          {{ $t('user.configuration.noTemperatureRaster') }}
        </div>
      </v-form>
      <v-divider class="mb-10"></v-divider>

      <v-container id="container-btn" class="text-md-center">
        <v-btn class="btn-confi-exit mx-lg-12" color="red" elevation="2" x-large @click="cancelConfiguration">
          <v-icon class="white--text mr-1">mdi-exit-to-app</v-icon>
          <span class="white--text">
            {{ $t('general.form.exit') }}
          </span>
        </v-btn>
        <v-btn class="btn-confi-save mx-lg-12" color="primary" :disabled="inputRule" elevation="2" x-large @click="save">
          <v-icon class="mr-1">mdi-content-save</v-icon>
          {{ $t('dashboard.configuration.accept') }}
        </v-btn>
      </v-container>
    </v-card>

    <v-snackbar v-model="alert" :timeout="timeout" color="error">
      {{ $t('user.configuration.something_wrong') }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="cancelConfiguration"> {{ $t('general.form.close') }} </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { getUser } from '../../../viewModels/userViewModel';
import { saveConfig } from '../../../viewModels/devicesViewModel';
import { eventBus } from '../../../main';
import device from '../../../utils/device';
import router from '../../../plugins/router/index';
const VueI18n = require('../../../plugins/lang/index');

export default {
  components: {},

  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    device: {
      type: Object,
    },
    titleInput: {
      type: Boolean,
    },
  },

  data: () => {
    return {
      user: null,
      probes: {},
      userDeviceSelected: null,
      rules: {},
      timeout: 2000,
      snackbar: false,
      alert: false,
      name: null,
      tag: null,
      probesObject: {
        p1: null,
        p2: null,
        p3: null,
        p4: null,
        p5: null,
        p6: null,
        p7: null,
        p8: null,
      },
      inputRule: false,
      probesClass: '',
      colsValue: [3, 5, 6, 7],
      probeFields: [
        { key: 'p1', label: VueI18n.default.t('user.configuration.p1_name') },
        { key: 'p2', label: VueI18n.default.t('user.configuration.p2_name') },
        { key: 'p3', label: VueI18n.default.t('user.configuration.p3_name') },
        { key: 'p4', label: VueI18n.default.t('user.configuration.p4_name') },
        { key: 'p5', label: VueI18n.default.t('user.configuration.p5_name') },
        { key: 'p6', label: VueI18n.default.t('user.configuration.p6_name') },
        { key: 'p7', label: VueI18n.default.t('user.configuration.p7_name') },
        { key: 'p8', label: VueI18n.default.t('user.configuration.p8_name') },
      ],
    };
  },

  computed: {
    maxNameLengthRule() {
      this.initializeName();

      const regex = /^(?!.*[~˜])[a-zA-Z0-9-\s]{1,20}$/;

      return [
        (value) => regex.test(value) || this.$t('dashboard.configuration.alphabet_character_rule'),
        (value) => (this.inputRule = !regex.test(value)),
      ];
    },

    maxLabelNameLengthRule() {
      this.initializeName();

      return [
        (value) => value?.length < 13 || 'Max 12 characters',
        (value) => value?.length < 13 || (this.inputRule = true),
        (value) => value?.length >= 13 || (this.inputRule = false),
        (value) => /[a-zA-Z]/.test(value) || this.$t('dashboard.configuration.alphabet_character'),
      ];
    },
  },

  methods: {
    initializeName() {
      this.name = this.device?.alias;
      this.tag = this.device?.tags[0];
    },
    changeCarPlate() {
      this.titleInput = false;
    },
    cancelConfiguration: function () {
      this.$emit('cancel-configuration');
      // this.name = null; // Si descomento se vacía el nombre cuando se cierra el dialog
      // this.tag = null;
      this.cleanProbes();
    },
    reloadDevices() {
      this.$store.dispatch('setSelectedDevice', null);

      eventBus.$emit('reloadDevices');
      if (this.currentRouteName != 'temperatures') {
        router.push({ name: 'map' });
      }
    },

    cleanProbes() {
      this.p1 = '';
      this.p2 = '';
      this.p3 = '';
      this.p4 = '';
      this.p5 = '';
      this.p6 = '';
      this.p7 = '';
      this.p8 = '';
    },

    save: async function () {
      let configToSave = {
        tag: this.tag,
        name: this.name,
        probes_config: {
          p1: { name: this.probesObject.p1 },
          p2: { name: this.probesObject.p2 },
          p3: { name: this.probesObject.p3 },
          p4: { name: this.probesObject.p4 },
          p5: { name: this.probesObject.p5 },
          p6: { name: this.probesObject.p6 },
          p7: { name: this.probesObject.p7 },
          p8: { name: this.probesObject.p8 },
        },
      };
      this.cleanProbes();

      let res = await saveConfig(this.device.device_sn, configToSave);
      if (res.result !== 'SUCCESS') {
        this.alert = !this.alert;
      } else {
        this.cancelConfiguration();
        this.reloadDevices();
      }
    },
  },

  mounted: async function () {
    this.user = await getUser();

    if (this.device) {
      if (this.device.last_frames?.frame_frigo && this.device.last_frames?.frame_frigo.probes_temperature) {
        this.probes = this.device.last_frames.frame_frigo.probes_temperature;
        const probesNoNullLength = Object.keys(this.probes).filter(
          (key) => this.probes[key] !== null && this.probes[key] !== 'disconnected',
        ).length;
        this.probesClass = this.colsValue.includes(probesNoNullLength) ? 'layout3' : 'layout2';
      }
      for (let userDevice of this.user.devices) {
        if (this.device.device_sn == userDevice.device_sn) {
          this.userDeviceSelected = userDevice;
        }
      }
      this.probesObject.p1 =
        (this.userDeviceSelected.probes_config &&
          this.userDeviceSelected.probes_config.p1 &&
          this.userDeviceSelected.probes_config.p1.name) ||
        null;
      this.probesObject.p2 =
        (this.userDeviceSelected.probes_config &&
          this.userDeviceSelected.probes_config.p2 &&
          this.userDeviceSelected.probes_config.p2.name) ||
        null;
      this.probesObject.p3 =
        (this.userDeviceSelected.probes_config &&
          this.userDeviceSelected.probes_config.p3 &&
          this.userDeviceSelected.probes_config.p3.name) ||
        null;
      this.probesObject.p4 =
        (this.userDeviceSelected.probes_config &&
          this.userDeviceSelected.probes_config.p4 &&
          this.userDeviceSelected.probes_config.p4.name) ||
        null;
      this.probesObject.p5 =
        (this.userDeviceSelected.probes_config &&
          this.userDeviceSelected.probes_config.p5 &&
          this.userDeviceSelected.probes_config.p5.name) ||
        null;
      this.probesObject.p6 =
        (this.userDeviceSelected.probes_config &&
          this.userDeviceSelected.probes_config.p6 &&
          this.userDeviceSelected.probes_config.p6.name) ||
        null;
      this.probesObject.p7 =
        (this.userDeviceSelected.probes_config &&
          this.userDeviceSelected.probes_config.p7 &&
          this.userDeviceSelected.probes_config.p7.name) ||
        null;
      this.probesObject.p8 =
        (this.userDeviceSelected.probes_config &&
          this.userDeviceSelected.probes_config.p8 &&
          this.userDeviceSelected.probes_config.p8.name) ||
        null;

      this.initializeName();
    }
  },
};
</script>

<style scoped>
.layout2 {
  display: grid;
  grid-template-columns: repeat(2, 0.3fr);
  justify-content: center;
  gap: 8px;
}
.layout3 {
  display: grid;
  grid-template-columns: repeat(3, 0.3fr);
  justify-content: center;
  gap: 8px;
}
</style>
