const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class ModifyNotificationEmailsException extends PetitionError {
  constructor(code) {
    super('Modify User By Itslef', code);
  }
}

async function modifyNotificationEmailsPetition(user_email, notification_emails, token) {
  const payload = {
    email: user_email,
    notification_emails: notification_emails,
  };

  const response = await post('users/notification_emails', payload, token);

  if (response.result !== ModifyNotificationEmailsException.success) {
    throw new ModifyNotificationEmailsException(response.result);
  } else {
    return response; // TODO transform
  }
}

module.exports = {
  modifyNotificationEmailsPetition,
  ModifyNotificationEmailsException,
};
