const PetitionError = require('../../petitionError');
const { getAnonymous } = require('../../httpRequester');

class GetImagePetitionException extends PetitionError {
  constructor(code) {
    super('Get User Devices', code);
  }
}

async function getImagePetition(token, email) {
  const response = await getAnonymous(`users/image?email=${email}&token=${token}`);

  if (response.result !== GetImagePetitionException.success) {
    throw new GetImagePetitionException(response.result);
  } else {
    return response;
  }
}


module.exports = {
  getImagePetition,
  GetImagePetitionException,
};
