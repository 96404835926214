<template>
  <div>
    <v-row justify="center">
      <h1 class="text-center mb-6 my-3">{{ $t('dashboard.configuration.probes_name') }}</h1>
    </v-row>
    <v-container class="d-flex justify-content-center">
      <v-alert v-if="alert" v-model="alert" type="error" dismissible>{{
        $t('dashboard.configuration.error_parameter_probe')
      }}</v-alert>

      <v-row>
        <v-col>
          <h3
            :style="{
              color: theme === 'dark' ? 'white !important' : '',
            }"
            class="d-flex justify-start">
            {{ $t('user.atribute.devices') }}
          </h3>
          <v-data-table
            @click:row="nameDevice"
            :loading="isTableLoading"
            :headers="devicesHeader"
            :items="devicesTable"
            hide-default-footer
            :page.sync="page"
            @page-count="pageCount = $event"
            :items-per-page="itemsPerPage"
            class="shared-data-table elevation-1"
            :search="search"
            v-bind:loading-text="$t('general.table.loading')">
            <template v-slot:top>
              <v-text-field
                style="width: 100%"
                v-model="search"
                :label="$t('shared.search')"
                prepend-inner-icon=" "></v-text-field>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination :dark="theme === 'dark'" v-model="page" :length="pageCount"></v-pagination>
          </div>
        </v-col>
        <v-divider :dark="theme === 'dark'" class="mr-5" vertical></v-divider>
        <v-col>
          <h3
            :style="{
              color: theme === 'dark' ? 'white !important' : '',
            }">
            {{ $t('reports.probes') }}
          </h3>
          <p v-if="selectedDevice">SN: {{ selectedDevice.device_sn }}</p>
          <div class="mt-8" v-if="selectedDevice">
            <div v-if="this.probes">
              <v-row v-if="this.probes.p1">
                <h4 class="mr-4">{{ $t('device.last_frames.frame_frigo.p1') }}</h4>
                <v-text-field type="text" v-model="p1" outlined :label="$t('user.configuration.p1_name')"> </v-text-field>
              </v-row>
              <v-row v-if="this.probes.p2">
                <h4 class="mr-4">{{ $t('device.last_frames.frame_frigo.p2') }}</h4>
                <v-text-field type="text" v-model="p2" outlined :label="$t('user.configuration.p2_name')"> </v-text-field>
              </v-row>
              <v-row v-if="this.probes.p3">
                <h4 class="mr-4">{{ $t('device.last_frames.frame_frigo.p3') }}</h4>
                <v-text-field type="text" v-model="p3" outlined :label="$t('user.configuration.p3_name')"> </v-text-field>
              </v-row>
              <v-row v-if="this.probes.p4">
                <h4 class="mr-4">{{ $t('device.last_frames.frame_frigo.p4') }}</h4>
                <v-text-field type="text" v-model="p4" outlined :label="$t('user.configuration.p4_name')"> </v-text-field>
              </v-row>
              <v-row v-if="this.probes.p5">
                <h4 class="mr-4">{{ $t('device.last_frames.frame_frigo.p5') }}</h4>
                <v-text-field type="text" v-model="p5" outlined :label="$t('user.configuration.p5_name')"> </v-text-field>
              </v-row>
              <v-row v-if="this.probes.p6">
                <h4 class="mr-4">{{ $t('device.last_frames.frame_frigo.p6') }}</h4>
                <v-text-field type="text" v-model="p6" outlined :label="$t('user.configuration.p6_name')"> </v-text-field>
              </v-row>
              <v-row v-if="this.probes.p7">
                <h4 class="mr-4">{{ $t('device.last_frames.frame_frigo.p7') }}</h4>
                <v-text-field type="text" v-model="p7" outlined :label="$t('user.configuration.p7_name')"> </v-text-field>
              </v-row>
              <v-row v-if="this.probes.p8">
                <h4 class="mr-4">{{ $t('device.last_frames.frame_frigo.p8') }}</h4>
                <v-text-field type="text" v-model="p8" outlined :label="$t('user.configuration.p8_name')"> </v-text-field>
              </v-row>
            </div>
            <div v-else>
              <v-row>
                <h4 class="ma-4">{{ $t('dashboard.configuration.no_probes') }}</h4>
              </v-row>
            </div>
          </div>
          <v-row v-else>
            <h4 class="ml-4 mt-4">{{ $t('reports.missing.device') }}</h4>
          </v-row>
          <v-row v-if="selectedDevice">
            <h4 class="ml-4 mt-4">{{ $t('dashboard.configuration.device_name') }}</h4>
            <v-text-field
              class="ml-2"
              type="text"
              v-model="deviceName"
              outlined
              :label="$t('dashboard.configuration.device_name')">
            </v-text-field>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="user-configuration-buttons">
        <v-btn v-if="selectedDevice" color="primary" class="d-inline ma-6" @click="save">
          <v-icon>mdi-content-save</v-icon>{{ $t('general.form.save') }}
        </v-btn>

        <v-btn v-else :dark="theme === 'dark'" disabled color="primary" class="d-inline ma-6">
          <v-icon>mdi-content-save</v-icon>{{ $t('general.form.save') }}
        </v-btn>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar" :timeout="timeout" color="success">
      {{ $t('general.form.save_successfully_') }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs"> {{ $t('general.form.close') }} </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { getMyFullDevices, saveConfig } from '../../../viewModels/devicesViewModel';
import { getUserByToken } from '../../../viewModels/userViewModel';
import { getToken } from '../../../viewModels/userViewModel';
import { eventBus } from '../../../main';
const ls = require('localstorage-slim');

export default {
  components: {},
  data: () => ({
    theme: ls.get('theme') ?? 'ligth',
    page: 1,
    pageCount: 0,
    itemsPerPage: 8,
    alert: false,
    snackbar: false,
    timeout: 2000,
    user: null,
    devicesTable: [],
    isTableLoading: true,
    search: null,
    selectedDevice: null,
    probes: null,
    deviceName: '',
    p1: null,
    p2: null,
    p3: null,
    p4: null,
    p5: null,
    p6: null,
    p7: null,
    p8: null,
    refreshTable: false,
  }),
  methods: {
    async nameDevice(item) {
      this.deviceName = item.name;
      console.log(this.deviceName);
      this.selectedDevice = null;
      this.probes = null;
      this.p1 = (item.probes_config && item.probes_config.p1 && item.probes_config.p1.name) || null;
      this.p2 = (item.probes_config && item.probes_config.p2 && item.probes_config.p2.name) || null;
      this.p3 = (item.probes_config && item.probes_config.p3 && item.probes_config.p3.name) || null;
      this.p4 = (item.probes_config && item.probes_config.p4 && item.probes_config.p4.name) || null;
      this.p5 = (item.probes_config && item.probes_config.p5 && item.probes_config.p5.name) || null;
      this.p6 = (item.probes_config && item.probes_config.p6 && item.probes_config.p6.name) || null;
      this.p7 = (item.probes_config && item.probes_config.p7 && item.probes_config.p7.name) || null;
      this.p8 = (item.probes_config && item.probes_config.p8 && item.probes_config.p8.name) || null;

      let myDevicesBack = await getMyFullDevices([item]);
      this.selectedDevice = myDevicesBack[0];
      if (this.selectedDevice.last_frames.frame_frigo && this.selectedDevice.last_frames.frame_frigo.probes_temperature) {
        this.probes = this.selectedDevice.last_frames.frame_frigo.probes_temperature;
      }
    },
    async save() {
      let configToSave = {
        name: this.deviceName,
        probes_config: {
          p1: { name: this.p1 },
          p2: { name: this.p2 },
          p3: { name: this.p3 },
          p4: { name: this.p4 },
          p5: { name: this.p5 },
          p6: { name: this.p6 },
          p7: { name: this.p7 },
          p8: { name: this.p8 },
        },
      };
      let res = await saveConfig(this.selectedDevice.device_sn, configToSave);
      if (res.result !== 'SUCCESS') {
        this.alert = !this.alert;
      } else {
        this.snackbar = !this.snackbar;
        this.alert = false;
        this.refreshTable = true;
      }
      if (this.refreshTable) {
        let token = await getToken();

        this.user = await getUserByToken(token);
        this.devicesTable = this.user.devices;
        console.log(this.devicesTable);
      }
    },
  },

  computed: {
    devicesHeader() {
      return [
        {
          text: `${this.$t('alarms.name')}`,
          value: 'name',
          width: '7rem',
          divider: true,
        },
        {
          text: 'SN',
          value: 'device_sn',
          width: '7rem',
          divider: true,
        },
      ];
    },
  },

  mounted: async function () {
    //Por si solo queremos que salgan los termografos
    // for (let device of this.user.devices) {
    //   if (device.device_sn.toString().length < 13) {
    //     this.devicesTable.push(device);
    //   }
    // }
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
    let token = await getToken();

    this.user = await getUserByToken(token);
    console.log(this.user.devices);

    this.devicesTable = this.user.devices;
    this.isTableLoading = false;
  },
};
</script>
