const PetitionError = require('../../petitionError');
const { externalGet, get, getAnonymous } = require('../../httpRequester');

const ls = require('localstorage-slim');

const { shortAddress } = require('../../../utils/address');

class GetReverseException extends PetitionError {
  constructor(code) {
    super('Get Reverse', code);
  }
}

const reverse = async (lat, lng, token) => {
  const language = 'es-ES';

  const response = await getAnonymous(`geocode?format=jsonv2&lat=${lat}&lon=${lng}&accept-language=${language}&token=${token}`);

  if (response.result !== GetReverseException.success) {
    throw new GetReverseException(response.result);
  } else {
    return response.data;
  }
};

const getAddress = async (lat, lng, token = '') => {
  const keyRevGeocode = 'getRevGeocodeAddress__' + lat.toString() + lng.toString();
  const responseFromLS = ls.get(keyRevGeocode);

  if (responseFromLS) {
    return responseFromLS;
  }

  let returnObj = null;

  let nominatimReverseResult = null;
  try {
    nominatimReverseResult = await reverse(lat, lng, token);
  } catch (e) {
    console.log(e);
  }

  if (nominatimReverseResult) {
    returnObj = {
      addressData: {
        ...nominatimReverseResult.address,
        displayFormatted: shortAddress(nominatimReverseResult.address),
      },
      lat,
      lng,
    };

    ls.set(keyRevGeocode, returnObj, { ttl: 3600 });
    return returnObj;
  }

  const revGeoCodeResponse = await getRevGeocodeAddress(lat, lng, token);
  if (revGeoCodeResponse?.items?.length > 0) {
    returnObj = {
      addressData: {
        ...revGeoCodeResponse.items[0].address,
        displayFormatted: shortAddress(revGeoCodeResponse.items[0].address),
      },
      lat,
      lng,
    };
    ls.set(keyRevGeocode, returnObj, { ttl: 3600 });
    return returnObj;
  }

  return null;
};

async function getRevGeocodeAddress(lat, lng, token) {
  const language = 'es-ES';
  const response = await externalGet(
    `https://revgeocode.search.hereapi.com/v1/revgeocode?at=${lat},${lng}&lang=${language}S&apiKey=${process.env.VUE_APP_APIKEY}`,
  );

  return response;
}

const getCoordinates = async (address) => {
  let returnObj = null;
  const searchResult = await search(address);

  if (searchResult?.items?.length > 0) {
    returnObj = {
      coordinates: {
        latitude: searchResult.items[0].position.lat,
        longitude: searchResult.items[0].position.lng,
      },
      address,
    };
  }

  return returnObj;
};

const search = async (query) => {
  const language = 'es-ES';

  let response = null;
  try {
    response = await externalGet(
      `https://revgeocode.search.hereapi.com/v1/geocode?q=${query}&lang=${language}&apiKey=${process.env.VUE_APP_APIKEY}`,
    );
  } catch (e) {
    console.log(e);
  }
  return response;
};

module.exports = { getRevGeocodeAddress, getAddress, getCoordinates };
