<template>
  <div class="ml-5">
    <div class="user-configuration-container">
      <v-form class="user-configuration-form" ref="userMetricForm" lazy-validation>
        <div class="user-configuration-form-title">
          <h1 class="my-5 text-center">{{ $t('user.atribute.unit.unit_measurement') }}</h1>
        </div>
        <v-row justify="center">
          <v-col :cols="isMobile ? 8 : 3">
            <v-select
              dense
              outlined
              item-text="name"
              item-value="value"
              v-model="selectedUnit"
              :items="refreshUnits"
              v-bind:label="$t('user.form.select_unit')"
              :rules="[(v) => !!v || $t('general.error.empty_value')]"
              required
              class="rounded-lg"></v-select>
          </v-col>
        </v-row>

        <div class="mt-5 ml-2">
          <v-row justify="center" class="user-configuration-buttons">
            <v-btn small color="red" class="d-inline text-white" @click="dashboardView">
              <v-icon class="white--text">mdi-exit-to-app</v-icon>
              <span class="white--text">{{ $t('general.form.exit') }}</span>
            </v-btn>

            <v-btn small color="primary" @click="saveMetric" class="d-inline ml-2">
              <v-icon>mdi-content-save</v-icon>{{ $t('general.form.save') }}
            </v-btn>
          </v-row>
          <div class="alert">
            <v-alert v-if="validReport" border="top" color="green lighten-2" prominent type="success" dark :timeout="timeout">
              <v-col class="grow"> <v-icon>mdi-emoticon-happy</v-icon> {{ $t('general.form.saved') }}</v-col>
            </v-alert>
          </div>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import router from '../../../plugins/router/index';
import { getUser, modifyMetrics } from '../../../viewModels/userViewModel.js';

export default {
  data: () => ({
    selectedUnit: '',
    user: null,
    validReport: false,
    timeout: 3000,
  }),
  methods: {
    async saveMetric() {
      if (this.$refs.userMetricForm.validate()) {
        try {
          await modifyMetrics(this.user.email, this.selectedUnit);
        } catch (e) {
          console.error('CATCH ERROR:', e);
        }
        this.activateAlert();
        // this.validReport = true;
      }
    },
    activateAlert() {
      this.validReport = true;
      this.closeAlert();
    },
    dashboardView: function () {
      router.push({ name: 'dashboard' });
    },
    closeAlert() {
      setTimeout(() => {
        this.validReport = false;
      }, 1000);
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    refreshUnits() {
      return [
        { name: this.$t('user.atribute.unit.metric'), value: 'metric' },
        { name: this.$t('user.atribute.unit.imperial'), value: 'imperial' },
      ];
    },
  },

  mounted: async function () {
    this.user = await getUser();
    this.selectedUnit = this.user.unit;
  },
};
</script>
<style>
.alert {
  height: 10rem;
  margin-top: 2rem;
}
</style>
