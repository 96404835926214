const PetitionError = require('../../petitionError');
const { post } = require('../../httpRequester');

class PostPOIException extends PetitionError {
  constructor(code) {
    super('Post POI', code);
  }
}

class ImportPOIsException extends PetitionError {
  constructor(code) {
    super('Import POIs', code);
  }
}

async function postPOI(token, poi) {
  const payload = { ...poi };
  const response = await post('pois', payload, token);

  if (response.result !== PostPOIException.success) {
    return response.result;
  }

  return response;
}

async function importPOIs(token, pois) {
  const payload = { pois };
  const response = await post('pois/import', payload, token);

  if (response.result !== ImportPOIsException.success) {
    return response.result;
  }

  return response;
}

module.exports = {
  postPOI,
  importPOIs,
  PostPOIException,
  ImportPOIsException,
};
