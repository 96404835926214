const { mdiCogSyncOutline } = require('@mdi/js');
const createClientPetition = require('../server/petitions/clients/createClient');
const getUsersPetition = require('../server/petitions/users/getUsers');
const { getUser } = require('./userViewModel');
const getAllCustomersPetition = require('../server/petitions/clients/getAllCustomersPetition');
const addClientPetition = require('../server/petitions/clients/addClient');
const { loginByToken } = require('../server/petitions/authentication/loginByToken');
const { deleteClient } = require('../server/petitions/clients/deleteClient');
const { getToken } = require('./userViewModel');
const { updateTable } = require('./devicesViewModel');

const userViewModel = require('./userViewModel');

const clientViewModel = function () {
  let users;
  let customers = [];

  async function getAllUsers() {
    if (users == null) {
      const token = getToken();
      users = await getUsersPetition.getUsers(token);
      return users;
    }
    return users;
  }
  async function getUserByToken(token) {
    try {
      const _user = await loginByToken(token);

      return _user;
    } catch (e) {
      console.error('ERRROR: ', e);

      return null;
    }
  }

  async function createClient(payload) {
    if (users == null) {
      await getAllUsers();
    }

    const token = getToken();

    const newUser = await createClientPetition.createClient(token, payload);

    users.push(newUser);
    return newUser;
  }

  async function addClient(name, email) {
    const token = getToken();
    const user = await getUserByToken(token);

    const found = customers.find((client) => client.email === email);

    if (!found) {
      const res = await addClientPetition.addNewClient(token, user, name, email);
      if (res.client !== "EMAIL_DOESN'T_EXIST") {
        customers.push(res.client);
      }
      return res;
    }
    const res = { result: 'ERROR' };
    return res;
  }

  async function getCustomers() {
    if (customers.length === 0) {
      const user = await getUser();
      const user_email = user.email;
      const token = getToken();

      customers = await getAllCustomersPetition.getAllCustomers(user_email, token);
      return customers;
    }
    return customers;
  }
  async function deleteCustomers(email) {
    const token = getToken();
    const user = await getUserByToken(token);
    const res = await deleteClient(token, user, email);
    updateTable(res);
  }

  function reset() {
    customers = [];
  }

  return {
    createClient,
    addClient,
    getCustomers,
    deleteCustomers,
    reset,
  };
};

module.exports = clientViewModel();
