<template>
  <v-card class="elevation-2 ma-2">
    <v-card-text class="pt-0">
      <div class="text-overline font-weight-bold">
        {{ $t('dashboard.details.location') }}
        <v-tooltip v-if="linkInfo" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="blue" v-if="linkInfo">mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <span v-if="linkInfo">{{ $t('dashboard.details.location_info') }}</span>
        </v-tooltip>
      </div>
      <v-divider class="py-1 mx-2" style="border-color: #607d8b"></v-divider>

      <div class="ml-1 d-flex flex-column">
        <span class="mb-1">
          <v-icon :class="checkGps" x-small> mdi-circle </v-icon>
          {{ gpsTimestamp }}
        </span>

        <span>
          <v-icon small> mdi-map-marker </v-icon>
          {{ getGeocode }}
        </span>

        <span>
          <v-icon small> mdi-speedometer </v-icon>
          {{ getSpeed }}
        </span>
      </div>

      <div class="d-flex flex-row my-3">
        <div class="d-flex justify-center flex-column" style="width: 100%">
          <span class="d-flex justify-center">
            {{ $t('dashboard.device_card.mileage_today') }}
          </span>
          <v-chip @click="showRoute" label small class="justify-center ma-1 blue-grey lighten-4" style="width: 95%">
            <span>{{ todayMileage }}</span>
          </v-chip>
        </div>

        <v-divider style="width: 10%" vertical></v-divider>

        <div class="d-flex flex-column" style="width: 100%">
          <span class="d-flex justify-center">
            {{ $t('dashboard.device_card.odometer') }}
          </span>
          <v-chip @click="openOdometer" label small class="justify-center ma-1 blue-grey lighten-4" style="width: 95%">
            <span>{{ odometer }} </span>
          </v-chip>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
    linkInfo: {
      type: Boolean,
    },
    status: {
      type: Object,
    },
    geocode: {
      type: [String],
      default: () => 'Sin localización',
    },
    odometer: {},
    todayMileage: {
      type: String,
    },
    gpsTimestamp: {
      type: String,
    },
  },
  data: () => ({}),
  computed: {
    checkGps() {
      if (this.status.gps) {
        return 'green--text';
      }
      return 'gray--text';
    },
    getGeocode() {
      return this.geocode;
    },
    getSpeed() {
      if (this.units == 'imperial') {
        const convertedOdometer = ((this.device.last_frames.frame_gps.speed * 1000) / 1609.34).toFixed(2);
        return convertedOdometer + ' ml/h';
      }
      return this.device.last_frames.frame_gps.speed.toFixed(0) + ' km/h';
    },
  },
  methods: {
    openOdometer() {
      this.$emit('openOdometer');
    },
    showRoute() {
      this.$emit('showRoute');
    },
  },
  mounted: async function () {},
};
</script>
