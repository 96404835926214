<template>
  <v-dialog
    v-model="isActive"
    max-width="50rem"
    scrollable
    @click:outside="cancelDialog"
    @keydown.esc="cancelDialog"
    class="ticket-view-modal">
    <v-card class="ticket-view-card">
      <v-card-title color="primary" class="ticket-view-modal-title headline" style="justify-content: space-between">
        <span class="ticket-view-modal-title-text">
          {{ this.$t('dashboard.tickets.title') + ' ' + this.customCarplate }}
        </span>
        <span @click="cancelDialog" class="ticket-view-modal-title-btn">
          <font-awesome-icon style="color: red; cursor: pointer" icon="fa-solid fa-circle-xmark" class="close-ticket-promp" />
        </span>
      </v-card-title>

      <v-card-title color="primary" class="caption pt-0"> S/N: {{ device.device_sn }} </v-card-title>

      <v-divider class="mb-2"></v-divider>

      <v-card-text class="ticket-view-card-text">
        <v-form class="ticket-view-form" ref="ticketForm" v-model="formValid" lazy-validation>
          <v-row class="ticket-view-row">
            <v-col class="ticket-calendar-promp mb-2">
              <v-toolbar class="ticket-calendar-toolbar my-1" dense rounded elevation="2">
                <v-icon color="primary" class="icon-calendar-modal"> mdi-calendar-range </v-icon>
                <v-toolbar-title v-if="dates.length == 0" :class="isFormValid" class="text-calendar-modal text-sm-body-2 mx-2">
                  <span class="text-calendar-modal-title">
                    {{ $t('reports.select_a_date') }}
                  </span>
                </v-toolbar-title>
                <v-toolbar-title v-else class="text-sm-body-2 mx-2" :class="isFormValid">
                  <strong>
                    <span>
                      {{
                        new Date(dates[0]).getTime() > new Date(dates[1]).getTime() == true
                          ? formatDate(dates[1])
                          : formatDate(dates[0])
                      }}
                    </span>
                  </strong>
                  <span class="px-2">
                    {{ $t('general.form.until') }}
                  </span>
                  <strong>
                    <span>
                      {{
                        new Date(dates[0]).getTime() < new Date(dates[1]).getTime() == false
                          ? formatDate(dates[0])
                          : formatDate(dates[1])
                      }}
                    </span>
                  </strong>
                  <span>
                    {{ howManyDaysSelected() }}
                  </span>
                </v-toolbar-title>
              </v-toolbar>
              <v-date-picker
                class="ticket-calendar-picker"
                :min="d"
                :max="maxDays"
                :show-current="new Date().toISOString().substring(0, 10)"
                color="primary"
                show-adjacent-months
                full-width
                no-title
                first-day-of-week="1"
                elevation="2"
                v-model="dates"
                range
                required
                @input="validateRange">
                <v-alert v-if="invalidDate" dismissible border="right" type="error">{{
                  $t('dashboard.tickets.rangeMaxDate')
                }}</v-alert>
              </v-date-picker>
            </v-col>

            <v-col class="col-selects">
              <v-select
                class="col-selects-text rounded-lg"
                dense
                outlined
                item-text="name"
                item-value="value"
                v-model="selectedRange"
                :items="minutesRange"
                v-bind:label="$t('dashboard.tickets.range')"
                :rules="[(v) => !!v || $t('general.error.empty_value')]">
              </v-select>
              <span class="ticket-row-time-description font-weight-bold mt-2"> {{ $t('reports.probes') }}: </span>

              <v-divider></v-divider>
              <v-row v-if="deviceProbes.length > 0">
                <v-checkbox
                  v-for="probe in deviceProbes"
                  :key="probe"
                  :label="$t(`device.last_frames.frame_frigo.${probe}`)"
                  :value="probe"
                  v-model="selectedProbes"
                  hide-details
                  class="ma-2"></v-checkbox>
              </v-row>
              <v-row class="ticket-row-time">
                <v-col>
                  <span class="ticket-row-time-description font-weight-bold"> {{ $t('dashboard.tickets.select_time') }}: </span>

                  <v-divider></v-divider>

                  <v-row class="d-flex justify-space-around">
                    <v-col cols="4">
                      <v-text-field
                        v-model="startingTime"
                        :label="
                          new Date(dates[0]) > new Date(dates[1]) == true
                            ? formatDate(dates[1])
                            : formatDate(dates[0]) + ' ' + $t('dashboard.tickets.at') + ':'
                        "
                        type="time">
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="endingTime"
                        :label="
                          new Date(dates[0]) < new Date(dates[1]) == false
                            ? formatDate(dates[0])
                            : formatDate(dates[1]) + ' ' + $t('dashboard.tickets.at') + ':'
                        "
                        type="time">
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <span class="ticket-row-time-description font-weight-bold mt-2">
                {{ $t('dashboard.tickets.select_shared') }}:
              </span>

              <v-divider></v-divider>

              <v-combobox v-if="byEmail" v-model="email" :items="predefinedEmails" label="Email" outlined :rules="emailRules">
              </v-combobox>

              <v-row class="buttons-search-dowloand mt-2 d-flex justify-center">
                <v-btn v-if="!byEmail" @click="downloadPdf" color="primary" class="ma-1">
                  <font-awesome-icon style="font-size: 22px" icon="fa-solid fa-file-pdf" />
                </v-btn>
                <v-btn
                  v-if="!byEmail"
                  @click="downloadEXCEL"
                  :loading="excelLoading"
                  :disabled="excelLoading"
                  color="primary"
                  class="ma-1">
                  <font-awesome-icon style="font-size: 22px" icon="fa-solid fa-file-excel" />
                </v-btn>

                <v-btn
                  v-if="!byEmail"
                  :loading="csvLoading"
                  :disabled="csvLoading"
                  color="primary"
                  class="ma-1"
                  @click="downloadCSV">
                  <font-awesome-icon style="font-size: 22px" icon="fa-solid fa-file-csv" />
                </v-btn>

                <v-btn
                  :loading="emailLoading"
                  color="primary"
                  class="ma-1"
                  @click="byEmail ? readyToSend() : (byEmail = !byEmail)">
                  <font-awesome-icon style="font-size: 22px" icon="fa-solid fa-envelope" />
                </v-btn>

                <v-btn v-if="byEmail" color="error" class="ma-1" @click="byEmail = !byEmail">
                  <font-awesome-icon style="font-size: 22px" icon="fa-solid fa-circle-xmark" />
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <h5
          :style="{
            color: theme === 'dark' ? 'white !important' : '',
          }">
          {{ $t('dashboard.tickets.current_day_preselected') }}
        </h5>
      </v-card-text>
    </v-card>

    <v-snackbar v-model="snackbar" :timeout="timeout" color="success">
      {{ $t('dashboard.tickets.successfully_sent') }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs">{{ $t('dashboard.tickets.close') }}</v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="redSnackbar" :timeout="timeout" color="error">
      {{ $t('dashboard.tickets.error') }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs"> X </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="redCSVSnackbar" :timeout="timeout" color="error">
      {{ $t('dashboard.tickets.no_data') }}
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { getTicket, sendTicketByEmail } from '../../../viewModels/reportsViewModel';
import { getCustomers } from '../../../viewModels/clientViewModel';
import { getUser } from '../../../viewModels/userViewModel';
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';
import { maxDate, validateDateRange } from '../../../utils/utils';
import { getUserDevices } from '../../../server/petitions/users/getUserDevices';

export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    device: {
      type: Object,
    },
  },
  data: () => ({
    theme: ls.get('theme') ?? 'ligth',
    d: '1900-01-01',
    formValid: false,
    isFormValid: '',
    dates: [],
    selectedRange: null,
    startingTime: '00:00',
    endingTime: '23:59',
    byEmail: false,
    email: '',
    csvLoading: false,
    excelLoading: false,
    predefinedEmails: [],
    emailLoading: false,
    timeout: 4000,
    snackbar: false,
    redSnackbar: false,
    redCSVSnackbar: false,
    userEmail: '',
    customCarplate: null,
    selectedProbes: [],
    invalidDate: false,
    devicesCollection: null,
    carplate: null,
  }),
  methods: {
    getCarplate() {
      eventBus.$on('carplate', (data) => {
        this.customCarplate = data;
      });
    },

    cancelDialog: function () {
      this.cleanInput();
      this.$emit('cancel-dialog');
    },

    downloadPdf: async function () {
      this.dates.length <= 0 ? this.dates.push(this.currentDay) : this.dates;

      if (this.dates[1] == null) {
        this.dates.push(this.dates[0]);
      }

      if (this.$refs.ticketForm.validate()) {
        let dateFrom = this.setTime('dateFrom');
        let dateTo = this.setTime('dateTo');
        if (this.selectedRange.value == this.minutesRange[2].value) {
          this.selectedRange = 10;
        }
        try {
          this.devicesCollection.map((i) => {
            if (i.device_sn == this.device.device_sn) this.carplate = i.alias;
          });
          let routeData = this.$router.resolve({
            name: 'loader',
            query: {
              type: 'ticket',
              dateFrom: dateFrom,
              dateTo: dateTo,
              startingTime: this.startingTime,
              endingTime: this.endingTime,
              range: this.selectedRange,
              deviceSn: this.device.device_sn,
              carplate: this.carplate,
              cif: this.device.device_data_from_frame.cif,
              probes: this.selectedProbes,
            },
          });

          window.open(routeData.href, '_blank');
        } catch (e) {}
      }
    },

    cleanInput() {
      this.byEmail = false;
      this.startingTime = '00:00';
      this.endingTime = '23:59';
      this.selectedRange = { name: `10 ${this.$t('dashboard.tickets.minutes')}`, value: 10 };
      this.dates = [];
    },

    downloadCSV: async function () {
      this.csvLoading = true;

      if (this.$refs.ticketForm.validate()) {
        let dateFrom = this.setTime('dateFrom');
        let dateTo = this.setTime('dateTo');
        if (this.selectedRange.value == this.minutesRange[2].value) {
          this.selectedRange = 10;
        }
        try {
          this.devicesCollection.map((i) => {
            if (i.device_sn == this.device.device_sn) this.carplate = i.alias;
          });
          let ticketCall = await getTicket(
            'csv',
            dateFrom,
            dateTo,
            this.selectedRange,
            this.device.device_sn,
            this.carplate,
            this.device.device_data_from_frame.cif,
            this.userEmail,
            this.selectedProbes,
          );
          if (ticketCall === null) this.redCSVSnackbar = true;

          this.csvLoading = false;
        } catch (e) {}
      }
    },
    downloadEXCEL: async function () {
      this.excelLoading = true;

      if (this.$refs.ticketForm.validate()) {
        let dateFrom = this.setTime('dateFrom');
        let dateTo = this.setTime('dateTo');
        if (this.selectedRange.value == this.minutesRange[2].value) {
          this.selectedRange = 10;
        }
        try {
          this.devicesCollection.map((i) => {
            if (i.device_sn == this.device.device_sn) this.carplate = i.alias;
          });
          let ticketCall = await getTicket(
            'excel',
            dateFrom,
            dateTo,
            this.selectedRange,
            this.device.device_sn,
            this.carplate,
            this.device.device_data_from_frame.cif,
            this.userEmail,
            this.selectedProbes,
          );
          if (ticketCall === null) this.redCSVSnackbar = true;

          this.excelLoading = false;
        } catch (e) {}
      }
    },
    readyToSend() {
      setTimeout(() => this.sendByEmail(), 100);
    },
    sendByEmail: async function () {
      if (this.$refs.ticketForm.validate()) {
        let dateFrom = this.setTime('dateFrom');
        let dateTo = this.setTime('dateTo');
        if (this.selectedRange.value == this.minutesRange[2].value) {
          this.selectedRange = 10;
        }
        try {
          this.devicesCollection.map((i) => {
            if (i.device_sn == this.device.device_sn) this.carplate = i.alias;
          });
          this.emailLoading = true;
          let res = await sendTicketByEmail(
            this.email,
            dateFrom,
            dateTo,
            this.selectedRange,
            this.device.device_sn,
            this.carplate,
            this.device.device_data_from_frame.cif,
            this.selectedProbes,
          );

          this.emailLoading = false;
          if (res.status === 200) {
            this.snackbar = true;
          } else {
            this.redSnackbar = true;
          }
        } catch (e) {
          this.byEmail = false;
          this.email = '';
        }
      }
    },
    setTime: function (type) {
      let date_from = this.dates[0] > this.dates[1] == true ? this.dates[1] : this.dates[0];
      let date_to = this.dates[0] < this.dates[1] == false ? this.dates[0] : this.dates[1];

      if (type == 'dateFrom') {
        let localTime = date_from + ' ' + this.startingTime;
        let dateFrom = new Date(localTime);
        return dateFrom;
      }
      if (type == 'dateTo') {
        let localTime = date_to + ' ' + this.endingTime;
        let dateTo = new Date(localTime);
        return dateTo;
      }
    },
    formatDate: function (date) {
      if (!date) {
        return '. . .';
      }

      let months = [
        this.$t('general.months.january'),
        this.$t('general.months.february'),
        this.$t('general.months.march'),
        this.$t('general.months.april'),
        this.$t('general.months.may'),
        this.$t('general.months.june'),
        this.$t('general.months.july'),
        this.$t('general.months.august'),
        this.$t('general.months.september'),
        this.$t('general.months.october'),
        this.$t('general.months.november'),
        this.$t('general.months.december'),
      ];
      let splitedDate = date.split('-');
      let formatedDate = `${splitedDate[2]} ${this.$t('general.form.of')} ${months[new Date(date).getMonth()]} ${this.$t(
        'general.form.of',
      )} ${splitedDate[0]}`;
      return formatedDate;
    },
    howManyDaysSelected: function () {
      if (!this.dates[1]) {
        return '';
      }
      const oneDay = 24 * 60 * 60 * 1000;
      const firstDate = new Date(this.dates[0]);
      const secondDate = new Date(this.dates[1]);

      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
      return `(${diffDays + 1} ${this.$t('reports.days')})`;
    },
    validateRange() {
      this.invalidDate = false;

      let validateDate = validateDateRange(this.dates);
      if (validateDate) {
        this.invalidDate = true;
        this.dates = validateDate;
      }
    },
  },
  update() {
    this.selectedRange = this.selectedRange;
  },
  computed: {
    maxDays() {
      return maxDate(this.dates);
    },
    minutesRange() {
      return [
        { name: `1 ${this.$t('dashboard.tickets.minutes')}`, value: 1 },
        { name: `5 ${this.$t('dashboard.tickets.minutes')}`, value: 5 },
        { name: `10 ${this.$t('dashboard.tickets.minutes')}`, value: 10 },
        { name: `15 ${this.$t('dashboard.tickets.minutes')}`, value: 15 },
        { name: `30 ${this.$t('dashboard.tickets.minutes')}`, value: 30 },
        { name: `60 ${this.$t('dashboard.tickets.minutes')}`, value: 60 },
      ];
    },
    getButtonEmailText() {
      if (this.byEmail) {
        return this.$t('dashboard.tickets.send');
      }
      return this.$t('dashboard.tickets.send_email');
    },
    emailRules() {
      return [(value) => (!!value && /.+@.+/.test(value)) || this.$t('general.error.not_valid_email')];
    },

    deviceProbes() {
      let probes = [];
      if (!this.device.device_data_from_frame.probes_type) return [];
      Object.entries(this.device.device_data_from_frame.probes_type).forEach(([probeName, probeValue]) => {
        if (probeValue != null && probes.indexOf(probeName) < 0) {
          probes = [...probes, probeName];
        }
      });
      this.selectedProbes = probes;
      return probes;
    },
  },
  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
    let user = await getUser();
    this.userEmail = user.email;
    this.devicesCollection = await getUserDevices(user.token, user.userId);

    let res = await getCustomers();
    let myClients = res;

    for (let client of myClients) {
      this.predefinedEmails.push(client.email);
    }
    this.getCarplate();

    this.selectedRange = { name: `10 ${this.$t('dashboard.tickets.minutes')}`, value: 10 };
  },
};
</script>
