<template>
  <v-dialog :dark="theme === 'dark' ? true : false" v-model="dialog" max-width="50%">
    <v-card :dark="theme === 'dark' ? true : false">
      <v-card-title>
        <span class="text-h5"> {{ $t('dashboard.pois_categories.poi_categories') }} </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <POICategoryDataForm ref="poiCategoryData" />
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close"> {{ $t('general.form.close') }} </v-btn>
      </v-card-actions>
    </v-card>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
        <v-icon class="mr-1">mdi-plus</v-icon> {{ $t('dashboard.pois_categories.categories') }}
      </v-btn>
    </template>
  </v-dialog>
</template>

<script>
import POICategoryDataForm from '../../../components/forms/poisForm/poiCategoryDataForm.vue';
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';

export default {
  components: {
    POICategoryDataForm,
  },
  data: () => {
    return {
      theme: ls.get('theme') ?? 'ligth',
      dialog: false,
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
    save() {
      this.close();
    },
  },
  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
  },
};
</script>
