const { createMaintenancePetition } = require('../server/petitions/maintenances/createMaintenance.js');
const { updateMaintenancePetition } = require('../server/petitions/maintenances/updateMaintenance.js');
const { deleteMaintenancePetition } = require('../server/petitions/maintenances/deleteMaintenance.js');
const userViewModel = require('./userViewModel.js');
const { getAllUserMaintenancePetition } = require('../server/petitions/maintenances/getMaintenances.js');

const UserMaintenancesViewModel = function () {
  const getAllUserMaintenance = async (user) => {
    try {
      return await getAllUserMaintenancePetition(user);
    } catch (error) {
      console.log(error);
    }
  };

  const createMaintenance = async (payload) => {
    try {
      const token = userViewModel.getToken();

      return await createMaintenancePetition(token, payload);
    } catch (error) {
      console.log(error);
    }
  };

  const updateMaintenance = async (payload) => {
    try {
      const token = userViewModel.getToken();

      return await updateMaintenancePetition(token, payload);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteMaintenance = async (payload) => {
    try {
      const token = userViewModel.getToken();

      await deleteMaintenancePetition(token, payload);
    } catch (error) {
      console.log(error);
    }
  };

  return { getAllUserMaintenance, createMaintenance, updateMaintenance, deleteMaintenance };
};

module.exports = UserMaintenancesViewModel();
